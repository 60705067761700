const styles = theme => ({
  form: {
    width: '100%'
  },
  marginBottom: {
    marginBottom: '.7rem',
  },
  marginTop: {
    marginTop: '.7rem',
  },
  button: {
    marginTop: '.7rem',
    border: 'none',
    width: 'fit-content',
    alignSelf: 'flex-end',
    '&:hover': {
      border: 'none',
    },
  },
  righted: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  buttonAction: {
    width: 'fit-content',
    fontSize: '.8rem'
  },
  noPadding: {
    margin: '0!important',
    padding: '0!important',
  },
  fullWidth: {
    width: '100%',
  },
  accordionDetails: {
    overflow: 'auto',
    maxHeight: '35vh',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  amountInfo: { fontSize: 11, marginBottom: 17, marginTop: 4, marginLeft: 14 },
  gridBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '1rem'
  },
  gridItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
    marginTop: '1rem'
  },
  styledRectangle: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    opacity: 0.9,
    transition: '.2s linear all',

    '&:hover': {
      opacity: 1,
      cursor: 'pointer'
    }
  },
  circle: {
    width: 16,
    height: 16,
    position: 'absolute',
    left: 0,
    top: 0,
    marginLeft: 8,
    marginTop: 8,
    borderRadius: '50%'
  }
})

export default styles
