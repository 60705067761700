/*eslint-disable */
import { BaseError } from './base'

export default class BadRequestError extends BaseError {
  /**
     *
     * @param type
     * @param config
     * @param code
     * @param request
     * @param response
     * @example {
                    "errors": {
                        "startDate": [
                        "Error converting value {null} to type 'System.DateTime'. Path 'startDate', line 1, position 88."
                        ],
                        "finishDate": [
                        "Error converting value {null} to type 'System.DateTime'. Path 'finishDate', line 1, position 106."
                        ]
                    },
                    "title": "One or more validation errors occurred.",
                    "status": 400,
                    "traceId": "0HLOIBNEGGT3I:00000008"
                }
     */
  constructor(type, config, code, request, response) {
    super(type, config, code, request, response)
  }
}
/*eslint-enabled */
