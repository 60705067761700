import translator from '../../translate'

/**
 *
 * @param value
 */
const ValidationFormItemDate = (form, formItem) => {
  const error = { success: true, message: '' }
  if (formItem.value === '' || formItem.value === null) {
    error.success = false
    error.message = translator._('form.error.empty')
    return error
  }
  return ' '
}
export default ValidationFormItemDate
