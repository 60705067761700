import translator from '../../../translate'
import {
  userUpdateEntityAction,
  userListDeleteAction,
} from '../../../reducers/user/action'
import { UserSchema } from '../../../model/user'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import AddIcon from '@material-ui/icons/Add'
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom'
import { ButtonType, ButtonData, ButtonActionType } from '../../../model/button'
import { Icon } from '@iconify/react'

import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../reducers/message-box/action'
import { ExcelExportUsersAction } from '../../../reducers/common/action'
import { excelExportAllUsersService } from '../../../api/common'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import {
  AddMember,
  ImportMembers,
} from 'src/pages/teams-member-list/components'
import ComponentBoxContent from 'src/model/component-box'
import styles from '../styles'
import { Typography } from '@material-ui/core'
import { teamsMemberExportAction } from 'src/reducers/teams/action'
import { useSelector } from 'react-redux'
/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 * @param {*} classes
 */

const actions = [
  {
    label: '',
    items: [
      {
        label: (
          <Typography color="#002E47" fontSize="14px" fontWeight={400}>
            Export List
          </Typography>
        ),
        type: ButtonType.NORMAL,
        icon: (
          <Icon
            icon={'pajamas:export'}
            width={14}
            height={14}
            style={{ marginRight: '5px', color: '#637381' }}
          />
        ),
        styles: {
          borderRadius: '8px',
          border: '1px solid #919eab52',
        },
        variant: 'outlined',
        link: null,
        exec: (entity, dispatch, refresh, query) => {
          dispatch(teamsMemberExportAction(query.Id, null))
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },
  {
    label: '',
    items: [
      {
        label: (
          <Typography color="#002E47" fontSize="14px" fontWeight={400}>
            Import List
          </Typography>
        ),
        type: ButtonType.NORMAL,
        icon: (
          <Icon
            icon={'pajamas:import'}
            width={14}
            height={14}
            style={{ marginRight: '5px', color: '#637381' }}
          />
        ),
        styles: {
          borderRadius: '8px',
          border: '1px solid #919eab52',
        },
        variant: 'outlined',
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            componentBoxShowAction(
              new ComponentBoxContent(
                <ImportMembers entity={entity} dispatch={dispatch} />,
                {
                  hideCloseButton: false,
                },
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },

  {
    label: '',
    items: [
      {
        label: (
          <Typography color="#fff" fontSize="14px" fontWeight={400}>
            New User
          </Typography>
        ),
        type: ButtonType.NORMAL,
        icon: <AddIcon style={{ marginRight: '5px', color: '#fff' }} />,
        styles: {
          borderRadius: '12px',
          backgroundColor: '#37D768',
        },

        variant: 'contained',
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            componentBoxShowAction(
              new ComponentBoxContent(
                <AddMember entity={entity} dispatch={dispatch} />,
                {
                  hideCloseButton: false,
                },
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },
]

export default actions
