const styles = theme => ({
  container: {
    //width: '90%',
    margin: '0 auto',
    /* RESPONSIVE BREAKPOINT
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },*/
  },
  grid: {
    width: '100%',
    flexBasis: 'unset',
    marginBottom: theme.spacing(1.5),
  },
  childGrid: {
    marginBottom: theme.spacing(1.5),
    overflow: 'visible',
    boxShadow: 'none',
  },
  informGrid: {
    minWidth: 130,
  },
  textField: {
    width: '100%',
    borderRadius: '10px !important',
  },
  media: {
    height: 60,
    width: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    marginBottom: 0,
  },
  cardContent: {
    textAlign: 'center',
  },
  paper: {
    width: '100%',
    minHeight: 'fit-content',
    textAlign: 'left',
    //overflow: 'hidden',
    borderRadius: '20px',

    [theme.breakpoints.down('xl')]: {
      //overflow: 'auto',
      //overflow: 'hidden',
      //minHeight: 485,
      //height: 'max-content',
    },

    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
      minHeight: 'auto',
      maxHeight: 'auto',
    },
    padding: '20px 30px 32px 30px',
    '& p': {
      fontSize: 14,
    },
    '& h2': {
      fontSize: 16,
      fontWeight: 700,
    },
    '& h1': {
      fontSize: 18,
      fontWeight: 700,
    },
  },
  saving: {
    position: 'fixed',
    left: '50%',
    bottom: '0',
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
    gap: '.4rem',
    background: '#EAEAEA',
    border: '1px solid #CBCBCB',
    borderRadius: '25px',
    padding: '.3rem .9rem',
    color: '#494949',
    userSelect: 'none',
    fontSize: '.9rem',
  },
  addCard: {
    position: 'absolute',
    right: '50%',
    bottom: '-55',
    transform: 'translateY(-50%)',
    zIndex: 999,
    //marginRight: '-2.5rem',
    //marginTop: '-30px',
    color: '#0C53B7',
    [theme.breakpoints.up('lg')]: {
      //overflow: 'auto',
      //overflow: 'hidden',
      bottom: '-66',
    },
  },
  textArea: {
    textAlign: 'left',
  },
  iconButtonAdd: {
    color: '#F67B3F',
    background: '#ffffff',
    '&:hover': {
      color: '#ffffff',
      background: '#F67B3F',
    },
    width: 32,
    height: 32,
  },
  popover: {
    borderRadius: 32,
    '&.MuiPopover-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
      borderRadius: 32,
    },
    '& .MuiPaper-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
      borderRadius: 32,
    },
  },
  widgetIcon: {
    color: '#919EAB',
    display: 'flex',
    flexDirection: 'row',
  },
  buttonGroup: {
    minWidth: '24px',
    marginRight: 12,
  },
  buttonGroupUpDown: {
    minWidth: '24px',
    //marginRight: 12,
  },
  warningText: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: '0px 0px 16px 16px',
    width: '-webkit-fill-available',
    height: '36px',
    backgroundColor: 'rgb(246, 123, 63)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icons: {
    width: '16px',
    marginTop: '2px',
    marginRight: '2px',
  },
})

export default styles
