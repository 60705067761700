import { put, call, take, fork } from 'redux-saga/effects'
import { shopPatchService } from '../../api/shop'
import { shopPatchSuccess } from '../../reducers/shop/action'
import { SHOP_PATCH } from '../../reducers/shop/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* shopPatchFlow(payload) {
  try {
    const { content, callback } = payload
    const response = yield call(shopPatchService, content)

    yield put(shopPatchSuccess(response.data))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* shopPatchWatcher() {
  while (true) {
    const action = yield take(SHOP_PATCH)
    const { payload } = action

    yield fork(generalSaga, shopPatchFlow, payload)
  }
}
