import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import propTypes from './prop'
import { withSnackbar } from 'notistack'
import translator from '../../translate'
import { snackbarDispose } from '../../reducers/snackbar/action'
import { useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'

import { registerMarketingInfo } from '../../api/sign-up'
import Preloader from '../../components/preloader'
import moment from 'moment-timezone'
import { Options } from 'src/model/user-marketing-info'
import {
  Button,
  CssBaseline,
  Grid,
  Box,
  Typography,
  TextField,
  Radio,
  Card,
  CircularProgress,
} from '@material-ui/core'
import { track, init, Identify, identify } from '@amplitude/analytics-browser'

import { get as getCookie } from 'es-cookie'
import { userGetSelfAction } from 'src/reducers/user/action'

const RadioButton = withStyles({
  root: {
    '&$checked': {
      color: '#015FFB',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

function Purpose(props) {
  const { classes } = props
  const [loading, setLoading] = React.useState(false)
  const [embedding, setEmbedding] = React.useState(4)
  const [sharing, setSharing] = React.useState(4)
  const [payments, setPayments] = React.useState(4)
  const [value, setValue] = React.useState('')
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [ReceivesEmail, setReceivesEmail] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [purposeFilled, setPurposeFilled] = React.useState(false)
  const [working, setWorking] = React.useState(false)
  const [purpose, setPurpose] = React.useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userGetSelfAction())

    if (getCookie('email')) {
      //init(process.env.REACT_APP_AMPLITUDE_KEY, getCookie('email'))
      track('signed_up')

      if (window.Reflio) {
        window.Reflio.signup(getCookie('email'))
      }
    }
  }, [])

  const handleChange = (text, index) => {
    setValue(text)
    setSelectedIndex(index + 1)
  }

  const handleNext = () => {
    setPurposeFilled(true)
  }

  const handleContinue = async event => {
    setLoading(true)

    //const selectedText = this.state.value
    const selectedIndexofText = selectedIndex
    let timeZone = 'None'

    if (moment.tz.guess() != undefined) {
      timeZone = moment.tz.guess()
    }
    const data = {
      selectedIndexofText,
      purpose,
      timeZone,
    }
    track('onboarding_complete_event')
    let response = await registerMarketingInfo(data)

    if (response.statusCode == 200) {
      setLoading(false)

      window.location.href = '/create-course-with-ai'
    }
    setLoading(false)
  }

  return (
    <Grid container component="main" className={classes.root}>
      {loading && <Preloader process={loading} />}
      <CssBaseline />
      <Grid item xs={12} sm={8} md={6}>
        <div className={classes.paper}>
          {!purposeFilled && (
            <>
              <div className={classes.paperText}>
                <img src="/assets/logo.png" height="50" />
                <Box m={2}></Box>
                <Typography variant="h4" textAlign="center">
                  What is your primary intention with creating mini-courses?
                </Typography>
                <Box m={2}></Box>
                <Typography variant="subtitle1" textAlign="center">
                  We’ll share use-case ideas and best practices accordingly.
                </Typography>
                <Box m={2}></Box>
              </div>
              {Options.map((option, index) => (
                <Card
                  className={classes.formGroupCard}
                  style={{
                    backgroundColor:
                      value == option.text
                        ? 'rgba(1, 95, 251,0.02) '
                        : 'rgba(255, 255, 255)',
                  }}
                  onClick={() => handleChange(option.text, index)}
                  key={'option_' + index}
                >
                  <div className={classes.formGroup}>
                    <RadioButton
                      checked={value === option.text}
                      value={option.text}
                    />
                    <Typography style={{ marginBottom: 4 }}>
                      {option.text}
                    </Typography>
                  </div>
                </Card>
              ))}
              <Box m={1}></Box>
              <Button
                onClick={() => {
                  handleNext()
                }}
                variant="contained"
                className={classes.submit}
              >
                {translator._('signUp.continue')}
              </Button>
            </>
          )}
          {purposeFilled && (
            <>
              <div className={classes.paperText}>
                <img src="/assets/logo.png" height="50" />
                <Box m={2}></Box>
                {isMobile ? (
                  <Typography
                    variant="h4"
                    textAlign="center"
                    //style={{ fontWeight: 500, fontSize: '1.1rem' }}
                  >
                    Regarding your intention, which specific product or approach
                    have you tried before?
                  </Typography>
                ) : (
                  <Typography
                    variant="h4"
                    textAlign="center"
                    //style={{ fontWeight: 500, fontSize: '1.1rem' }}
                  >
                    Regarding your intention, which specific product <br /> or
                    approach have you tried before?
                  </Typography>
                )}

                <Box m={2}></Box>
              </div>
              <TextField
                className={classes.form}
                multiline
                placeholder="We’ll share personalized suggestions accordingly."
                rows={5}
                onChange={e => {
                  setPurpose(e.target.value)
                }}
              ></TextField>
              {loading && (
                <>
                  <CircularProgress style={{ marginTop: 24 }} />
                </>
              )}
              {!loading && (
                <>
                  <Button
                    onClick={() => {
                      handleContinue()
                    }}
                    variant="contained"
                    className={classes.submit}
                  >
                    {translator._('signUp.continue')}
                  </Button>
                </>
              )}
            </>
          )}
          <Box m={2}></Box>
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Purpose)
