import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Box,
  Card,
  AppBar,
  Tab,
  Tabs,
  CardMedia,
  CardContent,
} from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f4f6f8',
    maxWidth: '75%',
    marginTop: '15px',
  },
  tabs: {
    justifyContent: 'space-evenly',
  },
  media: {
    height: 150,
    borderRadius: 15,
  },
}))

function ScrollableTabsButtonPrevent(props) {
  const { Options, selectedItem, handleClickUpdate } = props
  const classes = useStyles()
  const [tabValue, setTabValue] = React.useState(selectedItem)

  const handleClick = option => {
    setTabValue(option)
  }

  return (
    <div className={classes.root}>
      <>
        <AppBar
          position="static"
          style={{
            backgroundColor: '#f4f6f8',
            boxShadow: 'none',
            color: '#637381',
          }}
        >
          <Tabs
            value={tabValue}
            scrollButtons="auto"
            indicatorColor="primary"
            backgroundColor="primary"
            variant="scrollable"
          >
            {Options.map((option, index) => (
              <>
                <Tab
                  onClick={() => handleClick(index)}
                  label={option.cardName}
                  icon={
                    <img
                      src={`${option.appBarIcon}`}
                      width="20px"
                      height="20px"
                    />
                  }
                  {...a11yProps(index)}
                />
              </>
            ))}
          </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={tabValue}>
          <ItemList
            Options={Options}
            handleClickUpdate={handleClickUpdate}
            tabValue={tabValue}
          />
        </TabPanel>
      </>
    </div>
  )
}
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  }
}
function ItemList(props) {
  const { Options, tabValue, handleClickUpdate } = props

  const items = Options[tabValue].items
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1,
        //marginTop: '15px',
        paddingRight: '40px',
        paddingLeft: '40px',
        backgroundColor: '#f4f6f8',
        //marginTop: '20px',
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(3, 1fr)',
        },
      }}
    >
      {items.map((item, index) => (
        <ItemCard optionItems={item} handleClickUpdate={handleClickUpdate} />
      ))}
    </Box>
  )
}
function ItemCard(props) {
  const classes = useStyles()
  const { handleClickUpdate, optionItems } = props

  const handleRedirect = () => {
    handleClickUpdate(optionItems)
  }
  return (
    <Card
      sx={{
        bgcolor: '#fffff',
        height: '230px',
        width: '270px',
        marginTop: '15px',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={handleRedirect}
    >
      <CardMedia className={classes.media} image={`${optionItems.thumbnail}`} />
      <CardContent style={{ padding: 10 }}>
        <Typography variant="h5" style={{ fontSize: '1rem', fontWeight: 500 }}>
          {optionItems.name}
        </Typography>
      </CardContent>
    </Card>
  )
}
const CourseSelection = props => {
  const { classes, history, selectedItem, handleClickUpdate, Options } = props
  return (
    <>
      <Typography variant="h3" marginTop="10px">
        Pick a template. Make it your own.
      </Typography>

      <Typography
        variant="subtitle1"
        style={{
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '30px',
          letterSpacing: '0em',
          textAlign: 'center',
          marginTop: '15px',
        }}
      >
        The fonts and colors you choose below will be used for the <br />
        design of your course. You can change them at any time.
      </Typography>
      <ScrollableTabsButtonPrevent
        Options={Options}
        selectedItem={selectedItem}
        handleClickUpdate={handleClickUpdate}
      />
    </>
  )
}

export default withStyles(styles)(CourseSelection)
