import axios from 'axios'
import URL from 'src/api/url'

export async function handlePaymentFetch({ courseId, cardId, mode }) {
    await axios.post(URL.PAYMENT_CONNECTION, {
        courseId, cardId, mode
    }).then(response => {
        if (response && response.data) {
            window.location.replace(response.data.data.stripeUri)
        }
    })
}