import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import translator from '../../../translate'
import { Typography } from '@material-ui/core'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import CustomDomainCourseEdit from 'src/components/custom-domain-course-edit-modal'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { ListColumnType } from '../../../model/list'
import { ButtonType, ButtonData, ButtonActionType } from '../../../model/button'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../reducers/message-box/action'
import {
  domainDeleteAction,
  domainRetryAction,
} from '../../../reducers/domain/action'

/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 */
const columns = [
  {
    attribute: 'domain',
    label: translator._('domain.domain'),
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: entity => {
      return (
        <Typography variant="body1" style={{ fontSize: 13 }}>
          {entity.domain}
        </Typography>
      )
    },
  },

  {
    attribute: 'defaultCourseDto',
    label: 'Default',
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: entity => {
      if (entity.defaultCourseDto == null) {
        if (entity.defaultCollectionDto == null) {
          return (
            <Typography variant="body1" style={{ fontSize: 13 }}>
              No collection or course chosen
            </Typography>
          )
        } else {
          return (
            <Typography variant="body1" style={{ fontSize: 13 }}>
              {entity.defaultCollectionDto.collectionName}
            </Typography>
          )
        }
      } else {
        return (
          <Typography variant="body1" style={{ fontSize: 13 }}>
            {entity.defaultCourseDto.name}
          </Typography>
        )
      }
    },
  },
  {
    attribute: 'verificationStatus',
    label: translator._('domain.status'),
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: entity => {
      return (
        <Typography variant="body1" style={{ fontSize: 13 }}>
          {translator._('domain.statusType')[entity.verificationStatus]}
        </Typography>
      )
    },
  },

  {
    attribute: '',
    label: null,
    type: ListColumnType.ACTION,
    sortable: false,
    converter: null,
    suffix: null,
    renderer: null,
    actions: [
      {
        label: translator._('customDomain.edit'),
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            componentBoxShowAction(
              new ComponentBoxContent(
                (
                  <CustomDomainCourseEdit
                    title={translator._('customDomain.editDefault')}
                    domainName={entity.domain}
                    dataType={
                      entity.defaultCourseDto != null ? 'Course' : 'Collection'
                    }
                    inputLabel={
                      entity.defaultCourseDto != null
                        ? entity.defaultCourseDto.name
                        : entity.defaultCollectionDto != null
                        ? entity.defaultCollectionDto.collectionName
                        : ''
                    }
                    dispatch={dispatch}
                    entity={entity}
                    refresh={refresh}
                  />
                ),
                {
                  hideCloseButton: false,
                },
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('action.delete'),
        icon: DeleteIcon,
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            messageBoxShowAction(
              new MessageBoxContent(
                MessageBoxType.WARNING,
                translator._('message.delete'),
                [
                  new ButtonData(
                    ButtonType.NORMAL,
                    translator._('action.no'),
                    ButtonActionType.NO,
                    () => {
                      dispatch(messageBoxHideAction())
                    },
                  ),
                  new ButtonData(
                    ButtonType.ACTION,
                    translator._('action.yes'),
                    ButtonActionType.DELETE,
                    () => {
                      dispatch(
                        domainDeleteAction(entity.id, () => {
                          dispatch(messageBoxHideAction())
                          refresh()
                        }),
                      )
                    },
                  ),
                ],
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('domain.retry'),
        icon: EditIcon,
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(domainRetryAction(entity.id))
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return entity.verificationStatus === 2 ? false : true
        },
      },
    ],
  },
]

export default columns
