const style = theme => ({
  mainGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
  },
  header: {
    width: 280,
    textAlign: 'center',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoDiv: {
    marginTop: 32,
    marginBottom: 16,
    borderBottom: '1px solid #c5c6c7',
  },
})

export default style
