import React, { useEffect, useState } from 'react'
import styles from './styles'

import { withStyles } from '@material-ui/core/styles'
import { Typography, Box, Divider, Button } from '@material-ui/core'

import { NamingModal } from '../quiz-cube/components'
import { useHistory } from 'react-router-dom'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import EditIcon from '@material-ui/icons/Edit'

const CardHeader = props => {
  const { entity, dispatch, classes } = props

  let history = useHistory()
  const [refreshDateAvaliable, setRefreshDateAvaliable] = useState(true)
  const componentBoxHide = () => {
    dispatch(componentBoxHideAction())
  }
  const renameCourse = course => {
    const component = (
      <NamingModal
        courseEntity={course}
        dispatch={dispatch}
        history={history}
        componentBoxHide={componentBoxHide}
      />
    )

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, {
          hideCloseButton: false,
          callback: e => {},
        }),
      ),
    )
  }
  return (
    <div>
      <div className={classes.headerCourseName}>
        <Typography variant="h5" className={classes.headerText}>
          {entity && entity.name}
        </Typography>
        <EditIcon
          color="inherit"
          fontSize="small"
          className={classes.editIconButton}
          onClick={() => {
            renameCourse(entity)
          }}
        />
      </div>

      <Divider className={classes.dividerLine}></Divider>
    </div>
  )
}

export default withStyles(styles)(CardHeader)
