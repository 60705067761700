const style = theme => ({
  tablePagination: {
    float: 'right',
    width: '100%',
    justifyContent: 'space-between',
  },
  container: {
    //marginTop: theme.spacing(3),
  },
  paginationSelect: {
    padding: 0,
    margin: `0 ${theme.spacing(1)}px`,
    width: theme.spacing(7),
    fontSize: 13,
    color: '#757575',
  },
})

export default style
