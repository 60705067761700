import { put, call, take, fork } from 'redux-saga/effects'
import { paymentPatchService } from '../../api/question/payment/stripe-patch'
import { QUESTION_PAYMENT_DATA_UPDATE } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'
/**
 *
 * @param {*} payload
 */
function* paymentPatchEditFlow(payload) {
  try {
    const { content } = payload

    yield call(paymentPatchService, content)
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

export default function* paymentPatchWatcher() {
  while (true) {
    const action = yield take(QUESTION_PAYMENT_DATA_UPDATE)
    const { payload } = action
    yield fork(generalSaga, paymentPatchEditFlow, payload)
  }
}
