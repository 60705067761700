import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} entity
 */
export const getUserLimits = () =>
  new Request(URL.API, URL.GET_USER_LIMITS).get()

/**
 *
 * @param {*} entity
 */
export const customerPortal = () =>
  new Request(URL.API, URL.CUSTOMER_PORTAL).post()

/**
 *
 *
 */
export const customerUpgradePortal = () =>
  new Request(URL.API, URL.CUSTOMER_PORTAL_UPGRADE).post()
