import { put, call, take, fork } from 'redux-saga/effects'
import { confirmCodeService } from '../../api/sign-up'
import { confirmCodeSuccess } from '../../reducers/sign-up/action.js'
import { CONFIRM_CODE } from '../../reducers/sign-up/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* confirmCodeSagaFlow(payload) {
  try {
    const { data } = payload
    const response = yield call(confirmCodeService, data)

    yield put(confirmCodeSuccess(response))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* confirmCodeSagaWatcher() {
  while (true) {
    const action = yield take(CONFIRM_CODE)
    const { payload } = action

    yield fork(generalSaga, confirmCodeSagaFlow, payload)
  }
}
