import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import ListSchema from './list'
import List from '../list'
import { ListUseModeType } from '../../model/list'
import { Typography, Card, CardContent, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
class TeamsMemberListContainer extends Component {
  static defaultProps = {
    hideTopBar: false,
    hideSearchBar: false,
    showPaging: true,
    showSelect: false,
    showChildList: false,
    usageMode: ListUseModeType.LIST,
    showDetailSearch: false,
    query: {
      currentPage: 0,
      pageSize: 0,
      asc: true,
      sortingColumn: 'id',
      filterText: 'string',
      filters: [],
    },
  }

  /**
   *
   */
  render() {
    let newQuery = {
      currentPage: 0,
      pageSize: 20,
      asc: true,
      sortingColumn: 'id',
      filterText: 'string',
      filters: [],
      id: this.props.id,
      teamName: this.props.teamName,
    }

    return (
      <div>
        <List
          list={ListSchema}
          disabled={false}
          {...this.props}
          query={newQuery}
        />
      </div>
    )
  }
}

TeamsMemberListContainer.propTypes = propTypes

const mapStateToProps = response => {
  return {
    queryResult: response.teamsList.memberList,
  }
}

export default connect(mapStateToProps)(TeamsMemberListContainer)
