import React, { useState } from 'react'
import styles from './style'
import propTypes from './prop'
import { Grid, withStyles, TextField, Box } from '@material-ui/core'
import translator from '../../../../translate'
import { questionPatchAction } from '../../../../reducers/question/action'
import { QuestionFieldNames } from '../../../../model/question'
import { PatchContent, PatchData } from '../../../../model/patch'

const QuestionNumericEntry = props => {
  const { classes, entity, dispatch } = props

  const [maxValue, setMaxValue] = useState(entity[QuestionFieldNames.MAX_VALUE])

  /**
   *
   * @param {*} e
   */
  const changeHandle = e => {
    setMaxValue(e.target.value)

    dispatch(
      questionPatchAction(
        new PatchData(
          new PatchContent(
            e.target.value,
            QuestionFieldNames.MAX_VALUE,
            PatchContent.OPERATION_REPLACE,
          ),
          entity[QuestionFieldNames.ID],
        ),
        () => { },
      ),
    )
  }

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item className={classes.grid}>
        <Box mt={2} />
        <TextField
          type="number"
          label={translator._('question.addQuestion.answerType.maxValue')}
          value={maxValue}
          onChange={changeHandle}
        />
      </Grid>
    </Grid>
  )
}

QuestionNumericEntry.propTypes = propTypes

export default withStyles(styles)(QuestionNumericEntry)
