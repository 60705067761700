import { put, call, take, fork } from 'redux-saga/effects'
import { appsumoCouponRefundService } from '../../api/common'
import { appsumoCouponRefundSuccess } from '../../reducers/common/action.js'
import { APPSUMO_CODE_REFUND } from '../../reducers/common/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* appsumoCodeRefundSagaFlow(payload) {
  try {
    const { promoCodes, callback } = payload
    const response = yield call(appsumoCouponRefundService, promoCodes)
    yield put(appsumoCouponRefundSuccess(response))
    yield put(operationSuccess())
    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* appsumoCodeRefundSagaWatcher() {
  while (true) {
    const action = yield take(APPSUMO_CODE_REFUND)
    const { payload } = action

    yield fork(generalSaga, appsumoCodeRefundSagaFlow, payload)
  }
}
