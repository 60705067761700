import React from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles, Grid } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const CenterBox = props => {
  const { classes, children } = props

  return (
    <Grid className={classes.root}>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        className={classes.childRoot}
      >
        {children}
      </Grid>
    </Grid>
  )
}

CenterBox.propTypes = propTypes

export default withStyles(styles)(CenterBox)
