import React from 'react'
import style from './style'
import propTypes from './prop'
import { PaginationActions } from '..'
import translator from '../../../../translate'
import { withStyles, TablePagination } from '@material-ui/core'

const FooterFlex = props => {
  const { classes, queryResult, query, changePage, changeRowsPerPage } = props

  const handleChangePage = (event, newPage) => {
    changePage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    changeRowsPerPage(parseInt(event.target.value))
  }

  return (
    <div className={classes.container}>
      <TablePagination
        className={classes.tablePagination}
        rowsPerPageOptions={[5, 10, 20, 40, 60]}
        count={queryResult.totalCount}
        rowsPerPage={query.PageSize}
        page={query.CurrentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={PaginationActions}
        SelectProps={{
          native: true,
          className: classes.paginationSelect,
        }}
        labelRowsPerPage={translator._('list.rowsPerPage')}
      />
    </div>
  )
}

FooterFlex.propTypes = propTypes

export default withStyles(style)(FooterFlex)
