import PropTypes from 'prop-types'

const propTypes = {
  classes: PropTypes.object,
  element: PropTypes.element.isRequired,
  hasContent: PropTypes.bool.isRequired,
  formItem: PropTypes.any.isRequired,
}

export default propTypes
