import React, { useState, useEffect } from 'react'
import styles from './style'
import propTypes from './prop'
import {
  withStyles,
  FormControl,
  FormLabel,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
  FormGroup,
  Switch,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import translator from 'src/translate'
import { showModal } from 'src/components/upgrade-popup'

import { AssignmentFieldNames } from 'src/model/assignment'
import {
  QuestionFieldNames,
  CardSettingsTypes,
  CardSettingBuilder,
} from 'src/model/question'

import { useDispatch, useSelector } from 'react-redux'

import {
  useCardSettings,
  patchSetting,
  createSetting,
} from 'src/hooks/useCardSettings'

import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'

import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import URL from 'src/api/url'

const ColoredSwitch = withStyles({
  switchBase: {
    color: '#ffffff',
    '&$checked': {
      color: '#f67b3f',
    },
    '&$checked + $track': {
      backgroundColor: '#f67b3f',
    },
  },
  checked: {},
  track: {},
})(Switch)

const CardSettings = props => {
  const { classes, cardId, closeThisModal } = props
  const baseEntity = useSelector(
    state => state.assignment.entity[AssignmentFieldNames.QUESTION_DTOS],
  )
  const dispatch = useDispatch()
  const entity = baseEntity.find(x => x[QuestionFieldNames.ID] == cardId)
  const [maskedInputs, setMaskedInputs] = useState({})

  const settingsSchema = CardSettingBuilder(
    entity[QuestionFieldNames.QUESTION_TYPE],
  )
  const baseSettings =
    CardSettingsTypes[entity[QuestionFieldNames.QUESTION_TYPE]]

  const cardBaseSettings = useCardSettings(cardId, 'cardSettings')

  const isUserPremium = Number(window.CONSTANTS.USER_ROLE_ID) !== 1
  useEffect(() => {
    if (!cardBaseSettings) {
      createSetting(
        dispatch,
        cardId,
        'cardSettings',
        JSON.stringify(settingsSchema),
        () => {},
      )
    }
  }, [cardBaseSettings])

  const cardSettings = cardBaseSettings
    ? JSON.parse(cardBaseSettings.value)
    : settingsSchema

  const handleOpenPremiumDetails = () => {
    closeThisModal()
    showModal(dispatch) // premium details
  }

  return (
    <>
      {' '}
      <FormControl className={classes.form}>
        {baseSettings &&
          baseSettings.settings.map((value, index) => {
            let domContent = <></>

            const saveAction = newValue => {
              //if (!isUserPremium) return false
              patchSetting(
                dispatch,
                cardId,
                cardBaseSettings.id,
                'cardSettings',
                JSON.stringify({
                  ...cardSettings,
                  [value.key]: newValue,
                }),
                () => {},
              )
            }

            switch (value.type) {
              case 'string':
                domContent = (
                  <TextField
                    key={index}
                    label={value.label}
                    onBlur={e => saveAction(e.target.value)}
                    defaultValue={cardSettings[value.key]}
                    disabled={!cardSettings /*|| !isUserPremium*/}
                    fullWidth
                  />
                )
                break
              case 'password':
                domContent = (
                  <TextField
                    key={index}
                    type={maskedInputs[index] ? 'text' : 'password'}
                    label={value.label}
                    onBlur={e => saveAction(e.target.value)}
                    defaultValue={cardSettings[value.key]}
                    disabled={!cardSettings /*|| !isUserPremium*/}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setMaskedInputs({
                                ...maskedInputs,
                                [index]: !maskedInputs[index],
                              })
                            }
                            edge="end"
                          >
                            {maskedInputs[index] ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )
                break
              case 'boolean':
                domContent = (
                  <div className={classes.dom}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <ColoredSwitch
                            defaultChecked={cardSettings[value.key]}
                            onChange={e => saveAction(e.target.checked)}
                          />
                        }
                        disabled={!cardSettings /*|| !isUserPremium}*/}
                        label={value.label}
                      />
                    </FormGroup>
                  </div>
                )
                break
              case 'number':
                domContent = (
                  <TextField
                    type="number"
                    key={index}
                    label={value.label}
                    onBlur={e => saveAction(e.target.value)}
                    defaultValue={cardSettings[value.key]}
                    disabled={!cardSettings /*|| !isUserPremium*/}
                    fullWidth
                  />
                )
                break
              case 'editor':
                domContent = (
                  <SunEditor
                    key={'editor'}
                    defaultValue={cardSettings[value.key]}
                    onBlur={(event, editorContents) => {
                      saveAction(editorContents)
                    }}
                    className={classes.textArea}
                    setDefaultStyle="text-align: center;"
                    onImageUploadBefore={(files, info, uploadHandler) => {
                      dispatch(
                        mediaImageUploadAction(files[0], '', result => {
                          const response = {
                            result: [
                              {
                                url: `${URL.MEDIA}${result.url}`,
                                name: result.name,
                                size: 100,
                              },
                            ],
                          }

                          uploadHandler(response)
                        }),
                      )
                    }}
                    setOptions={{
                      mode: 'inline',
                      formats: ['h1', 'h2', 'p'],
                      placeholder:
                        'This will be shown to the user after they answer the question.',
                      height: 100,

                      buttonList: [
                        [],
                        ['formatBlock'],
                        [],
                        ['bold'],
                        [],
                        ['image'],
                        [],
                        ['codeView'],
                        [],
                        [
                          ':i-More Misc-default.more_vertical',
                          'fontColor',
                          'hiliteColor',
                          'align',
                          'link',
                          'italic',
                          'underline',
                          'removeFormat',
                        ],
                      ],
                      pasteTagsWhitelist: 'p|h1|h2|h3',
                      popupDisplay: 'local',
                    }}
                  />
                )
                break
              default:
                break
            }

            return (
              <div className={classes.action} key={index}>
                {domContent}

                {/*  {!isUserPremium && (
              <span
                className={classes.badge}
                onClick={handleOpenPremiumDetails}
              >
                Premium
              </span>
            )} */}
              </div>
            )
          })}
      </FormControl>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          style={{
            width: '80px',
            boxShadow: 'none',
            borderRadius: '16px',
          }}
          onClick={closeThisModal}
        >
          Save
        </Button>
      </div>
    </>
  )
}

CardSettings.propTypes = propTypes

export default withStyles(styles)(CardSettings)
