import React, { useEffect } from 'react'
import styles from '../styles'
import {
  withStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Input,
} from '@material-ui/core'
import { Icon } from '@iconify/react' // Import Sun Editor's CSS File
import { TeamListItem } from '..'
import { useSelector } from 'react-redux'
import CustomizedSwitch from 'src/components/customized-switch'
import { PatchContent, PatchData } from 'src/model/patch'
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
}

const CustomizedCheckBox = withStyles({
  root: {
    color: '#637381',
    '&$checked': {
      color: '#37D768',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const MailPreparation = props => {
  const {
    entity,
    collectionSettings,
    dispatch,
    handleSendLinkToNewMembersOnly,
    sendLinkToNewMembersOnly,
    selectedCourseList,
    setSelectedCourseList,
    classes,
  } = props
  const [allCourses, setAllCourses] = React.useState(entity.courses)

  const [emailText, setEmailText] = React.useState(
    collectionSettings.inviteEmailBody,
  )
  const [hasTeamsToView, setHasTeamsToView] = React.useState(false)

  const [teamListLength, setTeamListLength] = React.useState(0)
  const [memberListLength, setMemberListLength] = React.useState(0)
  const [selectedDomain, setSelectedDomain] = React.useState({
    id: '',
    domain: '',
  })
  const [domainList, setDomainList] = React.useState([])
  const onBlur = value => {
    if (collectionSettings.inviteEmailBody !== value) {
      const patchSource = new PatchData(null, entity.id)
      patchSource.addContent(
        new PatchContent(value, 'inviteEmailBody', PatchData.OPERATION_REPLACE),
      )

      dispatch(patchCollectionSettingsAction(patchSource, response => {}))
    }
  }

  const domainRawList = useSelector(state => state.domain.list.results)

  const domainListCreator = () => {
    const list = []

    domainRawList.map(domain => {
      if (
        domain.defaultCollectionDto &&
        domain.defaultCollectionDto.id === entity.id &&
        domain.verificationStatus === 1
      ) {
        list.push(domain)
      }
    })
    return list
  }
  useEffect(() => {
    const result = domainRawList && domainListCreator()
    setDomainList(result)
  }, [])

  const handleSelect = event => {
    setSelectedDomain({
      id: event.target.value,
      domain: event.target.name,
    })

    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(
        event.target.value,
        'customDomainId',
        PatchData.OPERATION_REPLACE,
      ),
    )

    dispatch(patchCollectionSettingsAction(patchSource, response => {}))
  }
  useEffect(() => {
    if (collectionSettings.teams.length !== 0) {
      setHasTeamsToView(true)
      setTeamListLength(collectionSettings.teams.length)
      collectionSettings.teams.map(team => {
        setMemberListLength(prev => prev + team.memberCount)
      })
    }
  }, [collectionSettings.teams])
  const handleChange = event => {
    let length = event.target.value.length

    let newItemId = event.target.value[length - 1]
    let item = allCourses.find(item => item.id == newItemId)
    if (selectedCourseList.map(item => item.id).includes(newItemId)) {
      setSelectedCourseList([])
    } else {
      setSelectedCourseList([item])
    }
  }

  return (
    <>
      <Typography color="#637381" fontWeight={400} fontSize={'14px'}>
        You can customize the invitation email below. A personalized, unique
        link for each user will be automatically included.
      </Typography>
      <TextField
        label="Email Body"
        variant="outlined"
        multiline
        rows={10}
        value={emailText}
        onChange={e => setEmailText(e.target.value)}
        onBlur={e => onBlur(e.target.value)}
      />

      <div className={classes.borderBottom}></div>

      {teamListLength !== 0 ? (
        <TeamListItem
          name={`${teamListLength} Groups`}
          number1={memberListLength}
          type={'black'}
        />
      ) : (
        <TeamListItem
          name={`You did not select any groups.`}
          number1={
            'Return and choose the group or groups to enable access to specific groups.'
          }
          type={'yellow'}
        />
      )}
      <div
        style={{
          background: '#f4f6f8',
          borderRadius: '16px',
          padding: '16px',
        }}
      >
        <FormControl className={classes.formControl}>
          <Select
            multiple
            value={allCourses}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            className={classes.select}
            renderValue={selected =>
              selectedCourseList[0] ? (
                <div className={classes.chips}>
                  <Typography>{selectedCourseList[0].name}</Typography>
                </div>
              ) : (
                <div className={classes.chips}>
                  <Typography>Select Quiz</Typography>
                </div>
              )
            }
            MenuProps={MenuProps}
          >
            {allCourses.map((item, index) => {
              return (
                <MenuItem
                  key={item.name}
                  name={item.id}
                  value={item.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <CustomizedCheckBox
                      checked={selectedCourseList
                        .map(listItem => listItem.id)
                        .includes(item.id)}
                    />
                    {item.name}
                  </div>
                  <div className={classes.displayFAI}>
                    <Icon icon={'mdi:people'} color="#637381" />
                    <Typography
                      fontSize={'12px'}
                      fontWeight={400}
                      color={'#637381'}
                    >
                      {item.memberCount}
                    </Typography>
                  </div>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>

      <div className={classes.sendLinkToNewMembersOnlyContent}>
        <Typography fontSize={'16px'} fontWeight={500}>
          Send link to new members only
        </Typography>
        <CustomizedSwitch
          id="sendLinkToNewMembersOnly"
          checked={sendLinkToNewMembersOnly}
          onChange={() =>
            handleSendLinkToNewMembersOnly(!sendLinkToNewMembersOnly)
          }
        />
      </div>

      <div className={classes.customDomain_select}>
        <div className={classes.displayF}>
          <Icon
            icon={'solar:earth-linear'}
            className={classes.magic_link_icon_black}
          />
          <Typography fontSize={'16px'} fontWeight={500}>
            Choose your custom domain linked to your collection
          </Typography>
        </div>
        {domainList && domainList.length !== 0 ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="collection-setting-custom-domain">
              Custom Domain
            </InputLabel>
            <Select
              labelId="collection-setting-custom-domain"
              id="demo-simple-select-outlined"
              value={selectedDomain.id}
              label="Custom Domain"
              onChange={event => {
                handleSelect(event)
              }}
            >
              {domainList.map((domain, index) => {
                return (
                  <MenuItem key={index} value={domain.id} name={domain.domain}>
                    {domain.domain}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        ) : (
          <div className={classes.noTeamsDiv}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src="/assets/no-connect.png"
                style={{
                  width: '80px',
                  height: '80px',
                }}
              ></img>
            </div>

            <Typography textAlign={'center'} fontSize={'16px'} fontWeight={500}>
              No custom domain selected{' '}
            </Typography>
            <Typography
              textAlign={'center'}
              fontSize={'14px'}
              color="#637381"
              fontWeight={400}
            >
              By Default your collection URL will look like this
            </Typography>
            <div className={classes.customDomainView}>
              <Icon icon={'iconoir:lock'} style={{ marginTop: '3px' }}></Icon>
              <Typography fontSize={'14px'} fontWeight={500}>
                share.quizcube.io/quiz-code
              </Typography>
            </div>
            <Typography
              textAlign={'center'}
              fontSize={'14px'}
              fontWeight={400}
              color={'#637381'}
            >
              To connect a domain, go to the Custom Domain tab.
            </Typography>
          </div>
        )}
      </div>
    </>
  )
}

export default withStyles(styles)(MailPreparation)
