import {
  FormItemPropTypes,
  FormItemOptionAttributeName,
} from '../form-item/default.js'
import PropTypes from 'prop-types'

const FormItemTreePropTypes = {
  ...FormItemPropTypes,
  nodeOperation: PropTypes.func.isRequired,
  leafNodeControl: PropTypes.func.isRequired,
  optionAttributeName: PropTypes.shape(FormItemOptionAttributeName).isRequired,
}

export default FormItemTreePropTypes
