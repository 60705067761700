import React, { Component } from 'react'
import propTypes from './prop'
import styles from './styles'
import { connect } from 'react-redux'
import translator from '../../translate'
import withStyles from '@material-ui/core/styles/withStyles'
import { Typography, Grid, Box } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { withRouter } from 'react-router-dom'
import { componentBoxHideAction } from '../../reducers/component-box/action'

class Welcome1 extends Component {
  /**
   *
   */
  buttonAction = e => {
    const { dispatch } = this.props

    this.props.history.push(e.currentTarget.dataset.path)

    dispatch(componentBoxHideAction())
  }

  /**
   *
   */
  render() {
    const { classes } = this.props

    return (
      <Grid container className={classes.adminExpContainer} spacing={2}>
        <Grid item md={12}>
          <Typography component="h1" variant="h4">
            {translator._('welcome.adminOnboarding.title')}
          </Typography>
          <Box mt={2} />
        </Grid>

        <Grid item md={6}>
          <Card className={classes.card}>
            <CardActionArea
              data-path="/planning/journey"
              onClick={this.buttonAction}
            >
              <CardMedia
                component="img"
                alt={translator._('welcome.adminOnboarding.journeyTitle')}
                height="180"
                src="assets/journey-welcome.png"
                title={translator._('welcome.adminOnboarding.journeyTitle')}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {translator._('welcome.adminOnboarding.journeyTitle')}
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  {translator._('welcome.adminOnboarding.journeyText')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item md={6}>
          <Card className={classes.card}>
            <CardActionArea
              data-path="/planning/trivia"
              onClick={this.buttonAction}
            >
              <CardMedia
                component="img"
                alt={translator._('welcome.adminOnboarding.triviaTitle')}
                height="180"
                src="assets/trivia-welcome.png"
                title={translator._('welcome.adminOnboarding.triviaTitle')}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {translator._('welcome.adminOnboarding.triviaTitle')}
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  {translator._('welcome.adminOnboarding.triviaText')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item md={6}>
          <Card className={classes.card}>
            <CardActionArea
              data-path="/planning/reinforcement"
              onClick={this.buttonAction}
            >
              <CardMedia
                component="img"
                alt={translator._('welcome.adminOnboarding.reinforcementTitle')}
                height="180"
                src="../../assets/reinforcement-welcome.png"
                title={translator._(
                  'welcome.adminOnboarding.reinforcementTitle',
                )}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {translator._('welcome.adminOnboarding.reinforcementTitle')}
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  {translator._('welcome.adminOnboarding.reinforcementText')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item md={6}>
          <Card className={classes.card}>
            <CardActionArea
              data-path="/planning/free-planning"
              onClick={this.buttonAction}
            >
              <CardMedia
                component="img"
                alt={translator._('welcome.adminOnboarding.freePlanTitle')}
                height="180"
                src="assets/free-plan-welcome.png"
                title={translator._('welcome.adminOnboarding.freePlanTitle')}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {translator._('welcome.adminOnboarding.freePlanTitle')}
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  {translator._('welcome.adminOnboarding.freePlanText')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

Welcome1.propTypes = propTypes

const mapStateToProps = response => ({
  componentBox: response.componentBox,
})
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Welcome1)),
)
