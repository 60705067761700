import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import { PatchData } from '../../../../model/patch'
import isEmpty from '../../../../utils/is-empty'
import { withStyles } from '@material-ui/core'
import { Panel as ColorPickerPanel } from 'material-ui-rc-color-picker'
import 'material-ui-rc-color-picker/assets/index.css'

const FormItemColorPicker = props => {
  const { value, updateEntityKeyValue } = props
  const [stateValue, setStateValue] = useState(value)
  const [stateBlurValue, setStateBlurValue] = useState(value)

  /**
   *
   * @param {*} event
   */
  const onChangeHandler = event => {
    setStateValue(event.color)
    updateEntityKeyValue(event.color, props, PatchData.OPERATION_REPLACE)
  }

  /**
   *
   * @param {*} event
   */
  const onBlur = event => {
    if (stateBlurValue === stateValue) {
      return
    }

    updateEntityKeyValue(stateValue, props, PatchData.OPERATION_REPLACE)

    setStateBlurValue(stateValue)
  }

  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <div>
          <ColorPickerPanel
            enableAlpha={false}
            color={stateValue ? stateValue : '#ff0000'}
            onBlur={onBlur}
            onChange={onChangeHandler}
            defaultColor="#ff0000"
            mode="RGB"
          />
        </div>
      }
    />
  )
}

FormItemColorPicker.propTypes = propTypes

export default withStyles(style)(FormItemColorPicker)
