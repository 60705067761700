import { put, call, take, fork } from 'redux-saga/effects'
import { questionListService } from '../../api/question'
import { questionIdListSuccess } from '../../reducers/question/action'
import { QUESTION_ID_LIST } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionIdListFlow(payload) {
  try {
    const { query, callback } = payload
    const response = yield call(questionListService, query)
    if (
      response.data.results.length === 0 &&
      query.FilterText === '' &&
      query.Filters === undefined
    ) {
      response.data.results = null
    }
    yield put(questionIdListSuccess(response.data))
    yield put(operationSuccess())
    callback(response.data.results)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionIdListWatcher() {
  while (true) {
    const action = yield take(QUESTION_ID_LIST)
    const { payload } = action
    yield fork(generalSaga, questionIdListFlow, payload)
  }
}
