import { isMobile } from 'react-device-detect'

const styles = theme => ({
  settingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '60vw',
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  headerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
  },
  prevButton: {
    width: 64,
    height: 36,
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  advancedGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  switchControl: {
    marginLeft: 0,
  },
  textFields: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: isMobile ? 'auto' : 700,
    minHeight: isMobile ? 'auto' : 300,
  },
  settings: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  taskPerDayInput: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
  },
  input: {
    borderColor: 'transparent',
  },
  gamificationDesc: {
    marginRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  previewGroup: {
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(1),
  },
  visibilityIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    marginRight: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  iconWrapper: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    //backgroundColor: '#D6F1E4',
    borderRadius: theme.spacing(3),
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    marginRight: theme.spacing(1),
  },
  timingHolder: {
    maxWidth: 320,
  },
  timingHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  aboutTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  timingHeaderGroup: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  infoSameLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
  copy: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  textField: {
    textFill: 'black',
    paddingRight: theme.spacing(3),
  },
  inputField: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  listGroup: {
    marginTop: 0,
  },
  listLabel: {
    padding: 24,
  },
  select: {
    marginTop: '0!important',
  },
})

export default styles
