import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_FOLDER_PATCH } from '../../reducers/media-folder/type'
import { mediaFolderPatchService } from '../../api/media-folder'
import { mediaFolderPatchSuccess } from '../../reducers/media-folder/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaFolderPatchFlow(payload) {
  try {
    const { content } = payload

    const response = yield call(mediaFolderPatchService, content)

    yield put(mediaFolderPatchSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaFolderPatchWatcher() {
  while (true) {
    const action = yield take(MEDIA_FOLDER_PATCH)
    const { payload } = action

    yield fork(generalSaga, mediaFolderPatchFlow, payload)
  }
}
