const style = theme => ({
  root: {
    marginRight: 6,
    marginBottom: 6,
    marginTop: 6,
  },
  formLabel: {
    //paddingTop: 6,
  },
  hasContent: {
    color: '#333333',
  },
  disabled: {
    opacity: 0.4,
  },
  error: {
    color: '#f44336',
  },
  info: {
    color: '#333333',
    fontSize: 13,
    paddingBottom: theme.spacing(1),
  },
})

export default style
