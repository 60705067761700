import { put, call, take, fork } from 'redux-saga/effects'
import { userPatchService } from '../../api/user'
import { userPatchSuccess } from '../../reducers/user/action'
import { USER_PATCH } from '../../reducers/user/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* userPatchFlow(payload) {
  try {
    const { content, callback } = payload

    yield call(userPatchService, content)
    yield put(userPatchSuccess(content))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* userPatchWatcher() {
  while (true) {
    const action = yield take(USER_PATCH)
    const { payload } = action

    yield fork(generalSaga, userPatchFlow, payload)
  }
}
