import { put, call, take, fork } from 'redux-saga/effects'
import { questionAddInsideCourseService } from '../../api/question'
import { questionAddSuccess } from '../../reducers/question/action'
import { QUESTION_ADD } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionAddFlow(payload) {
  try {
    const { entity, courseId, callback } = payload
    const response = yield call(
      questionAddInsideCourseService,
      entity,
      courseId,
    )

    yield put(questionAddSuccess(response.data))
    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionAddWatcher() {
  while (true) {
    const action = yield take(QUESTION_ADD)
    const { payload } = action

    yield fork(generalSaga, questionAddFlow, payload)
  }
}
