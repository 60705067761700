import PropTypes from 'prop-types'
import { ConverterTypes } from '../../../model/converter'
import { PatchDataTypes } from '../../../model/patch'

export class FormItemType {
  static INPUT = 0
  static TEXTAREA = 1
  static SELECT = 2
  static SWITCH = 3
  static DATE = 4
  static TREE = 5
  static LIST = 6
  static FILE_UPLOAD = 7
  static GROUP_CHECKBOX = 8
  static GROUP_RADIO = 9
  static COLOR_PICKER = 10
  static MEDIA_IMAGE = 11
  static MENU = 12
  static POOL_SELECTION = 13
  static REVERSED_SWITCH = 14
  static TEAM_AUTO_COMPLETE = 15
}
export class FormItemFilterAddMode {
  static SINGLE = 0
  static MULTIPLE = 1
}

export const FormItemFilterAddModes = [
  FormItemFilterAddMode.SINGLE,
  FormItemFilterAddMode.MULTIPLE,
]

export const FormItemTypes = [
  FormItemType.INPUT,
  FormItemType.TEXTAREA,
  FormItemType.SELECT,
  FormItemType.SWITCH,
  FormItemType.DATE,
  FormItemType.TREE,
  FormItemType.LIST,
  FormItemType.FILE_UPLOAD,
  FormItemType.GROUP_CHECKBOX,
  FormItemType.GROUP_RADIO,
  FormItemType.COLOR_PICKER,
  FormItemType.MEDIA_IMAGE,
  FormItemType.MENU,
  FormItemType.POOL_SELECTION,
  FormItemType.REVERSED_SWITCH,
  FormItemType.TEAM_AUTO_COMPLETE,
]

export const FormItemOptions = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  data: PropTypes.object,
}

export const FormItemOptionAttributeName = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export const FormItemError = {
  success: PropTypes.bool.isRequired,
  message: PropTypes.string,
}

export const FormItemPropTypes = {
  validator: PropTypes.func,
  alternativePatch: PropTypes.func,
  converter: PropTypes.shape(ConverterTypes),
  checkError: PropTypes.func,
  controlFormItemAsync: PropTypes.func,
  defaultPatchOperation: PropTypes.oneOf(PatchDataTypes),
  updateFormItemKeyValue: PropTypes.func,
  filterAddMode: PropTypes.oneOf(FormItemFilterAddModes),
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
  type: PropTypes.oneOf(FormItemTypes).isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.any,
  error: PropTypes.shape(FormItemError).isRequired,
}
