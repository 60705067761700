import { SNACKBAR_SHOW, SNACKBAR_DISPOSE } from './type'

/**
 *
 * @param {*} message
 */
export const snackbarShowAction = message => {
  return {
    type: SNACKBAR_SHOW,
    payload: {
      message,
    },
  }
}

/**
 *
 * @param {*} id
 */
export const snackbarDispose = id => {
  return {
    type: SNACKBAR_DISPOSE,
    payload: {
      id,
    },
  }
}
