import { put, call, take, fork } from 'redux-saga/effects'
import { editCourseCollectionIndexService } from '../../api/course-collection'
import { editCourseCollectionIndexSuccess } from '../../reducers/course-collection/action'
import { EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX } from '../../reducers/course-collection/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* editCourseCollectionIndexFlow(payload) {
  try {
    const { entity, callback } = payload

    const response = yield call(editCourseCollectionIndexService, entity)
    yield put(editCourseCollectionIndexSuccess(response.data))
    yield put(operationSuccess())
    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* editCourseCollectionIndexWatcher() {
  while (true) {
    const action = yield take(EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX)
    const { payload } = action

    yield fork(generalSaga, editCourseCollectionIndexFlow, payload)
  }
}
