const URL = {
  PARTICIPATION_WEEKLY: 'Statistics/ConsistencyStatisticsWeekly',
  SUPER_PARTICIPATION_WEEKLY: 'Statistics/SuperConsistencyStatisticsWeekly',
  PARTICIPATION_MONTHLY: 'Statistics/ConsistencyStatisticsMonthly',
  SUPER_PARTICIPATION_MONTHLY: 'Statistics/SuperConsistencyStatisticsMonthly',

  PARTICIPATION_WEEKLY_BY_PLATFORM:
    'Statistics/ConsistencyStatisticsByPlatformWeekly',
  PARTICIPATION_MONTHLY_BY_PLATFORM:
    'Statistics/ConsistencyStatisticsByPlatformMonthly',

  SUPER_PARTICIPATION_WEEKLY_BY_PLATFORM:
    'Statistics/SuperConsistencyStatisticsByPlatformWeekly',
  SUPER_PARTICIPATION_MONTHLY_BY_PLATFORM:
    'Statistics/SUPERConsistencyStatisticsByPlatformMonthly',
}

export default URL
