import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import ListSchema from './list'
import List from '../../container/list'
import { ListUseModeType } from '../../model/list'
import { Typography, Card, CardContent, Box } from '@material-ui/core'
class ListDomain extends Component {
  static defaultProps = {
    hideTopBar: true,
    hideSearchBar: true,
    showPaging: true,
    showSelect: false,
    showChildList: false,
    usageMode: ListUseModeType.LIST,
    showDetailSearch: false,
    query: {
      CurrentPage: 0,
      PageSize: 5,
      SortingColumn: 'createdAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    },
  }

  /**
   *
   */
  render() {
    let newQuery = this.props.query

    const { queryResult } = this.props

    return (
      (queryResult.results == null || queryResult.results.length > 0) && (
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Your Domain List
            </Typography>
            <Box m={2} />
            <List
              list={ListSchema}
              disabled={false}
              {...this.props}
              query={newQuery}
            />
          </CardContent>
        </Card>
      )
    )
  }
}

ListDomain.propTypes = propTypes

const mapStateToProps = response => ({
  queryResult: response.domain.list,
})

export default connect(mapStateToProps)(ListDomain)
