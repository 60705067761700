const styles = theme => ({
  imageContent: {
    width: '100%',
  },
  addIcon: {
    color: 'white',
  },
  folderIcon: {
    fontSize: 178,
    color: '#00adf5',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%)',
  },
  input: {
    padding: theme.spacing(1),
    marginTop: -50,
    marginLeft: 15,
    width: 150,
    fontSize: 13,
  },
  blur: {
    border: '1px solid transparent',
    backgroundColor: 'transparent',
  },
  endAdornmentIcon: {
    color: '#757575',
    fontSize: 16,
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  folderIconHolder: {
    overflow: 'hidden',
    width: '100%',
    height: '145px',
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: 'green',
  },
  chip: {
    fontSize: 9,
    marginBottom: 10,
    marginTop: 12,
    marginRight: 4,
  },
  titleWrapActionPosRight: {
    marginLeft: 4,
  },
  title: {
    fontSize: 12,
  },
  subtitle: {
    fontSize: 12,
  },
})

export default styles
