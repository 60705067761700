const style = theme => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  characterCount: {
    textAlign: 'left',
    fontSize: 11,
  },
  paper: {
    borderRadius: '16px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    //height: '380px',
    width: '100%',
    //padding: 32,
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
  },
  innerGrid: {
    display: 'flex',
    flexDirection: 'row',
    //minWidth: '760px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      zoom: 0.7,
    },
  },
  backButton: {
    position: 'absolute',
    top: '78px',
    left: '32px',
    display: 'flex',
    '&:hover': { cursor: 'pointer' },
  },
  button: {
    color: '#637381',
    '&:hover': { backgroundColor: 'transparent' },
  },

  ribbon: {
    width: '150px',
    height: '150px',
    overflow: 'hidden',
    position: 'absolute',
    top: -10,
    right: -10,
    '&:before': {
      boxSizing: 'content-box',
      borderRightColor: 'transparent',
      borderTopColor: 'transparent',
      top: 0,
      left: 0,
      position: 'absolute',
      zIndex: -1,
      content: '',
      display: 'block',
      border: '5px solid #2980b9',
    },
    '&:after': {
      borderRightColor: 'transparent',
      borderTopColor: 'transparent',
      top: 0,
      left: 0,
      position: 'absolute',
      zIndex: -1,
      content: '',
      display: 'block',
      border: '5px solid #2980b9',
    },
  },

  ribbonContent: {
    left: '-25px',
    top: '30px',
    webkitTransform: 'rotate(45deg)',
    msTransform: 'rotate(45deg)',
    transform: 'rotate(45deg)',
    position: 'absolute',
    display: 'block',
    width: '225px',
    padding: '15px 0',
    backgroundColor: '#3498db',
    boxShadow: '0 5px 10px rgba(0,0,0,.1)',
    color: '#fff',
    //font: "700 18px/1 'Lato', sans-serif",
    textShadow: ' 0 1px 1px rgba(0,0,0,.2)',
    //textTransform: uppercase;
    textAlign: 'center',
  },
  ribbonTopRight: {
    top: '-10px',
    right: '-10px',
    '&:before': {
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      top: 0,
      left: 0,
    },
    '&:after': {
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      bottom: 0,
      right: 0,
    },
  },

  ribbonTopRightSpan: {
    left: -'25px',
    top: '30px',
    transform: 'rotate(45deg)',
  },
  dividerMargin: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  lastTextPadding: {
    paddingBottom: '100px',
  },
  desktopText: {
    fontWeight: 600,
    fontSize: '1.145rem',
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  createButton: {
    backgroundColor: '#015FFB',
    borderRadius: '24px',
  },
})

export default style
