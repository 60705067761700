import PropTypes from 'prop-types'
import { ListPropTypes, ListUseModeTypes } from '../../model/list'

const QueryResultPropTypes = {
  results: PropTypes.array,
  totalCount: PropTypes.number,
}

const propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  selectedListSender: PropTypes.func,
  initialData: PropTypes.object,
  queryResult: PropTypes.shape(QueryResultPropTypes),
  showDetailSearch: PropTypes.bool,
  showPaging: PropTypes.bool.isRequired,
  showSelect: PropTypes.bool.isRequired,
  showChildList: PropTypes.bool.isRequired,
  usageMode: PropTypes.oneOf(ListUseModeTypes).isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  list: PropTypes.shape(ListPropTypes).isRequired,
  filterOpen: PropTypes.bool,
  multipleSelect: PropTypes.bool,
  hideSearchBar: PropTypes.bool,
  showRowAction: PropTypes.bool,
  customFirstHeaderRow: PropTypes.any,
  showRowAction: PropTypes.bool,
  showDragDrop: PropTypes.bool,
  onDragEnd: PropTypes.func,
  hoverActionEnabled: PropTypes.bool,
}

export default propTypes
