import React from 'react'
import { MLabel } from 'src/theme'

const ListItemTag = props => {
  const { classes, entity, tag } = props

  if (tag.label(entity) === null) {
    return null
  }

  if (tag.hidden(entity)) {
    return null
  }

  return (
    <MLabel color={tag.color(entity)} className={classes.tag}>
      {tag.icon(entity)} {tag.label(entity)}
    </MLabel>
  )
}

export default ListItemTag
