import { put, call, take, fork } from 'redux-saga/effects'
import { handleDisconnectService } from '../../api/question/payment/stripe-disconnect'
import { questionPaymentDisconnectAction } from '../../reducers/assignment/action'
import { QUESTION_PAYMENT_DISCONNECT } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'
/**
 *
 * @param {*} payload
 */
function* paymentDisconnectFlow(payload) {
  try {
    yield call(handleDisconnectService, payload)
    yield questionPaymentDisconnectAction(payload)

    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

export default function* paymentDisconnectWatcher() {
  while (true) {
    const action = yield take(QUESTION_PAYMENT_DISCONNECT)
    const { payload } = action
    yield fork(generalSaga, paymentDisconnectFlow, payload)
  }
}
