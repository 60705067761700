import { put, call, take, fork } from 'redux-saga/effects'
import { shopGetService } from '../../api/shop'
import { shopGetSuccess } from '../../reducers/shop/action'
import { SHOP_GET } from '../../reducers/shop/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* shopGetFlow(payload) {
  try {
    const { callback } = payload
    const response = yield call(shopGetService)

    yield put(shopGetSuccess(response.data))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* shopGetWatcher() {
  while (true) {
    const action = yield take(SHOP_GET)
    const { payload } = action

    yield fork(generalSaga, shopGetFlow, payload)
  }
}
