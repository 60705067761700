import translator from '../translate'
import { TIME_ZONE } from '../model/time'
import AnswerTypeTitleFactory from '../components/card-item/answer-type/factory-title'

const SYSTEM_DATA = {
  PASSWORD_CREATION: [
    {
      value: 1,
      label: translator._('data.passwordCreation.setManually'),
    },
    {
      value: 2,
      label: translator._('data.passwordCreation.sendEmailInvitationNow'),
    },
    {
      value: 3,
      label: translator._('data.passwordCreation.sendEmailInvitationLater'),
    },
  ],
  LANGUAGE: [
    { value: 'en', label: translator._('data.language.english') },
    { value: 'tr', label: translator._('data.language.turkish') },
  ],
  GENDER: [
    { value: 'male', label: translator._('data.gender.male') },
    { value: 'female', label: translator._('data.gender.female') },
  ],
  DAYS: [
    { value: 1, label: translator._('data.days.monday') },
    { value: 2, label: translator._('data.days.tuesday') },
    { value: 3, label: translator._('data.days.wednesday') },
    { value: 4, label: translator._('data.days.thursday') },
    { value: 5, label: translator._('data.days.friday') },
    { value: 6, label: translator._('data.days.saturday') },
    { value: 0, label: translator._('data.days.sunday') },
  ],
  AUTO_SENDER_SEND_TYPE: [
    { value: 0, label: translator._('data.autoSenderSendType.asc') },
    { value: 1, label: translator._('data.autoSenderSendType.desc') },
    { value: 2, label: translator._('data.autoSenderSendType.random') },
  ],
  AUTO_SENDER_FINISH_TYPE: [
    { value: 0, label: translator._('data.autoSenderFinishType.stop') },
    { value: 1, label: translator._('data.autoSenderFinishType.finish') },
  ],
  TRAINING_PLAN_TYPE: [
    {
      value: 1,
      label: translator._('data.trainingPlanType.allTeamEmployee'),
    },
    { value: 2, label: translator._('data.trainingPlanType.newEmployee') },

    {
      value: 3,
      label: translator._('data.trainingPlanType.employeWithoutNew'),
    },
  ],
  BOOLEAN: [
    { value: true, label: translator._('data.boolean.true') },
    { value: false, label: translator._('data.boolean.false') },
  ],
  BOOLEAN_STRING: [
    { value: 'true', label: translator._('data.booleanString.true') },
    { value: 'false', label: translator._('data.booleanString.false') },
  ],
  TIME_ZONE: [
    {
      value: TIME_ZONE.ABIDJAN,
      label: 'Africa/Abidjan',
    },
    {
      value: TIME_ZONE.ISTANBUL,
      label: 'Europe/Istanbul',
    },
  ],
  DATE_FORMAT: [
    { value: 'DD-MM-YYYY', label: 'DD-MM-YYYY' },
    { value: 'MM-DD-YYYY', label: 'MM-DD-YYYY' },
    { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
    { value: 'DD.MM.YYYY', label: 'DD.MM.YYYY' },
    { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
    { value: 'M/D/YYYY', label: 'M/D/YYYY' },
    { value: 'M/D/YY', label: 'M/D/YY' },
    { value: 'D MMMM YYYY', label: 'D MMMM YYYY' },
    { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
  ],
  FILTER_LOGIC: [
    { value: 'or', label: translator._('data.filterLogic.or') },
    { value: 'and', label: translator._('data.filterLogic.and') },
  ],
  FILTER_OPTIONS: {
    GameInfoStatus: [
      { value: 0, label: translator._('gameInfo.type.draft') },
      { value: 1, label: translator._('gameInfo.type.completed') },
      { value: 2, label: translator._('gameInfo.type.planned') },
      { value: 3, label: translator._('gameInfo.type.openToPlay') },
    ],
    Anonymous: [
      { value: true, label: translator._('question.isAnonymous') },
      { value: false, label: translator._('question.notAnonymous') },
    ],
    IsActive: [
      { value: true, label: translator._('general.isActive') },
      { value: false, label: translator._('general.isPassive') },
    ],
    IsCorrect: [
      { value: true, label: translator._('general.isCorrect') },
      { value: false, label: translator._('general.isWrong') },
    ],
    VerificationStatus: [
      { value: 1, label: translator._('general.verified') },
      { value: 2, label: translator._('general.unverified') },
    ],
    InvolvedIn: [
      { value: 1, label: translator._('general.learningJourney') },
      { value: 2, label: translator._('general.reinforcement') },
      { value: 3, label: translator._('general.independentItems') },
    ],
    ItemType: [
      { value: 0, label: translator._('general.assessment') },
      { value: 1, label: translator._('general.surveyAndFeedback') },
      { value: 2, label: translator._('general.information') },
    ],
    ReplyStatus: [
      { value: true, label: translator._('question.report.replied') },
      { value: false, label: translator._('question.report.notReplied') },
    ],
    IsInvited: [
      { value: true, label: translator._('user.isInvited') },
      { value: false, label: translator._('user.notInvited') },
    ],
    IsLoggedIn: [
      { value: true, label: translator._('user.isLoggedIn') },
      { value: false, label: translator._('user.notLoggedIn') },
    ],
    IsPoll: [
      { value: true, label: translator._('question.isPoll') },
      { value: false, label: translator._('question.notPoll') },
    ],
    IsAssessment: [
      { value: true, label: translator._('general.isAssessment') },
      { value: false, label: translator._('general.notAssessment') },
    ],
    HasAfterInfo: [
      { value: true, label: translator._('question.hasAfterInfo') },
      {
        value: false,
        label: translator._('question.noAfterInfo'),
      },
    ],
    HasPreInfo: [
      { value: true, label: translator._('question.hasPreInfo') },
      {
        value: false,
        label: translator._('question.noPreInfo'),
      },
    ],
    FinishStatus: [
      { value: true, label: translator._('gameInfo.isFinished') },
      { value: false, label: translator._('gameInfo.notFinished') },
    ],
    QuestionLevel: [
      {
        value: 1,
        label: translator._('question.levels.tooEasy'),
      },
      { value: 2, label: translator._('question.levels.easy') },
      { value: 3, label: translator._('question.levels.normal') },
      { value: 4, label: translator._('question.levels.hard') },
      { value: 5, label: translator._('question.levels.tooHard') },
    ],
    QuestionStatus: [
      {
        value: 1,
        label: translator._('question.statusList.draft'),
      },
      { value: 3, label: translator._('question.statusList.published') },
    ],
    QuestionType: [
      {
        value: 1,
        label: AnswerTypeTitleFactory({ type: 1 }),
      },
      {
        value: 2,
        label: AnswerTypeTitleFactory({ type: 2 }),
      },
      {
        value: 3,
        label: AnswerTypeTitleFactory({ type: 3 }),
      },
      {
        value: 4,
        label: AnswerTypeTitleFactory({ type: 4 }),
      },
      {
        value: 5,
        label: AnswerTypeTitleFactory({ type: 5 }),
      },
      {
        value: 6,
        label: AnswerTypeTitleFactory({ type: 6 }),
      },
      {
        value: 7,
        label: AnswerTypeTitleFactory({ type: 7 }),
      },
      {
        value: 8,
        label: AnswerTypeTitleFactory({ type: 8 }),
      },
      {
        value: 9,
        label: AnswerTypeTitleFactory({ type: 9 }),
      },
    ],
    TrainingHasPlan: [
      { value: true, label: translator._('training.hasPlan') },
      { value: false, label: translator._('training.noPlan') },
    ],
    AUTO_SENDER_SEND_TYPE: [
      { value: 0, label: translator._('data.autoSenderSendType.asc') },
      { value: 1, label: translator._('data.autoSenderSendType.desc') },
      { value: 2, label: translator._('data.autoSenderSendType.random') },
    ],
    AUTO_SENDER_FINISH_TYPE: [
      { value: 0, label: translator._('data.autoSenderFinishType.stop') },
      {
        value: 1,
        label: translator._('data.autoSenderFinishType.finish'),
      },
    ],
    USER_MESSAGE_FEEDBACK_TYPE: [
      { value: 0, label: translator._('userMessage.getInform') },
      {
        value: 1,
        label: translator._('userMessage.suggestion'),
      },
      { value: 2, label: translator._('userMessage.complaint') },
      { value: 3, label: translator._('userMessage.idea') },
    ],
  },
}

export default SYSTEM_DATA
