const styles = theme => ({
  list: {
    width: 500,
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  drawer: {
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  },
  tabList: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    marginLeft: '15px',
  },
  tab: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
      marginRight: '5px!important',
    },
  },
  content: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  endButtons: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  item: {
    width: '100%',
    marginBottom: '5px',
  },

  imagePlaceholder: {
    marginBottom: theme.spacing(1.5),
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: theme.spacing(1.5),
    alignItems: 'center',
  },
  imageUpload: {
    display: 'grid',
  },
  favIcon: {
    display: 'flex',
    flexDirection: 'row',
  },
  bottomButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    bottom: '20px',
    width: 460,
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
  },
  bottomButtonLoader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    bottom: '20px',
    width: 460,
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
  },
  card: {
    maxWidth: 460,
    [theme.breakpoints.down('md')]: {
      maxWidth: '80vw',
    },
    width: 'fill-available',
    borderRadius: 5,
  },
  previewCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  removeImageButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    fontSize: '12px',
  },
  thumbNailImage: {
    maxHeight: 120,
  },
  previewBtn: {
    marginRight: '20px',
  },
  presets: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '10px',
    marginTop: '10px',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  chip: {
    backgroundColor: 'transparent',
  },
  cardRoot: {},
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  colours: {
    display: 'flex',
  },
  presetHeader: {
    marginTop: '7px',
  },
  padding: {
    paddingTop: '.6rem',
    paddingBottom: '.6rem',
  },
  colorButton: {
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    marginRight: '5px',
    opacity: '.8',

    '&:hover': {
      cursor: 'pointer',
      opacity: '1',
    },
  },
  editPresetHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  media: {
    width: '100%',
    height: '100px',
  },
  bgContainer: {
    position: 'relative',
    width: '100%',
    borderRadius: '16px',
  },
  bgTrash: {
    position: 'absolute',
    right: 0,
    top: 0,
    marginRight: '5px',
    marginTop: '5px',
    background: '#f2f2f261',
    borderRadius: '50%',
    color: 'black',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backgroundImage: {
    borderRadius: '10px',
  },
  pickerBg: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    width: 32,
    height: 32,
    background: '#f2f2f2',
    borderRadius: '10px',
    boxShadow: '0px 0px 5px #00000029',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  picker: {
    position: 'absolute',
    marginRight: -300,
    marginTop: 200,
    zIndex: 99,
  },
  pickerSpan: {
    width: 24,
    height: 24,
    borderRadius: '10px',
    background: 'red',
  },
  selectedGrid: {
    background: '#e3e3e3',
  },
  inputGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  inputItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  /*locked: {
    pointerEvents: 'none',
    userSelect: 'none',
    filter: 'blur(15px)',
    overflow: 'hidden',
  },*/
  tabContainer: {
    position: 'relative',
  },
  premiumContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
  },
  previewText: {
    position: 'absolute',
    left: 0,
    top: 0,
    marginLeft: 10,
    marginTop: 10,
  },
  root__bg: {
    display: 'flex',
    paddingTop: 0,
    marginTop: 10,
    boxSizing: 'border-box',
    width: '100%',
    flexDirection: 'column',
    gap: '15px',
    justifyContent: 'space-between',
  },
  root__section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '20px',
  },
  image__bg: {
    position: 'relative',
    display: 'flex',
    width: 128,
    height: 128,
    borderRadius: '1rem',
    border: `1px solid #d9d9d9`,
    backgroundColor: '#fbfbfb',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    overflow: 'hidden',
  },
  image__icon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 100,
    height: 100,
    transform: 'translate(-50%, -50%)',
    opacity: 0.5,
    transition: '0.2s linear all',
    display: 'flex',
    justifyContent: 'center',
    gap: 5,
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  image__span: {
    fontSize: '0.85rem',
    lineHeight: '0.75rem',
    fontWeight: '700',
    color: theme.palette.grey[500],
    width: '100%',
  },
  image__content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image__img: {
    maxHeight: '128px',
  },
  root__content: {
    display: 'flex',
    width: '100%',
    gap: '0.5rem',
    flexDirection: 'column',
  },
  input__title: {
    fontSize: '2rem',
    borderColor: 'black',
  },
  span__extension: {
    fontSize: '0.8rem',
    lineHeight: '0.75rem',
    textAlign: 'center',
    width: '128px',
    color: theme.palette.grey[500],
  },
  file__upload: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxHeight: '128px',
    cursor: 'pointer',
    opacity: '0',
  },
  image__remove: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 48,
    height: 48,
    borderRadius: '1rem',
    opacity: 0,
    backgroundColor: theme.palette.grey[500_16],
    transition: '0.2s linear all',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 0.3,
    zIndex: 999,
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
