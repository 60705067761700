/*eslint-disable */
import { BaseError } from './base'

export default class UnexpectedError extends BaseError {
  /**
   *
   * @param type
   * @param config
   * @param code
   * @param request
   * @param response
   */
  constructor(type, config, code, request, response) {
    super(type, config, code, request, response)
  }
}
/*eslint-enabled */
