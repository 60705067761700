import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import translator from '../../../translate'
import { Typography } from '@material-ui/core'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import { ListColumnType } from '../../../model/list'
import { ButtonType, ButtonData, ButtonActionType } from '../../../model/button'
import { ConverterDateToLongFormat } from '../../../utils/converter'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../reducers/message-box/action'
import { userGetAction, userDeleteAction } from '../../../reducers/user/action'

/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 */
const columns = [
  {
    attribute: 'name',
    label: translator._('user.name'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    renderer: entity => {
      return (
        <Typography variant="body1" noWrap={false} style={{ fontSize: 13 }}>
          {entity.name + ' ' + entity.surName}{' '}
        </Typography>
      )
    },
    actions: null,
  },
  {
    attribute: 'email',
    label: translator._('user.email'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    renderer: null,
    actions: null,
  },
  {
    attribute: 'userName',
    label: translator._('general.username'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    renderer: null,
    actions: null,
  },
  {
    attribute: 'isInvited',
    label: translator._('user.invited'),
    type: ListColumnType.BOOLEAN,
    sortable: false,
    width: '60px',
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'lastActDate',
    label: translator._('user.loggedIn'),
    type: ListColumnType.DATE,
    sortable: true,
    width: '150px',
    converter: { input: ConverterDateToLongFormat },
    suffix: null,
    actions: null,
    renderer: null,
  },
  {
    attribute: 'isActive',
    label: translator._('general.isActive'),
    type: ListColumnType.BOOLEAN,
    sortable: true,
    width: '60px',
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: '',
    label: null,
    type: ListColumnType.ACTION,
    sortable: false,
    width: '50px',
    converter: null,
    suffix: null,
    renderer: null,
    actions: [
      {
        label: translator._('action.edit'),
        icon: EditIcon,
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(userGetAction(entity.id))
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },

      {
        label: translator._('action.delete'),
        icon: DeleteIcon,
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            messageBoxShowAction(
              new MessageBoxContent(
                MessageBoxType.WARNING,
                translator._('message.delete'),
                [
                  new ButtonData(
                    ButtonType.NORMAL,
                    translator._('action.no'),
                    ButtonActionType.NO,
                    () => {
                      dispatch(messageBoxHideAction())
                    },
                  ),
                  new ButtonData(
                    ButtonType.ACTION,
                    translator._('action.yes'),
                    ButtonActionType.DELETE,
                    () => {
                      dispatch(
                        userDeleteAction(entity.id, () => {
                          dispatch(messageBoxHideAction())
                          refresh()
                        }),
                      )
                    },
                  ),
                ],
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },
]

export default columns
