import React, { useState } from 'react'
import translator from 'src/translate'
import { assignmentPatchAction } from 'src/reducers/assignment/action'
import { AssignmentFieldNames } from '../../model/assignment'
import styles from './styles'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { PatchContent, PatchData } from 'src/model/patch'
import {
  Button,
  Divider,
  TextField,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core'

import URL from 'src/api/url'

const Instructions = props => {
  const { dispatch, classes } = props

  return (
    <div className={classes.popUp}>
      <Box m={3}></Box>
      <Typography variant="h6">AI Assistant Instructions</Typography>
      <Box m={1}></Box>
      <Typography>
        To get the most targeted results from AI-Assistant, take a look at the
        following instructions and example cases below:
      </Typography>
      <Box m={1}></Box>
      <Typography variant="subtitle2">1. Course Length </Typography>
      <div className={classes.textFirst}>
        <Typography>
          After the research and regarding the experts' opinions, we have deemed
          the appropriate course length should be between four and eight cards.
        </Typography>
        <Typography>
          Example:
          <div>
            <a href={URL.APP_URL + 'ZLuUigoi1k6Vdht2J-LWRQ/1'} target="_blank">
              Artificial Intelligent
            </a>
            <br />
            <a href={URL.APP_URL + 'PkfT8TLLkU-vxM_GgJMdIw/1'} target="_blank">
              Roman Empire
            </a>
          </div>
        </Typography>
      </div>

      <Box m={1}></Box>
      <Typography variant="subtitle2">2. The Topic</Typography>
      <div className={classes.textFirst}>
        <Typography>
          You should spell the words correctly. If you use a misspelled word in
          your topic, this will affect your entire AI-generated course. <br />
        </Typography>
        <Typography>
          Example:
          <div>
            <a href={URL.APP_URL + '1Ta4Yio3OUC58ijvMfD_Lg/1'} target="_blank">
              Lige Coaching
            </a>
            <br />
            <a href={URL.APP_URL + 'a_8U0UuAXUi21ZFBU4vDgQ/1'} target="_blank">
              Life Couching
            </a>
            <br />
            <a href={URL.APP_URL + 'dCGUdCpdQEiLGrx0Zrz6OQ/1'} target="_blank">
              Life Coaching
            </a>
          </div>
        </Typography>
        <Typography>
          It would be best if you used shorter topics. Based on the courses
          created by our users, it is safe to say that shorter and broader
          topics will get you better returns. Topics that are too narrow tend to
          go out of hand.
          <Typography>
            Example:
            <div>
              <a
                href={URL.APP_URL + 'KSjVhd7kcEmdTYMB7I-Ozg/1'}
                target="_blank"
              >
                The importance of reliability and customer satisfaction in real
                estate
              </a>
              <br />
              <a
                href={URL.APP_URL + 'dHuMAOPEp0qaSDHcYFgf9w/1'}
                target="_blank"
              >
                The importance of reliability in real estate
              </a>
              <br />
              <a
                href={URL.APP_URL + 'eGmWqkl100yW3jbk2QmkYg/1'}
                target="_blank"
              >
                The importance of customer satisfaction in real estate
              </a>
            </div>
          </Typography>
          You should mind the topic language. The default language of the
          courses is English. If you want to search for a topic in another
          language, it could return duplicated or nonsense courses.
          <Typography>
            Example:
            <div>
              <a
                href={URL.APP_URL + 'DTf9D8Sof06U-ihapNFBDw/1'}
                target="_blank"
              >
                l'effet de la musique sur les enfants
              </a>
              <br />
              <a
                href={'https://share.quizcube.io/khvbG6fwfEWGxVcIJhPIpg'}
                target="_blank"
              >
                Effect of Music on Children
              </a>
            </div>
          </Typography>
        </Typography>
      </div>
      <Box m={1}></Box>
      <Typography variant="subtitle2">3. The Course Created with AI</Typography>
      <div className={classes.textFirst}>
        <Typography>
          The AI-generated mini-course will include sample header images, info
          cards, and quiz cards. The content is plagiarism-free, yet, you can
          edit the raw material further according to your needs. After you
          create them, AI-generated mini-courses are not different from your
          manually created courses in practice.
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(Instructions)
