const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 'auto',
    },
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  centerBoxGrid: {
    textAlign: 'center',
    maxWidth: 700,
    margin: '50px auto',
  },
  maskot: {
    height: 125,
  },
  logoHolder: {
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  logo: {
    height: 30,
  },
  logoApp: {
    height: 26,
    paddingTop: 2,
  },
  line: {
    height: 20,
    width: 2,
    backgroundColor: '#efefef',
    display: 'inline-block',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
  },
  info1: {
    textAlign: 'center',
  },
  info2: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    width: '60%',
  },
  storeHolder: { marginTop: 10 },
  appStore: { marginRight: 10 },
  input: {
    width: '80%',
    border: '1px solid grey',
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    marginLeft: '10%',
    marginBottom: theme.spacing(3),
  },
  invalidCheck: {
    color: '#d3d3d3',
  },
  validCheck: {
    color: '#4caf50',
  },
  passwordRequirements: {
    color: '#4a4a4a',
    textAlign: 'center',
    display: 'block',
    marginTop: theme.spacing(2),
  },
  invalid: {
    color: '#d3d3d3',
    padding: theme.spacing(1),
    margin: 3,
    border: '1px solid #d3d3d3',
    fontSize: 11,
    borderRadius: 5,
  },
  valid: {
    color: '#ffffff',
    padding: theme.spacing(1),
    margin: 3,
    border: '1px solid #d3d3d3',
    backgroundColor: '#4caf50',
    fontSize: 11,
    borderRadius: 5,
  },
  strengthMeter: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '100%',
    maxHeight: '100%',
    padding: 10,
    boxShadow: theme.shadows[25].primary,
  },
  div: {
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
})

export default styles
