import { put, call, take, fork } from 'redux-saga/effects'
import { questionReportImprovementService } from '../../api/question'
import { questionReportImprovementSuccess } from '../../reducers/question/action'
import { QUESTION_REPORT_IMPROVEMENT } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionReportImprovementFlow(payload) {
  try {
    const { reqParams, parentId, callback } = payload

    const response = yield call(questionReportImprovementService, reqParams)

    yield put(questionReportImprovementSuccess(response.data, parentId))
    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionReportImprovementWatcher() {
  while (true) {
    const action = yield take(QUESTION_REPORT_IMPROVEMENT)
    const { payload } = action

    yield fork(generalSaga, questionReportImprovementFlow, payload)
  }
}
