import React from 'react'
import style from './style'
import { withStyles, Button, Typography } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { AnswerItemType } from 'src/model/question'
import { AssignmentFieldNames } from 'src/model/assignment'

import {
  assignmentUpdateAttribute,
  assignmentEditAction,
} from 'src/reducers/assignment/action'
import { questionAddAction } from 'src/reducers/question/action'
import { getQuestionTypeParam, QuestionSchema } from 'src/model/question'
const LastCardAddingField = props => {
  const { setPage, list, courseId, onAddCard, dispatch, classes } = props

  const saveType = async selectedType => {
    let entity = QuestionSchema()
    let list = []
    entity = getQuestionTypeParam(selectedType, entity)
    await dispatch(
      questionAddAction(entity, courseId, response => {
        let newTaskObject = { ...response, isNewAdded: true }
        list.push(newTaskObject)
        onAddCard(list, sortCard)
      }),
    )
  }

  //reOrderToNewAdded(cardCount, index + 1, newEntity)

  const sortCard = (newEntity, hasFinishCard) => {
    reOrderToNewAdded(list.length, list.length, newEntity)
    setPage(list.length + 1)
  }

  const reOrderToNewAdded = (oldIndex, newIndex, courseEntity) => {
    const list = courseEntity[AssignmentFieldNames.QUESTION_DTOS]

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    if (newIndex !== null) {
      let items = reorder(list, oldIndex, newIndex)

      // TODO: autosenderId için daha temiz ve garanti bir yol olmalı
      // TODO: backendde de autosenderId check edilmeli
      let letNewRelationList = items.map((x, index) => {
        return {
          courseId: window.location.pathname.split('/')[2],
          cardId: x.id,
          orderOfCard: index,
        }
      })

      items.forEach((value, index) => {
        items[index].orderOfCard = index
      })
      let lastEntity = {
        ...courseEntity,
        [AssignmentFieldNames.QUESTION_DTOS]: items,
        [AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS]: letNewRelationList,
      }

      dispatch(assignmentEditAction(lastEntity, () => {}))

      dispatch(
        assignmentUpdateAttribute([
          {
            path: AssignmentFieldNames.QUESTION_DTOS,
            value: items,
          },
          {
            path: AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS,
            value: letNewRelationList,
          },
        ]),
      )
    }
  }
  return (
    <div className={classes.addLastCard}>
      <div className={classes.addLastCardContent}>
        <div className={classes.addLastCardContentHeader}>
          <img src="/assets/completion.svg" className={classes.completionSvg} />
          <Typography className={classes.headerTexts} fontSize={14}>
            Completion
          </Typography>
        </div>
        <div className={classes.addLastCardContentHeader}>
          <Typography className={classes.subHeaderTexts} fontSize={14}>
            Select card type
          </Typography>
        </div>
        <div className={classes.addLastCardContentButtonsDiv}>
          <Button
            className={classes.addLastCardContentButtons}
            startIcon={<Icon icon="solar:heart-outline" />}
            variant="outlined"
            onClick={() => {
              saveType([AnswerItemType.RESULTDEFAULT])
            }}
          >
            Thank you
          </Button>
          <Button
            className={classes.addLastCardContentButtons}
            variant="outlined"
            startIcon={<Icon icon="octicon:number-16" />}
            onClick={() => {
              saveType([AnswerItemType.SCORECARD])
            }}
          >
            Score / Grading
          </Button>
          <Button
            variant="outlined"
            className={classes.addLastCardContentButtons}
            startIcon={<Icon icon="lucide:bookmark-check" />}
            onClick={() => {
              saveType([AnswerItemType.RESULT])
            }}
          >
            Conditional{' '}
          </Button>
          <Button
            variant="outlined"
            className={classes.addLastCardContentButtons}
            startIcon={<img src="/assets/sertifier.svg" />}
            onClick={() => {
              saveType([AnswerItemType.SERTIFIERCARD])
            }}
          >
            Sertifier
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withStyles(style)(LastCardAddingField)
