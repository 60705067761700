import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import ListSchema from './list'
import List from '../list'
import { ListUseModeType, ListItemViewType } from '../../model/list'
import { Typography } from '@material-ui/core'
import translator from 'src/translate'

class ListUser extends Component {
  static defaultProps = {
    hideTopBar: false,
    hideSearchBar: false,
    showPaging: true,
    showSelect: true,
    showChildList: false,
    usageMode: ListUseModeType.LIST,
    showDetailSearch: false,
    itemViewType: ListItemViewType.AVATAR,

    query: {
      CurrentPage: 0,
      PageSize: 10,
      SortingColumn: 'lastActDate',
      Asc: false,
      FilterText: '',
      Filters: [],
    },
  }

  /**
   *
   */
  render() {
    let newQuery = this.props.query
    if (this.props.teamId) {
      newQuery.Filters = [
        {
          FilteredField: 'TeamId',
          TeamId: this.props.teamId,
          AndOr: 'and',
        },
      ]
    } else {
      newQuery.Filters = []
    }
    return (
      <div
        style={{
          width: 700,
        }}
      >
        <Typography variant="h6">
          {translator._('assignment.selectAssignees')}
        </Typography>
        <List
          list={ListSchema}
          disabled={false}
          {...this.props}
          query={newQuery}
        />
      </div>
    )
  }
}

ListUser.propTypes = propTypes

const mapStateToProps = response => ({
  queryResult: response.user.list,
})

export default connect(mapStateToProps)(ListUser)
