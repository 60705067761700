import React from 'react'
import style from '../style'
import propTypes from '../prop'
import ReadJSONPathValue from '../../../../utils/read-json-path'
import isEmpty from '../../../../utils/is-empty'
import { withStyles, TableCell, Typography } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const ListContainerColumnNormal = props => {
  const { classes, column, entity } = props
  let value = ReadJSONPathValue(entity, column.attribute)

  if (column.converter) {
    value = column.converter.input(value)
  }

  if (isEmpty(value)) {
    value = '-'
  }

  return (
    <TableCell
      className={classes.root}
      style={{ width: column.width, textAlign: 'center' }}
    >
      <Typography
        variant="body1"
        noWrap={false}
        className={classes.fontSizeNormal}
      >
        {value.toLocaleString()}
      </Typography>
    </TableCell>
  )
}

ListContainerColumnNormal.propTypes = propTypes

export default withStyles(style)(ListContainerColumnNormal)
