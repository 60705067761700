import React from 'react'
import style from '../body/style'
import { ButtonType } from '../../../../model/button'
import { useHistory } from 'react-router-dom'
import { withStyles, Menu, MenuItem, Link } from '@material-ui/core'

const initialState = {
  mouseX: null,
  mouseY: null,
}

/**
 *
 * @param {*} props
 */
const RowMenuAction = props => {
  const {
    classes,
    column,
    entity,
    dispatch,
    refresh,
    anchorPosition,
    handleRowMenuClose,
  } = props

  let history = useHistory()

  /**
   *
   * @param {*} exec
   */
  const menuItemClickHandler = exec => {
    exec(entity, dispatch, refresh)

    handleRowMenuClose()
  }

  const menuItemLinkClickHandler = link => {
    history.push(link)
  }

  /**
   *
   * @param {*} action
   * @param {*} index
   */
  const renderMenuItem = (action, index) => {
    if (!action.hidden(entity)) {
      return (
        <MenuItem
          key={index}
          onClick={() => {
            menuItemClickHandler(action.exec)
          }}
          disabled={action.disabled(entity)}
        >
          {action.label}
        </MenuItem>
      )
    }
  }

  /**
   *
   * @param {*} action
   * @param {*} index
   */
  const renderLink = (action, index) => {
    return (
      <MenuItem
        key={index}
        component={Link}
        onClick={() => {
          menuItemLinkClickHandler(action.link(entity))
        }}
        //href={action.link(entity)}
        disabled={action.disabled(entity)}
      >
        {action.label}
      </MenuItem>
    )
  }

  return (
    <React.Fragment>
      <Menu
        open={anchorPosition.mouseY !== null && anchorPosition.id == entity.id}
        onClose={handleRowMenuClose}
        anchorReference="anchorPosition"
        disableScrollLock
        anchorPosition={
          anchorPosition.mouseY !== null && anchorPosition.mouseX !== null
            ? {
                top: anchorPosition.mouseY,
                left: anchorPosition.mouseX,
              }
            : undefined
        }
      >
        {column.actions.map((action, index) => {
          let element = ''
          switch (action.type) {
            case ButtonType.NORMAL:
              element = renderMenuItem(action, index)
              break
            case ButtonType.LINK:
              element = renderLink(action, index)
              break
            default:
              element = ''
              break
          }

          return element
        })}
      </Menu>
    </React.Fragment>
  )
}

//RowMenuAction.propTypes = propTypes

export default withStyles(style)(RowMenuAction)
