import translator from '../../../translate'
import { ButtonType } from '../../../model/button'
import Attachment from '@material-ui/icons/Attachment'
import { courseStatisticsReportAction } from '../../../reducers/course-statistics/action'

/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 */
const actions = [
  {
    label: translator._('action.excel.Export'),
    items: [
      {
        label: translator._('action.excel.export'),
        type: ButtonType.NORMAL,
        icon: Attachment,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(courseStatisticsReportAction())
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },
]

export default actions
