import translator from '../../../translate'
import { userUpdateEntityAction } from '../../../reducers/user/action'
import { UserSchema } from '../../../model/user'
import AddIcon from '@material-ui/icons/Add'

/**
 *
 */
const search = [
  {
    label: translator._('action.add'),
    icon: AddIcon,
    link: null,
    exec: (entity, dispatch, refresh) => {
      dispatch(userUpdateEntityAction(UserSchema))
    },
    disabled: entity => {
      return false
    },
    hidden: entity => {
      return false
    },
  },
]
export default search
