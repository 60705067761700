import {
  MEDIA_DIRECTORY_GET_ROOT_SUCCESS,
  MEDIA_DIRECTORY_GET_SUCCESS,
} from './type'

export default function (
  state = {
    entity: null,
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case MEDIA_DIRECTORY_GET_ROOT_SUCCESS:
      if (payload.entity == null) {
        return {
          ...state,
          entity: null,
        }
      }

      if (state.entity == null) {
        state.entity = {}
      }

      return {
        ...state,
        entity: {
          ...state.entity,
          ...payload.entity,
        },
      }
    case MEDIA_DIRECTORY_GET_SUCCESS:
      if (payload.entity == null) {
        return {
          ...state,
          entity: null,
        }
      }

      if (state.entity == null) {
        state.entity = {}
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          ...payload.entity,
        },
      }
    default:
      return state
  }
}
