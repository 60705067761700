import { put, call, take, fork } from 'redux-saga/effects'
import { getCollectionSettingsService } from '../../api/course-collection'
import { getCollectionSettingsSuccess } from '../../reducers/course-collection/action'
import { GET_COLLECTION_SETTINGS } from '../../reducers/course-collection/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* getCollectionSettingsFlow(payload) {
  try {
    const { id, callback } = payload

    const response = yield call(getCollectionSettingsService, id)

    yield put(getCollectionSettingsSuccess(response.data))

    yield put(operationSuccess())
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* getCollectionSettingsWatcher() {
  while (true) {
    const action = yield take(GET_COLLECTION_SETTINGS)
    const { payload } = action

    yield fork(generalSaga, getCollectionSettingsFlow, payload)
  }
}
