import React from 'react'
import styles from './style'
import propTypes from './prop'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import translator from '../../../translate'
import { ButtonData, ButtonType, ButtonActionType } from '../../../model/button'
import { withStyles, Chip } from '@material-ui/core'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../reducers/message-box/action'

/**
 *
 * @param {*} props
 */
const TeamItem = props => {
  const {
    dispatch,
    entity,
    classes,
    onRemove,
    parentItemName,
    itemName,
  } = props

  /**
   *
   * @param {*} e
   */
  const onRemoveClick = e => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          translator._('message.delete'),
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              e => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.yes'),
              ButtonActionType.DELETE,
              e => {
                dispatch(messageBoxHideAction())

                onRemove(entity)
              },
            ),
          ],
        ),
      ),
    )
  }

  /**
   *
   * @param {*} attributeName
   * @param {*} node
   * @param {*} result
   */
  const findParentTeam = (attributeName, node, result) => {
    if (node[attributeName] != null) {
      result.push(node[attributeName].name)
      findParentTeam(attributeName, node[attributeName], result)
      return result
    } else {
      return result
    }
  }

  /**
   *
   */
  const renderPath = () => {
    let name = []
    let node

    if (itemName != null && entity[itemName] != null) {
      name.push(entity[itemName].name)
      node = entity[itemName]
    } else {
      name.push(entity.name)
      node = entity
    }

    let path = findParentTeam(parentItemName, node, name)

    return path.reverse().join(' / ')
  }

  return (
    <Chip
      label={renderPath()}
      className={classes.chip}
      onDelete={onRemove == null ? null : onRemoveClick}
    />
  )
}

TeamItem.propTypes = propTypes

export default withStyles(styles)(TeamItem)
