import translator from 'src/translate'
import { withStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import styles from '../styles'
import {
  Typography,
  TextField,
  Box,
  Button,
  Alert,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import { useDispatch } from 'react-redux'
import {
  useCourseSettings,
  createSetting,
  patchSetting,
} from 'src/hooks/useCourseSettings'
import { Icon } from '@iconify/react'
import heartOutline from '@iconify-icons/eva/heart-outline'
import {
  AssignmentFieldNames,
  AssignmentInitialCourseSettings,
} from 'src/model/assignment'
import URL from 'src/api/url/api'
import { showModal } from 'src/components/upgrade-popup'
import UserType, { UserTypeNames } from 'src/model/user-types'

const SETTING_KEY = 'general'

const AdvancedSettings = props => {
  const { classes, entity, setSettingDrawerOpen } = props
  const dispatch = useDispatch()

  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)

  const [loading, setLoading] = useState(false)

  const isUserPremium =
    UserType[window.CONSTANTS.USER_ROLE_ID] !==
      UserTypeNames.REGULAR_ADMIN_ACCOUNT &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.PREMIUM &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.PREMIUM_V2 &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1 &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1A

  const [thumbnailUrl, setThumbnailUrl] = useState(exists ? value.logo : '')

  const [headerScript, setHeaderScript] = useState(
    exists ? value.headerScript : '',
  )

  const [bodyScript, setBodyScript] = useState(exists ? value.bodyScript : '')
  const [sendEmailOnFinish, setSendEmailOnFinish] = useState(
    exists && value.sendEmailOnFinish,
  )

  const handleOpenPremiumDetails = () => {
    showModal(dispatch) // premium details
  }

  const saveSettings = () => {
    const variables = {
      ...value,
      logo: thumbnailUrl,
      headerScript: headerScript,
      bodyScript: bodyScript,
      sendEmailOnFinish: sendEmailOnFinish,
    }
    if (!exists) {
      // create a setting entity with execute function
      execute(variables, () => {
        setLoading(false)
        setSettingDrawerOpen(false)
      })
    } else {
      update(variables, () => {
        setLoading(false)
        setSettingDrawerOpen(false)
      })
    }
  }
  return (
    <>
      <form>
        <div className={classes.content}>
          <Box m={2}></Box>
          <TextField
            multiline
            rows="5"
            placeholder={translator._('assignment.courseSettings.headerText')}
            id="headerScript"
            label={translator._('assignment.courseSettings.headerScript')}
            variant="outlined"
            defaultValue={headerScript}
            onBlur={event => setHeaderScript(event.target.value)}
            disabled={isUserPremium ? false : true}
          />
          <Box m={1}></Box>
          <TextField
            multiline
            rows="5"
            placeholder={translator._('assignment.courseSettings.bodyText')}
            id="headerScript"
            label={translator._('assignment.courseSettings.bodyScript')}
            variant="outlined"
            defaultValue={bodyScript}
            onBlur={event => setBodyScript(event.target.value)}
            disabled={isUserPremium ? false : true}
          />
          <Box m={1}></Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch id="sendEmailOnFinish" checked={sendEmailOnFinish} />
              }
              label="Notify by email when someone completes"
              onChange={event => setSendEmailOnFinish(event.target.checked)}
              disabled={isUserPremium ? false : true}
            />
          </FormGroup>
          <Box m={1}></Box>
          {loading ? (
            <div className={classes.bottomButton}>
              <CircularProgress></CircularProgress>
            </div>
          ) : isUserPremium ? (
            <div className={classes.bottomButton}>
              <Button
                fullWidth={true}
                color="secondary"
                size="small"
                variant="contained"
                onClick={saveSettings}
                className={classes.previewBtn}
              >
                {translator._('assignment.courseSettings.save')}
              </Button>
            </div>
          ) : (
            <div className={classes.bottomButton}>
              <Button
                fullWidth={true}
                to="/pricing"
                color="primary"
                variant="contained"
                startIcon={
                  <Icon
                    size="small"
                    icon="fa6-solid:crown"
                    color="#f7b155"
                  ></Icon>
                }
                component={RouterLink}
              >
                Upgrade to Edit
              </Button>
            </div>
          )}
        </div>
      </form>
      {/*{!isUserPremium && (
        <>
          <div className={classes.premiumContainer}>
            <img src="/assets/premium.gif" alt="premium" width="128" />
            <Alert severity="warning">
              You must be a premium to use this feature.
            </Alert>
            <Button
              variant="contained"
              style={{ marginTop: '1rem' }}
              onClick={handleOpenPremiumDetails}
            >
              Upgrade your Plan
            </Button>
          </div>
        </>
      )}
      */}
    </>
  )
}

export default withStyles(styles)(AdvancedSettings)
