import React from 'react'
import ReactGiphySearchbox from 'react-giphy-searchbox'

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key

const GiphyGrid = props => {
  const { onGifClick, classes } = props
  return (
    <div className={classes.giphyPanel}>
      <ReactGiphySearchbox
        apiKey="LaIbGHZnlC4bSEF6DolnJyUfaOwOAV6D" // Required: get your on https://developers.giphy.com
        onSelect={item => onGifClick(item)}
        masonryConfig={[
          { columns: 4, imageWidth: 110, gutter: 5 },
          { mq: '700px', columns: 7, imageWidth: 120, gutter: 4 },
        ]}
        poweredByGiphy={false}
      />
    </div>
  )
}

export default GiphyGrid
