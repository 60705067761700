import {
  ADD_CUSTOM_DOMAIN_SUCCESS,
  DOMAIN_LIST,
  DOMAIN_LIST_SUCCESS,
  DOMAIN_DELETE_SUCCESS,
} from './type'

export default function (
  state = {
    list: { results: null, totalCount: 0 },
    query: null,
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case DOMAIN_LIST:
      return { ...state, query: payload.query }
    case ADD_CUSTOM_DOMAIN_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          results: [...state.list.results, payload.response.data],
        },
      }
    case DOMAIN_DELETE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          results: state.list.results.filter(
            item => item.id !== payload.response,
          ),
        },
      }
    case DOMAIN_LIST_SUCCESS:
      return { ...state, list: payload.response }
    default:
      return state
  }
}
