import { put, call, take, fork } from 'redux-saga/effects'
import { domainRetryService } from '../../api/domain'
import { domainRetrySuccess } from '../../reducers/domain/action'
import { DOMAIN_RETRY } from '../../reducers/domain/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* domainRetryFlow(payload) {
  try {
    const { id, callback } = payload

    yield call(domainRetryService, id)
    yield put(domainRetrySuccess())
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* domainRetryWatcher() {
  while (true) {
    const action = yield take(DOMAIN_RETRY)
    const { payload } = action

    yield fork(generalSaga, domainRetryFlow, payload)
  }
}
