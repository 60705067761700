import { blue } from '@material-ui/core/colors'

const style = theme => ({
  listContainer: {
    width: '100%',
  },
  listItem: {
    borderRadius: 16,
    marginBottom: 24,
    overflow: 'hidden',
  },
  headerRow: {
    backgroundColor: '#F4F6F8',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  tag: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  headerClickable: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: theme.spacing(2),
    verticalAlign: 'center',
    cursor: 'pointer',
    maxWidth: 600,
  },
  tagGroup: {
    padding: theme.spacing(2),
  },
  buttonGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
    },
  },
  buttonWithIcon: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
  actionIcon: {
    color: blue[500],
  },
  smallIcon: {
    verticalAlign: 'middle',
    marginRight: 5,
  },
  infoField: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  infoFieldTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoFieldText: {},
})

export default style
