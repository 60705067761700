import { PROCESS_START, PROCESS_END } from './type'

export default function (
  state = {
    working: false,
  },
  action,
) {
  switch (action.type) {
    case PROCESS_START:
      return { ...state, working: true }
    case PROCESS_END:
      return { ...state, working: false }
    default:
      return state
  }
}
