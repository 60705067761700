const GET_FILTER_NAMES = 'GET_FILTER_NAMES'
const GET_FILTER_NAMES_SUCCESS = 'GET_FILTER_NAMES_SUCCESS'
const GET_FILTER_NAMES_ERROR = 'GET_FILTER_NAMES_ERROR'

const EXCEL_IMPORT_GET_FORM = 'EXCEL_IMPORT_GET_FORM'
const EXCEL_IMPORT_GET_FORM_SUCCESS = 'EXCEL_IMPORT_GET_FORM_SUCCESS'
const EXCEL_IMPORT_GET_FORM_ERROR = 'EXCEL_IMPORT_GET_FORM_ERROR'

const EXCEL_IMPORT_USERS = 'EXCEL_IMPORT_USERS'
const EXCEL_IMPORT_USERS_SUCCESS = 'EXCEL_IMPORT_USERS_SUCCESS'
const EXCEL_IMPORT_USERS_ERROR = 'EXCEL_IMPORT_USERS_ERROR'

const EXCEL_EXPORT_USERS = 'EXCEL_EXPORT_USERS'
const EXCEL_EXPORT_USERS_SUCCESS = 'EXCEL_EXPORT_USERS_SUCCESS'
const EXCEL_EXPORT_USERS_ERROR = 'EXCEL_EXPORT_USERS_ERROR'

const EXCEL_EXPORT_USER_MESSAGES = 'EXCEL_EXPORT_USER_MESSAGES'
const EXCEL_EXPORT_USER_MESSAGES_SUCCESS = 'EXCEL_EXPORT_USER_MESSAGES_SUCCESS'
const EXCEL_EXPORT_USER_MESSAGES_ERROR = 'EXCEL_EXPORT_USER_MESSAGES_ERROR'

const EXCEL_EXPORT_ALL_USERS = 'EXCEL_EXPORT_ALL_USERS'
const EXCEL_EXPORT_ALL_USERS_SUCCESS = 'EXCEL_EXPORT_ALL_USERS_SUCCESS'
const EXCEL_EXPORT_ALL_USERS_ERROR = 'EXCEL_EXPORT_ALL_USERS_ERROR'

const EXCEL_IMPORT_QUESTIONS = 'EXCEL_IMPORT_QUESTIONS'
const EXCEL_IMPORT_QUESTIONS_SUCCESS = 'EXCEL_IMPORT_QUESTIONS_SUCCESS'
const EXCEL_IMPORT_QUESTIONS_ERROR = 'EXCEL_IMPORT_QUESTIONS_ERROR'

const EXCEL_IMPORT_QUESTIONS_GET_FORM = 'EXCEL_IMPORT_QUESTIONS_GET_FORM'
const EXCEL_IMPORT_QUESTIONS_GET_FORM_SUCCESS =
  'EXCEL_IMPORT_QUESTIONS_GET_FORM_SUCCESS'
const EXCEL_IMPORT_QUESTIONS_GET_FORM_ERROR =
  'EXCEL_IMPORT_QUESTIONS_GET_FORM_ERROR'

const SEND_ACTIVATION_SMS = 'SEND_ACTIVATION_SMS'
const SEND_ACTIVATION_SMS_SUCCESS = 'SEND_ACTIVATION_SMS_SUCCESS'
const SEND_ACTIVATION_SMS_ERROR = 'SEND_ACTIVATION_SMS_ERROR'

const SEND_ACTIVATION_EMAIL = 'SEND_ACTIVATION_EMAIL'
const SEND_ACTIVATION_EMAIL_SUCCESS = 'SEND_ACTIVATION_EMAIL_SUCCESS'
const SEND_ACTIVATION_EMAIL_ERROR = 'SEND_ACTIVATION_EMAIL_ERROR'

const SEND_MAGIC_LINK_VIA_EMAIL = 'SEND_MAGIC_LINK_VIA_EMAIL'
const SEND_MAGIC_LINK_VIA_EMAIL_SUCCESS = 'SEND_MAGIC_LINK_VIA_EMAIL_SUCCESS'
const SEND_MAGIC_LINK_VIA_EMAIL_ERROR = 'SEND_MAGIC_LINK_VIA_EMAIL_ERROR'

const GET_CONSTANTS = 'GET_CONSTANTS'
const GET_CONSTANTS_SUCCESS = 'GET_CONSTANTS_SUCCESS'
const GET_CONSTANTS_ERROR = 'GET_CONSTANTS_ERROR'

const APPSUMO_CODE_CONFIRM = 'APPSUMO_CODE_CONFIRM'
const APPSUMO_CODE_CONFIRM_SUCCESS = 'APPSUMO_CODE_CONFIRM_SUCCESS'
const APPSUMO_CODE_CONFIRM_ERROR = 'APPSUMO_CODE_CONFIRM_ERROR'

const APPSUMO_CODE_REFUND = 'APPSUMO_CODE_REFUND'
const APPSUMO_CODE_REFUND_SUCCESS = 'APPSUMO_CODE_REFUND_SUCCES'
const APPSUMO_CODE_REFUND_ERROR = 'APPSUMO_CODE_REFUND_ERROR'

export {
  GET_FILTER_NAMES,
  GET_FILTER_NAMES_SUCCESS,
  GET_FILTER_NAMES_ERROR,
  EXCEL_IMPORT_GET_FORM,
  EXCEL_IMPORT_GET_FORM_SUCCESS,
  EXCEL_IMPORT_GET_FORM_ERROR,
  EXCEL_IMPORT_USERS,
  EXCEL_IMPORT_USERS_SUCCESS,
  EXCEL_IMPORT_USERS_ERROR,
  EXCEL_IMPORT_QUESTIONS,
  EXCEL_IMPORT_QUESTIONS_SUCCESS,
  EXCEL_IMPORT_QUESTIONS_ERROR,
  EXCEL_EXPORT_USERS,
  EXCEL_EXPORT_USERS_SUCCESS,
  EXCEL_EXPORT_USERS_ERROR,
  EXCEL_EXPORT_USER_MESSAGES,
  EXCEL_EXPORT_USER_MESSAGES_SUCCESS,
  EXCEL_EXPORT_USER_MESSAGES_ERROR,
  EXCEL_EXPORT_ALL_USERS,
  EXCEL_EXPORT_ALL_USERS_SUCCESS,
  EXCEL_EXPORT_ALL_USERS_ERROR,
  EXCEL_IMPORT_QUESTIONS_GET_FORM,
  EXCEL_IMPORT_QUESTIONS_GET_FORM_SUCCESS,
  EXCEL_IMPORT_QUESTIONS_GET_FORM_ERROR,
  SEND_ACTIVATION_SMS,
  SEND_ACTIVATION_SMS_SUCCESS,
  SEND_ACTIVATION_SMS_ERROR,
  SEND_ACTIVATION_EMAIL,
  SEND_ACTIVATION_EMAIL_SUCCESS,
  SEND_ACTIVATION_EMAIL_ERROR,
  SEND_MAGIC_LINK_VIA_EMAIL,
  SEND_MAGIC_LINK_VIA_EMAIL_SUCCESS,
  SEND_MAGIC_LINK_VIA_EMAIL_ERROR,
  GET_CONSTANTS,
  GET_CONSTANTS_SUCCESS,
  GET_CONSTANTS_ERROR,
  APPSUMO_CODE_CONFIRM,
  APPSUMO_CODE_CONFIRM_SUCCESS,
  APPSUMO_CODE_CONFIRM_ERROR,
  APPSUMO_CODE_REFUND,
  APPSUMO_CODE_REFUND_SUCCESS,
  APPSUMO_CODE_REFUND_ERROR,
}
