import React from 'react'
import propTypes from './prop'
import ItemLayoutForm from './form'
import ItemLayoutSearch from './search'
import { FormUseModeType } from '../../../../form/props'

const LayoutForm = props => {
  const { formItem } = props

  return (
    <React.Fragment>
      {(() => {
        switch (formItem.useMode) {
          case FormUseModeType.EDIT:
            return <ItemLayoutForm {...props} />
          case FormUseModeType.SEARCH:
            return <ItemLayoutSearch {...props} />
          default:
            return ''
        }
      })()}
    </React.Fragment>
  )
}

LayoutForm.propTypes = propTypes

export default LayoutForm
