import React from 'react'
import styles from './styles'
import { Typography, withStyles, Button } from '@material-ui/core'
import { exportCourseToScormAction } from 'src/reducers/assignment/action'
const ScormDownloadField = props => {
  const { title, dispatch, entity, type, classes } = props
  const [onHover, setOnHover] = React.useState(false)
  const handleDownload = () => {
    dispatch(
      exportCourseToScormAction(entity.name, entity.id, type, response => {}),
    )
  }
  return (
    <div
      style={{
        backgroundColor: onHover ? 'rgba(246, 123, 63, 0.08)' : '#fff',
        cursor: onHover ? 'pointer' : 'default',
      }}
      className={classes.scormDownloadFieldDiv}
      onMouseEnter={() => {
        setOnHover(true)
      }}
      onMouseLeave={() => {
        setOnHover(false)
      }}
      onClick={() => handleDownload()}
    >
      <img src="/assets/csv.svg" width={40} height={45} />
      <Typography fontSize={'16px'} fontWeight={500}>
        {title}
      </Typography>
      <Button
        style={{
          outline: !onHover && '1px solid rgba(145, 158, 171, 0.50)',
          color: onHover ? '#fff' : '#637381',
          backgroundColor: onHover ? '#F67B3F' : '#fff',
        }}
        className={classes.downloadButton}
      >
        Download
      </Button>
    </div>
  )
}

export default withStyles(styles)(ScormDownloadField)
