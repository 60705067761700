import {
  TEAMS_USER_LIST_SUCCESS,
  TEAM_LIST_SUCCESS,
  ADD_TEAM_SUCCESS,
  PATCH_TEAM_SUCCESS,
  DELETE_TEAM_SUCCESS,
  TEAMS_MEMBER_LIST_SUCCESS,
  TEAMS_MEMBER_DELETE_SUCCESS,
  TEAMS_MEMBER_PATCH_SUCCESS,
  TEAMS_MEMBER_ADD_SUCCESS,
  TEAMS_MEMBER_IMPORT_SUCCESS,
} from './type'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = {
    teamList: null,
    memberList: { results: null, totalCount: 0, teamName: null },
  },
  action,
) {
  const { payload, type } = action
  switch (type) {
    case TEAMS_USER_LIST_SUCCESS:
      return { ...state, userListList: payload.response }
    case TEAM_LIST_SUCCESS:
      return { ...state, teamList: payload.response }

    case ADD_TEAM_SUCCESS:
      return {
        ...state,
        teamList: [...state.teamList, payload.response],
      }

    case PATCH_TEAM_SUCCESS:
      const patchedItemId = payload.content._id

      const teamList = state.teamList.map(item => {
        if (item.id === patchedItemId) {
          item[payload.content._contents[0]._path] =
            payload.content._contents[0]._value
          return item
        }
        return item
      })
      return { ...state, teamList: teamList }

    case DELETE_TEAM_SUCCESS:
      const deletedItemId = payload.id

      const teamListAfterDelete = state.teamList.filter(
        item => item.id !== deletedItemId,
      )
      return { ...state, teamList: teamListAfterDelete }

    case TEAMS_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        memberList: {
          results: payload.response.teamMembers.results,
          totalCount: payload.response.teamMembers.totalCount,
          teamName: payload.response.teamName,
        },
      }
    case TEAMS_MEMBER_DELETE_SUCCESS:
      const deletedMemberId = payload.response.memberId

      const memberListAfterDelete = state.memberList.results.filter(
        item => item.id !== deletedMemberId,
      )

      return {
        ...state,
        memberList: {
          ...state.memberList,
          results: memberListAfterDelete,
          totalCount: memberListAfterDelete.length,
        },
      }

    case TEAMS_MEMBER_PATCH_SUCCESS:
      const patchedMemberId = payload.memberId

      const memberListAfterPatch = state.memberList.results.map(item => {
        if (item.id === patchedMemberId) {
          payload.content._contents.map(
            content => (item[content._path] = content._value),
          )
          return item
        }
        return item
      })
      return {
        ...state,
        memberList: {
          ...state.memberList,
          results: memberListAfterPatch,
          totalCount: memberListAfterPatch.length,
        },
      }
    case TEAMS_MEMBER_ADD_SUCCESS:
      const usersListWithoutError = payload.response.filter(
        item => item.errors == null,
      )
      return {
        ...state,
        memberList: {
          ...state.memberList,
          results: [...state.memberList.results, ...usersListWithoutError],
          totalCount:
            state.memberList.results.length + usersListWithoutError.length,
        },
      }
    case TEAMS_MEMBER_IMPORT_SUCCESS:
      const userListWithoutError = payload.response.filter(
        item => item.errors == null,
      )

      return {
        ...state,
        memberList: {
          ...state.memberList,
          results: [...state.memberList.results, ...userListWithoutError],
          totalCount:
            state.memberList.results.length + userListWithoutError.length,
        },
      }
    default:
      return state
  }
}
