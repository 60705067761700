const URL = {
  LIST_SUBJECT_BY_QUERY: 'Subject/GetSubjectsByFilter',
  LIST_SUBJECT_BY_PARENT: 'Subject/GetSubjectsByParentId',
  GET_SUBJECT: 'Subject/GetSubjectById',
  ADD_SUBJECT: 'Subject/AddSubject',
  EDIT_SUBJECT: 'Subject/EditSubject',
  PATCH_SUBJECT: 'Subject/PatchSubject',
  DELETE_SUBJECT: 'Subject/DeleteSubject',
  ADD_QUESTION_TO_SUBJECT: 'Subject/AddQuestionToSubject',
  DELETE_QUESTION_FROM_SUBJECT: 'Subject/RemoveQuestionFromSubject',
  ACTIVE_SUBJECT_LIST: 'Subject/MakeSubjectListActive',
  PASSIVE_SUBJECT_LIST: 'Subject/MakeSubjectListPassive',
  DELETE_SUBJECT_LIST: 'Subject/DeleteSubjectList',
  SET_PARENT_OF_SUBJECT_LIST: 'Subject/SetParentOfSubjectList',

  SUBJECT_REPORT_LINE_SERIES: 'Report/SubjectReportItemRepliedLineSeries',
  SUBJECT_REPORT_CONTENT_BREAKDOWN: 'Report/SubjectContentBreakdown',
  SUBJECT_REPORT_TEAM_ENGAGEMENT_DATA:
    'Report/SubjectReportParticipationByTeams',
  SUBJECT_REPORT_TEAM_PERFORMANCE_DATA:
    'Report/SubjectReportPerformanceByTeams',
  SUBJECT_REPORT_RELATED_TEAM_LIST: 'Report/SubjectReportRelatedTeamList',
  SUBJECT_REPORT_CONTENT_ANALYSIS_ASSESSMENT:
    'Report/SubjectContentAnalysisItemForAssessment',
  SUBJECT_REPORT_CONTENT_ANALYSIS_NON_ASSESSMENT:
    'Report/SubjectContentAnalysisItemForNonAssessment',
  CHECK_SUBJECT_NAME_AVAILABLE: 'Subject/CheckIfNameAvailable',
  SUBJECT_REPORT_IMPROVEMENT_DATA: 'Report/ImprovementSubjectQueryReport',
}

export default URL
