import React from 'react'
import { withStyles, useMediaQuery } from '@material-ui/core'
import styles from '../styles'
import { AssignmentFieldNames } from '../../../model/assignment'
import SelectTypeWidget from 'src/components/select-type-widget'
import QuestionCardList from 'src/components/card-list'
import QuestionCardListResponsive from 'src/components/card-list/components/responsive'
import { assignmentUpdateAttribute } from 'src/reducers/assignment/action'

const TasksStep = props => {
  const {
    dispatch,
    onAdd,
    entity,
    classes,
    submitEntity,
    onDrag,
    page,
    setPage,
    setThemeDrawerOpen,
    listingType,
  } = props
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <div className={classes.userList}>
      <QuestionCardList
        content={{}}
        entity={entity}
        list={
          entity
            ? entity[AssignmentFieldNames.QUESTION_DTOS]
              ? entity[AssignmentFieldNames.QUESTION_DTOS]
              : []
            : []
        }
        courseId={
          entity
            ? entity[AssignmentFieldNames.ID]
              ? entity[AssignmentFieldNames.ID]
              : 0
            : 0
        }
        baseEntity={entity}
        dispatch={dispatch}
        onAddCard={onAdd}
        onAdd={() => {}}
        onMove={() => {}}
        onRemove={() => {}}
        showSortOrder={true}
        onDragEnd={onDrag}
        page={page}
        setPage={setPage}
        courseCode={
          entity
            ? entity[AssignmentFieldNames.CODE]
              ? entity[AssignmentFieldNames.CODE]
              : 0
            : 0
        }
        setThemeDrawerOpen={setThemeDrawerOpen}
        listingType={listingType}
      />
    </div>
  )
  // }
}

export default withStyles(styles)(TasksStep)
