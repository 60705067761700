import { put, call, take, fork } from 'redux-saga/effects'
import { sendConfirmationEmailService } from '../../api/sign-up'
import { sendConfirmationEmailSuccess } from '../../reducers/sign-up/action.js'
import { SEND_CONFIRMATION_EMAIL } from '../../reducers/sign-up/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* sendConfirmEmailSagaFlow(payload) {
  try {
    const { email } = payload

    yield call(sendConfirmationEmailService, email)
    yield put(sendConfirmationEmailSuccess(email))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* sendConfirmEmailSagaWatcher() {
  while (true) {
    const action = yield take(SEND_CONFIRMATION_EMAIL)
    const { payload } = action

    yield fork(generalSaga, sendConfirmEmailSagaFlow, payload)
  }
}
