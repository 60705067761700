import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const mediaFolderGetByFilterService = query =>
  new Request(URL.API, URL.MEDIA_FOLDERS_GET_BY_FILTER).get(query)

/**
 *
 * @param {*} id
 */
export const mediaFolderGetByIdService = id =>
  new Request(URL.API, URL.MEDIA_FOLDER_GET_BY_ID).get({ id })

/**
 *
 * @param {*} id
 */
export const mediaFolderGetRootService = id =>
  new Request(URL.API, URL.MEDIA_FOLDER_GET_ROOT).get({ id })

/**
 *
 * @param {*} data
 */
export const mediaFolderAddService = data =>
  new Request(URL.API, URL.MEDIA_FOLDER_ADD_ASYNC).post(data)

/**
 *
 * @param {*} content
 */
export const mediaFolderPatchService = content =>
  new Request(URL.API, URL.MEDIA_FOLDER_PATCH + '?id=' + content.id).patch(
    content,
  )

/**
 *
 * @param {*} folders
 */
export const mediaFolderDeleteListService = folders =>
  new Request(URL.API, URL.MEDIA_FOLDER_DELETE_LIST).post(folders)

/**
 *
 * @param {*} id
 */
export const mediaFolderDeleteService = id =>
  new Request(URL.API, URL.MEDIA_FOLDER_DELETE).delete(id)
