import { put, call, take, fork } from 'redux-saga/effects'
import { courseStatisticsListService } from '../../api/course-statistics'
import { courseStatisticsListSuccess } from '../../reducers/course-statistics/action'
import { COURSE_STATISTICS_LIST } from '../../reducers/course-statistics/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* courseStatisticsListFlow(payload) {
  try {
    const { query } = payload
    const response = yield call(courseStatisticsListService, query)
    if (
      response.data.results.length == 0 &&
      query.FilterText == '' &&
      query.Filters == undefined
    ) {
      response.data.results = null
    }
    yield put(courseStatisticsListSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* courseStatisticsListWatcher() {
  while (true) {
    const action = yield take(COURSE_STATISTICS_LIST)
    const { payload } = action

    yield fork(generalSaga, courseStatisticsListFlow, payload)
  }
}
