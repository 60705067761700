import React from 'react'
import style from '../style'
import propTypes from '../prop'
import ReadJSONPathValue from '../../../../utils/read-json-path'
import isEmpty from '../../../../utils/is-empty'
import { withStyles, TableCell, Typography } from '@material-ui/core'
/* import CheckIcon from '@material-ui/icons/CheckBox'
import UnCheckIcon from '@material-ui/icons/CheckBoxOutlineBlank'
 */
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import UnCheckIcon from '@material-ui/icons/HighlightOff'
/**
 *
 * @param {*} props
 */
const ListContainerColumnBoolean = props => {
  const { classes, column, entity } = props
  let value = ReadJSONPathValue(entity, column.attribute)

  if (column.converter) {
    value = column.converter.input(value)
  }

  let iconView = <CheckIcon color="primary" />

  if (isEmpty(value)) {
    iconView = '-'
  }

  if (value === false) {
    iconView = <UnCheckIcon></UnCheckIcon>
  }

  return (
    <TableCell
      className={classes.root}
      style={{ width: column.width, textAlign: 'center' }}
    >
      <Typography variant="body1" noWrap={false}>
        {iconView}
      </Typography>
    </TableCell>
  )
}

ListContainerColumnBoolean.propTypes = propTypes

export default withStyles(style)(ListContainerColumnBoolean)
