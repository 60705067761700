/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Modal, withStyles, Typography, Box, Button } from '@material-ui/core'
import styles from './styles'
import { track } from '@amplitude/analytics-browser'

const LimitReachedModal = props => {
  const { handleCloseModal, classes, isModalOpen, history } = props

  const handleClickRedirect = type => {
    if ((type = 'Home Page')) {
      history.push('/')
    } else {
      history.push('/pricing')
    }
  }
  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleCloseModal()}
      className={classes.modal}
    >
      <div className={classes.limitModalContent}>
        <div className={classes.limitModalImage}>
          <img src="/assets/popup_girl.png" width="160px" />
        </div>

        <div className={classes.limitModalText}>
          <Typography
            variant="h4"
            style={{
              marginTop: '10px',
              textAlign: 'center',
              paddingRight: '20px',
            }}
          >
            Wow, you've reached the ceiling!
          </Typography>
          <Box m={1}></Box>
          <Typography
            variant="subtitle"
            fontSize="18px"
            paddingRight="20px"
            textAlign="center"
          >
            Upgrade your account to continue creating new mini-courses.
          </Typography>
          <Box m={1}></Box>
          <Button
            style={{
              marginRight: '20px',
              marginBottom: '30px',
              height: 44,
              fontSize: '1.1rem',
            }}
            variant="contained"
            to="/pricing"
            component={RouterLink}
            onClick={() => {
              handleClickRedirect()
              track('limit_warning_upgrade', {
                from: 'course_limit_upgrade',
              })
            }}
          >
            See Plans and Upgrade
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default withStyles(styles)(LimitReachedModal)
