const style = theme => ({
  paginationHolder: {
    display: 'block',
    width: '100%',
  },
  pagination: {
    display: 'inline-block',
    justifyContent: 'center',
    background: '#fdfdfd',
    border: '1px solid #ededed',
    padding: '.4rem',
    borderRadius: '15px',
  },
  cardListContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: 1,
  },
  cardListView: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    zIndex: 1,
    marginBottom: '32px',
  },
  cardListHolder: {
    position: 'relative',
    width: '90%',
    //height: '100%',
    textAlign: 'center',
    overflowX: 'clip',
    marginTop: '24px',
    /*justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    display: 'flex',*/
    zIndex: 1,
  },
  popover: {
    '&.MuiPopover-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
    },
    '& .MuiPaper-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
    },
  },
  emptySlot: {
    width: '100%',
    height: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  emptySlotContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptySlotTitle: {
    color: '#919eab',
    fontSize: 18,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
  emptySlotDescription: {
    color: '#919eab',
    fontSize: 16,
    marginTop: theme.spacing(1),
  },
  emptySlotButtonText: {
    fontSize: 14,
    textTransform: 'none',
  },
  rightHelper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 0,
  },
  front: { width: '100%' },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    //marginTop: '.8rem',
    textDecoration: 'none',
    color: '#ffffff',
    opacity: '1',
    marginBottom: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconBackground: {
    background: '#015FFB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: 45,
    borderRadius: '50%',
    zIndex: 9999999,
  },
  buttonText: {
    padding: '.8rem',
    lineHeight: '17px',
    fontSize: '13px',
    fontWeight: '500',
    borderRadius: '25px',
    background: '#ffffff',
    whiteSpace: 'nowrap',
    color: '#637381',
    width: '130px',
    textAlign: 'end',
    marginLeft: '-38px',
    height: '45px',
    '&:hover': {
      color: '#015FFB',
    },
  },
  buttonTextQuiz: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '32px',
    padding: '.8rem',
    lineHeight: '17px',
    fontSize: '13px',
    fontWeight: '500',
    borderRadius: '25px',
    background: '#ffffff',
    whiteSpace: 'nowrap',
    color: '#637381',
    width: '130px',
    textAlign: 'end',
    marginLeft: '-38px',
    height: '45px',
    '&:hover': {
      color: '#015FFB',
    },
  },
  previewBtn: {
    marginRight: theme.spacing(1),
    backgroundColor: '#ffffff',
    color: '#637381',
    border: 'none',
    borderRadius: 16,

    '&:hover': {
      backgroundColor: '#E7F430',
      color: '#1D1D1F',
      border: 'none',
    },
  },
  shareButton: {
    marginRight: theme.spacing(0),
    backgroundColor: '#E7F430',
    color: '#1D1D1F',
    border: 'none',
    borderRadius: 16,

    '&:hover': {
      border: 'none',
      backgroundColor: '#E7F430',
      color: '#1D1D1F',
    },
  },
  buttonTextes: {
    fontSize: 14,
    fontWeight: 400,
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  buttonShareText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#1D1D1F',
    marginBottom: 2,
  },
  modalStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  shareButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  shareButtonGroupText: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    paddingLeft: '24px',
    paddingBottom: '8px',
    paddingRight: '24px',
    justifyContent: 'center',
    cursor: 'pointer',
    borderLeft: '1px solid #ececec',
    backgroundColor: '#ffffff',
    color: '#637381',
    border: 'none',
    '&:hover': {
      backgroundColor: '#E9F1FF',
    },
  },
  addCard: {
    position: 'absolute',
    right: '50%',
    bottom: '-55',
    transform: 'translateY(-50%)',
    zIndex: 999,
    //marginRight: '-2.5rem',
    //marginTop: '-30px',
    color: '#0C53B7',
    [theme.breakpoints.up('lg')]: {
      //overflow: 'auto',
      //overflow: 'hidden',
      bottom: '-66',
    },
  },
  iconButtonAdd: {
    color: '#F67B3F',
    background: '#ffffff',
    '&:hover': {
      color: '#ffffff',
      background: '#F67B3F',
    },
    width: 32,
    height: 32,
  },
  popoverAddCard: {
    borderRadius: 32,
    '&.MuiPopover-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
      borderRadius: 32,
    },
    '& .MuiPaper-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
      borderRadius: 32,
    },
  },
  sortableListGrid: {
    flexWrap: 'inherit',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  addLastCard: {
    margin: '24px',
    width: '100%',
  },
  addLastCardContent: {
    margin: '24px',
    border: '2px dashed #dcdcdc',
    borderRadius: '20px',
    height: 160,
    display: 'flex',
    flexDirection: 'column',
    padding: '20 30 20 30',
  },
  addLastCardContentHeader: {
    marginBottom: '15px',
    display: 'flex',
  },
  completionSvg: {
    marginRight: '10px',
  },
  buttonSvg: {
    marginRight: '5px',
  },
  addLastCardContentButtons: {
    width: '32%',
  },
  addLastCardContentButtonsDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalStyleScorm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
})

export default style
