import translator from '../../../translate'
import AddIcon from '@material-ui/icons/Add'

/**
 *
 */
const search = [
  {
    label: translator._('action.add'),
    icon: AddIcon,
    link: null,
    exec: (entity, dispatch, refresh) => {},
    disabled: entity => {
      return false
    },
    hidden: entity => {
      return false
    },
  },
]
export default search
