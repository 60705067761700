import { SET_THEME_MODE, SET_THEME_DIRECTION } from './type'

export const switchMode = (themeMode) => {
  return {
    type: SET_THEME_MODE,
    payload: { themeMode }
  }
}

export const switchDirection = (themeDirection) => {
  return {
    type: SET_THEME_DIRECTION,
    payload: { themeDirection }
  }
}
