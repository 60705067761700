/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import styles from './styles'
import { Icon } from '@iconify/react'

import { addTeamsMemberAction } from 'src/reducers/teams/action'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { PatchContent, PatchData } from 'src/model/patch'

import { UserListWithError } from 'src/pages/teams-member-list/components/import-bulk-member/components'

const AddMember = props => {
  const { entity, dispatch, classes } = props

  const teamsId = window.location.pathname.split('users-member-list/')[1]
  const [userListWithError, setUserListWithError] = React.useState([])
  const [responseArrived, setResponseArrived] = React.useState(false)

  const [teamMembers, setTeamMembers] = React.useState([
    {
      name: '',
      username: '',
      email: '',
    },
  ])
  const handleCreateMembers = () => {
    dispatch(
      addTeamsMemberAction(teamsId, teamMembers, response => {
        setResponseArrived(true)
        let userListWithErrorList = []
        response.map(item => {
          if (item.errors != null) {
            userListWithErrorList = [item, ...userListWithErrorList]
          }
        })
        userListWithErrorList.length == 0 && dispatch(componentBoxHideAction())
        setUserListWithError(userListWithErrorList)
      }),
    )
  }

  const handleDeleteMember = index => {
    const newTeamMembers = [...teamMembers]
    newTeamMembers.splice(index, 1)
    newTeamMembers.length === 0 &&
      newTeamMembers.push({
        name: '',
        username: '',
        email: '',
      })

    setTeamMembers(newTeamMembers)
  }
  const addUserToList = () => {
    const newMember = {
      name: '',
      username: '',
      email: '',
    }
    setTeamMembers([...teamMembers, newMember])
  }
  const handleAddedMember = (e, index) => {
    const { name, value } = e.target
    let newMember = {
      ...teamMembers[index],
      [name]: value,
    }
    if (name === 'email') {
      newMember = {
        ...newMember,
        username: value,
      }
    }
    const newTeamMembers = [...teamMembers]
    newTeamMembers[index] = newMember
    setTeamMembers(newTeamMembers)
  }

  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
        <div className={classes.modalContent__header}>
          <Typography className={classes.modalContent__header__title}>
            Create Leaner
          </Typography>
        </div>

        {responseArrived
          ? userListWithError && (
              <>
                <Typography
                  className={classes.modalContent__header__description}
                >
                  {teamMembers.length - userListWithError.length} of the{' '}
                  {teamMembers.length} users have been successfully added to the
                  group.
                </Typography>
                {userListWithError.length > 0 && (
                  <UserListWithError
                    userListWithError={userListWithError}
                  ></UserListWithError>
                )}
              </>
            )
          : teamMembers.length > 0 &&
            teamMembers.map((member, index) => {
              return (
                <div
                  key={index}
                  className={classes.modalContent__header__naming}
                >
                  <TextField
                    value={member.name}
                    onChange={e => handleAddedMember(e, index)}
                    className={classes.modalContent__input}
                    label="Name"
                    name="name"
                    placeholder="Name"
                  />
                  <TextField
                    value={member.email}
                    onChange={e => handleAddedMember(e, index)}
                    className={classes.modalContent__input}
                    label="Email"
                    name="email"
                    placeholder="Email"
                  />
                  <IconButton onClick={() => handleDeleteMember(index)}>
                    <Icon
                      icon="ic:baseline-close"
                      height={24}
                      width={24}
                      color="#919EAB"
                    />
                  </IconButton>
                </div>
              )
            })}
        {!responseArrived ? (
          <>
            <div>
              <Button
                onClick={() => addUserToList()}
                style={{
                  color: '#919EAB',
                }}
              >
                + Add User
              </Button>
            </div>
            <div className={classes.modalContent__buttons}>
              <Button
                variant="outlined"
                className={classes.modalContent__cancelButton}
                onClick={() => dispatch(componentBoxHideAction())}
              >
                <Typography className={classes.modalContent__buttonText}>
                  Cancel
                </Typography>
              </Button>
              <Button
                variant="contained"
                className={classes.modalContent__SaveButton}
                onClick={() => handleCreateMembers()}
              >
                <Typography className={classes.modalContent__buttonText}>
                  Create User
                </Typography>
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={classes.modalContent__buttons}>
              <Button
                variant="contained"
                className={classes.modalContent__SaveButton}
                onClick={() => dispatch(componentBoxHideAction())}
              >
                <Typography className={classes.modalContent__buttonText}>
                  Done
                </Typography>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default withStyles(styles)(AddMember)
