import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import isEmpty from '../../../../utils/is-empty'
import { PatchData } from '../../../../model/patch'
import { withStyles, MenuItem, Input, MenuList } from '@material-ui/core'

const FormItemMenu = props => {
  const {
    classes,
    required,
    disabled,
    value,
    options,
    optionAttributeName,
    updateEntityKeyValue,
  } = props

  const [stateValue, setStateValue] = useState(value)
  const optionsList = options()

  /**
   *
   * @param event
   * @param newValue
   */
  const onChange = (event, newValue) => {
    updateEntityKeyValue(newValue, props, PatchData.OPERATION_REPLACE)

    setStateValue(newValue)
  }

  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <MenuList
          className={classes.select}
          disabled={disabled}
          required={required}
          value={isEmpty(stateValue) ? '' : stateValue}
          name="name"
          input={<Input id="select-input" name="select-input" />}
        >
          {optionsList &&
            optionsList.map((option, index) => (
              <MenuItem
                onClick={event => {
                  onChange(event, option[optionAttributeName.value])
                }}
                key={index}
                value={option[optionAttributeName.value]}
                selected={option[optionAttributeName.value] === stateValue}
              >
                {option[optionAttributeName.label]}
              </MenuItem>
            ))}
        </MenuList>
      }
    />
  )
}

FormItemMenu.propTypes = propTypes

export default withStyles(style)(FormItemMenu)
