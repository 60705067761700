import { put, call, take, fork, delay } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_VIDEO_UPLOAD } from '../../reducers/media-video/type'
import { mediaVideoUploadService } from '../../api/media-video'
import { mediaVideoUploadSuccess } from '../../reducers/media-video/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaVideoUploadFlow(payload) {
  try {
    const { parentFolderId, file, callback } = payload

    var formData = new FormData()

    formData.append('file', file)
    if (parentFolderId) {
      formData.set('parentFolderId', parentFolderId)
    }

    const response = yield call(mediaVideoUploadService, formData)

    yield delay(2000)
    yield put(mediaVideoUploadSuccess(response.data))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaVideoUploadWatcher() {
  while (true) {
    const action = yield take(MEDIA_VIDEO_UPLOAD)
    const { payload } = action

    yield fork(generalSaga, mediaVideoUploadFlow, payload)
  }
}
