import { OPERATION_SUCCESS, OPERATION_FAIL } from './type'

/**
 *
 */
export const operationSuccess = () => {
  return {
    type: OPERATION_SUCCESS,
  }
}

/**
 *
 * @param {*} error
 */
export const operationFail = error => {
  return {
    type: OPERATION_FAIL,
    error,
  }
}
