import { editCourseCollectionIndexAction } from 'src/reducers/course-collection/action'

const handleDragEnd = (
  changedCourses,
  dispatch,
  setActiveId,
  setChangedCourses,
) => {
  if (changedCourses.length !== 0) {
    dispatch(
      editCourseCollectionIndexAction({
        courseDtos: changedCourses,
      }),
    )
    setActiveId(null)
    setChangedCourses([])
  }
}

export default handleDragEnd
