import React from 'react'
import { sortableHandle } from 'react-sortable-hoc'
import OpenWithIcon from '@material-ui/icons/DragIndicator'
import { SortableElement } from 'react-sortable-hoc'
import RowMenuAction from '../../row-menu'
import { ListColumnType } from 'src/model/list'
import { TableRow, TableCell, Typography } from '@material-ui/core'
import {
  ListContainerColumnCustom,
  ListContainerColumnAction,
  ListContainerColumnActionHover,
  ListContainerColumnNormal,
  ListContainerColumnSelect,
  ListContainerColumnExpanded,
  ListContainerColumnBoolean,
  ListContainerColumnNumber,
} from '../../../column'

const BodyListItem = props => {
  const {
    classes,
    entity,
    dispatch,
    index,
    showDragDrop,
    refresh,
    row,
    selectedList,
    anchorPosition,
    handleRowClick,
    showChildList,
    handleRowMenuClose,
    changeExpanded,
    showSelect,
    changeSelected,
    expandedId,
    showRowAction,
    hoverActionEnabled,
  } = props

  const DragHandle = sortableHandle(() => (
    <OpenWithIcon fontSize="small" className={classes.dragHandleIcon} />
  ))

  const SortableItem = SortableElement(props => {
    const {
      entity,
      index,
      key,
      className,
      selected,
      style,
      onClick,
      showSortOrder,
      children,
      classes,
    } = props

    return (
      <TableRow
        className={className}
        selected={selected}
        style={style}
        onClick={onClick}
        dispatch={dispatch}
        entity={entity}
        index={index}
        key={key}
        showSortOrder={showSortOrder}
        classes={classes}
      >
        {children}
      </TableRow>
    )
  })

  return (
    <SortableItem
      className={classes.bodyRow}
      entity={entity}
      index={index}
      key={entity.id}
      collection={'test'}
      disabled={!showDragDrop}
      selected={
        selectedList.indexOf(entity.id) !== -1 ||
        (row.columns.filter(c => c.actions != null).length > 0 &&
          anchorPosition.id == entity.id)
      }
      style={{
        cursor:
          row.columns.filter(c => c.actions != null).length > 0
            ? !hoverActionEnabled && 'pointer'
            : 'default',
      }}
      onClick={event => !hoverActionEnabled && handleRowClick(event, entity.id)}
    >
      {showDragDrop && (
        <TableCell style={{ cursor: 'pointer' }}>
          <DragHandle className={classes.dragHandle} />
        </TableCell>
      )}
      {showDragDrop && (
        <TableCell>
          <Typography variant="body1">{index + 1}</Typography>
        </TableCell>
      )}

      {showChildList && (
        <ListContainerColumnExpanded
          key="expanded"
          disabled={row.childDisableControl(entity)}
          entity={entity}
          expanded={expandedId === entity.id}
          changeExpanded={changeExpanded}
        />
      )}

      {showSelect && (
        <ListContainerColumnSelect
          key="select"
          entity={entity}
          checked={selectedList.indexOf(entity.id) !== -1}
          changeSelected={changeSelected}
        />
      )}

      {row.columns
        //.filter(c => !c.hidden)
        .map((column, index) => {
          let renderer = ''

          if (typeof column.hidden === 'function') {
            let hidden = column.hidden(entity)
            if (hidden === true) {
              return ''
            }
          } else if (column.hidden === true) {
            return ''
          }

          if (column.renderer) {
            renderer = (
              <ListContainerColumnCustom
                key={index}
                column={column}
                entity={entity}
              />
            )
          } else {
            if (column.actions !== null) {
              if (showRowAction !== false) {
                renderer = (
                  <React.Fragment>
                    <TableCell>
                      {hoverActionEnabled ? (
                        <ListContainerColumnActionHover
                          key={index}
                          column={column}
                          entity={entity}
                          dispatch={dispatch}
                          refresh={refresh}
                        />
                      ) : (
                        <ListContainerColumnAction
                          key={index}
                          column={column}
                          entity={entity}
                          dispatch={dispatch}
                          refresh={refresh}
                        />
                      )}
                    </TableCell>

                    <RowMenuAction
                      column={column}
                      entity={entity}
                      dispatch={dispatch}
                      anchorPosition={anchorPosition}
                      handleRowMenuClose={handleRowMenuClose}
                      refresh={refresh}
                    />
                  </React.Fragment>
                )
              } else {
                renderer = null
              }
            } else if (column.type === ListColumnType.BOOLEAN) {
              renderer = (
                <ListContainerColumnBoolean
                  key={index}
                  column={column}
                  entity={entity}
                />
              )
            } else if (column.type === ListColumnType.NUMBER) {
              renderer = (
                <ListContainerColumnNumber
                  key={index}
                  column={column}
                  entity={entity}
                />
              )
            } else {
              renderer = (
                <ListContainerColumnNormal
                  key={index}
                  column={column}
                  entity={entity}
                />
              )
            }
          }

          return renderer
        })}
    </SortableItem>
  )
}

export default BodyListItem
