const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '70%',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  centerBoxGrid: {
    textAlign: 'center',
    maxWidth: 700,
    margin: '50px auto',
  },
  maskot: {
    height: 125,
  },
  logoHolder: {
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  logo: {
    height: 30,
  },
  logoApp: {
    height: 26,
    paddingTop: 2,
  },
  line: {
    height: 20,
    width: 2,
    backgroundColor: '#efefef',
    display: 'inline-block',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
  },
  info1: {
    textAlign: 'center',
  },
  info2: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    width: '60%',
  },
  storeHolder: { marginTop: 10 },
  appStore: { marginRight: 10 },
  input: {
    width: '80%',
    border: '1px solid grey',
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    marginLeft: '10%',
    //marginBottom: theme.spacing(3),
  },
  invalidCheck: {
    color: '#d3d3d3',
  },
  validCheck: {
    color: '#4caf50',
  },
  passwordRequirements: {
    color: '#4a4a4a',
    textAlign: 'center',
    display: 'block',
    marginTop: theme.spacing(2),
  },
  invalid: {
    color: '#d3d3d3',
    padding: theme.spacing(1),
    margin: 3,
    border: '1px solid #d3d3d3',
    fontSize: 11,
    borderRadius: 5,
    display: 'inline-block',
  },
  valid: {
    color: '#ffffff',
    padding: theme.spacing(1),
    margin: 3,
    border: '1px solid #d3d3d3',
    backgroundColor: '#4caf50',
    fontSize: 11,
    borderRadius: 5,
    display: 'inline-block',
  },
  strengthMeter: {
    textAlign: 'center',
  },
  divider: { width: '100%', marginTop: theme.spacing(2) },
  yourUserName: {
    fontWeight: 500,
    color: '#a5a5a5',
    marginTop: theme.spacing(1),
  },
  userName: {
    fontWeight: 600,
    margin: theme.spacing(1),
  },
  success: {
    backgroundColor: '#92a746',
    borderRadius: 20,
    color: 'white',
    fontWeight: 500,
    lineHeight: '31px',
    paddingRight: '7px',
    paddingLeft: '3px',
    margin: theme.spacing(2),
  },
  supportEmail: {
    color: '#2f9ab2',
    textDecoration: 'none',
    fontWeight: 500,
  },
})

export default styles
