import React, { Component } from 'react'
import { connect } from 'react-redux'
import { snackbarDispose } from '../../reducers/snackbar/action'
import translator from '../../translate'
import { withSnackbar } from 'notistack'
import Preloader from '../../components/preloader'

// material
import { styled } from '@material-ui/core/styles'
import { Box, Card, Grid, Link, Container, Typography } from '@material-ui/core'

// layouts
import AuthLayout from '../../layouts/AuthLayout'
// components
import Page from '../../components/Page'
import { MHidden } from '../../components/@material-extend'
import { LoginForm } from '../../components/authentication/login'
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

class Login extends Component {
  render() {
    const { process } = this.props
    return (
      <RootStyle title="Login | Mini-Course Generator">
        <AuthLayout>
          {translator._('signUp.dontHaveAnAccount')} &nbsp;
          <Link underline="none" variant="subtitle2" href="/register">
            {translator._('signUp.getStarted')}
          </Link>
        </AuthLayout>
        {process && <Preloader process={process} />}

        <MHidden width="mdDown">
          <SectionStyle>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant="h3" sx={{ pl: 5, mt: 10, mb: 5 }}>
                {translator._('signUp.hi')}
              </Typography>
              <Typography
                color="primary"
                variant="h3"
                sx={{ pl: 1, mt: 10, mb: 5 }}
              >
                {translator._('signUp.welcomeBack')}
              </Typography>
            </Box>
          </SectionStyle>
        </MHidden>

        <Container maxWidth="sm">
          <ContentStyle>
            <Grid direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translator._('loginPage.signIn')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {translator._('signUp.enterDetails')}
                </Typography>
              </Box>
            </Grid>

            <AuthFirebaseSocials />

            <LoginForm {...this.props} />
            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {translator._('signUp.dontHaveAnAccount')}&nbsp;
                <Link variant="subtitle2" href="/register">
                  {translator._('signUp.getStarted')}
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Container>
      </RootStyle>
    )
  }

  componentDidUpdate() {
    const { snackbar, enqueueSnackbar, dispatch } = this.props
    if (snackbar.messages) {
      snackbar.messages.forEach(message => {
        message.messageIds.forEach(messageId => {
          enqueueSnackbar(translator._(messageId), {
            preventDuplicate: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
        dispatch(snackbarDispose(message.id))
      })
    }
  }
}

const mapStateToProps = response => ({
  authentication: response.authentication,
  snackbar: response.snackbar,
  process: response.process,
})
export default connect(mapStateToProps)(withSnackbar(Login))
