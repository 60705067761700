const style = theme => ({
  root: {
    width: '920px',
    //height: 'calc(100% - 40px)',
    //overflow: 'hidden',
    flexDirection: 'column',
  },
  headerContent: {
    height: 60,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //marginTop: theme.spacing(2),
  },
  content: {
    height: '460px',
    overflow: 'auto',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    maxWidth: '100%',
    marginBottom: theme.spacing(4),
  },
  imageList: {
    padding: 3,
    width: '100%',
    alignContent: 'flex-start',
    overflow: 'hidden',
  },
  tabPanel: {
    width: '100%',
    height: '492px',
  },
  dropArea: {
    width: '100%',
    height: 'calc(100% - 6px)',
    border: '3px dashed gray',
    backgroundColor: '#eff2f7',
    zIndex: 1300,
    padding: 16,
    //pointerEvents: 'none',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  subTitle: {
    height: '40px !important',
    borderBottom: '1px solid #e2e2e2',
    marginBottom: 16,
    marginTop: 16,
    width: '100% !important',
  },

  linkPanel: {
    margin: '40px auto',
  },
  giphyPanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoText: {
    marginTop: 32,
    marginBottom: 16,
    borderBottom: '1px solid #c5c6c7',
  },
})

export default style
