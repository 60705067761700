import { put, call, take, fork } from 'redux-saga/effects'
import { questionReportCompareTeamsService } from '../../api/question'
import { questionReportCompareTeamsSuccess } from '../../reducers/question/action'
import { QUESTION_REPORT_COMPARE_TEAMS } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionReportCompareTeamsFlow(payload) {
  try {
    const { reqParams, parentId, callback } = payload

    const response = yield call(questionReportCompareTeamsService, reqParams)

    yield put(questionReportCompareTeamsSuccess(response.data, parentId))
    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionReportCompareTeamsWatcher() {
  while (true) {
    const action = yield take(QUESTION_REPORT_COMPARE_TEAMS)
    const { payload } = action

    yield fork(generalSaga, questionReportCompareTeamsFlow, payload)
  }
}
