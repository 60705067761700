const URL = {
  GET_FILTER_NAMES: 'Common/GetFilterNamesForItem',
  EXCEL_EXPORT_USERS: 'Common/ExcelExportUsers',
  EXCEL_EXPORT_ALL_USERS: 'Common/ExcelExportAllUsers',
  EXCEL_IMPORT_USERS: 'Common/ExcelImportUsers',
  EXCEL_IMPORT_QUESTIONS: 'Common/ExcelImportQuestions',
  SEND_ACTIVATION_SMS: 'Common/SendActivationSMS',
  SEND_ACTIVATION_EMAIL: 'Common/SendActivationEmail',
  SEND_MAGIC_LINK_VIA_EMAIL: 'Common/SendMagicLinkViaEmail',
  GET_CONSTANTS: 'Common/GetConstants',
  SHOP_LIST: 'Statistics/ShopList',
}

export default URL
