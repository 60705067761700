import URL from '../url'
import Request from '../request'

/**
 * @param {*} query
 */
export const userListService = query =>
  new Request(URL.API, URL.LIST_USER).post(query)

/**
 * @param {*} id
 */
export const userGetService = id =>
  new Request(URL.API, URL.GET_USER).get({ id })

/**
 * @param {*} shortLink
 */
export const userValidateShortLinkService = shortLink =>
  new Request(URL.API, URL.VALIDATE_SHORT_INTEGRATION_LINK_USER).get({
    shortLink,
  })

/**
 *
 */
export const userGetSelfService = () => new Request(URL.API, URL.GET_SELF).get()

/**
 * @param {*} entity
 */
export const userAddService = entity =>
  new Request(URL.API, URL.ADD_USER).post(entity)

/**
 * @param {*} content
 */
export const userPatchService = content =>
  new Request(URL.API, URL.PATCH_USER + '?id=' + content.id).patch(content)

/**
 * @param {*} content
 */
export const adminPatchService = content =>
  new Request(URL.API, URL.PATCH_ADMIN + '?id=' + content.id).patch(content)

/**
 * @param {*} id
 */
export const userDeleteService = id =>
  new Request(URL.API, URL.DELETE_USER).delete({ id })

/**
 * @param {*} ids
 */
export const userListMakeActiveService = ids =>
  new Request(URL.API, URL.ACTIVE_USER_LIST).post(ids)

/**
 * @param {*} ids
 */
export const userListMakePassiveService = ids =>
  new Request(URL.API, URL.PASSIVE_USER_LIST).post(ids)

/**
 * @param {*} ids
 */
export const userListDeleteService = ids =>
  new Request(URL.API, URL.DELETE_USER_LIST).post(ids)

/**
 * @param {*} id
 */
export const userCheckUsernameAvailableService = userName =>
  new Request(URL.API, URL.CHECK_USER_NAME_AVAILABLE).get({ userName })

/**
 *
 * @param {*} reqParams
 */
export const userReportQuestionAnalysisService = reqParams =>
  new Request(URL.REPORT_API, URL.USER_REPORT_QUESTION_ANALYSIS).post(reqParams)

/**
 *
 * @param {*} reqParams
 */
export const userReportDetailedAnalysisService = reqParams =>
  new Request(URL.REPORT_API, URL.USER_REPORT_DETAILED_ANALYSIS).post(reqParams)

/**
 *
 * @param {*} query
 */
export const userRoleDetailedListService = query =>
  new Request(URL.API, URL.USER_ROLE_DETAILED_LIST).post(query)
/**
 *
 * @param {*} data
 */
export const setUserRoleService = data => {
  return new Request(URL.API, URL.SET_USER_ROLE).post(data)
}
