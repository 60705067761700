import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'
import CustomizedSwitch from 'src/components/customized-switch'
import { Icon } from '@iconify/react'

const CustomDomainHeader = props => {
  const { classes } = props
  return (
    <div className={classes.customDomain__root}>
      <div className={classes.customDomain__headerWithIcon}>
        <Icon
          icon={'solar:earth-linear'}
          className={classes.customDomain__icon}
        />
        <Typography fontSize={'16px'} fontWeight={500}>
          Choose your custom domain linked to your collection
        </Typography>
      </div>
      <Typography color={'#637381'} fontSize={'14px'} fontWeight={400}>
        You can find detailed information about "Add CNAME to your DNS Records"
        to <a href="/custom-domain"> this link.</a>
      </Typography>
    </div>
  )
}

export default withStyles(styles)(CustomDomainHeader)
