import React from 'react'

import { PricingPlans } from 'src/pages/pricing'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { guid } from 'src/utils/guid'

function ModalContent() {
  return (
    <>
      <PricingPlans handleClick={() => {}} />
    </>
  )
}

export const showModal = (dispatch, header) => {
  dispatch(
    componentBoxShowAction(
      new ComponentBoxContent(<ModalContent key={guid.uid} />, {
        hideCloseButton: false,
        header: header || 'Upgrade your Plan',
        callback: e => {},
      }),
    ),
  )
}
