import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const assignmentListService = query =>
  new Request(URL.API, URL.ASSIGNMENT_LIST).post(query)

/**
 *
 * @param {*} reinforcementId
 */
export const assignmentGetService = reinforcementId =>
  new Request(URL.API, URL.GET_ASSIGNMENT).get({
    id: reinforcementId,
  })

/**
 *
 * @param {*} topic
 */

export const openaiSendRequestService = topic =>
  new Request(URL.API, URL.OPENAI_SEND_REQUEST).post({
    topic,
  })

/**
 *
 * @param {*} entity
 */
export const assignmentAddService = entity =>
  new Request(URL.API, URL.ADD_ASSIGNMENT).post(entity)

/**
 *
 * @param {*} entity
 */
export const assignmentEditService = entity =>
  new Request(URL.API, URL.EDIT_ASSIGNMENT).put(entity)

/**
 *
 * @param {*} id
 */
export const assignmentDeleteService = id =>
  new Request(URL.API, URL.DELETE_ASSIGNMENT).delete({ id })

/**
 *
 * @param {*} content
 */
export const assignmentPatchService = content =>
  new Request(URL.API, URL.PATCH_ASSIGNMENT + '?id=' + content.id).patch(
    content,
  )

/**
 *
 * @param {*} entity
 */
export const assignmentRemoveQuestionService = (questionId, courseId) =>
  new Request(URL.API, URL.REMOVE_QUESTION_ASSIGNMENT).post({
    id: courseId,
    cardIds: [questionId],
  })

/**
 *
 * @param {*} entity
 */
export const assignmentAddBulkQuestionService = entity =>
  new Request(URL.API, URL.ADD_BULK_QUESTION_ASSIGNMENT).post(entity)

/**
 *
 * @param {*} id
 */
export const assignmentCloneService = code =>
  new Request(URL.API, URL.CLONE_ASSIGNMENT).post({ code })

/**
 *
 *
 */
export const assignmentCardLinkPatchService = content =>
  new Request(URL.API, URL.CARD_PATCH_LINK + '?id=' + content.id).patch(content)

/**
 * @param {*} id
 *
 */
export const courseSettingPatchService = content => {
  new Request(URL.API, URL.PATCH_COURSE_SETTING + '?id=' + content.id).patch(
    content.data,
  )
}

/**
 * @param {*} id
 *
 */
export const courseSettingCreateService = content =>
  new Request(URL.API, URL.CREATE_COURSE_SETTING).post(content)

/**
 *
 *
 */
export const courseSettingRemoveService = id =>
  new Request(URL.API, URL.REMOVE_QUESTION_SETTING).delete({ id })

/**
 *
 * @param {*} starterText
 * @param {*} courseId
 * @param {*} cardId
 */

export const openaiSendCardRequestService = (starterText, courseId, cardId) =>
  new Request(URL.API, URL.OPENAI_SEND_CARD_REQUEST).post({
    starterText,
    courseId,
    cardId,
  })

/**
 *
 * @param {*} profession
 * @param {*} audience
 * @param {*} description
 * @param {*} language
 * @param {*} purpose
 */
export const openaiSendCourseCreationGetTitleRequestService = (
  profession,
  audience,
  description,
  language,
  purpose,
) =>
  new Request(URL.API, URL.OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST).post({
    profession,
    audience,
    description,
    language,
    purpose,
  })

/**
 *
 * @param {*} profession,
 * @param {*} audience,
 * @param {*}title,
 * @param {*}language,
 * @param {*}purpose,
 */
export const openaiSendCourseCreationGetOutlineRequestService = (
  profession,
  audience,
  title,
  language,
  purpose,
) =>
  new Request(
    URL.API,
    URL.OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST,
  ).post({
    profession,
    audience,
    title,
    language,
    purpose,
  })

/**
 * @param {*}firstCardText
 * @param {*} profession
 * @param {*} title
 * @param {*} outlines
 * @param {*} audience
 * @param {*} description
 * @param {*} courseCollectionId
 * @param {*} language
 * @param {*} purpose
 * @param {*} selectedPreset
 */
export const openaiSendCourseCreationRequestService = (
  title,
  outlines,
  profession,
  audience,
  description,
  themeSettings,
  courseCollectionId,
  variables,
  gatewaySelection,
  language,
  purpose,
  selectedPreset,
) =>
  new Request(URL.API, URL.OPENAI_SEND_COURSE_CREATION_REQUEST).post({
    title,
    outlines,
    profession,
    audience,
    description,
    themeSettings,
    courseCollectionId,
    variables,
    gatewaySelection,
    language,
    purpose,
    selectedPreset,
  })

/**
 *
 * @param {*} code
 * @param {*} title
 * @param {*} collectionId
 */
export const assignmentCloneTemplateCourseService = (
  code,
  title,
  collectionId,
) =>
  new Request(URL.API, URL.CLONE_TEMPLATE_COURSE).post({
    code,
    title,
    collectionId,
  })

/**
 *
 * @param {*} starterText
 */

export const sendCreatePromptFromCardContentRequestService = starterText =>
  new Request(URL.API, URL.CREATE_PROMPT_FROM_CARD).post({
    starterText,
  })

/**
 *
 * @param {*} courseId
 */

export const getCourseAnalyticsByIdService = courseId => {
  return new Request(URL.API, URL.GET_COURSE_ANALYTICS).post({
    courseId,
  })
}

/**
 *
 *
 */
export const addCourseDocumentService = entity =>
  new Request(URL.API, URL.ADD_COURSE_DOCUMENT).post(entity)

/**
 *
 * @param {*} courseId
 *
 */
export const getCourseDocumentByIdService = courseId =>
  new Request(URL.API, URL.GET_COURSE_DOCUMENT_BY_ID).post({ courseId })

/**
 *
 *
 */
export const generateQuizWithCourseDocumentService = entity =>
  new Request(URL.API, URL.GENERATE_QUIZ_WITH_COURSE_DOCUMENT).post(entity)

/**
 *
 *
 */
export const deleteCourseDocumentService = entity =>
  new Request(URL.API, URL.DELETE_COURSE_DOCUMENT).post(entity)

/**
 *
 *
 */
export const generateSuggestionWithCourseDocumentService = entity =>
  new Request(URL.API, URL.GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT).post(
    entity,
  )

/**
 *
 *
 */
export const generateInformationWithCourseDocumentService = entity =>
  new Request(URL.API, URL.GENERATE_INFORMATION_WITH_COURSE_DOCUMENT).post(
    entity,
  )

/**
 *
 *
 */

export const exportCourseExcelService = (courseId, courseName) =>
  new Request(URL.API, URL.EXPORT_COURSE_EXCEL).post(
    {
      courseId: courseId,
      courseName: courseName,
    },
    {
      responseType: 'blob',
    },
  )

/**
 *
 *
 */
export const importCourseExcelService = entity =>
  new Request(URL.API, URL.IMPORT_COURSE_EXCEL).post(entity)
/**
 *
 *
 */
export const importCourseTxtService = entity =>
  new Request(URL.API, URL.IMPORT_COURSE_TXT).post(entity)

/**
 *
 *
 */

export const exportCourseTxtService = (courseId, courseName) =>
  new Request(URL.API, URL.EXPORT_COURSE_TXT).post(
    {
      courseId: courseId,
      courseName: courseName,
    },
    {
      responseType: 'blob',
    },
  )

/**
 *
 * @param {*} id
 * @param {*} scormVersion
 */
export const exportCourseToScormService = (id, scormVersion) =>
  new Request(URL.API, URL.EXPORT_COURSE_TO_SCORM.replace('{id}', id)).post(
    {
      scormVersion: scormVersion,
    },
    {
      responseType: 'blob',
    },
  )
