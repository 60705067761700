import React, { useState } from 'react'
import style from '../style'
import propTypes from '../prop'
import translator from '../../../../translate'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ButtonType } from '../../../../model/button'
import { useHistory } from 'react-router-dom'
import { withStyles, Menu, MenuItem, IconButton, Link } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const ListContainerColumnActionHover = props => {
  const { classes, column, entity, dispatch, refresh } = props
  const [anchorEl, setAnchorEl] = useState(null)
  let history = useHistory()

  /**
   *
   * @param {*} exec
   */
  const menuItemClickHandler = exec => {
    exec(entity, dispatch, refresh, history)

    handleClose()
  }

  const menuItemLinkClickHandler = link => {
    history.push(link)
  }

  /**
   *
   * @param {*} event
   */
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  /**
   *
   * @param {*} event
   */
  const handleClose = event => {
    setAnchorEl(null)
  }

  /**
   *
   * @param {*} action
   * @param {*} index
   */
  const renderMenuItem = (action, index) => {
    if (!action.hidden(entity)) {
      return (
        <MenuItem
          key={index}
          onClick={() => {
            menuItemClickHandler(action.exec)
          }}
          disabled={action.disabled(entity)}
        >
          {action.label}
        </MenuItem>
      )
    }
  }

  /**
   *
   * @param {*} action
   * @param {*} index
   */
  const renderLink = (action, index) => {
    return (
      <MenuItem
        key={index}
        component={Link}
        onClick={() => {
          menuItemLinkClickHandler(action.link(entity))
        }}
        //href={action.link(entity)}
        disabled={action.disabled(entity)}
      >
        {action.label}
      </MenuItem>
    )
  }

  return (
    <React.Fragment>
      <IconButton
        aria-label={translator._('general.actions')}
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.actionButton}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {column.actions.map((action, index) => {
          let element = ''
          switch (action.type) {
            case ButtonType.NORMAL:
              element = renderMenuItem(action, index)
              break
            case ButtonType.LINK:
              element = renderLink(action, index)
              break
            default:
              element = ''
              break
          }

          return element
        })}
      </Menu>
    </React.Fragment>
  )
}

ListContainerColumnActionHover.propTypes = propTypes

export default withStyles(style)(ListContainerColumnActionHover)
