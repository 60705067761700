const URL = {
  EXCEL_EXPORT_QUIZ_GAME_USER_COMPARE:
    'ExcelReport/QuizGameReportUserComparisonBySubjects',
  EXCEL_EXPORT_QUIZ_GAME_CONTENT_ANALYSIS:
    'ExcelReport/QuizGameContentAnalysis',
  EXCEL_EXPORT_QUIZ_GAME_TEAM_COMPARISON:
    'ExcelReport/QuizGameTeamComparisonByGameMetrics',
  EXCEL_EXPORT_QUESTION_USER_ANSWERS:
    'ExcelReport/QuestionAnalysisShowUserAnswers',
  EXCEL_EXPORT_USER_METRICS_WITH_DATE: 'ExcelReport/UserMetricsIncludingDate',
  EXCEL_EXPORT_USER_METRICS_WITHOUT_DATE: 'ExcelReport/UserMetricsIgnoringDate',
  EXCEL_EXPORT_USER_QUESTION_ANALYSIS: 'ExcelReport/UserQuestionAnalysis',
  EXCEL_EXPORT_SUBJECT_PARTICIPATION:
    'ExcelReport/SubjectReportParticipationByTeams',
  EXCEL_EXPORT_SUBJECT_PERFORMANCE: 'ExcelReport/SubjectPerformanceByTeams',
  EXCEL_EXPORT_QUIZ_GAME_LEADERBOARD_USER_METRICS:
    'ExcelReport/QuizGameLeaderboardUserMetrics',
  EXCEL_EXPORT_REINFORCEMENT_PARTICIPATION:
    'ExcelReport/ReinforcementTeamEngagement',
  EXCEL_EXPORT_REINFORCEMENT_PERFORMANCE:
    'ExcelReport/ReinforcementTeamPerformance',
  EXCEL_EXPORT_REINFORCEMENT_AND_TRAINING_IMPROVEMENT:
    'ExcelReport/ReinforcementAndTrainingImprovement',
  EXCEL_EXPORT_JOURNEY_PARTICIPATION: 'ExcelReport/TrainingTeamEngagement',
  EXCEL_EXPORT_JOURNEY_PERFORMANCE: 'ExcelReport/TrainingTeamPerformance',
  EXCEL_EXPORT_SUBJECT_ASSESSMENT_ITEMS: 'ExcelReport/SubjectAssessmentItems',
  EXCEL_EXPORT_SUBJECT_ASSESSMENT_ITEMS_WITH_USER_DATA:
    'ExcelReport/SubjectAssessmentUserAnswers',
  EXCEL_EXPORT_SUBJECT_NON_ASSESSMENT_ITEMS:
    'ExcelReport/SubjectNonAssessmentItems',
  EXCEL_EXPORT_SUBJECT_NON_ASSESSMENT_ITEMS_WITH_USER_DATA:
    'ExcelReport/SubjectNonAssessmentUserAnswers',
  EXCEL_EXPORT_SUBJECT_IMPROVEMENT: 'ExcelReport/SubjectImprovement',

  EXCEL_EXPORT_PARTICIPATION_WEEKLY: 'ExcelReport/ConsistencyStatisticsWeekly',
  EXCEL_EXPORT_PARTICIPATION_MONTHLY:
    'ExcelReport/ConsistencyStatisticsMonthly',
  EXCEL_EXPORT_PARTICIPATION_BY_PLATFORM_WEEKLY:
    'ExcelReport/ConsistencyStatisticsByPlatformWeekly',
  EXCEL_EXPORT_PARTICIPATION_BY_PLATFORM_MONTHLY:
    'ExcelReport/ConsistencyStatisticsByPlatformMonthly',

  EXCEL_EXPORT_PARTICIPATION_WEEKLY_SUPER:
    'ExcelReport/SuperConsistencyStatisticsWeekly',
  EXCEL_EXPORT_PARTICIPATION_MONTHLY_SUPER:
    'ExcelReport/SuperConsistencyStatisticsMonthly',
  EXCEL_EXPORT_PARTICIPATION_BY_PLATFORM_WEEKLY_SUPER:
    'ExcelReport/SuperConsistencyStatisticsByPlatformWeekly',
  EXCEL_EXPORT_PARTICIPATION_BY_PLATFORM_MONTHLY_SUPER:
    'ExcelReport/SuperConsistencyStatisticsByPlatformMonthly',

  EXCEL_EXPORT_ACTIVATION_STATS_SUPER: 'ExcelReport/SuperActivationStats',

  EXCEL_EXPORT_AUTO_SENDER_REPORTS: 'course/exportAnalyticsReport',

  EXCEL_EXPORT_COURSE_TIME_SERIES_REPORT: 'report/excelExportCourseTimeSeries',
  EXCEL_EXPORT_COURSE_STATISTICS_REPORT: 'report/excelExportCourseStatistics',
}

export default URL
