import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Icon } from '@iconify/react'
import heartOutline from '@iconify-icons/eva/heart-outline'
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  withStyles,
} from '@material-ui/core'
import styles from './styles'

const CustomDomainNonPremiumView = props => {
  const { onClickUpgrade, classes } = props

  return (
    <div>
      <React.Fragment>
        <Card className={classes.card}>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: 0,
            }}
          >
            <div className={classes.limitModalImage}>
              <img src="/assets/popup_girl.png" width="160px" />
            </div>
            <Typography
              align="center"
              fontSize="16px"
              fontWeight={600}
              color={'#002E47'}
            >
              Would you like to customize your links?
            </Typography>
            <Typography
              align="center"
              color="#637381"
              fontSize="14px"
              fontWeight={400}
            >
              With a higher plan, you will be able to connect several custom
              domains and offer your mini-courses and collections with your own
              link!
            </Typography>
            <Button
              to="/pricing"
              variant="contained"
              startIcon={<Icon icon={heartOutline} />}
              component={RouterLink}
              onClick={() => onClickUpgrade()}
              style={{
                width: '100%',
                height: '40px',
                backgroundColor: '#015FFB',
              }}
            >
              See Plans and Upgrade
            </Button>
          </CardContent>
        </Card>
      </React.Fragment>
    </div>
  )
}
export default withStyles(styles)(CustomDomainNonPremiumView)
