import React from 'react'
import style from './style'
import translator from '../../../../translate'
import { withStyles, Grid, Typography } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const NoData = props => {
  const { classes } = props

  return (
    <Grid
      container
      className={classes.root}
    >
      <Typography className={classes.content}>
        <img className={classes.image} src="/assets/no-result.gif" />
        <br></br>
        {translator._('general.noResultFound')}
      </Typography>
    </Grid>
  )
}

export default withStyles(style)(NoData)
