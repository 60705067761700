const styles = theme => ({
  divider: {},
  couponGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    spacing: 2,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningGrid: {
    maxWidth: 300,
    //margin: '0 auto',
    //justifyContent: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    width: '100%',
    height: undefined,
  },
  gridItem: {
    minWidth: 'max-content',
  },
  successGrid: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 300,
    margin: '0 auto',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  icon: {
    marginBottom: '10px',
  },
})

export default styles
