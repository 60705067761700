import React, { useEffect, useState } from 'react'
import styles from './style'
import propTypes from './prop'
import { Grid, withStyles, Typography, Card, Box } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { getUserLimits } from 'src/api/limit'

import { LimitTypeEnum } from 'src/model/limit'
import moment from 'moment-timezone'
/**
 *
 * @param {*} props
 */
const MediaHeader = props => {
  const {
    classes,
    closeHandler,
    setAiCount,
    count,
    tabValue,
    popupType,
  } = props

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '70px',
        marginRight: '-24px',
        marginLeft: '-24px',
        borderRadius: '8px',
        alignItems: 'center',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: '-webkit-fill-available',
      }}
    >
      {popupType == 'create prompt' ? (
        <>
          <Box m={0.5}></Box>
          <img style={{ width: '30px' }} src={`/assets/ai-image.png`} />
          <Box m={0.5}></Box>
          <Typography variant="h5" color="#015FFB">
            AI-Image Generator
          </Typography>
        </>
      ) : (
        <>
          <div
            style={{
              marginLeft: '24px',
            }}
          ></div>
          <img
            src="/assets/qc-ai-image-icon.svg"
            style={{ width: '24px', height: '24px' }}
          />
          <Typography
            style={{
              marginLeft: 8,
              color: '#002E47',
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Media Gallery
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
        </>
      )}
    </Card>
  )
}

MediaHeader.propTypes = propTypes

export default withStyles(styles)(MediaHeader)
