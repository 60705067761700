import PropTypes from 'prop-types'

const errorPropTypes = {
  success: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
}
const propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.shape(errorPropTypes).isRequired,
  groupId: PropTypes.string.isRequired,
  useMode: PropTypes.string.isRequired,
  updateEntityKeyValue: PropTypes.func.isRequired,
}

export default propTypes
