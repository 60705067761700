const style = theme => ({
  root: {
    //height: 60,
    //paddingRight: theme.spacing(2),
    //paddingLeft: theme.spacing(2),
    //backgroundColor: '#d4d4d4',
  },
  cancel: {
    marginRight: theme.spacing(1),
  },
})

export default style
