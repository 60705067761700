import { AUTHENTICATION_LOGIN_USER_SUCCESS } from './type'
import { AUTHENTICATION_LOGOUT_SUCCESS } from './type'
import { AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS } from './type'
import { AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS } from './type'
import { set as setCookie, remove as removeCookie } from 'es-cookie'
export default function (state = { me: null }, action) {
  switch (action.type) {
    case AUTHENTICATION_LOGIN_USER_SUCCESS:
      const { payload } = action
      const { user } = payload

      setCookie('token', user.accessToken, { sameSite: 'none',secure: true })

      window.location.href = '/'
      return { ...state, user }
    case AUTHENTICATION_LOGOUT_SUCCESS:
      return { ...state }

    case AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS:
      return { ...state }
    case AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS:
      return { ...state }
    default:
      return state
  }
}
