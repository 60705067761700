import {
  USER_ROLE_DETAILED_LIST,
  USER_ROLE_DETAILED_LIST_SUCCESS,
  USER_ROLE_DETAILED_LIST_ERROR,
  SET_USER_ROLE,
  SET_USER_ROLE_SUCCESS,
  SET_USER_ROLE_ERROR,
} from './type'

/**
 *
 * @param {*} query
 */
export const userRoleDetailedListAction = query => {
  return {
    type: USER_ROLE_DETAILED_LIST,
    payload: { query },
  }
}

/**
 *
 * @param {*} response
 */
export const userRoleDetailedListSuccess = response => {
  return {
    type: USER_ROLE_DETAILED_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userRoleDetailedListError = () => {
  return {
    type: USER_ROLE_DETAILED_LIST_ERROR,
  }
}
/**
 *
 * @param {*} data
 */
export const setUserRoleAction = data => {
  return {
    type: SET_USER_ROLE,
    payload: { data },
  }
}

/**
 *
 * @param {*} response
 */
export const setUserRoleSuccess = response => {
  return {
    type: SET_USER_ROLE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const setUserRoleError = () => {
  return {
    type: SET_USER_ROLE_ERROR,
  }
}
