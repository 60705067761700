import { guid } from '../../../../../utils/guid'
import SYSTEM_DATA from '../../../../../data'
import translator from '../../../../../translate'
import {
  FormItemType,
  FormItemFilterAddMode,
} from '../../../../../form/props/form-item/default'

const FormItemLoggedin = {
  id: guid().uid(),
  attribute: 'IsLoggedIn',
  label: translator._('general.loggedIn'),
  type: FormItemType.MENU,
  optionAttributeName: { label: 'label', value: 'value' },
  required: false,
  disabled: false,
  hidden: false,
  filterAddMode: FormItemFilterAddMode.SINGLE,
  options: () => {
    return SYSTEM_DATA.FILTER_OPTIONS.IsLoggedIn
  },
}

export default FormItemLoggedin
