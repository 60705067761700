import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import FormFactory from '../factory'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { PatchData } from '../../../../model/patch'
import isEmpty from '../../../../utils/is-empty'
import { withStyles, Grid, Paper, IconButton } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const FormItemList = props => {
  const {
    id,
    dispatch,
    classes,
    value,
    formItem,
    updateEntityKeyValue,
    useMode,
  } = props

  const [stateValue, setStateValue] = useState(value)

  /**
   *
   * @param {*} newValue
   * @param {*} formItemProps
   * @param {*} operation
   * @param {*} index
   */
  const formItemUpdateEntityKeyValue = (
    newValue,
    formItemProps,
    operation,
    index,
  ) => {
    const updatedValue = [...stateValue]

    updateEntityKeyValue(updatedValue, props, operation)

    setStateValue(updatedValue)
  }

  /**
   *
   */
  //const addNewItemHandler = () => {
  //    const newItem = itemDataCreator(value)
  //    const newValue = [...stateValue, newItem]
  //
  //    updateEntityKeyValue(newValue, props, PatchData.OPERATION_ADD)
  //
  //    setStateValue(newValue)
  //}

  /**
   *
   * @param {*} event
   */
  const removeItemHandler = event => {
    const itemIndex = parseInt(event.currentTarget.dataset.index)
    const clone = [...stateValue]
    const removeItem = clone.splice(itemIndex, 1)

    updateEntityKeyValue(
      clone,
      props,
      PatchData.OPERATION_REMOVE,
      removeItem[0],
    )

    setStateValue(clone)
  }

  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <React.Fragment>
          <Grid container>
            {stateValue &&
              stateValue.map((v, i) => (
                <Paper key={i} className={classes.listItemWrapper}>
                  {FormFactory({
                    ...formItem,
                    id: i,
                    value: v,
                    groupId: id,
                    useMode: useMode,
                    dispatch: dispatch,
                    error: { success: true, message: '' },
                    updateEntityKeyValue: (
                      updatedValue,
                      props,
                      operation,
                      patchData,
                    ) => {
                      formItemUpdateEntityKeyValue(
                        updatedValue,
                        props,
                        operation,
                        patchData,
                        i,
                      )
                    },
                  })}
                  <IconButton
                    className={classes.listItemRemoveButton}
                    data-index={i}
                    onClick={removeItemHandler}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Paper>
              ))}
          </Grid>
        </React.Fragment>
      }
    />
  )
}

FormItemList.propTypes = propTypes

export default withStyles(style)(FormItemList)
