import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from '../styles'

import UserType from 'src/model/user-types'

const Badge = props => {
  const { type, classes } = props

  const premiumControl = () => {
    const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]

    if (
      (userControl == 'Regular Admin Account' ||
        userControl == 'Starter' ||
        userControl == 'StarterStarter - Legacy' ||
        userControl == 'Starter - A' ||
        userControl == 'Appsumo (Tier 1)' ||
        userControl == 'Appsumo (Tier 1A)') &&
      type == 1
    ) {
      return {
        text: 'Premium',
        textColor: 'rgba(97, 104, 0, 1)',
        backgroundColor: 'rgba(231, 244, 48, 0.48)',
        width: '60px',
      }
    }
    if (
      !(
        userControl == 'Premium Plus' ||
        userControl == 'Premium Plus v2' ||
        userControl == 'Premium - A' ||
        userControl == 'Appsumo (Tier 3)' ||
        userControl == 'Appsumo (Tier 3A)' ||
        userControl == 'Super Admin'
      ) &&
      type == 2
    ) {
      return {
        text: 'Plus',
        textColor: 'rgba(246, 123, 63, 1)',
        backgroundColor: 'rgba(246, 123, 63, 0.08)',
        width: '30px',
      }
    }
    if (type == 3) {
      return {
        text: 'Enterprise',
        textColor: '#d5a831',
        backgroundColor: '#ffb74714',
        width: '70px',
      }
    }
    if (
      !(
        userControl == 'Premium Plus' ||
        userControl == 'Premium Plus v2' ||
        userControl == 'Premium - A' ||
        userControl == 'Premium' ||
        userControl == 'Premium v2' ||
        userControl == 'Appsumo (Tier 2)' ||
        userControl == 'Appsumo (Tier 2A)' ||
        userControl == 'Appsumo (Tier 3)' ||
        userControl == 'Appsumo (Tier 3A)'
      ) &&
      type == 4
    ) {
      return {
        text: 'Premium',
        textColor: 'rgba(144, 155, 0, 1)',
        backgroundColor: 'rgba(231, 244, 48, 0.32)',
        width: '60px',
      }
    }
  }
  const viewContents = premiumControl()

  return viewContents ? (
    <div
      className={classes.badge}
      style={{
        backgroundColor: viewContents.backgroundColor,
        width: viewContents.width,
      }}
    >
      <Typography
        fontSize={'11px'}
        fontWeight={500}
        style={{ color: viewContents.textColor }}
      >
        {viewContents.text}
      </Typography>
    </div>
  ) : null
}

export default withStyles(styles)(Badge)
