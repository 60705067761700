import React, { useState, useEffect } from 'react'

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  IconButton,
  Divider,
  Chip,
  Stack,
  Autocomplete,
  TextField,
} from '@material-ui/core'
import Iconify from 'src/components/Iconify'
import { withStyles, styled } from '@material-ui/core/styles'

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  backgroundColor: theme.palette.background.neutral,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}))

export default function SettingStretch(props) {
  const { themeStretch, onToggleStretch } = props

  const ICON_SIZE = {
    width: themeStretch ? 24 : 18,
    height: themeStretch ? 24 : 18,
  }

  return (
    <BoxStyle
      onClick={onToggleStretch}
      sx={{
        ...(themeStretch && {
          color: theme => theme.palette.primary.main,
        }),
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          px: 1,
          mx: 'auto',
          width: 0.5,
          height: 40,
          borderRadius: 1,
          color: 'action.active',
          bgcolor: 'background.default',
          transition: theme => theme.transitions.create('width'),
          ...(themeStretch && {
            width: themeStretch == 2 ? 0.5 : themeStretch == 1 ? 0.25 : 1,
            color: 'primary.main',
          }),
        }}
      >
        <Iconify
          icon={
            themeStretch == 3
              ? 'eva:arrow-ios-back-fill'
              : 'eva:arrow-ios-forward-fill'
          }
          {...ICON_SIZE}
        />
        <Iconify
          icon={
            themeStretch == 3
              ? 'eva:arrow-ios-forward-fill'
              : 'eva:arrow-ios-back-fill'
          }
          {...ICON_SIZE}
        />
      </Stack>
    </BoxStyle>
  )
}
