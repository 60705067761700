const styles = theme => ({
  grid: {
    width: '100%',
    maxWidth: 610,
    flexBasis: 'unset',
    textAlign: 'center',
  },
  input: {
    '& .MuiOutlinedInput-input': {
      color: '#637381',
    },
  },
  alertTexts: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

export default styles
