import PropTypes from 'prop-types'

export class ButtonType {
  static NORMAL = 0
  static ACTION = 1
  static WARNING = 2
  static LINK = 3
}

export class ButtonActionType {
  static ADD = 0
  static REMOVE = 1
  static DELETE = 2
  static EDIT = 3
  static SHARE = 4
  static CANCEL = 5
  static YES = 6
  static NO = 7
}

export const ButtonVariantTypes = {
  [ButtonActionType.ADD]: 'contained',
  [ButtonActionType.REMOVE]: 'contained',
  [ButtonActionType.DELETE]: 'contained',
  [ButtonActionType.EDIT]: 'contained',
  [ButtonActionType.SHARE]: 'contained',
  [ButtonActionType.CANCEL]: 'outlined',
  [ButtonActionType.YES]: 'contained',
  [ButtonActionType.NO]: 'outlined',
}

export const ButtonColorTypes = {
  [ButtonActionType.ADD]: 'primary',
  [ButtonActionType.REMOVE]: 'primary',
  [ButtonActionType.DELETE]: 'primary',
  [ButtonActionType.EDIT]: 'primary',
  [ButtonActionType.SHARE]: 'primary',
  [ButtonActionType.CANCEL]: 'primary',
  [ButtonActionType.YES]: 'primary',
  [ButtonActionType.NO]: 'primary',
}

export const ActionPropTypes = {
  link: PropTypes.func,
  icon: PropTypes.any,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    ButtonType.NORMAL,
    ButtonType.ACTION,
    ButtonType.WARNING,
    ButtonType.LINK,
  ]).isRequired,
  exec: PropTypes.func.isRequired,
  disabled: PropTypes.func.isRequired,
  hidden: PropTypes.func.isRequired,
}

/*eslint-disabled */
export class ButtonData {
  /**
   *
   * @param {ButtonType} type
   * @param {string} title
   * @param {ButtonActionType} actionType
   * @param {any} exec
   */
  constructor(type, title, actionType, exec, color) {
    this._type = type
    this._title = title
    this._actionType = actionType
    this._exec = exec
    this._color = color
  }

  /**
   *
   */
  get type() {
    return this._type
  }

  /**
   *
   */
  get title() {
    return this._title
  }

  /**
   *
   */
  get actionType() {
    return this._actionType
  }

  /**
   *
   */
  get exec() {
    return this._exec
  }

  get color() {
    return this._color
  }

  /**
   *
   */
  toJSON() {
    return {
      type: this._type,
      title: this._title,
      actionType: this._actionType,
      exec: this._exec,
      color: this._color,
    }
  }
}
/*eslint-enabled */
