import React from 'react'
import { Tooltip, Typography, withStyles } from '@material-ui/core'
import styles from '../styles'
import { Icon } from '@iconify/react'

const UserListWithError = props => {
  const { userListWithError, classes } = props
  return (
    <>
      <div className={classes.border}></div>
      <Typography fontSize={'12px'} fontWeight={500} color="#637381">
        Failed to import
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '4px',
          backgroundColor: '#F4F6F8',
          padding: '16px',
          borderRadius: '16px',
          gap: '12px',
        }}
      >
        {userListWithError.map(item => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 16,
              }}
            >
              <Typography fontSize={'12px'} fontWeight={500} color="#002E47">
                {item.name}
              </Typography>
              <>
                <Typography fontSize={'12px'} fontWeight={500} color="#002E47">
                  {item.email}
                </Typography>
                <Tooltip title={item.errors && item.errors[0]}>
                  <Icon
                    icon={'eva:alert-triangle-fill'}
                    height={20}
                    width={20}
                    style={{ marginLeft: '6px', color: '#FFAB00' }}
                  ></Icon>
                </Tooltip>
              </>
            </div>
          )
        })}
      </div>
      <div style={{ display: 'flex', gap: 4 }}>
        <Icon
          icon={'ph:question'}
          color="#637381"
          width={'16px'}
          height={'16px'}
        ></Icon>
        <Typography color="#637381" fontSize={'12px'} fontWeight={400}>
          It may have been added previously or contain incorrect data.
        </Typography>
      </div>
    </>
  )
}

export default withStyles(styles)(UserListWithError)
