import React from 'react'
import styles from '../styles'
import { withStyles, Typography } from '@material-ui/core'

import { useDispatch } from 'react-redux'
import { MailPreparation, MailSent } from '../index'
import { BottomButton, BackButton } from '../../../components'
import { sendMailToLearnerAction } from 'src/reducers/teams/action'
import { Icon } from '@iconify/react'
import { track } from '@amplitude/analytics-browser'
import { PatchContent, PatchData } from 'src/model/patch'
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'

const MagicViewContent = props => {
  const { entity, handleBack, closeDrawer, collectionSettings, classes } = props

  const dispatch = useDispatch()
  const [mailSent, setMailSent] = React.useState(false)
  const sendMail = () => {
    const courseCode = selectedCourseList[0].code
    dispatch(
      sendMailToLearnerAction(
        entity.id,
        sendLinkToNewMembersOnly,
        courseCode,
        response => setMailSent(true),
      ),
    )
    track('magiclink_email_sent')
  }
  const [
    sendLinkToNewMembersOnly,
    setSendLinkToNewMembersOnly,
  ] = React.useState(collectionSettings.sendLinkToNewMembersOnly)

  const handleSendLinkToNewMembersOnly = value => {
    setSendLinkToNewMembersOnly(value)
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(
        value,
        'sendLinkToNewMembersOnly',
        PatchData.OPERATION_REPLACE,
      ),
    )

    dispatch(patchCollectionSettingsAction(patchSource, response => {}))
  }
  const [selectedCourseList, setSelectedCourseList] = React.useState([])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 16,
      }}
    >
      <BackButton handleBack={handleBack} />

      {mailSent ? (
        <>
          <MailSent closeDrawer={closeDrawer} />
          <BottomButton
            onClick={() => closeDrawer()}
            title={'Close'}
            className={classes.mailSentBottomButton}
            variant={'outlined'}
            disabled={false}
          />
        </>
      ) : (
        <>
          <div className={classes.displayF}>
            <Icon
              icon={'icon-park-outline:magic'}
              className={classes.magic_link_icon}
            />
            <Typography
              fontSize={'16px'}
              fontWeight={600}
              style={{ color: '#002E47' }}
            >
              Invite group users by magic link
            </Typography>
          </div>
          <MailPreparation
            handleSendLinkToNewMembersOnly={handleSendLinkToNewMembersOnly}
            sendLinkToNewMembersOnly={sendLinkToNewMembersOnly}
            entity={entity}
            collectionSettings={collectionSettings}
            dispatch={dispatch}
            selectedCourseList={selectedCourseList}
            setSelectedCourseList={setSelectedCourseList}
          />
          <BottomButton
            onClick={() => collectionSettings.teams.length != 0 && sendMail()}
            title={'Send Mail'}
            className={
              collectionSettings.teams.length == 0 ||
              selectedCourseList.length == 0
                ? classes.mailPreparationBottomDimButton
                : classes.mailPreparationBottomButton
            }
            variant={'contained'}
          />
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(MagicViewContent)
