const styles = theme => ({
  namingModalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadiusMd,
    minHeight: 250,
    width: 600,
    backgroundColor: '#fff',
    margin: 'auto',
    paddingTop: 24,
  },
  modalItems: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3),
    marginLeft: 25,
    marginBottom: 31,
    marginTop: 31,
  },
  fields: { marginBottom: theme.spacing(3) },
  input: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  select: {
    marginTop: '0!important',
  },
})

export default styles
