import React from 'react'
import style from './style'
import propTypes from './prop'
import { withStyles, Grid, Typography } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const Footer = props => {
  const { classes, label } = props
  if (!label) {
    return null
  }

  return (
    <Grid className={classes.root} container alignItems="center">
      <Typography className={classes.title} variant="h5">
        {label}
      </Typography>
    </Grid>
  )
}

Footer.propTypes = propTypes

export default withStyles(style)(Footer)
