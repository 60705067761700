import React from 'react'
import { IconButton, Typography, withStyles, MenuItem } from '@material-ui/core'
import MenuPopover from 'src/components/MenuPopover'

import styles from './styles'
import Iconify from 'src/components/Iconify'
import TeamNamingModal from './team-add'
import { TeamDelete } from './index'
const TeamView = props => {
  const { teamId, teamName, userCount, dispatch, classes } = props
  const [open, setOpen] = React.useState(null)
  const [openNamingModal, setOpenNamingModal] = React.useState(false)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const handleOpen = event => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleEditTeam = () => {
    setOpenNamingModal(true)
  }

  const handleDeleteTeam = () => {
    setOpenDeleteModal(true)
  }

  const handleEditMembers = () => {
    window.location.href = `/users-member-list/${teamId}`
  }

  return (
    <div className={classes.team_list_root}>
      <IconButton
        size="small"
        onClick={handleOpen}
        color={open ? 'inherit' : 'default'}
        className={classes.iconButton}
      >
        <Iconify icon={'ri:more-2-fill'} width={20} height={20} />
      </IconButton>
      <div
        className={classes.team_list_root_avatar}
        onClick={() => handleEditMembers()}
      >
        <img width={20} height={15} src="/assets/vector.png " />
      </div>
      <div className={classes.team_list_root_column}>
        <div
          className={classes.root__teamName}
          onClick={() => handleEditMembers()}
        >
          <Typography fontSize={'16px'} fontWeight={500}>
            {teamName}
          </Typography>
        </div>
        <div className={classes.root__teamName__number}>
          <Typography fontSize={'14px'} fontWeight={600} className={classes.mr}>
            {userCount}
          </Typography>
          <Typography
            fontSize={'12px'}
            fontWeight={400}
            color="#637381"
            className={classes.root__teamName_divider}
          >
            Users
          </Typography>
        </div>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          className={classes.menu_popover}
        >
          {' '}
          <MenuItem
            onClick={() => {
              handleEditMembers()
            }}
          >
            Edit users
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleEditTeam()
            }}
          >
            Rename group
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDeleteTeam()
            }}
          >
            Delete group
          </MenuItem>
        </MenuPopover>

        {openNamingModal && (
          <TeamNamingModal
            isModalOpen={openNamingModal}
            handleCloseModal={() => setOpenNamingModal(false)}
            dispatch={dispatch}
            teamInfo={{ teamId, teamName }}
          />
        )}
        {openDeleteModal && (
          <TeamDelete
            isModalOpen={openDeleteModal}
            handleCloseModal={() => setOpenDeleteModal(false)}
            dispatch={dispatch}
            teamInfo={{ teamId, teamName }}
            handleClose={handleClose}
          />
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(TeamView)
