import { guid } from '../../../../../utils/guid'
import { subjectListByParentService } from '../../../../../api/subject'
import translator from '../../../../../translate'
import {
  FormItemType,
  FormItemFilterAddMode,
} from '../../../../../form/props/form-item/default'

const FormItemSubject = {
  id: guid().uid(),
  attribute: 'SubjectId',
  label: translator._('general.subject'),

  type: FormItemType.TREE,
  required: false,
  disabled: false,
  hidden: false,
  nodeOperation: subjectListByParentService,
  optionAttributeName: { value: 'id', label: 'name' },
  filterAddMode: FormItemFilterAddMode.SINGLE,
  leafNodeControl: node => {
    return node.childCount === 0
  },
}

export default FormItemSubject
