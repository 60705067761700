import React from 'react'
import styles from './style'
import propTypes from './prop'
import CenterBox from '../center-box'
import ClassNames from 'classnames'
import { ButtonActionType, ButtonColorTypes, ButtonVariantTypes } from '../../model/button'
import {
  Paper,
  withStyles,
  Slide,
  Grid,
  Button,
  Typography,
} from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const MessageBox = props => {
  const { classes, content } = props

  return (
    <React.Fragment>
      {content && (
        <Paper elevation={4} className={classes.paper}>
          <CenterBox>
            <Grid container justifyContent="center">
              <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Grid item className={classes.grid}>
                  <Grid item xs="auto">
                    <Typography variant="h6">{content.message}</Typography>
                    <Typography variant="h8">{content.description}</Typography>
                  </Grid>
                  <div className={classes.buttons}>
                    {content.buttons.map((button, buttonIndex) => (
                      <Button
                        key={buttonIndex}
                        onClick={button.exec}
                        className={ClassNames({
                          [classes.button]: true,
                          [classes.buttonDelete]:
                            button.actionType === ButtonActionType.DELETE,
                        })}
                        variant={ButtonVariantTypes[button.actionType] || 'outlined'}
                        color={button.color || ButtonColorTypes[button.actionType]}
                        fullWidth
                      >
                        {button.title}{' '}
                      </Button>
                    ))}
                  </div>
                </Grid>
              </Slide>
            </Grid>
          </CenterBox>
        </Paper>
      )}
    </React.Fragment>
  )
}

MessageBox.propTypes = propTypes

export default withStyles(styles)(MessageBox)
