import React, { Component, useCallback, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import axios from 'axios'
import styles from './styles'
import propTypes from './prop'
import translator from '../../translate'
import parse from 'html-react-parser'

import Instructions from './instructions'
import { Icon } from '@iconify/react'
import heartOutline from '@iconify-icons/eva/heart-outline'
import { Link as RouterLink } from 'react-router-dom'
import {
  assignmentAddBulkQuestionAction,
  openaiSendRequestAction,
} from '../../reducers/assignment/action'
import { getUserLimits } from '../../api/limit'

import { componentBoxHideAction } from '../../reducers/component-box/action'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import {
  QuestionSchema,
  QuestionFieldNames,
  AnswerItemType,
  SelectionSchema,
  SelectionFieldNames,
  QuestionMediaSchema,
  QuestionMediaFieldNames,
} from '../../model/question'
import { AssignmentSchema, AssignmentFieldNames } from '../../model/assignment'
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Box,
  Alert,
} from '@material-ui/core'
import { LimitTypeEnum } from 'src/model/limit'
import moment from 'moment-timezone'
import UserType from 'src/model/user-types'

const unsplashCode = `4I16r0PnR9RHTjdXadlv59qrCNXjYc0kE970b-7WWcQ`
class Openai extends Component {
  state = {
    response: '',
    prompt: '',
    topic: '',
    topicCharacterCount: 0,
    request: '',
    process: { working: false },
    imageUrl: [],
    userType: UserType[window.CONSTANTS.USER_ROLE_ID],
    count: 0,
    limit: 5,
    refreshedAt: '',
  }

  /**
   *
   */
  setImage = t => {
    this.setState(state => ({
      imageUrl: this.state.imageUrl.push(t),
    }))
  }

  setResponse = async l => {
    this.setState({
      response: l,
    })
    let e = l
      .replaceAll('\\n', '\n')
      .replaceAll(`\"`, `"`)
      .replaceAll(` \n`, `\n`)
      .replaceAll(`\n\n`, `\n`)
    let list = []
    let info = [e.split('Keywords:')[0].replaceAll('\n\n', '\n').split('\n')]
    let a = info[0].length - 1
    let keywords = //keywords çıkarıyoruz
      e.split('Keywords:').length > 1
        ? [
            e
              .split('Keywords:')[1]
              .split('#Questions#')[0]
              .split('\n')[0]
              .split(','),
          ][0]
        : []

    let imageList = null
    await axios //fotoğrafları seçiyoruz
      .get('https://api.unsplash.com/search/photos', {
        params: {
          query: this.state.topic,
          per_page: 10,
        },
        headers: {
          Authorization: 'Client-ID ' + unsplashCode,
        },
      })
      .then(output => {
        imageList = output.data.results
      })
      .catch(err => {
        console.log(err)
      })

    for (var i = 0; i < a; i++) {
      let questionEntity = QuestionSchema()
      questionEntity[QuestionFieldNames.QTEXT] = info[0][i]
      questionEntity[QuestionFieldNames.QUESTION_TYPE] = AnswerItemType.INFORM //paragrafları alıyoruz
      questionEntity[QuestionFieldNames.HAS_AFTER_INFO] = false
      questionEntity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      questionEntity[QuestionFieldNames.ANSWER_TYPE] = 5
      questionEntity[QuestionFieldNames.IS_POLL] = true
      if (imageList.length > 0 && i < 2) {
        let mediaEntity = QuestionMediaSchema()
        mediaEntity[QuestionMediaFieldNames.MEDIA_URL] =
          imageList[i].urls.regular
        mediaEntity[QuestionMediaFieldNames.LOCATION_TYPE] = 1
        mediaEntity[QuestionMediaFieldNames.QUESTION_ID] = 0
        mediaEntity[QuestionMediaFieldNames.MEDIA_TYPE_ID] = 1
        questionEntity[QuestionFieldNames.QUESTION_MEDIAS].push(mediaEntity)
      }

      list.push(questionEntity)
    }
    let questionsCount =
      e.split('#Questions#').length > 1
        ? e.split('#Questions#')[1].split('?')
        : []
    let b = questionsCount.length
    let answersCount = e.split('Answer:').length
    if (questionsCount.length > 0) {
      for (var m = 1; m < answersCount; m++) {
        let questionEntity = QuestionSchema()
        let d =
          e
            .split('#Questions#')[1]
            .replaceAll('. ', '.')
            .split(m + '.')[1]
            .split('?')[0] + '?' // soru textini alıyoruz. 1., 2. den ayırıyoruz.
        questionEntity[QuestionFieldNames.QTEXT] = d
        let selections = [
          e
            .split('#Questions#')[1]
            .split('Answer: ')
            [m - 1].split('?\n')[1]
            .split(`\n`), //soruların seçeneklerini buluyoruz
        ][0]
        let answers = e
          .split('Answer:')
          [m].split('\n')[0]
          .replaceAll(' ', '')
          .split(',')
        let selectionCount = selections.length - 1
        let correctAnswerCount = answers.length
        for (var s = 0; s < selectionCount; s++) {
          let selectionEntity = SelectionSchema()
          selectionEntity[SelectionFieldNames.STEXT] = selections[s]
          let isCorrect = false
          questionEntity[QuestionFieldNames.QUESTION_TYPE] =
            correctAnswerCount == 1
              ? AnswerItemType.TESTINGSINGLECHOICE
              : AnswerItemType.TESTINGMULTIPLECHOICE //Soruların tek cevaplımı çok cevaplımı olduğunu buluyoruz
          for (var c = 0; c < correctAnswerCount; c++) {
            if (answers[c].indexOf(selections[s].split('.')[0]) !== -1) {
              isCorrect = true
            }
          }
          selectionEntity[SelectionFieldNames.ISCORRECT] = isCorrect
          selectionEntity[SelectionFieldNames.QUESTION_ID] = 0
          questionEntity[QuestionFieldNames.SELECTIONS].push(selectionEntity)
        }

        list.push(questionEntity)
        //list.splice(2 * m - 1, 0, questionEntity)
      }
    }
    let assignment = AssignmentSchema()
    assignment[AssignmentFieldNames.NAME] = this.state.topic
    assignment[AssignmentFieldNames.QUESTION_DTOS] = list

    this.props.dispatch(
      assignmentAddBulkQuestionAction(assignment, this.props.history, () => {
        this.setState({ process: { working: false } })
      }),
    )
  }

  callback = text => {
    if (text && text != '') {
      this.setResponse(text)
    }
  }

  handleClick = event => {
    event.preventDefault()
    this.setState({ process: { working: true } })
    this.props.dispatch(
      openaiSendRequestAction(this.state.topic, this.callback),
    )
  }
  handleChange = event => {
    this.setState({
      topic: event.target.value,
      topicCharacterCount: event.target.value.length,
    })
  }

  componentDidMount() {
    this.props.dispatch(componentBoxHideAction())
    getUserLimits().then(response => {
      let count = response.data.find(
        x => x.limit.type == LimitTypeEnum.ActiveQuizzes,
      ).count

      let limit = response.data.find(
        x => x.limit.type == LimitTypeEnum.ActiveQuizzes,
      ).limit.upperLimit

      let refreshedAt = response.data.find(
        x => x.limit.type == LimitTypeEnum.ActiveQuizzes,
      ).refreshedAt

      this.setState({
        count,
        limit,
        refreshedAt,
      })
    })
  }

  /**
   *
   */
  render() {
    const { classes, assignment, authentication, dispatch } = this.props
    const {
      topicCharacterCount,
      process,
      count,
      limit,
      refreshedAt,
    } = this.state
    //const prevEntity = useRef()
    const handleInstructions = () => {
      dispatch(
        componentBoxShowAction(
          new ComponentBoxContent(<Instructions />, {
            hideCloseButton: false,
          }),
        ),
      )
    }
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} square>
          <div>
            <form className={classes.form} onSubmit={this.handleClick}>
              <Grid container spacing={2}>
                {process.working && (
                  <Grid item className={classes.loadingHolder}>
                    <div className={classes.title}>
                      <a className={classes.animationTitle}>
                        {parse(translator._('openai.prepere'))}
                      </a>
                      <a>{translator._('openai.prepereSub')}</a>
                    </div>
                  </Grid>
                )}
                {!process.working && (
                  <Grid xs={12}>
                    <Typography
                      variant="h3"
                      align="center"
                      className={classes.header}
                    >
                      {parse(translator._('openai.title'))}
                    </Typography>
                    {count >= limit ? (
                      <Typography
                        align="center"
                        sx={{ color: 'text.secondary' }}
                      >
                        {translator._('openai.descriptionFree')}
                      </Typography>
                    ) : (
                      <div>
                        <Typography
                          align="center"
                          sx={{ color: 'text.secondary' }}
                        >
                          {translator._('openai.description')}{' '}
                        </Typography>
                      </div>
                    )}

                    <Box m={5}></Box>

                    {count >= limit ? (
                      <div className={classes.upgradeArea}>
                        <img
                          src="/assets/create-course-with-ai.gif"
                          width="75%"
                          alt=""
                          className={classes.upgradeGif}
                        />

                        <Typography align="center">
                          {translator._('openai.upgrade')}
                          <Box m={3}></Box>
                          <Button
                            to="/pricing"
                            variant="contained"
                            color="primary"
                            startIcon={<Icon icon={heartOutline} />}
                            component={RouterLink}
                          >
                            {translator._('openai.button')}
                          </Button>
                        </Typography>
                      </div>
                    ) : (
                      <div className={classes.textAreaHolder}>
                        {count === 0 && !(count > 0) ? (
                          <p className={classes.limitText}>
                            {translator._('openai.limitZero')}
                          </p>
                        ) : (
                          <p className={classes.limitText}>
                            {limit - count} AI Credits left. It will be renewed
                            on {moment(refreshedAt).add('days', 30).format('L')}
                            .
                          </p>
                        )}
                        {count < limit ? (
                          <React.Fragment>
                            <TextField
                              required
                              fullWidth
                              onChange={this.handleChange}
                              label={translator._('assignment.topic')}
                              placeholder={translator._(
                                'assignment.exampleTopic',
                              )}
                              inputProps={{ maxLength: 80 }}
                            />
                            <div className={classes.instructionBox}>
                              <Typography
                                variant="paragraph"
                                className={classes.instructionsHover}
                                onClick={handleInstructions}
                                color="primary"
                              >
                                Check instructions
                              </Typography>
                              <div className={classes.grow}></div>
                              <p className={classes.characterCount}>
                                {topicCharacterCount}/80
                              </p>
                            </div>

                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                            >
                              {translator._('assignment.createAICourse')}
                            </Button>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Alert severity="warning">
                              <p>
                                {translator._('openai.limit')}{' '}
                                {moment(refreshedAt)
                                  .add('days', 30)
                                  .format('L')}
                                .
                              </p>
                            </Alert>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    )
  }
  /**
   *
   */
}
Openai.propTypes = propTypes

const mapStateToProps = response => ({
  assignment: response.assignment,
  authentication: response.authentication,
})

export default connect(mapStateToProps)(withStyles(styles)(Openai))
