import React from 'react'
import styles from '../../renderer/avatar-body/avatar-list-item/styles'
import { ButtonType } from '../../../../model/button'
import { useHistory } from 'react-router-dom'
import { withStyles, IconButton } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const ListContainerColumnAction = props => {
  const { classes, column, entity, dispatch, refresh } = props
  let history = useHistory()

  /**
   *
   * @param {*} exec
   */
  const menuItemClickHandler = exec => {
    exec(entity, dispatch, refresh, history)
  }

  const menuItemLinkClickHandler = link => {
    history.push(link)
  }

  /**
   *
   * @param {*} action
   * @param {*} index
   */
  const renderMenuItem = (action, index) => {
    if (!action.hidden(entity)) {
      let ButtonIcon = action.icon
      return (
        <IconButton
          key={index}
          onClick={() => {
            menuItemClickHandler(action.exec)
          }}
          size="small"
          disabled={action.disabled(entity)}
          className={classes.iconButton}
        >
          <ButtonIcon className={classes.icon} />
        </IconButton>
      )
    }
  }

  /**
   *
   * @param {*} action
   * @param {*} index
   */
  const renderLink = (action, index) => {
    let ButtonIcon = action.icon
    return (
      <IconButton
        disabled={action.disabled(entity)}
        onClick={() => {
          menuItemLinkClickHandler(action.link(entity))
        }}
        size="small"
      >
        <ButtonIcon className={classes.icon} />
      </IconButton>
    )
  }

  return (
    <React.Fragment>
      {column.actions.map((action, index) => {
        let element = ''
        switch (action.type) {
          case ButtonType.NORMAL:
            element = renderMenuItem(action, index)
            break
          case ButtonType.LINK:
            element = renderLink(action, index)
            break
          default:
            element = ''
            break
        }
        return element
      })}
    </React.Fragment>
  )
}

export default withStyles(styles)(ListContainerColumnAction)
