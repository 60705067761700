import { put, call, take, fork } from 'redux-saga/effects'
import { openaiSendCourseCreationGetOutlineRequestService } from '../../api/assignment'
import { OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* openAiCourseCreationGetOutlineRequestFlow(payload) {
  try {
    const { profession, audience, title, language, purpose, callback } = payload

    const response = yield call(
      openaiSendCourseCreationGetOutlineRequestService,
      profession,
      audience,
      title,
      language,
      purpose,
    )

    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* openAiCourseCreationGetOutlineRequestWatcher() {
  while (true) {
    const action = yield take(OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST)
    const { payload } = action

    yield fork(
      generalSaga,
      openAiCourseCreationGetOutlineRequestFlow,
      payload,
      false,
    )
  }
}
