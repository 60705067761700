import { put, call, take, fork } from 'redux-saga/effects'
import { signUpService } from '../../api/sign-up'
import { signUpSuccess } from '../../reducers/sign-up/action.js'
import { SIGN_UP } from '../../reducers/sign-up/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* signUpSagaFlow(payload) {
  try {
    const { data } = payload
    const response = yield call(signUpService, data)

    yield put(signUpSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* signUpSagaWatcher() {
  while (true) {
    const action = yield take(SIGN_UP)
    const { payload } = action

    yield fork(generalSaga, signUpSagaFlow, payload)
  }
}
