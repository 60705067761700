import translator from 'src/translate'
import React, { useEffect, useState } from 'react'
import Webhook from './tabs/webhook-settings'
import SeoSettings from './tabs/seo-settings'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import { Drawer, Tab, Typography } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import StarIcon from '@material-ui/icons/Star'
import CodeIcon from '@material-ui/icons/Code'
import PhonelinkIcon from '@material-ui/icons/Phonelink'
import AdvancedSettings from './tabs/advanced-settings'
import GeneralSettings from './tabs/basics-settings'
import ThemeSettings from './tabs/theme-settings'
import { Icon } from '@iconify/react'
import UserType, { UserTypeNames } from 'src/model/user-types'

const SettingsDrawer = props => {
  const { setSettingDrawerOpen, drawerOpen, dispatch, entity, classes } = props
  const [currentTab, setCurrentTab] = useState('0')
  const isUserPremium =
    UserType[window.CONSTANTS.USER_ROLE_ID] !==
      UserTypeNames.REGULAR_ADMIN_ACCOUNT &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.PREMIUM &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.PREMIUM_V2 &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1 &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1A

  const [assignmentDescription, setAssignmentDescription] = useState(
    entity?.description || '',
  )
  const [assignmentTitle, setAssignmentTitle] = useState(
    entity ? entity.name : '',
  )
  useEffect(() => {
    setAssignmentDescription(entity?.description || '')
    setAssignmentTitle(entity ? entity.name : '')
  }, [entity])

  const handleTabChange = (event, newValue) => setCurrentTab(newValue)
  const SettingsTabs = [
    {
      label: translator._('assignment.courseSettings.theme'),
      component: entity => <ThemeSettings entity={entity} />,
      enabled: true,
    },
    {
      label: 'Basics',
      component: (entity, setSettingDrawerOpen) => (
        <GeneralSettings
          entity={entity}
          setSettingDrawerOpen={setSettingDrawerOpen}
          assignmentDescription={assignmentDescription}
          setAssignmentDescription={setAssignmentDescription}
          assignmentTitle={assignmentTitle}
          setAssignmentTitle={setAssignmentTitle}
        />
      ),
      enabled: true,
    },
    /*{
      label: translator._('assignment.courseSettings.seo'),
      component: (entity, setSettingDrawerOpen) => (
        <SeoSettings
          entity={entity}
          setSettingDrawerOpen={setSettingDrawerOpen}
          assignmentDescription={assignmentDescription}
          setAssignmentDescription={setAssignmentDescription}
          assignmentTitle={assignmentTitle}
          setAssignmentTitle={setAssignmentTitle}
        />
      ),
      disabled: false,
    },*/

    {
      label: 'Advanced',
      icon: isUserPremium ? (
        ''
      ) : (
        <Icon
          style={{ width: '15px', color: '#f7b155', marginRight: 3 }}
          icon="fa6-solid:crown"
        ></Icon>
      ),
      component: (entity, setSettingDrawerOpen) => (
        <AdvancedSettings
          entity={entity}
          setSettingDrawerOpen={setSettingDrawerOpen}
        />
      ),
      enabled: true,
    },
    {
      icon: isUserPremium ? (
        ''
      ) : (
        <Icon
          style={{ width: '15px', color: '#f7b155', marginRight: 3 }}
          icon="fa6-solid:crown"
        ></Icon>
      ),
      label: translator._('assignment.courseSettings.webhook'),
      component: (entity, setSettingDrawerOpen) => (
        <Webhook entity={entity} setSettingDrawerOpen={setSettingDrawerOpen} />
      ),
      disabled: false,
    },
  ]
  return (
    <div>
      <Drawer
        variant="temporary"
        anchor="right"
        classes={{ paper: classes.drawer }}
        open={drawerOpen}
        onClose={() => setSettingDrawerOpen(false)}
        onOpen={() => setSettingDrawerOpen(true)}
        transitionDuration={{ enter: 700, exit: 700 }}
      >
        <div className={classes.list}>
          <div>
            <TabContext value={currentTab}>
              <Typography
                variant="h5"
                style={{ textAlign: 'center', marginTop: '16px' }}
              >
                Settings
              </Typography>
              <TabList
                centered
                onChange={handleTabChange}
                className={classes.tabList}
                variant="scrollable"
                TabIndicatorProps={{
                  style: { background: '#00AB55' },
                }}
              >
                {SettingsTabs.map((value, index) => {
                  return (
                    <Tab
                      key={index}
                      label={value.label}
                      icon={value.icon}
                      value={index.toString()}
                      className={classes.tab}
                      disabled={value.disabled}
                    />
                  )
                })}
              </TabList>

              {SettingsTabs.map((value, index) => {
                return (
                  <TabPanel
                    className={classes.tabContainer}
                    key={index}
                    value={index.toString()}
                    hidden={Number(currentTab) !== Number(index)}
                  >
                    {value.component(entity, setSettingDrawerOpen)}
                  </TabPanel>
                )
              })}
            </TabContext>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default withStyles(styles)(SettingsDrawer)
