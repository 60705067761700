import React from 'react'
import styles from './style'
import propTypes from './prop'
import { Grid, withStyles } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const QuestionStars = props => {
  const { classes } = props

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item className={classes.grid}>
        <img src="/assets/stars.gif" width="75%" alt="" />
      </Grid>
    </Grid>
  )
}

QuestionStars.propTypes = propTypes

export default withStyles(styles)(QuestionStars)
