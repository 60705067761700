import { put, call, take, fork } from 'redux-saga/effects'
import { courseSettingPatchService } from '../../api/assignment'
//import { questionPatchSuccess } from '../../reducers/question/action'
import { courseSettingPatchSuccess } from '../../reducers/assignment/action'
import { COURSE_SETTING_PATCH } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* courseSettingsPatchFlow(payload) {
  try {
    const { content, questionId, settingId, key, callback } = payload

    yield call(courseSettingPatchService, { data: [content], id: settingId })
    yield put(courseSettingPatchSuccess(content, settingId, key, questionId))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* courseSettingsPatchWatcher() {
  while (true) {
    const action = yield take(COURSE_SETTING_PATCH)
    const { payload } = action

    yield fork(generalSaga, courseSettingsPatchFlow, payload, false)
  }
}
