import React, { useState } from 'react'
import translator from 'src/translate'
import { assignmentAddAction } from '../../../reducers/assignment/action'
import {
  AssignmentFieldNames,
  AssignmentSchema,
  AssignmentTypes,
} from '../../../model/assignment'
import {
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core'

const NamingModal = props => {
  const {
    history,
    dispatch,
    handleCloseModal,
    classes,
    isModalOpen,
    selectedAssignmentType,
    entity,
  } = props

  const [assignmentTitle, setAssignmentTitle] = useState('')

  const handleCreateButton = () => {
    dispatch(
      assignmentAddAction(
        {
          ...AssignmentSchema(),
          [AssignmentFieldNames.NAME]: assignmentTitle,
          [AssignmentFieldNames.IS_PERIODIC]: selectedAssignmentType,
        },
        history,
      ),
    )
  }

  const handleTextFieldChange = e => {
    setAssignmentTitle(e.target.value)
  }

  const isTextFieldValid = () => {
    if (assignmentTitle.length >= 1) {
      return true
    }
    return false
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleCloseModal()}
      className={classes.modal}
    >
      <div className={classes.namingModalContent}>
        <div className={classes.headerGroup}>
          <Typography variant={'h5'}>
            {selectedAssignmentType === AssignmentTypes.ONETIME &&
              translator._('assignment.createOneTime')}
            {selectedAssignmentType === AssignmentTypes.AUTOMATED &&
              translator._('assignment.createAutomated')}
          </Typography>
        </div>
        <TextField
          id="outlined-basic"
          label={translator._('assignment.assignmentName')}
          onChange={handleTextFieldChange}
          className={classes.textField}
        />
        <Divider />
        <div className={classes.buttonGroup}>
          <Button variant="outlined" color="inherit" onClick={handleCloseModal}>
            {translator._('assignment.back')}
          </Button>
          <Button
            variant="contained"
            disabled={isTextFieldValid() ? false : true}
            className={classes.continueButton}
            onClick={handleCreateButton}
          >
            {translator._('assignment.create')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default NamingModal
