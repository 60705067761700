import React from 'react'
import style from './style'
import propTypes from './prop'
import { PaginationActions } from '../'
import translator from '../../../../translate'
import {
  withStyles,
  TableRow,
  TableFooter,
  TablePagination,
  TableCell,
  Typography,
  Table,
} from '@material-ui/core'
import { ListFooterType } from 'src/model/list'

/**
 *
 * @param {*} props
 */
const Footer = props => {
  const {
    classes,
    queryResult,
    query,
    changePage,
    changeRowsPerPage,
    selectedCount,
    footerType,
  } = props
  /**
   *
   * @param {*} event
   * @param {*} newPage
   */
  const handleChangePage = (event, newPage) => {
    changePage(newPage)
  }

  /*
   *
   */
  const handleChangeRowsPerPage = event => {
    changeRowsPerPage(parseInt(event.target.value))
  }

  if (footerType === ListFooterType.GRID) {
    return (
      <div className={classes.gridFooter}>
        {selectedCount !== 0 && (
          <Typography variant="body1">
            {selectedCount + translator._('list.itemSelected')}
          </Typography>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 40, 60]}
          count={queryResult.totalCount}
          rowsPerPage={query.PageSize}
          page={query.CurrentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={PaginationActions}
          SelectProps={{
            native: true,
            className: classes.paginationSelect,
          }}
          labelRowsPerPage={translator._('list.rowsPerPage')}
        />
      </div>
    )
  }

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={1000} className={classes.countCell}>
          <Table>
            <TableFooter>
              <TableRow>
                {selectedCount !== 0 && (
                  <TableCell className={classes.countCell} colSpan="2">
                    <Typography variant="body1">
                      {selectedCount + translator._('list.itemSelected')}
                    </Typography>
                  </TableCell>
                )}

                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 40, 60]}
                  count={queryResult.totalCount}
                  rowsPerPage={query.PageSize}
                  page={query.CurrentPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={PaginationActions}
                  SelectProps={{
                    native: true,
                    className: classes.paginationSelect,
                  }}
                  labelRowsPerPage={translator._('list.rowsPerPage')}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}

Footer.propTypes = propTypes

export default withStyles(style)(Footer)
