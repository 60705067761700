import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import FormShop from '../../container/form-shop-settings'
import { Typography, Grid } from '@material-ui/core'
import {
  shopUpdateEntityAction,
  shopGetAction,
} from '../../reducers/shop/action'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
class Shop extends Component {
  /**
   *
   */
  render() {
    const { entity, dispatch, classes } = this.props
    let children = <Typography />

    if (entity != null) {
      children = (
        <div className={classes.holder}>
          <Grid>
            <FormShop entity={entity} dispatch={dispatch} />
          </Grid>
        </div>
      )
    }

    return children
  }
  /**
   *
   */
  componentDidMount() {
    const { dispatch } = this.props

    dispatch(shopGetAction())
  }

  /**
   *
   */
  componentWillUnmount() {
    const { dispatch } = this.props

    dispatch(shopUpdateEntityAction(null))
  }
}

Shop.propTypes = propTypes

const mapStateToProps = response => ({
  entity: response.shop.entity,
})

export default connect(mapStateToProps)(withStyles(styles)(Shop))
