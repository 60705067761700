import React from 'react'
// material
import { Container, withStyles } from '@material-ui/core'

import style from './style'

function Roadmap(props) {
  const { classes } = props

  return (
    <Container>
      <iframe
        width="100%"
        height="1500"
        className={classes.iframe}
        title="roadmap"
        src="https://app.loopedin.io/roadmap-98ke"
      ></iframe>
    </Container>
  )
}

export default withStyles(style)(Roadmap)
