import { FormUtilReadItemAsync, FormUtilWriteItemAttribute } from './utils'
import ReadJSONPath from '../utils/read-json-path'
import WriteJSONPath from '../utils/write-json-path'
import { PatchData, PatchContent } from '../model/patch'
import { FormUpdateMethodType, FormStateModeType } from './props'
import ValidationFactory from './validation/factory-validation'

export default class FormController {
  _form = null
  _entity = null
  _useMode = null
  _stateMode = null
  _updateMethod = null

  /**
   *
   * @param {*} form
   * @param {*} entity
   * @param {*} useMode
   * @param {*} stateMode
   * @param {*} updateMethod
   */
  constructor(form, entity, useMode, stateMode, updateMethod) {
    this._form = form
    this._entity = entity
    this._useMode = useMode
    this._stateMode = stateMode
    this._updateMethod = updateMethod
  }

  /**
   *
   */
  get form() {
    return this._form
  }

  /**
   *
   */
  set form(value) {
    this._form = value
  }

  /**
   *
   */
  async entity() {
    await FormUtilReadItemAsync(this._form, async formItem => {
      let value = formItem.value

      if (formItem.converter) {
        if (formItem.converter.output) {
          value = formItem.converter.output(value)
        }
      }

      WriteJSONPath(this._entity, formItem.attribute, value)
    })

    return this._entity
  }

  /**
   *
   * @param {*} formItem
   */
  getDefaultFormItem = async formItem => {
    formItem.error = { success: true, message: '' }
    let value = ReadJSONPath(this._entity, formItem.attribute)

    if (formItem.converter) {
      if (formItem.converter.input) {
        value = formItem.converter.input(value)
      }
    }

    formItem.value = value
    formItem.useMode = this._useMode

    if (formItem.required && !formItem.checkError) {
      const checkErrorFunc = ValidationFactory(formItem.type)
      if (checkErrorFunc) {
        formItem.checkError = checkErrorFunc
      }
    }

    formItem.updateEntityKeyValue = this.updateEntityFromKey

    if (formItem.controlFormItemAsync) {
      await formItem.controlFormItemAsync(this._form, formItem, value)
    }

    return formItem
  }

  /**
   *
   */
  async render() {
    await FormUtilReadItemAsync(this._form, this.getDefaultFormItem)
  }

  /**
   *
   */
  async checkError() {
    let hasError = false

    await FormUtilReadItemAsync(this._form, async formItem => {
      if (formItem.checkError) {
        formItem.error = await formItem.checkError(this._form, formItem)
        if (formItem.error.success === false) {
          hasError = true
        }
      }
    })

    return hasError
  }

  /**
   *
   * @param {*} formItem
   * @param {*} patchContent
   */
  async firePatchEvent(formItem, patchContent) {
    const patchEvent = new Event('onPatch')
    patchEvent.data = new PatchData(patchContent, this._entity.id)
    patchEvent.formContent = this._form
    if (formItem && formItem.alternativePatch) {
      await formItem.alternativePatch(this._form, patchEvent.data)
    }

    window.dispatchEvent(patchEvent)
  }

  /**
   *
   */
  updateEntityFromKey = async (value, formItem, patchOperation) => {
    await FormUtilWriteItemAttribute(this._form, {
      [formItem.attribute]: { value: value },
    })

    if (formItem.controlFormItemAsync) {
      await formItem.controlFormItemAsync(this._form, formItem, value)
    }

    switch (this._stateMode) {
      case FormStateModeType.UPDATE:
        switch (this._updateMethod) {
          case FormUpdateMethodType.PATCH:
            if (formItem.converter && formItem.converter.output) {
              value = formItem.converter.output(value)
            }

            if (formItem.defaultPatchOperation) {
              patchOperation = formItem.defaultPatchOperation
            }

            this.firePatchEvent(
              formItem,
              new PatchContent(value, formItem.attribute, patchOperation),
            )
            break
          case FormUpdateMethodType.PUT:
            break

          default:
            break
        }
        break
      case FormStateModeType.SAVE:
        break
      default:
        break
    }

    const updateEvent = new Event('onUpdated')
    updateEvent.formContent = this._form
    window.dispatchEvent(updateEvent)
  }

  /**
   *
   * @param {*} value
   */
  setEntity(value) {
    this._entity = value
  }

  /**
   *
   */
  getEntity() {
    return this._entity
  }

  /**
   *
   */
  destroy() {
    this._form = null
    this._entity = null
    this._stateMode = null
    this._updateMethod = null
  }
}
