const style = theme => ({
  root: {},
  expand: {
    width: 'auto',
    height: 'auto',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: theme.spacing(0.5),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    boxSizing: 'border-box',
  },

  labelContainer: {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
  },
  selectedNode: {
    background: '#e6e6e6',
  },
  menu: {
    width: '100%',
    padding: 0,
  },
})

export default style
