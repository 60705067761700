import React from 'react'
import Requirement from './requirement'
import translator from '../../translate/index'

const Requirements = ({
  long,
  number,
  lowercase,
  uppercase,
  special,
  classes,
}) => (
  <section className={classes.strengthMeter}>
    <Requirement
      htmlFor="password"
      isvalid={long}
      invalidMessage={translator._('setPassword.min8Char')}
      validMessage={translator._('setPassword.min8Char')}
      classes={classes}
    />
    <Requirement
      htmlFor="password"
      isvalid={number}
      invalidMessage={translator._('setPassword.number')}
      validMessage={translator._('setPassword.number')}
      classes={classes}
    />
    <Requirement
      htmlFor="password"
      isvalid={lowercase}
      invalidMessage={translator._('setPassword.lowercase')}
      validMessage={translator._('setPassword.lowercase')}
      classes={classes}
    />
    <Requirement
      htmlFor="password"
      isvalid={uppercase}
      invalidMessage={translator._('setPassword.uppercase')}
      validMessage={translator._('setPassword.uppercase')}
      classes={classes}
    />
    <Requirement
      htmlFor="password"
      isvalid={special}
      invalidMessage="!@#$%^*"
      validMessage="!@#$%^*"
      classes={classes}
    />
  </section>
)

export default Requirements
