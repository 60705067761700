const styles = theme => ({
  list: {
    width: 500,
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  drawer: {
    padding: '32px 24px',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    boxShadow: 'none',
  },
  tabList: {
    height: '44px',
    paddingRight: '0.5rem',
    color: '#0066FF',
    marginBottom: '16px',
  },
  tab: {
    '&.Mui-selected': {
      color: '#F67B3F',
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
      marginRight: '5px!important',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
})

export default styles
