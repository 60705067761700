import React from 'react'
import styles from './style'
import propTypes from './prop'
import translator from '../../../../translate'
import {
  Grid,
  withStyles,
  IconButton,
  TextField,
  Checkbox,
  InputAdornment,
} from '@material-ui/core'
import { PatchContent, PatchData } from '../../../../model/patch'
import {
  QuestionFieldNames,
  SelectionFieldNames,
  AnswerItemType,
} from '../../../../model/question'
import {
  questionSelectionPatchAction,
  questionSelectionDeleteAction,
} from '../../../../reducers/question/action'
import {
  ButtonData,
  ButtonType,
  ButtonActionType,
} from '../../../../model/button'
import MessageBoxContent, {
  MessageBoxType,
} from '../../../../model/message-box'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../../reducers/message-box/action'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { grey } from '@material-ui/core/colors'
import SvgIconStyle from 'src/components/SvgIconStyle'
import CloseIcon from '@material-ui/icons/Close'
/**
 *
 * @param {*} props
 */
const QuestionTestingSingleChoiceSelection = props => {
  const { classes, entity, dispatch, selection } = props
  const [isCorrect, setIsCorrect] = React.useState(selection.isCorrect)
  const [sText, setSText] = React.useState(selection.text)

  React.useEffect(() => {
    setIsCorrect(selection.isCorrect)
  }, [entity])
  let toggleButtons

  /**
   *
   * @param {*} isCorrect
   */
  const saveSelectionStatus = isCorrect => {
    setIsCorrect(isCorrect)

    dispatch(
      questionSelectionPatchAction(
        new PatchData(
          new PatchContent(
            isCorrect,
            SelectionFieldNames.ISCORRECT,
            PatchContent.OPERATION_REPLACE,
          ),
          selection[SelectionFieldNames.ID],
        ),
        entity[QuestionFieldNames.ID],
        () => {
          // dispatch(questionGetAction(entity.id))
        },
      ),
    )
  }
  /**
   *
   * @param {*} isCorrect
   */
  const saveSelectionStatusSingleChoice = isCorrect => {
    setIsCorrect(isCorrect)

    //selection isCorrect = true ise once true olani patch e sok.
    if (isCorrect === true) {
      dispatch(
        questionSelectionPatchAction(
          new PatchData(
            new PatchContent(
              isCorrect,
              SelectionFieldNames.ISCORRECT,
              PatchContent.OPERATION_REPLACE,
            ),
            selection.id,
          ),
          entity[QuestionFieldNames.ID],
          () => {
            entity[QuestionFieldNames.SELECTIONS]
              .filter(x => x.id !== selection.id)
              .map(x =>
                dispatch(
                  questionSelectionPatchAction(
                    new PatchData(
                      new PatchContent(
                        false,
                        SelectionFieldNames.ISCORRECT,
                        PatchContent.OPERATION_REPLACE,
                      ),
                      x.id,
                    ),
                    entity[QuestionFieldNames.ID],
                    () => {
                      // dispatch(questionGetAction(entity.id))
                    },
                  ),
                ),
              )
          },
        ),
      )
    }
  }

  /**
   *
   * @param {*} e
   * @param {*} isCorrect
   */
  const handleChange = (e, isCorrect) => {
    if (
      entity[QuestionFieldNames.QUESTION_TYPE] ===
        AnswerItemType.TESTINGSINGLECHOICE &&
      isCorrect == true
    ) {
      saveSelectionStatusSingleChoice(isCorrect)
    } else if (isCorrect !== null) {
      saveSelectionStatus(isCorrect)
    }
  }

  /**
   *
   * @param {*} e
   */
  const handleTextChange = e => {
    setSText(e.target.value)
  }

  /**
   *
   * @param {*} e
   */
  const deleteButtonClickHandler = e => {
    const id = selection[SelectionFieldNames.ID]
    const questionId = selection[SelectionFieldNames.QUESTION_ID]

    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          translator._('message.delete'),
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              e => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.yes'),
              ButtonActionType.DELETE,
              e => {
                dispatch(messageBoxHideAction())

                dispatch(
                  questionSelectionDeleteAction(id, questionId, () => {
                    // dispatch(questionGetAction(entity.id))
                  }),
                )
              },
            ),
          ],
        ),
      ),
    )
  }

  /**
   *
   */
  const onBlur = () => {
    if (selection[SelectionFieldNames.STEXT] !== sText) {
      dispatch(
        questionSelectionPatchAction(
          new PatchData(
            new PatchContent(
              sText,
              SelectionFieldNames.STEXT,
              PatchContent.OPERATION_REPLACE,
            ),
            selection[SelectionFieldNames.ID],
          ),
          entity[QuestionFieldNames.ID],
          () => {
            // dispatch(questionGetAction(entity.id))
          },
        ),
      )
    }
  }

  let containerClass = classes.container
  if (
    entity[QuestionFieldNames.QUESTION_TYPE] ===
      AnswerItemType.TESTINGSINGLECHOICE ||
    entity[QuestionFieldNames.QUESTION_TYPE] ===
      AnswerItemType.TESTINGMULTIPLECHOICE
  ) {
    toggleButtons = (
      <Grid
        item
        style={{
          borderColor: isCorrect !== true ? grey[400] : '#54D62C',
        }}
        className={classes.gridItem}
      >
        <Checkbox
          icon={<CircleCheckedFilled className={classes.checkboxIcon} />}
          checkedIcon={<CircleCheckedFilled />}
          checked={isCorrect}
          onChange={handleChange}
          color={'secondary'}
          className={classes.checkbox}
          size="small"
        />
      </Grid>
    )
  } else {
    toggleButtons = <div></div>
    containerClass = classes.containerNoGrid
  }

  return (
    <Grid
      container
      alignItems="stretch"
      style={{ borderColor: isCorrect !== true ? grey[400] : '#54D62C' }}
      className={containerClass}
    >
      {toggleButtons}
      <Grid
        item
        alignItems="center"
        style={{ width: '50%' }}
        className={classes.gridItem}
      >
        <TextField
          placeholder={translator._(
            'question.addQuestion.answerType.optionLabel',
          )}
          multiline
          style={{ width: '100%' }}
          value={sText}
          size="small"
          onChange={handleTextChange}
          onBlur={onBlur}
          variant="outlined"
          fullWidth
          InputProps={{
            classes: {
              notchedOutline: classes.noBorder,
              input: classes.input,
            },
          }}
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <IconButton
          color="inherit"
          onClick={deleteButtonClickHandler}
          className={classes.deleteButton}
          size="small"
          disableRipple
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

QuestionTestingSingleChoiceSelection.propTypes = propTypes

export default withStyles(styles)(QuestionTestingSingleChoiceSelection)
