import { put, call, take, fork } from 'redux-saga/effects'
import { questionGetListService } from '../../api/question'
import { questionGetListSuccess } from '../../reducers/question/action'
import { QUESTION_GET_LIST } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionGetListFlow(payload) {
  try {
    const { idList } = payload
    const response = yield call(questionGetListService, idList)

    yield put(questionGetListSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionGetListWatcher() {
  while (true) {
    const action = yield take(QUESTION_GET_LIST)
    const { payload } = action

    yield fork(generalSaga, questionGetListFlow, payload)
  }
}
