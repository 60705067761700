const styles = theme => ({
  stripeForm: {
    position: 'relative',
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '1rem',
  },
  marginBottom: {
    marginBottom: '.5rem',
  },
  marginTop: {
    marginTop: '.5rem',
  },
  noPadding: {
    margin: '0!important',
    padding: '0!important',
  },
  alert: {
    fontSize: '.78rem',
  },
  currencyInformation: {
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    fontSize: '1.2rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewImage: {
    width: '80%',
  },
})

export default styles
