import React, { useRef } from 'react'
import styles from '../../style'
import {
  Button,
  withStyles,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import UserType, { UserTypeNames } from 'src/model/user-types'

const ImportButton = props => {
  const {
    handleRedirecPricing,
    handleFileChangeExcel,
    handleFileChangeTxt,
    classes,
  } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const inputRefExcel = useRef(null)
  const inputRefTxt = useRef(null)

  const handleClickExcel = () => {
    // 👇️ open file input box on click of another element
    inputRefExcel.current.click()
  }
  const handleClickTxt = () => {
    // 👇️ open file input box on click of another element
    inputRefTxt.current.click()
  }

  return (
    <div>
      <Button
        variant="outlined"
        color="inherit"
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={classes.previewBtn}
        size="small"
      >
        <Icon
          icon={'uil:import'}
          style={{ marginTop: '3px', marginRight: '5px' }}
          width="16"
          height="16"
        />
        <Typography className={classes.buttonTextes}>Import</Typography>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            UserType[window.CONSTANTS.USER_ROLE_ID] ===
            UserTypeNames.REGULAR_ADMIN_ACCOUNT
              ? handleRedirecPricing()
              : handleClickExcel()
          }
        >
          <input
            style={{ display: 'none' }}
            ref={inputRefExcel}
            type="file"
            onChange={handleFileChangeExcel}
          />
          {UserType[window.CONSTANTS.USER_ROLE_ID] ===
            UserTypeNames.REGULAR_ADMIN_ACCOUNT && (
            <Icon
              icon={'fa6-solid:crown'}
              style={{ marginTop: '3px', marginRight: '5px' }}
              color="#f7b155"
              width="16"
              height="16"
            />
          )}
          <Typography style={{ fontSize: 12 }}>Import Excel</Typography>
        </MenuItem>
        <MenuItem
          onClick={() =>
            UserType[window.CONSTANTS.USER_ROLE_ID] ===
            UserTypeNames.REGULAR_ADMIN_ACCOUNT
              ? handleRedirecPricing()
              : handleClickTxt()
          }
        >
          <input
            style={{ display: 'none' }}
            ref={inputRefTxt}
            type="file"
            onChange={handleFileChangeTxt}
          />
          {UserType[window.CONSTANTS.USER_ROLE_ID] ===
            UserTypeNames.REGULAR_ADMIN_ACCOUNT && (
            <Icon
              icon={'fa6-solid:crown'}
              style={{ marginTop: '3px', marginRight: '5px' }}
              color="#f7b155"
              width="16"
              height="16"
            />
          )}
          <Typography style={{ fontSize: 12 }}>Import AIKEN Format</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}
export default withStyles(styles)(ImportButton)
