/**
 *
 * @param initialValue
 * @param path
 */
const ReadJSONPath = (initialValue, path) => {
  const pathArray = path.split('.')

  return pathArray.reduce(
    /**
     *
     * @param accumulator
     * @param currentValue
     */
    (accumulator, currentValue) => {
      if (accumulator == null) {
        return null
      }

      if (currentValue.indexOf('[') !== -1) {
        const reg = /\[([0-9]+)\]/g
        const result = reg.exec(currentValue)
        const target = currentValue.split('[')[0]

        if (accumulator[target] == null) {
          accumulator[target] = []
        }

        accumulator = accumulator[target][result[1]]
      } else {
        accumulator = accumulator[currentValue]
      }

      return accumulator
    },
    initialValue,
  )
}

export default ReadJSONPath
