const MEDIA_FOLDERS_GET_BY_FILTER = 'MEDIA_FOLDERS_GET_BY_FILTER'
const MEDIA_FOLDERS_GET_BY_FILTER_SUCCESS =
  'MEDIA_FOLDERS_GET_BY_FILTER_SUCCESS'
const MEDIA_FOLDERS_GET_BY_FILTER_ERROR = 'MEDIA_FOLDERS_GET_BY_FILTER_ERROR'
const MEDIA_FOLDER_GET_BY_ID = 'MEDIA_FOLDER_GET_BY_ID'
const MEDIA_FOLDER_GET_BY_ID_SUCCESS = 'MEDIA_FOLDER_GET_BY_ID_SUCCESS'
const MEDIA_FOLDER_GET_BY_ID_ERROR = 'MEDIA_FOLDER_GET_BY_ID_ERROR'
const MEDIA_FOLDER_GET_ROOT = 'MEDIA_FOLDER_GET_ROOT'
const MEDIA_FOLDER_GET_ROOT_SUCCESS = 'MEDIA_FOLDER_GET_ROOT_SUCCESS'
const MEDIA_FOLDER_GET_ROOT_ERROR = 'MEDIA_FOLDER_GET_ROOT_ERROR'
const MEDIA_FOLDER_ADD_ASYNC = 'MEDIA_FOLDER_ADD_ASYNC'
const MEDIA_FOLDER_ADD_ASYNC_SUCCESS = 'MEDIA_FOLDER_ADD_ASYNC_SUCCESS'
const MEDIA_FOLDER_ADD_ASYNC_ERROR = 'MEDIA_FOLDER_ADD_ASYNC_ERROR'
const MEDIA_FOLDER_PATCH = 'MEDIA_FOLDER_PATCH'
const MEDIA_FOLDER_PATCH_SUCCESS = 'MEDIA_FOLDER_PATCH_SUCCESS'
const MEDIA_FOLDER_PATCH_ERROR = 'MEDIA_FOLDER_PATCH_ERROR'
const MEDIA_FOLDER_DELETE_LIST = 'MEDIA_FOLDER_DELETE_LIST'
const MEDIA_FOLDER_DELETE_LIST_SUCCESS = 'MEDIA_FOLDER_DELETE_LIST_SUCCESS'
const MEDIA_FOLDER_DELETE_LIST_ERROR = 'MEDIA_FOLDER_DELETE_LIST_ERROR'
const MEDIA_FOLDER_DELETE = 'MEDIA_FOLDER_DELETE'
const MEDIA_FOLDER_DELETE_SUCCESS = 'MEDIA_FOLDER_DELETE_SUCCESS'
const MEDIA_FOLDER_DELETE_ERROR = 'MEDIA_FOLDER_DELETE_ERROR'

export {
  MEDIA_FOLDERS_GET_BY_FILTER,
  MEDIA_FOLDERS_GET_BY_FILTER_SUCCESS,
  MEDIA_FOLDERS_GET_BY_FILTER_ERROR,
  MEDIA_FOLDER_GET_BY_ID,
  MEDIA_FOLDER_GET_BY_ID_SUCCESS,
  MEDIA_FOLDER_GET_BY_ID_ERROR,
  MEDIA_FOLDER_GET_ROOT,
  MEDIA_FOLDER_GET_ROOT_SUCCESS,
  MEDIA_FOLDER_GET_ROOT_ERROR,
  MEDIA_FOLDER_ADD_ASYNC,
  MEDIA_FOLDER_ADD_ASYNC_SUCCESS,
  MEDIA_FOLDER_ADD_ASYNC_ERROR,
  MEDIA_FOLDER_PATCH,
  MEDIA_FOLDER_PATCH_SUCCESS,
  MEDIA_FOLDER_PATCH_ERROR,
  MEDIA_FOLDER_DELETE_LIST,
  MEDIA_FOLDER_DELETE_LIST_SUCCESS,
  MEDIA_FOLDER_DELETE_LIST_ERROR,
  MEDIA_FOLDER_DELETE,
  MEDIA_FOLDER_DELETE_SUCCESS,
  MEDIA_FOLDER_DELETE_ERROR,
}
