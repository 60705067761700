import PropTypes from 'prop-types'

const propTypes = {
  classes: PropTypes.object.isRequired,
  completeHandler: PropTypes.func,
  content: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  refresh: PropTypes.func,
}

export default propTypes
