const isEmpty = value => {
  return (
    value === '' ||
    value === undefined ||
    value === null ||
    (typeof value != 'string' && isNaN(value))
  )
}

export default isEmpty
