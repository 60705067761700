import React from 'react'
import { withStyles } from '@material-ui/core'
import styles from '../styles'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from '@material-ui/core'
import { styled } from '@material-ui/core'
import { AccordionSummaryContent } from '..'
import UserType from 'src/model/user-types'
import { useSelector } from 'react-redux'
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  '&.Mui-expanded': {
    boxShadow: 'none',
  },
}))
const OptIn = props => {
  const { entity, accessRule, handleAccessRule, classes } = props
  const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]
  const doubleOptinControl = () => {
    if (
      userControl == 'Regular Admin Account' ||
      userControl == 'Starter - Legacy' ||
      userControl == 'Starter' ||
      userControl == 'Starter A'
    ) {
      return true
    }
    return false
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClickSwitch = event => {
    doubleOptinControl() ? handleClick(event) : handleAccessRule(1)
  }
  const teamList = useSelector(state => state.teamsList.teamList)

  const onClickLearners = () => {
    let team
    teamList &&
      teamList.map((item, index) =>
        item.name == `${entity.collectionName} [Double Opt-In]`
          ? (team = item)
          : null,
      )
    team == undefined
      ? (window.location.href = `/users`)
      : (window.location.href = `/users-member-list/${team.id}`)
  }
  return (
    <div className={classes.root}>
      <StyledAccordion expanded={accessRule == 1}>
        <AccordionSummary
          className={classes.accordion__summary}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <AccordionSummaryContent
            accessRule={accessRule}
            handleClickSwitch={handleClickSwitch}
            contentHeader={'Enable access after double opt-in'}
            contentDescription={
              'Upon entering their email address at the sign-up wall of your quiz, users receive a link to proceed.'
            }
            id={id}
            badgeType={1}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            popupHeader={'Upgrade to Premium'}
            popupContent={
              'Unlock exclusive features by upgrading to the Premium plan.'
            }
            accessRuleEqual={1}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordion__details}>
          <div className={classes.accordion__details__content}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                marginLeft: '8px',
                marginRight: '8px',
              }}
            >
              <Typography>See the users of this category</Typography>
              <Button
                variant="text"
                onClick={() => {
                  onClickLearners()
                }}
                style={{
                  backgroundColor: '#fff',
                  color: '#000',
                }}
              >
                Users
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  )
}

export default withStyles(styles)(OptIn)
