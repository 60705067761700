import PropTypes from 'prop-types'

const propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  executeAction: PropTypes.func.isRequired,
  selectedCount: PropTypes.number.isRequired,
}

export default propTypes
