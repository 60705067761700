import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from '../styles'
import CustomizedSwitch from 'src/components/customized-switch'

const EveryoneAccess = props => {
  const { accessRule, handleAccessRule, classes } = props
  return (
    <div className={classes.root}>
      <div className={classes.root__summary}>
        <Typography fontSize={'16px'} fontWeight={500}>
          Everyone can access
        </Typography>
      </div>
      <div
        className={classes.switch_div}
        style={{
          transform: accessRule == 0 ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      >
        <CustomizedSwitch
          checked={accessRule == 0}
          onClick={() => handleAccessRule(0)}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(EveryoneAccess)
