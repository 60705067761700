const COURSE_TIME_SERIES_LIST = 'COURSE_TIME_SERIES_LIST'
const COURSE_TIME_SERIES_LIST_SUCCESS = 'COURSE_TIME_SERIES_LIST_SUCCESS'
const COURSE_TIME_SERIES_LIST_ERROR = 'COURSE_TIME_SERIES_LIST_ERROR'
const COURSE_TIME_SERIES_REPORT = 'COURSE_TIME_SERIES_REPORT'
const COURSE_TIME_SERIES_REPORT_SUCCESS = 'COURSE_TIME_SERIES_SUCCESS'
const COURSE_TIME_SERIES_REPORT_ERROR = 'COURSE_TIME_SERIES_ERROR'

export {
  COURSE_TIME_SERIES_LIST,
  COURSE_TIME_SERIES_LIST_SUCCESS,
  COURSE_TIME_SERIES_LIST_ERROR,
  COURSE_TIME_SERIES_REPORT,
  COURSE_TIME_SERIES_REPORT_SUCCESS,
  COURSE_TIME_SERIES_REPORT_ERROR,
}
