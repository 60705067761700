import PropTypes from 'prop-types'

const propTypes = {
  source: PropTypes.any.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  createButtonClickHandler: PropTypes.func.isRequired,
}

export default propTypes
