const CREATE_COURSE_COLLECTION = 'CREATE_COURSE_COLLECTION'
const CREATE_COURSE_COLLECTION_SUCCESS = 'CREATE_COURSE_COLLECTION_SUCCESS'
const CREATE_COURSE_COLLECTION_ERROR = 'CREATE_COURSE_COLLECTION_ERROR'
const DELETE_COURSE_COLLECTION = 'DELETE_COURSE_COLLECTION'
const DELETE_COURSE_COLLECTION_SUCCESS = 'DELETE_COURSE_COLLECTION_SUCCESS'
const DELETE_COURSE_COLLECTION_ERROR = 'DELETE_COURSE_COLLECTION_ERROR'
const PATCH_COURSE_COLLECTION = 'PATCH_COURSE_COLLECTION'
const PATCH_COURSE_COLLECTION_SUCCESS = 'PATCH_COURSE_COLLECTION_SUCCESS'
const PATCH_COURSE_COLLECTION_ERROR = 'PATCH_COURSE_COLLECTION_ERROR'
const LIST_COURSE_COLLECTION = 'LIST_COURSE_COLLECTION'
const LIST_COURSE_COLLECTION_SUCCESS = 'LIST_COURSE_COLLECTION_SUCCESS'
const LIST_COURSE_COLLECTION_ERROR = 'LIST_COURSE_COLLECTION_ERROR'
const UPDATE_COURSE_COLLECTION = 'UPDATE_COURSE_COLLECTION'
const UPDATE_COURSE_COLLECTION_SUCCESS = 'UPDATE_COURSE_COLLECTION_SUCCESS'
const UPDATE_COURSE_COLLECTION_ERROR = 'UPDATE_COURSE_COLLECTION_ERROR'
const EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX =
  'EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX'
const EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_SUCCESS =
  'EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_SUCCESS'
const EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_ERROR =
  'EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_ERROR'
const ASSIGNMENT_CLONE = 'ASSIGNMENT_CLONE'
const ASSIGNMENT_CLONE_SUCCESS = 'ASSIGNMENT_CLONE_SUCCESS'
const ASSIGNMENT_CLONE_ERROR = 'ASSIGNMENT_CLONE_ERROR'
const ASSIGNMENT_DELETE = 'ASSIGNMENT_DELETE'
const ASSIGNMENT_DELETE_SUCCESS = 'ASSIGNMENT_DELETE_SUCCESS'
const ASSIGNMENT_DELETE_ERROR = 'ASSIGNMENT_DELETE_ERROR'
const COLLECTION_COURSE_PATCH = 'COLLECTION_COURSE_PATCH'
const COLLECTION_COURSE_PATCH_SUCCESS = 'COLLECTION_COURSE_PATCH_SUCCESS'
const COLLECTION_COURSE_PATCH_ERROR = 'COLLECTION_COURSE_PATCH_ERROR'
const GET_COLLECTION_SETTINGS = 'GET_COLLECTION_SETTINGS'
const GET_COLLECTION_SETTINGS_SUCCESS = 'GET_COLLECTION_SETTINGS_SUCCESS'
const GET_COLLECTION_SETTINGS_ERROR = 'GET_COLLECTION_SETTINGS_ERROR'
const PATCH_COLLECTION_SETTINGS = 'PATCH_COLLECTION_SETTINGS'
const PATCH_COLLECTION_SETTINGS_SUCCESS = 'PATCH_COLLECTION_SETTINGS_SUCCESS'
const PATCH_COLLECTION_SETTINGS_ERROR = 'PATCH_COLLECTION_SETTINGS_ERROR'
export {
  CREATE_COURSE_COLLECTION,
  CREATE_COURSE_COLLECTION_SUCCESS,
  CREATE_COURSE_COLLECTION_ERROR,
  DELETE_COURSE_COLLECTION,
  DELETE_COURSE_COLLECTION_SUCCESS,
  DELETE_COURSE_COLLECTION_ERROR,
  PATCH_COURSE_COLLECTION,
  PATCH_COURSE_COLLECTION_SUCCESS,
  PATCH_COURSE_COLLECTION_ERROR,
  LIST_COURSE_COLLECTION,
  LIST_COURSE_COLLECTION_SUCCESS,
  LIST_COURSE_COLLECTION_ERROR,
  UPDATE_COURSE_COLLECTION,
  UPDATE_COURSE_COLLECTION_SUCCESS,
  UPDATE_COURSE_COLLECTION_ERROR,
  EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX,
  EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_SUCCESS,
  EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_ERROR,
  ASSIGNMENT_CLONE,
  ASSIGNMENT_CLONE_SUCCESS,
  ASSIGNMENT_CLONE_ERROR,
  ASSIGNMENT_DELETE,
  ASSIGNMENT_DELETE_SUCCESS,
  ASSIGNMENT_DELETE_ERROR,
  COLLECTION_COURSE_PATCH,
  COLLECTION_COURSE_PATCH_SUCCESS,
  COLLECTION_COURSE_PATCH_ERROR,
  GET_COLLECTION_SETTINGS,
  GET_COLLECTION_SETTINGS_SUCCESS,
  GET_COLLECTION_SETTINGS_ERROR,
  PATCH_COLLECTION_SETTINGS,
  PATCH_COLLECTION_SETTINGS_SUCCESS,
  PATCH_COLLECTION_SETTINGS_ERROR,
}
