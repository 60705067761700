const style = theme => ({
  root: {
    padding: 0,
    //display: 'flex',
    maxWidth: 350,
    margin: 'auto 0',
  },
  input: {
    flex: 1,
    border: 'none',
    paddingLeft: theme.spacing(1),
  },
  search: {
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.shadows[25].z8 },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.lighter,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
})

export default style
