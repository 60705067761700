import { put, call, take, fork } from 'redux-saga/effects'
import { updateCourseCollectionService } from '../../api/course-collection'
import { courseCollectionUpdateSuccess } from '../../reducers/course-collection/action'
import { UPDATE_COURSE_COLLECTION } from '../../reducers/course-collection/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* courseCollectionUpdateFlow(payload) {
  try {
    const { entity, callback } = payload
    const response = yield call(updateCourseCollectionService, entity)

    yield put(courseCollectionUpdateSuccess(response.data))
    yield put(operationSuccess())
    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* courseCollectionUpdateWatcher() {
  while (true) {
    const action = yield take(UPDATE_COURSE_COLLECTION)
    const { payload } = action

    yield fork(generalSaga, courseCollectionUpdateFlow, payload)
  }
}
