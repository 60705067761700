import React from 'react'
// material
import { Container, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import style from './style'
import ListCourseTimeSeries from '../../../container/list-course-time-series'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function CourseTimeSeries(props) {
  const { dispatch, classes } = props

  return (
    <Container>
      <React.Fragment>
        <div className={classes.card}>
          <ListCourseTimeSeries></ListCourseTimeSeries>
        </div>{' '}
      </React.Fragment>
    </Container>
  )
}

export default connect()(withStyles(style)(CourseTimeSeries))
