import moment from 'moment-timezone'
import isEmpty from '../is-empty'
import { TIME_ZONE } from '../../model/time'

/**
 *
 * @param value
 */
const ConverterDateToShortFormat = function (value) {
  if (isEmpty(value)) {
    return ''
  } else {
    let date = moment.tz(value, TIME_ZONE.ISTANBUL)
    let converted = date
      .clone()
      .tz(window.CONSTANTS.TIME_ZONE)
      .format(window.CONSTANTS.DATE_FORMAT)

    return converted
  }
}

export default ConverterDateToShortFormat
