import translator from '../../../translate'
import { guid } from '../../../utils/guid'
import {
  FormItemName,
  FormItemShopLogo,
  FormItemInfoURL,
  FormItemSubject,
  FormItemGoal1,
  FormItemGoal2,
  FormItemGoal3,
  FormItemGoal4,
} from './form-item'

const ShopForm = {
  editTitle: translator._('form.editShop'),
  groups: [
    {
      id: guid().uid(),
      label: translator._('shop.info'),
      collapsible: true,
      expanded: true,
      formItems: [{ ...FormItemName }, { ...FormItemShopLogo }],
    },
    {
      id: guid().uid(),
      label: translator._('shop.onboardingText'),
      collapsible: true,
      expanded: true,
      formItems: [
        { ...FormItemSubject },
        { ...FormItemGoal1 },
        { ...FormItemGoal2 },
        { ...FormItemGoal3 },
        { ...FormItemGoal4 },
        { ...FormItemInfoURL },
      ],
    },
  ],
}

export default ShopForm
