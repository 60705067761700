import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'
import { Icon } from '@iconify/react'

const ShareViewHeader = props => {
  const { classes } = props
  return (
    <div className={classes.customDomain__root}>
      <div className={classes.customDomain__headerWithIcon}>
        <Icon
          icon={'material-symbols:share'}
          className={classes.customDomain__icon}
        />
        <Typography fontSize={'16px'} fontWeight={500}>
          Share Link
        </Typography>
      </div>
      <Typography color={'#637381'} fontSize={'14px'} fontWeight={400}>
        Get link to share or get code to embed in your website.
      </Typography>
      <Typography fontSize={16} fontWeight={500}>
        Choose your custom domain linked to your collection
      </Typography>
    </div>
  )
}

export default withStyles(styles)(ShareViewHeader)
