import React, { useState, useEffect } from 'react'
import styles from './styles'
import { appSumoSignUpAction } from '../../reducers/sign-up/action.js'
import translator from '../../translate'
import parse from 'html-react-parser'
import moment from 'moment-timezone'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { useDispatch } from 'react-redux'
import { snackbarDispose } from '../../reducers/snackbar/action.js'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import Preloader from '../../components/preloader'

import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'

// material
import {
  Container,
  Button,
  CssBaseline,
  Paper,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  InputAdornment,
  IconButton,
  withStyles,
  CircularProgress,
} from '@material-ui/core'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function AppSumoSignUp(props) {
  const dispatch = useDispatch()
  const { classes, snackbar, enqueueSnackbar, process } = props
  const [showPassword, setShowPassword] = useState(false)
  const [promoCodes, setPromoCodes] = useState('')
  const [signupEmail, setSignupEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [brandName, setBrandName] = useState('')
  const [receivesEmail, setReceivesEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [embedding, setEmbedding] = useState(4)
  const [sharing, setSharing] = useState(4)
  const [payments, setPayments] = useState(4)
  const [termsOfService, setTermsOfService] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)

  useEffect(() => {
    if (snackbar.messages) {
      snackbar.messages.forEach(message => {
        message.messageIds.forEach(messageId => {
          enqueueSnackbar(translator._(messageId), {
            preventDuplicate: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
        dispatch(snackbarDispose(message.id))
      })
    }
  }, [snackbar])

  const handleSubmit = () => {
    setLoading(true)
    const timeZone = moment.tz.guess()
    const userMarketingInterests = [
      { id: 1, rating: embedding },
      { id: 2, rating: sharing },
      { id: 3, rating: payments },
    ]
    const data = {
      email: signupEmail.trim(),
      name: firstName,
      surName: lastName,
      shopName: brandName,
      password: password,
      receivesEmail: receivesEmail,
      timeZone,
      promoCodes: promoCodes.split(',').map(x => (x = x.trim())),
    }
    dispatch(appSumoSignUpAction(data))
    setLoading(false)
  }

  return (
    <Grid container component="main" className={classes.root}>
      {process && <Preloader process={process} />}
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.appSumo}>
            <Container className={classes.appSumo}>
              <img src="/assets/qc-logo.svg" height="30" alt="logo" />
              <Box m={1}></Box>
              <FavoriteIcon color="primary"> </FavoriteIcon>
              <Box m={1}></Box>
              <img
                src="/assets/as-appsumo-logo-blk.png"
                height="24"
                width="150"
                alt="logo"
              />
            </Container>
          </div>
          <Box m={2}></Box>
          <Typography component="h1" variant="h5">
            {translator._('signUp.sumoLings')}
          </Typography>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={translator._('signUp.appSumoCode')}
                  placeholder="Code1, Code2, Code3"
                  onBlur={e => setPromoCodes(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label={translator._('signUp.email')}
                  onBlur={e => setSignupEmail(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={translator._('signUp.password')}
                  type={showPassword ? 'text' : 'password'}
                  onBlur={e => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  required
                  fullWidth
                  label={translator._('signUp.firstName')}
                  onBlur={e => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="surName"
                  label={translator._('signUp.lastName')}
                  name="surName"
                  onBlur={e => setLastName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="shopName"
                  label={translator._('signUp.companyName')}
                  name="shopName"
                  onBlur={e => setBrandName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="ReceivesEmail"
                      id="ReceivesEmail"
                      onChange={() => setReceivesEmail(!receivesEmail)}
                      checked={receivesEmail}
                    />
                  }
                  label={translator._('signUp.iWantToReceiveMarketing')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={termsOfService}
                      color="primary"
                      name="ReceivesEmail"
                      id="ReceivesEmail"
                      onChange={() => {
                        setTermsOfService(!termsOfService)
                      }}
                      checked={termsOfService}
                    />
                  }
                  label={parse(translator._('signUp.termsOfUse'))}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={privacyPolicy}
                      color="primary"
                      name="ReceivesEmail"
                      id="ReceivesEmail"
                      onChange={() => {
                        setPrivacyPolicy(!privacyPolicy)
                      }}
                      checked={privacyPolicy}
                    />
                  }
                  label={parse(translator._('signUp.privacyPolicy'))}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleSubmit()}
              disabled={
                signupEmail == '' ||
                password == '' ||
                firstName == '' ||
                lastName == '' ||
                brandName == '' ||
                privacyPolicy == false ||
                termsOfService == false ||
                promoCodes == ''
                  ? true
                  : false
              }
            >
              {translator._('signUp.signUp')}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item></Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
const mapStateToProps = response => ({
  snackbar: response.snackbar,
  process: response.process,
})
export default connect(mapStateToProps)(
  withStyles(styles)(withSnackbar(AppSumoSignUp)),
)
