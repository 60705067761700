import React from 'react'
import {
  Button,
  Typography,
  withStyles,
  Breadcrumbs,
  Link,
} from '@material-ui/core'
import styles from './styles'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { useSelector } from 'react-redux'
const TeamsMemberListHeader = props => {
  const { teamName, classes } = props

  function handleClick(event) {
    window.location.href = event
  }

  return (
    <div className={classes.page__header}>
      <div className={classes.page__header__title}>
        <Typography fontSize={'24px'} fontWeight={700}>
          Users
        </Typography>
        <Breadcrumbs
          style={{
            root: {
              '&.MuiBreadcrumbs-separator': {
                margin: 0,
              },
            },
          }}
          separator={
            <FiberManualRecordIcon style={{ height: '6px', width: '6px' }} />
          }
          aria-label="breadcrumb"
        >
          <Link
            color="inherit"
            href="/users"
            onClick={() => handleClick('/users')}
          >
            Groups
          </Link>
          <Link color="inherit">{teamName}</Link>
        </Breadcrumbs>
      </div>
    </div>
  )
}

export default withStyles(styles)(TeamsMemberListHeader)
