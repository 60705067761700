import React from 'react'
import {
  Typography,
  TextField,
  Box,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Tab,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { track } from '@amplitude/analytics-browser'
import Features from './features'
import SourceItem from './source-item'
import { Icon } from '@iconify/react'
import UserType, { UserTypeNames } from 'src/model/user-types'

export default function GenerateContent(props) {
  const {
    documentsUploaded,
    setDocumentsUploaded,
    selectedMenu,
    setSelectedMenu,
    courseDocuments,
    produceType,
    setProduceType,
    questionType,
    setQuestionType,
    maxQuestions,
    setMaxQuestions,
    languageCode,
    setLanguageCode,
    handleGenerate,
    handleDelete,
    titleList,
    setTitleList,
    setTitle,
    title,
    setDocumentUrl,
    setDocumentText,
    setFile,
    roleType,
  } = props

  const isUserPremium =
    UserType[window.CONSTANTS.USER_ROLE_ID] !==
      UserTypeNames.REGULAR_ADMIN_ACCOUNT &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1 &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1A &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_2 &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_2A

  const onHandleBack = () => {
    track('ai-wiz-add-more-documents')
    setDocumentsUploaded(false)
    setDocumentText('')
    setDocumentUrl([''])
    setFile(null)
  }
  const SourceAdd = () => {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: '16px',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            style={{ fontSize: '16px', color: '#F67B3F', fontWeight: 500 }}
          >
            Sources
          </Typography>
        </div>

        <div style={{ flexGrow: 1 }}></div>
        <Button
          variant="outlined"
          style={{ color: '#F67B3F', borderColor: '#F67B3F' }}
          onClick={() =>
            isUserPremium ? onHandleBack() : window.open('/pricing', '_blank')
          }
        >
          {isUserPremium ? (
            <AddIcon style={{ width: 20, height: 20, marginRight: 5 }} />
          ) : (
            <Icon
              size="small"
              icon="fa6-solid:crown"
              color="#f7b155"
              width={15}
              style={{ marginRight: 5 }}
            ></Icon>
          )}
          Add More
        </Button>
      </div>
    )
  }

  const GenerateButton = () => {
    return (
      <Button
        variant="contained"
        style={{
          width: '100%',
          color: '#fff',
          backgroundColor: '#F67B3F',
          height: '40px',
          fontSize: '16px',
          alignItems: 'center',
          marginTop: 16,
          boxShadow: 'none',
          borderRadius: '50px',
        }}
        onClick={() => handleGenerate()}
      >
        <img
          src="/assets/ic_AI.png"
          height={12}
          style={{ marginRight: 5 }}
        ></img>
        Generate
      </Button>
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '455px',
        overflow: 'auto',
      }}
    >
      <SourceAdd />
      <SourceItem
        courseDocuments={courseDocuments}
        handleDelete={handleDelete}
        produceType={produceType}
        selectedMenu={selectedMenu}
      />
      <Features
        produceType={produceType}
        setProduceType={setProduceType}
        questionType={questionType}
        setQuestionType={setQuestionType}
        maxQuestions={maxQuestions}
        setMaxQuestions={setMaxQuestions}
        languageCode={languageCode}
        setLanguageCode={setLanguageCode}
        titleList={titleList}
        setTitleList={setTitleList}
        setTitle={setTitle}
        title={title}
        roleType={roleType}
      />
      <div style={{ flexGrow: 1 }}></div>
      <GenerateButton />
    </div>
  )
}
