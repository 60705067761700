const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 'auto',
    },
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  centerBoxGrid: {
    textAlign: 'center',
    maxWidth: 700,
    margin: '50px auto',
  },
  maskot: {
    height: 125,
  },
  logoHolder: {
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  logo: {
    height: 30,
  },
  logoApp: {
    height: 26,
    paddingTop: 2,
  },
  line: {
    height: 20,
    width: 2,
    backgroundColor: '#efefef',
    display: 'inline-block',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
  },
  info1: {
    textAlign: 'center',
  },
  info2: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    width: '60%',
  },
  storeHolder: { marginTop: 10 },
  appStore: { marginRight: 10 },
  input: {
    width: '100%',
    border: '1px solid grey',
    padding: theme.spacing(1.5),
    borderRadius: '8px',
  },
  invalidCheck: {
    color: '#d3d3d3',
  },
  validCheck: {
    color: '#4caf50',
  },
  passwordRequirements: {
    color: '#4a4a4a',
    textAlign: 'center',
    display: 'block',
    marginTop: theme.spacing(2),
  },
  invalid: {
    color: '#ff0000',
    margin: 3,

    fontSize: 11,
  },
  valid: {
    color: '#4caf50',
    margin: 3,
    fontSize: 11,
  },
  strengthMeter: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  card: {
    width: '100%',
    maxHeight: '100%',
  },
  div: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
})

export default styles
