import React, { useState } from 'react'
import styles from '../style'
import propTypes from '../prop'
import { withStyles, ImageListItemBar, IconButton } from '@material-ui/core'
import withMediaLayout from '../layout-hoc'
import URL from '../../../../api/url'
import AddIcon from '@material-ui/icons/AddCircle'
import RemoveIcon from '@material-ui/icons/RemoveCircle'
import ConverterDateToShortFormat from '../../../../utils/converter/date-to-short-format'
import DeleteIcon from '@material-ui/icons/Delete'
import Zoom from '@material-ui/core/Zoom'
import translator from '../../../../translate'
import ClassNames from 'classnames'

import {
  ButtonType,
  ButtonData,
  ButtonActionType,
} from '../../../../model/button'
import MessageBoxContent, {
  MessageBoxType,
} from '../../../../model/message-box'

import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../../reducers/message-box/action'

import { mediaImageDeleteAction } from '../../../../reducers/media-image/action'

/**
 *
 * @param {*} props
 */
const MediaItemImage = props => {
  const {
    classes,
    url,
    name,
    createDate,
    onSelect,
    selected,
    dispatch,
    id,
  } = props
  const [hideItem, setHideItem] = useState(true)
  const [displayItem, setDisplayItem] = useState('block')

  /**
   *
   */
  const handleSelect = () => {
    onSelect(props)
  }

  const onDelete = () => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          translator._('message.deleteImage'),
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              () => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.yes'),
              ButtonActionType.DELETE,
              () => {
                dispatch(
                  mediaImageDeleteAction({ id }, () => {
                    dispatch(messageBoxHideAction())
                    setHideItem(false)
                  }),
                )
              },
            ),
          ],
        ),
      ),
    )
  }
  return (
    <Zoom in={hideItem} onExited={() => setDisplayItem('none')} appear={false}>
      <div
        style={{
          background: '#fff',
          height: '100%',
          display: displayItem,
          border: '1px solid #f7f7f7',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            width: '100%',
            height: 145,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            onClick={handleSelect}
            src={URL.STORAGE + url}
            className={classes.imageContent}
            alt=""
          />
        </div>
        <IconButton
          className={classes.deleteButton}
          size="small"
          onClick={onDelete}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>

        <ImageListItemBar
          onClick={handleSelect}
          style={{ height: 'auto' }}
          title={name ? name : '-'}
          subtitle={ConverterDateToShortFormat(createDate)}
          classes={{
            root: classes.titleBar,
            title: classes.title,
            subtitle: classes.subtitle,
            titleWrapActionPosRight: classes.titleWrapActionPosRight,
          }}
          className={ClassNames({
            [classes.selected]: selected,
          })}
          actionIcon={
            <IconButton className={classes.addIcon}>
              {selected && <RemoveIcon />}
              {!selected && <AddIcon />}
            </IconButton>
          }
        />
      </div>
    </Zoom>
  )
}

MediaItemImage.propTypes = propTypes

export default withMediaLayout(withStyles(styles)(MediaItemImage))
