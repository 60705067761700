import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  withStyles,
  Typography,
  Grid,
  Card,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import heartOutline from '@iconify-icons/eva/heart-outline'
import { Link as RouterLink } from 'react-router-dom'
import styles from './style'
import translator from '../../translate'
import { getUserLimits, customerPortal } from '../../api/limit'
import { LimitTypeEnum } from 'src/model/limit'
import UserType from 'src/model/user-types'
import { track } from '@amplitude/analytics-browser'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from 'src/reducers/message-box/action'
import MessageBoxContent, { MessageBoxType } from 'src/model/message-box'
import { ButtonType, ButtonData, ButtonActionType } from 'src/model/button'

const ProfileInformation = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(false)
  const [type, setType] = useState('')
  const [courseCreationLimitation, setCourseLimitation] = useState('')
  const [aiCourseCount, setAiCourseCount] = useState('')
  const [courseCreationCount, setCourseCreationCount] = useState('')
  const [responsesPerMonthCount, setResponsesPerMonthCount] = useState('')
  const [responsesPerMonthLimit, setResponsesPerMonthLimit] = useState('')
  const [contentInputLengthPerQuiz, setContentInputLengthPerQuiz] = useState('')
  const [loading, dispatchLoading] = useState(0) // 0: nothing, 1: loading

  const directUrl = () => {
    dispatchLoading(1)
    customerPortal().then(response => {
      let sessionUrl = response.data.sessionUrl
      window.open(`${sessionUrl}`)
      dispatchLoading(0)
    })
  }

  const openStripeAlertModal = () => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          'We are having an issue with the subscription service provider.',
          [
            new ButtonData(ButtonType.NORMAL, 'Ok', ButtonActionType.NO, () => {
              dispatch(messageBoxHideAction())
            }),
          ],
          'If you want to cancel your subscription, upgrade, or downgrade, please reach us through the widget right-below or send an email to support@quizcube.io. We’ll handle your request immediately.',
        ),
      ),
    )
  }

  useEffect(() => {
    getUserLimits().then(response => {
      let quizReportUserLimitCount = response.data.find(
        x => x.limit.type == LimitTypeEnum.QuizReportUserLimit,
      ).count
      setResponsesPerMonthCount(quizReportUserLimitCount)
      let quizReportUserLimit = response.data.find(
        x => x.limit.type == LimitTypeEnum.QuizReportUserLimit,
      ).limit.upperLimit
      var usFormatReport = quizReportUserLimit
        .toLocaleString('en-US')
        .replace(',', '.')
      setResponsesPerMonthLimit(usFormatReport)

      let courseCreationCount = response.data.find(
        x => x.limit.type == LimitTypeEnum.ActiveQuizzes,
      ).count
      setCourseCreationCount(courseCreationCount)

      let courseCreationLimit = response.data.find(
        x => x.limit.type == LimitTypeEnum.ActiveQuizzes,
      ).limit.upperLimit
      setCourseLimitation(courseCreationLimit)

      let contentInputLengthPerQuiz = response.data.find(
        x => x.limit.type == LimitTypeEnum.ContentInputLengthPerQuiz,
      ).limit.upperLimit
      var contentLength = contentInputLengthPerQuiz
        .toLocaleString('en-US')
        .replace(',', '.')

      setContentInputLengthPerQuiz(contentLength)

      let userType = response.data[0].limit.roleType
      setType(userType)
    })
  }, [])

  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item>
          <Typography variant={'h3'}>
            {translator._('profile.profile')}
          </Typography>
        </Grid>
        <Grid item className={classes.items}>
          <TableContainer component={Card} className={classes.card}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableTitle}
                  >
                    {translator._('profile.accountInfo')}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {translator._('profile.type')}
                  </TableCell>
                  <TableCell align="center">{UserType[type]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {translator._('profile.responses-per-month')}
                  </TableCell>
                  <TableCell align="center">
                    {responsesPerMonthLimit === '-1' ? (
                      <Typography>
                        {translator._('profile.unlimited')}
                      </Typography>
                    ) : (
                      <Typography>{responsesPerMonthLimit}</Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {translator._('profile.active-quizzes')}
                  </TableCell>
                  <TableCell align="center">
                    {courseCreationLimitation === -1 ? (
                      <Typography>
                        {translator._('profile.unlimited')}
                      </Typography>
                    ) : (
                      <Typography>
                        {courseCreationCount}/{courseCreationLimitation}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {translator._('profile.input-length')}
                  </TableCell>
                  <TableCell align="center">
                    {contentInputLengthPerQuiz === '-1' ? (
                      <Typography>
                        {translator._('profile.unlimited')}
                      </Typography>
                    ) : (
                      <Typography>{contentInputLengthPerQuiz}</Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className={classes.buttonGroup}>
              {(UserType[type] == 'Premium v2' ||
                UserType[type] == 'Premium Plus v2') && (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  className={classes.button}
                  onClick={directUrl}
                >
                  {translator._('profile.paymentDetails')}
                </Button>
              )}

              {(UserType[type] == 'Premium' ||
                UserType[type] == 'Super Admin' ||
                UserType[type] == 'Premium Admin Account (Quarterly)' ||
                UserType[type] == 'Premium Admin Account (Starter)' ||
                UserType[type] == 'Starter - Legacy' ||
                UserType[type] == 'Starter' ||
                UserType[type] == 'Starter - A' ||
                UserType[type] == 'Premium - A' ||
                UserType[type] == 'Premium Plus') && (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  className={classes.button}
                  onClick={openStripeAlertModal}
                >
                  {translator._('profile.paymentDetails')}
                </Button>
              )}
              {UserType[type] == 'Regular Admin Account' && (
                <Button
                  className={classes.button}
                  to="/pricing"
                  sx={{
                    mr: 1,
                  }}
                  variant="contained"
                  startIcon={
                    <Icon
                      size="small"
                      icon="fa6-solid:crown"
                      color="#f7b155"
                      width={15}
                    ></Icon>
                  }
                  component={RouterLink}
                  onClick={() => {
                    track('limit_warning_upgrade', {
                      from: 'account_page_upgrade',
                    })
                  }}
                >
                  {translator._('general.upgrade')}
                </Button>
              )}

              <div className={classes.midDiv}></div>
            </div>
            {loading == 1 ? <CircularProgress /> : ''}
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(ProfileInformation)
