import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import styles from '../styles'
// @mui
import {
  Container,
  Typography,
  Card,
  Grid,
  IconButton,
  withStyles,
} from '@material-ui/core'
const AddCourseCard = props => {
  const { classes } = props
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  return (
    <Card
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className={classes.addQuizCard}
    >
      <IconButton className={classes.iconButtonAdd} aria-label="add card">
        <AddIcon />
      </IconButton>

      <Typography
        variant="subtitle1"
        style={{
          color: isHovering ? '#f67b3f' : '#ffffff',
          transition: '0.4s all',
        }}
      >
        Create Quiz
      </Typography>
      <img
        src="/assets/bgimageAddCourse.png"
        style={{ width: 50, position: 'absolute', right: 0, bottom: 0 }}
      />
      <img
        src="/assets/bgimageElipse.png"
        style={{ width: 50, position: 'absolute', left: 0, top: 0 }}
      />
    </Card>
  )
}

export default withStyles(styles)(AddCourseCard)
