import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_DIRECTORY_GET_ROOT } from '../../reducers/media-directory/type'
import { mediaDirectoryGetRootService } from '../../api/media-directory'
import { mediaDirectoryGetRootSuccess } from '../../reducers/media-directory/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaDirectoryGetRootFlow(payload) {
  try {
    const { filterType } = payload

    const response = yield call(mediaDirectoryGetRootService, filterType)

    yield put(mediaDirectoryGetRootSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaDirectoryGetRootWatcher() {
  while (true) {
    const action = yield take(MEDIA_DIRECTORY_GET_ROOT)
    const { payload } = action

    yield fork(generalSaga, mediaDirectoryGetRootFlow, payload)
  }
}
