import translator from 'src/translate'
import { withStyles } from '@material-ui/core/styles'
import React, { useState, useRef } from 'react'
import styles from '../styles'
import { useDispatch } from 'react-redux'
import { AssignmentFieldNames } from 'src/model/assignment'
import { assignmentPatchAction } from 'src/reducers/assignment/action'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import { PatchContent, PatchData } from 'src/model/patch'
import {
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
  OutlinedInput,
  Box,
  Switch,
} from '@material-ui/core'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import DeleteIcon from '@material-ui/icons/Delete'

import {
  useCourseSettings,
  createSetting,
  patchSetting,
} from 'src/hooks/useCourseSettings'
import URL from 'src/api/url/api'
import TitleLogo from './components/title-logo'
import CoverPage from './components/cover-page'
import CollectionPage from './components/collection-page'

const SETTING_KEY = 'general'

const GeneralSettings = props => {
  const {
    classes,
    entity,
    setSettingDrawerOpen,
    assignmentDescription,
    setAssignmentDescription,
    assignmentTitle,
    setAssignmentTitle,
  } = props

  const dispatch = useDispatch()
  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)
  const thumbnailRef = useRef(null)
  const logoRef = useRef(null)

  const [loading, setLoading] = useState(false)
  const [thumbnailUrl, setThumbnailUrl] = useState(entity?.thumbnail || null)

  const [coverPage, setCoverPage] = useState(exists ? value?.coverPage : null)
  const [coverPageText, setCoverPageText] = useState(
    exists ? value.coverPageText : null,
  )
  const [logo, setLogo] = useState(exists ? value.logo : null)

  const onLogoUpload = event => {
    const fileObj = event.target.files && event.target.files[0]
    if (fileObj) {
      dispatch(
        mediaImageUploadAction(fileObj, '', result => {
          setLogo(`${result.url}`)
        }),
      )
    }
  }
  const handleClickAddThumbnail = () => {
    // 👇️ open file input box on click of other element
    thumbnailRef.current.click()
  }
  const handleClickAddLogo = () => {
    // 👇️ open file input box on click of other element
    logoRef.current.click()
  }
  const onThumbnailUpload = event => {
    const fileObj = event.target.files && event.target.files[0]

    if (fileObj) {
      dispatch(
        mediaImageUploadAction(fileObj, '', result => {
          setThumbnailUrl(`${result.url}`)
        }),
      )
    }
  }
  const handleSaveCourse = () => {
    setLoading(true)

    const variables = {
      ...value,
      logo: logo,
      coverPage: coverPage,
      coverPageText: assignmentDescription,
    }

    if (!exists) {
      // create a setting entity with execute function
      execute(variables, {})
    } else {
      update(variables, {})
    }

    const patchSource = new PatchData(null, entity[AssignmentFieldNames.ID])
    patchSource.addContent(
      new PatchContent(
        assignmentTitle,
        AssignmentFieldNames.NAME,
        PatchData.OPERATION_REPLACE,
      ),
    )
    patchSource.addContent(
      new PatchContent(
        assignmentDescription,
        AssignmentFieldNames.DESCRIPTION,
        PatchData.OPERATION_REPLACE,
      ),
    )

    patchSource.addContent(
      new PatchContent(
        thumbnailUrl,
        AssignmentFieldNames.THUMBNAIL,
        PatchData.OPERATION_REPLACE,
      ),
    )

    dispatch(
      assignmentPatchAction(patchSource, () => {
        setLoading(false)
        setSettingDrawerOpen(false)
      }),
    )
  }

  const handleTextFieldChange = e => {
    setAssignmentTitle(e.target.value)
  }
  const handleTextFieldChangeDescription = e => {
    setAssignmentDescription(e.target.value)
  }
  const handleTextFieldChangeCoverText = e => {
    setCoverPageText(e.target.value)
  }

  return (
    <div className={classes.content}>
      <div className={classes.root__bg}>
        <TitleLogo
          assignmentTitle={assignmentTitle}
          handleTextFieldChange={handleTextFieldChange}
          logo={logo}
          handleClickAddLogo={handleClickAddLogo}
          onLogoUpload={onLogoUpload}
          setLogo={setLogo}
          logoRef={logoRef}
          assignmentDescription={assignmentDescription}
          handleTextFieldChangeDescription={handleTextFieldChangeDescription}
        />
        <CoverPage
          coverPage={coverPage}
          setCoverPage={setCoverPage}
          coverPageText={coverPageText}
          handleTextFieldChangeCoverText={handleTextFieldChangeCoverText}
          assignmentDescription={assignmentDescription}
          handleTextFieldChangeDescription={handleTextFieldChangeDescription}
        />
      </div>
      <div className={classes.bottomButton}>
        <Button
          fullWidth={true}
          color="primary"
          size="small"
          variant="contained"
          onClick={handleSaveCourse}
        >
          {translator._('assignment.courseSettings.save')}
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(GeneralSettings)
