import React, { useEffect } from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles, Grid, Typography, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { processStartEnd } from 'src/reducers/process/action'

/**
 *
 * @param {*} props
 */
const EmptyList = props => {
  const {
    classes,
    source,
    buttonTitle,
    titleItalic,
    title,
    subTitle,
    createButtonClickHandler,
    customButton,
    customImage,
  } = props

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(processStartEnd())
  }, [])

  /**
   *
   * @param {*} e
   */
  const buttonClickHandler = e => {
    createButtonClickHandler()
  }

  return (
    <Grid container flexDirection="column" alignItems="center" spacing={2}>
      <Grid item xs="auto">
        {customImage ? (
          customImage
        ) : (
          <img src={source} width="240px" alt="image" />
        )}
      </Grid>

      <Grid item xs="auto" className={classes.content}>
        {titleItalic && (
          <Typography className={classes.titleItalic}>{titleItalic}</Typography>
        )}
        {title && <Typography variant="h5">{title}</Typography>}
        {subTitle && <Typography variant="subtitle2">{subTitle}</Typography>}
        {customButton ? (
          customButton
        ) : (
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={buttonClickHandler}
          >
            {buttonTitle}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

EmptyList.propTypes = propTypes

export default withStyles(styles)(EmptyList)
