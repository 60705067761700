const styles = theme => ({
  holder: {
    margin: theme.spacing(3),
    maxWidth: '970px',
    marginRight: 'auto',
    marginLeft: 'auto',
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: 'white',
    minHeight: 247,
  },
})

export default styles
