import React, { useEffect, useState, useRef } from 'react'
import styles from './style'
import propTypes from './prop'
import translator from '../../../translate'
import Media from '../media'
import AnswerTypeFactory from '../answer-type/factory'
import {
  QuestionFieldNames,
  AnswerItemType,
  QuestionEditorHeights,
  QuestionEditorResizable,
  QuestionPlaceholders,
  QuestionMediaFieldNames,
  isAnswerItemTypeResultCard,
} from '../../../model/question'
import { Icon } from '@iconify/react'

import { questionPatchAction } from '../../../reducers/question/action'
import { PatchContent, PatchData } from '../../../model/patch'
import {
  Grid,
  withStyles,
  Paper,
  Card,
  Box,
  IconButton,
  Typography,
  Tooltip,
  Button,
  Alert,
} from '@material-ui/core'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
import { isMobile } from 'react-device-detect'
import CircularProgress from '@material-ui/core/CircularProgress'
import Popover from '@material-ui/core/Popover'

import { questionMediaAddAction } from '../../../reducers/question/action'
import { questionMediaDeleteAction } from '../../../reducers/question/action'

import AddIcon from '@material-ui/icons/Add'
import URL from 'src/api/url'

import { AssignmentFieldNames } from 'src/model/assignment'

import { mediaImageUploadAction } from 'src/reducers/media-image/action'

import {
  assignmentUpdateAttribute,
  assignmentEditAction,
} from 'src/reducers/assignment/action'

import { useCourse } from 'src/hooks/useCourseSettings'
import {
  componentBoxShowAction,
  componentBoxHideAction,
} from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import OpenAiPopup from './openai-card-complete/index'
import options from '../../select-type-widget/options'

import {
  messageBoxShowAction,
  messageBoxHideAction,
} from 'src/reducers/message-box/action'
import { ButtonType, ButtonData, ButtonActionType } from 'src/model/button'
import MessageBoxContent, { MessageBoxType } from 'src/model/message-box'
import { assignmentRemoveQuestion } from 'src/reducers/assignment/action'
import SelectTypeWidget from 'src/components/select-type-widget'
import ButtonGroup from './button-group'

// load theme styles with webpack
require('medium-editor/dist/css/medium-editor.css')
require('medium-editor/dist/css/themes/default.css')

// ES module

const QuestionMain = props => {
  const {
    classes,
    back,
    index,
    entity,
    dispatch,
    onDrag,
    onClick,
    cardCount,
    onAddCard,
    setPage,
    lastCardFinish,
    page,
  } = props

  const baseEntity = useCourse()
  const courseId = baseEntity[AssignmentFieldNames.ID]

  const [updatedQText, setQText] = useState(entity[QuestionFieldNames.QTEXT])
  const [saving, setSaving] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectWidgetOpen, setSelectWidgetOpen] = React.useState(null)

  const [imageURL, setImageURL] = useState(
    entity[QuestionFieldNames.QUESTION_MEDIAS].length > 0
      ? entity[QuestionFieldNames.QUESTION_MEDIAS][0][
          QuestionMediaFieldNames.MEDIA_URL
        ]
      : null,
  )
  const widgetParent = options({ entity: baseEntity }).find(o =>
    o.contents.find(
      oc => oc.dataType === entity[QuestionFieldNames.QUESTION_TYPE],
    ),
  )
  const open = Boolean(anchorEl)

  const spanRef = React.useRef()

  const widgetData =
    widgetParent &&
    widgetParent.contents.find(
      v => v.dataType === entity[QuestionFieldNames.QUESTION_TYPE],
    )

  const isLastCard =
    baseEntity &&
    baseEntity[AssignmentFieldNames.QUESTION_DTOS].length - 1 == index

  const hasScoreCardAndLastCard = baseEntity
    ? baseEntity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
        isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
      ) == null && isLastCard
    : false

  const isLastCardFinishCard =
    baseEntity[AssignmentFieldNames.QUESTION_DTOS].length > 0 &&
    index == baseEntity[AssignmentFieldNames.QUESTION_DTOS].length - 1 &&
    (isAnswerItemTypeResultCard(
      baseEntity.cardDtos[baseEntity.cardDtos.length - 1].type,
    ) ||
      baseEntity.cardDtos[baseEntity.cardDtos.length - 1].type == 13)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setSelectWidgetOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const editor = useRef()

  const getSunEditorInstance = sunEditor => {
    editor.current = sunEditor
  }
  const addTextToTextArea = text => {
    onBlur(text)
    editor.current.setContents(text)
    onChange(text)
  }

  /* useEffect(() => {
    setQText(entity[QuestionFieldNames.QTEXT])
  }, [entity])
 */
  const openEmptyPopup = value => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <OpenAiPopup
              entity={entity}
              dispatch={dispatch}
              value={value}
              addTextToTextArea={addTextToTextArea}
              editor={editor.current}
            />
          ),
          {
            hideCloseButton: false,
            callback: e => {},
          },
        ),
      ),
    )
  }

  /**
   *
   * @param {*} e
   */
  const onChange = async value => {
    setQText(value)
    if (
      value.includes('+++') &&
      value.replace(/(<([^>]+)>)/gi, '').split('+++')[0] == ''
    ) {
      openEmptyPopup('')
    } else if (
      value.includes('+++') &&
      value.replace(/(<([^>]+)>)/gi, '').split('+++')[0] !== ''
    ) {
      setAnchorEl(spanRef.current)
    }

    setQText(value.replace('+++', ''))
  }

  /**
   *
   */
  const onBlur = async value => {
    if (entity[QuestionFieldNames.QTEXT] !== value) {
      setSaving(true)
      await dispatch(
        questionPatchAction(
          new PatchData(
            new PatchContent(
              value,
              QuestionFieldNames.QTEXT,
              PatchContent.OPERATION_REPLACE,
            ),
            entity[QuestionFieldNames.ID],
          ),
          () => {
            setSaving(false)
          },
        ),
      )
    }
  }

  const sortCard = (newEntity, hasFinishCard) => {
    handleClose()
    reOrderToNewAdded(cardCount, index + 1, newEntity)
    setPage(index + 2)
  }

  const reOrderToNewAdded = (oldIndex, newIndex, courseEntity) => {
    const list = courseEntity[AssignmentFieldNames.QUESTION_DTOS]

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    if (newIndex !== null) {
      let items = reorder(list, oldIndex, newIndex)

      // TODO: autosenderId için daha temiz ve garanti bir yol olmalı
      // TODO: backendde de autosenderId check edilmeli
      let letNewRelationList = items.map((x, index) => {
        return {
          courseId: window.location.pathname.split('/')[2],
          cardId: x.id,
          orderOfCard: index,
        }
      })

      items.forEach((value, index) => {
        items[index].orderOfCard = index
      })
      let lastEntity = {
        ...courseEntity,
        [AssignmentFieldNames.QUESTION_DTOS]: items,
        [AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS]: letNewRelationList,
      }

      dispatch(assignmentEditAction(lastEntity, () => {}))

      dispatch(
        assignmentUpdateAttribute([
          {
            path: AssignmentFieldNames.QUESTION_DTOS,
            value: items,
          },
          {
            path: AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS,
            value: letNewRelationList,
          },
        ]),
      )
    }
  }

  const toggleCodeView = isCodeView => {
    let content = editor.current.getContents()

    onBlur(content)
  }

  const onOk = (items, close) => {
    const item = items[0]
    let object = {}
    let url = item.url
    if (item.type == 2) {
      url = item.link
    } else {
      url = item.url
      if (url && url.includes('Content/storage/sorbe')) {
        setImageURL(`${URL.STORAGE}` + url)
      } else if (
        url &&
        (url.includes('production/images') || url.includes('test/images'))
      ) {
        setImageURL(`${URL.STORAGE_QUESTION_IMAGE}` + url)
      }
    }
    object[QuestionMediaFieldNames.QUESTION_ID] = entity[QuestionFieldNames.ID]
    object[QuestionMediaFieldNames.MEDIA_URL] = url
    object[QuestionMediaFieldNames.MEDIA_TYPE_ID] = item.type

    dispatch(
      questionMediaAddAction(object, () => {
        dispatch(componentBoxHideAction())
        if (close) {
          dispatch(componentBoxHideAction())
        }
      }),
    )
  }

  const onRemove = assignment => {
    const card = assignment['cardDtos'].find(
      x => x[QuestionFieldNames.ID] == entity[QuestionFieldNames.ID],
    )
    const id =
      card[QuestionFieldNames.QUESTION_MEDIAS][0][QuestionMediaFieldNames.ID]

    dispatch(questionMediaDeleteAction(id, entity[QuestionFieldNames.ID]))
  }
  let canSeeBack = (index || 0) - 1 >= 0
  let canSeeNext = (index || 0) + 1 < cardCount

  if (lastCardFinish) {
    if (index + 1 == cardCount - 1) {
      canSeeNext = false
    } else if (index + 1 == cardCount) {
      canSeeBack = false
      canSeeNext = false
    }
  }
  const handleNextCard = () => onDrag(index, index + 1)
  const handleBackCard = () => onDrag(index, index - 1)
  const onDeleteClick = e => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          'Confirmation',
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              () => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.remove'),
              ButtonActionType.DELETE,
              () => {
                const order = index
                dispatch(
                  assignmentRemoveQuestion(
                    entity.id,
                    window.location.pathname.split('/')[2],
                    () => {
                      dispatch(messageBoxHideAction())
                      setPage(order)
                    },
                  ),
                )
              },
              'error',
            ),
          ],
          translator._('assignment.areYouSureQuestion'),
        ),
      ),
    )
  }
  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid item className={classes.grid}>
        <Card className={classes.childGrid}>
          <Paper
            id={'main_' + `${entity[QuestionFieldNames.ID]}`}
            className={classes.paper}
            onClick={() => onClick(editor.current)}
          >
            {widgetParent && (
              <div className={classes.widgetIcon}>
                <Typography
                  fontSize={10}
                  style={{
                    color: '#F67B3F',
                    marginBottom: 16,
                    marginTop: 8,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {isAnswerItemTypeResultCard(
                    widgetParent.contents[0].dataType,
                  ) ? (
                    <img className={classes.icons} src={widgetParent.icon} />
                  ) : (
                    `${index + 1}. `
                  )}
                  {widgetParent.title == 'Info Card' ||
                  widgetParent.title == 'Input Card'
                    ? widgetParent.title
                    : widgetParent.title + ' Card'}
                </Typography>
                <div style={{ flexGrow: 1 }}></div>
                <div>
                  <ButtonGroup
                    canSeeNext={canSeeNext}
                    canSeeBack={canSeeBack}
                    handleBackCard={handleBackCard}
                    handleNextCard={handleNextCard}
                    cardCount={cardCount}
                    onDeleteClick={onDeleteClick}
                    entity={entity}
                    dispatch={dispatch}
                    isLastCardFinishCard={isLastCardFinishCard}
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                    onOk={onOk}
                    onRemove={onRemove}
                  ></ButtonGroup>
                </div>
              </div>
            )}
            {entity[QuestionFieldNames.QUESTION_TYPE] !=
            AnswerItemType.RESULT ? (
              <>
                <Media
                  dispatch={dispatch}
                  entity={entity}
                  imageURL={imageURL}
                  setImageURL={setImageURL}
                  onOk={onOk}
                  onRemove={onRemove}
                />
                <SunEditor
                  getSunEditorInstance={getSunEditorInstance}
                  key={'editor' + entity[QuestionFieldNames.ID]}
                  defaultValue={entity[QuestionFieldNames.QTEXT]}
                  onChange={onChange}
                  setContents={updatedQText}
                  onBlur={(event, editorContents) => {
                    onBlur(editorContents)
                  }}
                  toggleCodeView={toggleCodeView}
                  className={classes.textArea}
                  setDefaultStyle="text-align: center;"
                  onImageUploadBefore={(files, info, uploadHandler) => {
                    dispatch(
                      mediaImageUploadAction(files[0], '', result => {
                        const response = {
                          result: [
                            {
                              url: `${URL.MEDIA}${result.url}`,
                              name: result.name,
                              size: 100,
                            },
                          ],
                        }

                        uploadHandler(response)
                      }),
                    )
                  }}
                  setOptions={{
                    key: 'editorOptions' + entity[QuestionFieldNames.ID],
                    mode: 'inline',
                    formats: ['h1', 'h2', 'p'],
                    placeholder:
                      QuestionPlaceholders[
                        entity[QuestionFieldNames.QUESTION_TYPE]
                      ] ||
                      translator._(
                        'taskItem.addQuestion.main.textFieldPlaceholder',
                      ),
                    height: 'unset',
                    minHeight: QuestionEditorHeights[
                      entity[QuestionFieldNames.QUESTION_TYPE]
                    ]
                      ? isMobile
                        ? QuestionEditorHeights[
                            entity[QuestionFieldNames.QUESTION_TYPE]
                          ].mobile
                        : QuestionEditorHeights[
                            entity[QuestionFieldNames.QUESTION_TYPE]
                          ].desktop
                      : 80,
                    maxHeight: '300px',

                    addTagsWhitelist: 'file-embed|google-maps|div',

                    buttonList: [
                      [
                        ':t-Format Text-default.more_text',
                        'formatBlock',
                        'bold',
                        'italic',
                        'underline',
                        'fontColor',
                        'hiliteColor',
                      ],
                      //['align'],
                      ['list'],
                      ['image'],
                      ['link'],
                      ['removeFormat'],
                      ['codeView'],
                      /*  [
                        ':i-More Misc-default.more_vertical',
                        'link',
                        'removeFormat',
                      ], */
                    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                    // plugins: [font] set plugins, all plugins are set by default
                    // Other option
                    pasteTagsWhitelist: 'p|h1|h2|h3',
                    popupDisplay: 'local',
                  }}
                />
              </>
            ) : (
              <></>
            )}
            <Box m={1} />
            {saving && (
              <div className={classes.saving}>
                <CircularProgress color="inherit" size={16} />
                <span>Saving content...</span>
              </div>
            )}

            {AnswerTypeFactory({
              type: entity[QuestionFieldNames.QUESTION_TYPE],
              dispatch,
              entity,
            })}
            {(entity[QuestionFieldNames.QUESTION_TYPE] ==
              AnswerItemType.TESTINGSINGLECHOICE ||
              entity[QuestionFieldNames.QUESTION_TYPE] ==
                AnswerItemType.TESTINGMULTIPLECHOICE) &&
              entity[QuestionFieldNames.SELECTIONS].find(
                x => x.isCorrect == true,
              ) === undefined && (
                <div className={classes.warningText}>
                  <Typography color="#fff" style={{ textAlign: 'center' }}>
                    {translator._(
                      'taskItem.addQuestion.PleaseSelectCorrectAnswer',
                    )}
                  </Typography>
                </div>
              )}
          </Paper>
          {!isLastCardFinishCard && (
            <div className={classes.addCard}>
              <IconButton
                className={classes.iconButtonAdd}
                aria-label="add card"
                onClick={handleClick}
              >
                <AddIcon />
              </IconButton>
            </div>
          )}

          <Popover
            style={{ opacity: selectWidgetOpen ? 1 : 0 }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.popover}
          >
            <SelectTypeWidget
              dispatch={dispatch}
              courseId={courseId}
              baseEntity={baseEntity}
              onAdd={list => {
                onAddCard(list, sortCard)
              }}
              disabledAiPopup={true}
              hasScoreCardAndLastCard={hasScoreCardAndLastCard}
            />
          </Popover>
        </Card>
      </Grid>
    </Grid>
  )
}

QuestionMain.propTypes = propTypes

export default withStyles(styles)(QuestionMain)
