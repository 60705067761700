const URL = {
  AUTOSENDERSETTING_LIST: 'AutoSenderSetting/GetAutoSendersByFilter',
  GET_AUTOSENDERSETTING: 'AutoSenderSetting/GetAutoSenderById',
  ADD_AUTOSENDERSETTING: 'AutoSenderSetting/AddAutoSenderSetting',
  EDIT_AUTOSENDERSETTING: 'AutoSenderSetting/EditAutoSenderSetting',
  PATCH_AUTOSENDERSETTING: 'AutoSenderSetting/PatchAutoSenderSetting',
  DELETE_AUTOSENDERSETTING: 'AutoSenderSetting/DeleteAutoSenderSetting',
  ADD_AUTOSENDERSETTING_USER: 'AutoSenderSetting/AddAutoSenderUserRelation',
  DELETE_AUTOSENDERSETTING_USER:
    'AutoSenderSetting/RemoveAutoSenderUserRelation',
  DELETE_AUTOSENDERSETTING_LIST:
    'AutoSenderSetting/DeleteAutoSenderSettingList',
  AUTO_SENDER_REPORT_PARTICIPANT_LINE_SERIES:
    'Report/ReinforcementReportParticipantLineSeries',
  AUTO_SENDER_REPORT_CARD_VALUE: 'Report/ReinforcementReportCardValue',
  AUTO_SENDER_REPORT_CONTENT_BREAKDOWN:
    'Report/ReinforcementReportContentBreakdown',
  AUTO_SENDER_REPORT_TEAM_ENGAGEMENT_DATA:
    'Report/ReinforcementReportTeamEngagementData',
  AUTO_SENDER_REPORT_TEAM_PERFORMANCE_DATA:
    'Report/ReinforcementReportTeamPerformanceData',
  AUTO_SENDER_REPORT_RELATED_SUBJECT_LIST:
    'Report/ReinforcementReportRelatedSubjectList',
  AUTO_SENDER_REPORT_USER_DATA: 'Report/ReinforcementReportUserData',
  AUTO_SENDER_SEND_TO_ENOCTA: 'Integration/SendAutoSenderToEnocta',
  REINFORCEMENT_TEST: 'AutoSenderSetting/Test',
}

export default URL
