/*eslint-disable */
import { BaseError } from './base'

export default class ServerError extends BaseError {
  /**
     *
     * @param type
     * @param config
     * @param code
     * @param request
     * @param response
     * @example {
                    "statusCode": 500,
                    "operationMessage": "Incorrect email or password.",
                    "operationCode": "ERROR_MESSAGE_INCORRECT_USERNAME_OR_PASSWORD",
                    "data": null
                }
     */
  constructor(type, config, code, request, response) {
    super(type, config, code, request, response)
  }
}
/*eslint-enabled */
