import { put, call, take, fork } from 'redux-saga/effects'
import { getCourseAnalyticsByIdService } from '../../api/assignment'
import { GET_COURSE_ANALYTICS } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* getCourseAnalyticsByIdFlow(payload) {
  try {
    const { courseId, callback } = payload

    const response = yield call(getCourseAnalyticsByIdService, courseId)

    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* getCourseAnalyticsByIdWatcher() {
  while (true) {
    const action = yield take(GET_COURSE_ANALYTICS)
    const { payload } = action

    yield fork(generalSaga, getCourseAnalyticsByIdFlow, payload)
  }
}
