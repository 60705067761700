import { Icon } from '@iconify/react'
import { Typography, withStyles, Button } from '@material-ui/core'
import React from 'react'
import styles from '../styles'

const MagicLink = props => {
  const { handleMagicLink, classes } = props
  return (
    <div className={classes.magic_link_root}>
      <div className={classes.magic_link_root__header}>
        <div className={classes.displayF}>
          <Typography
            fontSize={'16px'}
            fontWeight={500}
            style={{ color: '#002E47' }}
          >
            Invite users by magic link
          </Typography>
        </div>
        <Typography color="#637381" fontWeight={400} fontSize={'14px'}>
          Customize and send the invitation email
        </Typography>
      </div>
      <div className={classes.magic_link_icon_button_div}>
        <Button
          className={classes.magic_link_root_button}
          onClick={handleMagicLink}
        >
          Send mail
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(MagicLink)
