import { put, call, take, fork } from 'redux-saga/effects'
import { domainPatchService } from '../../api/domain'
import { domainPatchSuccess } from '../../reducers/domain/action'
import { PATCH_DOMAIN } from '../../reducers/domain/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* domainPatchFlow(payload) {
  try {
    const { content, callback } = payload
    const response = yield call(domainPatchService, content)

    yield put(domainPatchSuccess(response.data))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* domainPatchWatcher() {
  while (true) {
    const action = yield take(PATCH_DOMAIN)
    const { payload } = action

    yield fork(generalSaga, domainPatchFlow, payload)
  }
}
