const styles = theme => ({
  grid: {
    width: '100%',
    maxWidth: 610,
    flexBasis: 'unset',
    textAlign: 'center',
  },
  photoButton: {
    marginRight: theme.spacing(2),
  },
})

export default styles
