import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { switchMode, switchDirection } from '../reducers/settings/action'

import { QuestionFieldNames } from 'src/model/question'
import { AssignmentFieldNames } from 'src/model/assignment'

import { questionSettingPatch, questionSettingCreate } from 'src/reducers/assignment/action'

export function useCard(cardId) {
    const baseEntity = useSelector((state) => state.assignment.entity[AssignmentFieldNames.QUESTION_DTOS])
    return baseEntity.find(x => x[QuestionFieldNames.ID] == cardId)
}

export function useCardSettings(cardId, key) {
    const card = useCard(cardId)
    const settings = card[QuestionFieldNames.SETTING_DTOS]

    if (!key)
        return settings;
    
    const setting = settings.find(x => x.key === key)
    return setting
}

export function patchSetting(dispatch, cardId, id, key, value, callback) {
    dispatch(
      questionSettingPatch({
        path: "Value",
        value: value
      }, id, cardId, key, () => {
          if (callback) {
              callback()
          }
      })
    )
}

export function createSetting(dispatch, cardId, key, value, callback) {
    dispatch(
        questionSettingCreate(
            {
                cardId: cardId,
                key: key,
                value: value
            },
            cardId,
            () => {
                if (callback) callback()
            }
        )
    )
}