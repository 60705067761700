const USER_ROLE_DETAILED_LIST = 'USER_ROLE_DETAILED_LIST'
const USER_ROLE_DETAILED_LIST_SUCCESS = 'USER_ROLE_DETAILED_LIST_SUCCESS'
const USER_ROLE_DETAILED_LIST_ERROR = 'USER_ROLE_DETAILED_LIST_ERROR'
const SET_USER_ROLE = 'SET_USER_ROLE'
const SET_USER_ROLE_SUCCESS = 'SET_USER_ROLE_SUCCESS'
const SET_USER_ROLE_ERROR = 'SET_USER_ROLE_ERROR'

export {
  USER_ROLE_DETAILED_LIST,
  USER_ROLE_DETAILED_LIST_SUCCESS,
  USER_ROLE_DETAILED_LIST_ERROR,
  SET_USER_ROLE,
  SET_USER_ROLE_SUCCESS,
  SET_USER_ROLE_ERROR,
}
