import { sortByKey } from '../../utils/array'
import {
  QUESTION_UPDATE_ENTITY,
  QUESTION_LIST,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_ERROR,
  QUESTION_GET_SUCCESS,
  QUESTION_GET_LIST_SUCCESS,
  QUESTION_DELETE_LIST,
  QUESTION_DELETE_ALL_LIST,
  QUESTION_GET_ERROR,
  QUESTION_ADD_SUCCESS,
  QUESTION_ADD_ERROR,
  QUESTION_EDIT_SUCCESS,
  QUESTION_EDIT_ERROR,
  QUESTION_PATCH_SUCCESS,
  QUESTION_TAG_ADD_SUCCESS,
  QUESTION_TAG_ADD_ERROR,
  QUESTION_GAMEINFO_ADD_SUCCESS,
  QUESTION_GAMEINFO_ADD_ERROR,
  QUESTION_MEDIA_ADD_SUCCESS,
  QUESTION_MEDIA_ADD_ERROR,
  QUESTION_MEDIA_DELETE_SUCCESS,
  QUESTION_LINK_ADD_SUCCESS,
  QUESTION_LINK_ADD_ERROR,
  QUESTION_SELECTION_ADD_SUCCESS,
  QUESTION_SELECTION_ADD_ERROR,
  QUESTION_TAG_DELETE_SUCCESS,
  QUESTION_TAG_DELETE_ERROR,
  QUESTION_GAMEINFO_DELETE_SUCCESS,
  QUESTION_GAMEINFO_DELETE_ERROR,
  QUESTION_REPORT_COMPARE_TEAMS_SUCCESS,
  QUESTION_REPORT_CHOICE_TENDENCY_SUCCESS,
  QUESTION_REPORT_RELATED_TEAMS_SUCCESS,
  QUESTION_REPORT_SHOW_USER_ANSWERS_SUCCESS,
  QUESTION_REPORT_IMPROVEMENT_SUCCESS,
  QUESTION_RE_ORDER_LIST,
  CARD_PATCH_LINK_SUCCESS,
  QUESTION_SELECTIONS_ADD_SUCCESS,
  QUESTION_SELECTIONS_ADD_ERROR,
} from './type'
import {
  QuestionLinkSchema,
  QuestionFieldNames,
  QuestionMediaSchema,
  SelectionSchema,
} from '../../model/question'

export default function (
  state = {
    list: { results: null, totalCount: 0 },
    reportCompareTeams: { teamResults: [] },
    reportImprovement: {
      overallImprovementAccuracy: '-',
      reportProgressDTO: [],
      reportResentImprovementDTO: {
        amount: 0,
        correctAnswerAmount: 0,
        correctAnswerRate: 0,
      },
    },
    reportChoiceTendency: {
      teamResults: [],
      selectionTendencies: [],
    },
    reportUserAnswers: { results: null, totalCount: 0 },
    reportRelatedTeams: [],
    entity: null,
    entityList: [],
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case QUESTION_UPDATE_ENTITY:
      const { entity } = payload

      if (entity == null) {
        return {
          ...state,
          entity: null,
        }
      }

      if (state.entity == null) {
        state.entity = {}
      }

      return {
        ...state,
        entity: {
          ...state.entity,
          ...entity,
        },
      }
    case QUESTION_PATCH_SUCCESS:
      payload.content.contents.forEach(patchContent => {
        state = {
          ...state,
          entity: {
            ...state.entity,
            [patchContent.path]: patchContent.value,
          },
        }
      })
      return state
    case QUESTION_LIST:
      return { ...state, query: payload.query }
    case QUESTION_LIST_SUCCESS:
      return { ...state, list: payload.list }
    case QUESTION_GET_LIST_SUCCESS:
      let newEntityList = [...state.entityList, ...payload.entityList]

      return {
        ...state,
        entityList: newEntityList,
      }
    case QUESTION_RE_ORDER_LIST:
      return {
        ...state,
        entityList: payload.entityList,
      }

    case QUESTION_DELETE_LIST:
      const filteredListItems = state.entityList.filter(function (item) {
        return item.id !== payload.id
      })

      return {
        ...state,
        entityList: filteredListItems,
      }

    case QUESTION_DELETE_ALL_LIST:
      return {
        ...state,
        entityList: [],
      }

    case QUESTION_LIST_ERROR:
      return { ...state, list: null }
    case QUESTION_GET_SUCCESS:
      return { ...state, entity: { ...state.entity, ...payload.entity } }
    case QUESTION_GET_ERROR:
      return { ...state, entity: null }
    case QUESTION_ADD_SUCCESS:
      return {
        ...state,
        entity: {
          ...state.entity,
          ...payload.entity,
        },
      }
    case QUESTION_ADD_ERROR:
      return { ...state, entity: null }
    case QUESTION_EDIT_SUCCESS:
      return { ...state, entity: payload.entity }
    case QUESTION_EDIT_ERROR:
      return { ...state, entity: null }
    case QUESTION_TAG_ADD_SUCCESS:
      let subjectSchema = payload.entity
      let subjectList = state.entity[QuestionFieldNames.QUESTION_TAG_RELATION]
        ? [...state.entity[QuestionFieldNames.QUESTION_TAG_RELATION]]
        : []

      let child = [...subjectList, ...subjectSchema]
      let object = {
        ...state,
        entity: {
          ...state.entity,
          [QuestionFieldNames.QUESTION_TAG_RELATION]: Object.values(child),
        },
      }

      return object
    case QUESTION_TAG_ADD_ERROR:
      return { ...state, entity: null }
    case QUESTION_GAMEINFO_ADD_SUCCESS:
      return { ...state, entity: payload.entity }
    case QUESTION_GAMEINFO_ADD_ERROR:
      return { ...state, entity: null }
    case QUESTION_MEDIA_ADD_SUCCESS:
      const mediaSchema = { ...QuestionMediaSchema(), ...payload.entity }
      /* const mediaList = state.entity[QuestionFieldNames.QUESTION_MEDIAS]
        ? [...state.entity[QuestionFieldNames.QUESTION_MEDIAS]]
        : [] */

      //Feedback ve intro acılırsa düzenlenmeli todo
      /*  return {
        ...state,
        entity: {
          ...state.entity,
          [QuestionFieldNames.QUESTION_MEDIAS]: [
            ...sortByKey([...mediaList, { ...mediaSchema }], 'id'),
          ],
        },
      } */

      return {
        ...state,
        entity: {
          ...state.entity,
          [QuestionFieldNames.QUESTION_MEDIAS]: [{ ...mediaSchema }],
        },
      }
    case QUESTION_MEDIA_ADD_ERROR:
      return { ...state, entity: null }
    case QUESTION_MEDIA_DELETE_SUCCESS:
      const filteredMediaItems = state.entity[
        QuestionFieldNames.QUESTION_MEDIAS
      ].filter(function (item) {
        return item.id !== payload.id
      })

      let newList = {
        ...state,
        entity: {
          ...state.entity,
          [QuestionFieldNames.QUESTION_MEDIAS]: filteredMediaItems,
        },
      }

      return newList
    case QUESTION_LINK_ADD_SUCCESS:
      const linkSchema = { ...QuestionLinkSchema(), ...payload.entity }
      const linkList = state.entity[QuestionFieldNames.QUESTION_LINKS]
        ? [...state.entity[QuestionFieldNames.QUESTION_LINKS]]
        : []
      return {
        ...state,
        entity: {
          ...state.entity,
          [QuestionFieldNames.QUESTION_LINKS]: [
            ...sortByKey([...linkList, { ...linkSchema }], 'title'),
          ],
        },
      }
    case QUESTION_LINK_ADD_ERROR:
      return { ...state, entity: null }
    case QUESTION_SELECTION_ADD_SUCCESS:
      if (state.entity == null) {
        return state
      }
      const selectionData = { ...SelectionSchema(), ...payload.response }

      const selectionList = state.entity[QuestionFieldNames.SELECTIONS]
        ? [...state.entity[QuestionFieldNames.SELECTIONS]]
        : []

      return {
        ...state,
        entity: {
          ...state.entity,
          [QuestionFieldNames.SELECTIONS]: [
            ...sortByKey([...selectionList, { ...selectionData }], 'id'),
          ],
        },
      }
    case QUESTION_SELECTION_ADD_ERROR:
      return { ...state, entity: null }
    case QUESTION_TAG_DELETE_SUCCESS:
      const filteredItems = state.entity[
        QuestionFieldNames.QUESTION_TAG_RELATION
      ].filter(function (item) {
        return item.id !== payload.id
      })

      let newTagList = {
        ...state,
        entity: {
          ...state.entity,
          [QuestionFieldNames.QUESTION_TAG_RELATION]: filteredItems,
        },
      }

      return newTagList
    case QUESTION_TAG_DELETE_ERROR:
      return { ...state, entity: null }
    case QUESTION_GAMEINFO_DELETE_SUCCESS:
      return { ...state, entity: payload.entity }
    case QUESTION_GAMEINFO_DELETE_ERROR:
      return { ...state, entity: null }
    case QUESTION_REPORT_COMPARE_TEAMS_SUCCESS:
      return {
        ...state,
        reportCompareTeams: payload.response,
      }
    case QUESTION_REPORT_CHOICE_TENDENCY_SUCCESS:
      return {
        ...state,
        reportChoiceTendency: payload.response,
      }
    case QUESTION_REPORT_IMPROVEMENT_SUCCESS:
      return {
        ...state,
        reportImprovement: payload.response,
      }
    case QUESTION_REPORT_RELATED_TEAMS_SUCCESS:
      return {
        ...state,
        reportRelatedTeams: payload.response,
      }
    case QUESTION_REPORT_SHOW_USER_ANSWERS_SUCCESS:
      return {
        ...state,
        reportUserAnswers: payload.response,
      }
    default:
      return state
  }
}
