import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_IMAGE_DELETE } from '../../reducers/media-image/type'
import { mediaImageDeleteService } from '../../api/media-image'
import { mediaImageDeleteSuccess } from '../../reducers/media-image/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaImageDeleteFlow(payload) {
  try {
    const { id, callback } = payload
    const response = yield call(mediaImageDeleteService, id)

    yield put(mediaImageDeleteSuccess(response.data))
    yield put(operationSuccess())
    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaImageDeleteWatcher() {
  while (true) {
    const action = yield take(MEDIA_IMAGE_DELETE)
    const { payload } = action

    yield fork(generalSaga, mediaImageDeleteFlow, payload)
  }
}
