import URL from '../url'
import Request from '../request'

/**
 *
 */
export const shopGetService = () => new Request(URL.API, URL.GET_SHOP).get()

/**
 *
 * @param {*} entity
 */
export const shopEditService = entity =>
  new Request(URL.API, URL.EDIT_SHOP).put(entity)

/**
 *
 * @param {*} content
 */
export const shopPatchService = content =>
  new Request(URL.API, URL.PATCH_SHOP + '?id=' + content.id).patch(content)
