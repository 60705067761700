/*eslint-disabled */
export class PatchContent {
  /**
   *
   * @param {*} value
   * @param {*} path
   * @param {*} operation
   */
  constructor(value, path, operation) {
    this._value = value
    this._path = path
    this._operation = operation
  }

  /**
   *
   */
  get value() {
    return this._value
  }

  /**
   *
   */
  get path() {
    return this._path
  }

  /**
   *
   */
  get operation() {
    return this._operation
  }

  /**
   *
   */
  toJSON() {
    return {
      value: this._value,
      path: this._path,
      op: this._operation,
    }
  }
}

export class PatchData {
  static OPERATION_TEST = 'test'
  static OPERATION_REMOVE = 'remove'
  static OPERATION_ADD = 'add'
  static OPERATION_REPLACE = 'replace'
  static OPERATION_MOVE = 'move'
  static OPERATION_COPY = 'copy'

  /**
   *
   * @param {*} content
   * @param {*} id
   */
  constructor(content, id) {
    this._contents = []
    this._id = id

    // eslint-disable-next-line no-mixed-operators
    if (content) this._contents.push(content)
  }

  /**
   *
   * @param {PatchContent} content
   */
  addContent(content) {
    this._contents.push(content)
  }

  /**
   *
   */
  deleteContent() {
    this._contents.pop()
  }
  /**
   *
   */
  get id() {
    return this._id
  }

  /**
   *
   */
  get contents() {
    return this._contents
  }

  /**
   *
   */
  toJSON() {
    return [...this._contents]
  }
}

export const PatchDataTypes = [
  PatchData.OPERATION_ADD,
  PatchData.OPERATION_COPY,
  PatchData.OPERATION_MOVE,
  PatchData.OPERATION_REMOVE,
  PatchData.OPERATION_REPLACE,
  PatchData.OPERATION_TEST,
]
/*eslint-enabled */
