import { guid } from '../../../../../utils/guid'
import translator from '../../../../../translate'
import { FormItemType } from '../../../../../form/props/form-item/default'
import { teamListByParentWithEmptyService } from '../../../../../api/team'

const FormItemTeamSelect = {
  id: guid().uid(),
  attribute: 'TeamId',
  label: translator._('general.team'),
  placeholder: translator._('general.team'),
  type: FormItemType.SELECT,
  options: () => {
    return null
  },
  optionAttributeName: { value: 'id', label: 'name' },
  nodeOperation: teamListByParentWithEmptyService,
  required: true,
  disabled: false,
  hidden: false,
}
export default FormItemTeamSelect
