import { put, call, take, fork } from 'redux-saga/effects'
import { assignmentGetService } from '../../api/assignment'
import { assignmentGetSuccess } from '../../reducers/assignment/action'
import { ASSIGNMENT_GET } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* assignmentGetFlow(payload) {
  try {
    const { id, callback } = payload
    const response = yield call(assignmentGetService, id)

    yield put(assignmentGetSuccess(response.data))
    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* assignmentGetWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_GET)
    const { payload } = action

    yield fork(generalSaga, assignmentGetFlow, payload)
  }
}
