import { put, call, take, fork } from 'redux-saga/effects';
import { exportCourseExcelService } from '../../api/assignment';
import FileDownload from 'js-file-download';
import { EXPORT_COURSE_EXCEL } from '../../reducers/assignment/type';
import { exportCourseExcelSuccess } from '../../reducers/assignment/action';

import generalSaga from '../general';
import { operationSuccess, operationFail } from '../../reducers/operation/action';

/**
 *
 * @param {*} payload
 */
function* exportCourseExcelFlow(payload) {
  try {
    const { courseId, courseName } = payload;

    const response = yield call(exportCourseExcelService, courseId, courseName);

    yield put(exportCourseExcelSuccess());

    FileDownload(response, `Export-${courseName}.xls`);

    yield put(operationSuccess());
  } catch (error) {
    yield put(operationFail(error));
  }
}

/**
 *
 */
export default function* exportCourseExcelWatcher() {
  while (true) {
    const action = yield take(EXPORT_COURSE_EXCEL);
    const { payload } = action;

    yield fork(generalSaga, exportCourseExcelFlow, payload);
  }
}
