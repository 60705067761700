import { put, call, take, fork } from 'redux-saga/effects'
import { loginUserService } from '../../api/authentication'
import { authenticationLoginUserSuccess } from '../../reducers/authentication/action'
import { AUTHENTICATION_LOGIN_USER } from '../../reducers/authentication/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'
import { set as setCookie } from 'es-cookie'

/**
 *
 * @param {*} payload
 */
function* loginSagaFlow(payload) {
  try {
    const { userForm } = payload
    const response = yield call(loginUserService, userForm)

    setCookie('language', response.data.language, {sameSite: 'none',secure: true})

    yield put(authenticationLoginUserSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* loginSagaWatcher() {
  while (true) {
    const action = yield take(AUTHENTICATION_LOGIN_USER)
    const { payload } = action

    yield fork(generalSaga, loginSagaFlow, payload)
  }
}
