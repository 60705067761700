const groupCoursesByCollection = (courseDtos, collections) => {
  const collectionsMap = new Map()

  // Initialize collections map with empty arrays for each collection
  collections.forEach(collection => {
    collectionsMap.set(collection.id, { ...collection, courses: [] })
  })

  // Iterate over courseDtos and group them under their respective collection
  courseDtos.forEach(course => {
    if (collectionsMap.has(course.courseCollectionId)) {
      collectionsMap.get(course.courseCollectionId).courses.push(course)
    }
  })

  // Convert the map back to an array
  return Array.from(collectionsMap.values())
}

export default groupCoursesByCollection
