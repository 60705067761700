import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const questionListService = query =>
  new Request(URL.API, URL.LIST_QUESTION).post(query)

/**
 *
 * @param {*} id
 */
export const questionGetService = id =>
  new Request(URL.API, URL.GET_QUESTION).get({ id })

/**
 *
 * @param {*} idList
 */
export const questionGetListService = idList =>
  new Request(URL.API, URL.GET_QUESTION_LIST).post(idList)

/**
 *
 * @param {*} entity
 */
export const questionAddService = entity =>
  new Request(URL.API, URL.ADD_QUESTION).post(entity)

/**
 *
 * @param {*} entity
 */
export const questionEditService = entity =>
  new Request(URL.API, URL.EDIT_QUESTION).put(entity)
/**
 *
 * @param {*} content
 */
export const questionPatchService = content =>
  new Request(URL.API, URL.PATCH_QUESTION + '?id=' + content.id).patch(content)

/**
 * @param {*} id
 *
 */
export const questionSettingPatchService = content => {
  new Request(URL.API, URL.PATCH_SETTING + '?id=' + content.id).patch(
    content.data,
  )
}

/**
 * @param {*} id
 *
 */
export const questionSettingCreateService = content =>
  new Request(URL.API, URL.CREATE_SETTING).post(content)

export const questionSettingRemoveService = id =>
  new Request(URL.API, URL.REMOVE_SETTING).delete({ id })

/**
 *
 * @param {*} content
 */
export const questionSelectionPatchService = content =>
  new Request(URL.API, URL.PATCH_SELECTION + '?id=' + content.id).patch(content)
/**
 *
 * @param {*} id
 */
export const questionDeleteService = id =>
  new Request(URL.API, URL.DELETE_QUESTION).delete({ id })

/**
 * @param {*} ids
 */
export const questionListMakeActiveService = ids =>
  new Request(URL.API, URL.ACTIVE_QUESTION_LIST).post(ids)

/**
 * @param {*} ids
 */
export const questionListMakePassiveService = ids =>
  new Request(URL.API, URL.PASSIVE_QUESTION_LIST).post(ids)

/**
 *
 * @param {*} ids
 */
export const questionListDeleteService = ids =>
  new Request(URL.API, URL.DELETE_QUESTION_LIST).post(ids)
/**
 *
 * @param {*} entity
 */
export const questionTagAddService = entity =>
  new Request(URL.API, URL.ADD_QUESTION_TAG).post(entity)
/**
 *
 * @param {*} entity
 */
export const questionGameInfoAddService = entity =>
  new Request(URL.API, URL.ADD_QUESTION_GAMEINFO).post(entity)
/**
 *
 * @param {*} entity
 */
export const questionMediaAddService = entity =>
  new Request(URL.API, URL.ADD_QUESTIONMEDIA).post(entity)
/**
 *
 * @param {*} entity
 */
export const questionLinkAddService = entity =>
  new Request(URL.API, URL.ADD_QUESTIONLINK).post(entity)
/**
 *
 * @param {*} entity
 */
export const questionSelectionAddService = entity =>
  new Request(URL.API, URL.ADD_QUESTIONSELECTION).post(entity)
/**
 *
 * @param {*} entity
 */
export const questionTagDeleteService = entity =>
  new Request(URL.API, URL.DELETE_QUESTION_TAG).post(entity)
/**
 *
 * @param {*} id
 */
export const questionGameDeleteService = id =>
  new Request(URL.API, URL.DELETE_QUESTION_GAMEINFO).delete(id)
/**
 *
 * @param {*} id
 */
export const questionMediaDeleteService = id =>
  new Request(URL.API, URL.DELETE_QUESTIONMEDIA).delete({ id })
/**
 *
 * @param {*} id
 */
export const questionLinkDeleteService = id =>
  new Request(URL.API, URL.DELETE_QUESTIONLINK).delete({ id })
/**
 *
 * @param {*} id
 */
export const questionSelectionDeleteService = id =>
  new Request(URL.API, URL.DELETE_QUESTIONSELECTION).delete({ id })

/**
 *
 * @param {*} reqParams
 */
export const questionReportCompareTeamsService = reqParams =>
  new Request(URL.REPORT_API, URL.QUESTION_REPORT_COMPARE_TEAMS).post(reqParams)
/**
 *
 * @param {*} reqParams
 */
export const questionReportRelatedTeamsService = reqParams =>
  new Request(URL.REPORT_API, URL.QUESTION_REPORT_RELATED_TEAMS).post(reqParams)
/**
 *
 * @param {*} reqParams
 */
export const questionReportShowUserAnswersService = reqParams =>
  new Request(URL.REPORT_API, URL.QUESTION_REPORT_SHOW_USER_ANSWERS).post(
    reqParams,
  )
/**
 *
 * @param {*} reqParams
 */
export const questionReportChoiceTendencyService = reqParams =>
  new Request(URL.REPORT_API, URL.QUESTION_REPORT_CHOICE_TENDENCY).post(
    reqParams,
  )

/**
 *
 * @param {*} reqParams
 */
export const questionReportImprovementService = reqParams =>
  new Request(URL.REPORT_API, URL.QUESTION_REPORT_IMPROVEMENT).post(reqParams)

/**
 *
 * @param {*} ids
 */
export const questionCloneService = ids =>
  new Request(URL.API, URL.CLONE_QUESTION).post(ids)

export const questionPreviewService = questionIds =>
  new Request(URL.API, URL.PREVIEW_QUESTION).post(questionIds)

/**
 *
 * @param {*} entity
 * @param {*} courseId
 */
export const questionAddInsideCourseService = (entity, courseId) =>
  new Request(URL.API, URL.ADD_QUESTION_INSIDE_COURSE).post({
    ...entity,
    courseId: courseId,
  })

export const createCustomerPortalSession = () =>
  new Request(URL.API, URL.CREATE_CUSTOMER_PORTAL_SESSION).post({})

/**
 *
 * @param {*} id
 */
export const getSertifierDeliveries = CardId =>
  new Request(URL.API, URL.GET_SERTIFIER_DELIVERIES).get({ CardId })

/**
 *
 * @param {*} entity
 */
export const questionSelectionsAddService = entity =>
  new Request(URL.API, URL.ADD_QUESTIONSELECTIONS).post(entity)
