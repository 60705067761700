import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Paper, Grid } from '@material-ui/core'
import styles from './styles'

const TemplateSelection = props => {
  const { classes, handleSelectedItem, Options } = props
  return (
    <>
      <Typography textAlign="center" variant="h3" marginTop="10px">
        Select Template
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          fontSize: '20px',
          fontWeight: 400,
          lineHeight: '30px',
          letterSpacing: '0em',
          textAlign: 'center',
          marginTop: '15px',
        }}
      >
        What’s your purpose of creating mini-course?
      </Typography>

      <Grid
        container
        spacing={5}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        style={{ maxWidth: 1200, marginTop: '5px' }}
      >
        {Options.map((option, index) => (
          <Grid item>
            <Paper
              key={option.index}
              className={classes.paper}
              sx={{
                p: 2,
                width: 1,
                bgcolor: '#fffff',
              }}
              onClick={() => {
                handleSelectedItem(index)
              }}
            >
              <img src={`${option.icon}`}></img>
              <Typography
                variant="subtitle1"
                textAlign={'center'}
                style={{ marginTop: '15px' }}
              >
                {option.cardName}
              </Typography>

              <Typography
                variant="paragraph"
                style={{
                  textAlign: 'center',
                  fontSize: '14px',
                  lineHeight: '22px',
                  fontWeight: 400,
                  marginTop: '15px',
                  maxWidth: '218px',
                  color: '#637381',
                }}
              >
                {option.text}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default withStyles(styles)(TemplateSelection)
