import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button, Paper, Grid } from '@material-ui/core'
import styles from './styles'

const EmbedView = props => {
  const { classes, selectedCourse, setTemplateSelected } = props

  return (
    <Grid container fullWidth className={classes.embedViewGrid} spacing={2}>
      <Grid item md={3} sx={12}>
        <Paper className={classes.embedViewPaper}>
          <Typography style={{ marginTop: 10 }} variant="subtitle1">
            {selectedCourse.name}
          </Typography>
          <Typography
            style={{ marginTop: 10, fontWeight: 500, fontSize: '0.9rem' }}
            variant="subtitle1"
          >
            About
          </Typography>
          <Typography
            variant="paragraph"
            style={{
              fontSize: '14px',
              lineHeight: '22px',
              fontWeight: 400,
              marginTop: '5px',
              color: '#637381',
            }}
          >
            {selectedCourse.about}
          </Typography>
          <Button
            variant="contained"
            onClick={() => setTemplateSelected(true)}
            style={{
              borderRadius: '10px',
              backgroundColor: '#015FFB',
              marginTop: 30,
            }}
          >
            Use this template
          </Button>
        </Paper>
      </Grid>
      <Grid item md={9} sx={12}>
        <Paper className={classes.embedViewPaper}>
          <div className={classes.iframeDiv}>
            <iframe
              className={classes.iframe}
              loading="lazy"
              src={'https://app.quizcube.io/' + selectedCourse.code}
            ></iframe>
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(EmbedView)
