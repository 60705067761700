const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  magic_link_root_header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '24px',
  },
  accordion__summary: {
    display: 'flex',
    //justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px',
    width: '100%',
    '& .MuiAccordionSummary-content': {
      margin: '0px',
    },
  },
  accordion__details: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    overflow: 'hidden',
    transition: '1s ease-in-out ',
    padding: '0px',
    marginBottom: 12,
  },
  accordion__details__content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    overflow: 'hidden',
    backgroundColor: '#F4F6F9',
    borderRadius: '12px',
  },
  menu_popover: {
    width: 'auto',
    '& .MuiMenuItem-root': {
      px: 1,
      typography: 'body2',
      backgroundColor: '#fff',
      color: '#000',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: '#F4F6F8',
        color: '#000',
      },
    },
  },
  iconButton: {
    width: '36px',
    height: '36px',
    borderRadius: '1rem',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#015FFB',
    },
  },
  root__teamName: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  root__teamName__number: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  root__teamName_divider: {
    marginRight: '10px',
    paddingRight: '10px',
    marginTop: '2px',
  },
  root__teamName_enrolled: {
    marginRight: '10px',
    marginTop: '2px',
  },
  mr: {
    marginRight: '5px',
  },
  team_list_root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 16px',
    borderRadius: '16px',

    height: '76px',
    //width: '100%',
    alignItems: 'center',
    gap: '16px',
  },
  team_list_root_avatar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    minWidth: '44px',
    height: '44px',
  },
  team_list_root_column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  magic_link_root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80px',
    padding: '12px 16px',
    borderRadius: 16,
  },
  magic_link_root_button: {
    backgroundColor: '#35CE64',
    width: '100%',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#29e746b3',
    },
  },
  magic_link_root__header: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 6,
  },

  magic_link_icon: {
    marginRight: '5px',
    width: '20px',
    height: '20px',
    marginTop: '3px',
    color: '#002E47!important',
  },
  magic_link_icon_button_div: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  magic_link_icon_button: {
    width: '24px',
    height: '24px',
    color: '#002E47',
    backgroundColor: '#fff',
  },
  displayF: {
    display: 'flex',
  },
  backButtonRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '36px',
    '&:hover': {
      cursor: 'pointer',
    },
    borderBottom: '1px solid rgba(145, 158, 171, 0.32)',
  },
  textArea: {
    textAlign: 'left',
    border: '1px solid rgba(145, 158, 171, 0.32) !important',
    borderRadius: '8px',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(145, 158, 171, 0.32)',
  },

  bottomButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    bottom: '24px',
    width: 453,
    height: '40px',
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
  },
  mailSentBottomButton: {
    borderRadius: '12px',
    width: '100%',
    boxShadow: 'none',
  },
  mailPreparationBottomButton: {
    borderRadius: '12px',
    width: '100%',
    border: 'none',
    backgroundColor: '#59B851',
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#7bcc75',
    },
  },
  liPadding: {
    padding: '0px 16px 0 32px',
  },
  root__summary: {
    display: 'flex',
    //justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px',
    width: '100%',
    //margin: '12px 0',
  },
  root__summary__auth: {
    display: 'flex',
    //justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px',
    width: '100%',
  },
  switch_div: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    display: 'flex',
    alignItems: 'center',
  },
  optIn__root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  optIn__root__description: {
    marginBottom: '8px',
  },
  paper: {
    padding: '4px',
  },
  magic_link_icon_black: {
    marginRight: '5px',
    width: '20px',
    height: '20px',
    marginTop: '3px',
    color: '##002E47',
  },
  customDomain_select: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '32px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  select: {
    width: '97%',
    minHeight: '40px',
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'unset',
    },
  },
  displayFAI: {
    display: 'flex',
    alignItems: 'center',
  },
  saveBottomButton: {
    backgroundColor: '#015FFB',
    color: '#fff',
    borderRadius: '12px',
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3a92e9',
    },
  },
  noTeamsDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    marginTop: '32px',
    padding: '16px',
    justifyContent: 'center',
  },
  customDomainView: {
    display: 'flex',
    padding: '6px 32px',
    gap: 4,
    backgroundColor: '#FFF',
    alignItems: 'center',
    borderRadius: '50px',
  },
  badge: {
    display: 'flex',
    height: '20px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    marginTop: '3px',
  },
  mailSent__header: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  mailSent__header__icon: {
    height: 330,
    backgroundColor: '#F4F6F8',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    padding: 16,
    borderRadius: 16,
  },
  mailSent__button: {
    color: '#fff',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mailPreparationBottomDimButton: {
    borderRadius: '12px',
    width: '100%',
    border: 'none',
    backgroundColor: '#919eab',
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#919eab',
    },
  },
  continueButton: {
    width: '91px',
    height: '36px',
    boxShadow: 'none',
    backgroundColor: '#015FFB',
    color: '#fff',
  },
  sendLinkToNewMembersOnlyContent: {
    paddingLeft: '24px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#f4f6f8',
    padding: '8px',
  },
})

export default styles
