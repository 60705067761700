import {
  COURSE_TIME_SERIES_LIST,
  COURSE_TIME_SERIES_LIST_SUCCESS,
  COURSE_TIME_SERIES_LIST_ERROR,
  COURSE_TIME_SERIES_REPORT,
  COURSE_TIME_SERIES_REPORT_SUCCESS,
  COURSE_TIME_SERIES_REPORT_ERROR,
} from './type'

/**
 *
 * @param {*} query
 */
export const courseTimeSeriesListAction = query => {
  return {
    type: COURSE_TIME_SERIES_LIST,
    payload: { query },
  }
}

/**
 *
 * @param {*} response
 */
export const courseTimeSeriesListSuccess = response => {
  return {
    type: COURSE_TIME_SERIES_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const courseTimeSeriesListError = () => {
  return {
    type: COURSE_TIME_SERIES_LIST_ERROR,
  }
}

export const courseTimeSeriesReportAction = () => {
  return {
    type: COURSE_TIME_SERIES_REPORT,
    payload: {},
  }
}

/**
 *
 * @param {*} response
 */
export const courseTimeSeriesReportSuccess = () => {
  return {
    type: COURSE_TIME_SERIES_REPORT_SUCCESS,
    payload: {},
  }
}

/**
 *
 */
export const courseTimeSeriesReportError = () => {
  return {
    type: COURSE_TIME_SERIES_REPORT_ERROR,
  }
}
