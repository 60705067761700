import translator from 'src/translate'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import parse from 'html-react-parser'
import styles from '../styles'
import {
  Typography,
  TextField,
  Box,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  CircularProgress,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardActions,
  Link,
  Divider,
  OutlinedInput,
} from '@material-ui/core'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import { assignmentPatchAction } from 'src/reducers/assignment/action'
import { useDispatch } from 'react-redux'
import {
  useCourseSettings,
  createSetting,
  patchSetting,
} from 'src/hooks/useCourseSettings'

import {
  AssignmentFieldNames,
  AssignmentInitialCourseSettings,
} from 'src/model/assignment'
import { PatchContent, PatchData } from 'src/model/patch'

import URL from 'src/api/url/api'
const SETTING_KEY = 'seo'
const SeoSettings = props => {
  const {
    classes,
    entity,
    setSettingDrawerOpen,
    assignmentDescription,
    setAssignmentDescription,
    assignmentTitle,
    setAssignmentTitle,
  } = props
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)

  const [faviconUrl, setFaviconUrl] = useState(exists ? value.favicon : '')

  const [thumbnailUrl, setThumbnailUrl] = useState(
    exists ? value.thumbnail : '',
  )

  const [indexGoogle, setIndexGoogle] = useState(
    exists ? value.indexGoogle : false,
  )

  const [title, setTitle] = useState(entity.name)

  const [customDomain, setCustomDomain] = useState(
    entity.customDomainDtos[0]
      ? entity.customDomainDtos[0].domain +
          '/' +
          entity.customDomainDtos[0].defaultCourseDto.code
      : URL.APP_URL + entity.code,
  )

  const ogURL =
    'https://images.socialsplash.xyz/b0bd52d9-05c0-42dd-9fd0-ce8444ac0901?title=' +
    title +
    '&subtitle=-&background=%23E7F430&text=%23000000'
  const onThumbnailUpload = event => {
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        setThumbnailUrl(`${result.url}`)
      }),
    )
  }

  const onFaviconUpload = event => {
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        setFaviconUrl(`${result.url}`)
      }),
    )
  }
  const handleIndexGoogle = () => {
    setIndexGoogle(!indexGoogle)
  }

  const saveSettings = e => {
    const variables = {
      title: title,
      description: assignmentDescription,
      favicon: faviconUrl,
      thumbnail: thumbnailUrl,
      indexGoogle: indexGoogle,
    }
    setLoading(true)

    if (!exists) {
      // create a setting entity with execute function
      execute(variables, () => {
        setLoading(false)
        setSettingDrawerOpen(false)
      })
    } else {
      update(variables, () => {
        setLoading(false)
        setSettingDrawerOpen(false)
      })
    }
    const patchSource = new PatchData(null, entity[AssignmentFieldNames.ID])

    patchSource.addContent(
      new PatchContent(
        assignmentTitle,
        AssignmentFieldNames.NAME,
        PatchData.OPERATION_REPLACE,
      ),
    )
    patchSource.addContent(
      new PatchContent(
        assignmentDescription,
        AssignmentFieldNames.DESCRIPTION,
        PatchData.OPERATION_REPLACE,
      ),
    )
    dispatch(
      assignmentPatchAction(patchSource, () => {
        setLoading(false)
        setSettingDrawerOpen(false)
      }),
    )
  }
  const handleTextFieldChangeDescription = e => {
    setAssignmentDescription(e.target.value)
  }
  const handleTextFieldChange = e => {
    setAssignmentTitle(e.target.value)
  }
  return (
    <form>
      <div className={classes.content}>
        <OutlinedInput
          sx={{
            borderColor: 'black',
            width: '-webkit-fill-available',
            marginBottom: '16px',
            height: '40px',
          }}
          defaultValue={assignmentTitle}
          placeholder={translator._('assignment.assignmentName')}
          onChange={handleTextFieldChange}
        />
        <Box m={1}></Box>
        <TextField
          className={classes.input__description}
          style={{ width: '-webkit-fill-available', marginBottom: '16px' }}
          multiline
          maxRows={3}
          minRows={3}
          defaultValue={assignmentDescription}
          placeholder="Description (for collection page & SEO)"
          onChange={handleTextFieldChangeDescription}
        />
        <Box m={1}></Box>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {translator._('assignment.courseSettings.favIcon')}
        </Typography>
        <Box m={0.25}></Box>
        {faviconUrl ? (
          <div className={classes.imageUpload}>
            <img
              width={64}
              height={64}
              alt=""
              src={`${URL.MEDIA}` + faviconUrl}
            />
            <Box m={0.2}></Box>
            <a
              onClick={() => setFaviconUrl()}
              className={classes.removeImageButton}
            >
              {translator._('assignment.courseSettings.changeImage')}
            </a>
          </div>
        ) : (
          <div>
            <TextField
              type="file"
              fullWidth={true}
              onChange={onFaviconUpload}
            />
            <Box m={0.25}></Box>
            <Typography variant="subtitle">
              {parse(translator._('assignment.courseSettings.recommend'))}
            </Typography>
          </div>
        )}
        {/*
        <Box m={1}></Box>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {translator._('assignment.courseSettings.thumbNail')}
        </Typography>
        <Box m={0.25}></Box>
        {thumbnailUrl ? (
          <div className={classes.imageUpload}>
            <img
              className={classes.thumbNailImage}
              alt=""
              src={`${URL.MEDIA}` + thumbnailUrl}
            />
            <Box m={0.2}></Box>
            <a
              onClick={() => setThumbnailUrl()}
              className={classes.removeImageButton}
            >
              {translator._('assignment.courseSettings.changeImage')}
            </a>
          </div>
        ) : (
          <div>
            <TextField
              type="file"
              fullWidth={true}
              onChange={onThumbnailUpload}
            />
          </div>
        )}*/}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                id="indexGoogle"
                checked={indexGoogle}
                onChange={() => {
                  handleIndexGoogle(indexGoogle)
                }}
              />
            }
            label={translator._('assignment.courseSettings.indexGoogle')}
          />
        </FormGroup>
        <Box m={0.5}></Box>

        <Box m={1}></Box>

        <Divider variant="fullWidth" />
        <Box m={0.5}></Box>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {translator._('assignment.courseSettings.searchEngine')}
        </Typography>
        <Box m={0.5}></Box>
        <div className={classes.previewCard}>
          <Link variant="h6">{title}</Link>
          <Typography
            variant="subtitle2"
            color="secondary"
            overflow="hidden"
            textOverflow="ellipsis"
            height="20px"
          >
            {customDomain}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {assignmentDescription}
          </Typography>
        </div>
        <Box m={0.5}></Box>
        <Divider variant="fullWidth" />
        <Box m={1}></Box>

        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {translator._('assignment.courseSettings.social')}
        </Typography>
        <Box m={1}></Box>

        <div className={classes.imageUpload}>
          <Card className={classes.card}>
            <CardMedia
              component="img"
              alt="SEO Image"
              height="180"
              image={thumbnailUrl ? `${URL.MEDIA}` + thumbnailUrl : ogURL}
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                {title}
              </Typography>
              <Typography variant="subtitle2" component="p">
                {assignmentDescription}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                overflow="hidden"
                textOverflow="ellipsis"
                height="20px"
              >
                {customDomain}
              </Typography>
            </CardContent>
          </Card>
        </div>
        <Box m={5}></Box>
        {loading ? (
          <div className={classes.bottomButton}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <div className={classes.bottomButton}>
            <Button
              fullWidth={true}
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => saveSettings()}
            >
              {translator._('assignment.courseSettings.save')}
            </Button>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }} m={1}></Box>
            <Button
              fullWidth={true}
              color="secondary"
              size="small"
              variant="outlined"
              sx={{ display: { xs: 'block', sm: 'none' } }}
              onClick={() => setSettingDrawerOpen(false)}
            >
              {translator._('assignment.courseSettings.cancel')}
            </Button>
          </div>
        )}
      </div>
    </form>
  )
}

export default withStyles(styles)(SeoSettings)
