import React, { Component } from 'react'
import styles from './style'
import { Grid, withStyles, CircularProgress } from '@material-ui/core'
import CenterBox from '../../components/center-box'
import propTypes from './prop'
import FormController from '../../form'
import LayoutForm from './layout/form'
import LayoutSearch from './layout/search'
import { FormUseModeType } from '../../form/props'

class Form extends Component {
  _formController = null
  state = {
    formContent: null,
  }

  /**
   *
   */
  onPatch = e => {
    const { onPatch } = this.props
    const { formContent } = this.state

    if (e.formContent && formContent.addTitle == e.formContent.addTitle) {
      this.setState({ formContent: e.formContent })

      onPatch(e.data, this._formController)
    }
  }

  /**
   *
   */
  onUpdated = e => {
    const { formContent } = this.state

    if (e.formContent && formContent.addTitle == e.formContent.addTitle) {
      this.setState({ formContent: e.formContent })
    }
  }

  /**
   *
   */
  handleCancel = () => {
    const { onCancel } = this.props
    if (onCancel) {
      onCancel()
    }
  }

  /**
   *
   */
  updateFormContent = form => {
    this.setState({ formContent: form })

    this._formController.form = form
  }

  /**
   *
   */
  handleSave = async () => {
    const { onSave } = this.props

    let hasError = await this._formController.checkError()

    this.setState({ formContent: { ...this._formController.form } })

    if (hasError) {
      return
    }

    onSave(await this._formController.entity())
  }

  handlePut = async () => {
    const { onPut } = this.props

    let hasError = await this._formController.checkError()

    this.setState({ formContent: { ...this._formController.form } })

    if (hasError) {
      return
    }

    onPut(await this._formController.entity())
  }

  /**
   *
   */
  renderForm = () => {
    const { updateMethod, stateMode, form, viewMode, dispatch } = this.props
    const { formContent } = this.state

    return (
      <LayoutForm
        updateMethod={updateMethod}
        stateMode={stateMode}
        dispatch={dispatch}
        editTitle={form.editTitle}
        addTitle={form.addTitle}
        formContent={formContent}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        onPut={this.handlePut}
        viewMode={viewMode}
      />
    )
  }

  /**
   *
   */
  renderSearch = () => {
    const { dispatch } = this.props
    const { formContent } = this.state

    return (
      <LayoutSearch
        formContent={formContent}
        dispatch={dispatch}
        formController={this._formController}
        updateFormContent={this.updateFormContent}
      />
    )
  }

  /**
   *
   */
  render() {
    const { classes, useMode, viewMode } = this.props
    const { formContent } = this.state
    let rootClasses = classes.drawerRoot
    if (viewMode === 'inline') {
      rootClasses = classes.inlineRoot
    }

    if (viewMode === 'modal') {
      rootClasses = classes.modalRoot
    }

    return (
      <Grid container className={rootClasses}>
        {formContent &&
          (() => {
            switch (useMode) {
              case FormUseModeType.EDIT:
                return this.renderForm()
              case FormUseModeType.SEARCH:
                return this.renderSearch()
              default:
                return ''
            }
          })()}
        {!formContent && (
          <CenterBox>
            <CircularProgress className={classes.progress} color="inherit" />
          </CenterBox>
        )}
      </Grid>
    )
  }

  /**
   *
   */
  async componentDidMount() {
    const { form, entity, useMode, stateMode, updateMethod } = this.props
    this._formController = new FormController(
      form,
      entity,
      useMode,
      stateMode,
      updateMethod,
    )

    await this._formController.render()

    if (this._formController) {
      this.setState({ formContent: { ...this._formController.form } })
    }

    window.addEventListener('onPatch', this.onPatch)
    window.addEventListener('onUpdated', this.onUpdated)
  }

  /**
   *
   */
  componentWillUnmount() {
    window.removeEventListener('onPatch', this.onPatch)
    window.removeEventListener('onUpdated', this.onUpdated)

    this._formController.destroy()
    this._formController = null
  }
}

Form.propTypes = propTypes

export default withStyles(styles, { withTheme: true })(Form)
