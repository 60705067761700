import React from 'react'
import style from './style'
import propTypes from './prop'
import QuestionItem from '../item'
import { withStyles } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import SortableList, { SortableItem } from 'react-easy-sort'

import {
  QuestionFieldNames,
  AnswerItemType,
  QuestionEditorHeights,
  QuestionEditorResizable,
} from 'src/model/question'

const QuestionResponsiveList = props => {
  const {
    dispatch,
    list,
    onAdd,
    onMove,
    onRemove,
    content,
    showSortOrder,
    refresh,
    onDragEnd,
    classes,
    courseId,
    courseCode,
    baseEntity,
    onAddCard,
    page,
    setPage,
  } = props

  const [cachedListLength, setCachedListLength] = React.useState(list.length)

  React.useEffect(() => {
    if (list && list.length > 0) {
      if (cachedListLength != 0) setPage(list.length)

      setCachedListLength(list.length)
    }
  }, [list.length])

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <div className={classes.cardListHolder}>
      {list.length > 0 && list[page - 1] && (
        <SortableList>
          <QuestionItem
            dispatch={dispatch}
            entity={list[page - 1]}
            index={page - 1}
            id={list[page - 1].id}
            onMove={onMove}
            onClick={e => {
              handleChange(e, page - 1 + 1)
            }}
            cardCount={list?.length}
            onDrag={(prev, next) => {
              onDragEnd(prev, next)
              handleChange(null, next + 1)
            }}
            onRemove={onRemove}
            refresh={refresh}
            showSortOrder={showSortOrder}
            courseId={courseId}
            baseEntity={baseEntity}
            onAddCard={onAddCard}
            setPage={setPage}
            lastCardFinish={
              list[list.length - 1][QuestionFieldNames.QUESTION_TYPE] >= 12 &&
              list[list.length - 1][QuestionFieldNames.QUESTION_TYPE] <= 15
            }
          />
          <Pagination
            count={list.length}
            className={classes.pagination}
            page={page}
            onChange={handleChange}
          />
        </SortableList>
      )}
    </div>
  )
}

QuestionResponsiveList.propTypes = propTypes

export default withStyles(style)(QuestionResponsiveList)
