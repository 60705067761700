import { MESSAGE_BOX_SHOW, MESSAGE_BOX_HIDE } from './type'
import MessageBoxContent from '../../model/message-box'

export default function (state = {}, action) {
  switch (action.type) {
    case MESSAGE_BOX_SHOW:
      const { content } = action.payload
      if (content instanceof MessageBoxContent) {
        return {
          ...state,
          content: content.toJSON(),
        }
      } else {
        console.error(
          'MessageBox needs to object type like MessageBoxContent. Please check it.',
        )
      }

      return state
    case MESSAGE_BOX_HIDE:
      return {
        ...state,
        content: null,
      }
    default:
      return state
  }
}
