import React from 'react'
import styles from './style'
import propTypes from './prop'
import { Grid, withStyles } from '@material-ui/core'
import translator from 'src/translate'

import { TextField } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const QuestionFreeform = props => {
  const { classes } = props

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item className={classes.grid}>
        <TextField
          multiline
          rows="2"
          disabled
          row={5}
          fullWidth
          label={translator._('question.addQuestion.answerType.freeFormLabel')}
        />
      </Grid>
    </Grid>
  )
}

QuestionFreeform.propTypes = propTypes

export default withStyles(styles)(QuestionFreeform)
