const style = theme => ({
  paginationSelect: {
    padding: 0,
    margin: `0 ${theme.spacing(1)}px`,
    width: theme.spacing(7),
    fontSize: 13,
    color: '#757575',
  },
  countCell: {
    paddingLeft: 0,
  },
  gridFooter: {
    float: 'right',
  },
})

export default style
