import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import translator from '../../../translate'
import { Typography } from '@material-ui/core'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import UserRoleEdit from 'src/components/edit-user-role'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { ListColumnType } from '../../../model/list'
import { ButtonType, ButtonData, ButtonActionType } from '../../../model/button'
import { ListUseModeType } from '../../../model/list'
import { ListSchema } from '../../../model/list'
import UserType from 'src/model/user-types'
/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 */

const columns = [
  {
    attribute: 'id',
    label: 'id',
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: entity => {
      return (
        <Typography variant="body1" style={{ fontSize: 13 }}>
          {entity.id}
        </Typography>
      )
    },
  },
  {
    attribute: 'name',
    label: 'name',
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: entity => {
      return (
        <Typography variant="body1" style={{ fontSize: 13 }}>
          {entity.name}
        </Typography>
      )
    },
  },
  {
    attribute: 'surname',
    label: 'surname',
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: entity => {
      return (
        <Typography variant="body1" style={{ fontSize: 13 }}>
          {entity.surname}
        </Typography>
      )
    },
  },
  {
    attribute: 'userName',
    label: 'userName',
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: entity => {
      return (
        <Typography variant="body1" style={{ fontSize: 13 }}>
          {entity.userName}
        </Typography>
      )
    },
  },
  {
    attribute: 'email',
    label: 'email',
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: entity => {
      return (
        <Typography variant="body1" style={{ fontSize: 13 }}>
          {entity.email}
        </Typography>
      )
    },
  },
  {
    attribute: 'roleType',
    label: 'roleType',
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: entity => {
      return (
        <Typography variant="body1" style={{ fontSize: 13 }}>
          {UserType[entity.roleType]}
        </Typography>
      )
    },
  },
  {
    attribute: '',
    label: null,
    type: ListColumnType.ACTION,
    sortable: false,
    converter: null,
    suffix: null,
    actions: [
      {
        label: 'Edit',
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            componentBoxShowAction(
              new ComponentBoxContent(
                (
                  <UserRoleEdit
                    dispatch={dispatch}
                    entity={entity}
                    refresh={refresh}
                  />
                ),
                {
                  hideCloseButton: false,
                },
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },
]

export default columns
