import { put, call, take, fork } from 'redux-saga/effects'
import { questionSelectionDeleteService } from '../../api/question'
import { questionSelectionDeleteSuccess } from '../../reducers/assignment/action'
import { QUESTION_SELECTION_DELETE } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionSelectionDeleteFlow(payload) {
  try {
    const { id, questionId, callback } = payload
    yield call(questionSelectionDeleteService, id)

    yield put(questionSelectionDeleteSuccess(id, questionId))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionSelectionDeleteWatcher() {
  while (true) {
    const action = yield take(QUESTION_SELECTION_DELETE)
    const { payload } = action

    yield fork(generalSaga, questionSelectionDeleteFlow, payload)
  }
}
