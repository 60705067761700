import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import styles from './styles'

const BottomButton = props => {
  const { title, onClick, className, variant, disabled, classes } = props
  return (
    <div className={classes.bottomButton}>
      <Button
        fullWidth={true}
        style={{
          height: '100%',
        }}
        size="small"
        variant={variant}
        onClick={onClick}
        className={className}
        disabled={disabled}
      >
        {title}
      </Button>
    </div>
  )
}

export default withStyles(styles)(BottomButton)
