import { put, call, take, fork } from 'redux-saga/effects'
import { signUpRootieService } from '../../api/sign-up'
import { signUpRootieSuccess } from '../../reducers/sign-up/action.js'
import { SIGN_UP_ROOTIE } from '../../reducers/sign-up/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* signUpRootieSagaFlow(payload) {
  try {
    const { data } = payload
    const response = yield call(signUpRootieService, data)

    yield put(signUpRootieSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* signUpRootieSagaWatcher() {
  while (true) {
    const action = yield take(SIGN_UP_ROOTIE)
    const { payload } = action

    yield fork(generalSaga, signUpRootieSagaFlow, payload)
  }
}
