import { put, call, take, fork } from 'redux-saga/effects'
import { questionPatchService } from '../../api/question'
//import { questionPatchSuccess } from '../../reducers/question/action'
import { questionPatchSuccess } from '../../reducers/assignment/action'
import { QUESTION_PATCH } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionPatchFlow(payload) {
  try {
    const { content, callback } = payload

    yield call(questionPatchService, content)
    yield put(questionPatchSuccess(content))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionPatchWatcher() {
  while (true) {
    const action = yield take(QUESTION_PATCH)
    const { payload } = action

    yield fork(generalSaga, questionPatchFlow, payload, false)
  }
}
