import React from 'react'
// material
import { Container, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import style from './style'
import UserList from '../../../container/user-role-list'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function UserDetailedList(props) {
  const { classes } = props

  return (
    <Container>
      <React.Fragment>
        <div className={classes.card}>
          <UserList></UserList>
        </div>{' '}
      </React.Fragment>
    </Container>
  )
}

export default connect()(withStyles(style)(UserDetailedList))
