const styles = theme => ({
  form: {
    maxWidth: '50vw',
  },
  marginBottom: {
    marginBottom: '.7rem',
  },
  marginTop: {
    marginTop: '.7rem',
  },
  button: {
    marginTop: '.7rem',
    border: 'none',
    width: 'fit-content',
    alignSelf: 'flex-end',
    '&:hover': {
      border: 'none',
    },
  },
  righted: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  buttonAction: {
    width: 'fit-content',
    fontSize: '.8rem'
  },
  noPadding: {
    margin: '0!important',
    padding: '0!important',
  },
  fullWidth: {
    width: '100%',
  },
  accordionDetails: {
    overflow: 'auto',
    maxHeight: '35vh',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  amountInfo: { fontSize: 11, marginBottom: 17, marginTop: 4, marginLeft: 14 },
})

export default styles
