import { SHOP_UPDATE_ENTITY, SHOP_GET_SUCCESS, SHOP_EDIT_SUCCESS } from './type'

export default function (
  state = {
    list: { results: null, totalCount: 0 },
    entity: null,
    query: null,
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case SHOP_UPDATE_ENTITY:
      const { entity } = payload

      if (entity == null) {
        return {
          ...state,
          entity: null,
        }
      }

      if (state.entity == null) {
        state.entity = {}
      }

      return {
        ...state,
        entity: {
          ...state.entity,
          ...entity,
        },
      }
    case SHOP_GET_SUCCESS:
      return { ...state, entity: payload.response }
    case SHOP_EDIT_SUCCESS:
      return { ...state, entity: payload.response }
    default:
      return state
  }
}
