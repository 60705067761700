import React, { useState, useEffect } from 'react'
import styles from './style'
import propTypes from './prop'
import {
  withStyles,
  FormControl,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import translator from 'src/translate'

import {
  questionSettingPatch,
  questionSettingCreate,
  questionSettingRemove,
} from 'src/reducers/assignment/action'

import { AssignmentFieldNames } from 'src/model/assignment'
import { QuestionFieldNames } from 'src/model/question'

import { useDispatch, useSelector } from 'react-redux'

import { getSertifierDeliveries } from 'src/api/question'

const SertifierModal = props => {
  const { classes, cardId } = props
  const baseEntity = useSelector(
    state => state.assignment.entity[AssignmentFieldNames.QUESTION_DTOS],
  )

  useEffect(() => {
    fetchAPI()
  }, [])

  useEffect(() => {
    fetchAPI()
  }, baseEntity)

  const dispatch = useDispatch()

  const entity = baseEntity.find(x => x[QuestionFieldNames.ID] == cardId)

  const [sertifierKey, setSertifierKey] = useState()
  const [loading, setLoading] = useState(false)

  const [inputValue, setInputValue] = useState('')

  const [data, setData] = useState(false)
  const [deliveryTitle, setDeliveryTitle] = useState('')

  const entityId = entity[QuestionFieldNames.ID]
  const sertifierData = entity[QuestionFieldNames.SETTING_DTOS].find(
    x => x.key === 'sertifierPublishableKey',
  )
  const isSertifierConnected = sertifierData && sertifierData.value != '' // 1: Sertifier
  const deliveryData = entity[QuestionFieldNames.SETTING_DTOS].find(
    x => x.key === 'sertifierDeliveryId',
  ) // 1: Sertifier

  async function fetchAPI() {
    if (!isSertifierConnected || data) return
    try {
      let response = await getSertifierDeliveries(entityId)

      if (response?.statusCode == 200) {
        if (deliveryData) {
          let selectedData = response.data.find(
            x => x.id === deliveryData.value,
          )
          setDeliveryTitle(selectedData)
        }
        setData(response.data)
      } else {
        // err
      }
    } catch (e) {
      // err
    }
  }

  const patchAction = (id, key, value) => {
    if (loading) return

    setLoading(true)
    dispatch(
      questionSettingPatch(
        {
          path: 'Value',
          value: value,
        },
        id,
        entityId,
        key,
        () => {
          setLoading(false)
        },
      ),
    )
  }

  const connectButtonClickHandler = () => {
    if (loading) return
    setLoading(true)
    setData(false)
    dispatch(
      questionSettingCreate(
        {
          cardId: entityId,
          key: 'sertifierPublishableKey',
          value: sertifierKey,
          private: true,
        },
        cardId,
        () => {
          setLoading(false)
        },
      ),
    )
  }

  const disconnectButtonClickHandler = () => {
    if (!isSertifierConnected) return

    dispatch(questionSettingRemove(entityId, sertifierData.id))
  }

  const saveSettings = () => {
    if (loading) return
    setLoading(true)

    if (inputValue == '') return
    if (deliveryData) {
      patchAction(deliveryData.id, 'sertifierDeliveryId', inputValue)
    } else {
      dispatch(
        questionSettingCreate(
          {
            cardId: entityId,
            type: 1,
            key: 'sertifierDeliveryId',
            value: inputValue,
          },
          cardId,
          () => {
            setLoading(false)
          },
        ),
      )
    }
  }

  return (
    <FormControl className={classes.form}>
      {!isSertifierConnected && (
        <>
          <TextField
            className={classes.marginBottom + ' ' + classes.fullWidth}
            label={translator._('taskItem.sertifier.sertifierKeyText')}
            onBlur={event => {
              setSertifierKey(event.target.value)
            }}
            disabled={loading}
          />
          <Button
            variant="contained"
            className={classes.marginTop + ' ' + classes.marginBottom}
            onClick={connectButtonClickHandler}
            disabled={loading}
          >
            {translator._('action.connectToSertifier')}
          </Button>
        </>
      )}

      {isSertifierConnected && (
        <>
          <div className={classes.righted}>
            <Button
              variant="text"
              className={classes.buttonAction}
              onClick={disconnectButtonClickHandler}
              disabled={loading}
            >
              {translator._('action.disconnect')}
            </Button>
          </div>
          <TextField
            className={classes.marginBottom + ' ' + classes.fullWidth}
            label={translator._('taskItem.sertifier.sertifierKeyText')}
            disabled={true}
            defaultValue={sertifierData.value}
          />
          {data && data.length > 0 ? (
            <Autocomplete
              className={classes.marginBottom + ' ' + classes.fullWidth}
              options={data}
              getOptionLabel={option => option.title}
              onChange={(event, newValue) => {
                setInputValue(newValue?.id)
              }}
              disableClearable={true}
              onBlur={saveSettings}
              defaultValue={deliveryData ? deliveryTitle : ''}
              renderInput={params => (
                <TextField
                  {...params}
                  label={translator._('taskItem.sertifier.select')}
                  defaultValue={deliveryData ? deliveryTitle : ''}
                />
              )}
            />
          ) : (
            <CircularProgress />
          )}
        </>
      )}
    </FormControl>
  )
}

SertifierModal.propTypes = propTypes

export default withStyles(styles)(SertifierModal)
