import { put, call, take, fork } from 'redux-saga/effects'
import { excelExportCourseStatisticsReportService } from '../../api/excel-report'
import FileDownload from 'js-file-download'
import { COURSE_STATISTICS_REPORT } from '../../reducers/course-statistics/type'
import { courseStatisticsReportSuccess } from '../../reducers/course-statistics/action'

import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* courseStatisticsReportFlow() {
  try {
    const response = yield call(excelExportCourseStatisticsReportService)

    yield put(courseStatisticsReportSuccess())

    FileDownload(response, 'coursestatistics.xlsx')

    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* courseStatisticsReportWatcher() {
  while (true) {
    const action = yield take(COURSE_STATISTICS_REPORT)
    const { payload } = action

    yield fork(generalSaga, courseStatisticsReportFlow, payload)
  }
}
