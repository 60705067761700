import React from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles, Alert, Card, CardContent } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'

import {
  QuestionFieldNames,
  QuestionPaymentFieldNames,
} from 'src/model/question'

import { currencySymbols } from 'src/model/payment'

const Stripe = props => {
  const { entity, classes } = props
  const paymentData = entity[QuestionFieldNames.PAYMENT]
    ? entity[QuestionFieldNames.PAYMENT][0]
      ? entity[QuestionFieldNames.PAYMENT][0]
      : {}
    : {}
  const isStripeConnected =
    paymentData[QuestionPaymentFieldNames.ACCOUNT_ID] &&
    paymentData[QuestionPaymentFieldNames.ACCOUNT_ID] != undefined

  const currencySymbol =
    paymentData && paymentData[QuestionPaymentFieldNames.CURRENCY]
      ? currencySymbols[
          paymentData[QuestionPaymentFieldNames.CURRENCY].toUpperCase()
        ]
        ? currencySymbols[
            paymentData[QuestionPaymentFieldNames.CURRENCY].toUpperCase()
          ]
        : ''
      : ''
  return (
    <div className={classes.stripeForm}>
      {!isStripeConnected ? (
        <Alert severity="warning" className={classes.alert}>
          To connect your Stripe account, click on the{' '}
          <SettingsIcon fontSize="small" /> icon below.{' '}
        </Alert>
      ) : paymentData[QuestionPaymentFieldNames.AMOUNT] === undefined ||
        paymentData[QuestionPaymentFieldNames.AMOUNT] <= 0 ? (
        <Alert severity="warning" className={classes.alert}>
          The amount has not been determined. This card is considered invalid.
        </Alert>
      ) : (
        <>
          <Alert severity="success" className={classes.alert}>
            Your Stripe account is now connected {paymentData[QuestionPaymentFieldNames.MODE] == 0 && "(in TEST MODE) "}and pay-wall is active {
              paymentData[QuestionPaymentFieldNames.MODE] == 0 ? "for your tests" : "to receive payments"
            } 👍
          </Alert>
          <div className={classes.currencyInformation}>
            {currencySymbol}
            {paymentData[QuestionPaymentFieldNames.AMOUNT]}
          </div>
          <img
            className={classes.previewImage}
            src="/assets/payment.gif"
            alt="Stripe payment sample image."
          ></img>
        </>
      )}
    </div>
  )
}

Stripe.propTypes = propTypes

export default withStyles(styles)(Stripe)
