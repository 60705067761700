const style = theme => ({
  bodyRow: {
    backgroundColor: '#fff',
    '&:nth-child(even)': {
      backgroundColor: '#f9fafc',
    },
  },
  iconColor: {
    //color: 'green',
    marginRight: theme.spacing(1),
  },
  actionMenu: {
    color: '#212123',
    cursor: 'pointer',
    border: '1px solid #ced4da',
    display: 'inline-flex',
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    backgroundColor: 'white',
    marginRight: theme.spacing(2),
  },
  action: {
    cursor: 'pointer',
    display: 'inline-flex',
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: 'none',
    marginRight: theme.spacing(2),
    height: '100%',
  },
  actionMenuIcon: {
    marginLeft: theme.spacing(0.5),
  },
  buttonsGrid: {
    height: '40px',
  },
})

export default style
