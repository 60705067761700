/*eslint-disabled */
export default class ComponentBoxContent {
  /**
   *
   * @param {*} element
   * @param {*} closeCallback
   */
  constructor(element, options) {
    this._element = element
    this._options = options
  }

  /**
   *
   */
  toJSON() {
    return {
      element: this._element,
      options: this._options,
    }
  }
}
/*eslint-enabled */
