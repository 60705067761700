import React from 'react'
import styles from './styles'
import { withStyles, IconButton, Typography } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const BackButton = props => {
  const { classes, handleBack } = props
  return (
    <div className={classes.backButtonRoot} onClick={() => handleBack()}>
      <IconButton className={classes.magic_link_icon_button}>
        <ChevronLeftIcon />
      </IconButton>
      <Typography fontSize={'16px'} fontWeight={500}>
        {' '}
        Back
      </Typography>
    </div>
  )
}

export default withStyles(styles)(BackButton)
