const USER_UPDATE_ENTITY = 'USER_UPDATE_ENTITY'

const USER_LIST = 'USER_LIST'
const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
const USER_LIST_ERROR = 'USER_LIST_ERROR'

const USER_ID_LIST = 'USER_ID_LIST'
const USER_ID_LIST_SUCCESS = 'USER_ID_LIST_SUCCESS'
const USER_ID_LIST_ERROR = 'USER_ID_LIST_ERROR'

const USER_GET = 'USER_GET'
const USER_GET_SUCCESS = 'USER_GET_SUCCESS'
const USER_GET_ERROR = 'USER_GET_ERROR'

const USER_VALIDATE_SHORT_LINK = 'USER_VALIDATE_SHORT_LINK'
const USER_VALIDATE_SHORT_LINK_SUCCESS = 'USER_VALIDATE_SHORT_LINK_SUCCESS'
const USER_VALIDATE_SHORT_LINK_ERROR = 'USER_VALIDATE_SHORT_LINK_ERROR'

const USER_GET_SELF = 'USER_GET_SELF'
const USER_GET_SELF_SUCCESS = 'USER_GET_SELF_SUCCESS'
const USER_GET_SELF_ERROR = 'USER_GET_SELF_ERROR'

const USER_ADD = 'USER_ADD'
const USER_ADD_SUCCESS = 'USER_ADD_SUCCESS'
const USER_ADD_ERROR = 'USER_ADD_ERROR'

const USER_PATCH = 'USER_PATCH'
const USER_PATCH_SUCCESS = 'USER_PATCH_SUCCESS'
const USER_PATCH_ERROR = 'USER_PATCH_ERROR'

const ADMIN_PATCH = 'ADMIN_PATCH'
const ADMIN_PATCH_SUCCESS = 'ADMIN_PATCH_SUCCESS'
const ADMIN_PATCH_ERROR = 'ADMIN_PATCH_ERROR'

const DELETE_USER = 'DELETE_USER'
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

const USER_LIST_DELETE = 'USER_LIST_DELETE'
const USER_LIST_DELETE_SUCCESS = 'USER_LIST_DELETE_SUCCESS'
const USER_LIST_DELETE_ERROR = 'USER_LIST_DELETE_ERROR'

const USER_LIST_MAKE_ACTIVE = 'USER_LIST_MAKE_ACTIVE'
const USER_LIST_MAKE_ACTIVE_SUCCESS = 'USER_LIST_MAKE_ACTIVE_SUCCESS'
const USER_LIST_MAKE_ACTIVE_ERROR = 'USER_LIST_MAKE_ACTIVE_ERROR'

const USER_LIST_MAKE_PASSIVE = 'USER_LIST_MAKE_PASSIVE'
const USER_LIST_MAKE_PASSIVE_SUCCESS = 'USER_LIST_MAKE_PASSIVE_SUCCESS'
const USER_LIST_MAKE_PASSIVE_ERROR = 'USER_LIST_MAKE_PASSIVE_ERROR'

const USER_REPORT_QUESTION_ANALYSIS = 'USER_REPORT_QUESTION_ANALYSIS'
const USER_REPORT_QUESTION_ANALYSIS_SUCCESS =
  'USER_REPORT_QUESTION_ANALYSIS_SUCCESS'
const USER_REPORT_QUESTION_ANALYSIS_ERROR =
  'USER_REPORT_QUESTION_ANALYSIS_ERROR'
const USER_REPORT_DETAILED_ANALYSIS = 'USER_REPORT_DETAILED_ANALYSIS'
const USER_REPORT_DETAILED_ANALYSIS_SUCCESS =
  'USER_REPORT_DETAILED_ANALYSIS_SUCCESS'
const USER_REPORT_DETAILED_ANALYSIS_ERROR =
  'USER_REPORT_DETAILED_ANALYSIS_ERROR'
export {
  USER_UPDATE_ENTITY,
  USER_LIST,
  USER_LIST_SUCCESS,
  USER_LIST_ERROR,
  USER_ID_LIST,
  USER_ID_LIST_SUCCESS,
  USER_ID_LIST_ERROR,
  USER_GET,
  USER_GET_SUCCESS,
  USER_GET_ERROR,
  USER_VALIDATE_SHORT_LINK,
  USER_VALIDATE_SHORT_LINK_SUCCESS,
  USER_VALIDATE_SHORT_LINK_ERROR,
  USER_GET_SELF,
  USER_GET_SELF_SUCCESS,
  USER_GET_SELF_ERROR,
  USER_ADD,
  USER_ADD_SUCCESS,
  USER_ADD_ERROR,
  USER_PATCH,
  USER_PATCH_SUCCESS,
  USER_PATCH_ERROR,
  ADMIN_PATCH,
  ADMIN_PATCH_SUCCESS,
  ADMIN_PATCH_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  USER_LIST_DELETE,
  USER_LIST_DELETE_SUCCESS,
  USER_LIST_DELETE_ERROR,
  USER_LIST_MAKE_ACTIVE,
  USER_LIST_MAKE_ACTIVE_SUCCESS,
  USER_LIST_MAKE_ACTIVE_ERROR,
  USER_LIST_MAKE_PASSIVE,
  USER_LIST_MAKE_PASSIVE_SUCCESS,
  USER_LIST_MAKE_PASSIVE_ERROR,
  USER_REPORT_DETAILED_ANALYSIS,
  USER_REPORT_DETAILED_ANALYSIS_SUCCESS,
  USER_REPORT_DETAILED_ANALYSIS_ERROR,
  USER_REPORT_QUESTION_ANALYSIS,
  USER_REPORT_QUESTION_ANALYSIS_SUCCESS,
  USER_REPORT_QUESTION_ANALYSIS_ERROR,
}
