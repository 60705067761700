import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const mediaVideoGetByFilterService = query =>
  new Request(URL.API, URL.MEDIA_VIDEO_GET_BY_FILTER).post(query)

/**
 *
 * @param {*} id
 */
export const mediaVideoGetByIdService = id =>
  new Request(URL.API, URL.MEDIA_VIDEO_GET_BY_ID).get({ id })

/**
 *
 * @param {*} formData
 */
export const mediaVideoUploadManyService = formData =>
  new Request(URL.API, URL.MEDIA_VIDEO_UPLOAD_MANY).post(formData)

/**
 *
 * @param {*} formData
 */
export const mediaVideoUploadService = formData =>
  new Request(URL.API, URL.MEDIA_VIDEO_UPLOAD).post(formData)

/**
 *
 * @param {*} content
 */
export const mediaVideoPatchService = content =>
  new Request(URL.API, URL.MEDIA_VIDEO_PATCH).patch(content)

/**
 *
 * @param {*} id
 */
export const mediaVideoDeleteService = id =>
  new Request(URL.API, URL.MEDIA_VIDEO_DELETE).delete(id)

/**
 *
 * @param {*} ids
 */
export const mediaVideoDeleteListService = ids =>
  new Request(URL.API, URL.MEDIA_VIDEO_DELETE_LIST).post(ids)
