const styles = theme => ({
  divider: {},
  couponGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    spacing: 2,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  successGrid: {
    maxWidth: 300,
    margin: '0 auto',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  image: {
    width: '100%',
    height: undefined,
  },
  checkIcon: {
    fontSize: 22,
    color: 'green',
  },
  table: {
    maxWidth: 800,
    margin: '0 auto',
    marginTop: '20px',
  },
  tableTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    paddingLeft: '15px !important',
  },
  tableHead: {
    padding: 15,
  },
  icon: {
    marginTop: 6,
  },
  gridItem: {
    minWidth: 'max-content',
  },
  warningGrid: {
    maxWidth: 300,
    //margin: '0 auto',
    //justifyContent: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
})

export default styles
