import React from 'react'
import style from './style'
import propTypes from './prop'
import TeamItem from './item'
import TeamEmpty from './empty'
import { withStyles, Grid } from '@material-ui/core'

const TeamList = props => {
  const {
    dispatch,
    list,
    onAdd,
    onMove,
    onRemove,
    content,
    parentItemName,
    itemName,
    addItemButtonLabel,
    componentBoxContent,
  } = props

  /**
   *
   * @param {*} list
   */
  const onAddItem = list => {
    onAdd(content, list, dispatch)
  }

  return (
    <React.Fragment>
      <Grid container>
        {list.length > 0 &&
          list.map((q, i) => (
            <TeamItem
              dispatch={dispatch}
              entity={q}
              key={i}
              onMove={onMove}
              onRemove={onRemove}
              itemName={itemName}
              parentItemName={parentItemName}
            />
          ))}
        {onAdd && (
          <TeamEmpty
            onAdd={onAddItem}
            dispatch={dispatch}
            addItemButtonLabel={addItemButtonLabel}
            componentBoxContent={componentBoxContent}
          />
        )}
      </Grid>
    </React.Fragment>
  )
}

TeamList.propTypes = propTypes

export default withStyles(style)(TeamList)
