import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import translator from '../../../translate'
import { Typography } from '@material-ui/core'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import UserRoleEdit from 'src/components/edit-user-role'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { ListColumnType } from '../../../model/list'
import { ButtonType, ButtonData, ButtonActionType } from '../../../model/button'
import { ListUseModeType } from '../../../model/list'
import { ListSchema } from '../../../model/list'
import UserType from 'src/model/user-types'
import {
  DeleteMember,
  EditMember,
} from 'src/pages/teams-member-list/components'
import { Icon } from '@iconify/react'

/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 */

const columns = [
  {
    attribute: 'name',
    label: 'Name',
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: null,
    width: '49%',
  },

  {
    attribute: 'email',
    label: 'Email',
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
    renderer: null,
    width: '49%',
  },

  {
    attribute: '',
    label: null,
    type: ListColumnType.ACTION,
    sortable: false,
    converter: null,
    suffix: null,
    actions: [
      {
        label: 'Edit User',
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            componentBoxShowAction(
              new ComponentBoxContent(
                <EditMember entity={entity} dispatch={dispatch} />,
                {
                  hideCloseButton: false,
                },
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: 'Delete User',
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            componentBoxShowAction(
              new ComponentBoxContent(
                <DeleteMember entity={entity} dispatch={dispatch} />,
                {
                  hideCloseButton: false,
                },
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },
]

export default columns
