import React, { useState, useEffect } from 'react'
import translator from 'src/translate'
import { MButton } from 'src/theme'
import styles from './styles'
import StarIcon from '@material-ui/icons/Star'
import CodeIcon from '@material-ui/icons/Code'
import PhonelinkIcon from '@material-ui/icons/Phonelink'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { domainListAction } from 'src/reducers/domain/action'
import { AssignmentFieldNames } from 'src/model/assignment'
import {
  Card,
  Typography,
  TextField,
  withStyles,
  Grid,
  Button,
  Tab,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Box,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import URL from 'src/api/url'
import { Link } from 'react-router-dom'
import { track } from '@amplitude/analytics-browser'

const EmbedStep = props => {
  const { handleBack, classes, entity } = props
  const [tabValue, setTabValue] = useState(0)
  const [showShareButtons, setShowShareButtons] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }
  if (!entity) {
    return null
  }

  let shareCode = showShareButtons ? '?shr=1' : ''

  return entity ? (
    <Card className={classes.settingsContainer}>
      <div className={classes.headerLine}>
        <div className={classes.header}>
          <Typography variant="h6">Embed</Typography>
          <Typography variant="body2">
            Get code to embed in your website.
          </Typography>
        </div>
        {handleBack && (
          <MButton
            variant="outlined"
            color="inherit"
            className={classes.prevButton}
            onClick={() => handleBack()}
          >
            {translator._('general.back')}
          </MButton>
        )}
      </div>

      <div>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { background: '#00AB55' },
            }}
          >
            <Tab
              disableRipple
              label={translator._('assignment.embedCode')}
              value={0}
              icon={<CodeIcon fontSize="small" color="secondary" />}
            />

            <Tab
              disableRipple
              label={translator._('assignment.iframeCode')}
              value={1}
              icon={<PhonelinkIcon fontSize="small" color="secondary" />}
            />
          </TabList>

          <TabPanel value={tabValue} index={0} hidden={tabValue !== 0}>
            <div className={classes.settings}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showShareButtons}
                    onChange={(e, checked) => {
                      setShowShareButtons(checked)
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={translator._('assignment.showShareButtonsLabel')}
              />
            </div>
            <div className={classes.textFields}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <span>
                    Add the following codes to the {'<head>'} tag of your site.
                  </span>
                  <TextField
                    fullWidth
                    multiline
                    value={
                      '<meta http-equiv="Access-Control-Allow-Origin" content="' +
                      URL.APP_URL +
                      '" />' +
                      '<script src="' +
                      URL.APP_URL +
                      'viewer.js"></script>' +
                      '<script>' +
                      'const app = new Quiz();' +
                      'document.addEventListener("DOMContentLoaded", function () {' +
                      'app.init({code: "' +
                      entity[AssignmentFieldNames.CODE] +
                      '", page: 0, share: ' +
                      (shareCode ? 'true' : 'false') +
                      '});' +
                      '});' +
                      '</script>'
                    }
                    className={classes.textField}
                  />

                  <CopyToClipboard
                    text={
                      '<meta http-equiv="Access-Control-Allow-Origin" content="' +
                      URL.APP_URL +
                      '" />' +
                      '<script src="' +
                      URL.APP_URL +
                      'viewer.js"></script>' +
                      '<script>' +
                      'const app = new Quiz();' +
                      'document.addEventListener("DOMContentLoaded", function () {' +
                      'app.init({code: "' +
                      entity[AssignmentFieldNames.CODE] +
                      '", page: 0, share: ' +
                      (shareCode ? 'true' : 'false') +
                      '});' +
                      '});' +
                      '</script>'
                    }
                    className={classes.copy}
                  >
                    <Button variant="outlined">
                      {translator._('assignment.copy')}
                    </Button>
                  </CopyToClipboard>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className={classes.previewGroup}>
                    <Typography variant="body1">
                      You can embed your site with JavaScript code.
                      <br />
                      Paste the code in the {'<head>'} of your site.
                      <br />
                      <br />
                      Then add the following in the {'<body>'} where you want to
                      show the quiz:
                      <br />
                      <br />
                      {'<div id="quizcube-form-' +
                        entity[AssignmentFieldNames.CODE] +
                        '"></div>'}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <span>
                    Add the following codes to the {'<body>'} tag of your site.
                  </span>
                  <TextField
                    fullWidth
                    multiline
                    value={
                      '<div id="quizcube-form-' +
                      entity[AssignmentFieldNames.CODE] +
                      '"></div>'
                    }
                    className={classes.textField}
                  />

                  <CopyToClipboard
                    text={
                      '<div id="quizcube-form-' +
                      entity[AssignmentFieldNames.CODE] +
                      '"></div>'
                    }
                    className={classes.copy}
                  >
                    <Button variant="outlined">
                      {translator._('assignment.copy')}
                    </Button>
                  </CopyToClipboard>
                  <Box m={3}></Box>
                </Grid>
              </Grid>
            </div>
          </TabPanel>

          <TabPanel value={tabValue} index={1} hidden={tabValue !== 1}>
            <div className={classes.settings}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showShareButtons}
                    onChange={(e, checked) => {
                      setShowShareButtons(checked)
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={translator._('assignment.showShareButtonsLabel')}
              />
            </div>
            <div className={classes.textFields}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    multiline
                    value={
                      '<div id="iframe_container" style="width: 100%;position: relative;overflow: hidden;padding-top: 100%;"><iframe id="quizcube" name="quizcube_frame" title="Quiz-Cube" src="' +
                      '' +
                      URL.APP_URL +
                      '' +
                      entity[AssignmentFieldNames.CODE] +
                      '/0/' +
                      shareCode +
                      '" align="center" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  style="position: absolute; width: 100%;height: 100%;top: 0;right: 0;bottom: 0;left: 0;border: none;margin: 0px;padding: 0px;"></iframe></div>'
                    }
                    className={classes.textField}
                  />

                  <CopyToClipboard
                    text={
                      '<div id="iframe_container" style="width: 100%;position: relative;overflow: hidden;padding-top: 100%;"><iframe id="quizcube" name="quizcube_frame" title="Quiz-Cube" src="' +
                      '' +
                      URL.APP_URL +
                      '' +
                      entity[AssignmentFieldNames.CODE] +
                      '/0/' +
                      shareCode +
                      '" align="center"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  style="position: absolute; width: 100%;height: 100%;top: 0;right: 0;bottom: 0;left: 0;border: none;margin: 0px;padding: 0px;"></iframe></div>'
                    }
                    className={classes.copy}
                  >
                    <Button variant="outlined">
                      {translator._('assignment.copy')}
                    </Button>
                  </CopyToClipboard>
                  <Box m={6}></Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <div className={classes.previewGroup}>
                    <Typography variant="body1">
                      {translator._('assignment.embedCodeInfo')}
                      <br />
                      <br />
                      You can adjust your iframe code using third party tools
                      such as
                      <a target="_blank" href="https://iframe-generator.com">
                        iframe-generator.com
                      </a>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </Card>
  ) : null
}

export default withStyles(styles)(EmbedStep)
