const URL = {
  LIST_REMINDINGSETTING: 'RemindingSetting/GetRemindersByFilter',
  GET_REMINDINGSETTING: 'RemindingSetting/GetReminderById',
  ADD_REMINDINGSETTING: 'RemindingSetting/AddRemindingSetting',
  EDIT_REMINDINGSETTING: 'RemindingSetting/EditRemindingSetting',
  PATCH_REMINDINGSETTING: 'RemindingSetting/PatchRemindingSetting',
  DELETE_REMINDINGSETTING: 'RemindingSetting/DeleteRemindingSetting',
}

export default URL
