import React, { useState } from 'react'
import ListAssignments from '../../container/list-assignment'
import ListDomain from '../../container/list-domain'

import ListUserAvatar from '../../container/list-user-avatar-sample'
import ListUser from '../../container/list-user-sample'

import styles from './styles'

import { ListApplicationType } from 'src/model/list'
import { Box, withStyles } from '@material-ui/core'

const Assignments = props => {
  const { dispatch, history, entity, classes } = props

  const [isNamingModalOpen, setNamingModalOpen] = useState(false)

  return (
    <Box>
      <Box style={{ maxWidth: 900, margin: '0 auto' }}>
        <ListAssignments
          applicationType={ListApplicationType.ASSIGNMENT}
          setSelectionModalOpen={setNamingModalOpen}
        />
      </Box>

      <Box style={{ maxWidth: 900, margin: '0 auto' }}>
        <ListUser></ListUser>
      </Box>

      <Box style={{ maxWidth: 900, margin: '0 auto' }}>
        <ListUserAvatar></ListUserAvatar>
      </Box>

      <Box style={{ maxWidth: 900, margin: '0 auto' }}>
        <ListDomain></ListDomain>
      </Box>
    </Box>
  )
}

export default withStyles(styles)(Assignments)
