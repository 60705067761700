const UserFieldNames = {
  EMAIL: 'email',
  ID: 'id',
  USERCODE: 'userCode',
  USER_NAME: 'userName',
  NAME: 'name',
  SURNAME: 'surName',
  PASSWORD: 'password',
  PICTURE: 'picture',
  GENDER: 'gender',
  PHONE1: 'phone1',
  PHONE2: 'phone2',
  GSM1: 'gsm1',
  GSM2: 'gsm2',
  LANGUAGE: 'language',
  USER_TYPE: 'userType',
  ADDRESS: 'address',
  CITY: 'city',
  DISTRICT: 'district',
  ZIP_CODE: 'zipCode',
  WEB_ADDRESS: 'webAddress',
  FAX: 'fax',
  MANAGER: 'manager',
  MANAGER_ID: 'managerId',
  BIRTHDAY: 'birthday',
  REGISTER_ID: 'registerId',
  TCKN: 'tckn',
  DATE_OF_ENTRY: 'dateOfEntry',
  DATE_OF_LEAVE: 'dateOfLeave',
  MARITAL_STATUS: 'maritalStatus',
  PLACE_OF_BIRTH: 'placeOfBirth',
  EDUCATIONAL_STATUS: 'educationalStatus',
  ACTIVATION_DATE: 'activationDate',
  LAST_ACT_DATE: 'lastActDate',
  CREATE_DATE: 'createdAt',
  IS_ACTIVE: 'isActive',
  IS_INVITED: 'isInvited',
  IS_LOGGED_IN: 'isLoggedIn',
  IS_DELETED: 'isDeleted',
  LOCKOUT_END_DATE_UTC: 'lockoutEndDateUtc',
  PASSWORD_CREATION_TYPE: 'passwordCreationType',
  PASSWORD_CREATION_METHOD: 'passwordCreationMethod',
  TIMEZONE: 'timeZone',
  PAGE_AUTHORITY: 'pageAuthority',
  TAG_AUTHORITY: 'tagAuthority',
  USER_TEAM_LIST: 'userTeamRelationDTOs',
}

const UserSchema = () => {
  return {
    [UserFieldNames.ID]: null,
    [UserFieldNames.USERCODE]: '',
    [UserFieldNames.USER_NAME]: '',
    [UserFieldNames.NAME]: '',
    [UserFieldNames.SURNAME]: '',
    [UserFieldNames.PICTURE]: '',
    [UserFieldNames.GENDER]: '',
    [UserFieldNames.PHONE1]: '',
    [UserFieldNames.PHONE2]: '',
    [UserFieldNames.GSM1]: '',
    [UserFieldNames.GSM2]: '',
    [UserFieldNames.LANGUAGE]: 'en',
    [UserFieldNames.USER_TYPE]: 4,
    [UserFieldNames.ADDRESS]: '',
    [UserFieldNames.CITY]: '',
    [UserFieldNames.DISTRICT]: '',
    [UserFieldNames.ZIP_CODE]: '',
    [UserFieldNames.WEB_ADDRESS]: '',
    [UserFieldNames.FAX]: '',
    [UserFieldNames.MANAGER]: '',
    [UserFieldNames.MANAGER_ID]: '',
    [UserFieldNames.BIRTHDAY]: null,
    [UserFieldNames.REGISTER_ID]: '',
    [UserFieldNames.TCKN]: '',
    [UserFieldNames.DATE_OF_ENTRY]: '',
    [UserFieldNames.DATE_OF_LEAVE]: '',
    [UserFieldNames.MARITAL_STATUS]: '',
    [UserFieldNames.PLACE_OF_BIRTH]: '',
    [UserFieldNames.EDUCATIONAL_STATUS]: '',
    [UserFieldNames.ACTIVATION_DATE]: null,
    [UserFieldNames.LAST_ACT_DATE]: null,
    [UserFieldNames.CREATE_DATE]: null,
    [UserFieldNames.IS_ACTIVE]: true,
    [UserFieldNames.IS_INVITED]: false,
    [UserFieldNames.IS_LOGGED_IN]: false,
    [UserFieldNames.IS_DELETED]: false,
    [UserFieldNames.LOCKOUT_END_DATE_UTC]: '',
    [UserFieldNames.PASSWORD_CREATION_TYPE]: '',
    [UserFieldNames.PASSWORD_CREATION_METHOD]: '',
    [UserFieldNames.TIMEZONE]: '',
    [UserFieldNames.PAGE_AUTHORITY]: '',
    [UserFieldNames.TAG_AUTHORITY]: '',
    [UserFieldNames.EMAIL]: '',
    [UserFieldNames.USER_TEAM_LIST]: [],
  }
}

export { UserSchema, UserFieldNames }
