import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'
import {
  Typography,
  TextField,
  Box,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Tab,
} from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { useDispatch } from 'react-redux'
import UploadText from './upload-text'
import UploadUrl from './upload-url'
import UploadPdf from './upload-pdf'
import { Icon } from '@iconify/react'

const SettingsTabs = [
  /* {
       label: translator._('assignment.courseSettings.theme'),
       component: entity => <ThemeSettings entity={entity} />,
       enabled: true,
     },*/
  {
    label: 'Text',
    component: (
      documentUrl,
      setDocumentUrl,
      file,
      setFile,
      documentPdf,
      setDocumentPdf,
      documentText,
      setDocumentText,
      roleType,
      handleAddSource,
      cardText,
      limit,
    ) => (
      <UploadText
        documentText={documentText}
        setDocumentText={setDocumentText}
        roleType={roleType}
        handleAddSource={handleAddSource}
        cardText={cardText}
        limit={limit}
      />
    ),
    enabled: true,
  },
  {
    label: 'Url',
    component: (
      documentUrl,
      setDocumentUrl,
      file,
      setFile,
      documentPdf,
      setDocumentPdf,
      documentText,
      setDocumentText,
      roleType,
      handleAddSource,
    ) => (
      <UploadUrl
        documentUrl={documentUrl}
        setDocumentUrl={setDocumentUrl}
        handleAddSource={handleAddSource}
      />
    ),
    disabled: false,
  },

  {
    label: 'Upload',
    component: (
      documentUrl,
      setDocumentUrl,
      file,
      setFile,
      documentPdf,
      setDocumentPdf,
      documentText,
      setDocumentText,
      roleType,
      handleAddSource,
    ) => (
      <UploadPdf
        file={file}
        setFile={setFile}
        documentPdf={documentPdf}
        setDocumentPdf={setDocumentPdf}
        handleAddSource={handleAddSource}
      />
    ),
    enabled: true,
  },
]

const DescriptionText = () => {
  return (
    <div>
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 500,
          color: '#637381',
          marginBottom: 16,
        }}
      >
        💡 Add your resources to make AI Assistant create questions based on
        them.
      </Typography>
    </div>
  )
}

const AddSourceButton = props => {
  const { canAddSource, handleUploadDocuments } = props
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Button
        variant="contained"
        style={{
          width: '100%',
          height: '40px',
          fontSize: '16px',
          backgroundColor: canAddSource ? '#F67B3F' : '#342f2f1f',
          boxShadow: 'none',
          borderRadius: '50px',
        }}
        onClick={() => {
          handleUploadDocuments()
        }}
        disabled={!canAddSource}
      >
        Add Source
      </Button>
    </div>
  )
}
function AddDocument(props) {
  const {
    documentUrl,
    setDocumentUrl,
    file,
    setFile,
    documentPdf,
    setDocumentPdf,
    selectedMenu,
    setSelectedMenu,
    documentText,
    setDocumentText,
    roleType,
    handleUploadDocuments,
    cardText,
    limit,
    classes,
  } = props
  const [currentTab, setCurrentTab] = useState('0')
  const ButtonMenu = () => {
    return (
      <div
        style={{
          backgroundColor: '#F2F2F2',
          borderRadius: 50,
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        {SettingsTabs.map((item, index) => (
            <Button
              variant="contained"
              className={classes.buttonGroup}
              //disabled={currentServerType == 1 && index > 0}
              style={{
                backgroundColor:
                  item.label == selectedMenu ? '#F67B3F' : '#f2f2f2',
                color: item.label == selectedMenu ? '#fff' : '#1D1D1F',
              }}
              onClick={() => handleTabChange(item.label, index)}
              startIcon={
                roleType == 1 &&
                index != 0 && (
                  <Icon
                    size="small"
                    icon="fa6-solid:crown"
                    color="#f7b155"
                    width={15}
                  ></Icon>
                )
              }
              key= {"button_group_"+index}
            >
              <Typography style={{ fontWeight: 500, fontSize: '16px' }}>
                {item.label}
              </Typography>
            </Button>
        ))}
      </div>
    )
  }

  const handleTabChange = (event, newValue) => {
    if (roleType == 1 && newValue != 0) {
      window.open('https://admin.quizcube.io/pricing', '_blank')
    } else {
      setSelectedMenu(event)
      setCurrentTab(newValue.toString())
    }
  }
  const [canAddSource, setCanAddSource] = useState(false)

  const handleAddSource = e => {
    setCanAddSource(e)
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '455px',
      }}
    >
      <DescriptionText />
      <TabContext value={currentTab}>
        <ButtonMenu></ButtonMenu>

        {SettingsTabs.map((value, index) => {
          return (
            <TabPanel
              className={classes.tabContainer}
              key={index}
              value={index.toString()}
              hidden={Number(currentTab) !== Number(index)}
            >
              {value.component(
                documentUrl,
                setDocumentUrl,
                file,
                setFile,
                documentPdf,
                setDocumentPdf,
                documentText,
                setDocumentText,
                roleType,
                handleAddSource,
                cardText,
                limit,
              )}
            </TabPanel>
          )
        })}
      </TabContext>
      <div style={{ flexGrow: 1 }}></div>
      <AddSourceButton
        canAddSource={canAddSource}
        handleUploadDocuments={handleUploadDocuments}
      />
    </div>
  )
}

export default withStyles(styles)(AddDocument)
