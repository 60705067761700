import React from 'react'

const Requirement = ({
  htmlFor,
  isvalid,
  validMessage,
  invalidMessage,
  classes,
}) => (
  <a className={!isvalid ? classes.invalid : classes.valid}>
    {!isvalid ? invalidMessage : validMessage}
  </a>
)

export default Requirement
