const style = theme => ({
  card: {
    maxWidth: 600,
    maxHeigh: 600,
    margin: '20px auto',
    alignItems: 'center',
  },
})

export default style
