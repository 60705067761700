const MEDIA_IMAGE_GET_BY_FILTER = 'MEDIA_IMAGE_GET_BY_FILTER'
const MEDIA_IMAGE_GET_BY_FILTER_SUCCESS = 'MEDIA_IMAGE_GET_BY_FILTER_SUCCESS'
const MEDIA_IMAGE_GET_BY_FILTER_ERROR = 'MEDIA_IMAGE_GET_BY_FILTER_ERROR'
const MEDIA_IMAGE_GET_BY_ID = 'MEDIA_IMAGE_GET_BY_ID'
const MEDIA_IMAGE_GET_BY_ID_SUCCESS = 'MEDIA_IMAGE_GET_BY_ID_SUCCESS'
const MEDIA_IMAGE_GET_BY_ID_ERROR = 'MEDIA_IMAGE_GET_BY_ID_ERROR'
const MEDIA_IMAGE_UPLOAD_MANY = 'MEDIA_IMAGE_UPLOAD_MANY'
const MEDIA_IMAGE_UPLOAD_MANY_SUCCESS = 'MEDIA_IMAGE_UPLOAD_MANY_SUCCESS'
const MEDIA_IMAGE_UPLOAD_MANY_ERROR = 'MEDIA_IMAGE_UPLOAD_MANY_ERROR'
const MEDIA_IMAGE_UPLOAD = 'MEDIA_IMAGE_UPLOAD'
const MEDIA_IMAGE_UPLOAD_SUCCESS = 'MEDIA_IMAGE_UPLOAD_SUCCESS'
const MEDIA_IMAGE_UPLOAD_ERROR = 'MEDIA_IMAGE_UPLOAD_ERROR'
const MEDIA_IMAGE_PATCH = 'MEDIA_IMAGE_PATCH'
const MEDIA_IMAGE_PATCH_SUCCESS = 'MEDIA_IMAGE_PATCH_SUCCESS'
const MEDIA_IMAGE_PATCH_ERROR = 'MEDIA_IMAGE_PATCH_ERROR'
const MEDIA_IMAGE_DELETE = 'MEDIA_IMAGE_DELETE'
const MEDIA_IMAGE_DELETE_SUCCESS = 'MEDIA_IMAGE_DELETE_SUCCESS'
const MEDIA_IMAGE_DELETE_ERROR = 'MEDIA_IMAGE_DELETE_ERROR'
const MEDIA_IMAGE_DELETE_LIST = 'MEDIA_IMAGE_DELETE_LIST'
const MEDIA_IMAGE_DELETE_LIST_SUCCESS = 'MEDIA_IMAGE_DELETE_LIST_SUCCESS'
const MEDIA_IMAGE_DELETE_LIST_ERROR = 'MEDIA_IMAGE_DELETE_LIST_ERROR'
const OPENAI_GENERATE_IMAGE = 'OPENAI_GENERATE_IMAGE'
const OPENAI_GENERATE_IMAGE_SUCCESS = 'OPENAI_GENERATE_IMAGE_SUCCESS'
const OPENAI_GENERATE_IMAGE_ERROR = 'OPENAI_GENERATE_IMAGE_ERROR'
const MEDIA_CARD_IMAGE_CREATION = 'MEDIA_CARD_IMAGE_CREATION'
const MEDIA_CARD_IMAGE_CREATION_SUCCESS = 'MEDIA_CARD_IMAGE_CREATION_SUCCESS'
const MEDIA_CARD_IMAGE_CREATION_ERROR = 'MEDIA_CARD_IMAGE_CREATION_ERROR'

export {
  MEDIA_IMAGE_GET_BY_FILTER,
  MEDIA_IMAGE_GET_BY_FILTER_SUCCESS,
  MEDIA_IMAGE_GET_BY_FILTER_ERROR,
  MEDIA_IMAGE_GET_BY_ID,
  MEDIA_IMAGE_GET_BY_ID_SUCCESS,
  MEDIA_IMAGE_GET_BY_ID_ERROR,
  MEDIA_IMAGE_UPLOAD_MANY,
  MEDIA_IMAGE_UPLOAD_MANY_SUCCESS,
  MEDIA_IMAGE_UPLOAD_MANY_ERROR,
  MEDIA_IMAGE_UPLOAD,
  MEDIA_IMAGE_UPLOAD_SUCCESS,
  MEDIA_IMAGE_UPLOAD_ERROR,
  MEDIA_IMAGE_PATCH,
  MEDIA_IMAGE_PATCH_SUCCESS,
  MEDIA_IMAGE_PATCH_ERROR,
  MEDIA_IMAGE_DELETE,
  MEDIA_IMAGE_DELETE_SUCCESS,
  MEDIA_IMAGE_DELETE_ERROR,
  MEDIA_IMAGE_DELETE_LIST,
  MEDIA_IMAGE_DELETE_LIST_SUCCESS,
  MEDIA_IMAGE_DELETE_LIST_ERROR,
  OPENAI_GENERATE_IMAGE,
  OPENAI_GENERATE_IMAGE_SUCCESS,
  OPENAI_GENERATE_IMAGE_ERROR,
  MEDIA_CARD_IMAGE_CREATION,
  MEDIA_CARD_IMAGE_CREATION_SUCCESS,
  MEDIA_CARD_IMAGE_CREATION_ERROR,
}
