const TEAM_LIST = 'TEAM_LIST'
const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS'
const TEAM_LIST_ERROR = 'TEAM_LIST_ERROR'

const TEAMS_USER_LIST = 'TEAMS_USER_LIST'
const TEAMS_USER_LIST_SUCCESS = 'TEAMS_USER_LIST_SUCCESS'
const TEAMS_USER_LIST_ERROR = 'TEAMS_USER_LIST_ERROR'

const ADD_TEAM = 'ADD_TEAM'
const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS'
const ADD_TEAM_ERROR = 'ADD_TEAM_ERROR'

const PATCH_TEAM = 'PATCH_TEAM'
const PATCH_TEAM_SUCCESS = 'PATCH_TEAM_SUCCESS'
const PATCH_TEAM_ERROR = 'PATCH_TEAM_ERROR'

const DELETE_TEAM = 'DELETE_TEAM'
const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS'
const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR'

const TEAMS_MEMBER_LIST = 'TEAMS_MEMBER_LIST'
const TEAMS_MEMBER_LIST_SUCCESS = 'TEAMS_MEMBER_LIST_SUCCESS'
const TEAMS_MEMBER_LIST_ERROR = 'TEAMS_MEMBER_LIST_ERROR'

const TEAMS_MEMBER_DELETE = 'TEAMS_MEMBER_DELETE'
const TEAMS_MEMBER_DELETE_SUCCESS = 'TEAMS_MEMBER_DELETE_SUCCESS'
const TEAMS_MEMBER_DELETE_ERROR = 'TEAMS_MEMBER_DELETE_ERROR'

const TEAMS_MEMBER_PATCH = 'TEAMS_MEMBER_PATCH'
const TEAMS_MEMBER_PATCH_SUCCESS = 'TEAMS_MEMBER_PATCH_SUCCESS'
const TEAMS_MEMBER_PATCH_ERROR = 'TEAMS_MEMBER_PATCH_ERROR'

const TEAMS_MEMBER_ADD = 'TEAMS_MEMBER_ADD'
const TEAMS_MEMBER_ADD_SUCCESS = 'TEAMS_MEMBER_ADD_SUCCESS'
const TEAMS_MEMBER_ADD_ERROR = 'TEAMS_MEMBER_ADD_ERROR'

const TEAMS_MEMBER_EXPORT = 'TEAMS_MEMBER_EXPORT'
const TEAMS_MEMBER_EXPORT_SUCCESS = 'TEAMS_MEMBER_EXPORT_SUCCESS'
const TEAMS_MEMBER_EXPORT_ERROR = 'TEAMS_MEMBER_EXPORT_ERROR'

const TEAMS_MEMBER_IMPORT = 'TEAMS_MEMBER_IMPORT'
const TEAMS_MEMBER_IMPORT_SUCCESS = 'TEAMS_MEMBER_IMPORT_SUCCESS'
const TEAMS_MEMBER_IMPORT_ERROR = 'TEAMS_MEMBER_IMPORT_ERROR'

const SEND_MAIL_TO_LEARNER = 'SEND_MAIL_TO_LEARNER'
const SEND_MAIL_TO_LEARNER_SUCCESS = 'SEND_MAIL_TO_LEARNER_SUCCESS'
const SEND_MAIL_TO_LEARNER_ERROR = 'SEND_MAIL_TO_LEARNER_ERROR'

export {
  TEAM_LIST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_ERROR,
  TEAMS_USER_LIST,
  TEAMS_USER_LIST_SUCCESS,
  TEAMS_USER_LIST_ERROR,
  ADD_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_ERROR,
  PATCH_TEAM,
  PATCH_TEAM_SUCCESS,
  PATCH_TEAM_ERROR,
  DELETE_TEAM,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  TEAMS_MEMBER_LIST,
  TEAMS_MEMBER_LIST_SUCCESS,
  TEAMS_MEMBER_LIST_ERROR,
  TEAMS_MEMBER_DELETE,
  TEAMS_MEMBER_DELETE_SUCCESS,
  TEAMS_MEMBER_DELETE_ERROR,
  TEAMS_MEMBER_PATCH,
  TEAMS_MEMBER_PATCH_SUCCESS,
  TEAMS_MEMBER_PATCH_ERROR,
  TEAMS_MEMBER_ADD,
  TEAMS_MEMBER_ADD_SUCCESS,
  TEAMS_MEMBER_ADD_ERROR,
  TEAMS_MEMBER_EXPORT,
  TEAMS_MEMBER_EXPORT_SUCCESS,
  TEAMS_MEMBER_EXPORT_ERROR,
  TEAMS_MEMBER_IMPORT,
  TEAMS_MEMBER_IMPORT_SUCCESS,
  TEAMS_MEMBER_IMPORT_ERROR,
  SEND_MAIL_TO_LEARNER,
  SEND_MAIL_TO_LEARNER_SUCCESS,
  SEND_MAIL_TO_LEARNER_ERROR,
}
