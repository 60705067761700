const style = theme => ({
  root: { width: 'auto' },
  itemWrapper: {
    padding: theme.spacing(1),
    background: '#eff2f6',
    marginRight: theme.spacing(1),
    maxWidth: 300,
  },
  hasLabel: {
    height: '100%',
  },
  formLabel: {
    fontWeight: 600,
  },
  formLabelContainer: {
    flexGrow: 1,
  },
  clearIcon: {
    fontSize: '1.2em',
  },
  hasContent: {
    color: '#333333',
  },
  disabled: {
    opacity: 0.4,
  },
  error: {
    color: '#f44336',
  },
})

export default style
