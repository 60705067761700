import { meService } from '../api/authentication'
import * as amplitude from '@amplitude/analytics-browser'
import HMACObj from 'hmac-obj'

import { sendConstantsService } from '../api/common'
import SYSTEM_DATA from '../data'
import translator from '../translate'

const systemMiddleware = api => next => async action => {
  if (
    api.getState().authentication.me == null &&
    !api.getState().authentication.sendMeOperation
  ) {
    api.getState().authentication.sendMeOperation = true

    const resMe = await meService()

    window.CONSTANTS = {
      TIME_ZONE: resMe.data.timeZone,
      DATE_FORMAT: resMe.data.dateFormat,
      USER_NAME: resMe.data.userName,
      NAME: resMe.data.name,
      SUR_NAME: resMe.data.surname == null ? '' : resMe.data.surname,
      COMPANY_NAME: resMe.data.companyName,
      COMPANY_LOGO: resMe.data.companyLogo,
      EMAIL: resMe.data.email,
      IS_ENOCTA: resMe.data.isEnocta,
      USER_ROLE_ID: resMe.data.userRoleId,
      CUSTOM_DOMAIN: resMe.data.lastCustomDomain,
    }

    // 3 ay geçmişse şifre güncellemeye zorlama
    /* if (
      resMe &&
      resMe.data &&
      resMe.data.passwordOutdated &&
      !resMe.data.isEnocta
    ) {
      window.location.href = '/renew-password'
    } */

    api.getState().authentication.me = resMe.data

    //amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, resMe.data.userName)
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('Plan_Type', resMe.data.userRoleString)
    amplitude.identify(identifyEvent)

    const hmacSHA256 = await HMACObj.new(
      'lrlXI0qq1dMOYjoYQnQH1MlFtUM1trBC0L2t_bgV',
      resMe.data.userName,
      'SHA-256',
    )

    {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'vp527f0g',
        name: resMe.data.name + ' ' + resMe.data.surname, // Full name
        email: resMe.data.userName, // Email address
        userRole: resMe.data.userRoleString,
        user_hash: hmacSHA256.hexdigest(),
      })
    }

    // const resConstants = await sendConstantsService()

    // resConstants.data.forEach(element => {
    //   for (let key in element) {
    //     SYSTEM_DATA[key] = []
    //     element[key].forEach(option => {
    //       option.label = translator._('constants.' + key + '.' + option.key)

    //       SYSTEM_DATA[key].push(option)
    //     })
    //   }
    // })

    return next(action)
  } else {
    return next(action)
  }
}

export default systemMiddleware
