import React from 'react'
import {
  Button,
  Typography,
  withStyles,
  Breadcrumbs,
  Link,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import styles from './styles'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import NamingModal from './team-add'

const TeamsHeader = props => {
  const { dispatch, classes } = props
  const [open, setOpen] = React.useState(false)
  const handleClick = event => {
    window.location.href = event
  }
  const openNamingModal = () => {
    setOpen(true)
  }

  return (
    <div className={classes.page__header}>
      <div className={classes.page__header__title}>
        <Typography fontSize={'24px'} fontWeight={700}>
          Groups
        </Typography>
      </div>

      <Button
        variant="contained"
        className={classes.page__header__button}
        onClick={() => openNamingModal()}
        startIcon={
          <Icon icon={'eva:plus-fill'} width={24} height={24} color={'#fff'} />
        }
      >
        New Group
      </Button>
      {open && (
        <NamingModal
          isModalOpen={open}
          handleCloseModal={() => setOpen(false)}
          dispatch={dispatch}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(TeamsHeader)
