import PropTypes from 'prop-types'
import { ListUseModeTypes } from '../../../../model/list'

const propTypes = {
  queryResult: PropTypes.object,
  childList: PropTypes.func,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  changeSelected: PropTypes.func.isRequired,
  changeExpanded: PropTypes.func.isRequired,
  selectedList: PropTypes.array.isRequired,
  showChildList: PropTypes.bool.isRequired,
  showSelect: PropTypes.bool.isRequired,
  usageMode: PropTypes.oneOf(ListUseModeTypes).isRequired,
}

export default propTypes
