const URL = {
  LIST_LEADERBOARD: 'Leaderboard/GetLeaderboardsByFilter',
  GET_LEADERBOARD: 'Leaderboard/GetLeaderboardById',
  ADD_LEADERBOARD: 'Leaderboard/AddLeaderboard',
  PATCH_LEADERBOARD: 'Leaderboard/PatchLeaderboard',
  DELETE_LEADERBOARD: 'Leaderboard/DeleteLeaderboard',
  DELETE_LEADERBOARD_LIST: 'Leaderboard/DeleteLeaderboardList',
  GET_LEADERBOARD_USER_PERFORMANCE: 'Leaderboard/GetLeaderboardUserPerformance',
  GET_LEADERBOARD_TEAM_PERFORMANCE: 'Leaderboard/GetLeaderboardTeamPerformance',
  GET_LEADERBOARD_USER_DETAIL: 'Leaderboard/GetLeaderboardUserDetail',
  GET_LEADERBOARD_TEAM_DETAIL: 'Leaderboard/GetLeaderboardDetailTeam',
  GET_LEADERBOARD_TEAM_DETAIL_USER:
    'Leaderboard/GetLeaderboarDetailTeamPerformanceOfUser',
  GET_LEADERBOARD_USER_QUESTION_STATS:
    'Leaderboard/GetLeaderboardUserQuestionStats',
  GET_LEADERBOARD_SUMMARY: 'Leaderboard/GetLeaderboardSummary',
}

export default URL
