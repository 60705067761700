import { fork, put, take, cancel } from 'redux-saga/effects'
import { SnackbarType } from '../model/snackbar/type'
import { processStartAction, processStartEnd } from '../reducers/process/action'
import { snackbarShowAction } from '../reducers/snackbar/action'
import { OPERATION_SUCCESS, OPERATION_FAIL } from '../reducers/operation/type'
import SnackbarMessage from '../model/snackbar'
import { guid } from '../utils/guid'
import { remove as removeCookie } from 'es-cookie'

/**
 *
 * @param {*} callMethod
 * @param {*} payload
 */
export default function* generalSaga(callMethod, payload, preloading) {
  try {
    if (preloading == false) {
      // Preloader Hide if method for patch
    } else {
      yield put(processStartAction())
    }

    const task = yield fork(callMethod, payload)

    const resultAction = yield take([OPERATION_SUCCESS, OPERATION_FAIL])

    if (resultAction.type === OPERATION_FAIL) {
      const { error } = resultAction

      const message = new SnackbarMessage(
        guid().uid(),
        SnackbarType.ERROR,
        null,
      )
      if (error.type === 'BadRequestError') {
        message.messageIds = ['message.error.server']

        const { data } = error.response
        const { errors } = data
        if (errors) {
          const codes = []

          Object.keys(errors).forEach(function (key, index) {
            codes.push(errors[index])
          })

          message.messageIds = codes
        }
        yield put(snackbarShowAction(message))
      } else if (error.type === 'ServerError') {
        const { data } = error.response
        const { operationCode } = data

        message.messageIds = [operationCode]
        yield put(snackbarShowAction(message))
      } else if (error.type === 'ForbiddenError') {
        message.messageIds = ['message.error.forbidden']
        yield put(snackbarShowAction(message))
      } else if (error.type === 'UnauthorizedError') {
        message.messageIds = ['message.error.unauthorized']

        //yield put(snackbarShowAction(message))
        removeCookie('token')
        window.location.href = '/'
      } else if (error.response.status === 406) {
        const { data } = error.response
        const { operationCode } = data

        message.messageIds = [operationCode]
        yield put(snackbarShowAction(message))
      } else if (error.type === 'UnexpectedError') {
        message.messageIds = ['message.error.unexpected']
        yield put(snackbarShowAction(message))
      } else if (error.type === 'CustomError') {
        const { data } = error.response
        const { messages } = data
        const codes = []

        Object.keys(messages).forEach(function (key, index) {
          const message = messages[key]

          message.forEach(data => {
            codes.push(data.code)
          })
        })
        message.messageIds = codes
        yield put(snackbarShowAction(message))
      } else if (error.type === 'NetworkError') {
        message.messageIds = ['message.error.network']

        yield put(snackbarShowAction(message))
      }

      yield cancel(task)
    }
  } catch (err) {
    // error code
  } finally {
    yield put(processStartEnd())
  }
}
