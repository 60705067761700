import {
  MEDIA_VIDEO_GET_BY_FILTER,
  MEDIA_VIDEO_GET_BY_FILTER_SUCCESS,
  MEDIA_VIDEO_GET_BY_FILTER_ERROR,
  MEDIA_VIDEO_GET_BY_ID,
  MEDIA_VIDEO_GET_BY_ID_SUCCESS,
  MEDIA_VIDEO_GET_BY_ID_ERROR,
  MEDIA_VIDEO_UPLOAD_MANY,
  MEDIA_VIDEO_UPLOAD_MANY_SUCCESS,
  MEDIA_VIDEO_UPLOAD_MANY_ERROR,
  MEDIA_VIDEO_UPLOAD,
  MEDIA_VIDEO_UPLOAD_SUCCESS,
  MEDIA_VIDEO_UPLOAD_ERROR,
  MEDIA_VIDEO_PATCH,
  MEDIA_VIDEO_PATCH_SUCCESS,
  MEDIA_VIDEO_PATCH_ERROR,
  MEDIA_VIDEO_DELETE,
  MEDIA_VIDEO_DELETE_SUCCESS,
  MEDIA_VIDEO_DELETE_ERROR,
  MEDIA_VIDEO_DELETE_LIST,
  MEDIA_VIDEO_DELETE_LIST_SUCCESS,
  MEDIA_VIDEO_DELETE_LIST_ERROR,
} from './type'

/**
 *
 * @param {*} query
 */
export const mediaVideoGetByFilterAction = query => {
  return {
    type: MEDIA_VIDEO_GET_BY_FILTER,
    payload: { query },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaVideoGetByFilterSuccess = response => {
  return {
    type: MEDIA_VIDEO_GET_BY_FILTER_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaVideoGetByFilterError = () => {
  return {
    type: MEDIA_VIDEO_GET_BY_FILTER_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const mediaVideoGetByIdAction = id => {
  return {
    type: MEDIA_VIDEO_GET_BY_ID,
    payload: { id },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaVideoGetByIdSuccess = response => {
  return {
    type: MEDIA_VIDEO_GET_BY_ID_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaVideoGetByIdError = () => {
  return {
    type: MEDIA_VIDEO_GET_BY_ID_ERROR,
  }
}

/**
 *
 * @param {*} files
 */
export const mediaVideoUploadManyAction = files => {
  return {
    type: MEDIA_VIDEO_UPLOAD_MANY,
    payload: { files },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaVideoUploadManySuccess = response => {
  return {
    type: MEDIA_VIDEO_UPLOAD_MANY_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaVideoUploadManyError = () => {
  return {
    type: MEDIA_VIDEO_UPLOAD_MANY_ERROR,
  }
}

/**
 *
 * @param {*} file
 * @param {*} parentFolderId
 * @param {*} callback
 */
export const mediaVideoUploadAction = (file, parentFolderId, callback) => {
  return {
    type: MEDIA_VIDEO_UPLOAD,
    payload: { file, parentFolderId, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaVideoUploadSuccess = response => {
  return {
    type: MEDIA_VIDEO_UPLOAD_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaVideoUploadError = () => {
  return {
    type: MEDIA_VIDEO_UPLOAD_ERROR,
  }
}

/**
 *
 * @param {*} content
 */
export const mediaVideoPatchAction = content => {
  return {
    type: MEDIA_VIDEO_PATCH,
    payload: { content },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaVideoPatchSuccess = response => {
  return {
    type: MEDIA_VIDEO_PATCH_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaVideoPatchError = () => {
  return {
    type: MEDIA_VIDEO_PATCH_ERROR,
  }
}

/**
 *
 * @param {*} file
 * @param {*} parentFolderId
 */
export const mediaVideoDeleteAction = (id, callback) => {
  return {
    type: MEDIA_VIDEO_DELETE,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaVideoDeleteSuccess = response => {
  return {
    type: MEDIA_VIDEO_DELETE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaVideoDeleteError = () => {
  return {
    type: MEDIA_VIDEO_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} ids
 */
export const mediaVideoDeleteListAction = ids => {
  return {
    type: MEDIA_VIDEO_DELETE_LIST,
    payload: { ids },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaVideoDeleteListSuccess = response => {
  return {
    type: MEDIA_VIDEO_DELETE_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaVideoDeleteListError = () => {
  return {
    type: MEDIA_VIDEO_DELETE_LIST_ERROR,
  }
}
