const style = theme => ({
  section: {
    width: '100%',
    height: 'calc(100vh - 136px)',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    //paddingRight: theme.spacing(1),
    //paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  hasNotFooter: {
    height: 'calc(100vh - 76px)',
  },
  inline: {
    height: 'auto',
    margin: 'auto',
    backgroundColor: 'transparent',
  },

  modal: {
    height: 'auto',
    margin: 'auto',
    backgroundColor: 'transparent',
  },
})

export default style
