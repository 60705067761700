import grey from '@material-ui/core/colors/grey'

const style = theme => ({
  root: {
    //height: 60,
    //paddingRight: theme.spacing(2),
    //paddingLeft: theme.spacing(2),
    //backgroundColor: '#1f2d3d',
  },
  title: {
    color: grey[700],
  },
})

export default style
