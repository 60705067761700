import React from 'react'
import { Typography, withStyles, Button } from '@material-ui/core'
import styles from '../styles'
import { Icon } from '@iconify/react'

const MailSent = props => {
  const { closeDrawer, classes } = props
  return (
    <>
      <div className={classes.mailSent__header}>
        <Icon icon={'material-symbols:mail-outline'} width={20} height={20} />
        <Typography fontSize={'16px'} fontWeight={600}>
          Send Magic Link by Email
        </Typography>
      </div>
      <div className={classes.mailSent__header__icon}>
        <img
          src={'/assets/done_img.png'}
          alt={'mail-sent'}
          width={100}
          height={100}
        />
        <Typography textAlign={'center'} fontSize="20px" fontWeight={600}>
          Your delivery <br />
          has been done!
        </Typography>
        <Typography
          textAlign={'center'}
          fontSize="14px"
          fontWeight={400}
          color="#637381"
        >
          We intend to employ your category name along with a concise,
          uninterrupted link tailored specifically for the group or individual
          user within the groups you've chosen.
        </Typography>
        <Button
          variant="contained"
          onClick={() => closeDrawer()}
          className={classes.mailSent__button}
        >
          <Typography fontSize={'12px'} fontWeight={600}>
            Take me to category
          </Typography>

          <Icon icon={'majesticons:open-line'} style={{ marginLeft: '4px' }} />
        </Button>
      </div>
    </>
  )
}

export default withStyles(styles)(MailSent)
