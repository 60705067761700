import { MESSAGE_BOX_SHOW, MESSAGE_BOX_HIDE } from './type'

/**
 *
 * @param {*} content
 */
export const messageBoxShowAction = content => {
  return {
    type: MESSAGE_BOX_SHOW,
    payload: { content },
  }
}

/**
 *
 */
export const messageBoxHideAction = () => {
  return {
    type: MESSAGE_BOX_HIDE,
  }
}
