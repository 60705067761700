/**
 *
 * @param {*} initialValue
 * @param {*} path
 * @param {*} value
 */
const WriteJSONPath = (initialValue, path, value) => {
  const pathArray = path.split('.')
  let key = null
  if (value == null && pathArray.length > 1) {
    pathArray.splice(pathArray.length - 1, 1)
    key = pathArray.splice(pathArray.length - 1, 1)[0]
  } else {
    key = pathArray.splice(pathArray.length - 1, 1)[0]
  }

  let targetObject = pathArray.reduce(
    /**
     *
     * @param accumulator
     * @param currentValue
     */
    (accumulator, currentValue) => {
      if (accumulator == null) {
        return null
      }

      if (currentValue.indexOf('[') !== -1) {
        const reg = /\[([0-9]+)\]/g
        const result = reg.exec(currentValue)
        const target = currentValue.split('[')[0]

        if (accumulator[target] == null) {
          accumulator[target] = []
        }

        accumulator = accumulator[target][result[1]]
      } else {
        if (accumulator[currentValue] == null) {
          accumulator[currentValue] = {}
        }

        accumulator = accumulator[currentValue]
      }

      return accumulator
    },
    initialValue,
  )

  if (key.indexOf('[') !== -1) {
    const reg = /\[([0-9]+)\]/g
    const result = reg.exec(key)
    const target = key.split('[')[0]

    targetObject[target][result[1]] = value
  } else {
    targetObject[key] = value
  }
}

export default WriteJSONPath
