import React from 'react'
import ImageListItem from '@material-ui/core/ImageListItem'

const withMediaLayout = MediaItem => {
  return class extends React.Component {
    /**
     *
     */
    render() {
      return (
        <ImageListItem key={this.props.id} style={{ margin: 2, maxHeight: 145 }}>
          <MediaItem {...this.props} />
        </ImageListItem>
      )
    }
  }
}

export default withMediaLayout
