import React, { useEffect, useState } from 'react'
import style from './style'
import propTypes from './prop'
import SearchIcon from '@material-ui/icons/Search'
import {
  withStyles,
  Paper,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core'
import translator from 'src/translate'

/**
 *
 * @param {*} props
 */
const SearchInput = props => {
  const { classes, searchTextExecute } = props
  const [searchText, setSearchText] = useState('')
  const [query, setQuery] = useState('')

  useEffect(() => {
    const timeOutId = setTimeout(() => setQuery(searchText), 1000)
    return () => clearTimeout(timeOutId)
  }, [searchText])

  useEffect(() => {
    searchTextExecute(query)
  }, [query])

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      searchTextExecute(query)
    }
  }

  const handleChange = event => {
    setSearchText(event.currentTarget.value)
  }

  return (
    <Paper className={classes.root}>
      <OutlinedInput
        value={searchText}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={translator._('general.searchDots')}
        className={classes.search}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </Paper>
  )
}

SearchInput.propTypes = propTypes

export default withStyles(style)(SearchInput)
