import React from 'react'
import classNames from 'classnames'
import style from './style'
import propTypes from './prop'
import { withStyles, CircularProgress, Grid } from '@material-ui/core'

const Preloader = ({ classes, ...props }) => {
  const { process } = props
  const { working } = process

  if (!working) {
    return null
  }
  return (
    <Grid
      className={classNames(classes.root, !working && classes.hide)}
      container
      alignContent="center"
      alignItems="center"
    >
      <Grid item xs={12} className={classes.progressWrapper}>
        <CircularProgress color="primary" />
      </Grid>
    </Grid>
  )
}

Preloader.propTypes = propTypes

export default withStyles(style)(Preloader)
