import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import MomentUtils from '@date-io/moment'
import moment from 'moment-timezone'
import { withStyles, Box } from '@material-ui/core'
import ItemLayout from '../../renderer/item-layout'
import { PatchData } from '../../../../model/patch'
import isEmpty from '../../../../utils/is-empty'
import translator from '../../../../translate/index'

import { get as getCookie } from 'es-cookie'

const FormItemDate = props => {
  //moment.tz.setDefault(window.CONSTANTS.TIME_ZONE)
  moment.locale(getCookie('language'))

  const {
    classes,
    value,
    dateFormat,
    timeFormat,
    dateTimeFormat,
    minDate,
    maxDate,
    disablePast,
    placeholder,
    updateEntityKeyValue,
    hidden,
    initialFocusedDate,
    minutesStep,
    disabled,
    disabledEdit,
    stateMode,
    label,
  } = props

  const [stateValue, setStateValue] = useState(value)
  //const [closed, setClosed] = useState(false)
  /**
   *
   * @param event
   */
  const onChangeDateHandler = date => {
    date = new Date(date)
    setStateValue(date)

    updateEntityKeyValue(date, props, PatchData.OPERATION_REPLACE)
  }

  const onChangeTimeHandler = date => {
    date = new Date(date)
    setStateValue(date)

    //updateEntityKeyValue(date, props, PatchData.OPERATION_REPLACE)
  }

  const onClose = date => {
    //closed = true
    /*  setStateValue(null)

        updateEntityKeyValue(null, props, PatchData.OPERATION_REPLACE) */
  }

  /**
   *
   * @param event
   */
  const onAccept = date => {
    date = new Date(date)

    updateEntityKeyValue(date, props, PatchData.OPERATION_REPLACE)
  }

  if (hidden) {
    return ''
  }
  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <React.Fragment>
         
          
        </React.Fragment>
      }
    />
  )
}

FormItemDate.propTypes = propTypes

export default withStyles(style)(FormItemDate)
