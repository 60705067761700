import { put, call, take, fork } from 'redux-saga/effects'
import { shopEditService } from '../../api/shop'
import { shopEditSuccess } from '../../reducers/shop/action'
import { SHOP_EDIT } from '../../reducers/shop/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* shopEditFlow(payload) {
  try {
    const { query } = payload
    const response = yield call(shopEditService, query)

    yield put(shopEditSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* shopEditWatcher() {
  while (true) {
    const action = yield take(SHOP_EDIT)
    const { payload } = action

    yield fork(generalSaga, shopEditFlow, payload)
  }
}
