import translator from '../../../translate'
import { ListColumnType } from '../../../model/list'
import { ConverterDateToShortFormat } from '../../../utils/converter'
/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 */
const columns = [
  {
    attribute: 'date',
    label: translator._('couseTimeSeries.date'),
    type: ListColumnType.DATE,
    sortable: false,
    converter: { input: ConverterDateToShortFormat },
    suffix: null,
    actions: null,
  },

  {
    attribute: 'signUps',
    label: translator._('couseTimeSeries.signUps'),
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'createdContentItems',
    label: translator._('couseTimeSeries.createdContentItems'),
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'repliedContentItems',
    label: translator._('couseTimeSeries.repliedContentItems'),
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'courseViews',
    label: translator._('couseTimeSeries.courseViews'),
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'uniqueCourseViews',
    label: translator._('couseTimeSeries.uniqueCourseViews'),
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'engagedUsers',
    label: translator._('couseTimeSeries.engagedUsers'),
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'uniqueEngageUsers',
    label: translator._('couseTimeSeries.uniqueEngageUsers'),
    type: ListColumnType.STRING,
    sortable: false,
    converter: null,
    suffix: null,
    actions: null,
  },
]

export default columns
