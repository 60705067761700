import React, { Component } from 'react'
import parse from 'html-react-parser'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import { signUpAction } from '../../reducers/sign-up/action.js'
import { connect } from 'react-redux'
import propTypes from './prop'
import { withSnackbar } from 'notistack'
import translator from '../../translate'
import { snackbarDispose } from '../../reducers/snackbar/action'
import moment from 'moment-timezone'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import SetPassword from './password-field'
import { track, init } from '@amplitude/analytics-browser'
import {
  Button,
  CssBaseline,
  Paper,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { get as getCookie } from 'es-cookie'
class SignUp extends Component {
  state = {
    termsOfService: false,
    privacyPolicy: false,
    primaryRole: '',
    primaryInterest: '',
    showPassword: false,
    name: '',
    surName: '',
    shopName: '',
    password: '',
    disabled: true,
    ReceivesEmail: false,
  }

  /**
   *
   */
  onHandleSignUp = event => {
    event.preventDefault()
    const name = event.target.name.value
    const surName = event.target.surName.value
    const shopName = event.target.shopName.value
    const password = this.state.password
    //const purpose = event.target.purpose.value
    const emailConfirmationID =
      this.state.confirmationId != undefined ||
      this.state.confirmationId != null
        ? this.state.confirmationId
        : getCookie('confirmCode')
    const receivesEmail = this.state.ReceivesEmail
    /*     const primaryInterest = this.state.primaryInterest
    const marketingRole = this.state.primaryRole */
    const timeZone = moment.tz.guess()
    {
      /*if (getCookie('email')) {
      init(process.env.REACT_APP_AMPLITUDE_KEY, getCookie('email'))
      track('signed_up')
    }*/
    }
    const data = {
      name,
      surName,
      shopName,
      password,
      emailConfirmationID,
      receivesEmail,
      /*primaryInterest,
      marketingRole, */
      timeZone,
      //purpose,
    }
    this.props.dispatch(signUpAction(data))
  }

  /**
   *
   */
  setReceivesEmail = e => {
    this.setState({
      ReceivesEmail: !this.state.ReceivesEmail,
    })
  }
  /**
   *
   */
  setTermsOfService = e => {
    this.setState({
      termsOfService: !this.state.termsOfService,
    })
  }
  /**
   *
   */
  setPrivacyPolicy = e => {
    this.setState({
      privacyPolicy: !this.state.privacyPolicy,
    })
  }
  handleChangeRole = e => {
    this.setState({
      primaryRole: e.target.value,
    })
  }

  handleChangeInterest = e => {
    this.setState({
      primaryInterest: e.target.value,
    })
  }

  handleChangeShopName = shopName => {
    this.setState({
      shopName: shopName,
    })
  }

  handleChangeName = name => {
    this.setState({
      name: name,
    })
  }

  handleChangeSurName = surName => {
    this.setState({
      surName: surName,
    })
  }

  handleChangePassword = password => {
    this.setState({
      password: password,
    })
  }
  handleChangeDisable = disabled => {
    this.setState({
      disabled: disabled,
    })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  /**
   *
   */
  render() {
    const { classes } = this.props
    const {
      name,
      surName,
      shopName,
      termsOfService,
      privacyPolicy,
      password,
      disabled,
    } = this.state

    let signUpButtonDisable = true
    if (
      shopName !== '' &&
      name !== '' &&
      surName !== '' &&
      password !== '' &&
      termsOfService == true &&
      privacyPolicy == true &&
      disabled == false
    ) {
      signUpButtonDisable = false
    } else {
      signUpButtonDisable = true
    }

    return (
      <Grid container component="main" className={classes.root}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img src="/assets/qc-logo.svg" height="30" alt="logo" />
            <Box m={2}></Box>
            <Typography component="h1" variant="h5">
              {translator._('signUp.signUp')}
            </Typography>
            <form className={classes.form} onSubmit={this.onHandleSignUp}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="shopName"
                    label={translator._('signUp.companyName')}
                    name="shopName"
                    onChange={value => {
                      this.handleChangeShopName(value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="name"
                    required
                    fullWidth
                    id="name"
                    label={translator._('signUp.firstName')}
                    autoFocus
                    onChange={value => {
                      this.handleChangeName(value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="surName"
                    label={translator._('signUp.lastName')}
                    name="surName"
                    onChange={value => {
                      this.handleChangeSurName(value)
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SetPassword
                    password={password}
                    handleChangePassword={this.handleChangePassword}
                    disabled={disabled}
                    handleChangeDisable={this.handleChangeDisable}
                  />
                </Grid>

                {/*  <Grid item xs={12}>
                  <FormControl
                    variant="filled"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="filled-interest">
                      {translator._('signUp.primaryInterestQuestion')}
                    </InputLabel>
                    <Select
                      value={this.state.primaryInterest}
                      onChange={this.handleChangeInterest}
                      input={
                        <FilledInput name="interest" id="filled-interest" />
                      }
                    >
                      <MenuItem value={1}>
                        {translator._('signUp.primaryInterest1')}
                      </MenuItem>
                      <MenuItem value={2}>
                        {translator._('signUp.primaryInterest2')}
                      </MenuItem>
                      <MenuItem value={3}>
                        {translator._('signUp.primaryInterest3')}
                      </MenuItem>
                      <MenuItem value={4}>
                        {translator._('signUp.primaryInterest4')}
                      </MenuItem>
                      <MenuItem value={5}>
                        {translator._('signUp.primaryInterest5')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    name="purpose"
                    placeholder={translator._('signUp.purpose')}
                    type={'text'}
                    id="purpose"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    variant="filled"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="filled-role">
                      {translator._('signUp.marketingRoleQuestion')}
                    </InputLabel>
                    <Select
                      value={this.state.primaryRole}
                      onChange={this.handleChangeRole}
                      required
                      input={
                        <FilledInput name="role" id="filled-role" required />
                      }
                    >
                      <MenuItem value={1}>
                        {translator._('signUp.marketingRole1')}
                      </MenuItem>
                      <MenuItem value={2}>
                        {translator._('signUp.marketingRole2')}
                      </MenuItem>
                      <MenuItem value={3}>
                        {translator._('signUp.marketingRole3')}
                      </MenuItem>
                      <MenuItem value={4}>
                        {translator._('signUp.marketingRole4')}
                      </MenuItem>
                      <MenuItem value={5}>
                        {translator._('signUp.marketingRole5')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={this.state.ReceivesEmail}
                        color="primary"
                        name="ReceivesEmail"
                        id="ReceivesEmail"
                        onChange={this.setReceivesEmail}
                        checked={this.state.ReceivesEmail}
                      />
                    }
                    label={translator._('signUp.iWantToReceiveMarketing')}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '9px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={this.state.termsOfService}
                        color="primary"
                        name="ReceivesEmail"
                        id="ReceivesEmail"
                        onChange={this.setTermsOfService}
                        checked={this.state.termsOfService}
                      />
                    }
                    label={parse(translator._('signUp.termsOfUse'))}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '9px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={this.state.privacyPolicy}
                        color="primary"
                        name="ReceivesEmail"
                        id="ReceivesEmail"
                        onChange={this.setPrivacyPolicy}
                        checked={this.state.privacyPolicy}
                      />
                    }
                    label={parse(translator._('signUp.privacyPolicy'))}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                disabled={signUpButtonDisable}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {translator._('signUp.signUp')}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Box m={2}>
                    <Link href="/login" variant="body2">
                      {translator._('signUp.alreayHaveAnAccount')}
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
        <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2200343&vkey=f0d1ebbfbb1b0efe0b4949da260fe34b" />
      </Grid>
    )
  }

  /**
   *
   */
  componentWillMount() {
    const { match } = this.props
    var id = match.params.id
    this.setState({
      confirmationId: id,
    })
  }

  /**
   *
   */
  componentDidUpdate() {
    const { snackbar, enqueueSnackbar, dispatch } = this.props
    if (snackbar.messages) {
      snackbar.messages.forEach(message => {
        message.messageIds.forEach(messageId => {
          enqueueSnackbar(translator._(messageId), {
            preventDuplicate: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
        dispatch(snackbarDispose(message.id))
      })
    }
  }
}

SignUp.propTypes = propTypes

const mapStateToProps = response => ({
  snackbar: response.snackbar,
})

export default connect(mapStateToProps)(
  withStyles(styles)(withSnackbar(SignUp)),
)
