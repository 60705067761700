const style = theme => ({
  container: {
    minHeight: 52,
    marginBottom: theme.spacing(3),
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  gridLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePhoto: {
    height: 48,
    minWidth: 48,
    width: 48,
    borderRadius: 48,
    backgroundColor: theme.palette.primary.light,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  infoGroup: {
    width: '100%',
    marginLeft: theme.spacing(2),
  },
  infoTopLine: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  teamButtonHolder: {
    marginTop: 'auto',
    marginBottom: 'auto',
    alignItems: 'center',
  },
  teamButton: {
    height: 22,
    width: 48,
    borderRadius: 6,
  },
  iconGroup: {
    float: 'right',
    marginTop: -40,
    marginRight: -50,
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
})

export default style
