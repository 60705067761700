import PropTypes from 'prop-types'
import { FormItemOptionAttributeName } from '../../../../../form/props/form-item/default'

const propTypes = {
  value: PropTypes.any,
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  nodeOperation: PropTypes.func.isRequired,
  leafNodeControl: PropTypes.func.isRequired,
  onSelectNode: PropTypes.func.isRequired,
  attribute: PropTypes.string.isRequired,
  optionAttributeName: PropTypes.shape(FormItemOptionAttributeName).isRequired,
}

export default propTypes
