import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import propTypes from './prop'
import translator from '../../translate'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CssBaseline, Typography, Grid, Box } from '@material-ui/core'

class IntegrationDesktop extends Component {
  /**
   *
   */
  render() {
    const { classes } = this.props
    /* return (
            <CenterBox>
                <Grid container justifyContent="center">
                    <Grid item xs={4} style={{ marginTop: 30 }}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Yönlendirme yapılamadıysa lütfen linke
                                tıklayınız...
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                href={localStorage.getItem('REDIRECT_URL')}
                                target="_blank"
                                color="primary"
                                variant="contained"
                            >
                                Devam Et
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </CenterBox>
        ) */
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} md={12} className={classes.image}></Grid>

        <div className={classes.paper}>
          <Typography variant="h4">
            <b>{translator._('integration.aLittleInfoTitle')}</b>
          </Typography>
          <Box mt={4} />

          <Typography variant="subtitle1">
            {translator._('integration.aLittleInfoTextPart1')}
          </Typography>

          <br />
          <Typography variant="subtitle1">
            {translator._('integration.aLittleInfoTextPart3')}
          </Typography>
        </div>
      </Grid>
    )
  }
}

IntegrationDesktop.propTypes = propTypes

const mapStateToProps = response => ({
  componentBox: response.componentBox,
})
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(IntegrationDesktop)),
)
