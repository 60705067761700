const findValueOfItems = (id, type, containers) => {
  if (type === 'container') {
    // Directly return the container if it's found
    return containers.find(container => container.id === id)
  } else if (type === 'item') {
    // Loop through each container to find the item
    for (const container of containers) {
      const item = container.courses.find(x => x.id == id)

      if (item) {
        // Return the found item, not the container

        return container
      }
    }
  }
  return null // Return null if nothing is found
}

export default findValueOfItems
