import React from 'react'
import style from './style'
import propTypes from './prop'
import Form from '../../../form'
import { withStyles } from '@material-ui/core'

import {
  FormUpdateMethodType,
  FormUseModeType,
  FormStateModeType,
  FormViewType,
} from '../../../../form/props'

/**
 *
 * @param {*} props
 */
const SearchDetail = props => {
  const { filters, query, onUpdate, dispatch } = props

  /**
   *
   * @param {*} content
   * @param {*} formController
   */
  const onPatchFilter = patchData => {
    let data = patchData
    onUpdate(data)
  }

  return (
    <Form
      dispatch={dispatch}
      entity={query}
      form={filters}
      useMode={FormUseModeType.EDIT}
      updateMethod={FormUpdateMethodType.PATCH}
      stateMode={FormStateModeType.UPDATE}
      viewMode={FormViewType.VIEW_INLINE}
      hasFooter={false}
      onPatch={onPatchFilter}
    />
  )
}

SearchDetail.propTypes = propTypes

export default withStyles(style)(SearchDetail)
