import { put, call, take, fork } from 'redux-saga/effects';
import { generateSuggestionWithCourseDocumentService } from '../../api/assignment';
//import { questionPatchSuccess } from '../../reducers/question/action'
import { generateSuggestionWithCourseDocumentSuccess } from '../../reducers/assignment/action';
import { GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT } from '../../reducers/assignment/type';
import generalSaga from '../general';
import { operationSuccess, operationFail } from '../../reducers/operation/action';

/**
 *
 * @param {*} payload
 */
function* generateSuggestionWithCourseDocumentFlow(payload) {
  try {
    const { entity, callback } = payload;
    const response = yield call(generateSuggestionWithCourseDocumentService, entity);

    yield put(operationSuccess());
    yield put(generateSuggestionWithCourseDocumentSuccess(response.data));
    callback(response.data);
  } catch (error) {
    yield put(operationFail(error));
  }
}

/**
 *
 */
export default function* generateSuggestionWithCourseDocumentWatcher() {
  while (true) {
    const action = yield take(GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT);
    const { payload } = action;

    yield fork(generalSaga, generateSuggestionWithCourseDocumentFlow, payload);
  }
}
