import React from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles } from '@material-ui/core'
import Link from '../../link'

/**
 *
 * @param {*} props
 */
const QuestionInform = props => {
  const { dispatch, entity } = props

  return <Link dispatch={dispatch} entity={entity} location={1} />
}

QuestionInform.propTypes = propTypes

export default withStyles(styles)(QuestionInform)
