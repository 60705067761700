const URL = {
  ASSIGNMENT_LIST: 'Course/GetByFilter',
  GET_ASSIGNMENT: 'Course/GetById',
  ADD_ASSIGNMENT: 'Course/Create',
  EDIT_ASSIGNMENT: 'Course/Edit',
  DELETE_ASSIGNMENT: 'Course/Delete',
  PATCH_ASSIGNMENT: 'Course/Patch',
  REMOVE_QUESTION_ASSIGNMENT: 'Course/RemoveQuestionsFromCourse',
  ADD_BULK_QUESTION_ASSIGNMENT: 'Course/CreateOpenAICourse',
  OPENAI_SEND_REQUEST: 'OpenAI/SendRequest',
  OPENAI_SEND_CARD_REQUEST: 'OpenAI/SendCardRequest',
  OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST:
    'OpenAI/SendCourseCreationGetTitleRequest',
  OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST:
    'OpenAI/SendCourseCreationGetOutlineRequest',
  OPENAI_SEND_COURSE_CREATION_REQUEST: 'OpenAI/SendCourseCreationRequest',
  CLONE_ASSIGNMENT: 'Course/Clone',
  CARD_PATCH_LINK: 'Card/PatchLink',
  PATCH_COURSE_SETTING: 'Course/PatchSetting',
  CREATE_COURSE_SETTING: 'Course/CreateSetting',
  REMOVE_QUESTION_SETTING: 'Course/RemoveSetting',
  CLONE_TEMPLATE_COURSE: 'Course/CloneTemplateCourse',
  CREATE_PROMPT_FROM_CARD: 'OpenAI/SendCreatePromptFromCardContentRequest',
  GET_COURSE_ANALYTICS: 'Report/GetCourseAnalyticsById',

  ADD_COURSE_DOCUMENT: 'Course/AddCourseDocument',
  GET_COURSE_DOCUMENT_BY_ID: 'Course/GetCourseDocumentById',
  GENERATE_QUIZ_WITH_COURSE_DOCUMENT: 'Course/GenerateQuizWithCourseDocument',
  DELETE_COURSE_DOCUMENT: 'Course/DeleteDocument',
  GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT:
    'Course/GenerateSuggestionWithCourseDocument',
  GENERATE_INFORMATION_WITH_COURSE_DOCUMENT:
    'Course/GenerateInformationWithCourseDocument',
  EXPORT_COURSE_EXCEL: 'Course/ExportCourseExcel',
  IMPORT_COURSE_EXCEL: 'Course/ImportCourseExcel',
  IMPORT_COURSE_TXT: 'Course/ImportCourseTxt',
  EXPORT_COURSE_TXT: 'Course/ExportCourseTxt',
  EXPORT_COURSE_TO_SCORM: 'course/{id}/export-to-scorm',
}

export default URL
