import { put, call, take, fork } from 'redux-saga/effects'
import { signUpAppsumoService } from '../../api/sign-up'
import { appSumoSignUpSuccess } from '../../reducers/sign-up/action.js'
import { APPSUMO_SIGN_UP } from '../../reducers/sign-up/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* appSumoSignUpSagaFlow(payload) {
  try {
    const { data } = payload
    const response = yield call(signUpAppsumoService, data)

    yield put(appSumoSignUpSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* appSumosignUpSagaWatcher() {
  while (true) {
    const action = yield take(APPSUMO_SIGN_UP)
    const { payload } = action

    yield fork(generalSaga, appSumoSignUpSagaFlow, payload)
  }
}
