import React from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import { PatchData } from '../../../../model/patch'
import { withStyles, TextField } from '@material-ui/core'

const FormItemFileUpload = props => {
  const { attribute, required, disabled, updateEntityKeyValue } = props

  /**
   *
   * @param {*} event
   */
  const onChangeHandler = event => {
    const { currentTarget } = event
    const { files } = currentTarget

    updateEntityKeyValue(files[0], props, PatchData.OPERATION_REPLACE)
  }

  return (
    <ItemLayout
      hasContent={true}
      formItem={props}
      element={
        <TextField
          type="file"
          fullWidth={true}
          required={required}
          disabled={disabled}
          id={attribute}
          onChange={onChangeHandler}
        />
      }
    />
  )
}

FormItemFileUpload.propTypes = propTypes

export default withStyles(style)(FormItemFileUpload)
