import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import translator from 'src/translate'
import { withSnackbar } from 'notistack'
import { get as getCookie } from 'es-cookie'
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Box,
} from '@material-ui/core'
import { remove as removeCookie } from 'es-cookie'

import Requirements from './requirements'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

class SetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideFormArea: false,
      name: '',
      long: false,
      number: false,
      lowercase: false,
      uppercase: false,
      special: false,
      password: '',
      showPassword: false,
      disabled: true,
      openAlert: false,
    }
  }
  handleLogoutOpenPopup = e => {
    removeCookie('token')
    window.location.href = '/'
  }

  handleChange = prop => event => {
    let regexForLong = new RegExp('(?=.{8,})')

    let long = regexForLong.test(event.target.value)

    let regexForNumber = new RegExp('(?=.*[0-9])')

    let number = regexForNumber.test(event.target.value)

    let regexForLowercase = new RegExp('(?=.*[a-z])')

    let lowercase = regexForLowercase.test(event.target.value)

    let regexForUppercase = new RegExp('(?=.*[A-Z])')

    let uppercase = regexForUppercase.test(event.target.value)

    let regexForSpecial = new RegExp('(?=.*[!@#$%^&*])')

    let special = regexForSpecial.test(event.target.value)
    this.props.handleChangePassword(event.target.value)
    this.props.handleChangeDisable(
      !(long && number && lowercase && uppercase && special),
    )

    this.setState({
      long,
      number,
      lowercase,
      uppercase,
      special,
      disabled: !(long && number && lowercase && uppercase && special),
    })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleClose = () => {
    this.setState({ openAlert: false })
  }

  /**
   *
   */
  render() {
    const { classes, password, disabled } = this.props
    const {
      hideFormArea,
      long,
      number,
      lowercase,
      uppercase,
      special,
      showPassword,
    } = this.state
    translator.changeLanguage(getCookie('language'))
    return (
      <div className={classes.div}>
        <Box className={classes.card}>
          {!hideFormArea && (
            <div className={classes.form}>
              <FormControl
                margin="normal"
                required
                fullWidth
                className={classes.input}
              >
                <Input
                  id="password"
                  placeholder="Password"
                  inputProps={{
                    'aria-label': 'description',
                  }}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={this.handleChange('password')}
                  label={translator._('setPassword.password')}
                  variant="outlined"
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.handleClickShowPassword()
                        }}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Requirements
                long={long}
                number={number}
                uppercase={uppercase}
                lowercase={lowercase}
                special={special}
                classes={classes}
              />
            </div>
          )}
        </Box>
      </div>
    )
  }
}

export default withStyles(styles)(withSnackbar(SetPassword))
