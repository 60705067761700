const styles = theme => ({
  grid: {
    position: 'relative',
    width: '100%',
    maxWidth: 610,
    flexBasis: 'unset',
  },
  childGrid: {
    marginBottom: theme.spacing(1.5),
    background: 'url(/assets/dummy-image-background.jpg)',
    height: 200,
    flexBasis: 'unset',
    justifyContent: 'center',
    alignItems: 'center',
  },

  button: {
    borderColor: '#1890FF',
    color: '#1890FF',
    textTransform: 'none',
  },
  linkBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: '75%',
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  addBox: {
    margin: '0 auto',
    textAlign: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  gridContainer: {
    border: '1px dashed #dadada',
  },
})

export default styles
