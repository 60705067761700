import { put, call, take, fork } from 'redux-saga/effects'
import { userListService } from '../../api/user'
import { userListSuccess } from '../../reducers/user/action'
import { USER_LIST } from '../../reducers/user/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* userListFlow(payload) {
  try {
    const { query } = payload
    const response = yield call(userListService, query)

    if (
      response.data.results.length == 0 &&
      query.FilterText == '' &&
      query.Filters == undefined
    ) {
      response.data.results = null
    }
    yield put(userListSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* userListWatcher() {
  while (true) {
    const action = yield take(USER_LIST)
    const { payload } = action

    yield fork(generalSaga, userListFlow, payload)
  }
}
