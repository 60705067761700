import React, { useState } from 'react'
import translator from 'src/translate'

import {
  Button,
  Divider,
  Modal,
  TextField,
  withStyles,
  Typography,
} from '@material-ui/core'
import { addTeamAction, patchTeamAction } from 'src/reducers/teams/action'

import styles from './styles'
import { PatchContent, PatchData } from 'src/model/patch'

const TeamNamingModal = props => {
  const { isModalOpen, handleCloseModal, dispatch, teamInfo, classes } = props
  const [teamTitle, setTeamTitle] = useState(teamInfo ? teamInfo.teamName : '')

  const handleTextFieldChange = e => {
    setTeamTitle(e.target.value)
  }

  const handleAddTeam = () => {
    dispatch(addTeamAction({ name: teamTitle }, response => {}))
    handleCloseModal()
  }
  const handlePatchTeam = () => {
    dispatch(
      patchTeamAction(
        new PatchData(
          new PatchContent(teamTitle, 'name', PatchData.OPERATION_REPLACE),
          teamInfo.teamId,
        ),
        () => {
          handleCloseModal()
        },
      ),
    )
  }
  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleCloseModal()}
      className={classes.namingModal}
    >
      <div className={classes.namingModal__Content}>
        <Typography className={classes.namingModal__Title}>
          Create New Group
        </Typography>
        <TextField
          label="Group Name"
          placeholder="Group Name"
          value={teamTitle}
          onChange={e => handleTextFieldChange(e)}
          defaultValue={teamInfo ? teamInfo.teamName : ''}
        />
        <div className={classes.namingModal__ButtonGroup}>
          <Button variant="outlined" color="inherit" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.namingModal__SaveButton}
            onClick={() => (teamInfo ? handlePatchTeam() : handleAddTeam())}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withStyles(styles)(TeamNamingModal)
