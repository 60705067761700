/*eslint-disable */
import { SnackbarType } from './type'

export default class SnackbarMessage {
  /**
   *
   * @param {SnackbarType} type
   * @param {string} id
   * @param {Array} messageIds
   */
  constructor(id, type, messageIds) {
    this._type = type
    this._id = id
    this._messageIds = messageIds
  }

  /**
   *
   */
  get type() {
    return this._type
  }

  /**
   *
   */
  set type(value) {
    this._type = value
  }

  /**
   *
   */
  get id() {
    return this._id
  }

  /**
   *
   */
  set id(value) {
    this._id = value
  }

  /**
   *
   */
  get messageIds() {
    return this._messageIds
  }

  /**
   *
   */
  set messageIds(value) {
    this._messageIds = value
  }

  /**
   *
   */
  toJSON() {
    return {
      id: this._id,
      type: this._type,
      messageIds: this._messageIds,
    }
  }
}
/*eslint-enabled */
