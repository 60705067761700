import { put, call, take, fork } from 'redux-saga/effects'
import { questionSettingCreateService } from '../../api/question'
//import { questionPatchSuccess } from '../../reducers/question/action'
import { questionSettingCreateSuccess } from '../../reducers/assignment/action'
import { QUESTION_SETTING_CREATE } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionSettingsCreateFlow(payload) {
  try {
    const { content, questionId, callback } = payload

    const response = yield call(questionSettingCreateService, content)
    yield put(questionSettingCreateSuccess(response.data, questionId))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionSettingsCreateWatcher() {
  while (true) {
    const action = yield take(QUESTION_SETTING_CREATE)
    const { payload } = action
    
    yield fork(generalSaga, questionSettingsCreateFlow, payload, false)
  }
}
