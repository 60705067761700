import {
  CREATE_COURSE_COLLECTION,
  CREATE_COURSE_COLLECTION_SUCCESS,
  CREATE_COURSE_COLLECTION_ERROR,
  DELETE_COURSE_COLLECTION,
  DELETE_COURSE_COLLECTION_SUCCESS,
  DELETE_COURSE_COLLECTION_ERROR,
  PATCH_COURSE_COLLECTION,
  PATCH_COURSE_COLLECTION_SUCCESS,
  PATCH_COURSE_COLLECTION_ERROR,
  LIST_COURSE_COLLECTION,
  LIST_COURSE_COLLECTION_SUCCESS,
  LIST_COURSE_COLLECTION_ERROR,
  UPDATE_COURSE_COLLECTION,
  UPDATE_COURSE_COLLECTION_SUCCESS,
  UPDATE_COURSE_COLLECTION_ERROR,
  EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX,
  EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_SUCCESS,
  EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_ERROR,
  ASSIGNMENT_CLONE,
  ASSIGNMENT_CLONE_SUCCESS,
  ASSIGNMENT_CLONE_ERROR,
  ASSIGNMENT_DELETE,
  ASSIGNMENT_DELETE_SUCCESS,
  ASSIGNMENT_DELETE_ERROR,
  COLLECTION_COURSE_PATCH,
  COLLECTION_COURSE_PATCH_SUCCESS,
  COLLECTION_COURSE_PATCH_ERROR,
  GET_COLLECTION_SETTINGS,
  GET_COLLECTION_SETTINGS_SUCCESS,
  GET_COLLECTION_SETTINGS_ERROR,
  PATCH_COLLECTION_SETTINGS,
  PATCH_COLLECTION_SETTINGS_SUCCESS,
  PATCH_COLLECTION_SETTINGS_ERROR,
} from './type'

/**
 *
 * @param {*} collectionName
 * @param {*} callback
 */
export const createCourseCollectionAction = (collectionName, callback) => {
  return {
    type: CREATE_COURSE_COLLECTION,
    payload: { collectionName, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const createCourseCollectionSuccess = response => {
  return {
    type: CREATE_COURSE_COLLECTION_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const createCourseCollectionError = () => {
  return {
    type: CREATE_COURSE_COLLECTION_ERROR,
  }
}

/**
 *
 * @param {*} id
 *
 */
export const deleteCourseCollectionAction = (id, callback) => {
  return {
    type: DELETE_COURSE_COLLECTION,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const deleteCourseCollectionSuccess = response => {
  return {
    type: DELETE_COURSE_COLLECTION_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const deleteCourseCollectionError = () => {
  return {
    type: DELETE_COURSE_COLLECTION_ERROR,
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */
export const patchCourseCollectionAction = (content, callback) => {
  return {
    type: PATCH_COURSE_COLLECTION,
    payload: { content, callback },
  }
}

/**
 *
 * @param {*} content
 */
export const patchCourseCollectionSuccess = content => {
  return {
    type: PATCH_COURSE_COLLECTION_SUCCESS,
    payload: { content },
  }
}

export const patchCourseCollectionError = () => {
  return {
    type: PATCH_COURSE_COLLECTION_ERROR,
  }
}

/**
 *
 * @param {*} query
 */
export const courseCollectionListAction = (query, callback) => {
  return {
    type: LIST_COURSE_COLLECTION,
    payload: { query, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const courseCollectionListSuccess = response => {
  return {
    type: LIST_COURSE_COLLECTION_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const courseCollectionListError = () => {
  return {
    type: LIST_COURSE_COLLECTION_ERROR,
  }
}
/**
 *
 * @param {*} entity
 */
export const courseCollectionUpdateAction = (entity, callback) => {
  return {
    type: UPDATE_COURSE_COLLECTION,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const courseCollectionUpdateSuccess = response => {
  return {
    type: UPDATE_COURSE_COLLECTION_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const courseCollectionUpdateError = () => {
  return {
    type: UPDATE_COURSE_COLLECTION_ERROR,
  }
}

/**
 *
 * @param {*} entity
 */
export const editCourseCollectionIndexAction = (entity, callback) => {
  return {
    type: EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const editCourseCollectionIndexSuccess = entity => {
  return {
    type: EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const editCourseCollectionIndexError = () => {
  return {
    type: EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_ERROR,
  }
}

/**
 *
 * @param {*} code
 * @param {*} callback
 */
export const assignmentCloneAction = (code, callback, history) => {
  return {
    type: ASSIGNMENT_CLONE,
    payload: { code, callback, history },
  }
}

/**
 *
 * @param {*} entity
 */
export const assignmentCloneSuccess = entity => {
  return {
    type: ASSIGNMENT_CLONE_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const assignmentCloneError = () => {
  return {
    type: ASSIGNMENT_CLONE_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const assignmentDeleteAction = (id, callback) => {
  return {
    type: ASSIGNMENT_DELETE,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const assignmentDeleteSuccess = response => {
  return {
    type: ASSIGNMENT_DELETE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const assignmentDeleteError = () => {
  return {
    type: ASSIGNMENT_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */
export const collectionCoursePatchAction = (content, callback) => {
  return {
    type: COLLECTION_COURSE_PATCH,
    payload: { content, callback },
  }
}

/**
 *
 * @param {*} content
 */
export const collectionCoursePatchSuccess = content => {
  return {
    type: COLLECTION_COURSE_PATCH_SUCCESS,
    payload: { content },
  }
}

/**
 *
 */
export const collectionCoursePatchError = () => {
  return {
    type: COLLECTION_COURSE_PATCH_ERROR,
  }
}

/**
 *
 * @param {*} id
 * @param {*} callback
 */
export const getCollectionSettingsAction = (id, callback) => {
  return {
    type: GET_COLLECTION_SETTINGS,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const getCollectionSettingsSuccess = response => {
  return {
    type: GET_COLLECTION_SETTINGS_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const getCollectionSettingsError = () => {
  return {
    type: GET_COLLECTION_SETTINGS_ERROR,
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */
export const patchCollectionSettingsAction = (content, callback) => {
  return {
    type: PATCH_COLLECTION_SETTINGS,
    payload: { content, callback },
  }
}

/**
 *
 * @param {*} content
 */
export const patchCollectionSettingsSuccess = content => {
  return {
    type: PATCH_COLLECTION_SETTINGS_SUCCESS,
    payload: { content },
  }
}

/**
 *
 */
export const patchCollectionSettingsError = () => {
  return {
    type: PATCH_COLLECTION_SETTINGS_ERROR,
  }
}
