import { guid } from '../../../../../utils/guid'
// import { teamListByParentService } from '../../../../../api/team'
import { teamListByParentWithEmptyService } from '../../../../../api/team'
import translator from '../../../../../translate'
import {
  FormItemType,
  FormItemFilterAddMode,
} from '../../../../../form/props/form-item/default'

const FormItemTeam = {
  id: guid().uid(),
  attribute: 'TeamId',
  label: translator._('general.team'),
  type: FormItemType.TREE,
  required: false,
  disabled: false,
  hidden: false,
  // nodeOperation: teamListByParentService,
  nodeOperation: teamListByParentWithEmptyService,
  optionAttributeName: { value: 'id', label: 'name' },
  filterAddMode: FormItemFilterAddMode.MULTIPLE,
  leafNodeControl: node => {
    return node.childCount === 0
  },
}

export default FormItemTeam
