const URL = {
  /*Dev'e publish alırken alttakini alıyoruz*/
  //API: 'https://mlpbackend.rootielearning.com/api/',

  /*Prod'a publish alırken alttakini alıyoruz */
  //API: 'https://mlpbackendprod.rootielearning.com/api/',

  // API: 'https://localhost:44393/api/',
  // API: 'http://localhost:61724/api/',
  //API: 'http://localhost:5000/api',

  API: process.env.REACT_APP_URL,

  //REPORT_API: process.env.REACT_REPORT_URL,
  //REPORT_API: 'https://localhost:5001/api/',
  //REPORT_API: 'http://176.236.134.187:5000/api/',
  //REPORT_API: process.env.REACT_APP_URL,
  REPORT_API: process.env.REACT_APP_URL,
  SOCKET_URL: process.env.REACT_APP_URL.replace("api/","myHub"),
  MEDIA: process.env.REACT_APP_STORAGE,
  STORAGE: process.env.REACT_APP_STORAGE,
  STORAGE_QUESTION_IMAGE: process.env.REACT_APP_STORAGE,
  CDN: process.env.REACT_APP_CDN,

  APP_URL: process.env.REACT_APP_VIEW_URL,
}

export default URL
