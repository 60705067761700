const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: 16,
    marginTop: 16,
    paddingBottom: 16,
  },
  teamListRoot: {
    //height: 'fit-content',
    width: '100%',
    backgroundColor: '#F4F6F8',
    padding: '32px 16px',
    borderRadius: '16px',
    marginBottom: 16,
  },
  teamListRoot__teamDiv: {
    width: '100%',
    backgroundColor: '#F4F6F8',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
    alignContent: 'center',
    height: '100%',
  },
  page__header__button: {
    borderRadius: '12px',
    backgroundColor: '#37D768',
    color: '#fff',
    height: '40px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#63f28f',
    },
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
})

export default styles
