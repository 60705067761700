const styles = theme => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '235px',
    flexBasis: 'unset',
    borderRadius: '10px',
    marginBottom: '20px',
    backgroundColor: 'rgb(145,158,171,0.08)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  gridViewImage: {
    width: '100%',
    flexBasis: 'unset',
    height: 235,
  },
  imagePlaceholder: {
    marginBottom: theme.spacing(1.5),
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: theme.spacing(1.5),
    alignItems: 'center',
  },

  informGrid: {
    minWidth: 130,
  },
  card: {
    width: '100%',
    maxWidth: 120,
    minHeight: 150,
    marginBottom: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  media: {
    height: 60,
    width: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    marginBottom: 0,
  },
  cardContent: {
    textAlign: 'center',
  },
  button: {
    borderColor: '#0C53B7',
    background: '#0C53B7',
    color: 'white',
    textTransform: 'none',
    marginBottom: theme.spacing(2),
    '&:hover': {
      borderColor: '#0c72ce',
      background: '#0c72ce',
      color: 'white',
    },
  },

  or: {
    color: 'white',
    margin: theme.spacing(1),
  },
  box: {
    width: 162,
    margin: '0 auto',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  deleteButton: {
    position: 'absolute',
    zIndex: 1300,
  },

  addImage: {
    borderColor: '#0C53B7',
    background: '#0C53B7',
    color: 'white',
    textTransform: 'none',
    margin: theme.spacing(1),
    '&:hover': {
      borderColor: '#0c72ce',
      background: '#0c72ce',
      color: 'white',
    },
  },
  iconButton: {
    background: 'white',
    padding: '3px',
    borderRadius: '50%',
    position: 'absolute',
    fontSize: 'small',
    '&:hover': {
      background: 'white',
    },
  },
  childGrid: {
    background: 'black',
    height: '100%',
  },
})

export default styles
