import translator from '../../../translate'
import { ListColumnType } from '../../../model/list'

/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 */
const columns = [
  {
    attribute: 'courseName',
    label: translator._('courseStatistics.courseName'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    actions: null,
  },

  {
    attribute: 'account',
    label: translator._('courseStatistics.account'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'courseViews',
    label: translator._('courseStatistics.courseViews'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'uniqueCourseViews',
    label: translator._('courseStatistics.uniqueCourseViews'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'uniqueEngageUsers',
    label: translator._('courseStatistics.uniqueEngageUsers'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'createdContentItems',
    label: translator._('courseStatistics.createdContentItems'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    actions: null,
  },
  {
    attribute: 'repliedContentItems',
    label: translator._('courseStatistics.repliedContentItems'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    actions: null,
  },
]

export default columns
