import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} email
 */
export const sendConfirmationEmailService = email =>
  new Request(URL.API, URL.SEND_CONFIRMATION_EMAIL).post(email)

/**
 *
 * @param {*} query
 */
export const confirmCodeService = query =>
  new Request(URL.API, URL.CONFIRM_CODE).get(query)

/**
 *
 * @param {*} query
 */
export const signUpService = query =>
  new Request(URL.API, URL.SIGN_UP).post(query)

/**
 *
 * @param {*} query
 */
export const signUpRootieService = query =>
  new Request(URL.API, URL.SIGN_UP_ROOTIE).post(query)

export const registerMarketingInfo = query =>
  new Request(URL.API, URL.REGISTER_MARKETING_INFO).post(query)

/**
 *
 * @param {*} query
 */
export const signUpAppsumoService = query =>
  new Request(URL.API, URL.APPSUMO_SIGNUP).post(query)
