import React from 'react'
import style from './style'
import propTypes from './prop'
import ClassNames from 'classnames'
import Form from '../../../form'
import { withStyles, Collapse, Paper } from '@material-ui/core'
import {
  FormUpdateMethodType,
  FormUseModeType,
  FormStateModeType,
  FormViewType,
} from '../../../../form/props'

/**
 *
 * @param {*} props
 */
const SearchDetail = props => {
  const { classes, expanded, filters, query, onUpdate, dispatch } = props

  /**
   *
   * @param {*} content
   * @param {*} formController
   */
  const onPatchFilter = async (content, formController) => {
    onUpdate(await formController.entity())
  }

  return (
    <Collapse
      in={expanded}
      timeout="auto"
      className={ClassNames(
        classes.collapse,
        expanded ? classes.collapseShow : null,
      )}
    >
      <Paper className={classes.paper}>
        <Form
          dispatch={dispatch}
          entity={query}
          form={filters}
          useMode={FormUseModeType.SEARCH}
          updateMethod={FormUpdateMethodType.PATCH}
          stateMode={FormStateModeType.UPDATE}
          viewMode={FormViewType.VIEW_INLINE}
          hasFooter={false}
          onPatch={onPatchFilter}
        />
      </Paper>
    </Collapse>
  )
}

SearchDetail.propTypes = propTypes

export default withStyles(style)(SearchDetail)
