const styles = theme => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    //maxWidth: '1200px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '50px',
  },
  characterCount: {
    textAlign: 'left',
    fontSize: 11,
  },
  paper: {
    borderRadius: '16px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '220px',
    width: '300px',
    //padding: 32,
    alignItems: 'center',
    '&:hover': {
      outline: '1px solid #0d53b7',
      cursor: 'pointer',
    },
  },
  innerGrid: {
    display: 'flex',
    flexDirection: 'row',
    //minWidth: '760px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      zoom: 0.7,
    },
  },
  iframeDiv: {
    overFlow: 'hidden',
    position: 'relative',
    paddingTop: '65%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '85%',
    },
  },
  iframe: {
    border: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  embedViewGrid: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '38px',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    maxWidth:"1280px"
  },
  embedViewPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    marginBottom: 20,
    height: '95%',
  },
  backButton: {
    position: 'absolute',
    top: '78px',
    left: '32px',
    display: 'flex',
    '&:hover': { cursor: 'pointer' },
  },
  button: {
    color: '#637381',
    '&:hover': { backgroundColor: 'transparent' },
  },
})

export default styles
