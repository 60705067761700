import { Component } from 'react'
import propTypes from './prop'
import { set as setCookie } from 'es-cookie'
import { integrationValidateShortLinkService } from '../../api/integration'

class EnoctaReroute extends Component {
  checkToken = async shortLink => {
    //let response = await integrationValidateTokenService(token)

    let response = await integrationValidateShortLinkService(shortLink)

    if (response.data != null) {
      setCookie('token', response.data.accessToken, {
        sameSite: 'none',
        secure: true,
      })
      //setCookie('token', token)

      if (response.data.firstLogin) {
        setCookie('email', response.data.userName, {
          sameSite: 'none',
          secure: true,
        })

        window.location.href = '/'
      } else {
        window.location.href = '/'
      }
    } else {
      window.location.href = '/login'
    }
  }

  /**
   *
   */
  render() {
    let url = window.location.href
    //let token = url.split('token=')[1]
    let shortLink = url.split('shortLink=')[1]

    if (shortLink) {
      this.checkToken(shortLink)
    }
    // if (token) {
    //     this.checkToken(token)
    // }

    return ''
  }
}

EnoctaReroute.propTypes = propTypes

export default EnoctaReroute
