import PropTypes from 'prop-types'

const FormGroupPropTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  collapsible: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  formItems: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default FormGroupPropTypes
