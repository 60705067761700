import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, TextField, Box, Button } from '@material-ui/core'
import styles from './styles'

const CourseNaming = props => {
  const { setCourseTitle, handleCopyCourse } = props
  return (
    <>
      <Box m={6}></Box>
      <Typography variant="h4">Let's name your mini-course.</Typography>

      <Box m={2}></Box>

      <TextField
        style={{
          width: '300px',
          borderColor: '#015FFB',
        }}
        variant="outlined"
        onChange={e => {
          setCourseTitle(e.currentTarget.value)
        }}
        label="Name your mini-course"
      />
      <Box m={1}></Box>
      <Button
        variant="contained"
        style={{
          backgroundColor: '#015FFB',
          borderRadius: '20px',
          marginTop: 10,
          minWidth: '150px',
        }}
        onClick={() => handleCopyCourse()}
      >
        Next
      </Button>
    </>
  )
}

export default withStyles(styles)(CourseNaming)
