const style = theme => ({
  collapse: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  collapseShow: {
    '&:after': {
      content: '',
      position: 'absolute',
      width: 0,
      height: 0,
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderBottom: '5px solid white',
    },
  },
  paper: {
    width: '100%',
    border: '1px solid #ced4da',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
  },
})

export default style
