import {
  USER_ROLE_DETAILED_LIST,
  USER_ROLE_DETAILED_LIST_SUCCESS,
  SET_USER_ROLE_SUCCESS,
} from './type'

export default function (
  state = {
    list: { results: null, totalCount: 0 },
    query: null,
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case USER_ROLE_DETAILED_LIST:
      return { ...state, query: payload.query }
    case USER_ROLE_DETAILED_LIST_SUCCESS:
      return { ...state, list: payload.response }
    case SET_USER_ROLE_SUCCESS:
      state = {
        ...state,
        list: { ...state.list, results: [payload.response] },
      }
      return state
    default:
      return state
  }
}
