import { domainListAction } from '../../../reducers/domain/action'
import grupActions from './grup-actions'
import columns from './columns'
import search from './search'
import filters from './filters'
import filterModel from './filters/model'

const list = {
  searchOperation: (query, dispatch, initialData) => {
    dispatch(domainListAction(query))
  },
  grupActions: grupActions,
  search: search,
  filters: filters,
  filtersModel: filterModel,
  row: {
    columns: columns,
    childDisableControl: row => {
      return true
    },
    childVisibleControl: row => {
      return true
    },
  },
}

export default list
