import React from 'react'
import styles from './style'
import propTypes from './prop'
import AddIcon from '@material-ui/icons/AddCircle'
import ComponentBoxContent from '../../../model/component-box'
import { withStyles, Button, Grid } from '@material-ui/core'
import {
  componentBoxHideAction,
  componentBoxShowAction,
} from '../../../reducers/component-box/action'

const TeamEmpty = props => {
  const {
    classes,
    onAdd,
    dispatch,
    addItemButtonLabel,
    componentBoxContent,
  } = props

  /**
   *
   * @param {*} e
   */
  const onAddClick = e => {
    /**
     *
     */
    const onCancel = () => {
      dispatch(componentBoxHideAction())
    }

    /**
     *
     * @param {*} list
     */
    const onOk = (idlist, entityList) => {
      onCancel()

      onAdd(entityList)
    }

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(componentBoxContent(onOk, onCancel), {
          hideCloseButton: true,
        }),
      ),
    )
  }

  return (
    <Grid container>
      <Button
        onClick={onAddClick}
        variant="outlined"
        color="primary"
        className={classes.addButton}
        size="small"
      >
        <AddIcon fontSize="small" className={classes.buttonIcon} />

        {addItemButtonLabel}
      </Button>
    </Grid>
  )
}

TeamEmpty.propTypes = propTypes

export default withStyles(styles)(TeamEmpty)
