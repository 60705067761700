import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { USER_RENEW_PASSWORD } from '../../reducers/user-password/type'
import { userRenewPasswordService } from '../../api/user-password'
import { userRenewPasswordSuccess } from '../../reducers/user-password/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* userRenewPasswordFlow(payload) {
  try {
    const { entity, callback } = payload
    const response = yield call(userRenewPasswordService, entity)
    yield put(userRenewPasswordSuccess(response))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* userRenewPasswordWatcher() {
  while (true) {
    const action = yield take(USER_RENEW_PASSWORD)
    const { payload } = action

    yield fork(generalSaga, userRenewPasswordFlow, payload)
  }
}
