import { NamingModal } from '../components'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'

const createCourse = (collectionId, courses, dispatch, history) => {
  const componentBoxHide = () => {
    dispatch(componentBoxHideAction())
  }
  const component = (
    <NamingModal
      courses={courses}
      collectionId={collectionId}
      dispatch={dispatch}
      history={history}
      componentBoxHide={componentBoxHide}
    />
  )

  dispatch(
    componentBoxShowAction(
      new ComponentBoxContent(component, {
        hideCloseButton: false,
        callback: e => {},
      }),
    ),
  )
}

export default createCourse
