import translator from '../../translate'

/**
 *
 * @param value
 */
const ValidationFormItemFileUpload = (form, formItem) => {
  const error = { success: true, message: '' }

  if (formItem.value == null) {
    error.success = false
    error.message = translator._('form.error.empty')
    return error
  }

  return error
}

export default ValidationFormItemFileUpload
