const style = theme => ({
  searchBar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '17px',
  },
  button: {
    width: '20%',
  },
  content: {
    height: 'calc(100% - 110px)',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    maxWidth: 600,
  },
  imageList: {
    padding: 2,
    alignContent: 'flex-start',
    overflow: 'hidden',
  },
  imageItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  card: {
    width: '100%',
    padding: '10px',
    boxShadow: theme.shadows[25].primary,
    [theme.breakpoints.down('md')]: {
      height: '100%',
      padding: 0,
    },
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    paddingLeft: '15px !important',
  },
  tableTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  items: {
    paddingBottom: '30px',
    paddingTop: '30px',
  },
  button: {
    //marginTop: theme.spacing(3),
    margin: '12px',
  },
  midDiv: { flexGrow: 0.6 },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export default style
