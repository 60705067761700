/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  Button,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import styles from './styles'
import { Icon } from '@iconify/react'

import { teamsMemberImportAction } from 'src/reducers/teams/action'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import {
  InputField,
  ViewExample,
  ImportButtons,
  UserListWithError,
} from './components'
const ImportMembers = props => {
  const { entity, dispatch, classes } = props

  const teamsId = window.location.pathname.split('users-member-list/')[1]
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [uploadedFile, setUploadedFile] = React.useState()
  const [memberCount, setMemberCount] = React.useState(0)
  const [userListWithError, setUserListWithError] = React.useState([])

  const onAddBulkMember = event => {
    const file = event.target.files[0]
    if (file.type === 'text/csv') {
      //read files row count
      const reader = new FileReader()
      reader.readAsText(file)

      reader.onload = function (e) {
        const csv = e.target.result
        const lines = csv.split('\n')
        setMemberCount(lines.length - 2)
      }
      setUploadedFile(file)
    }
  }
  const handleImport = () => {
    setLoading(true)
    dispatch(
      teamsMemberImportAction(uploadedFile, teamsId, response => {
        let userListWithErrorList = []

        response.map(item => {
          if (item.errors != null) {
            userListWithErrorList = [item, ...userListWithErrorList]
          }
        })
        //userListWithErrorList.length == 0 && dispatch(componentBoxHideAction())
        setUserListWithError(userListWithErrorList)
        setLoading(false)
        setSuccess(true)
      }),
    )
  }
  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
        <div className={classes.modalContent__header}>
          {success && (
            <img
              src="/assets/import-success.png"
              style={{
                width: '40px',
                height: '40px',
              }}
            />
          )}
          <Typography className={classes.modalContent__header__title}>
            {success ? 'Users added successfully!' : 'Import Users'}
          </Typography>
        </div>
        {success ? (
          <>
            <Typography className={classes.modalContent__header__description}>
              {memberCount - userListWithError.length} of the {memberCount}{' '}
              users in the .CSV file you selected have been successfully added
              to the group.
            </Typography>

            {userListWithError.length > 0 && (
              <UserListWithError userListWithError={userListWithError} />
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.modalContent__doneButton}
                onClick={() => {
                  dispatch(componentBoxHideAction())
                }}
              >
                Done
              </Button>
            </div>
          </>
        ) : (
          <>
            <InputField
              uploadedFile={uploadedFile}
              onAddBulkMember={onAddBulkMember}
              memberCount={memberCount}
              setUploadedFile={setUploadedFile}
            />
            <div className={classes.border}></div>
            <ViewExample uploadedFile={uploadedFile} />
            <ImportButtons
              handleImport={handleImport}
              dispatch={dispatch}
              uploadedFile={uploadedFile}
            />
          </>
        )}
      </div>
    </div>
  )
}
export default withStyles(styles)(ImportMembers)
