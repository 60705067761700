import {
  GET_FILTER_NAMES,
  GET_FILTER_NAMES_SUCCESS,
  GET_FILTER_NAMES_ERROR,
  EXCEL_IMPORT_GET_FORM,
  EXCEL_IMPORT_GET_FORM_SUCCESS,
  EXCEL_IMPORT_GET_FORM_ERROR,
  EXCEL_IMPORT_USERS,
  EXCEL_IMPORT_USERS_SUCCESS,
  EXCEL_IMPORT_USERS_ERROR,
  EXCEL_IMPORT_QUESTIONS_GET_FORM,
  EXCEL_IMPORT_QUESTIONS_GET_FORM_SUCCESS,
  EXCEL_IMPORT_QUESTIONS_GET_FORM_ERROR,
  EXCEL_IMPORT_QUESTIONS,
  EXCEL_IMPORT_QUESTIONS_SUCCESS,
  EXCEL_IMPORT_QUESTIONS_ERROR,
  EXCEL_EXPORT_USERS,
  EXCEL_EXPORT_USERS_SUCCESS,
  EXCEL_EXPORT_USERS_ERROR,
  EXCEL_EXPORT_ALL_USERS,
  EXCEL_EXPORT_ALL_USERS_SUCCESS,
  EXCEL_EXPORT_ALL_USERS_ERROR,
  EXCEL_EXPORT_USER_MESSAGES,
  EXCEL_EXPORT_USER_MESSAGES_SUCCESS,
  EXCEL_EXPORT_USER_MESSAGES_ERROR,
  SEND_ACTIVATION_SMS,
  SEND_ACTIVATION_SMS_SUCCESS,
  SEND_ACTIVATION_SMS_ERROR,
  SEND_ACTIVATION_EMAIL,
  SEND_ACTIVATION_EMAIL_SUCCESS,
  SEND_ACTIVATION_EMAIL_ERROR,
  SEND_MAGIC_LINK_VIA_EMAIL,
  SEND_MAGIC_LINK_VIA_EMAIL_SUCCESS,
  SEND_MAGIC_LINK_VIA_EMAIL_ERROR,
  GET_CONSTANTS,
  GET_CONSTANTS_SUCCESS,
  GET_CONSTANTS_ERROR,
  APPSUMO_CODE_CONFIRM,
  APPSUMO_CODE_CONFIRM_SUCCESS,
  APPSUMO_CODE_CONFIRM_ERROR,
  APPSUMO_CODE_REFUND,
  APPSUMO_CODE_REFUND_SUCCESS,
  APPSUMO_CODE_REFUND_ERROR,
} from './type'

/**
 *
 * @param {*} entity
 */
export const GetFilterNamesAction = entity => {
  return {
    type: GET_FILTER_NAMES,
    payload: { entity },
  }
}

/**
 *
 * @param {*} entity
 */
export const GetFilterNamesSuccess = entity => {
  return {
    type: GET_FILTER_NAMES_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 * @param {*} entity
 */
export const GetFilterNamesError = entity => {
  return {
    type: GET_FILTER_NAMES_ERROR,
    payload: { entity },
  }
}

/**
 *
 * @param callback
 */
export const ExcelImportGetFormAction = callback => {
  return {
    type: EXCEL_IMPORT_GET_FORM,
    payload: { callback },
  }
}

/**
 *
 * @param {*} response
 */
export const ExcelImportGetFormSuccess = response => {
  return {
    type: EXCEL_IMPORT_GET_FORM_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const ExcelImportGetFormError = () => {
  return {
    type: EXCEL_IMPORT_GET_FORM_ERROR,
  }
}

/**
 *
 * @param {*} file
 */
export const ExcelImportUsersAction = (file, callback) => {
  return {
    type: EXCEL_IMPORT_USERS,
    payload: { file, callback },
  }
}

/**
 *
 * @param {*} excelFile
 */
export const ExcelImportUsersSuccess = excelFile => {
  return {
    type: EXCEL_IMPORT_USERS_SUCCESS,
    payload: { excelFile },
  }
}

/**
 *
 * @param {*} excelFile
 */
export const ExcelImportUsersError = excelFile => {
  return {
    type: EXCEL_IMPORT_USERS_ERROR,
    payload: { excelFile },
  }
}

/**
 *
 * @param callback
 */
export const ExcelImportQuestionsGetFormAction = callback => {
  return {
    type: EXCEL_IMPORT_QUESTIONS_GET_FORM,
    payload: { callback },
  }
}

/**
 *
 * @param {*} response
 */
export const ExcelImportQuestionsGetFormSuccess = response => {
  return {
    type: EXCEL_IMPORT_QUESTIONS_GET_FORM_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const ExcelImportQuestionGetFormError = () => {
  return {
    type: EXCEL_IMPORT_QUESTIONS_GET_FORM_ERROR,
  }
}

/**
 *
 * @param {*} file
 */
export const ExcelImportQuestionsAction = (file, callback) => {
  return {
    type: EXCEL_IMPORT_QUESTIONS,
    payload: { file, callback },
  }
}

/**
 *
 * @param {*} excelFile
 */
export const ExcelImportQuestionsSuccess = excelFile => {
  return {
    type: EXCEL_IMPORT_QUESTIONS_SUCCESS,
    payload: { excelFile },
  }
}

/**
 *
 * @param {*} excelFile
 */
export const ExcelImportQuestionsError = excelFile => {
  return {
    type: EXCEL_IMPORT_QUESTIONS_ERROR,
    payload: { excelFile },
  }
}

/**
 *
 * @param {*} userIds
 */
export const ExcelExportUsersAction = userIds => {
  return {
    type: EXCEL_EXPORT_USERS,
    payload: { userIds },
  }
}

/**
 *
 * @param {*} userIds
 */
export const ExcelExportUsersSuccess = userIds => {
  return {
    type: EXCEL_EXPORT_USERS_SUCCESS,
    payload: { userIds },
  }
}

/**
 *
 * @param {*} userIds
 */
export const ExcelExportUsersError = userIds => {
  return {
    type: EXCEL_EXPORT_USERS_ERROR,
    payload: { userIds },
  }
}
/**
 *
 * @param {*} query
 */
export const excelExportUserMessagesAction = query => {
  return {
    type: EXCEL_EXPORT_USER_MESSAGES,
    payload: { query },
  }
}

/**
 *
 * @param {*} userMessageIds
 */
export const excelExportUserMessagesSuccess = userMessageIds => {
  return {
    type: EXCEL_EXPORT_USER_MESSAGES_SUCCESS,
    payload: { userMessageIds },
  }
}

/**
 *
 * @param {*} userMessageIds
 */
export const excelExportUserMessagesError = userMessageIds => {
  return {
    type: EXCEL_EXPORT_USER_MESSAGES_ERROR,
    payload: { userMessageIds },
  }
}

/**
 *
 * @param {*} userIds
 */
export const ExcelExportAllUsersAction = () => {
  return {
    type: EXCEL_EXPORT_ALL_USERS,
  }
}

/**
 *
 * @param {*} userIds
 */
export const ExcelExportAllUsersSuccess = userIds => {
  return {
    type: EXCEL_EXPORT_ALL_USERS_SUCCESS,
    payload: { userIds },
  }
}

/**
 *
 * @param {*} userIds
 */
export const ExcelExportAllUsersError = userIds => {
  return {
    type: EXCEL_EXPORT_ALL_USERS_ERROR,
    payload: { userIds },
  }
}

/**
 *
 * @param {*} userIds
 */
export const sendActivationSMSAction = userIds => {
  return {
    type: SEND_ACTIVATION_SMS,
    payload: { userIds },
  }
}

/**
 *
 * @param {*} entity
 */
export const sendActivationSMSSuccess = entity => {
  return {
    type: SEND_ACTIVATION_SMS_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 * @param {*} entity
 */
export const sendActivationSMSError = entity => {
  return {
    type: SEND_ACTIVATION_SMS_ERROR,
    payload: { entity },
  }
}

/**
 *
 * @param {*} userIds
 */
export const sendActivationEmailAction = userIds => {
  return {
    type: SEND_ACTIVATION_EMAIL,
    payload: { userIds },
  }
}

/**
 *
 * @param {*} entity
 */
export const sendActivationEmailSuccess = entity => {
  return {
    type: SEND_ACTIVATION_EMAIL_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 * @param {*} entity
 */
export const sendActivationEmailError = entity => {
  return {
    type: SEND_ACTIVATION_EMAIL_ERROR,
    payload: { entity },
  }
}

/**
 *
 * @param {*} userIds
 */
export const sendMagicLinkViaEmailAction = userIds => {
  return {
    type: SEND_MAGIC_LINK_VIA_EMAIL,
    payload: { userIds },
  }
}

/**
 *
 * @param {*} entity
 */
export const sendMagicLinkViaEmailSuccess = entity => {
  return {
    type: SEND_MAGIC_LINK_VIA_EMAIL_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 * @param {*} entity
 */
export const sendMagicLinkViaEmailError = entity => {
  return {
    type: SEND_MAGIC_LINK_VIA_EMAIL_ERROR,
    payload: { entity },
  }
}

/**
 *
 */
export const getConstants = () => {
  return {
    type: GET_CONSTANTS,
  }
}

/**
 *
 * @param {*} constants
 */
export const getConstantsSuccess = constants => {
  return {
    type: GET_CONSTANTS_SUCCESS,
    payload: { constants },
  }
}

/**
 *
 */
export const getConstantsError = () => {
  return {
    type: GET_CONSTANTS_ERROR,
  }
}

/**
 *
 * @param {*} code
 * @param {*} callback
 */
export const codeVerifierAction = (code, callback) => {
  return {
    type: APPSUMO_CODE_CONFIRM,
    payload: { code, callback },
  }
}

/**
 *
 * @param {*} code
 */
export const codeVerifierActionSuccess = code => {
  return {
    type: APPSUMO_CODE_CONFIRM_SUCCESS,
    payload: { code },
  }
}

/**
 *
 * @param {*} error
 */
export const codeVerifierActionError = error => {
  return {
    type: APPSUMO_CODE_CONFIRM_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} promoCodes
 * @param {*} callback
 */

export const appsumoCouponRefundAction = (promoCodes, callback) => {
  return {
    type: APPSUMO_CODE_REFUND,
    payload: { promoCodes, callback },
  }
}

/**
 *
 * @param {*} response
 */

export const appsumoCouponRefundSuccess = response => {
  return {
    type: APPSUMO_CODE_REFUND_SUCCESS,
    payload: { response },
  }
}

/**
 *
 * @param {*} error
 */
export const appsumoCouponRefundError = error => {
  return {
    type: APPSUMO_CODE_REFUND_ERROR,
    payload: { error },
  }
}
