import React from 'react'

import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
// material
import { styled, makeStyles } from '@material-ui/core/styles'
import { Card, Button, Typography, Box, Stack } from '@material-ui/core'
// routes
//
import Label from '../Label'
import checkmarkFill from '@iconify-icons/eva/checkmark-fill'
import WhatshotOutlinedIcon from '@material-ui/icons/WhatshotOutlined'

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  minHeight: 660,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    minWidth: 350,
    marginBottom: 30,
    marginRight: '-20px',
  },
  [theme.breakpoints.up('md')]: {
    minWidth: 400,
    marginRight: '20px',
    marginBottom: 30,
  },
}))
const useStyles = makeStyles({
  captionText: {
    '&:hover': {
      //cursor: 'pointer',
    },
  },
})

// ----------------------------------------------------------------------

PricingPlanCard.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
}

export default function PricingPlanCard({
  card,
  index,
  onClickHandler,
  timeInterval,
  handleChange,
}) {
  const { subscription, icon, price, caption, lists, labelAction } = card
  const classes = useStyles()
  return (
    <RootStyle>
      {index === 4 && (
        <Label
          color="info"
          sx={{
            top: 16,
            right: 16,
            position: 'absolute',
          }}
        >
          POPULAR
        </Label>
      )}

      <Typography
        variant="overline"
        sx={{ color: 'text.secondary', fontSize: '15px' }}
      >
        {subscription}
      </Typography>

      <Box
        sx={{
          display: index == 3 ? 'none' : 'flex',
          justifyContent: 'flex-end',
          my: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          $
        </Typography>

        <Typography variant="h2" sx={{ mx: 1 }}>
          {price === 0 ? '0' : price}
        </Typography>

        <Typography
          gutterBottom
          component="span"
          variant="subtitle2"
          sx={{
            alignSelf: 'flex-end',
            color: 'text.secondary',
          }}
        >
          {timeInterval ? '/mo' : '/mo'}
        </Typography>
      </Box>

      {index == 3 ? (
        <Typography
          variant="subtitle2"
          component="span"
          sx={{
            color: 'text.secondary',
            textAlign: 'center',
            paddingBottom: '30px',
            paddingTop: '30px',
          }}
        >
          {caption}
        </Typography>
      ) : (
        <Typography
          variant="subtitle2"
          gutterBottom
          component="span"
          className={classes.captionText}
          sx={{
            color: 'text.secondary',
            minHeight: 25,
          }}
          //onClick={() => handleChange()}
        >
          {timeInterval || index == 0 ? caption : ' '}
        </Typography>
      )}

      <Box sx={{ width: 80, height: 80, mt: 3 }}>{icon}</Box>

      <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
        {lists.map(item => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{
              typography: 'body2',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
            }}
          >
            <Box
              component={Icon}
              icon={checkmarkFill}
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="body2">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
      <div style={{ flexGrow: 1 }}></div>
      <Button
        fullWidth
        size="large"
        variant="contained"
        disabled={index == 0 || labelAction == 'Current Plan'}
        style={{
          backgroundColor: 'primary',
        }}
        onClick={() => {
          index != 3 && onClickHandler()
        }}
        href={
          index === 3 &&
          'https://calendly.com/minicoursegenerator/30min?month=2023-08'
        }
        target="_blank"
      >
        {labelAction}
      </Button>
    </RootStyle>
  )
}
