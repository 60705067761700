import { PROCESS_START, PROCESS_END } from './type'

/**
 *
 */
export const processStartAction = () => {
  return {
    type: PROCESS_START,
  }
}

/**
 *
 */
export const processStartEnd = () => {
  return {
    type: PROCESS_END,
  }
}
