import React, { useState } from 'react'

import styles from '../styles'
import translator from 'src/translate'
// @mui
import {
  Container,
  Typography,
  Card,
  Button,
  IconButton,
  withStyles,
  Tooltip,
  MenuItem,
  Modal,
  Box,
} from '@material-ui/core'

import BarChartIcon from '@material-ui/icons/BarChart'
import Iconify from 'src/components/Iconify'
import MenuPopover from 'src/components/MenuPopover'
import ComponentBoxContent from 'src/model/component-box'
import ShareLinkStep from 'src/pages/create-quiz/share-link'
import EmbedStep from 'src/pages/create-quiz/embed'
import { ButtonType, ButtonData, ButtonActionType } from 'src/model/button'
import {
  assignmentGetAction,
  assignmentReportAction,
} from 'src/reducers/assignment/action'
import {
  assignmentCloneAction,
  assignmentDeleteAction,
} from 'src/reducers/course-collection/action'

import {
  messageBoxShowAction,
  messageBoxHideAction,
} from 'src/reducers/message-box/action'
import MessageBoxContent, { MessageBoxType } from 'src/model/message-box'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import URL from 'src/api/url'
import NamingModal from './naming-modal'
import { Icon } from '@iconify/react'

const CourseCard = props => {
  const {
    dispatch,
    course,
    redirectCourse,
    history,
    refresh,
    componentBoxHide,
    setCurrentCredit,
    currentCredit,
    classes,
    listeners,
  } = props

  const [open, setOpen] = useState(null)
  const [isNamingModalOpen, setNamingModalOpen] = useState(false)

  const [shareLinkModalOpen, setShareLinkModalOpen] = React.useState(false)
  const [shareEmbedModalOpen, setShareEmbedModalOpen] = React.useState(false)

  const handleGetAnalytics = course => {
    dispatch(assignmentReportAction(course.id, course.name))
  }
  const handleOpen = event => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const duplicateCourse = course => {
    dispatch(assignmentCloneAction(course.code))
  }
  const courseView = course => {
    window.open(URL.APP_URL + course.code, course.name, 'height=800,width=600')
  }
  const editCourse = course => {
    dispatch(
      assignmentGetAction(course.id, () => {
        history.push('/quiz/' + course.id)
      }),
    )
  }
  const renameCourse = course => {
    setNamingModalOpen(true)

    const component = (
      <NamingModal
        courseEntity={course}
        dispatch={dispatch}
        history={history}
        componentBoxHide={componentBoxHide}
      />
    )

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, {
          hideCloseButton: false,
          callback: e => {},
        }),
      ),
    )
  }

  const deleteCourse = course => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(MessageBoxType.WARNING, 'Delete', [
          new ButtonData(ButtonType.NORMAL, 'No', ButtonActionType.NO, () => {
            dispatch(messageBoxHideAction())
          }),
          new ButtonData(
            ButtonType.ACTION,
            'Yes',
            ButtonActionType.DELETE,
            () => {
              dispatch(
                assignmentDeleteAction(course.id, () => {
                  setCurrentCredit(currentCredit + 1)
                  dispatch(messageBoxHideAction())
                  refresh()
                }),
              )
            },
          ),
        ]),
      ),
    )
  }
  const handleCloseShareLink = () => setShareLinkModalOpen(false)
  const handleCloseShareEmbed = () => setShareEmbedModalOpen(false)
  const handleOpenLink = () => {
    setShareLinkModalOpen(true)
  }
  const handleOpenShare = () => {
    setShareEmbedModalOpen(true)
  }
  return (
    <Card className={classes.courseCard}>
      <div
        style={{ height: '100%', display: 'flex' }}
        onClick={() => redirectCourse(course.id)}
      >
        <div
          style={{
            width: '25px',
          }}
        >
          <Icon
            {...listeners}
            icon="icon-park-outline:drag"
            width={'20px'}
            color={'#F67B3F'}
            style={{ marginTop: 5, marginRight: 5 }}
          />
        </div>

        <Typography
          style={{ fontSize: '14px', fontWeight: 500 }}
          variant="subtitle2"
        >
          {course.name}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'absolute',
            bottom: 8,
            width: '85%',
          }}
        >
          <Button
            style={{
              background: '#F67B3F',
              borderRadius: 16,
              color: '#fff',
              height: 28,
              zIndex: 10000,
            }}
            onClick={() => {
              handleOpenLink()
            }}
          >
            <Typography style={{ fontSize: '12px' }}>Share</Typography>
          </Button>
          <div style={{ flexGrow: 1 }}></div>
          <Tooltip title="Analytics">
            <IconButton
              onClick={() => {
                handleGetAnalytics(course)
              }}
              className={classes.iconButton}
              size="small"
            >
              <BarChartIcon style={{ color: '#F67B3F' }} />
            </IconButton>
          </Tooltip>

          <IconButton
            size="small"
            className={classes.iconButton}
            onClick={handleOpen}
          >
            <Iconify
              icon={'eva:more-horizontal-fill'}
              width={20}
              height={20}
              color={'#F67B3F'}
            />
          </IconButton>
        </div>
      </div>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            editCourse(course)
          }}
        >
          Edit
        </MenuItem>
        <MenuItem>
          <a
            href={URL.APP_URL + course.code}
            target="_blank"
            style={{
              color: '#000',
              textDecoration: 'none',
            }}
          >
            Preview
          </a>
        </MenuItem>
        <MenuItem
          onClick={() => {
            renameCourse(course)
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            duplicateCourse(course)
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenLink()
          }}
        >
          Share Link
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenShare()
          }}
        >
          Embed
        </MenuItem>

        <MenuItem
          onClick={() => {
            deleteCourse(course)
          }}
        >
          Delete
        </MenuItem>
      </MenuPopover>
      <Modal
        open={shareLinkModalOpen}
        onClose={() => {
          handleCloseShareLink()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <ShareLinkStep dispatch={dispatch} entity={course}></ShareLinkStep>
        </Box>
      </Modal>

      <Modal
        open={shareEmbedModalOpen}
        onClose={() => {
          handleCloseShareEmbed()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <EmbedStep dispatch={dispatch} entity={course}></EmbedStep>
        </Box>
      </Modal>
    </Card>
  )
}

export default withStyles(styles)(CourseCard)
