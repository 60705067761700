import React from 'react'
import styles from './style'
import FormItemGroup from '../../renderer/form-group'
import Header from '../../renderer/header'
import Footer from '../../renderer/footer'
import ClassNames from 'classnames'
import propTypes from './prop'
import {
  FormUpdateMethodType,
  FormStateModeType,
  FormViewType,
} from '../../../../form/props'
import { withStyles } from '@material-ui/core'

const LayoutForm = props => {
  const {
    dispatch,
    classes,
    updateMethod,
    stateMode,
    viewMode,
    editTitle,
    addTitle,
    formContent,
    onSave,
    onCancel,
    onPut,
  } = props
  const hasFooter = !(
    updateMethod === FormUpdateMethodType.PATCH &&
    stateMode === FormStateModeType.UPDATE
  )

  return (
    <React.Fragment>
      <Header
        label={stateMode === FormStateModeType.UPDATE ? editTitle : addTitle}
      />

      <section
        className={ClassNames({
          [classes.section]: true,
          [classes.inline]: viewMode == FormViewType.VIEW_INLINE,
          [classes.modal]: viewMode == FormViewType.VIEW_MODAL,
          //[classes.hasNotFooter]: !hasFooter,
        })}
      >
        {formContent.groups.map((group, groupIndex) => (
          <FormItemGroup
            key={groupIndex}
            stateMode={stateMode}
            {...group}
            dispatch={dispatch}
          />
        ))}
      </section>
      {hasFooter && (
        <Footer
          save={onSave}
          put={onPut}
          cancel={onCancel}
          stateMode={stateMode}
        />
      )}
      {!hasFooter && viewMode !== FormViewType.VIEW_INLINE && (
        <Footer save={onCancel} />
      )}
    </React.Fragment>
  )
}

LayoutForm.propTypes = propTypes

export default withStyles(styles)(LayoutForm)
