import React from 'react'
import styles from './style'
import propTypes from './prop'
import QuestionView from '../../../card-item/main'
import { Grid, withStyles } from '@material-ui/core'

const QuestionItem = props => {
  const {
    classes,
    back,
    index,
    entity,
    dispatch,
    onDrag,
    onClick,
    cardCount,
    onAddCard,
    setPage,
    lastCardFinish,
    page,
  } = props
  /**
   *
   */

  return (
    <Grid item className={classes.root}>
      <div className={classes.badgeRoot}>
        <QuestionView
          back={back}
          index={index}
          entity={entity}
          dispatch={dispatch}
          onDrag={onDrag}
          onClick={onClick}
          cardCount={cardCount}
          onAddCard={onAddCard}
          setPage={setPage}
          lastCardFinish={lastCardFinish}
          page={page}
        ></QuestionView>
      </div>
    </Grid>
  )
}

QuestionItem.propTypes = propTypes

export default withStyles(styles)(QuestionItem)
