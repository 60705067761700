import * as signalR from '@microsoft/signalr'
import URL from 'src/api/url/api'

const connection = new signalR.HubConnectionBuilder()
  .configureLogging(signalR.LogLevel.Error)
  .withUrl(URL.SOCKET_URL, {
    skipNegotiation: true,
    transport: 1,
  })
  .withAutomaticReconnect()
  .build()

const startConnection = async () => {
  try {
    if (connection.state !== 'Connected') {
      await connection
        .start()
        .then(() => {
          //try some stuff here :)
          //addMessageReceivedListener(cardId, callback)
        })
        .catch(function (err) {
          //failed to connect
          return console.error(err.toString())
        })
    } else {
      //addMessageReceivedListener(cardId, callback)
    }
  } catch (err) {
    console.error('Error starting SignalR connection:', err)
  }
}

const addMessageReceivedListener = (cardId, callback) => {
  connection.on('ReceiveMessage', message => {
    callback(message)
  })
}
const addCourseCreationMessageReceivedListener = callback => {
  connection.on('ReceiveMessage', message => {
    callback(message)
  })
}

export {
  startConnection,
  addMessageReceivedListener,
  addCourseCreationMessageReceivedListener,
}
