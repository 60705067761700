import { FormItemTeamSelect } from './form-item'
import { guid } from '../../../../utils/guid'

const Filters = {
  addTitle: null,
  editTitle: null,
  groups: [
    {
      id: guid().uid(),
      label: null,
      collapsible: false,
      expanded: true,
      formItems: [{ ...FormItemTeamSelect }],
    },
  ],
  formItemsSuggestion: [],
}

export default Filters
