export const UserTypeNames = {
  REGULAR_ADMIN_ACCOUNT: 'Regular Admin Account',
  PREMIUM: 'Premium',
  APPSUMO_TIER_1: 'Appsumo (Tier 1)',
  APPSUMO_TIER_2: 'Appsumo (Tier 2)',
  PREMIUM_ADMIN_ACCOUNT_QUARTERLY: 'Premium Admin Account (Quarterly)',
  APPSUMO_TIER_3: 'Appsumo (Tier 3)',
  APPSUMO_TIER_1A: 'Appsumo (Tier 1A)',
  APPSUMO_TIER_2A: 'Appsumo (Tier 2A)',
  APPSUMO_TIER_3A: 'Appsumo (Tier 3A)',
  STARTER_LEGACY: 'Starter - Legacy',
  STARTER: 'Starter',
  STARTER_A: 'Starter - A',
  PERMIUM_A: 'Premium - A',
  PREMIUM_PLUS: 'Premium Plus',
  PREMIUM_V2: 'Premium v2',
  PREMIUM_PLUS_V2: 'Premium Plus v2',
  APPSUMO_TIER_4: 'Appsumo (Tier 4)',
}

export default class UserType {
  static 1 = UserTypeNames.REGULAR_ADMIN_ACCOUNT
  static 2 = UserTypeNames.PREMIUM
  static 3 = 'Super Admin'
  static 4 = UserTypeNames.APPSUMO_TIER_1
  static 5 = UserTypeNames.APPSUMO_TIER_2
  static 6 = UserTypeNames.PREMIUM_ADMIN_ACCOUNT_QUARTERLY
  static 7 = UserTypeNames.APPSUMO_TIER_3
  static 8 = UserTypeNames.APPSUMO_TIER_1A
  static 9 = UserTypeNames.APPSUMO_TIER_2A
  static 10 = UserTypeNames.APPSUMO_TIER_3A
  static 11 = UserTypeNames.STARTER_LEGACY
  static 12 = UserTypeNames.STARTER
  static 13 = UserTypeNames.STARTER_A
  static 14 = UserTypeNames.PERMIUM_A
  static 15 = UserTypeNames.PREMIUM_PLUS
  static 16 = UserTypeNames.PREMIUM_V2
  static 17 = UserTypeNames.PREMIUM_PLUS_V2
  static 18 = UserTypeNames.APPSUMO_TIER_4
}
