import grey from '@material-ui/core/colors/grey'

const styles = theme => ({
  main: {
    //paddingBottom: '1rem',
  },
  childGrid: {
    marginBottom: theme.spacing(1.5),
    background: 'url(/assets/dummy-image-background.jpg)',
    height: 200,
    flexBasis: 'unset',
    justifyContent: 'center',
    alignItems: 'center',
  },

  button: {
    color: '#f67b3f',
    marginLeft: 34,
    width: '50.5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#b75f0d14',
    },
  },
  linkBox: {
    margin: '0 auto',
    padding: theme.spacing(1),
    paddingLeft: 120,
    textAlign: 'center',
  },
  addBox: {
    width: 160,
    margin: '0 auto',
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  checkboxIcon: {
    color: grey[400],
  },
  checkbox: {
    float: 'left',
  },
  deleteButton: {
    color: '#637381',
    marginLeft: 8,
  },
  container: {
    borderRadius: 10,
    marginBottom: 8,
    display: 'flex',
    gridTemplate: 'auto / 32px calc(100% - 64px) 32px',
    '&:hover button': {
      display: 'block',
    },
  },
  containerNoGrid: {
    border: '1px solid',
    borderRadius: 10,
    marginBottom: 8,
    display: 'flex',
    '&:hover button': {
      display: 'block',
    },
  },
  noBorder: {
    //border: 'none',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grid: { padding: 4, paddingBottom: '12px' },
  input: {
    fontSize: 14,
    color: '#637381',
  },
  alert: {
    marginBottom: '8px',
  },
  root: {
    '& .MuiFilledInput-root': {
      background: 'rgb(232, 241, 250)',
    },
  },
})

export default styles
