import React from 'react'
import { useDispatch } from 'react-redux'
import Page from '../../components/Page'
import style from './style'
// material
import { styled } from '@material-ui/core/styles'
import { Container, withStyles } from '@material-ui/core'
import Error from './error'

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10),
}))

// ----------------------------------------------------------------------

function Pricing(props) {
  const dispatch = useDispatch()
  const { classes, history } = props

  return (
    <RootStyle title="Pricing">
      <Container maxWidth="lg">
        <Error history={history} dispatch={dispatch} />
      </Container>
    </RootStyle>
  )
}

export default withStyles(style)(Pricing)
