import React from 'react'
import styles from './style'
import propTypes from './prop'
import translator from '../../../../translate'
import { Grid, withStyles, Button, Alert } from '@material-ui/core'
import Selection from './selection'
import { questionSelectionAddAction } from '../../../../reducers/question/action'
import AddIcon from '@material-ui/icons/Add'

import {
  QuestionFieldNames,
  SelectionFieldNames,
  AnswerItemType,
} from '../../../../model/question'
import BulkImportContainer from './bulk-import-option'

import { componentBoxShowAction } from '../../../../reducers/component-box/action'
import ComponentBoxContent from '../../../../model/component-box'
const QuestionTestingSingleChoice = props => {
  const { classes, entity, dispatch } = props

  /**
   *
   */
  const addButtonClickHandler = () => {
    let object = {}
    object[SelectionFieldNames.QUESTION_ID] = entity[QuestionFieldNames.ID]
    object[SelectionFieldNames.STEXT] = ''
    object[SelectionFieldNames.ISCORRECT] = false
    dispatch(questionSelectionAddAction(object, () => {}))
  }

  /**
   *
   */
  const importOptionButtonClickHandler = () => {
    const { dispatch } = props

    let defaultOptions = ''
    if (!entity[QuestionFieldNames.IS_ANSWERED]) {
      entity[QuestionFieldNames.SELECTIONS].forEach((element, i) => {
        if (i != 0) {
          defaultOptions =
            defaultOptions + '\n' + element[SelectionFieldNames.STEXT]
        } else {
          defaultOptions = element[SelectionFieldNames.STEXT]
        }
      })
    }

    const component = (
      <BulkImportContainer
        entity={entity}
        dispatch={dispatch}
        defaultOptions={defaultOptions}
      />
    )

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, {
          hideCloseButton: false,
          callback: e => {},
        }),
      ),
    )
  }

  /**
   *
   */
  const options = () => {
    let item = entity[QuestionFieldNames.SELECTIONS].map((selection, index) => {
      return (
        <Selection
          selection={selection}
          key={selection.id}
          entity={entity}
          index={index}
          dispatch={dispatch}
        />
      )
    })

    return item
  }
  return (
    <div>
      <div className={classes.main}>{options()}</div>

      <Grid container>
        <Grid item style={{ width: '100%' }} className={classes.grid}>
          <Button
            onClick={addButtonClickHandler}
            className={classes.button}
            size="small"
            fullWidth
            startIcon={<AddIcon />}
          >
            {translator._('taskItem.addQuestion.answerType.addOption')}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

QuestionTestingSingleChoice.propTypes = propTypes

export default withStyles(styles)(QuestionTestingSingleChoice)
