import React from 'react'
import style from './style'
import propTypes from './prop'
import List from '../../'
import { ListUseModeType } from '../../../../model/list'
import { SortableContainer } from 'react-sortable-hoc'
import BodyListItem from './body-list-item'
import { withStyles, TableRow, TableBody, TableCell } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const Body = props => {
  const {
    classes,
    queryResult,
    row,
    selectedList,
    changeSelected,
    changeExpanded,
    dispatch,
    refresh,
    childList,
    parentList,
    expandedId,
    showChildList,
    showSelect,
    showChildSelect,
    showDragDrop,
    onDragEnd,
    usageMode,
    isChildFilterSameToParent,
    showRowAction,
    handleRowClick,
    anchorPosition,
    handleRowMenuClose,
    hoverActionEnabled,
  } = props

  const SortableList = SortableContainer(props => {
    const { children } = props
    return <TableBody className={classes.tableBody}>{children}</TableBody>
  })

  const onDrag = result => {
    onDragEnd(result, queryResult.results, dispatch)
  }

  return (
    <SortableList axis="xy" onSortEnd={onDrag} useDragHandle={showDragDrop}>
      {queryResult.results.map((entity, index) => (
        <React.Fragment key={index}>
          <BodyListItem
            classes={classes}
            entity={entity}
            dispatch={dispatch}
            index={index}
            showDragDrop={showDragDrop}
            refresh={refresh}
            row={row}
            selectedList={selectedList}
            anchorPosition={anchorPosition}
            handleRowClick={handleRowClick}
            handleRowMenuClose={handleRowMenuClose}
            showChildList={showChildList}
            changeExpanded={changeExpanded}
            showSelect={showSelect}
            changeSelected={changeSelected}
            expandedId={expandedId}
            showRowAction={showRowAction}
            hoverActionEnabled={hoverActionEnabled}
          />
          {expandedId === entity.id && (
            <TableRow>
              <TableCell
                colSpan={2 + row.columns.length}
                className={classes.childList}
              >
                <List
                  list={childList()}
                  parentList={parentList}
                  initialData={entity}
                  disabled={false}
                  queryResult={entity.rowChildList}
                  dispatch={dispatch}
                  hideTopBar={true}
                  hideHeaderTitle={true}
                  showSelect={showChildSelect}
                  showRowAction={showRowAction}
                  showChildSelect={showChildSelect}
                  showPaging={false}
                  showChildList={true}
                  usageMode={ListUseModeType.LIST}
                  changeSelected={changeSelected}
                  selectedList={selectedList}
                  isChildFilterSameToParent={isChildFilterSameToParent}
                />
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
    </SortableList>
  )
}

Body.propTypes = propTypes

export default withStyles(style)(Body)
