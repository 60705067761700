import React, { Component } from 'react'
import propTypes from './prop'
import styles from './styles'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import translator from 'src/translate'

class Welcome1 extends Component {
  /**
   *
   */
  render() {
    const { classes } = this.props

    return (
      <Grid
        container
        className={classes.centerBoxGrid}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={12} alignItems="center" alignContent="center">
          <Box mt={2} />
        </Grid>

        <div>
          <Accordion square expanded={true}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography variant="h2" className={classes.panelTitle}>
                {translator._('assignment.downloadRootie')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                variant="column"
                className={classes.centerBoxGrid}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item md={12}>
                  <Box m={1}></Box>
                  <Grid
                    container
                    variant="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item md={6}>
                      <a
                        href="https://itunes.apple.com/us/app/rootie-kurumsal-%C3%B6%C4%9Frenme/id1307561630?mt=8"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        <img
                          src="/assets/app-store-badge.png"
                          alt="app store"
                        ></img>
                      </a>
                    </Grid>
                    <Grid item md={6}>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.rootie.android"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        <img
                          src="/assets/google-play-badge.png"
                          alt="google play"
                        ></img>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
    )
  }
}

Welcome1.propTypes = propTypes

export default withStyles(styles)(Welcome1)
