import React from 'react'
import style from './style'
import translator from '../../../../translate'
import {
  Checkbox,
  Typography,
  withStyles,

} from '@material-ui/core'

const HeaderFlex = props => {
  const {
    classes,
    selectedCount,
    totalCount,
    selectAllChecked,
    changeAllSelect,

  } = props

  return (
    <div className={classes.container}>
      <div className={classes.checkBoxGroup}>
        <Checkbox
          checked={selectAllChecked}
          onChange={changeAllSelect}
          color="secondary"
        />
        <Typography
          variant="subtitle2"
          className={classes.text}
        >
          {translator._('assignment.selectAll')}
        </Typography>

      </div>
      {
        <Typography
          variant="body2"
          className={classes.text}
        >
          {selectedCount + '/' + totalCount + ' ' + translator._('assignment.selected')}
        </Typography>
      }
    </div>
  )
}

export default withStyles(style)(HeaderFlex)
