const DOMAIN_LIST = 'DOMAIN_LIST'
const DOMAIN_LIST_SUCCESS = 'DOMAIN_LIST_SUCCESS'
const DOMAIN_LIST_ERROR = 'DOMAIN_LIST_ERROR'

const DOMAIN_ADD = 'DOMAIN_ADD'
const DOMAIN_ADD_SUCCESS = 'DOMAIN_ADD_SUCCESS'
const DOMAIN_ADD_ERROR = 'DOMAIN_ADD_ERROR'

const DOMAIN_DELETE = 'DELETE_DOMAIN'
const DOMAIN_DELETE_SUCCESS = 'DELETE_DOMAIN_SUCCESS'
const DOMAIN_DELETE_ERROR = 'DELETE_DOMAIN_ERROR'

const DOMAIN_RETRY = 'RETRY_DOMAIN'
const DOMAIN_RETRY_SUCCESS = 'RETRY_DOMAIN_SUCCESS'
const DOMAIN_RETRY_ERROR = 'RETRY_DOMAIN_ERROR'

const PATCH_DOMAIN = 'PATCH_DOMAIN'
const PATCH_DOMAIN_SUCCESS = 'PATCH_DOMAIN_SUCCESS'
const PATCH_DOMAIN_ERROR = 'PATCH_DOMAIN_ERROR'

const ADD_CUSTOM_DOMAIN = 'ADD_CUSTOM_DOMAIN'
const ADD_CUSTOM_DOMAIN_SUCCESS = 'ADD_CUSTOM_DOMAIN_SUCCESS'
const ADD_CUSTOM_DOMAIN_ERROR = 'ADD_CUSTOM_DOMAIN_ERROR'
export {
  DOMAIN_LIST,
  DOMAIN_LIST_SUCCESS,
  DOMAIN_LIST_ERROR,
  DOMAIN_ADD,
  DOMAIN_ADD_SUCCESS,
  DOMAIN_ADD_ERROR,
  DOMAIN_DELETE,
  DOMAIN_DELETE_SUCCESS,
  DOMAIN_DELETE_ERROR,
  DOMAIN_RETRY,
  DOMAIN_RETRY_SUCCESS,
  DOMAIN_RETRY_ERROR,
  PATCH_DOMAIN,
  PATCH_DOMAIN_SUCCESS,
  PATCH_DOMAIN_ERROR,
  ADD_CUSTOM_DOMAIN,
  ADD_CUSTOM_DOMAIN_SUCCESS,
  ADD_CUSTOM_DOMAIN_ERROR,
}
