import translator from 'src/translate'
import { withStyles } from '@material-ui/core/styles'
import React, { useState, useRef } from 'react'
import styles from '../../styles'
import { Typography, TextField, Button } from '@material-ui/core'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import URL from 'src/api/url/api'
import DeleteIcon from '@material-ui/icons/Delete'

const CollectionPage = props => {
  const {
    classes,
    thumbnailUrl,
    onThumbnailUpload,
    setThumbnailUrl,
    assignmentDescription,
    handleTextFieldChangeDescription,
  } = props

  return (
    <div>
      <div
        style={{
          borderBottom: 'solid 1px #ededed',
          marginBottom: '16px',
          height: '32px',
        }}
      >
        <Typography
          variant="subtitle2"
          style={{ marginTop: '4px', marginBottom: '4px' }}
        >
          Info for Collection Page
        </Typography>
      </div>
      <div
        className={classes.root__section}
        style={{
          justifyContent: 'flex-start',
          gap: '0.7rem',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <div className={classes.image__bg}>
          {!thumbnailUrl && (
            <div className={classes.image__icon}>
              <AddPhotoAlternateIcon
                fontSize="inherit"
                style={{ fontSize: '2rem' }}
              />
              <span className={classes.image__span}>Add Thumbnail</span>
            </div>
          )}
          <div className={classes.image__content}>
            {thumbnailUrl && (
              <img
                className={classes.image__img}
                alt=""
                src={`${URL.MEDIA}` + thumbnailUrl}
              />
            )}
          </div>
          {thumbnailUrl && (
            <div
              className={classes.image__remove}
              onClick={() => setThumbnailUrl()}
            >
              <DeleteIcon fontSize="medium" />
            </div>
          )}

          {!thumbnailUrl && (
            <input
              type="file"
              className={classes.file__upload}
              onChange={onThumbnailUpload}
            />
          )}
        </div>
        <span className={classes.span__extension}>.png, .jpg, .gif</span>
      </div>
      <img
        src="/assets/collection-page-layout.png"
        width="100%"
        style={{ marginBottom: '64px' }}
      />
    </div>
  )
}

export default withStyles(styles)(CollectionPage)
