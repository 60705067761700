import URL from '../url'
import Request from '../request'

export const integrationRerouteService = () =>
  new Request(URL.API, URL.TOKEN_REROUTE).get()

export const integrationValidateTokenService = token =>
  new Request(URL.API, URL.VALIDATE_TOKEN).get({ token })

export const integrationValidateShortLinkService = shortLink =>
  new Request(URL.API, URL.VALIDATE_SHORT_LINK).post({ shortId: shortLink })
