export const sortByKey = (array, key, asc = true) => {
  if (asc == true) {
    return array.sort(function (a, b) {
      var x = a[key]
      var y = b[key]
      return x < y ? -1 : x > y ? 1 : 0
    })
  } else {
    return array.sort(function (a, b) {
      var x = a[key]
      var y = b[key]
      return x < y ? 1 : x > y ? -1 : 0
    })
  }
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}