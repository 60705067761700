import { FormItemPropTypes } from '../form-item/default.js'
import PropTypes from 'prop-types'

const FormItemTeamAutoCompletePropTypes = {
  ...FormItemPropTypes,
  list: PropTypes.array,
  add: PropTypes.func,
  remove: PropTypes.func,
}

export default FormItemTeamAutoCompletePropTypes
