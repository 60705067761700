import { put, call, take, fork } from 'redux-saga/effects'
import { domainListService } from '../../api/domain'
import { domainListSuccess } from '../../reducers/domain/action'
import { DOMAIN_LIST } from '../../reducers/domain/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* domainListFlow(payload) {
  try {
    const { query, callback } = payload
    const response = yield call(domainListService, query)
    if (
      response.data.results.length === 0 &&
      query.FilterText === '' &&
      query.Filters === undefined
    ) {
      response.data.results = null
    }
    yield put(domainListSuccess(response.data))
    yield put(operationSuccess())
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* domainListWatcher() {
  while (true) {
    const action = yield take(DOMAIN_LIST)
    const { payload } = action
    yield fork(generalSaga, domainListFlow, payload)
  }
}
