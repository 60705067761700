const style = theme => ({
  searchBar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '17px',
    width : "100%"
  },
  button: {
    width: '20%',
  },
  content: {
    height: 'calc(100% - 110px)',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    maxWidth: 600,
    justifyContent: "center"

  },
  imageList: {
    padding: 2,
    alignContent: 'flex-start',
    overflow: 'hidden',
  },
  imageItem: {
    marginBottom: 5,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  unsplashPanel:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "90%",
    maxWidth:550,
    margin: "0 auto",
    flexDirection: "column"

  }
})

export default style
