import { withStyles } from '@material-ui/core'
import { Icon } from '@iconify/react'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import translator from 'src/translate'
import { Button, Tooltip } from '@material-ui/core'
import {
  QuestionFieldNames,
  isAnswerItemTypeResultCard,
} from 'src/model/question'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import ModalContent from 'src/components/card-item/card-settings'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import ContainerMediaGalery from 'src/container/media-galery'
import { TranslateCardTypes } from 'src/model/question'
import TranslateIcon from '@material-ui/icons/Translate'
import SertifierModal from '../sertifier'
import { guid } from 'src/utils/guid'

import styles from '../style'

import React, { useState } from 'react'

const ButtonGroup = props => {
  const {
    canSeeNext,
    canSeeBack,
    handleBackCard,
    handleNextCard,
    cardCount,
    onDeleteClick,
    entity,
    dispatch,
    isLastCardFinishCard,
    imageURL,
    setImageURL,
    onOk,
    onRemove,
    classes,
  } = props
  const [questionType, setQuestionType] = useState(
    entity[QuestionFieldNames.QUESTION_TYPE],
  )
  const [cachedModal, setCachedModal] = React.useState(null)

  const settingsCloseHandler = () => {
    dispatch(componentBoxHideAction(cachedModal))
  }
  const hasMedia = entity[QuestionFieldNames.QUESTION_MEDIAS].length > 0
  const settingsClickHandler = () => {
    let modal = new ComponentBoxContent(
      (
        <ModalContent
          cardId={entity.id}
          {...props}
          key={guid.uid}
          closeThisModal={settingsCloseHandler}
        />
      ),
      {
        hideCloseButton: false,
        header: translator._('action.settings'),
        callback: e => {},
      },
    )
    setCachedModal(modal)
    dispatch(componentBoxShowAction(modal))
  }

  const settingsSertifierClickHandler = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <SertifierModal
              classes={classes}
              cardId={entity.id}
              key={guid.uid}
            />
          ),
          {
            hideCloseButton: false,
            header: translator._('taskItem.sertifier.header'),
            callback: e => {},
          },
        ),
      ),
    )
  }

  const imageButtonClickHandler = () => {
    const { dispatch } = props

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <ContainerMediaGalery
              setImageURL={setImageURL}
              onOk={onOk}
              onRemove={onRemove}
              cardEntity={entity}
            />
          ),
          {
            hideCloseButton: false,
          },
        ),
      ),
    )
  }
  return (
    <div>
      <div>
        {!hasMedia && (
          <Tooltip title="Add Image" placement="bottom">
            <span>
              <Button
                style={{
                  color: '#F67B3F',
                }}
                size="small"
                className={classes.buttonGroup}
                onClick={() => imageButtonClickHandler()}
              >
                <img
                  src="/assets/qc-ai-image-icon.svg"
                  style={{ width: '18px', height: '18px' }}
                />
              </Button>
            </span>
          </Tooltip>
        )}
        {questionType === 13 && (
          <Tooltip title="Translate" placement="bottom">
            <Button
              style={{
                color: '#F67B3F',
              }}
              size="small"
              className={classes.buttonGroup}
              onClick={() => settingsClickHandler()}
            >
              <Icon
                icon="material-symbols:translate"
                width="18"
                height="19px"
                className={classes.icon}
              />
            </Button>
          </Tooltip>
        )}

        {!(
          questionType === 1 ||
          isAnswerItemTypeResultCard(questionType) ||
          questionType === 15
        ) && (
          <Tooltip title={translator._('action.settings')} placement="bottom">
            <span>
              <Button
                style={{
                  color: '#F67B3F',
                }}
                size="small"
                className={classes.buttonGroup}
                onClick={() =>
                  questionType == 13
                    ? settingsSertifierClickHandler()
                    : settingsClickHandler()
                }
              >
                <Icon
                  icon="ion:settings-outline"
                  width="18"
                  height="19px"
                  className={classes.icon}
                />
              </Button>
            </span>
          </Tooltip>
        )}

        {cardCount > 1 && (
          <Tooltip title={translator._('action.delete')} placement="bottom">
            <span>
              <Button
                className={classes.buttonGroup}
                style={{ color: '#F67B3F' }}
                size="small"
                onClick={onDeleteClick}
              >
                <Icon
                  icon="bx:trash-alt"
                  width="18"
                  height="19px"
                  className={classes.icon}
                />
              </Button>
            </span>
          </Tooltip>
        )}
        {!isLastCardFinishCard && questionType !== 15 && (
          <>
            <Tooltip title={translator._('action.changeOrder')}>
              <span>
                <Button
                  className={classes.buttonGroupUpDown}
                  style={{
                    color: canSeeBack ? '#F67B3F' : '#919eabcc',
                    minWidth: '24px',
                  }}
                  size="small"
                  onClick={handleBackCard}
                  disabled={!canSeeBack}
                >
                  <KeyboardArrowUpIcon
                    className={classes.icon}
                    fontSize="medium"
                  />
                </Button>
              </span>
            </Tooltip>

            <Tooltip title={translator._('action.changeOrder')}>
              <span>
                <Button
                  style={{
                    color: canSeeNext ? '#F67B3F' : '#919eabcc',
                    marginRight: 12,
                  }}
                  className={classes.buttonGroupUpDown}
                  size="small"
                  onClick={handleNextCard}
                  disabled={!canSeeNext}
                >
                  <KeyboardArrowDownIcon
                    className={classes.icon}
                    fontSize="medium"
                  />
                </Button>
              </span>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(ButtonGroup)
