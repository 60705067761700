const styles = theme => ({
  root: {
    height: '100%',
  },
  content: {
    maxWidth: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: 150,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  titleItalic: {
    fontSize: '1.2rem',
    fontStyle: 'italic',
    fontWeight: 500,
  },
})

export default styles
