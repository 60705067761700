import React from 'react'
// material
import { Container, withStyles } from '@material-ui/core'

import style from './style'

function HelpCenter(props) {
  const { classes } = props
  React.useEffect(() => {
    window.location.replace('https://minicoursegenerator.com/knowledge-base/')
  }, [])
  return (
    <Container>
     {/*  <iframe
        width="100%"
        height="1500"
        className={classes.iframe}
        title="help-center"
        src="https://minicoursegenerator.com/knowledge-base/"
      ></iframe> */}
    </Container>
  )
}

export default withStyles(style)(HelpCenter)
