const style = theme => ({
  filterListWrapper: {
    padding: theme.spacing(1, 1.5, 1, 1.5),
    maxWidth: 300,
    background: '#eff2f6',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 'min-content',
  },
  filterItemGroup: {
    width: 'auto',
    marginTop: theme.spacing(1),
  },
  filterListTitle: {
    fontWeight: 600,
  },
  filterList: { maxHeight: 220, overflowX: 'hidden' },
})

export default style
