import React, { useState } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import ListAssignments from '../../container/list-assignment'
import translator from '../../translate'
import styles from './styles'
import AddIcon from '@material-ui/icons/Add'
import NamingModal from './naming-modal'
import { ListApplicationType } from 'src/model/list'
import { Box, Button, Typography, withStyles } from '@material-ui/core'
import { AssignmentTypes } from '../../model/assignment'
const Assignments = props => {
  const { dispatch, history, entity, classes } = props

  const [isNamingModalOpen, setNamingModalOpen] = useState(false)

  return (
    <Box style={{ maxWidth: 900, margin: '0 auto' }}>
      <div className={classes.headerRow}>
        <Typography variant="h4">{translator._('assignment.title')}</Typography>
        <Button
          variant="contained"
          color="secondary"
          className={classes.createButton}
          onClick={() => setNamingModalOpen(true)}
          size="medium"
          startIcon={<AddIcon />}
        >
          {translator._('assignment.createAssignment')}
        </Button>
      </div>

      <ListAssignments
        applicationType={ListApplicationType.ASSIGNMENT}
        setSelectionModalOpen={setNamingModalOpen}
      />

      <NamingModal
        classes={classes}
        dispatch={dispatch}
        entity={entity}
        history={history}
        handleCloseModal={() => setNamingModalOpen(false)}
        isModalOpen={isNamingModalOpen}
        selectedAssignmentType={AssignmentTypes.ONETIME}
      />
    </Box>
  )
}

Assignments.propTypes = propTypes

const mapStateToProps = response => ({
  entity: response.assignment.entity,
})

export default connect(mapStateToProps)(withStyles(styles)(Assignments))
