import { put, call, take, fork } from 'redux-saga/effects'
import { sendCreatePromptFromCardContentRequestService } from '../../api/assignment'
import { createPromptFromCardSuccess } from '../../reducers/assignment/action'
import { CREATE_PROMPT_FROM_CARD } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* sendCreatePromptFromCardContentRequestFlow(payload) {
  try {
    const { starterText, callback } = payload
    const response = yield call(
      sendCreatePromptFromCardContentRequestService,
      starterText,
    )
    yield put(createPromptFromCardSuccess(starterText))
    yield put(operationSuccess())

    callback(response)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* sendCreatePromptFromCardContentRequestWatcher() {
  while (true) {
    const action = yield take(CREATE_PROMPT_FROM_CARD)
    const { payload } = action

    yield fork(generalSaga, sendCreatePromptFromCardContentRequestFlow, payload)
  }
}
