import React, { useEffect, useState } from 'react'
// material
import {
  Container,
  Typography,
  Card,
  CardContent,
  Alert,
  withStyles,
  AlertTitle,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Input,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { connect } from 'react-redux'

import style from './style'
import { saveDomainService } from '../../api/domain/index'
import ListDomain from '../../container/list-domain'
import { Link as RouterLink } from 'react-router-dom'
import heartOutline from '@iconify-icons/eva/heart-outline'
import { Icon } from '@iconify/react'
import { domainListAction } from '../../reducers/domain/action'
import translator from 'src/translate'
import { courseCollectionListAction } from 'src/reducers/course-collection/action'

import URL from 'src/api/url'

import { track } from '@amplitude/analytics-browser'
import UserType, { UserTypeNames } from 'src/model/user-types'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function getSteps() {
  return ['Add following CNAME to your DNS Records', 'Add Domain']
}

function CustomDomain(props) {
  const { dispatch, classes, selectedItem } = props

  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [domain, setDomain] = useState(null)
  const [verified, setVerified] = useState(true)
  const [error, setError] = useState(false)
  const [courses, setCourses] = useState()
  const [collections, setCollections] = useState()
  const [courseName, setCourseName] = useState()
  const [courseId, setCourseId] = useState()
  const [collectionName, setCollectionName] = useState()
  const [collectionId, setCollectionId] = useState()
  const [type, setType] = useState('Course')
  const [visible, setVisible] = useState('hidden')
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleStep = step => () => {
    setActiveStep(step)
  }

  const isUserPremium = UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.REGULAR_ADMIN_ACCOUNT && UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1 && UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1A


  /**
   *
   * @param {*} e
   */
  const handleCourse = e => {
    setCourseName(e.target.value)
    setCourseId(e.target.value)
  }
  const handleCollection = e => {
    setCollectionName(e.target.value)
    setCollectionId(e.target.value)
  }
  const handleType = e => {
    setType(e.target.value)
  }
  const handleAddDomain = async () => {
    setError(false)
    setLoading(true)
    type == 'Course'
      ? await saveDomainService(domain, null, courseId, type)
      : await saveDomainService(domain, collectionId, null, type)
    setLoading(false)
    setDomain('')
    setActiveStep(0)
    dispatch(
      domainListAction({
        CurrentPage: 0,
        PageSize: 5,
        SortingColumn: 'createdAt',
        Asc: false,
        FilterText: '',
        Filters: [],
      }),
    )
  }
  useEffect(() => {
    if (type != '') setVisible('visible')
    else if (type == '') setVisible('hidden')
  }, [type])
  useEffect(() => {
    if (!courses && !collections) {
      const query = {
        CurrentPage: 0,
        PageSize: 200,
        SortingColumn: 'LastModifiedAt',
        Asc: false,
        FilterText: '',
        Filters: [],
      }
      dispatch(
        courseCollectionListAction(
          { ...query, SortingColumn: 'LastModifiedAt' },
          data => {
            setCourses(data.courseDtos)
            setCollections(data.courseCollectionDtos)
          },
        ),
      )
    }
  }, [])
  const onClickUpgrade = () => {
    track('limit_warning_upgrade', {
      from: 'cname_limit_upgrade',
    })
  }
  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <Box m={2}></Box>
            <p>
              {translator._('customDomain.detailedInformation')}
              {'  '}
              <a
                href={`https://share.minicoursegenerator.com/how-to-add-custom-domain-638315026803824550/`}
                target="_blank"
              >
                {translator._('customDomain.thisLink')}
              </a>
              {'.'}
            </p>
            <Box m={2}></Box>

            <Typography variant="subtitle2">
              {translator._('customDomain.cName')}
            </Typography>
          </React.Fragment>
        )

      case 1:
        return (
          <Box autoComplete="off">
            <Box m={2}></Box>
            <TextField
              label="Domain"
              placeholder="Exp: app.yourdomain.com"
              className={classes.textField}
              onChange={e => {
                setDomain(e.target.value)
              }}
              value={domain}
            />
            <Box m={2}></Box>
           

            {type && type == 'Course' && (
              <FormControl fullWidth={true} variant="outlined">
                <InputLabel id="demo-simple-select-helper-label">
                  {translator._('customDomain.chooseCourse')}
                </InputLabel>
                <Select
                  classes={{ select: classes.combo }}
                  inputProps={{
                    className: classes.input,
                  }}
                  fullWidth
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Choose your quiz"
                  key={courseId}
                  value={courseName}
                  disabled={false}
                  input={<Input id="select-input" name="select-input" />}
                  onChange={handleCourse}
                >
                  {courses &&
                    courses.map(value => {
                      return (
                        <MenuItem value={value.id} key={value.name}>
                          {value.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            )}
          

            {!verified && (
              <Alert severity="error">
                <AlertTitle>{translator._('customDomain.addCname')}</AlertTitle>
                {translator._('customDomain.cName')}
              </Alert>
            )}
            {error && (
              <Alert severity="error">
                <AlertTitle>
                  {translator._('customDomain.alertTitle')}
                </AlertTitle>
                {translator._('customDomain.alertMessage')}
              </Alert>
            )}
            {loading && <CircularProgress />}
            {!loading && (
              <Button
                variant="contained"
                color="primary"
                className={classes.next}
                onClick={() => handleAddDomain()}
                disabled={!(domain && (courseId || collectionId))}
              >
                {translator._('customDomain.addDomain')}
              </Button>
            )}
          </Box>
        )

      default:
        return 'Unknown step'
    }
  }

  return (
    <Container>
      {isUserPremium && (
        <React.Fragment>
          <Typography variant="h3" align="center">
            {translator._('customDomain.customDomain')}
          </Typography>
          <Typography align="center" gutterBottom>
            {translator._('customDomain.addCustomDomain')}
          </Typography>
          <Card className={classes.card}>
            <CardContent>
              <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel onClick={handleStep(index)}>{label}</StepLabel>
                    <StepContent>
                      {getStepContent(index)}
                      {activeStep == 0 && (
                        <div className={classes.actionsContainer}>
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              className={classes.next}
                            >
                              {activeStep === steps.length - 1
                                ? 'Finish'
                                : 'Next'}
                            </Button>
                          </div>
                        </div>
                      )}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </CardContent>
          </Card>
          <Box m={6}></Box>
          <div className={classes.card}>
            <ListDomain></ListDomain>
          </div>{' '}
        </React.Fragment>
      )}

      {!isUserPremium && (
        <React.Fragment>
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.limitModalImage}>
                <img src="/assets/popup_girl.png" width="160px" />
              </div>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                marginBottom="20px"
              >
                Would you like to customize your links?
              </Typography>
              <Typography align="center" gutterBottom>
                With a higher plan, you will be able to connect several custom
                domains <br /> and offer your quizzes and catagories with your
                own link!
                <Box m={3}></Box>
                <Button
                  to="/pricing"
                  sx={{
                    mr: 1,
                    color: 'text.primary',
                  }}
                  variant="outlined"
                  startIcon={<Icon icon={heartOutline} />}
                  component={RouterLink}
                  onClick={() => onClickUpgrade()}
                >
                  See Plans and Upgrade
                </Button>
              </Typography>
            </CardContent>
          </Card>
        </React.Fragment>
      )}
    </Container>
  )
}

export default connect()(withStyles(style)(CustomDomain))
