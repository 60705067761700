const styles = theme => ({
  form: {
    width: '576px',
  },
  editForm: {
    width: '100%',
    margin: '0 auto',
    paddingTop: '1rem',
    paddingBottom: '2rem',
  },
  box: {
    width: '100%',
    display: 'grid',
    gridTemplate: 'auto / 40% 20% 15%',
    marginBottom: '.7rem',
    justifyContent: 'space-between',
  },
  marginBottom: {
    marginBottom: '.7rem',
  },
  marginTop: {
    marginTop: '.7rem',
  },
  button: {
    marginTop: '.7rem',
    border: 'none',
    width: 'fit-content',
    alignSelf: 'flex-end',
    '&:hover': {
      border: 'none',
    },
  },
  noPadding: {
    margin: '0!important',
    padding: '0!important',
  },
  fullWidth: {
    width: '100%',
  },
  accordionDetails: {
    overflow: 'auto',
    maxHeight: '35vh',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  conditionSelect: {
    width: '90%',
    margin: '0 auto',
  },
  conditionTypeSelect: {
    width: '100%',
    margin: '0 auto',
  },
  buttonGroup: {
    display: 'flex',
    width: '100%',
  },
  conditionMessage: {
    fontSize: '.8rem',
    color: '6e6e6e',
  },
  helperText: {
    color: '#637381',
  },
})

export default styles
