import { SET_THEME_MODE, SET_THEME_DIRECTION } from './type'

export default function (
  state = {
    themeMode: 'light',
    themeDirection: 'ltr'
  },
  action
) {
  const { payload, type } = action
  switch (type) {
    case SET_THEME_MODE:
      return { ...state, themeMode: payload.themeMode }
    case SET_THEME_DIRECTION:
      return { ...state, themeDirection: payload.themeDirection }
    default:
      return state
  }
}
