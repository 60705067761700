import PropTypes from 'prop-types'
import { ListUseModeTypes, ListItemViewTypes } from '../../model/list'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  list: PropTypes.object,
  usageMode: PropTypes.oneOf(ListUseModeTypes),
  showDetailSearch: PropTypes.bool,
  showPaging: PropTypes.bool,
  showSelect: PropTypes.bool,
  showChildList: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  listItemViewType: PropTypes.oneOf(ListItemViewTypes),
}

export default propTypes
