/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import translator from 'src/translate'
import { assignmentAddAction } from 'src/reducers/assignment/action'
import {
  AssignmentFieldNames,
  AssignmentSchema,
  AssignmentTypes,
} from 'src/model/assignment'
import { assignmentPatchAction } from 'src/reducers/assignment/action'
import { PatchContent, PatchData } from 'src/model/patch'
import {
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
  withStyles,
  OutlinedInput,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from '../styles'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import URL from 'src/api/url/api'
import { DEFAULT_COLLECTION_GUID } from 'src/pages/list-courses'
import { track } from '@amplitude/analytics-browser'

const NamingModal = props => {
  const {
    courses,
    collectionId,
    dispatch,
    history,
    componentBoxHide,
    courseEntity,
    classes,
  } = props

  const [assignmentTitle, setAssignmentTitle] = useState(
    courseEntity ? courseEntity.name : '',
  )
  const [assignmentDescription, setAssignmentDescription] = useState(
    courseEntity?.description || '',
  )

  const handleCreateButton = () => {
    const courseCollectionOrder = courses ? courses.length : 0
    dispatch(
      assignmentAddAction(
        {
          ...AssignmentSchema(),
          [AssignmentFieldNames.NAME]: assignmentTitle,
          CourseCollectionId: collectionId,
          [AssignmentFieldNames.DESCRIPTION]: assignmentDescription,
          CourseCollectionOrder: courseCollectionOrder,
        },
        history,
        entity => {
          track('Course Created', {
            courseId: entity.id,
          })
          componentBoxHide()
          history.push('/quiz/' + entity.id)
        },
      ),
    )
  }
  const handleSaveCourse = () => {
    const patchSource = new PatchData(
      null,
      courseEntity[AssignmentFieldNames.ID],
    )
    patchSource.addContent(
      new PatchContent(
        assignmentTitle,
        AssignmentFieldNames.NAME,
        PatchData.OPERATION_REPLACE,
      ),
    )
    patchSource.addContent(
      new PatchContent(
        assignmentDescription,
        AssignmentFieldNames.DESCRIPTION,
        PatchData.OPERATION_REPLACE,
      ),
      //TODO: add to selectionPatch!
    )

    dispatch(assignmentPatchAction(patchSource, () => componentBoxHide()))
  }
  const handleTextFieldChange = e => {
    setAssignmentTitle(e.target.value)
  }
  const handleTextFieldChangeDescription = e => {
    setAssignmentDescription(e.target.value)
  }

  const isTextFieldValid = () => {
    if (courseEntity || assignmentTitle.length >= 1) {
      return true
    }
    return false
  }

  return (
    <div className={classes.modal}>
      <div className={classes.namingModalContent}>
        <div className={classes.headerGroup}>
          <Typography variant={'h5'}>Edit Title & Description</Typography>
        </div>

        <div className={classes.headerGroupSecond}>
          <OutlinedInput
            sx={{
              typography: 'h6',
              fontWeight: 'normal',
              width: '100%',
              marginBottom: '10px',
            }}
            defaultValue={assignmentTitle}
            placeholder={translator._('assignment.assignmentName')}
            onChange={handleTextFieldChange}
          />
          <TextField
            style={{ marginBottom: '10px' }}
            fullWidth
            multiline
            maxRows={3}
            minRows={3}
            defaultValue={assignmentDescription}
            placeholder={translator._('assignment.assignmentDescription')}
            onChange={handleTextFieldChangeDescription}
          />
          <Typography>
            This text will appear at the start. You can use it to give a brief
            introduction to the quiz or assessment.
          </Typography>
        </div>
        <Divider />
        <div className={classes.buttonGroup}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => componentBoxHide()}
            className={classes.backButton}
          >
            {translator._('assignment.back')}
          </Button>
          <Button
            variant="contained"
            disabled={isTextFieldValid() ? false : true}
            className={classes.continueButton}
            onClick={courseEntity ? handleSaveCourse : handleCreateButton}
          >
            {translator._(
              courseEntity ? 'assignment.save' : 'assignment.create',
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default withStyles(styles)(NamingModal)
