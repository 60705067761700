import PropTypes from 'prop-types'
import {
  FormItemPropTypes,
  FormItemOptionAttributeName,
} from '../form-item/default.js'

const FormItemSelectPropTypes = {
  ...FormItemPropTypes,
  options: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  optionAttributeName: PropTypes.shape(FormItemOptionAttributeName).isRequired,
  getDataAsync: PropTypes.func,
}

export default FormItemSelectPropTypes
