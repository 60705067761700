import React, { useState, useEffect } from 'react'
import styles from './style'
import {
  withStyles,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import propTypes from './prop'

import {
  useCardSettings,
  patchSetting,
  createSetting,
} from 'src/hooks/useCardSettings'
import Alert from '@material-ui/core/Alert'

import {
  QuestionFieldNames,
  CardSettingsTypes,
  CardSettingBuilder,
} from 'src/model/question'

import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { Icon } from '@iconify/react'
/**
 *
 * @param {*} props
 */
const ProtectionCard = props => {
  const { dispatch, entity, classes } = props

  const cardId = entity && entity.id
  const settingsSchema = CardSettingBuilder(
    entity[QuestionFieldNames.QUESTION_TYPE],
  )
  const cardBaseSettings = useCardSettings(cardId, 'cardSettings')

  const cardSettings = cardBaseSettings
    ? JSON.parse(cardBaseSettings.value)
    : settingsSchema

  const [passwordEmpty, setPasswordEmpty] = useState(
    cardSettings && cardSettings.password != '',
  )
  const [values, setValues] = React.useState({
    password: cardSettings && cardSettings.password,
    showPassword: false,
  })

  useEffect(() => {
    values.password == '' ? setPasswordEmpty(true) : setPasswordEmpty(false)
  }, [values.password])

  const saveAction = newValue => {
    //if (!isUserPremium) return false

    if (values.password != cardSettings.password)
      if (cardBaseSettings == undefined) {
        createSetting(
          dispatch,
          cardId,
          'cardSettings',
          JSON.stringify({
            ...cardSettings,
            password: newValue,
          }),
          () => {},
        )
      } else {
        patchSetting(
          dispatch,
          cardId,
          cardBaseSettings.id,
          'cardSettings',
          JSON.stringify({
            ...cardSettings,
            password: newValue,
          }),
          () => {},
        )
      }
  }
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }
  return (
    <>
      <FormControl
        variant="outlined"
        fullWidth
        style={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          label="Password"
          placeholder="Password"
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          onBlur={e => saveAction(e.target.value)}
          className={classes.input}
          startAdornment={
            <InputAdornment position="start">
              <Icon icon="solar:lock-password-linear" />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? (
                  <Visibility style={{ width: 18, height: 18 }} />
                ) : (
                  <VisibilityOff style={{ width: 18, height: 18 }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {!passwordEmpty ? (
        <div className={classes.alertTexts}>
          <CheckCircleIcon
            style={{
              marginRight: '5px',
              color: '#00C662',
              width: 18,
              height: 18,
            }}
          />
          <Typography fontSize={14} color={'#00C662'}>
            Password is set.{' '}
          </Typography>{' '}
        </div>
      ) : (
        <div className={classes.alertTexts}>
          <InfoIcon
            style={{
              marginRight: '5px',
              color: '#F67B3F',
              width: 18,
              height: 18,
            }}
          />
          <Typography fontSize={14} color={'#F67B3F'}>
            You must enter a password
          </Typography>{' '}
        </div>
      )}
    </>
  )
}

ProtectionCard.propTypes = propTypes

export default withStyles(styles)(ProtectionCard)
