import API from './api'
import AUTHENTICATION from './authentication'
import COMMON from './common'
import TRAINING from './training'
import SUBJECT from './subject'
import TEAM from './team'
import QUESTION from './question'
import AUTOSENDERSETTING from './auto-sender-setting'
import REMINDING_SETTING from './reminding-setting'
import USER from './user'
import CHANGE_PASSWORD from './user-password'
import CALENDAR from './calendar'
import GAMEINFO from './game-info'
import SIGNUP from './sign-up'
import MEDIA_IMAGE from './media-image'
import MEDIA_FOLDER from './media-folder'
import MEDIA_DIRECTORY from './media-directory'
import MEDIA_VIDEO from './media-video'
import SHOP from './shop'
import DASHBOARD from './dashboard'
import LEADERBOARD from './leaderboard'
import INTEGRATION from './integration'
import EXCEL_EXPORT from './excel-export'
import LEARNING_CONTENT from './learning-content'
import USER_MESSAGE from './user-message'
import DOCUMENT from './document'
import REPORT_PARTICIPATION from './report-participation'
import REPORT_ACTIVATION from './report-activation'
import SHOP_MESSAGE from './shop-message'
import ASSIGNMENT from './assignment'
import CUSTOM_DOMAIN from './custom-domain'
import COURSE_STATISTICS from './course-statistics'
import COURSE_TIME_SERIES from './course-time-series'
import APPSUMO_SIGNUP from './appsumo-signup'
import APPSUMO_CODE from './appsumo-code'
import LIMIT from './limit'
import PAYMENT_INTEGRATION from './payment-integration'
import APPSUMO_CODE_REFUND from './appsumo-refund-code'
import COURSE_COLLECTION from './course-collection'
import TEAMS from './teams'

const URL = {
  ...API,
  ...AUTHENTICATION,
  ...COMMON,
  ...TRAINING,
  ...SUBJECT,
  ...TEAM,
  ...QUESTION,
  ...AUTOSENDERSETTING,
  ...REMINDING_SETTING,
  ...USER,
  ...CHANGE_PASSWORD,
  ...CALENDAR,
  ...GAMEINFO,
  ...SIGNUP,
  ...MEDIA_IMAGE,
  ...MEDIA_FOLDER,
  ...MEDIA_DIRECTORY,
  ...MEDIA_VIDEO,
  ...SHOP,
  ...DASHBOARD,
  ...LEADERBOARD,
  ...INTEGRATION,
  ...EXCEL_EXPORT,
  ...LEARNING_CONTENT,
  ...USER_MESSAGE,
  ...DOCUMENT,
  ...REPORT_PARTICIPATION,
  ...REPORT_ACTIVATION,
  ...SHOP_MESSAGE,
  ...ASSIGNMENT,
  ...CUSTOM_DOMAIN,
  ...COURSE_TIME_SERIES,
  ...COURSE_STATISTICS,
  ...APPSUMO_SIGNUP,
  ...APPSUMO_CODE,
  ...LIMIT,
  ...PAYMENT_INTEGRATION,
  ...APPSUMO_CODE_REFUND,
  ...COURSE_COLLECTION,
  ...TEAMS,
}

export default URL
