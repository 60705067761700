const styles = theme => ({
  grid: {
    width: '100%',
    flexBasis: 'unset',
    textAlign: 'center',
    paddingTop: '1rem',
  },
})

export default styles
