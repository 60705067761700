export const TR = {
  general: require('./general/index.json'),
  action: require('./action/index.json'),
  calendar: require('./calendar/index.json'),
  gameInfo: require('./gameInfo/index.json'),
  team: require('./team/index.json'),
  loginPage: require('./loginPage/index.json'),
  signUp: require('./signUp/index.json'),
  verifyEmail: require('./verifyEmail/index.json'),
  user: require('./user/index.json'),
  training: require('./training/index.json'),
  trainingPlan: require('./trainingPlan/index.json'),
  trainingReport: require('./trainingReport/index.json'),
  question: require('./question/index.json'),
  message: require('./message/index.json'),
  list: require('./list/index.json'),
  menu: require('./menu/index.json'),
  remindingSetting: require('./remindingSetting/index.json'),
  autoSender: require('./autoSender/index.json'),
  admin: require('./admin/index.json'),
  form: require('./form/index.json'),
  shop: require('./shop/index.json'),
  subject: require('./subject/index.json'),
  welcome: require('./welcome/index.json'),
  constants: require('./constants/index.json'),
  data: require('./data/index.json'),
  dashboard: require('./dashboard/index.json'),
  mediaGallery: require('./mediaGallery/index.json'),
  leaderboard: require('./leaderboard/index.json'),
  common: require('./common/index.json'),
  integration: require('./integration/index.json'),
  userMessage: require('./userMessage/index.json'),
  setPassword: require('./setPassword/index.json'),
  media: require('./media/index.json'),
  participationReport: require('./participationReport/index.json'),
  participationByPlatformReport: require('./participationByPlatformReport/index.json'),
  activationReport: require('./activationReport/index.json'),
  unsubscribedDone: require('./unsubscribeDone/index.json'),
  shopMessage: require('./shopMessage/index.json'),
  assignments: require('./assignments/index.json'),
}
