import React from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles } from '@material-ui/core'
import { Popover, Button, Alert, AlertTitle } from '@material-ui/core'
import Link from '../../link'

import InfoIcon from '@material-ui/icons/Info'

/**
 *
 * @param {*} props
 */
const Score = props => {
  const { dispatch, entity, classes } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'info-score-popover' : undefined

  return (
    <div className={classes.center}>
      <Button
        className={classes.helperButton}
        aria-describedby={id}
        startIcon={<InfoIcon />}
        onClick={handleClick}
      >
        Instructions for showing scores{' '}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.maxWidthAlert}>
          <AlertTitle>Using Score Card</AlertTitle>
          <span>
            You can get the numbers using the following variables in the editor.
          </span>
          <br />
          <br />
          <strong>{'{{Correct_Answers}}'}: </strong>
          Returns the number of correct answers.
          <br />
          <strong>{'{{Wrong_Answers}}'}: </strong>
          Returns the number of wrong answers.
          <br />
          <strong>{'{{Quiz_Questions}}'}: </strong>
          Returns the number of quiz questions.
          <br />
          <strong>{'{{Answerable_Questions}}'}: </strong>
          Returns the number of answerable questions.
          <br />
          <strong>{'{{Card_Count}}'}: </strong>
          Returns the number of all cards.
          <br />
          <strong>{'{{Total}}'}: </strong>
          Correct_Answers + Survey_Answers + Info_View
          <br />
          <br />
          <strong>Math Calculations:</strong>
          You can create your own formula with variables in square brackets.
          <br />
          <br />
          <strong>Example of an accuracy ratio calculation:</strong>
          <br />
          {'[{{Correct_Answers}} / {{Quiz_Questions}} * 100]%'}
        </div>
      </Popover>
      <Link dispatch={dispatch} entity={entity} location={1} />
    </div>
  )
}

Score.propTypes = propTypes

export default withStyles(styles)(Score)
