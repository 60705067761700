import { get as getCookie, set as setCookie } from 'es-cookie'
import LanguageType from './language-type'
import { EN } from './en'
import { TR } from './tr'

class Translator {
  static instance

  _dictionary = null
  _langType = null

  /**
   *
   */
  constructor() {
    if (Translator.instance) {
      return Translator.instance
    }

    this._dictionaries = {
      en: () => EN,
      tr: () => TR,
    }

    if (getCookie('language') !== undefined) {
      this._langType = getCookie('language')

      this._dictionary = this._dictionaries[this._langType]()
    } else {
      this._langType = LanguageType.EN

      this.changeLanguage(this._langType)
      //this.detectLanguage()
    }

    Translator.instance = this
  }

  /**
   *
   * @param {LanguageType} langType
   */
  changeLanguage(langType) {
    this._langType = langType

    setCookie('language', this._langType, {sameSite: 'none',secure: true})

    this._dictionary = this._dictionaries[this._langType]()
  }

  /**
   *
   * @param {string} langType
   */
  _(key) {
    const keyList = key.split('.')
    const text = keyList.reduce(
      /**
       *
       * @param {string} accumulator
       * @param {Object} currentValue
       */
      (accumulator, currentValue) => {
        if (accumulator == null) {
          return null
        }

        accumulator = accumulator[currentValue]

        return accumulator
      },
      this._dictionary,
    )

    if (text) {
      return text
    }

    return key
  }
}

const translator = new Translator()

export default translator

export const changeLanguage = langType => {
  if (!langType && typeof langType != 'string') {
    console.error('Should you send paremeter type langType')
  }

  translator.changeLanguage(langType)
}

export const detectLanguage = () => {
  translator.detectLanguage()
}
