const COURSE_STATISTICS_LIST = 'COURSE_STATISTICS_LIST'
const COURSE_STATISTICS_LIST_SUCCESS = 'COURSE_STATISTICS_LIST_SUCCESS'
const COURSE_STATISTICS_LIST_ERROR = 'COURSE_STATISTICS_LIST_ERROR'
const COURSE_STATISTICS_REPORT = 'COURSE_STATISTICS_REPORT'
const COURSE_STATISTICS_REPORT_SUCCESS = 'COURSE_STATISTICS_REPORT_SUCCESS'
const COURSE_STATISTICS_REPORT_ERROR = ' COURSE_STATISTICS_REPORT_ERROR'

export {
  COURSE_STATISTICS_LIST,
  COURSE_STATISTICS_LIST_SUCCESS,
  COURSE_STATISTICS_LIST_ERROR,
  COURSE_STATISTICS_REPORT,
  COURSE_STATISTICS_REPORT_SUCCESS,
  COURSE_STATISTICS_REPORT_ERROR,
}
