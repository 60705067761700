import { userListAction, userIdListAction } from '../../../reducers/user/action'
import grupActions from './grup-actions'
import columns from './columns'
import search from './search'
import filters from './filters'
import filterModel from './filters/model'

const list = {
  searchOperation: (query, dispatch, initialData) => {
    dispatch(userListAction(query))
  },
  selectAllOperation: (query, dispatch, callback) => {
    dispatch(
      userIdListAction(
        { ...query, PageSize: 100000, CurrentPage: 0 },
        callback,
      ),
    )
  },
  grupActions: grupActions,
  search: search,
  filters: filters,
  filtersModel: filterModel,
  row: {
    columns: columns,
    childDisableControl: row => {
      return true
    },
    childVisibleControl: row => {
      return true
    },
  },
}

export default list
