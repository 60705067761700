import axios from 'axios'
import { get as getCookie } from 'es-cookie'
import { config } from './request-config'
import {
  UnauthorizedError,
  ForbiddenError,
  UnexpectedError,
  ServerError,
  CustomError,
  NetworkError,
  BadRequestError,
} from '../model/error'

/**
 *
 */
class Request {
  endPoint = ''
  baseURL = ''
  /**
   *
   * @param {string} baseUrl
   * @param {string} endPoint
   */
  constructor(baseUrl, endPoint) {
    axios.interceptors.request.use(axiosConfig => {
      if (axiosConfig.url.indexOf('https://api.unsplash.com/') === -1) {
        axiosConfig.baseURL = baseUrl

        if (getCookie('token')) {
          axiosConfig.headers.Authorization = `Bearer ${getCookie('token')}`
        } else {
          axiosConfig.headers.Authorization =
            'Basic YXN0YXJ1czphc3RhcnVzc2VjcmV0'
        }
      }

      return axiosConfig
    })

    this.endPoint = endPoint
    this.baseURL = baseUrl
  }

  /**
   *
   * @param parameters
   */
  async get(parameters) {
    const result = await axios
      .get(`${this.endPoint}${this.getUrlParam(parameters)}`, config)
      .then(this.handleResponse)
      .catch(this.handleCatch)

    return result
  }

  /**
   *
   * @param parameter
   */
  async delete(parameter) {
    const result = await axios
      .delete(`${this.endPoint}${this.getUrlParam(parameter)}`, config)
      .then(this.handleResponse)
      .catch(this.handleCatch)

    return result
  }

  /**
   *
   * @param parameters
   */
  async head(parameters) {
    const result = await axios
      .head(`${this.endPoint}${this.getUrlParam(parameters)}`, config)
      .then(this.handleResponse)
      .catch(this.handleCatch)

    return result
  }

  /**
   *
   * @param data
   */
  async post(data, dataConfig) {
    let postConfig = config
    if (dataConfig) {
      postConfig = { ...postConfig, ...dataConfig }
    }

    let baseURL = this.baseURL ? this.baseURL : ''
    const result = await axios
      .post(`${baseURL + this.endPoint}`, data, postConfig)
      .then(this.handleResponse)
      .catch(this.handleCatch)

    return result
  }

  /**
   *
   * @param data
   */
  async put(data) {
    const result = await axios
      .put(`${this.endPoint}`, data, config)
      .then(this.handleResponse)
      .catch(this.handleCatch)

    return result
  }

  /**
   *
   * @param data
   */
  async patch(data) {
    const result = await axios
      .patch(`${this.endPoint}`, data, {
        ...config,
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      })
      .then(this.handleResponse)
      .catch(this.handleCatch)

    return result
  }

  /**
   *
   * @param response
   */
  handleResponse(response) {
    if (response.data == null) {
      throw new CustomError('CustomError', response.config, '', null, response)
    }

    return response.data
  }

  /**
   *
   * @param error
   */
  handleCatch(error) {
    if (error.type === 'UnexpectedError') {
      throw new UnexpectedError(
        'UnexpectedError',
        error.response.config,
        '',
        null,
        error.response,
      )
    }

    if (error.type === 'CustomError') {
      throw new CustomError(
        'CustomError',
        error.response.config,
        '',
        null,
        error.response,
      )
    }

    if (error.response == null) {
      throw new NetworkError('NetworkError', error.config, '', null)
    }

    switch (error.response.status) {
      case 400:
        throw new BadRequestError(
          'BadRequestError',
          error.response.config,
          '',
          null,
          error.response,
        )
      case 401:
        throw new UnauthorizedError(
          'UnauthorizedError',
          error.response.config,
          '',
          null,
          error.response,
        )
      case 403:
        throw new ForbiddenError(
          'ForbiddenError',
          error.response.config,
          '',
          null,
          error.response,
        )
      case 500:
        throw new ServerError(
          'ServerError',
          error.response.config,
          '',
          null,
          error.response,
        )
      default:
        throw new UnexpectedError(
          'UnexpectedError',
          error.response.config,
          '',
          null,
          error.response,
        )
    }
  }

  /**
   *
   */
  getUrlParam = parameters => {
    let query = ''

    if (parameters) {
      if (Object.keys(parameters).length > 0) {
        query = '?'
        Object.keys(parameters).forEach(key => {
          if (parameters[key] !== undefined && parameters[key] !== null) {
            query += `${key}=${parameters[key]}&`
          }
        })
      } else {
        query = `/${parameters}`
      }
    }

    return query
  }
}

export default Request
