import PropTypes from 'prop-types'
import { ListUseModeTypes } from '../../model/list'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  list: PropTypes.object,
  usageMode: PropTypes.oneOf(ListUseModeTypes),
  showDetailSearch: PropTypes.bool,
  showPaging: PropTypes.bool,
  showSelect: PropTypes.bool,
  showChildList: PropTypes.bool,
  asc: PropTypes.bool,
  SortingColumn: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
}

export default propTypes
