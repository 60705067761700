import PropTypes from 'prop-types'
import { FormItemPropTypes } from '../form-item/default.js'

const FormItemDatePropTypes = {
  ...FormItemPropTypes,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  initialFocusedDate: PropTypes.instanceOf(Date),
  dateTimeFormat: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  disablePast: PropTypes.bool,
}

export default FormItemDatePropTypes
