import PropTypes from 'prop-types'
import { ActionPropTypes } from '../button'
import { TagPropTypes } from '../tag'
import { ConverterTypes } from '../converter'
import { FormPropTypes } from '../../form/props'

export class ListUseModeType {
  static LIST = 'list'
  static SELECT = 'select'
}

export class ListFooterType {
  static GRID = 'grid'
  static TABLE = 'table'
}

export const ListUseModeTypes = [ListUseModeType.LIST, ListUseModeType.SELECT]
export class ListItemViewType {
  static SINGLE_ROW = 'singleRow'
  static DOUBLE_ROW = 'doubleRow'
  static AVATAR = 'avatar'
}

export const ListItemViewTypes = [
  ListItemViewType.SINGLE_ROW,
  ListItemViewType.DOUBLE_ROW,
  ListItemViewType.AVATAR,
]

export const ListApplicationType = {
  ASSIGNMENT: 'assignment'
}

export class ListColumnType {
  static STRING = 0
  static NUMBER = 1
  static BOOLEAN = 2
  static DATE = 3
  static ENUM = 4
  static ACTION = 5
  static ARRAY = 6
  static TAG = 7
  static HOVER_INFO = 8
  static ASSIGNMENT_DATE = 9
}

export const ListColumnOptions = [
  ListColumnType.STRING,
  ListColumnType.NUMBER,
  ListColumnType.BOOLEAN,
  ListColumnType.DATE,
  ListColumnType.ENUM,
  ListColumnType.ACTION,
  ListColumnType.ARRAY,
  ListColumnType.TAG,
  ListColumnType.HOVER_INFO,
  ListColumnType.ASSIGNMENT_DATE,
]

export const GroupActionsPropTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(ActionPropTypes)),
}

export const ColumnsPropTypes = {
  label: PropTypes.string,
  converter: PropTypes.shape(ConverterTypes),
  suffix: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  renderer: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.shape(ActionPropTypes)),
  tags: PropTypes.arrayOf(PropTypes.shape(TagPropTypes)),
  attribute: PropTypes.string.isRequired,
  type: PropTypes.oneOf(ListColumnOptions).isRequired,
  sortable: PropTypes.bool.isRequired,
}

const propTypes = {
  filters: PropTypes.shape(FormPropTypes),
  searchOperation: PropTypes.func.isRequired,
  grupActions: PropTypes.arrayOf(PropTypes.shape(GroupActionsPropTypes)),
  row: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape(ColumnsPropTypes)).isRequired,
  }),
}

propTypes.childList = PropTypes.func

export const ListPropTypes = { ...propTypes }
