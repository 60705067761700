import { put, call, take, fork } from 'redux-saga/effects';
import { deleteCourseDocumentService } from '../../api/assignment';
//import { questionPatchSuccess } from '../../reducers/question/action'
import { deleteCourseDocumentSuccess } from '../../reducers/assignment/action';
import { DELETE_COURSE_DOCUMENT } from '../../reducers/assignment/type';
import generalSaga from '../general';
import { operationSuccess, operationFail } from '../../reducers/operation/action';

/**
 *
 * @param {*} payload
 */
function* deleteCourseDocumentFlow(payload) {
  try {
    const { entity, callback } = payload;
    const response = yield call(deleteCourseDocumentService, entity);

    yield put(operationSuccess());
    yield put(deleteCourseDocumentSuccess(response.data));
    callback(response.data);
  } catch (error) {
    yield put(operationFail(error));
  }
}

/**
 *
 */
export default function* deleteCourseDocumentWatcher() {
  while (true) {
    const action = yield take(DELETE_COURSE_DOCUMENT);
    const { payload } = action;

    yield fork(generalSaga, deleteCourseDocumentFlow, payload);
  }
}
