import { put, call, take, fork } from 'redux-saga/effects'
import { excelExportCourseTimeSeriesReportService } from '../../api/excel-report'
import FileDownload from 'js-file-download'
import { COURSE_TIME_SERIES_REPORT } from '../../reducers/course-time-series/type'
import { courseTimeSeriesReportSuccess } from '../../reducers/course-time-series/action'

import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* courseTimeSeriesReportFlow() {
  try {
    const response = yield call(excelExportCourseTimeSeriesReportService)

    yield put(courseTimeSeriesReportSuccess())

    FileDownload(response, 'coursetimeseries.xlsx')

    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* courseTimeSeriesReportWatcher() {
  while (true) {
    const action = yield take(COURSE_TIME_SERIES_REPORT)
    const { payload } = action

    yield fork(generalSaga, courseTimeSeriesReportFlow, payload)
  }
}
