import { put, call, take, fork } from 'redux-saga/effects'
import { questionSettingPatchService } from '../../api/question'
//import { questionPatchSuccess } from '../../reducers/question/action'
import { questionSettingPatchSuccess } from '../../reducers/assignment/action'
import { QUESTION_SETTING_PATCH } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionSettingsPatchFlow(payload) {
  try {
    const { content, questionId, settingId, key, callback } = payload

    yield call(questionSettingPatchService, {data: [content], id: settingId})
    yield put(questionSettingPatchSuccess(content, settingId, key, questionId))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionSettingsPatchWatcher() {
  while (true) {
    const action = yield take(QUESTION_SETTING_PATCH)
    const { payload } = action
    
    yield fork(generalSaga, questionSettingsPatchFlow, payload, false)
  }
}
