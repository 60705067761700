const style = theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  expand: {
    width: 'auto',
    height: 'auto',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    color: '#ffffff',
    padding: theme.spacing(0.5),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  iconHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  label: {
    fontWeight: 500,
  },
  header: {
    borderBottomColor: theme.palette.secondary.light,
    borderBottom: '1px solid',
    paddingLeft: theme.spacing(1),
  },
  collapse: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  labelWrapper: {
    flexGrow: 1,
  },
})

export default style
