import { put, call, take, fork } from 'redux-saga/effects'
import { exportCourseToScormService } from 'src/api/assignment'
import FileDownload from 'js-file-download'
import { EXPORT_COURSE_TO_SCORM } from 'src/reducers/assignment/type'
import { exportCourseToScormSuccess } from 'src/reducers/assignment/action'

import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* exportCourseToScormFlow(payload) {
  const { courseName, id, scormVersion, callback } = payload

  try {
    const response = yield call(exportCourseToScormService, id, scormVersion)

    yield put(exportCourseToScormSuccess())

    FileDownload(response, `${courseName}-scorm${scormVersion}.zip`)

    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* exportCourseToScormWatcher() {
  while (true) {
    const action = yield take(EXPORT_COURSE_TO_SCORM)
    const { payload } = action

    yield fork(generalSaga, exportCourseToScormFlow, payload)
  }
}
