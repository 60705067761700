import {
  QUESTION_UPDATE_ENTITY,
  QUESTION_LIST,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_ERROR,
  QUESTION_ID_LIST,
  QUESTION_ID_LIST_SUCCESS,
  QUESTION_ID_LIST_ERROR,
  QUESTION_GET,
  QUESTION_GET_SUCCESS,
  QUESTION_GET_ERROR,
  QUESTION_GET_LIST,
  QUESTION_GET_LIST_SUCCESS,
  QUESTION_GET_LIST_ERROR,
  QUESTION_DELETE_LIST,
  QUESTION_DELETE_ALL_LIST,
  QUESTION_ADD,
  QUESTION_ADD_SUCCESS,
  QUESTION_ADD_ERROR,
  QUESTION_EDIT,
  QUESTION_EDIT_SUCCESS,
  QUESTION_EDIT_ERROR,
  QUESTION_PATCH,
  QUESTION_PATCH_SUCCESS,
  QUESTION_PATCH_ERROR,
  QUESTION_CLONE,
  QUESTION_CLONE_SUCCESS,
  QUESTION_CLONE_ERROR,
  QUESTION_DELETE,
  QUESTION_DELETE_SUCCESS,
  QUESTION_DELETE_ERROR,
  QUESTION_LIST_DELETE,
  QUESTION_LIST_DELETE_SUCCESS,
  QUESTION_LIST_DELETE_ERROR,
  QUESTION_LIST_MAKE_ACTIVE,
  QUESTION_LIST_MAKE_ACTIVE_SUCCESS,
  QUESTION_LIST_MAKE_ACTIVE_ERROR,
  QUESTION_LIST_MAKE_PASSIVE,
  QUESTION_LIST_MAKE_PASSIVE_SUCCESS,
  QUESTION_LIST_MAKE_PASSIVE_ERROR,
  QUESTION_TAG_ADD,
  QUESTION_TAG_ADD_SUCCESS,
  QUESTION_TAG_ADD_ERROR,
  QUESTION_GAMEINFO_ADD,
  QUESTION_GAMEINFO_ADD_SUCCESS,
  QUESTION_GAMEINFO_ADD_ERROR,
  QUESTION_MEDIA_ADD,
  QUESTION_MEDIA_ADD_SUCCESS,
  QUESTION_MEDIA_ADD_ERROR,
  QUESTION_LINK_ADD,
  QUESTION_LINK_ADD_SUCCESS,
  QUESTION_LINK_ADD_ERROR,
  QUESTION_SELECTION_ADD,
  QUESTION_SELECTION_ADD_SUCCESS,
  QUESTION_SELECTION_ADD_ERROR,
  QUESTION_TAG_DELETE,
  QUESTION_TAG_DELETE_SUCCESS,
  QUESTION_TAG_DELETE_ERROR,
  QUESTION_GAMEINFO_DELETE,
  QUESTION_GAMEINFO_DELETE_SUCCESS,
  QUESTION_GAMEINFO_DELETE_ERROR,
  QUESTION_MEDIA_DELETE,
  QUESTION_MEDIA_DELETE_SUCCESS,
  QUESTION_MEDIA_DELETE_ERROR,
  QUESTION_LINK_DELETE,
  QUESTION_LINK_DELETE_SUCCESS,
  QUESTION_LINK_DELETE_ERROR,
  QUESTION_SELECTION_DELETE,
  QUESTION_SELECTION_DELETE_SUCCESS,
  QUESTION_SELECTION_DELETE_ERROR,
  QUESTION_REPORT_COMPARE_TEAMS,
  QUESTION_REPORT_RELATED_TEAMS,
  QUESTION_REPORT_SHOW_USER_ANSWERS,
  QUESTION_REPORT_CHOICE_TENDENCY,
  QUESTION_REPORT_COMPARE_TEAMS_SUCCESS,
  QUESTION_REPORT_RELATED_TEAMS_SUCCESS,
  QUESTION_REPORT_SHOW_USER_ANSWERS_SUCCESS,
  QUESTION_REPORT_CHOICE_TENDENCY_SUCCESS,
  QUESTION_REPORT_COMPARE_TEAMS_ERROR,
  QUESTION_REPORT_RELATED_TEAMS_ERROR,
  QUESTION_REPORT_SHOW_USER_ANSWERS_ERROR,
  QUESTION_REPORT_CHOICE_TENDENCY_ERROR,
  QUESTION_REPORT_IMPROVEMENT,
  QUESTION_REPORT_IMPROVEMENT_ERROR,
  QUESTION_REPORT_IMPROVEMENT_SUCCESS,
  QUESTION_SELECTION_PATCH,
  QUESTION_SELECTION_PATCH_SUCCESS,
  QUESTION_SELECTION_PATCH_ERROR,
  QUESTION_RE_ORDER_LIST,
  QUESTION_SELECTIONS_ADD,
} from './type'

/**
 *
 * @param {*} entity
 */
export const questionUpdateEntityAction = (entity, callback) => {
  return {
    type: QUESTION_UPDATE_ENTITY,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} query
 */
export const questionListAction = query => {
  return {
    type: QUESTION_LIST,
    payload: { query },
  }
}

/**
 *
 * @param {*} list
 */
export const questionListSuccess = list => {
  return {
    type: QUESTION_LIST_SUCCESS,
    payload: { list },
  }
}

/**
 *
 */
export const questionListError = () => {
  return {
    type: QUESTION_LIST_ERROR,
  }
}

/**
 *
 * @param {*} query
 */
export const questionIdListAction = (query, callback) => {
  return {
    type: QUESTION_ID_LIST,
    payload: { query, callback },
  }
}

/**
 *
 * @param {*} list
 */
export const questionIdListSuccess = list => {
  return {
    type: QUESTION_ID_LIST_SUCCESS,
    payload: { list },
  }
}

/**
 *
 */
export const questionIdListError = () => {
  return {
    type: QUESTION_ID_LIST_ERROR,
  }
}
/**
 *
 * @param {*} id
 */
export const questionGetAction = id => {
  return {
    type: QUESTION_GET,
    payload: { id },
  }
}

/**
 *
 * @param {*} entity
 */
export const questionGetSuccess = entity => {
  return {
    type: QUESTION_GET_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const questionGetError = () => {
  return {
    type: QUESTION_GET_ERROR,
  }
}

/**
 *
 * @param {*} idList
 */
export const questionGetListAction = idList => {
  return {
    type: QUESTION_GET_LIST,
    payload: { idList },
  }
}

/**
 *
 * @param {*} entity
 */
export const questionGetListSuccess = entityList => {
  return {
    type: QUESTION_GET_LIST_SUCCESS,
    payload: { entityList },
  }
}

/**
 *
 */
export const questionGetListError = () => {
  return {
    type: QUESTION_GET_LIST_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const questionDeleteListAction = id => {
  return {
    type: QUESTION_DELETE_LIST,
    payload: { id },
  }
}

/**
 *
 */
export const questionDeleteAllListAction = () => {
  return {
    type: QUESTION_DELETE_ALL_LIST,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const questionAddAction = (entity, courseId, callback) => {
  return {
    type: QUESTION_ADD,
    payload: { entity, courseId, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const questionAddSuccess = entity => {
  return {
    type: QUESTION_ADD_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const questionAddError = () => {
  return {
    type: QUESTION_ADD_ERROR,
  }
}

/**
 *
 * @param {*} entity
 */
export const questionEditAction = entity => {
  return {
    type: QUESTION_EDIT,
    payload: { entity },
  }
}

/**
 *
 * @param {*} entity
 */
export const questionEditSuccess = entity => {
  return {
    type: QUESTION_EDIT_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const questionEditError = () => {
  return {
    type: QUESTION_EDIT_ERROR,
  }
}

/**
 *
 * @param {
 *
 *} entity
 */
export const questionReOrderListAction = entityList => {
  return {
    type: QUESTION_RE_ORDER_LIST,
    payload: { entityList },
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */
export const questionPatchAction = (content, callback) => {
  return {
    type: QUESTION_PATCH,
    payload: { content, callback },
  }
}

/**
 *
 * @param {*} content
 */
export const questionPatchSuccess = content => {
  return {
    type: QUESTION_PATCH_SUCCESS,
    payload: { content },
  }
}

/**
 *
 */
export const questionPatchError = () => {
  return {
    type: QUESTION_PATCH_ERROR,
  }
}
/**
 *
 * @param {*} ids
 * @param {*} callback
 */
export const questionCloneAction = (ids, callback) => {
  return {
    type: QUESTION_CLONE,
    payload: { ids, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const questionCloneSuccess = response => {
  return {
    type: QUESTION_CLONE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const questionCloneError = () => {
  return {
    type: QUESTION_CLONE_ERROR,
  }
}
/**
 *
 * @param {*} id
 * @param {*} callback
 */
export const questionDeleteAction = (id, callback) => {
  return {
    type: QUESTION_DELETE,
    payload: { id, callback },
  }
}

/**
 *@param {*} response
 */
export const questionDeleteSuccess = response => {
  return {
    type: QUESTION_DELETE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const questionDeleteError = () => {
  return {
    type: QUESTION_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} ids
 * @param {*} callback
 */
export const questionListDeleteAction = (ids, callback) => {
  return {
    type: QUESTION_LIST_DELETE,
    payload: { ids, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const questionListDeleteSuccess = response => {
  return {
    type: QUESTION_LIST_DELETE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const questionListDeleteError = () => {
  return {
    type: QUESTION_LIST_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} ids
 * @param {*} callback
 */
export const questionListMakeActiveAction = (ids, callback) => {
  return {
    type: QUESTION_LIST_MAKE_ACTIVE,
    payload: { ids, callback },
  }
}

/**
 *
 * @param {*} ids
 */
export const questionListMakeActiveSuccess = ids => {
  return {
    type: QUESTION_LIST_MAKE_ACTIVE_SUCCESS,
    payload: { ids },
  }
}

/**
 *
 */
export const questionListMakeActiveError = () => {
  return {
    type: QUESTION_LIST_MAKE_ACTIVE_ERROR,
  }
}

/**
 *
 * @param {*} ids
 * @param {*} callback
 */
export const questionListMakePassiveAction = (ids, callback) => {
  return {
    type: QUESTION_LIST_MAKE_PASSIVE,
    payload: { ids, callback },
  }
}

/**
 *
 * @param {*} ids
 */
export const questionListMakePassiveSuccess = ids => {
  return {
    type: QUESTION_LIST_MAKE_PASSIVE_SUCCESS,
    payload: { ids },
  }
}

/**
 *
 */
export const questionListMakePassiveError = () => {
  return {
    type: QUESTION_LIST_MAKE_PASSIVE_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const questionTagAddAction = entity => {
  return {
    type: QUESTION_TAG_ADD,
    payload: { entity },
  }
}

/**
 *
 */
export const questionTagAddSuccess = entity => {
  return {
    type: QUESTION_TAG_ADD_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const questionTagAddError = () => {
  return {
    type: QUESTION_TAG_ADD_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const questionGameInfoAddAction = id => {
  return {
    type: QUESTION_GAMEINFO_ADD,
    payload: { id },
  }
}

/**
 *
 */
export const questionGameInfoAddSuccess = () => {
  return {
    type: QUESTION_GAMEINFO_ADD_SUCCESS,
  }
}

/**
 *
 */
export const questionGameInfoAddError = () => {
  return {
    type: QUESTION_GAMEINFO_ADD_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const questionMediaAddAction = (entity, callback) => {
  return {
    type: QUESTION_MEDIA_ADD,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const questionMediaAddSuccess = entity => {
  return {
    type: QUESTION_MEDIA_ADD_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const questionMediaAddError = () => {
  return {
    type: QUESTION_MEDIA_ADD_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const questionLinkAddAction = (entity, callback) => {
  return {
    type: QUESTION_LINK_ADD,
    payload: { entity, callback },
  }
}

/**
 *
 */
export const questionLinkAddSuccess = entity => {
  return {
    type: QUESTION_LINK_ADD_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const questionLinkAddError = () => {
  return {
    type: QUESTION_LINK_ADD_ERROR,
  }
}
/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const questionSelectionAddAction = (entity, callback) => {
  return {
    type: QUESTION_SELECTION_ADD,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const questionSelectionAddSuccess = response => {
  return {
    type: QUESTION_SELECTION_ADD_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const questionSelectionAddError = () => {
  return {
    type: QUESTION_SELECTION_ADD_ERROR,
  }
}
/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const questionTagDeleteAction = (entity, callback) => {
  return {
    type: QUESTION_TAG_DELETE,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const questionTagDeleteSuccess = id => {
  return {
    type: QUESTION_TAG_DELETE_SUCCESS,
    payload: { id },
  }
}

/**
 *
 */
export const questionTagDeleteError = () => {
  return {
    type: QUESTION_TAG_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const questionGameInfoDeleteAction = id => {
  return {
    type: QUESTION_GAMEINFO_DELETE,
    payload: { id },
  }
}

/**
 *
 */
export const questionGameInfoDeleteSuccess = () => {
  return {
    type: QUESTION_GAMEINFO_DELETE_SUCCESS,
  }
}

/**
 *
 */
export const questionGameInfoDeleteError = () => {
  return {
    type: QUESTION_GAMEINFO_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} id
 * @param {*} callback
 */
export const questionMediaDeleteAction = (id, questionId, callback) => {
  return {
    type: QUESTION_MEDIA_DELETE,
    payload: { id, questionId, callback },
  }
}

/**
 *
 */
export const questionMediaDeleteSuccess = id => {
  return {
    type: QUESTION_MEDIA_DELETE_SUCCESS,
    payload: { id },
  }
}

/**
 *
 */
export const questionMediaDeleteError = () => {
  return {
    type: QUESTION_MEDIA_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} id
 * @param {*} callback
 */
export const questionLinkDeleteAction = (id, questionId, callback) => {
  return {
    type: QUESTION_LINK_DELETE,
    payload: { id, questionId, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const questionLinkDeleteSuccess = response => {
  return {
    type: QUESTION_LINK_DELETE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const questionLinkDeleteError = () => {
  return {
    type: QUESTION_LINK_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} id
 * @param {*} callback
 */
export const questionSelectionDeleteAction = (id, questionId, callback) => {
  return {
    type: QUESTION_SELECTION_DELETE,
    payload: { id, questionId, callback },
  }
}

/**
 *
 * @param {*} id
 * @param {*}  questionId
 */
export const questionSelectionDeleteSuccess = (id, questionId) => {
  return {
    type: QUESTION_SELECTION_DELETE_SUCCESS,
    payload: { id, questionId },
  }
}

/**
 *
 */
export const questionSelectionDeleteError = () => {
  return {
    type: QUESTION_SELECTION_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} reqParams
 * @param {*} parentId
 * @param {*} callback
 */
export const questionReportCompareTeamsAction = (reqParams, callback) => {
  return {
    type: QUESTION_REPORT_COMPARE_TEAMS,
    payload: { reqParams, callback },
  }
}

/**
 *
 * @param {*} response
 * @param {*} parentId
 */
export const questionReportCompareTeamsSuccess = (response, parentId) => {
  return {
    type: QUESTION_REPORT_COMPARE_TEAMS_SUCCESS,
    payload: { response, parentId },
  }
}
/**
 *
 */
export const questionReportCompareTeamsError = () => {
  return {
    type: QUESTION_REPORT_COMPARE_TEAMS_ERROR,
  }
}

/**
 *
 * @param {*} reqParams
 * @param {*} parentId
 * @param {*} callback
 */
export const questionReportRelatedTeamsAction = (reqParams, callback) => {
  return {
    type: QUESTION_REPORT_RELATED_TEAMS,
    payload: { reqParams, callback },
  }
}

/**
 *
 * @param {*} response
 * @param {*} parentId
 */
export const questionReportRelatedTeamsSuccess = (response, parentId) => {
  return {
    type: QUESTION_REPORT_RELATED_TEAMS_SUCCESS,
    payload: { response, parentId },
  }
}
/**
 *
 */
export const questionReportRelatedTeamsError = () => {
  return {
    type: QUESTION_REPORT_RELATED_TEAMS_ERROR,
  }
}

/**
 *
 * @param {*} reqParams
 * @param {*} parentId
 * @param {*} callback
 */
export const questionReportShowUserAnswersAction = (reqParams, callback) => {
  return {
    type: QUESTION_REPORT_SHOW_USER_ANSWERS,
    payload: { reqParams, callback },
  }
}

/**
 *
 * @param {*} response
 * @param {*} parentId
 */
export const questionReportShowUserAnswersSuccess = (response, parentId) => {
  return {
    type: QUESTION_REPORT_SHOW_USER_ANSWERS_SUCCESS,
    payload: { response, parentId },
  }
}
/**
 *
 */
export const questionReportShowUserAnswersError = () => {
  return {
    type: QUESTION_REPORT_SHOW_USER_ANSWERS_ERROR,
  }
}

/**
 *
 * @param {*} reqParams
 * @param {*} parentId
 * @param {*} callback
 */
export const questionReportChoiceTendencyAction = (reqParams, callback) => {
  return {
    type: QUESTION_REPORT_CHOICE_TENDENCY,
    payload: { reqParams, callback },
  }
}

/**
 *
 * @param {*} response
 * @param {*} parentId
 */
export const questionReportChoiceTendencySuccess = (response, parentId) => {
  return {
    type: QUESTION_REPORT_CHOICE_TENDENCY_SUCCESS,
    payload: { response, parentId },
  }
}
/**
 *
 */
export const questionReportChoiceTendencyError = () => {
  return {
    type: QUESTION_REPORT_CHOICE_TENDENCY_ERROR,
  }
}

/**
 *
 * @param {*} reqParams
 * @param {*} parentId
 * @param {*} callback
 */
export const questionReportImprovementAction = (reqParams, callback) => {
  return {
    type: QUESTION_REPORT_IMPROVEMENT,
    payload: { reqParams, callback },
  }
}

/**
 *
 * @param {*} response
 * @param {*} parentId
 */
export const questionReportImprovementSuccess = (response, parentId) => {
  return {
    type: QUESTION_REPORT_IMPROVEMENT_SUCCESS,
    payload: { response, parentId },
  }
}
/**
 *
 */
export const questionReportImprovementError = () => {
  return {
    type: QUESTION_REPORT_IMPROVEMENT_ERROR,
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */
export const questionSelectionPatchAction = (content, questionId, callback) => {
  return {
    type: QUESTION_SELECTION_PATCH,
    payload: { content, questionId, callback },
  }
}

/**
 *
 * @param {*} content
 */
export const questionSelectionPatchSuccess = (content, questionId) => {
  return {
    type: QUESTION_SELECTION_PATCH_SUCCESS,
    payload: { content, questionId },
  }
}

/**
 *
 */
export const questionSelectionPatchError = () => {
  return {
    type: QUESTION_SELECTION_PATCH_ERROR,
  }
}
