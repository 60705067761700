import React from 'react'
import style from './style'
import ClassNames from 'classnames'
import propTypes from './prop'
import {
  withStyles,
  Typography,
  TableRow,
  TableCell,
  TableHead,
  Tooltip,
  Checkbox,
} from '@material-ui/core'
import { ListUseModeType, ListColumnType } from '../../../../model/list'
import SwartVertIcon from '@material-ui/icons/SwapVertTwoTone'
/**
 *
 * @param {*} props
 */
const Header = props => {
  const {
    classes,
    columns,
    showChildList,
    showSelect,
    usageMode,
    hideHeaderTitle,
    queryResult,
    customFirstHeaderRow,
    handleSortingColumnExecute,
    changeAllSelect,
    multipleSelect,
    selectAllChecked,
    showDragDrop,
  } = props

  if (hideHeaderTitle == true) {
    return ''
  }
  return (
    <TableHead>
      {customFirstHeaderRow}
      <TableRow className={classes.tableRow}>
        {showChildList && <TableCell className={classes.headerColumn} />}

        {showDragDrop && <TableCell className={classes.headerColumn} />}
        {showDragDrop && <TableCell className={classes.headerColumn} />}

        {showSelect && (
          <TableCell className={classes.headerColumn}>
            {multipleSelect !== false && !showChildList && (
              <Checkbox
                checked={selectAllChecked}
                onChange={changeAllSelect}
                color="primary"
              />
            )}
            <Typography>{multipleSelect}</Typography>
          </TableCell>
        )}

        {columns
          //.filter(c => !c.hidden)
          .map((column, index) => {
            let render = true
            if (column.actions) {
              if (usageMode === ListUseModeType.SELECT) {
                render = false
              }
            }

            if (typeof column.hidden == 'function') {
              let hidden = column.hidden(queryResult.results[0])
              if (hidden == true) {
                render = false
              }
            } else if (column.hidden == true) {
              render = false
            }

            if (render) {
              return (
                <TableCell className={classes.headerColumn} key={index}>
                  <Tooltip title={column.label ? column.label : ''}>
                    <div
                      className={ClassNames({
                        [classes.flex]: true,
                        [classes.isSortable]: column.sortable,
                        [classes.textAlign]:
                          column.type == ListColumnType.NUMBER ||
                          column.type == ListColumnType.BOOLEAN,
                      })}
                      onClick={() => {
                        if (column.sortable && !showDragDrop) {
                          handleSortingColumnExecute(column.attribute)
                        }
                      }}
                    >
                      {column.sortable && !showDragDrop && (
                        <SwartVertIcon className={classes.sortableIcon} />
                      )}
                      <Typography
                        variant="body1"
                        noWrap={true}
                        className={ClassNames({
                          [classes.truncate]: true,
                          [classes.inlineBlock]: true,
                          [classes.textAlign]:
                            column.type == ListColumnType.NUMBER ||
                            column.type == ListColumnType.BOOLEAN,
                        })}
                      >
                        {(column.label ? column.label : '') +
                          (column.suffix ? ` (${column.suffix})` : '')}
                      </Typography>
                    </div>
                  </Tooltip>
                </TableCell>
              )
            } else {
              return ''
            }
          })}
      </TableRow>
    </TableHead>
  )
}

Header.propTypes = propTypes

export default withStyles(style)(Header)
