import React, { Component } from 'react'
import { useLocation } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import translator from '../../translate'
import { withSnackbar } from 'notistack'
import { get as getCookie } from 'es-cookie'
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Card,
  Box,
  CircularProgress,
} from '@material-ui/core'

import Requirements from './requirements'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { userChangePasswordService } from '../../api/authentication'

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideFormArea: false,
      name: '',
      long: false,
      number: false,
      lowercase: false,
      uppercase: false,
      special: false,
      password: '',
      showPassword: false,
      disabled: true,
      openAlert: false,
      loading: false,
    }
  }
  onHandleSetPassword = async email => {
    this.setState({ loading: true })
    const { password } = this.state
    if (password) {
      const data = {
        email,
        password,
      }
      let response = null
      try {
        response = await userChangePasswordService(data)
      } catch (error) {
        this.setState({ openAlert: true })
      }

      if (response && response.statusCode == 200) {
        window.location.href = '/'
        this.setState({ loading: false })
      }
    }
  }

  handleChange = prop => event => {
    let regexForLong = new RegExp('(?=.{8,})')

    let long = regexForLong.test(event.target.value)

    let regexForNumber = new RegExp('(?=.*[0-9])')

    let number = regexForNumber.test(event.target.value)

    let regexForLowercase = new RegExp('(?=.*[a-z])')

    let lowercase = regexForLowercase.test(event.target.value)

    let regexForUppercase = new RegExp('(?=.*[A-Z])')

    let uppercase = regexForUppercase.test(event.target.value)

    let regexForSpecial = new RegExp('(?=.*[!@#$%^&*])')

    let special = regexForSpecial.test(event.target.value)

    this.setState({
      password: event.target.value,
      long,
      number,
      lowercase,
      uppercase,
      special,
      disabled: !(long && number && lowercase && uppercase && special),
    })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleClose = () => {
    this.setState({ openAlert: false })
  }

  /**
   *
   */
  render() {
    const { classes, hasHeader, email } = this.props
    const {
      hideFormArea,
      long,
      number,
      lowercase,
      uppercase,
      special,
      password,
      showPassword,
      disabled,
      openAlert,
    } = this.state
    translator.changeLanguage(getCookie('language'))
    return (
      <div className={classes.div}>
        <Dialog
          open={openAlert}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translator._('setPassword.passwordSameWithTheLastOne')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              {translator._('setPassword.ok')}
            </Button>
          </DialogActions>
        </Dialog>

        {hasHeader && (
          <Typography
            variant={'h3'}
            paddingBottom={'30px'}
            textAlign={'center'}
          >
            {translator._('setPassword.renewPassword')}
          </Typography>
        )}
        <Card className={classes.card}>
          {!hideFormArea && (
            <div className={classes.form}>
              <FormControl
                margin="normal"
                required
                fullWidth
                className={classes.input}
              >
                <Input
                  id="password"
                  placeholder="Password"
                  inputProps={{
                    'aria-label': 'description',
                  }}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={this.handleChange('password')}
                  label={translator._('setPassword.password')}
                  variant="outlined"
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.handleClickShowPassword()
                        }}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Requirements
                long={long}
                number={number}
                uppercase={uppercase}
                lowercase={lowercase}
                special={special}
                classes={classes}
              />
              {this.state.loading == false && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                  className={classes.submit}
                  onClick={() => this.onHandleSetPassword(email)}
                >
                  Change Password
                </Button>
              )}
              {this.state.loading == true && (
                <div className={classes.circularProgress}>
                  <CircularProgress />
                </div>
              )}
            </div>
          )}
        </Card>
        <Box m={3}></Box>
        {hideFormArea && (
          <video autoPlay width="320" height="240">
            <source src="/assets/video/done-complete.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(withSnackbar(ChangePasswordForm))
