import PropTypes from 'prop-types'

const propTypes = {
  filters: PropTypes.any,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default propTypes
