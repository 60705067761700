const style = theme => ({
  addLastCard: {
    margin: '0 24px 24px',
    width: '100%',
  },
  addLastCardContent: {
    margin: '24px',
    border: '2px dashed #dcdcdc',
    borderRadius: '20px',
    height: 160,
    display: 'flex',
    flexDirection: 'column',
    padding: '20 30 20 30',
    background: '#ffffff',
  },
  addLastCardContentHeader: {
    marginBottom: '15px',
    display: 'flex',
  },
  completionSvg: {
    marginRight: '10px',
  },
  buttonSvg: {
    marginRight: '5px',
  },
  addLastCardContentButtons: {
    width: '32%',
  },
  addLastCardContentButtonsDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
  headerTexts: {
    color: '#f67b3f',
    textAlign: 'left',
  },
  subHeaderTexts: {
    color: '#919EAB',
    textAlign: 'left',
  },
})

export default style
