const style = theme => ({
  root: {
    //minHeight: 150,
  },
  usageModeSelectRoot: {
    height: '100%',
  },
  usageModeSelectContent: {
    height: 'calc(100% - 70px)',
    overflowX: 'hidden',
  },
  headerNavigation: {},
  actionButtons: { width: '100%', display: 'flex', justifyContent: 'flex-end' },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  headerNavigationRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    //marginTop: theme.spacing(2),
    minHeight: 99,
  },
  table: {
    //width: '100%',
    // marginTop: theme.spacing(2),
  },
  tableContainer: {
    width: '100%',
  },
  gridTable: {
    width: '100%',
  },
  filterButtonHolder: {
    margin: 'auto 8px',
  },
  filterButton: {
    cursor: 'pointer',
    border: '1px solid #ced4da',
    display: 'inline-flex',
    padding: '11px 14px',
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: 'white',
    height: 56,
  },
})

export default style
