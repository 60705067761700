
const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(../../assets/entegrasyon-web.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  paper: {
    maxWidth: '59%',
    margin: theme.spacing(8, 4),
    position: 'absolute',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  listItemRoot: {
    padding: theme.spacing(2),
  },
  listItemText: {
    color: "black",
  },
  video: {
    maxWidth: 250,
  },
  centerBoxGrid: {
    textAlign: 'center',
    maxWidth: 700,
  },
  adminExpContainer: {
    textAlign: 'center',
    maxWidth: 600,
  },
  panelTitle: {
    marginTop: 6,
    marginLeft: theme.spacing(1),
  },
})

export default styles
