import PropTypes from 'prop-types'

const propTypes = {
  classes: PropTypes.object.isRequired,
  componentBox: PropTypes.any,
  messageBox: PropTypes.any,
  process: PropTypes.any,
}

export default propTypes
