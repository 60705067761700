import { combineReducers } from 'redux'
import authentication from './authentication/reducer'
import signUp from './sign-up/reducer'
import process from './process/reducer'
import snackbar from './snackbar/reducer'
import messageBox from './message-box/reducer'
import componentBox from './component-box/reducer'
import operation from './operation/reducer'
import common from './common/reducer'
import question from './question/reducer'
import user from './user/reducer'
import shop from './shop/reducer'
import userPassword from './user-password/reducer'
import mediaDirectory from './media-directory/reducer'
import mediaFolder from './media-folder/reducer'
import mediaImage from './media-image/reducer'
import settings from './settings/reducer'
import assignment from './assignment/reducer'
import domain from './domain/reducer'
import courseTimeSeries from './course-time-series/reducer'
import courseStatistics from './course-statistics/reducer'
import userRoleDetailedList from './user-role-detailed-list/reducer'
import courseCollectionList from './course-collection/reducer'
import teamsList from './teams/reducer'

const rootReducer = combineReducers({
  authentication,
  process,
  snackbar,
  messageBox,
  componentBox,
  operation,
  common,
  question,
  user,
  shop,
  userPassword,
  signUp,
  mediaDirectory,
  mediaFolder,
  mediaImage,
  settings,
  assignment,
  domain,
  courseTimeSeries,
  courseStatistics,
  userRoleDetailedList,
  courseCollectionList,
  teamsList,
})

export default rootReducer
