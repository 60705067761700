import { AnswerItemType } from '../../../model/question'
import translator from '../../../translate'

/**
 *
 * @param {*} props
 */
const AnswerTypeFactory = props => {
  const { type } = props
  switch (type) {
    case AnswerItemType.INFORM:
      return translator._('tasks.biteSized.title')
    case AnswerItemType.TESTINGSINGLECHOICE:
      return translator._('tasks.singleSelect.title')
    case AnswerItemType.TESTINGMULTIPLECHOICE:
      return translator._('tasks.multipleSelect.title')
    case AnswerItemType.SURVEYSINGLECHOICE:
      return translator._('tasks.singleSelectFeedback.title')
    case AnswerItemType.SURVEYMULTIPLECHOICE:
      return translator._('tasks.multipleSelectFeedback.title')
    case AnswerItemType.SURVEYFREEFORM:
      return translator._('tasks.freeForm.title')
    case AnswerItemType.SURVEYINPUT:
      return translator._('taskItem.addQuestion.main.titles.surveyPhotoAnswer')
    case AnswerItemType.SURVEYSTARS:
      return translator._('tasks.starRating.title')
    case AnswerItemType.SURVEYNUMERICENTRY:
      return translator._('tasks.numericInput.title')
    case AnswerItemType.AI:
      return translator._('tasks.ai.title')
    default:
      return ''
  }
}

export default AnswerTypeFactory
