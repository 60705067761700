import { isMobile } from 'react-device-detect'

const styles = theme => ({
  settingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'space-evenly',
    width: 622,
    padding: '20px 24px',
    height: '280px',
    gap: 24,
  },
  headerLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    width: '24px',
    height: '24px',
  },
  scormDownloadFieldDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    border: '1px solid #E5E5E5',
    width: '262px',
    gap: '16px',
    borderRadius: '16px',
  },
  downloadButton: {
    borderColor: 'none',
    boxShadow: 'none',
    width: '120px',
  },
  scormDownloadFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export default styles
