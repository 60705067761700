import React, { Component } from 'react'
import propTypes from './prop'
import styles from './styles'
import withStyles from '@material-ui/core/styles/withStyles'
import OneIcon from '@material-ui/icons/LooksOne'
import TwoIcon from '@material-ui/icons/LooksTwo'
import ThreeIcon from '@material-ui/icons/Looks3'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import translator from '../../translate'
class Welcome1 extends Component {
  /**
   *
   */
  render() {
    const { classes } = this.props

    return (
      <Grid
        container
        className={classes.centerBoxGrid}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={12} alignItems="center" alignContent="center">
          <Typography variant="body1">
            {translator._('welcome.mobileOnboarding.text')}
          </Typography>
          <Box mt={2} />
        </Grid>

        <div>
          <Accordion square expanded={true}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <OneIcon color="secondary" fontSize="large"></OneIcon>
              <Typography variant="h2" className={classes.panelTitle}>
                Download Becompe app to your phone
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                variant="column"
                className={classes.centerBoxGrid}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item md={3}>
                  <Typography variant="body2">Scan QR code below</Typography>
                  <img src="assets/becompe_QR.png" alt="QR code" height="100" />
                </Grid>
                <Grid item md={1}>
                  OR
                </Grid>
                <Grid item md={7}>
                  <Typography variant="body2">
                    Search "Becompe for Corporate Trainers” at app market
                  </Typography>
                  <Box m={1}></Box>
                  <Grid
                    container
                    variant="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item md={6}>
                      <a
                        href="https://apps.apple.com/us/app/id1477193000"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        <img
                          src="/assets/app-store-badge.png"
                          alt="app-store"
                        ></img>
                      </a>
                    </Grid>
                    <Grid item md={6}>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.becompe.android"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        <img
                          src="/assets/google-play-badge.png"
                          alt="google play"
                        ></img>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion square expanded={true}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <TwoIcon color="secondary" fontSize="large"></TwoIcon>
              <Typography variant="h2" className={classes.panelTitle}>
                Enter your account details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                variant="column"
                className={classes.centerBoxGrid}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <img src="assets/loginScreen.png" alt="login" height="100" />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion square expanded={true}>
            <AccordionSummary
              aria-controls="panel1d-content"
              aria-label="panel1d-header"
              id="panel1d-header"
            >
              <ThreeIcon color="secondary" fontSize="large"></ThreeIcon>
              <Typography variant="h2" className={classes.panelTitle}>
                Check demo content with assessment, survey, and reflection
                questions in addition to information items.<span>✌</span>
              </Typography>
            </AccordionSummary>
          </Accordion>
        </div>
      </Grid>
    )
  }
}

Welcome1.propTypes = propTypes

export default withStyles(styles)(Welcome1)
