import React from 'react'
import style from '../style'
import propTypes from '../prop'
import { withStyles, TableCell } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

/**
 *
 * @param {*} props
 */
const ListContainerColumnCustom = props => {
  const { classes, column, entity } = props
  let history = useHistory()

  return (
    <TableCell
      className={classes.root}
      style={{
        width: column.width,
        textAlign: column.align ? column.align : 'left',
      }}
    >
      {column.renderer(entity, history)}
    </TableCell>
  )
}

ListContainerColumnCustom.propTypes = propTypes

export default withStyles(style)(ListContainerColumnCustom)
