import React, { Component } from 'react'
import FilterListIcon from '@material-ui/icons/FilterList'
import style from './style'
import propTypes from './prop'
import ClassNames from 'classnames'
import { Table, Grid, Button, withStyles, Typography } from '@material-ui/core'
import { ListFooterType, ListUseModeType } from '../../model/list'
import translator from '../../translate'
import { ListItemViewType } from '../../model/list/index'
import { isMobile } from 'react-device-detect'

import {
  NoData,
  Header,
  Body,
  AvatarBody,
  DoubleLineBody,
  Footer,
  GrupActions,
  SearchInput,
  SearchDetail,
  SearchLowDetail,
  FooterAction,
  FooterFlex,
  HeaderFlex,
} from './renderer'

class List extends Component {
  state = {
    anchorPosition: {
      mouseX: null,
      mouseY: null,
      id: null,
    },
    showSearchDetail: false,
    selectedList: [],
    selectedEntityList: [],
    selectAllChecked: false,
    expandedId: null,
    showSearch: this.props.filterOpen == true ? true : false,
    query: {
      CurrentPage: 0,
      //PageSize: 20,
      PageSize:
        this.props.query != null || this.props.query != undefined
          ? this.props.query.PageSize
            ? this.props.query.PageSize
            : 20
          : 20,
      SortingColumn:
        this.props.query != null || this.props.query != undefined
          ? this.props.query.SortingColumn
            ? this.props.query.SortingColumn
            : 'id'
          : 'id',
      Asc:
        this.props.query != null || this.props.query != undefined
          ? this.props.query.Asc == null ||
            this.props.query.Asc == undefined ||
            this.props.query.Asc == true
            ? this.props.query.Asc
            : false
          : false,
      FilterText: '',
      Filters:
        this.props.query != null || this.props.query != undefined
          ? this.props.query.Filters
            ? this.props.query.Filters
            : []
          : [],
      Id:
        this.props.query != null || this.props.query != undefined
          ? this.props.query.id
            ? this.props.query.id
            : null
          : null,
      TeamName:
        this.props.query != null || this.props.query != undefined
          ? this.props.query.teamName
            ? this.props.query.teamName
            : null
          : null,
    },
  }

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props)

    //this.refresh = this.refresh.bind(this)
  }

  /**
   *
   */
  changePage = newPage => {
    this.setState(
      {
        query: {
          ...this.state.query,
          CurrentPage: newPage,
        },
      },
      () => {
        this.refresh()
      },
    )
  }

  changeFiltersForLowDetail = data => {
    let newFilter = []
    if (
      data &&
      data._contents[0]._value != '' &&
      data._contents[0]._value !== null
    ) {
      newFilter = [
        {
          FilteredField: data._contents[0]._path,
          FilteredValue: data._contents[0]._value,
          AndOr: 'and',
        },
      ]
    }

    newFilter = this.state.query.Filters.filter(f => {
      return f['FilteredField'] !== data._contents[0]._path
    }).concat(newFilter)

    this.setState(
      {
        query: {
          ...this.state.query,
          Filters: newFilter,
          CurrentPage: 0,
        },
      },
      () => {
        this.refresh()
      },
    )
  }

  /**
   *
   */
  changeFilters = query => {
    const { list } = this.props

    let newFilter = query.Filters
    if (
      list != undefined &&
      list.filters !== undefined &&
      //list.filters.hasInitialFilter == true &&
      list.filtersModel.Filters != null
    ) {
      newFilter = list.filtersModel.Filters.concat(query.Filters)
      /* TODO Neden boyle bir filtre konulmuş? 
            
            .filter(
                (v, i, a) => {
                    return (
                        a.findIndex(
                            t => t[t.FilteredField] === v[v.FilteredField],
                        ) === i
                    )
                },
            ) */
    }

    this.setState(
      {
        query: {
          ...this.state.query,
          Filters: newFilter,
          CurrentPage: 0,
        },
        //selectedList: [],
        //selectedEntityList: [],
        //selectAllChecked: false,
      },
      () => {
        this.refresh()
      },
    )
  }

  /**
   *
   */
  changeRowsPerPage = pageSize => {
    this.setState(
      {
        query: {
          ...this.state.query,
          PageSize: pageSize,
          CurrentPage: 0,
        },
      },
      () => {
        this.refresh()
      },
    )
  }

  /**
   *
   */
  handleSearchToggle = event => {
    const { showSearch } = this.state

    this.setState({
      showSearch: !showSearch,
    })
  }

  /**
   *
   */
  refresh = () => {
    const { dispatch, list, initialData, parentList } = this.props
    const { query } = this.state
    const filteredQuery = { ...query }

    list.searchOperation(filteredQuery, dispatch, initialData, parentList)
  }

  /**
   *
   */
  changeMultipleSelect = (id, checked, entity) => {
    const { selectedList, selectedEntityList } = this.state
    const { changeSelected } = this.props

    let newList = [...selectedList]

    let newEntityList = [...selectedEntityList]

    if (checked) {
      newList.push(id)
      newEntityList.push(entity)
    } else {
      newList = newList.filter(selectedId => {
        return selectedId !== id
      })

      newEntityList = newEntityList.filter(selected => {
        return selected.id !== id
      })
    }

    this.setState({
      ...this.state,
      selectedList: [...newList],
      selectedEntityList: [...newEntityList],
    })
    if (changeSelected !== undefined) {
      changeSelected(id, checked, entity)
    }
  }

  /**
   *
   */
  changeSingleSelect = (id, checked, entity) => {
    const { changeSelected } = this.props
    let newList
    let newEntityList
    if (checked) {
      newList = [id]
      newEntityList = [entity]
    } else {
      newList = []

      newEntityList = []
    }

    this.setState({
      ...this.state,
      selectedList: newList,
      selectedEntityList: newEntityList,
    })

    if (changeSelected !== undefined) {
      changeSelected(id, checked, entity)
    }
  }

  /**
   *
   */
  changeSelect = (id, checked, entity, event) => {
    //event.stopPropagation()

    const { multipleSelect } = this.props

    if (multipleSelect == false) {
      this.changeSingleSelect(id, checked, entity)
    } else {
      this.changeMultipleSelect(id, checked, entity)
    }
  }

  /**
   *
   */
  changeAllSelect = (entity, event) => {
    const { dispatch, list, initialData, parentList } = this.props
    const { selectedList, query } = this.state

    const filteredQuery = { ...query }

    if (selectedList.length > 0) {
      this.setState({
        ...this.state,
        selectedList: [],
        selectedEntityList: [],
        selectAllChecked: false,
      })
    } else {
      list.selectAllOperation(filteredQuery, dispatch, data => {
        this.setState({
          ...this.state,
          selectedList: data.map(x => x.id),
          selectedEntityList: data,
          selectAllChecked: true,
        })
      })
    }
  }

  /**
   *
   */
  changeExpanded = id => {
    const { expandedId } = this.state
    let newExpandedId = null

    if (expandedId === id) {
      newExpandedId = null
    } else {
      newExpandedId = id
    }

    this.setState({ expandedId: newExpandedId })
  }

  /**
   *
   */
  executeAction = action => {
    const { dispatch } = this.props
    const { selectedList, query } = this.state
    const filteredQuery = { ...query }

    action.exec(selectedList, dispatch, this.refresh, filteredQuery)

    this.setState({
      selectedList: [],
    })
  }

  /**
   *
   */
  handleSearchTextExecute = FilterText => {
    const { query } = this.state

    this.setState(
      {
        query: {
          ...query,
          FilterText,
          CurrentPage: 0,
        },
      },
      () => {
        this.refresh()
      },
    )
  }

  /**
   *
   */
  handleSortingColumnExecute = SortingColumn => {
    const { query } = this.state

    this.setState(
      { query: { ...query, SortingColumn, Asc: !query.Asc } },
      () => {
        this.refresh()
      },
    )
  }

  /**
   *
   */
  selectModeOnCancel = () => {
    const { onCancel } = this.props

    onCancel()
  }

  /**
   *
   */
  selectModeOnOk = () => {
    const { onOk } = this.props
    const { selectedList, selectedEntityList } = this.state

    onOk(selectedList, selectedEntityList)
  }

  handleRowClick = (event, id) => {
    if (event.target.tagName === 'TD' || event.target.tagName === 'P') {
      this.setState({
        anchorPosition: {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
          id: id,
        },
      })
    }
  }

  handleRowMenuClose = (event, id) => {
    this.setState({
      anchorPosition: {
        mouseX: null,
        mouseY: null,
        id: null,
      },
    })
  }

  /**
   *
   */
  renderTable = () => {
    const {
      query,
      selectedList,
      expandedId,
      anchorPosition,
      selectAllChecked,
    } = this.state
    const {
      classes,
      queryResult,
      queryResultChild,
      list,
      dispatch,
      showPaging,
      showSelect,
      showChildSelect,
      showChildList,
      usageMode,
      isChildFilterSameToParent,
      hideHeaderTitle,
      customFirstHeaderRow,
      showRowAction,
      multipleSelect,
      itemViewType = ListItemViewType.SINGLE_ROW,
      showDragDrop,
      onDragEnd,
      md,
      lg,
      applicationType,
      hoverActionEnabled,
    } = this.props

    if (itemViewType === ListItemViewType.DOUBLE_ROW) {
      return (
        <Grid className={classes.tableContainer}>
          <DoubleLineBody
            applicationType={applicationType}
            usageMode={usageMode}
            showChildList={showChildList}
            showSelect={showSelect}
            showChildSelect={showChildSelect}
            queryResult={queryResult}
            queryResultChild={queryResultChild}
            row={list.row}
            childList={list.childList}
            parentList={list}
            changeSelected={this.changeSelect}
            changeExpanded={this.changeExpanded}
            selectedList={selectedList}
            dispatch={dispatch}
            expandedId={expandedId}
            refresh={this.refresh}
            isChildFilterSameToParent={isChildFilterSameToParent}
            showRowAction={showRowAction}
            handleRowClick={this.handleRowClick}
            handleRowMenuClose={this.handleRowMenuClose}
            anchorPosition={anchorPosition}
          />
          {showPaging && (
            <Footer
              footerType={ListFooterType.GRID}
              queryResult={queryResult}
              query={query}
              changePage={this.changePage}
              selectedCount={selectedList.length}
              changeRowsPerPage={this.changeRowsPerPage}
            />
          )}
        </Grid>
      )
    }
    if (itemViewType === ListItemViewType.AVATAR) {
      return (
        <div className={classes.tableContainer}>
          {showSelect && (
            <HeaderFlex
              selectedCount={selectedList.length}
              selectAllChecked={selectAllChecked}
              totalCount={queryResult.totalCount}
              changeAllSelect={this.changeAllSelect}
            />
          )}
          <Grid className={classes.gridTable}>
            <AvatarBody
              usageMode={usageMode}
              showChildList={showChildList}
              showSelect={showSelect}
              showChildSelect={showChildSelect}
              queryResult={queryResult}
              queryResultChild={queryResultChild}
              row={list.row}
              childList={list.childList}
              parentList={list}
              changeSelected={this.changeSelect}
              changeExpanded={this.changeExpanded}
              selectedList={selectedList}
              dispatch={dispatch}
              expandedId={expandedId}
              refresh={this.refresh}
              isChildFilterSameToParent={isChildFilterSameToParent}
              showRowAction={showRowAction}
              handleRowClick={this.handleRowClick}
              handleRowMenuClose={this.handleRowMenuClose}
              anchorPosition={anchorPosition}
              md={md}
              lg={lg}
            />
          </Grid>
          {showPaging && (
            <FooterFlex
              queryResult={queryResult}
              query={query}
              changePage={this.changePage}
              changeRowsPerPage={this.changeRowsPerPage}
            />
          )}
        </div>
      )
    }

    return (
      <Table className={classes.table}>
        <div
          style={{
            backgroundColor: '#F4F6F8',
            borderRadius: 16,
            padding: 16,
            //width: '100%',
          }}
        >
          <Header
            columns={list.row.columns}
            hasChildList={list.childList != null}
            showChildList={showChildList}
            hideHeaderTitle={hideHeaderTitle}
            handleSortingColumnExecute={this.handleSortingColumnExecute}
            showSelect={showSelect}
            usageMode={usageMode}
            queryResult={queryResult}
            customFirstHeaderRow={customFirstHeaderRow}
            changeAllSelect={this.changeAllSelect}
            multipleSelect={multipleSelect}
            selectAllChecked={selectAllChecked}
            showDragDrop={showDragDrop}
          />

          <Body
            usageMode={usageMode}
            showChildList={showChildList}
            showSelect={showSelect}
            showChildSelect={showChildSelect}
            queryResult={queryResult}
            queryResultChild={queryResultChild}
            row={list.row}
            childList={list.childList}
            parentList={list}
            changeSelected={this.changeSelect}
            changeExpanded={this.changeExpanded}
            selectedList={selectedList}
            dispatch={dispatch}
            expandedId={expandedId}
            refresh={this.refresh}
            isChildFilterSameToParent={isChildFilterSameToParent}
            showRowAction={showRowAction}
            handleRowClick={this.handleRowClick}
            handleRowMenuClose={this.handleRowMenuClose}
            anchorPosition={anchorPosition}
            showDragDrop={showDragDrop}
            onDragEnd={onDragEnd}
            hoverActionEnabled={hoverActionEnabled}
          />
        </div>

        {showPaging && (
          <Footer
            queryResult={queryResult}
            query={query}
            changePage={this.changePage}
            selectedCount={selectedList.length}
            changeRowsPerPage={this.changeRowsPerPage}
          />
        )}
      </Table>
    )
  }

  renderDetailSearch = () => {
    const { showDetailSearch, classes } = this.props
    if (showDetailSearch == false) {
      return ''
    }
    return (
      <Grid item xs={3} className={classes.filterButtonHolder}>
        <Button
          onClick={this.handleSearchToggle}
          size="small"
          color="inherit"
          className={classes.filterButton}
        >
          <FilterListIcon />
          <Typography>{translator._('general.filter')}</Typography>
        </Button>
      </Grid>
    )
  }

  /**
   *
   */
  renderTopBar = () => {
    const {
      classes,
      list,
      usageMode,
      hideSearchBar,
      dispatch,
      showDetailSearch,
    } = this.props
    const { selectedList, query } = this.state

    return (
      <div className={classes.topBar}>
        <Grid
          item
          xs={12}
          className={classes.headerNavigation}
          hidden={hideSearchBar}
        >
          <div className={classes.headerNavigationRight}>
            <SearchInput searchTextExecute={this.handleSearchTextExecute} />
            {list.filters && !showDetailSearch && !isMobile && (
              <SearchLowDetail
                dispatch={dispatch}
                expanded={true}
                filters={list.filters}
                query={query}
                onUpdate={this.changeFiltersForLowDetail}
              />
            )}

            {this.renderDetailSearch()}
          </div>
        </Grid>
        <Grid item className={classes.actionButtons}>
          {usageMode === ListUseModeType.LIST && (
            <GrupActions
              actions={list.grupActions}
              executeAction={this.executeAction}
              selectedCount={selectedList.length}
            />
          )}
        </Grid>
      </div>
    )
  }

  /**
   *
   */
  render() {
    const {
      classes,
      queryResult,
      hideTopBar,
      usageMode,
      list,
      okButtonText,
      okButtonColor,
      dispatch,
      showDetailSearch,
    } = this.props
    const { showSearch, query } = this.state
    const showRows =
      queryResult && queryResult.results && queryResult.results.length > 0

    return (
      <Grid
        container
        className={ClassNames({
          [classes.root]: true,
          [classes.usageModeSelectRoot]: usageMode === ListUseModeType.SELECT,
        })}
      >
        {usageMode === ListUseModeType.SELECT && (
          <FooterAction
            onCancel={this.selectModeOnCancel}
            onOk={this.selectModeOnOk}
            okButtonText={okButtonText}
            okButtonColor={okButtonColor}
          />
        )}

        <Grid
          container
          className={ClassNames({
            [classes.usageModeSelectContent]:
              usageMode === ListUseModeType.SELECT,
          })}
        >
          {!hideTopBar && this.renderTopBar()}

          {list.filters && showDetailSearch && (
            <SearchDetail
              dispatch={dispatch}
              expanded={showSearch}
              filters={list.filters}
              query={query}
              onUpdate={this.changeFilters}
            />
          )}
          {showRows && this.renderTable()}

          {!showRows && queryResult.results != null && <NoData />}
        </Grid>

        {usageMode === ListUseModeType.SELECT && (
          <FooterAction
            onCancel={this.selectModeOnCancel}
            onOk={this.selectModeOnOk}
            okButtonText={okButtonText}
            okButtonColor={okButtonColor}
          />
        )}
      </Grid>
    )
  }

  /*queryModifier = (list, parentList, isChildFilterSameToParent) => {
    
   
    if (parentList && isChildFilterSameToParent == true) {
      this.setState(
        {
          query: {
            ...this.state.query,
            Filters: parentList.filtersModel.Filters,
          },
        },
        () => {
          this.refresh()
        },
      )
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            Filters: list.filtersModel.Filters,
          },
        },
        () => {
          this.refresh()
        },
      )
    }
    
  }*/

  componentWillReceiveProps(newProps) {
    /*if (
      (newProps.startDate != undefined &&
        newProps.startDate != this.props.startDate) ||
      (newProps.endDate != undefined &&
        newProps.endDate != this.props.endDate) ||
      newProps.teamId != this.props.teamId
    ) {
      this.refresh()

      //const { list, parentList, isChildFilterSameToParent } = newProps

      //this.queryModifier(list, parentList, isChildFilterSameToParent)
    }*/
  }
  /**
   *
   */
  componentDidMount() {
    const { list, parentList, isChildFilterSameToParent } = this.props

    /*if (list.filtersModel) {
      this.queryModifier(list, parentList, isChildFilterSameToParent)
    } else {
      this.refresh()
    }*/

    this.refresh()
  }
}

List.propTypes = propTypes

export default withStyles(style, { withTheme: true })(List)
