import React, { useState } from 'react'
import styles from '../../style'
import {
  Button,
  withStyles,
  Typography,
  Modal,
  Box,
  Popover,
} from '@material-ui/core'
import { Icon } from '@iconify/react'

import SettingsDrawer from 'src/components/settings-drawer'
import AnalyticsDrawer from 'src/components/analytics-drawer'

import LinkIcon from '@material-ui/icons/LinkOutlined'
import CodeIcon from '@material-ui/icons/Code'

import ShareLinkStep from 'src/pages/create-quiz/share-link'
import EmbedStep from 'src/pages/create-quiz/embed'
import { AssignmentFieldNames } from 'src/model/assignment'
import ExportButton from '../export-button'
import ImportButton from '../import-button'
import { useHistory } from 'react-router-dom'

import {
  assignmentReportAction,
  exportCourseExcelAction,
  importCourseExcelAction,
  importCourseTxtAction,
  exportCourseTxtAction,
} from 'src/reducers/assignment/action'
import URLs from 'src/api/url'
import UserType, { UserTypeNames } from 'src/model/user-types'
import ScormDownloadModal from 'src/components/scorm-download-modal'

const CourseNavbar = props => {
  const { entity, dispatch, classes } = props
  let history = useHistory()
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false)
  const [shareEmbedModalOpen, setShareEmbedModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElSec, setAnchorElSec] = useState(null)
  const open = Boolean(anchorEl)
  const opensec = Boolean(anchorElSec)
  const id = open ? 'simple-popover' : undefined

  const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]
  const [scormPopupOpen, setScormPopupOpen] = React.useState(false)

  const premiumControl = () => {
    if (
      userControl == UserTypeNames.PREMIUM_PLUS ||
      userControl == UserTypeNames.PREMIUM_PLUS_V2 ||
      userControl == UserTypeNames.PERMIUM_A ||
      userControl == UserTypeNames.APPSUMO_TIER_3A ||
      userControl == UserTypeNames.APPSUMO_TIER_3
    ) {
      return false
    } else return true
  }

  const [scormExportUserEligable, setScormExportEligable] = React.useState(
    premiumControl(),
  )

  const handleOpenLink = () => {
    setShareLinkModalOpen(true)
  }
  const handleOpenShare = () => {
    setShareEmbedModalOpen(true)
    setAnchorElSec(null)
  }
  const handleCloseShare = () => {
    setAnchorElSec(null)
  }
  const handleCloseShareLink = () => setShareLinkModalOpen(false)
  const handleCloseShareEmbed = () => setShareEmbedModalOpen(false)

  const [settingsDrawerOpen, setSettingDrawerOpen] = useState(false)
  const [analyticsDrawerOpen, setAnalyticsDrawerOpen] = useState(false)
  const handleClickShare = event => {
    setAnchorElSec(event.currentTarget)
  }
  const handleGetAnalytics = () => {
    dispatch(assignmentReportAction(entity.id, entity.name))
  }
  const handleExportExcelCourse = () => {
    dispatch(
      exportCourseExcelAction(
        entity[AssignmentFieldNames.ID],
        entity[AssignmentFieldNames.NAME],
      ),
    )
  }
  const handleRedirecPricing = () => {
    history.push('/pricing')
  }
  const handleExportTxtCourse = () => {
    dispatch(
      exportCourseTxtAction(
        entity[AssignmentFieldNames.ID],
        entity[AssignmentFieldNames.NAME],
      ),
    )
  }

  const handleFileChangeExcel = event => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    const file = event.target.files[0]

    let formData = new FormData()
    formData.append('courseId', entity[AssignmentFieldNames.ID])
    formData.append('file', file)

    dispatch(importCourseExcelAction(formData, response => {}))
  }
  const handleFileChangeTxt = event => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    const file = event.target.files[0]

    let formData = new FormData()
    formData.append('courseId', entity[AssignmentFieldNames.ID])
    formData.append('file', file)

    dispatch(importCourseTxtAction(formData, response => {}))
  }
  const handleScormClose = () => setScormPopupOpen(false)
  const handleScormClick = () => {
    setScormPopupOpen(true)
  }
  return (
    <>
      <Modal
        open={shareLinkModalOpen}
        onClose={handleCloseShareLink}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <ShareLinkStep dispatch={dispatch} entity={entity}></ShareLinkStep>
        </Box>
      </Modal>

      <Modal
        open={shareEmbedModalOpen}
        onClose={() => {
          handleCloseShareEmbed()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <EmbedStep dispatch={dispatch} entity={entity}></EmbedStep>
        </Box>
      </Modal>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0 24px',
        }}
      >
        <div
          style={{
            height: 40,
            backgroundColor: '#fff',
            width: '100%',
            borderRadius: 16,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 8,
          }}
        >
          <Button
            size="small"
            variant="outlined"
            href={
              entity ? URLs.APP_URL + entity[AssignmentFieldNames.CODE] : ''
            }
            target="_blank"
            className={classes.previewBtn}
          >
            <Icon
              icon="ph:eye-thin"
              style={{ marginTop: '3px', marginRight: '5px' }}
              width="16"
              height="16"
            />
            <Typography className={classes.buttonTextes}>View</Typography>
          </Button>
          <div style={{ flexGrow: 1 }}></div>

          <Button
            size="small"
            variant="outlined"
            className={classes.previewBtn}
            onClick={() => {
              setSettingDrawerOpen(true)
            }}
          >
            <Icon
              icon="ion:settings-outline"
              style={{ marginTop: '3px', marginRight: '5px' }}
              width="16"
              height="16"
            />
            <Typography className={classes.buttonTextes}>Settings</Typography>
          </Button>

          <Button
            size="small"
            variant="outlined"
            className={classes.previewBtn}
            onClick={() => {
              setAnalyticsDrawerOpen(true)
              //track('report_clicked')
            }}
          >
            <Icon
              icon="material-symbols:equalizer"
              style={{ marginTop: '3px', marginRight: '5px' }}
              width="16"
              height="16"
            />
            <Typography className={classes.buttonTextes}>Report</Typography>
          </Button>
          <ImportButton
            handleRedirecPricing={handleRedirecPricing}
            handleFileChangeExcel={handleFileChangeExcel}
            handleFileChangeTxt={handleFileChangeTxt}
          />
          <ExportButton
            handleRedirecPricing={handleRedirecPricing}
            handleExportExcelCourse={handleExportExcelCourse}
            handleExportTxtCourse={handleExportTxtCourse}
          />

          <Button
            size="small"
            variant="outlined"
            disableElevation={true}
            className={classes.shareButton}
            onClick={event => {
              handleClickShare(event)
            }}
          >
            <Typography className={classes.buttonShareText}>Share</Typography>
          </Button>
        </div>
        <Popover
          open={opensec}
          anchorEl={anchorElSec}
          onClose={handleCloseShare}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{
            marginTop: '8px',
            marginRight: '55px',
          }}
        >
          <div className={classes.shareButtonGroup}>
            <div
              onClick={() => handleOpenLink()}
              className={classes.shareButtonGroupText}
            >
              <LinkIcon
                style={{
                  fontSize: '16px',
                  marginTop: '3px',
                  marginRight: '5px',
                  color: '#FFD243',
                }}
              />
              <Typography
                textAlign="center"
                style={{ width: 'max-content', fontSize: '0.775rem' }}
              >
                Share with <br /> a Link
              </Typography>
            </div>

            <div
              onClick={() => handleOpenShare()}
              className={classes.shareButtonGroupText}
            >
              <CodeIcon
                style={{
                  fontSize: '16px',
                  marginTop: '3px',
                  marginRight: '5px',
                  color: '#FF8E4F',
                }}
              />
              <Typography
                textAlign="center"
                style={{ width: 'max-content', fontSize: '0.775rem' }}
              >
                Embed on <br /> a website
              </Typography>
            </div>
            <div
              onClick={handleScormClick}
              className={classes.shareButtonGroupText}
            >
              <Icon
                size="small"
                icon="octicon:package-16"
                color="#497fc9"
              ></Icon>
              <Typography
                textAlign="center"
                style={{ width: 'max-content', fontSize: '0.775rem' }}
              >
                Export to <br /> SCORM{' '}
                <Icon
                  size="small"
                  icon="fa6-solid:crown"
                  color="#f7b155"
                ></Icon>
              </Typography>
            </div>
          </div>
        </Popover>

        {entity && (
          <SettingsDrawer
            dispatch={dispatch}
            entity={entity}
            setSettingDrawerOpen={setSettingDrawerOpen}
            drawerOpen={settingsDrawerOpen}
          />
        )}
        {entity && analyticsDrawerOpen && (
          <AnalyticsDrawer
            dispatch={dispatch}
            entity={entity}
            setAnalyticsDrawerOpen={setAnalyticsDrawerOpen}
            analyticsDrawerOpen={analyticsDrawerOpen}
            handleGetAnalytics={handleGetAnalytics}
          />
        )}
        <Modal
          open={scormPopupOpen}
          onClose={() => {
            handleScormClose()
          }}
          aria-labelledby="scorm-modal"
          aria-describedby="scorm-modal"
        >
          <Box className={classes.modalStyleScorm}>
            <ScormDownloadModal
              dispatch={dispatch}
              entity={entity}
              onClose={() => handleScormClose()}
            />
          </Box>
        </Modal>
      </div>
    </>
  )
}
export default withStyles(styles)(CourseNavbar)
