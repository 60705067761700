const URL = {
  LIST_TRAINING: 'Training/GetAllTrainingsAsyncForShop',
  LIST_TRAINING_PLAN: 'Training/GetAllTrainingPlansAsyncForTraining',
  GET_TRAINING: 'Training/GetTrainingAsync',
  GET_TRAINING_PLAN: 'Training/GetTrainingPlanAsync',
  GET_TRAINING_DAY: 'Training/GetTrainingDayAsync',
  GET_TRAINING_DAYS_OF_TRAINING: 'Training/GetTrainingDaysofTrainingAsync',
  ADD_TRAINING: 'Training/AddTraining',
  ADD_TRAINING_PLAN: 'Training/AddTrainingPlan',
  ADD_TRAINING_QUESTION: 'Training/AddTrainingQuestion',
  ADD_TRAINING_DAY: 'Training/AddTrainingDay',
  ADD_TRAINING_DAY_HOUR: 'Training/AddTrainingDayHour',
  ADD_TRAINING_PLAN_TAG: 'Training/AddTrainingPlanTag',
  PATCH_TRAINING: 'Training/PatchTraining',
  PATCH_TRAINING_PLAN: 'Training/PatchTrainingPlan',
  PATCH_TRAINING_DAY: 'Training/PatchTrainingDay',
  PATCH_TRAINING_DAY_HOUR: 'Training/PatchTrainingDayHour',
  PATCH_TRAINING_QUESTION: 'Training/PatchTrainingQuestion',
  DELETE_TRAINING: 'Training/DeleteTraining',
  DELETE_TRAINING_DAY: 'Training/DeleteTrainingDay',
  DELETE_TRAINING_DAY_HOUR: 'Training/DeleteTrainingDayHour',
  DELETE_TRAINING_PLAN: 'Training/DeleteTrainingPlan',
  DELETE_TRAINING_QUESTION: 'Training/DeleteTrainingQuestion',
  DELETE_TRAINING_PLAN_TAG: 'Training/DeleteTrainingPlanTag',
  SEND_TRAINING_TO_ENOCTA: 'Integration/SendTrainingToEnocta',
  TRAINING_REPORT_PARTICIPANT_LINE_SERIES:
    'Report/TrainingReportParticipantLineSeries',
  TRAINING_REPORT_CARD_VALUE: 'Report/TrainingReportCardValue',
  TRAINING_REPORT_CONTENT_BREAKDOWN: 'Report/TrainingReportContentBreakdown',
  TRAINING_REPORT_TEAM_ENGAGEMENT_DATA:
    'Report/TrainingReportTeamEngagementData',
  TRAINING_REPORT_TEAM_PERFORMANCE_DATA:
    'Report/TrainingReportTeamPerformanceData',
  TRAINING_REPORT_TEAM_IMPROVEMENT_DATA: 'Report/ImprovementTeamQueryReport',
  TRAINING_REPORT_RELATED_SUBJECT_LIST:
    'Report/TrainingReportRelatedSubjectList',
  TRAINING_REPORT_USER_DATA: 'Report/TrainingReportUserData',
  ACTIVE_TRAINING_LIST: 'Training/MakeTrainingListActive',
  PASSIVE_TRAINING_LIST: 'Training/MakeTrainingListPassive',
  DELETE_TRAINING_LIST: 'Training/DeleteTrainingList',
  TRAINING_QUESTION_CLONE: 'Training/CloneTrainingQuestion',
  CLONE_TRAINING: 'Training/CloneTraining',
  TRAINING_TEST: 'Training/Test',
}

export default URL
