import React from 'react'
import style from '../style'
import propTypes from '../prop'
import ClassNames from 'classnames'
import { withStyles, TableCell, Checkbox } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const ListContainerColumnSelect = props => {
  const { classes, checked, changeSelected, entity } = props

  /**
   *
   * @param {*} event
   */
  const handleChange = event => {
    event.stopPropagation()

    changeSelected(entity.id, event.target.checked, entity, event)
  }

  return (
    <TableCell className={ClassNames(classes.root, classes.selectColumn)}>
      <Checkbox checked={checked} onChange={handleChange} color="primary" />
    </TableCell>
  )
}

ListContainerColumnSelect.propTypes = propTypes

export default withStyles(style)(ListContainerColumnSelect)
