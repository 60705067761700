import React, { useState, useEffect } from 'react'
import styles from '../style'
import propTypes from '../prop'
import ClassNames from 'classnames'
import translator from '../../../../translate'
import {
  withStyles,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import withMediaLayout from '../layout-hoc'
import FolderIcon from '@material-ui/icons/Folder'
import { MediaItemType } from '../../../../model/media-directory'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { PatchContent, PatchData } from '../../../../model/patch'
import Zoom from '@material-ui/core/Zoom'
import {
  ButtonType,
  ButtonData,
  ButtonActionType,
} from '../../../../model/button'
import MessageBoxContent, {
  MessageBoxType,
} from '../../../../model/message-box'

import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../../reducers/message-box/action'

import {
  mediaFolderPatchAction,
  mediaFolderDeleteAction,
} from '../../../../reducers/media-folder/action'

/**
 *
 * @param {*} props
 */
const MediaItemFolder = props => {
  const { classes, onSelect, id, name, dispatch } = props
  const [updatedName, setName] = useState(name)

  useEffect(() => {
    setName(name)
  }, [name])

  const [focus, setFocus] = useState(false)
  const [hideItem, setHideItem] = useState(true)
  const [displayItem, setDisplayItem] = useState('block')

  /**
   *
   * @param {*} e
   */
  const onChange = e => {
    setName(e.currentTarget.value)
  }

  /**
   *
   */
  const onFocus = () => {
    setFocus(true)
  }

  /**
   *
   */
  const onBlur = () => {
    setFocus(false)

    if (name !== updatedName) {
      dispatch(
        mediaFolderPatchAction(
          new PatchData(
            new PatchContent(
              updatedName,
              'name',
              PatchContent.OPERATION_REPLACE,
            ),
            id,
          ),
          () => {},
        ),
      )
    }
  }

  const onDelete = () => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          translator._('message.deleteFolder'),
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              () => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.yes'),
              ButtonActionType.DELETE,
              () => {
                dispatch(
                  mediaFolderDeleteAction({ id }, () => {
                    dispatch(messageBoxHideAction())
                    setHideItem(false)
                  }),
                )
              },
            ),
          ],
        ),
      ),
    )
  }

  return (
    <Zoom in={hideItem} onExited={() => setDisplayItem('none')} appear={false}>
      <div style={{ display: displayItem }}>
        <div
          data-id={id}
          data-type={MediaItemType.FOLDER}
          onClick={onSelect}
          className={classes.folderIconHolder}
        >
          <FolderIcon className={classes.folderIcon} />
        </div>
        <IconButton
          className={classes.deleteButton}
          size="small"
          onClick={onDelete}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>

        <TextField
          value={updatedName}
          InputProps={{
            className: ClassNames({
              [classes.input]: true,
              [classes.blur]: !focus,
            }),
            endAdornment: (
              <InputAdornment position="end">
                <EditIcon className={classes.endAdornmentIcon} />
              </InputAdornment>
            ),
          }}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </Zoom>
  )
}

MediaItemFolder.propTypes = propTypes

export default withMediaLayout(withStyles(styles)(MediaItemFolder))
