const style = theme => ({
  addPasswordCard: {
    margin: '0 24px',
    width: '100%',
  },
  addPasswordCardContent: {
    margin: '24px 24px 0',
    border: '2px dashed #dcdcdc',
    borderRadius: '20px',
    height: 38,
    display: 'flex',
    flexDirection: 'row',
    background: '#ffffff',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addPasswordCardContentHeader: {
    display: 'flex',
  },
  headerTexts: {
    color: '#f67b3f',
    textAlign: 'left',
  },
  completionSvg: {
    marginRight: '10px',
  },
})

export default style
