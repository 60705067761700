import { guid } from '../../../../utils/guid'

const Filters = {
  addTitle: guid().uid(),
  editTitle: null,
  groups: [],
  formItemsSuggestion: [],
}

export default Filters
