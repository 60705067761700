const URL = {
  VERIFY_CNAME: 'customDomain/verifyCName',
  SAVE_DOMAIN: 'customDomain/addCustomDomain',
  GET_CUSTOM_DOMAIN_LIST: 'customDomain/getCustomDomainList',
  LIST_DOMAIN: 'customDomain/GetCustomDomainsByFilter',
  RETRY_DOMAIN: 'customDomain/requestDomainVerification',
  DELETE_DOMAIN: 'customDomain/deleteCustomDomain',
  PATCH_DOMAIN: 'customDomain/Patch',
}

export default URL
