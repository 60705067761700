import { put, call, take, fork } from 'redux-saga/effects'
import { userListService } from '../../api/user'
import { userIdListSuccess } from '../../reducers/user/action'
import { USER_ID_LIST } from '../../reducers/user/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* userIdListFlow(payload) {
  try {
    const { query, callback } = payload
    const response = yield call(userListService, query)
    if (
      response.data.results.length == 0 &&
      query.FilterText == '' &&
      query.Filters == undefined
    ) {
      response.data.results = null
    }

    callback(response.data.results)

    yield put(userIdListSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* userIdListWatcher() {
  while (true) {
    const action = yield take(USER_ID_LIST)
    const { payload } = action

    yield fork(generalSaga, userIdListFlow, payload)
  }
}
