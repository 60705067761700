import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import FormChangePassword from '../../container/form-renew-password'
import ProfileInformation from '../../container/profile'
import { Typography, Grid, Container, Card } from '@material-ui/core'
import {
  userUpdateEntityAction,
  userGetSelfAction,
} from '../../reducers/user/action'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
class Profile extends Component {
  /**
   *
   */
  render() {
    const { entity, dispatch, classes } = this.props
    let children = <Typography />

    if (entity != null) {
      children = (
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} md={6} className={classes.holder}>
            <ProfileInformation />
          </Grid>
          <Grid item xs={12} md={6} className={classes.holder}>
            <FormChangePassword dispatch={dispatch} hasHeader={true} />
          </Grid>
        </Grid>
      )
    }

    return children
  }

  /**
   *
   */
  componentDidMount() {
    const { dispatch } = this.props

    dispatch(userGetSelfAction())
  }

  /**
   *
   */
  componentWillUnmount() {
    const { dispatch } = this.props

    dispatch(userUpdateEntityAction(null))
  }
}

Profile.propTypes = propTypes

const mapStateToProps = response => ({
  entity: response.user.entity,
})

export default connect(mapStateToProps)(withStyles(styles)(Profile))
