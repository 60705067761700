import PropTypes from 'prop-types'
import {
  FormPropTypes,
  FormUseModeTypes,
  FormUpdateMethodTypes,
  FormStateModeTypes,
  FormViewTypes,
} from '../../form/props'

const propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onPatch: PropTypes.func,
  entity: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.shape(FormPropTypes).isRequired,
  useMode: PropTypes.oneOf(FormUseModeTypes).isRequired,
  updateMethod: PropTypes.oneOf(FormUpdateMethodTypes).isRequired,
  stateMode: PropTypes.oneOf(FormStateModeTypes).isRequired,
  viewMode: PropTypes.oneOf(FormViewTypes),
}

export default propTypes
