import moment from 'moment-timezone'

export const TIME_ZONE = {
  ISTANBUL: 'Europe/Istanbul',
  ABIDJAN: 'Africa/Abidjan',
}

export const TIME_ZONE_LIST = moment.tz.names().map(element => {
  return { value: element, label: element }
})
