const URL = {
  MEDIA_FOLDERS_GET_BY_FILTER: 'Media/GetFoldersByFilter',
  MEDIA_FOLDER_GET_BY_ID: 'Media/GetFolderById',
  MEDIA_FOLDER_GET_ROOT: 'Media/GetRootFolder',
  MEDIA_FOLDER_ADD_ASYNC: 'Media/AddFolderAsync',
  MEDIA_FOLDER_PATCH: 'Media/PatchFolder',
  MEDIA_FOLDER_DELETE_LIST: 'Media/DeleteFolderList',
  MEDIA_FOLDER_DELETE: 'Media/DeleteFolder',
}

export default URL
