import React from 'react'
import style from './style'
import propTypes from '../prop'
import ClassNames from 'classnames'
import ClearIcon from '@material-ui/icons/Clear'
import { withStyles, Grid, Typography, IconButton } from '@material-ui/core'

const ItemLayoutSearch = props => {
  const { classes, element, formItem } = props

  /**
   *
   * @param {*} e
   */
  const onClearButtonClickHandler = e => {
    formItem.onClear(formItem)
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.root}
    >
      <Grid
        className={ClassNames({
          [classes.itemWrapper]: true,
          [classes.hasLabel]: formItem.label != null,
        })}
        item
      >
        {formItem.label && (
          <Grid container>
            <Grid item className={classes.formLabelContainer}>
              <Typography
                variant="body1"
                className={ClassNames({
                  [classes.formLabel]: true,
                })}
              >
                {formItem.label}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={onClearButtonClickHandler}>
                <ClearIcon className={classes.clearIcon} />
              </IconButton>
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid container>{element}</Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ItemLayoutSearch.propTypes = propTypes

export default withStyles(style)(ItemLayoutSearch)
