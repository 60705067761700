import PropTypes from 'prop-types'

const propTypes = {
  formContent: PropTypes.object,
  formController: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  updateFormContent: PropTypes.func.isRequired,
}

export default propTypes
