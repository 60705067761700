import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} user
 */
export const verifyCNAME = domain =>
  new Request(URL.API, URL.VERIFY_CNAME).post({ domain })

/**
 *
 */
export const saveDomainService = (
  domain,
  defaultCollectionId,
  defaultCourseId,
  type,
) =>
  new Request(URL.API, URL.SAVE_DOMAIN).post({
    domain,
    defaultCollectionId,
    defaultCourseId,
    type,
  })

/**
 *
 */
export const domainListService = query =>
  new Request(URL.API, URL.LIST_DOMAIN).post(query)

/**
 * @param {*} id
 */
export const domainDeleteService = id =>
  new Request(URL.API, URL.DELETE_DOMAIN).post({ id })

/**
 * @param {*} id
 */
export const domainRetryService = id =>
  new Request(URL.API, URL.RETRY_DOMAIN).post({ id })

/**
 *
 * @param {*} content
 */
export const domainPatchService = content =>
  new Request(URL.API, URL.PATCH_DOMAIN + '?id=' + content.id).patch(content)
