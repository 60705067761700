import React from 'react'
// material
import { Container, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import style from './style'
import ListCourseStatistics from '../../../container/list-course-statistics'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function CourseStatistics(props) {
  const { classes } = props

  return (
    <Container>
      <React.Fragment>
        <div className={classes.card}>
          <ListCourseStatistics></ListCourseStatistics>
        </div>{' '}
      </React.Fragment>
    </Container>
  )
}

export default connect()(withStyles(style)(CourseStatistics))
