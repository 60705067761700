const styles = theme => ({
  root: {
    height: 40,
  },
  limitText: {
    lineHeight: 0.5,
    textAlign: 'end',
    fontWeight: 300,
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: 1,
    },
  },
  limitTextSpan: { fontWeight: 600 },
  limitTextRenewal: {
    fontWeight: 200,
    fontSize: '10px',
    marginTop: 5,
    textAlign: 'end',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: 1,
      marginTop: 0,
      marginLeft: 3,
    },
  },
  creditHolder: {
    marginRight: 60,
  },
})

export default styles
