import React, { useState } from 'react'
import MyAvatar from 'src/components/MyAvatar'
import styles from './styles'
import { ListColumnType } from 'src/model/list'
import { ListContainerColumnActionHover } from '../../../column'
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  Checkbox,
  withStyles,
} from '@material-ui/core'

const AvatarListItem = props => {
  const {
    entity,
    row,
    showSelect,
    checked,
    changeSelected,
    index,
    showRowAction,
    dispatch,
    refresh,
    classes,
    md,
    lg,
  } = props

  const [areButtonsVisible, setButtonsVisible] = useState(false)

  const handleChange = event => {
    event.stopPropagation()

    changeSelected(entity.id, event.target.checked, entity, event)
  }

  const renderProfilePhoto = (entity, column) => {
    if (column[1].converter === null) {
      return null
    }
    const firstCharsAtName = column[1].converter(entity)

    const picture = entity[column[0].attribute]
    if (!!picture) {
      return <img src={picture} alt={' '} className={classes.profilePhoto} />
    }
    return <MyAvatar text={firstCharsAtName} />
  }

  const renderOnHoverTeams = (teamString, label, entity) => {
    let title = teamString(entity)
    return (
      <Tooltip title={title} className={classes.teamButtonHolder}>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          className={classes.teamButton}
        >
          {label}
        </Button>
      </Tooltip>
    )
  }

  return (
    <Grid
      container
      xs={12}
      md={md}
      lg={lg}
      key={index}
      className={classes.grid}
      onMouseEnter={e => {
        setButtonsVisible(true)
      }}
      onMouseLeave={e => {
        setButtonsVisible(false)
      }}
      className={classes.container}
    >
      {showSelect && (
        <Grid item xs={1} className={classes.checkbox}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            color="secondary"
          />
        </Grid>
      )}

      <Grid item xs={9} className={classes.gridLeft}>
        {renderProfilePhoto(entity, row.columns)}

        <div className={classes.infoGroup}>
          <div className={classes.infoTopLine}>
            {row.columns[1].renderer != null
              ? row.columns[1].renderer(entity)
              : ''}
            {row.columns[3] &&
              row.columns[3].converter &&
              renderOnHoverTeams(
                row.columns[3].converter,
                row.columns[3].label,
                entity,
              )}
          </div>
          <Typography variant="body2">
            {entity[row.columns[2].attribute]}
          </Typography>

          {areButtonsVisible && (
            <div className={classes.iconGroup}>
              {row.columns.map((column, index) => {
                let renderer = ''
                if (typeof column.hidden === 'function') {
                  let hidden = column.hidden(entity)
                  if (hidden === true) {
                    return ''
                  }
                } else if (column.hidden === true) {
                  return ''
                }
                if (!column.renderer) {
                  if (column.type == ListColumnType.ACTION) {
                    if (showRowAction !== false) {
                      renderer = (
                        <ListContainerColumnActionHover
                          key={index}
                          column={column}
                          entity={entity}
                          dispatch={dispatch}
                          refresh={refresh}
                        />
                      )
                    }
                  }
                }
                return renderer
              })}
            </div>
          )}
        </div>
      </Grid>

      {/*   <Grid item xs={2}>
       
      </Grid> */}
    </Grid>
  )
}

export default withStyles(styles)(AvatarListItem)
