import React, { useEffect } from 'react'
import styles from './styles'
import {
  withStyles,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {
  TeamsHeader,
  TeamView,
  TeamNamingModal,
  NoTeamsView,
} from './components'
import { useDispatch, useSelector } from 'react-redux'
import { teamListAction } from 'src/reducers/teams/action'

const Teams = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const [openNamingModal, setOpenNamingModal] = React.useState(false)

  useEffect(() => {
    dispatch(teamListAction({}, response => {}))
  }, [])

  const teamList = useSelector(state => state.teamsList.teamList)
  const handleEditTeam = () => {
    setOpenNamingModal(true)
  }
  return (
    <div className={classes.root}>
      <TeamsHeader dispatch={dispatch} />
      <div
        style={{
          height: teamList && teamList.length > 0 ? 'fit-content' : '100%',
        }}
        className={classes.teamListRoot}
      >
        <div className={classes.teamListRoot__teamDiv}>
          {teamList != null &&
            (teamList.length > 0 ? (
              teamList.map((item, index) => (
                <TeamView
                  key={index}
                  teamId={item.id}
                  teamName={item.name}
                  userCount={item.memberCount}
                  dispatch={dispatch}
                />
              ))
            ) : (
              <NoTeamsView handleEditTeam={handleEditTeam} />
            ))}
        </div>
      </div>
      {openNamingModal && (
        <TeamNamingModal
          isModalOpen={openNamingModal}
          handleCloseModal={() => setOpenNamingModal(false)}
          dispatch={dispatch}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(Teams)
