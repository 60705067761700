import React, { useState, useEffect } from 'react'
//
//import { IconPicker } from 'react-fa-icon-picker'
//
import styles from './style'
import propTypes from './prop'
import ClassNames from 'classnames'
import translator from '../../../translate'
import { componentBoxHideAction } from '../../../reducers/component-box/action'
import Input from '@material-ui/core/Input'
import { assignmentCardLinkPatchAction } from 'src/reducers/assignment/action'
import { questionLinkAddAction } from '../../../reducers/question/action'
import { PatchContent, PatchData } from 'src/model/patch'
import { assignmentListAction } from '../../../reducers/assignment/action'
import { documentUploadPDFService } from '../../../api/document'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'

import {
  QuestionFieldNames,
  QuestionLinkFieldNames,
} from '../../../model/question'
import {
  Grid,
  withStyles,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  Alert,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { cos } from '@amcharts/amcharts4/.internal/core/utils/Math'
import URL from 'src/api/url/api'
import { useDispatch, useSelector } from 'react-redux'
import { AssignmentFieldNames } from 'src/model/assignment'

const QuestionCreateLink = props => {
  const {
    classes,
    cardId,
    linkId,
    selectedItem,
    deleteButtonClickHandler,
  } = props
  const baseEntity = useSelector(state => state.assignment.entity)
  const baseEntityDtos = useSelector(
    state => state.assignment.entity[AssignmentFieldNames.QUESTION_DTOS],
  )
  const dispatch = useDispatch()

  const entity = baseEntityDtos.find(x => x[QuestionFieldNames.ID] == cardId)

  const linkEntity = entity
    ? entity[QuestionFieldNames.QUESTION_LINKS].find(x => x.id == linkId)
    : null

  const [entityURL, setEntityURL] = useState(linkEntity ? linkEntity.url : '')
  const [isHttp, setIsHttp] = useState(true)
  const [PdfURL, setPdfURL] = useState(
    linkEntity
      ? linkEntity[QuestionLinkFieldNames.TYPE] == 'pdf'
        ? linkEntity.url
        : ''
      : '',
  )
  const [title, setTitle] = useState(
    linkEntity ? linkEntity[QuestionLinkFieldNames.TITLE] : '',
  )
  const [type, setType] = useState(
    linkEntity ? linkEntity[QuestionLinkFieldNames.TYPE] : selectedItem,
  )
  const [courses, setCourses] = useState(false)
  const [icon, setIcon] = useState('')
  const [checked, setChecked] = useState(
    linkEntity
      ? linkEntity[QuestionLinkFieldNames.TARGET] == '_blank'
        ? false
        : true
      : false,
  )

  /**
   *
   */
  const createButtonClickHandler = () => {
    if (linkEntity) {
      deleteButtonClickHandler(linkEntity)
    } else {
      let object = {}
      object[QuestionLinkFieldNames.QUESTION_ID] = entity[QuestionFieldNames.ID]
      object[QuestionLinkFieldNames.TITLE] = title
      object[QuestionLinkFieldNames.URL] = entityURL
      object[QuestionLinkFieldNames.TYPE] = type
      object[QuestionLinkFieldNames.TARGET] = checked ? '_self' : '_blank'
      object[QuestionLinkFieldNames.ICON] = icon
      object[QuestionLinkFieldNames.THUMBNAIL] = ''
      object[QuestionLinkFieldNames.ATTENTIONTEXT] = ''
      dispatch(
        questionLinkAddAction(object, () => {
          dispatch(componentBoxHideAction())
        }),
      )
    }
  }

  /**
   * Edit with blur
   */
  const handlePatchData = async (type, value) => {
    if (linkEntity == null || linkEntity == undefined) {
      return false
    }

    let cardId = linkEntity[QuestionLinkFieldNames.QUESTION_ID]
    await dispatch(
      assignmentCardLinkPatchAction(
        new PatchData(
          new PatchContent(value, type, PatchData.OPERATION_REPLACE),
          linkEntity[QuestionLinkFieldNames.ID],
        ),
        cardId,
        () => {},
      ),
    )
  }

  // State Changes

  /**
   *
   * @param {*} e
   */
  const handleURL = e => {
    setEntityURL(e.target.value)
    setIsHttp(e.target.value.startsWith('http'))
  }

  /**
   *
   * @param {*} e
   */
  const handleTitle = e => {
    setTitle(e.target.value)
  }

  /**
   *
   * @param {*} e
   */
  const handleType = e => {
    setType(e.target.value)
    setPdfURL()
    setEntityURL()
  }

  /**
   *
   * @param {*} e
   * @param {*} b
   */
  const handleCourse = (e, b) => {
    let linkTitle = b.props.children
    let linkUrl = e.target.value
    setTitle(b.props.children)
    setEntityURL(e.target.value)
    handlePatchData('title', linkTitle)
    handlePatchData('url', linkUrl)
    setIsHttp(true)
  }

  const handleCheckBox = () => {
    let linkTarget = !checked ? '_self' : '_blank'
    handlePatchData('target', linkTarget)
    setChecked(!checked)
  }

  const choosePdf = async e => {
    const file = e.target.files[0]

    var formData = new FormData()

    formData.append('file', file)

    if (file.type !== 'application/pdf') {
      alert(translator._('question.addQuestion.createLink.PleaseSelectPdf'))
      return
    }

    let response = await documentUploadPDFService(formData)
    if (linkEntity) {
      handlePatchData('url', response.data.url)
    }
    setPdfURL(response.data.url)

    setEntityURL(response.data.url)
    e.preventDefault()
    e.stopPropagation()
  }

  const removePdf = () => {
    setPdfURL()
    setEntityURL()
  }
  useEffect(() => {
    if (!courses) {
      const query = {
        CurrentPage: 0,
        PageSize: 200,
        SortingColumn: 'LastModifiedAt',
        Asc: false,
        FilterText: '',
        Filters: [],
      }
      dispatch(
        assignmentListAction(
          { ...query, SortingColumn: 'LastModifiedAt' },

          data => {
            setCourses(data.data.results)
          },
        ),
      )
    }
  }, [])

  const handleChangeAttentionText = e =>
    handlePatchData('attentionText', e.target.value)

  const onLogoUpload = event => {
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        handlePatchData('thumbnailUrl', result.url)
      }),
    )
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={4} className={classes.grid}>
        <Grid container className={classes.headers}>
          <Typography variant="h6">
            {linkEntity
              ? translator._('question.addQuestion.createLink.editLink')
              : `${translator._(
                  'question.addQuestion.createLink.add',
                )} ${translator._('question.addQuestion.createLink.' + type)}`}
          </Typography>
        </Grid>
        <Grid container className={classes.childGrid}>
          <Select
            onChange={handleType}
            classes={{ select: classes.combo }}
            inputProps={{
              className: classes.input,
            }}
            variant="outlined"
            fullWidth
            name="type"
            value={type}
            disabled={linkEntity ? true : false}
            input={<Input id="select-input" name="select-input" />}
          >
            <MenuItem disabled value="select">
              {translator._('question.addQuestion.createLink.selectType')}
            </MenuItem>
            <MenuItem key="item_1" value="url">
              {translator._('question.addQuestion.createLink.url')}
            </MenuItem>
            <MenuItem key="item_1" value="course">
              {translator._('question.addQuestion.createLink.course')}
            </MenuItem>
            <MenuItem key="item_2" value="pdf">
              {translator._('question.addQuestion.createLink.pdf')}
            </MenuItem>
          </Select>
        </Grid>
        {type !== 'course' ? (
          <Grid container className={classes.childGrid}>
            <TextField
              fullWidth={true}
              placeholder={translator._(
                'question.addQuestion.createLink.title',
              )}
              label={translator._('question.addQuestion.createLink.title')}
              id="title"
              name="title"
              onChange={handleTitle}
              inputProps={{
                maxLength: 80,
                className: ClassNames({
                  [classes.input]: true,
                  [classes.blur]: true,
                }),
              }}
              onBlur={() => {
                if (title != '') {
                  handlePatchData('title', title)
                }
              }}
              value={title}
            />
          </Grid>
        ) : (
          <Grid container className={classes.childGrid}>
            <FormControl fullWidth={true}>
              <InputLabel id="demo-simple-select-helper-label">
                {linkEntity
                  ? `${title}`
                  : translator._('question.course.chooseCourse')}
              </InputLabel>
              <Select
                classes={{ select: classes.combo }}
                inputProps={{
                  className: classes.input,
                }}
                variant="outlined"
                fullWidth
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Choose Course"
                disabled={false}
                input={<Input id="select-input" name="select-input" />}
                onChange={(e, b) => {
                  handleCourse(e, b)
                }}
              >
                <MenuItem disabled={true} value={title}>
                  {title}
                </MenuItem>
                {courses &&
                  courses.map(value => {
                    return (
                      <MenuItem
                        value={value.code}
                        name={value.name}
                        key={value.name}
                      >
                        {value.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {(type === 'url' || type === 'video' || type === 'gorsel') && (
          <Grid container className={classes.childGrid}>
            <TextField
              fullWidth={true}
              label={translator._('question.addQuestion.createLink.url')}
              placeholder="http://www.youraddress.com"
              id="url"
              name="url"
              onChange={handleURL}
              inputProps={{
                maxLength: 500,
                className: ClassNames({
                  [classes.input]: true,
                  [classes.blur]: true,
                }),
              }}
              onBlur={() => {
                if (entityURL != '' && isHttp == true) {
                  handlePatchData('url', entityURL)
                }
              }}
              value={entityURL}
            />

            {!isHttp && (
              <Alert severity="info" className={classes.alert}>
                {translator._(
                  'taskItem.addQuestion.createLink.pleaseAddFullLinkAdress',
                )}
              </Alert>
            )}
          </Grid>
        )}
        {type == 'pdf' && !PdfURL && (
          <Grid container className={classes.childGrid}>
            <TextField type="file" fullWidth={true} onChange={choosePdf} />
          </Grid>
        )}
        {PdfURL && (
          <React.Fragment>
            <Grid container className={classes.childGrid}>
              <iframe
                className={classes.pdfViewer}
                src={
                  'https://docs.google.com/viewer?embedded=true&url=' +
                  URL.CDN +
                  PdfURL
                }
              ></iframe>
            </Grid>
            <Grid style={{ textAlign: 'center' }}>
              <Button
                size="small"
                onClick={removePdf}
                className={classes.changePdf}
              >
                {translator._('question.addQuestion.createLink.changePdf')}
              </Button>
            </Grid>
          </React.Fragment>
        )}
        <Grid container className={classes.iconPicker}>
          <FormControlLabel
            label="Open in same tab"
            control={<Checkbox checked={checked} onChange={handleCheckBox} />}
          ></FormControlLabel>
        </Grid>
        {linkEntity && (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Advanced Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  label="Attention Text"
                  fullWidth
                  variant="outlined"
                  defaultValue={linkEntity.attentionText || ''}
                  onBlur={handleChangeAttentionText}
                />
                <Typography
                  variant="body1"
                  style={{ fontWeight: 600, paddingTop: 10, paddingBottom: 10 }}
                >
                  Thumbnail
                </Typography>

                {linkEntity.thumbnailUrl && linkEntity.thumbnailUrl != '' ? (
                  <div className={classes.imageUpload}>
                    <img
                      width="64"
                      alt=""
                      src={`${URL.MEDIA}${linkEntity.thumbnailUrl}`}
                    />
                    <Box m={0.2}></Box>
                    <Button
                      onClick={() => handlePatchData('thumbnailUrl', null)}
                    >
                      {translator._('assignment.courseSettings.changeImage')}
                    </Button>
                  </div>
                ) : (
                  <TextField
                    type="file"
                    fullWidth={true}
                    onChange={onLogoUpload}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
        <Grid container className={classes.childGrid}>
          <Button
            fullWidth={linkEntity ? false : true}
            onClick={createButtonClickHandler}
            color="primary"
            variant={linkEntity ? 'text' : 'contained'}
            disabled={!(entityURL && title && isHttp)}
          >
            {linkEntity
              ? translator._('question.addQuestion.createLink.delete')
              : translator._('question.addQuestion.createLink.add')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

QuestionCreateLink.propTypes = propTypes

export default withStyles(styles)(QuestionCreateLink)
