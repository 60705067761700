const styles = theme => ({
  modal: {
    display: 'flex !important',
  },
  modalContent: {
    width: '694px',
    //height: '196px',
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
    paddingTop: '20px',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '&:focus': {
      outline: 'none!important',
    },
  },
  modalContent__header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    gap: '8px',
  },
  modalContent__header__title: {
    fontSize: '18px',
    fontWeight: 700,
  },
  modalContent__header__text: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#002E47',
    marginBottom: '16px',
  },
  modalContent__header__warning: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#ED4245',
    marginBottom: '16px',
  },
  modalContent__buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
  },
  modalContent__deleteButton: {
    backgroundColor: '#ED4245',
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ED4245',
      color: '#fff',
      boxShadow: 'none',
    },
  },
  modalContent__cancelButton: {
    color: '#002E47',
    border: 'none',
    outline: '1px solid rgba(145, 158, 171, 0.32)',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#002E47',
      outline: '1px solid rgba(145, 158, 171, 0.32)',
      border: 'none',
    },
  },
  modalContent__buttonText: {
    fontSize: '14px',
    fontWeight: 500,
  },
  modalContent__input: {
    width: '50%',
  },
  modalContent__importButton: {
    boxShadow: 'none',
    color: '#fff',
    backgroundColor: '#015FFB',
    padding: '6px 16px',
    borderRadius: '8px',
    '&:hover': {
      //backgroundColor: '#63f28f',
    },
  },
  file__upload: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxHeight: '128px',
    cursor: 'pointer',
    opacity: '0',
  },
  modalContent__downloadExampleButton: {
    height: '30px',
    width: '90px',
    color: '#002E47',
    backgroundColor: '#fff',
  },
  modalContent__inputField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed #919eab7a',
    backgroundColor: '#F4F6F8',
    borderRadius: '12px',
    //width: '646px',
    height: '180px',
    gap: '8px',
    position: 'relative',
  },
  modalContent__viewFile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F4F6F8',
    borderRadius: '12px',
    //width: '646px',
    gap: '8px',
    position: 'relative',
    padding: '12px 16px',
  },
  border: {
    border: '1px solid #919eab7a',
  },
  modalContent__header__description: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#002E47',
    marginBottom: '16px',
  },
  modalContent__blueButton: {
    boxShadow: 'none',
    color: '#fff',
    backgroundColor: '#015FFB',
    padding: '6px 16px',
    borderRadius: '8px',
    '&:hover': {
      //backgroundColor: '#63f28f',
    },
  },
  modalContent__doneButton: {
    boxShadow: 'none',
    color: '#fff',
    backgroundColor: '#4CAF4F',
    padding: '6px 16px',
    borderRadius: '8px',
    '&:hover': {
      //backgroundColor: '#63f28f',
    },
  },
})

export default styles
