import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import ListSchema from './list'
import List from '../list'
import { ListItemViewType, ListUseModeType } from '../../model/list'
import { Box, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import translator from '../../translate'
import EmptyList from 'src/components/empty-list'
import robotLoveOutline from '@iconify/icons-simple-icons/probot'
import { Icon } from '@iconify/react'
import { Link as RouterLink } from 'react-router-dom'

class ListAssignments extends Component {
  state = {
    tabValue: '0',
  }
  static defaultProps = {
    hideTopBar: false,
    hideSearchBar: true,
    showPaging: true,
    showSelect: false,
    showChildList: false,
    showDetailSearch: false,
    usageMode: ListUseModeType.LIST,
    itemViewType: ListItemViewType.DOUBLE_ROW,
    query: {
      CurrentPage: 0,
      PageSize: 20,
      SortingColumn: 'LastModifiedAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    },
    // sortingColumn: 'createDate',
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    })
  }

  /**
   *
   */
  render() {
    const { tabValue } = this.state
    const { query, queryResult, setSelectionModalOpen } = this.props

    let newQuery = query

    newQuery.Filters = [
      {
        FilteredField: 'IsPeriodic',
        FilteredValue: null,
        AndOr: 'and',
      },
    ]

    return (
      <React.Fragment>
        {queryResult.totalCount !== 0 ? (
          <List
            list={ListSchema}
            disabled={false}
            {...this.props}
            applicationType={this.props.applicationType}
            key={'tabVlaue' + tabValue}
            query={newQuery}
          />
        ) : (
          <EmptyList
            buttonTitle={translator._('assignment.createAssignment')}
            createButtonClickHandler={() => setSelectionModalOpen(true)}
            source=""
            title={translator._('assignment.emptyTitle')}
            subTitle={translator._('assignment.emptySubtitle')}
            customButton={
              <Box
                sx={{ display: 'flex', p: 1, m: 1, gap: 2.5 }}
                style={{ marginTop: 30 }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    textTransform: 'none',
                    textAlign: 'center',
                  }}
                  onClick={() => setSelectionModalOpen(true)}
                  size="medium"
                >
                  <span style={{ width: '80%' }}>
                    {translator._('assignment.createAssignmentFromScratch')}
                  </span>
                </Button>
                <Button
                  variant="outlined"
                  to="/create-course-with-ai"
                  color="secondary"
                  style={{
                    textTransform: 'none',
                    textAlign: 'center',
                  }}
                  component={RouterLink}
                  size="medium"
                >
                  <span style={{ width: '60%' }}>
                    {translator._('assignment.createAssignmentWithAI')}
                  </span>
                </Button>
              </Box>
            }
            customImage={
              <img
                src="/assets/assignments-empty.png"
                alt="empty assignments"
                style={{ height: 240, width: 327 }}
              />
            }
          />
        )}
      </React.Fragment>
    )
  }
}

ListAssignments.propTypes = propTypes

const mapStateToProps = response => ({
  queryResult: response.assignment.list,
})

export default connect(mapStateToProps)(ListAssignments)
