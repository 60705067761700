import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { TEAMS_MEMBER_IMPORT } from 'src/reducers/teams/type'
import { teamsImportService } from 'src/api/teams'
import { teamsMemberImportSuccess } from 'src/reducers/teams/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* teamsImportMemberFlow(payload) {
  try {
    const { file, teamsId, callback } = payload

    var formData = new FormData()

    formData.append('file', file)

    const response = yield call(teamsImportService, formData, teamsId)

    yield put(teamsMemberImportSuccess(response.data))
    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* teamsImportMemberWatcher() {
  while (true) {
    const action = yield take(TEAMS_MEMBER_IMPORT)
    const { payload } = action

    yield fork(generalSaga, teamsImportMemberFlow, payload)
  }
}
