const styles = theme => ({
  grid: {
    width: 400,
    maxWidth: 'none',
    flexBasis: 'unset',
  },
  childGrid: {
    marginBottom: theme.spacing(1.5),
  },
  input: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  inputWrapper: {
    borderColor: '#d6d6d6',
  },
  pdfViewer: {
    width: 200,
    height: 273,
    margin: '0px auto',
  },
  changePdf: {
    textAlign: 'center',
    color: '#4a4a4a',
    textTransform: 'none',
    marginBottom: theme.spacing(1.5),
  },
  alert: {
    margin: theme.spacing(1.5),
  },
  iconPicker: {
    marginBottom: theme.spacing(1.5),
    alignItems: 'center',
  },
  headers: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
  imageUpload: {
    display: 'grid',
  },
})

export default styles
