import React from 'react'
import styles from './style'
import propTypes from './prop'
import options from './options'
import { questionAddAction } from '../../reducers/question/action'
import { getQuestionTypeParam, QuestionSchema } from '../../model/question'

import { withStyles, Typography, Box, useMediaQuery } from '@material-ui/core'
import SpeedDial from '@material-ui/lab/SpeedDial'
import { track } from '@amplitude/analytics-browser'

import { useHistory } from 'react-router-dom'
import UserType, { UserTypeNames } from 'src/model/user-types'

const QuestionType = props => {
  const { dispatch, courseId, baseEntity, onAdd, classes } = props

  const isMobile = useMediaQuery('(max-width: 600px)')
  const direction = isMobile ? 'up' : 'right'
  const [open, setOpen] = React.useState(false)
  const [isShown, setIsShown] = React.useState(false)
  const [optionTitle, setOptionTitle] = React.useState('')
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false)
  const [roleType, setRoleType] = React.useState(
    UserType[window.CONSTANTS.USER_ROLE_ID],
  )
  const hidden = false
  let history = useHistory()

  const handleClose = () => {
    setOpen(false)
    setOpenSpeedDial(false)
  }
  const handleOpen = name => {
    setOpen(name)
    setOpenSpeedDial(true)
  }

  const saveType = async selectedType => {
    let entity = QuestionSchema()
    let list = []
    entity = getQuestionTypeParam(selectedType, entity)
    await dispatch(
      questionAddAction(entity, courseId, response => {
        let newTaskObject = { ...response, isNewAdded: true }
        list.push(newTaskObject)
        onAdd(list)
      }),
    )

    var elem = document.getElementById('backDrop')
    elem.style.display = 'none'

    var selectTypeWidget = document.getElementById('selectTypeWidget')
    selectTypeWidget.style.border = '1px solid #ededed'

    const widgetParent = options({ entity: baseEntity }).find(o =>
      o.contents.find(oc => oc.dataType === selectedType),
    )
    track('Card Created', {
      cardType: widgetParent.title + ' Card',
    })
  }
  const speadDialAction = () => {}

  const handleClickRedirect = () => {
    history.push('/pricing')
  }
  return (
    <>
      <div className={classes.speedDialWrapper} id="selectTypeWidget">
        {options(props)
          .filter(x => x.isOnList == true)
          .map((group, index) => {
            return (
              <Box
                onClick={() => {
                  roleType === UserTypeNames.STARTER &&
                  group.title == 'Score Card'
                    ? handleClickRedirect()
                    : group.locked
                    ? speadDialAction()
                    : saveType(group.contents[0].dataType)
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 32,
                }}
                key={index}
              >
                <SpeedDial
                  ariaLabel="SpeedDial example"
                  className={classes.speedDial}
                  hidden={hidden}
                  icon={
                    <img
                      alt="cardIcon"
                      style={{
                        width: '32px',
                        opacity:
                          (roleType == 1 && group.title == 'Score Card') ||
                          group.locked
                            ? 0.5
                            : 1,
                      }}
                      src={group.icon}
                    />
                  }
                  onClose={handleClose}
                  FabProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      width: 'fit-content',
                      height: 'fit-content',
                    },
                  }}
                  /*  onOpen={() => {
                  return group.contents.length > 1 && handleOpen(group.title)
                }} */

                  open={open == group.title ? true : false}
                ></SpeedDial>
                <Typography
                  style={{
                    opacity:
                      (roleType == 1 && group.title == 'Score Card') ||
                      group.locked
                        ? 0.5
                        : 1,
                  }}
                  className={classes.speadDialTitle}
                >
                  {group.title}
                </Typography>
              </Box>
            )
          })}
      </div>
      {/*disabledAiPopup != true && (
        <Tooltip
          title="How to use AI Editor?"
          placement="right"
          classes={{
            tooltip: classes.tooltip,
          }}
          //open={true}
          //arrow={true}
        >
          <img
            src="/assets/widget_ai.gif"
            className={classes.aiImage}
            style={{ borderRadius: '50%', height: '56px', width: '56px' }}
            onClick={openPopup}
          />
        </Tooltip>
        )*/}
    </>
  )
}

QuestionType.propTypes = propTypes

export default withStyles(styles)(QuestionType)
