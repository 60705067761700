import { put, call, take, fork } from 'redux-saga/effects'
import { userVerifyChangePasswordCodeService } from '../../api/authentication'
import { authenticationVerifyChangePasswordSuccess } from '../../reducers/authentication/action'
import { AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE } from '../../reducers/authentication/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* verifyChangePasswordFlow(payload) {
  const { callback, linkCode } = payload
  try {
    const response = yield call(userVerifyChangePasswordCodeService, linkCode)
    yield put(authenticationVerifyChangePasswordSuccess(response))
    yield put(operationSuccess())
    callback(response)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* verifyChangePasswordWatcher() {
  while (true) {
    const action = yield take(AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE)
    const { payload } = action

    yield fork(generalSaga, verifyChangePasswordFlow, payload)
  }
}
