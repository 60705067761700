import {
  AUTHENTICATION_LOGIN_USER,
  AUTHENTICATION_LOGIN_USER_SUCCESS,
  AUTHENTICATION_LOGIN_USER_ERROR,
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_LOGOUT_SUCCESS,
  AUTHENTICATION_LOGOUT_ERROR,
  AUTHENTICATION_DELETE,
  AUTHENTICATION_DELETE_SUCCESS,
  AUTHENTICATION_DELETE_ERROR,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL_ERROR,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_ERROR,
} from './type'

/**
 *
 * @param {*} userForm
 */
export const authenticationLoginUserAction = userForm => {
  return {
    type: AUTHENTICATION_LOGIN_USER,
    payload: { userForm },
  }
}

/**
 *
 * @param {*} user
 */
export const authenticationLoginUserSuccess = user => {
  return {
    type: AUTHENTICATION_LOGIN_USER_SUCCESS,
    payload: { user },
  }
}

/**
 *
 * @param {*} error
 */
export const authenticationLoginUserError = error => {
  return {
    type: AUTHENTICATION_LOGIN_USER_ERROR,
    payload: { error },
  }
}

/**
 *
 */
export const authenticationLogoutAction = () => {
  return {
    type: AUTHENTICATION_LOGOUT,
  }
}

/**
 *
 */
export const authenticationLogoutSuccess = () => {
  return {
    type: AUTHENTICATION_LOGOUT_SUCCESS,
  }
}

/**
 *
 * @param {*} error
 */
export const authenticationLogoutError = error => {
  return {
    type: AUTHENTICATION_LOGOUT_ERROR,
    payload: { error },
  }
}

/**
 *
 */
export const authenticationDeleteAction = () => {
  return {
    type: AUTHENTICATION_DELETE,
  }
}

/**
 *
 */
export const authenticationDeleteSuccess = () => {
  return {
    type: AUTHENTICATION_DELETE_SUCCESS,
  }
}

/**
 *
 * @param {*} error
 */
export const authenticationDeleteError = error => {
  return {
    type: AUTHENTICATION_DELETE_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} email
 * @param {*} callback
 */
export const authenticationForgotPasswordEmailAction = (email, callback) => {
  return {
    type: AUTHENTICATION_FORGOT_PASSWORD_EMAIL,
    payload: { email, callback },
  }
}

/**
 *
 *
 */

export const authenticationForgotPasswordEmailSuccess = () => {
  return {
    type: AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS,
  }
}
/**
 *
 * @param {*} error
 */
export const authenticationForgotPasswordEmailError = error => {
  return {
    type: AUTHENTICATION_FORGOT_PASSWORD_EMAIL_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} linkCode
 * @param {*} callback
 */
export const authenticationVerifyChangePasswordAction = (
  linkCode,
  callback,
) => {
  return {
    type: AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE,
    payload: { linkCode, callback },
  }
}

/**
 *
 *
 */

export const authenticationVerifyChangePasswordSuccess = () => {
  return {
    type: AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS,
  }
}
/**
 *
 * @param {*} error
 */
export const authenticationVerifyChangePasswordError = error => {
  return {
    type: AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_ERROR,
    payload: { error },
  }
}
