import { put, call, take, fork } from 'redux-saga/effects'
import { userRoleDetailedListService } from '../../api/user'
import { userRoleDetailedListSuccess } from '../../reducers/user-role-detailed-list/action'
import { USER_ROLE_DETAILED_LIST } from '../../reducers/user-role-detailed-list/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* userRoleDetailedListFlow(payload) {
  try {
    const { query } = payload

    const response = yield call(userRoleDetailedListService, query)

    if (
      response.data.results.length == 0 &&
      query.FilterText == '' &&
      query.Filters == undefined
    ) {
      response.data.results = null
    }

    yield put(userRoleDetailedListSuccess(response.data))

    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* userRoleDetailedListWatcher() {
  while (true) {
    const action = yield take(USER_ROLE_DETAILED_LIST)
    const { payload } = action

    yield fork(generalSaga, userRoleDetailedListFlow, payload)
  }
}
