import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import ListSchema from './list'
import List from '../list'
import { ListUseModeType } from '../../model/list'

class ListUser extends Component {
  static defaultProps = {
    hideTopBar: false,
    hideSearchBar: false,
    showPaging: true,
    showSelect: true,
    showChildList: false,
    usageMode: ListUseModeType.LIST,
    showDetailSearch: true,

    query: {
      CurrentPage: 0,
      PageSize: 20,
      SortingColumn: 'lastActDate',
      Asc: false,
      FilterText: '',
      Filters: [],
    },
  }

  /**
   *
   */
  render() {
    let newQuery = this.props.query

    if (this.props.teamId) {
      newQuery.Filters = [
        {
          FilteredField: 'TeamId',
          TeamId: this.props.teamId,
          AndOr: 'and',
        },
      ]
    } else {
      newQuery.Filters = []
    }
    return (
      <List
        list={ListSchema}
        disabled={false}
        {...this.props}
        query={newQuery}
      />
    )
  }
}

ListUser.propTypes = propTypes

const mapStateToProps = response => ({
  queryResult: response.user.list,
})

export default connect(mapStateToProps)(ListUser)
