/**
 *
 * @param value
 */
const ConverterArrayToStringFormat = function (value) {
  if (value === null || value === undefined || value.length === 0) {
    return ''
  } else {
    let tagNames = value.map(
      value =>
        ' ' +
        (value.teamDTO
          ? value.teamDTO.name
          : value.teamTreeDTO
          ? value.teamTreeDTO.name
          : value.subjectDTO
          ? value.subjectDTO.name
          : value.subjectTreeDTO
          ? value.subjectTreeDTO.name
          : value.name),
    )
    return tagNames.toString()
  }
}

export default ConverterArrayToStringFormat
