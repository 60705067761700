import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import translator from 'src/translate'
import isEmpty from 'src/utils/is-empty'
import ReadJSONPath from 'src/utils/read-json-path'

const ListItemField = (props) => {
  const {
    classes,
    FieldIcon,
    index,
    entity,
    column
  } = props

  let value = ReadJSONPath(entity, column.attribute)

  if (column.converter) {
    value = column.converter.input(value)
  }

  if (isEmpty(value)) {
    value = translator._('assignment.none')
  }

  if (!!column.hidden) {
    if (column.hidden(entity) === true) {
      return null
    }
  }

  return (
    <Grid key={index} item xs={6} sm={3} className={classes.infoField}>
      <div className={classes.infoFieldTitle}>
        {FieldIcon && (
          <FieldIcon className={classes.smallIcon} />
        )}
        <Typography variant="body2">
          {column.label}
        </Typography>
      </div>
      <Typography variant="body2"
        className={classes.infoFieldBottomLine}
        style={{ fontWeight: 600 }}
      >
        {value}
      </Typography>
    </Grid>
  )
}

export default ListItemField