import PropTypes from 'prop-types'

const propTypes = {
  classes: PropTypes.object.isRequired,
  queryResult: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
  changeRowsPerPage: PropTypes.func.isRequired,
  selectedCount: PropTypes.number.isRequired,
}

export default propTypes
