import { put, call, take, fork } from 'redux-saga/effects'
import { questionSelectionPatchService } from '../../api/question'
import { questionSelectionPatchSuccess } from '../../reducers/assignment/action'
import { QUESTION_SELECTION_PATCH } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionSelectionPatchFlow(payload) {
  try {
    const { content, questionId, callback } = payload

    yield call(questionSelectionPatchService, content)
    yield put(questionSelectionPatchSuccess(content, questionId))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionSelectionPatchWatcher() {
  while (true) {
    const action = yield take(QUESTION_SELECTION_PATCH)
    const { payload } = action

    yield fork(generalSaga, questionSelectionPatchFlow, payload, false)
  }
}
