const AUTHENTICATION_LOGIN_USER = 'AUTHENTICATION_LOGIN_USER'
const AUTHENTICATION_LOGIN_USER_SUCCESS = 'AUTHENTICATION_LOGIN_USER_SUCCESS'
const AUTHENTICATION_LOGIN_USER_ERROR = 'AUTHENTICATION_LOGIN_USER_ERROR'
const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT'
const AUTHENTICATION_LOGOUT_SUCCESS = 'AUTHENTICATION_LOGOUT_SUCCESS'
const AUTHENTICATION_LOGOUT_ERROR = 'AUTHENTICATION_LOGOUT_ERROR'
const AUTHENTICATION_DELETE = 'AUTHENTICATION_DELETE'
const AUTHENTICATION_DELETE_SUCCESS = 'AUTHENTICATION_DELETE_SUCCESS'
const AUTHENTICATION_DELETE_ERROR = 'AUTHENTICATION_DELETE_ERROR'
const AUTHENTICATION_FORGOT_PASSWORD_EMAIL =
  'AUTHENTICATION_FORGOT_PASSWORD_EMAIL'
const AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS =
  'AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS'
const AUTHENTICATION_FORGOT_PASSWORD_EMAIL_ERROR =
  'AUTHENTICATION_FORGOT_PASSWORD_EMAIL_ERROR'
const AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE = 'VERIFY_CHANGE_PASSWORD_CODE'
const AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS =
  'VERIFY_CHANGE_PASSWORD_CODE_SUCCESS'
const AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_ERROR =
  'VERIFY_CHANGE_PASSWORD_CODE_ERROR'
export {
  AUTHENTICATION_LOGIN_USER,
  AUTHENTICATION_LOGIN_USER_SUCCESS,
  AUTHENTICATION_LOGIN_USER_ERROR,
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_LOGOUT_SUCCESS,
  AUTHENTICATION_LOGOUT_ERROR,
  AUTHENTICATION_DELETE,
  AUTHENTICATION_DELETE_SUCCESS,
  AUTHENTICATION_DELETE_ERROR,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL_ERROR,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_ERROR,
}
