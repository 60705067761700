import { put, call, take, fork } from 'redux-saga/effects'
import { questionSelectionsAddService } from '../../api/question'
import { questionSelectionsAddSuccess } from '../../reducers/assignment/action'
import { QUESTION_SELECTIONS_ADD } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionSelectionsAddFlow(payload) {
  try {
    const { entity, callback } = payload
    const response = yield call(questionSelectionsAddService, entity)
    yield put(questionSelectionsAddSuccess(response.data))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionSelectionsAddWatcher() {
  while (true) {
    const action = yield take(QUESTION_SELECTIONS_ADD)
    const { payload } = action

    yield fork(generalSaga, questionSelectionsAddFlow, payload, true)
  }
}
