import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../reducers'
import rootSaga from '../sagas'
import systemMiddleware from '../middleware/system'

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  return {
    ...createStore(
      rootReducer,
      composeEnhancer(applyMiddleware(sagaMiddleware, systemMiddleware)),
    ),
    runSaga: sagaMiddleware.run(rootSaga),
  }
}

export default configureStore
