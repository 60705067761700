import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const excelExportQuizGameUserCompareService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_QUIZ_GAME_USER_COMPARE).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportQuizGameContentAnalysisService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_QUIZ_GAME_CONTENT_ANALYSIS).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportQuizGameTeamComparisonByGameMetricsService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_QUIZ_GAME_TEAM_COMPARISON).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportQuestionUserAnswersService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_QUESTION_USER_ANSWERS).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportUserMetricsWithDateService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_USER_METRICS_WITH_DATE).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportUserMetricsWithoutDateService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_USER_METRICS_WITHOUT_DATE).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportQuizGameLeaderboardUserMetricsService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_QUIZ_GAME_LEADERBOARD_USER_METRICS,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportUserQuestionAnalysisService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_USER_QUESTION_ANALYSIS).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportSubjectParticipationService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_SUBJECT_PARTICIPATION).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportSubjectPerformanceService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_SUBJECT_PERFORMANCE).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportSubjectAssessmentItemsService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_SUBJECT_ASSESSMENT_ITEMS).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportSubjectAssessmentItemsWithUserDataService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_SUBJECT_ASSESSMENT_ITEMS_WITH_USER_DATA,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportSubjectNonAssessmentItemsService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_SUBJECT_NON_ASSESSMENT_ITEMS,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportSubjectNonAssessmentItemsWithUserDataService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_SUBJECT_NON_ASSESSMENT_ITEMS_WITH_USER_DATA,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportReinforcementTeamEngagementService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_REINFORCEMENT_PARTICIPATION,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportReinforcementTeamPerformanceService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_REINFORCEMENT_PERFORMANCE).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportReinforcementAndTrainingImprovementService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_REINFORCEMENT_AND_TRAINING_IMPROVEMENT,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportTrainingTeamEngagementService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_JOURNEY_PARTICIPATION).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportTrainingTeamPerformanceService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_JOURNEY_PERFORMANCE).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportSubjectImprovementService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_SUBJECT_IMPROVEMENT).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportParticipationWeeklyService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_PARTICIPATION_WEEKLY).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportParticipationMonthlyService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_PARTICIPATION_MONTHLY).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportParticipationByPlatformWeeklyService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_PARTICIPATION_BY_PLATFORM_WEEKLY,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportParticipationByPlatformMonthlyService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_PARTICIPATION_BY_PLATFORM_MONTHLY,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportParticipationWeeklySuperService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_PARTICIPATION_WEEKLY_SUPER).post(
    query,
  )

/**
 *
 * @param {*} query
 */
export const excelExportParticipationMonthlySuperService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_PARTICIPATION_MONTHLY_SUPER,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportParticipationByPlatformWeeklySuperService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_PARTICIPATION_BY_PLATFORM_WEEKLY_SUPER,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportParticipationByPlatformMonthlySuperService = query =>
  new Request(
    URL.REPORT_API,
    URL.EXCEL_EXPORT_PARTICIPATION_BY_PLATFORM_MONTHLY_SUPER,
  ).post(query)

/**
 *
 * @param {*} query
 */
export const excelExportActivationStatsSuperService = query =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_ACTIVATION_STATS_SUPER).post(
    query,
  )

export const excelExportAutoSenderReportService = autoSenderId =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_AUTO_SENDER_REPORTS).post(
    {
      courseId: autoSenderId,
    },
    {
      responseType: 'blob',
    },
  )
export const excelExportCourseTimeSeriesReportService = () =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_COURSE_TIME_SERIES_REPORT).post(
    {},
    {
      responseType: 'blob',
    },
  )
export const excelExportCourseStatisticsReportService = () =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_COURSE_STATISTICS_REPORT).post(
    {},
    {
      responseType: 'blob',
    },
  )
