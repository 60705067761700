import { COMPONENT_BOX_SHOW, COMPONENT_BOX_HIDE } from './type'
import ComponentBoxContent from '../../model/component-box'

export default function (state = {}, action) {
  switch (action.type) {
    case COMPONENT_BOX_SHOW:
      const { content } = action.payload
      if (content instanceof ComponentBoxContent) {
        return {
          ...state,
          content: content.toJSON(),
        }
      } else {
        console.error(
          'ComponentBox needs to object type like ComponentBoxContent. Please check it.',
        )
      }

      return state
    case COMPONENT_BOX_HIDE:


      return {
        ...state,
        content: '',
      }
    default:
      return state
  }
}
