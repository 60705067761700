const style = theme => ({
  card: {
    maxWidth: 700,
    maxHeigh: 600,
    margin: '20px auto',
    alignItems: 'center',
  },
  textField: {
    width: 300,
  },
  button: {
    backgroundColor: 'rgba(0, 171, 85, 0.08)',
    color: '#00AB55',
    marginLeft: '40%',
    margin: '25px 0 15px 0',
  },
  next: {
    marginTop: theme.spacing(2),
  },
  input: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  inputWrapper: {
    borderColor: '#d6d6d6',
  },
  childGrid: {
    marginBottom: theme.spacing(1.5),
  },
  limitModalImage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '20px',
  },
})

export default style
