import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import styles from './styles'

const BackButton = props => {
  const {
    classes,
    itemSelected,
    courseSelected,
    templateSelected,
    Options,
    selectedItem,
    handleBack,
  } = props

  return (
    <div className={classes.backButton} onClick={() => handleBack()}>
      <Button className={classes.button}>
        <ChevronLeftIcon style={{ width: '0.8em', marginTop: '3px' }} />
        <Typography variant="subtitle1" style={{ color: 'black' }}>
          {itemSelected && courseSelected && !templateSelected
            ? Options[selectedItem].cardName
            : 'Back'}
        </Typography>
      </Button>
    </div>
  )
}

export default withStyles(styles)(BackButton)
