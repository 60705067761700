import { put, call, take, fork } from 'redux-saga/effects';
import { generateInformationWithCourseDocumentService } from '../../api/assignment';
//import { questionPatchSuccess } from '../../reducers/question/action'
import { generateInformationWithCourseDocumentSuccess } from '../../reducers/assignment/action';
import { GENERATE_INFORMATION_WITH_COURSE_DOCUMENT } from '../../reducers/assignment/type';
import generalSaga from '../general';
import { operationSuccess, operationFail } from '../../reducers/operation/action';

/**
 *
 * @param {*} payload
 */
function* generateInformationWithCourseDocumentFlow(payload) {
  try {
    const { entity, callback } = payload;

    const response = yield call(generateInformationWithCourseDocumentService, entity);
    yield put(generateInformationWithCourseDocumentSuccess(response.data));
    yield put(operationSuccess());
    callback(response.data);
  } catch (error) {
    yield put(operationFail(error));
  }
}

/**
 *
 */
export default function* generateInformationWithCourseDocumentWatcher() {
  while (true) {
    const action = yield take(GENERATE_INFORMATION_WITH_COURSE_DOCUMENT);
    const { payload } = action;

    yield fork(generalSaga, generateInformationWithCourseDocumentFlow, payload);
  }
}
