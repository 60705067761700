const URL = {
  CREATE_COURSE_COLLECTION: 'CourseCollection/Create',
  DELETE_COURSE_COLLECTION: 'CourseCollection/Delete',
  PATCH_COLLECTION: 'CourseCollection/Patch',
  LIST_COURSE_COLLECTION: 'CourseCollection/GetCourseCollectionByFilter',
  UPDATE_COURSE_COLLECTION: 'CourseCollection/Edit',
  EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX: 'Course/EditCourseCollectionIndex',
  PATCH_COLLECTION_COURSE: 'Course/Patch',
  GET_COLLECTION_TEAM_LIST: 'CourseCollection',
  GET_COLLECTION_SETTINGS: '/settings',
}

export default URL
