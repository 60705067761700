const URL = {
  MEDIA_VIDEO_GET_BY_FILTER: 'Media/GetVideosByFilter',
  MEDIA_VIDEO_GET_BY_ID: 'Media/GetVideoById',
  MEDIA_VIDEO_UPLOAD_MANY: 'Media/UploadManyVideo',
  MEDIA_VIDEO_UPLOAD: 'Media/UploadVideo',
  MEDIA_VIDEO_PATCH: 'Media/PatchVideo',
  MEDIA_VIDEO_DELETE: 'Media/DeleteVideo',
  MEDIA_VIDEO_DELETE_LIST: 'Media/DeleteVideoList',
}

export default URL
