import React, { useRef, useState, useEffect } from 'react'

import { mediaCardImageCreationAction } from 'src/reducers/media-image/action'
import { Icon } from '@iconify/react'

import { Link as RouterLink } from 'react-router-dom'
import URL from 'src/api/url'

import { componentBoxHideAction } from 'src/reducers/component-box/action'

import moment from 'moment-timezone'
import {
  TextField,
  withStyles,
  Button,
  Box,
  Grid,
  Typography,
  Autocomplete,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import styles from './style'
import {
  CameraSettings,
  Places,
  Styles,
  Genre,
  Presets,
} from 'src/model/image-generation-options/index'
import { createPromptFromCardAction } from 'src/reducers/assignment/action'

import { track } from '@amplitude/analytics-browser'
import UserType, { UserTypeNames } from 'src/model/user-types'

const AiImage = props => {
  const {
    cardEntity,
    assignment,
    onPhotoClick,
    dispatch,
    loading,
    choosePhoto,
    refreshedAt,
    popupType,
    classes,
  } = props

  const [description, setDescription] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [imageUrlForSaveImage, setImageUrlForSaveImage] = useState()

  const premiumControl =
    UserType[window.CONSTANTS.USER_ROLE_ID] !=
    UserTypeNames.REGULAR_ADMIN_ACCOUNT
  const [openAdvanced, setOpenAdvanced] = useState(false)
  const [genre, setGenre] = useState([])
  const [style, setStyle] = useState([])
  const [place, setPlace] = useState([])
  const [camera, setCamera] = useState([])
  const [selectedPreset, setPreset] = useState()
  const [refreshDateAvaliable, setRefreshDateAvaliable] = useState()

  const bottomRef = useRef(null)
  useEffect(() => {
    if (popupType == 'create prompt') {
      createPrompt()
    }
  }, [popupType])
  useEffect(() => {
    refreshedAt &&
      refreshedAt.includes('2022') &&
      setRefreshDateAvaliable(false)
  }, [refreshedAt])

  const sendAiRequest = () => {
    const starterText = description
    track('generate_ai_image')

    dispatch(
      mediaCardImageCreationAction(starterText, selectedPreset, response => {
        setImageUrlForSaveImage(response.data)
        setImageUrl(`${URL.STORAGE}` + response.data)
        //downloadImage(response.data)
      }),
    )
  }
  const handleDescription = value => {
    setDescription(value)
  }

  const descriptionModifier = (oldValue, value, setter) => {
    let newDescription = ''

    if (description !== undefined) {
      newDescription = description
    }

    oldValue.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    value.forEach(element => {
      newDescription = newDescription + ', ' + element
    })

    // for delete first empty character
    if (newDescription[0] == ' ') {
      newDescription = newDescription.replace(' ', '')
    }
    // for delete first ,
    if (newDescription[0] == ',') {
      newDescription = newDescription.replace(',', '')
    }

    setter(value)
    setDescription(newDescription)
  }

  const handleStyles = async value => {
    descriptionModifier(style, value, setStyle)
  }

  const handleGenre = async value => {
    descriptionModifier(genre, value, setGenre)
  }
  const handlePlace = async value => {
    descriptionModifier(place, value, setPlace)
  }
  const handleCamera = async value => {
    descriptionModifier(camera, value, setCamera)
  }

  const clearAdvancedSettings = async () => {
    let newDescription = ''

    if (description !== undefined) {
      newDescription = description
    }

    style.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    genre.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    camera.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    place.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    // for delete first empty character
    if (newDescription[0] == ' ') {
      newDescription = newDescription.replace(' ', '')
    }
    // for delete first ,
    if (newDescription[0] == ',') {
      newDescription = newDescription.replace(',', '')
    }
    setStyle([])
    setGenre([])
    setCamera([])
    setPlace([])
    setDescription(newDescription)
  }

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [openAdvanced])

  useEffect(() => {
    clearAdvancedSettings()
  }, [selectedPreset])

  const createPrompt = () => {
    var starterText = cardEntity.text.replace(/(<([^>]+)>)/gi, '')
    track('generate_ai_image_prompt')
    dispatch(
      createPromptFromCardAction(starterText, response => {
        setDescription(response.data)
      }),
    )
  }
  const redirect = () => {
    window.location.href = '/pricing'
    dispatch(componentBoxHideAction())
  }

  return (
    <Grid container>
      <Grid item xs={6} spacing={10} className={classes.mainGrid}>
        <div className={classes.searchBar}>
          <div className={classes.section}>
            <Typography className={classes.sectionTitle}>
              Text Prompt
              <Button
                variant="outlined"
                className={classes.random}
                disabled={!premiumControl}
                onClick={() => {
                  createPrompt()
                }}
              >
                Get Prompt from Card
              </Button>
            </Typography>
          </div>
          <Box m={0.5} />
          <TextField
            multiline
            rows={2}
            fullWidth={true}
            value={description}
            placeholder={'An astronaut riding a horse on mars, Van Gogh'}
            //label="Please describe your image"
            className={classes.textField}
            onChange={e => handleDescription(e.target.value)}
          />
        </div>
        <Box m={0.5} />
        <div style={{ overflowY: 'auto', height: '265px' }}>
          <div className={classes.section}>
            <Typography className={classes.sectionTitle}>Presets</Typography>
            <div className={classes.presets}>
              <div
                className={classes.preset}
                onClick={() => {
                  setPreset(null)
                  setOpenAdvanced(false)
                }}
                style={{
                  border: selectedPreset == null ? '3px solid #00a350' : 'none',
                }}
              >
                <Icon
                  icon="material-symbols:block"
                  style={{ margin: '25px auto', color: '#d4dadf' }}
                  width="40"
                  height="40"
                />
              </div>
              {Presets.map(preset => (
                <Card
                  className={classes.preset}
                  key={preset.id}
                  onClick={() => {
                    setPreset(preset.id)
                    setOpenAdvanced(false)
                  }}
                  style={{
                    outline:
                      selectedPreset == preset.id
                        ? '3px solid #00a350'
                        : 'none',
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={preset.imageUrl}
                      title={preset.name}
                    >
                      {selectedPreset == preset.id && (
                        <CheckCircleIcon
                          className={classes.aiImageIcon}
                          size="small"
                        />
                      )}
                    </CardMedia>
                  </CardActionArea>
                </Card>
              ))}
              <div
                className={classes.preset}
                ref={bottomRef}
                style={{
                  backgroundColor: '#e5f7ee',
                  border:
                    selectedPreset == 'advanced' ? '3px solid #00a350' : 'none',
                }}
                onClick={() => {
                  setOpenAdvanced(true)
                  setPreset('advanced')
                }}
              >
                <Icon
                  icon="material-symbols:dashboard-customize-outline-rounded"
                  style={{ margin: '15px auto 0px auto', color: '#39be7b' }}
                  width="30"
                  height="30"
                />
                <Typography style={{ color: '#39be7b', fontSize: 12 }}>
                  Advanced
                </Typography>
              </div>
            </div>
          </div>
          {openAdvanced == true && (
            <>
              <Box m={2} />
              <Autocomplete
                multiple
                id="tags-outlined"
                options={Styles}
                getOptionLabel={option => option}
                onChange={(event, value) => handleStyles(value)}
                filterSelectedOptions
                renderInput={params => (
                  <TextField {...params} variant="outlined" label="Styles" />
                )}
              />
              <Box m={2} />
              <Autocomplete
                multiple
                id="tags-outlined"
                options={Genre}
                getOptionLabel={option => option}
                onChange={(event, value) => handleGenre(value)}
                filterSelectedOptions
                renderInput={params => (
                  <TextField {...params} variant="outlined" label="Genres" />
                )}
              />
              <Box m={2} />
              <Autocomplete
                multiple
                id="tags-outlined"
                options={Places}
                getOptionLabel={option => option}
                onChange={(event, value) => handlePlace(value)}
                filterSelectedOptions
                renderInput={params => (
                  <TextField {...params} variant="outlined" label="Places" />
                )}
              />
              <Box m={2} />
              <Autocomplete
                multiple
                id="tags-outlined"
                options={CameraSettings}
                getOptionLabel={option => option}
                //defaultValue={[top100Films[13]]}
                onChange={(event, value) => handleCamera(value)}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Camera Settings"
                  />
                )}
              />
              <div ref={bottomRef} />
            </>
          )}
        </div>
        {openAdvanced && <Box m={2} />}
        <Button
          variant="contained"
          className={classes.requestButton}
          onClick={() => sendAiRequest()}
          disabled={!premiumControl}
        >
          <img
            height={16}
            width={16}
            style={{ marginRight: 4 }}
            src="/assets/ai-white.png"
          />
          Generate Image
        </Button>
      </Grid>
      <Grid item xs={6} className={classes.imageGrid}>
        <Card className={classes.imageCard}>
          {loading ? (
            <CircularProgress />
          ) : !premiumControl ? (
            <>
              <img
                width="390px"
                height="350px"
                style={{ borderRadius: '10px' }}
                src="/assets/qc-ai-limit.jpg"
              />
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <Icon
                  size="small"
                  icon="fa6-solid:crown"
                  color="#F67B3F"
                  width={20}
                  style={{ marginRight: 10, marginTop: 3 }}
                ></Icon>

                <Typography fontWeight={600} fontSize="16px">
                  Upgrade to generate images with AI.
                </Typography>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  redirect()
                }}
                style={{
                  borderRadius: 50,
                  backgroundColor: '#E7F430',
                  height: '36px',
                  width: '180px',
                  padding: '11px 84px',
                  boxShadow: 'none',
                  color: '#1D1D1F',
                }}
              >
                <Typography fontSize="14px" fontWeight={600}>
                  Upgrade
                </Typography>
              </Button>
            </>
          ) : imageUrl ? (
            <>
              <img
                width={390}
                height={390}
                className={classes.generatedImage}
                src={imageUrl}
                onClick={() => {
                  onPhotoClick(imageUrlForSaveImage)
                  track('use_ai_image')
                }}
              />

              <Box m={1} />
              <Button
                variant="outlined"
                style={{
                  color: '#00AB55',
                  borderRadius: 8,
                  borderColor: '#00AB55',
                }}
                onClick={() => {
                  onPhotoClick(imageUrlForSaveImage)
                  track('use_ai_image')
                }}
              >
                Use This Image
              </Button>
            </>
          ) : (
            <>
              <Card
                style={{
                  width: '90%',
                  height: '100%',
                  margin: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src="/assets/qc-ai-image-icon.svg"
                  style={{ width: '48px', height: '48px' }}
                />

                <Typography
                  style={{
                    color: '#919EAB',
                    fontWeight: 450,
                    fontSize: '12px',
                  }}
                >
                  Enter your text prompt and generate image!
                </Typography>
              </Card>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(AiImage)
