import React from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import TeamsMemberListContainer from 'src/container/teams-member-list'
import { useSelector } from 'react-redux'
import { TeamsMemberListHeader } from './components'
const TeamsMemberList = props => {
  const { classes } = props
  const id = window.location.pathname.split('users-member-list/')[1]
  const teamName = useSelector(state => state.teamsList.memberList.teamName)
  return (
    <div className={classes.root}>
      <TeamsMemberListHeader teamName={teamName} />
      <div className={classes.teamListRoot}>
        <TeamsMemberListContainer id={id} teamName={teamName} />
      </div>
    </div>
  )
}

export default withStyles(styles)(TeamsMemberList)
