import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import { PatchData } from '../../../../model/patch'
import isEmpty from '../../../../utils/is-empty'
import { withStyles, Switch } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const FormItemReversedSwitch = props => {
  const { disabled, value, updateEntityKeyValue } = props
  const [stateValue, setStateValue] = useState(!value)

  /**
   *
   * @param {*} event
   * @param {*} checked
   */
  const onChange = (event, checked) => {
    updateEntityKeyValue(!checked, props, PatchData.OPERATION_REPLACE)

    setStateValue(!checked)
  }

  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <Switch
          checked={stateValue}
          onChange={onChange}
          disabled={disabled}
          color="primary"
        />
      }
    />
  )
}

FormItemReversedSwitch.propTypes = propTypes

export default withStyles(style)(FormItemReversedSwitch)
