const MEDIA_VIDEO_GET_BY_FILTER = 'MEDIA_VIDEO_GET_BY_FILTER'
const MEDIA_VIDEO_GET_BY_FILTER_SUCCESS = 'MEDIA_VIDEO_GET_BY_FILTER_SUCCESS'
const MEDIA_VIDEO_GET_BY_FILTER_ERROR = 'MEDIA_VIDEO_GET_BY_FILTER_ERROR'
const MEDIA_VIDEO_GET_BY_ID = 'MEDIA_VIDEO_GET_BY_ID'
const MEDIA_VIDEO_GET_BY_ID_SUCCESS = 'MEDIA_VIDEO_GET_BY_ID_SUCCESS'
const MEDIA_VIDEO_GET_BY_ID_ERROR = 'MEDIA_VIDEO_GET_BY_ID_ERROR'
const MEDIA_VIDEO_UPLOAD_MANY = 'MEDIA_VIDEO_UPLOAD_MANY'
const MEDIA_VIDEO_UPLOAD_MANY_SUCCESS = 'MEDIA_VIDEO_UPLOAD_MANY_SUCCESS'
const MEDIA_VIDEO_UPLOAD_MANY_ERROR = 'MEDIA_VIDEO_UPLOAD_MANY_ERROR'
const MEDIA_VIDEO_UPLOAD = 'MEDIA_VIDEO_UPLOAD'
const MEDIA_VIDEO_UPLOAD_SUCCESS = 'MEDIA_VIDEO_UPLOAD_SUCCESS'
const MEDIA_VIDEO_UPLOAD_ERROR = 'MEDIA_VIDEO_UPLOAD_ERROR'
const MEDIA_VIDEO_PATCH = 'MEDIA_VIDEO_PATCH'
const MEDIA_VIDEO_PATCH_SUCCESS = 'MEDIA_VIDEO_PATCH_SUCCESS'
const MEDIA_VIDEO_PATCH_ERROR = 'MEDIA_VIDEO_PATCH_ERROR'
const MEDIA_VIDEO_DELETE = 'MEDIA_VIDEO_DELETE'
const MEDIA_VIDEO_DELETE_SUCCESS = 'MEDIA_VIDEO_DELETE_SUCCESS'
const MEDIA_VIDEO_DELETE_ERROR = 'MEDIA_VIDEO_DELETE_ERROR'
const MEDIA_VIDEO_DELETE_LIST = 'MEDIA_VIDEO_DELETE_LIST'
const MEDIA_VIDEO_DELETE_LIST_SUCCESS = 'MEDIA_VIDEO_DELETE_LIST_SUCCESS'
const MEDIA_VIDEO_DELETE_LIST_ERROR = 'MEDIA_VIDEO_DELETE_LIST_ERROR'

export {
  MEDIA_VIDEO_GET_BY_FILTER,
  MEDIA_VIDEO_GET_BY_FILTER_SUCCESS,
  MEDIA_VIDEO_GET_BY_FILTER_ERROR,
  MEDIA_VIDEO_GET_BY_ID,
  MEDIA_VIDEO_GET_BY_ID_SUCCESS,
  MEDIA_VIDEO_GET_BY_ID_ERROR,
  MEDIA_VIDEO_UPLOAD_MANY,
  MEDIA_VIDEO_UPLOAD_MANY_SUCCESS,
  MEDIA_VIDEO_UPLOAD_MANY_ERROR,
  MEDIA_VIDEO_UPLOAD,
  MEDIA_VIDEO_UPLOAD_SUCCESS,
  MEDIA_VIDEO_UPLOAD_ERROR,
  MEDIA_VIDEO_PATCH,
  MEDIA_VIDEO_PATCH_SUCCESS,
  MEDIA_VIDEO_PATCH_ERROR,
  MEDIA_VIDEO_DELETE,
  MEDIA_VIDEO_DELETE_SUCCESS,
  MEDIA_VIDEO_DELETE_ERROR,
  MEDIA_VIDEO_DELETE_LIST,
  MEDIA_VIDEO_DELETE_LIST_SUCCESS,
  MEDIA_VIDEO_DELETE_LIST_ERROR,
}
