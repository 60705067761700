const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    //width: 450,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1,
    )}px`,
  },

  input: {
    minWidth: '245px',
  },
  form: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
})

export default styles
