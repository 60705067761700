import PropTypes from 'prop-types'
// @mui
import { alpha, styled } from '@material-ui/core/styles'
import { Card, Typography } from '@material-ui/core'
// utils
import { fShortenNumber } from 'src/utils/formatNumber'
// components
import Iconify from 'src/components/Iconify'

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  width : 150,
  padding: theme.spacing(2,0)
}))

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: '50px',
  height: '42px',
  justifyContent: 'center',
}))

// ----------------------------------------------------------------------

AnalyticsWidgetSummary.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
  ]),
  icon: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
}

export default function AnalyticsWidgetSummary({
  title,
  total,
  icon,
  color = 'primary',
}) {
  return (
    <RootStyle
      sx={{
        color: theme => theme.palette[color].darker,
        bgcolor: theme => theme.palette[color].lighter,
      }}
    >
      <IconWrapperStyle
        sx={{
          color: theme => theme.palette[color].dark,
          backgroundImage: theme =>
            `linear-gradient(135deg, ${alpha(
              theme.palette[color].dark,
              0,
            )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
        }}
      >
        <Iconify icon={icon} width={28} height={28} />
      </IconWrapperStyle>
      <Typography variant="h2">{fShortenNumber(total)}</Typography>
      <Typography
        variant="subtitle2"
        sx={{ opacity: 0.72, marginBottom: '8px' }}
      >
        {title}
      </Typography>
    </RootStyle>
  )
}
