import React, { useState, useEffect } from 'react'
import {
  withStyles,
  Typography,
  Grid,
  Paper,
  Box,
  Divider,
  Button,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import styles from './style'
import { userGetSelfAction } from 'src/reducers/user/action'
import { useDispatch } from 'react-redux'
import Lottie from 'react-lottie'
import animationData from 'src/lotties/mobile-desktop.json'

import { track } from '@amplitude/analytics-browser'

const Options = [
  {
    cardName: 'Create with AI-Assistant',
    icon: '/assets/use-ai-assistant.png',
    text: 'Make AI create a complete mini-course based on your guidance.',
    height: '380px',
    opacity: 1,
  },
  {
    cardName: 'From scratch',
    icon: '/assets/from-scratch.png',
    text: 'Start to build mini-course immediately with your content.',
    badge: 'Optimized for desktop',
    height: '300px',
    opacity: 0.6,
  },
  {
    cardName: 'Select Template',
    icon: '/assets/select-template.png',
    text: 'See use-case ideas and content suggestions from common practices.',
    badge: 'Optimized for desktop',
    height: '300px',
    opacity: 0.6,
  },
]
const MobileUi = props => {
  const { classes } = props
  let history = useHistory()
  const [collection, setCollection] = useState(
    window.location.pathname.split('create-course/')[1] || '',
  )
  const dispatch = useDispatch()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    dispatch(userGetSelfAction())
  }, [])

  const handleClickCard = index => {
    if (index == 0) {
      history.push('/create-course-with-ai/' + collection)
      track('mobile_ai_selected', {})
      return
    }
    return true
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Typography variant="h3">Hi, {window.CONSTANTS.NAME}👋</Typography>
        <Paper
          className={classes.paper}
          sx={{
            p: 2,
            width: 1,
            marginBottom: 2.5,
            marginTop: 2.5,
          }}
        >
          <Typography className={classes.desktopText} textAlign="center">
            Creating, editing and managing Quizzes are optimized for desktop
            browsers. Please visit your account{' '}
            <span style={{ color: '#F67B3F' }}>on desktop</span> for the full
            capability.
          </Typography>

          <img src="assets/qcube-desktop-mobile.png" />
        </Paper>

        <Box m={2}></Box>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(MobileUi)
