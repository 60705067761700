import { arrayMove } from '@dnd-kit/sortable'
import { useCallback } from 'react'
import { findValueOfItems } from '.'
const useHandleDragMove = props => {
  const {
    activeId,
    containers,
    collections,
    setContainers,
    setChangedCourses,
  } = props
  return useCallback(
    event => {
      const { active, over } = event
      // Handle Items Sorting
      if (activeId !== active.id) return

      if (
        over.id.toString().includes('empty-item-') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        const emptyActiveContainer = findValueOfItems(
          active.id.split('-item')[0],
          'item',
          containers,
        )

        const emptyOverContainer = findValueOfItems(
          over.id.split('empty-item-')[1],
          'container',
          containers,
        )

        if (!emptyActiveContainer || !emptyOverContainer) return
        const activeContainerIndex = containers.findIndex(
          container => container.id === emptyActiveContainer.id,
        )
        const emptyOverContainerIndex = containers.findIndex(
          container => container.id === emptyOverContainer.id,
        )
        const activeitemIndex = emptyActiveContainer.courses.findIndex(
          x => x.id == active.id.split('-item')[0],
        )

        let newItems = [...containers]
        const [removeditem] = newItems[activeContainerIndex].courses.splice(
          activeitemIndex,
          1,
        )
        newItems[emptyOverContainerIndex].courses.push(removeditem)

        let changedCoursesInit = []
        for (const collection of newItems) {
          for (const course of collection.courses) {
            changedCoursesInit.push({
              ...course,
              courseCollectionId: collection.id,
              courseCollectionOrder: collection.courses.indexOf(course),
              courseCollectionDto: collections.find(x => x.id == collection.id),
            })
          }
        }
        setChangedCourses(changedCoursesInit)
        newItems.map(x =>
          x.courses.sort((a, b) => {
            return a.courseCollectionOrder - b.courseCollectionOrder
          }),
        )
        setContainers(newItems)
        return
      }

      if (
        active.id.toString().includes('item') &&
        over?.id.toString().includes('item') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        // Find the active container and over container
        const activeContainer = findValueOfItems(
          active.id.split('-item')[0],
          'item',
          containers,
        )
        const overContainer = findValueOfItems(
          over.id.split('-item')[0],
          'item',
          containers,
        )
        // If the active or over container is not found, return
        if (!activeContainer || !overContainer) return

        // Find the index of the active and over container

        const activeContainerIndex = containers.findIndex(
          container => container.id === activeContainer.id,
        )
        const overContainerIndex = containers.findIndex(
          container => container.id === overContainer.id,
        )

        // Find the index of the active and over item
        const activeitemIndex = activeContainer.courses.findIndex(
          item => item.id.toString() === active.id.split('-item')[0],
        )
        const overitemIndex = overContainer.courses.findIndex(
          item => item.id.toString() === over.id.split('-item')[0],
        )

        // In the same container
        if (activeContainerIndex === overContainerIndex) {
          let newItems = [...containers]

          newItems[activeContainerIndex].courses = arrayMove(
            newItems[activeContainerIndex].courses,
            activeitemIndex,
            overitemIndex,
          )
          let changedCoursesInit = []

          for (const course of activeContainer.courses) {
            changedCoursesInit.push({
              ...course,
              courseCollectionId: activeContainer.id,
              courseCollectionOrder: activeContainer.courses.indexOf(course),
              courseCollectionDto: collections.find(
                x => x.id == activeContainer.id,
              ),
            })
          }

          setChangedCourses(changedCoursesInit)
          newItems.map(x =>
            x.courses.sort((a, b) => {
              return a.courseCollectionOrder - b.courseCollectionOrder
            }),
          )
          setContainers(newItems)
        } else {
          // In different containers
          let newItems = [...containers]
          const [removeditem] = newItems[activeContainerIndex].courses.splice(
            activeitemIndex,
            1,
          )
          newItems[overContainerIndex].courses.splice(
            overitemIndex,
            0,
            removeditem,
          )
          let changedCoursesInit = []
          for (const collection of newItems) {
            for (const course of collection.courses) {
              changedCoursesInit.push({
                ...course,
                courseCollectionId: collection.id,
                courseCollectionOrder: collection.courses.indexOf(course),
                courseCollectionDto: collections.find(
                  x => x.id == collection.id,
                ),
              })
            }
          }

          setChangedCourses(changedCoursesInit)
          newItems.map(x =>
            x.courses.sort((a, b) => {
              return a.courseCollectionOrder - b.courseCollectionOrder
            }),
          )
          setContainers(newItems)
        }
        return
      }
    },
    [activeId, containers, collections, setContainers, setChangedCourses],
  )
}

export default useHandleDragMove
