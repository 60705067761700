import React from 'react'
// @mui
import { Stack } from '@material-ui/core'
import {} from '@material-ui/core/styles'
import { Droppable } from 'react-beautiful-dnd'
// redux
// components
// sections
import { KanbanColumn } from 'src/components/kanban'
// ----------------------------------------------------------------------

import { DEFAULT_COLLECTION_GUID } from '../index'

function NonCollectionCourses(props) {
  const {
    courseDtos,
    history,
    isUserReachedCourseLimit,
    setIsUserReachedCourseLimit,
  } = props
  const defaultCollection = {
    id: DEFAULT_COLLECTION_GUID,
    collectionName: 'Independent Mini-Courses',
    collectionIndex: 0,
    isDeleted: false,
    userId: '',
  }
  return (
    <Droppable
      droppableId="default-column"
      direction="horizontal"
      type="column"
      isDropDisabled={true}
    >
      {provided => (
        <Stack
          {...provided.droppableProps}
          ref={provided.innerRef}
          direction="row"
          alignItems="flex-start"
          spacing={1}
          paddingRight="16px"
          sx={{ height: 'calc(100% - 32px)' }}
        >
          {[defaultCollection].map((collection, index) => {
            let courses = courseDtos.filter(
              course => course.courseCollectionId == null,
            )

            courses.sort((a, b) => {
              return a.courseCollectionOrder - b.courseCollectionOrder
            })
            return (
              <KanbanColumn
                courses={courses}
                index={courses.courseCollectionOrder}
                key={collection.id}
                column={{
                  name: collection.collectionName,
                  cardIds: [],
                  id: collection.id,
                  collectionIndex: index, //collection.collectionIndex,
                }}
                isUserReachedCourseLimit={isUserReachedCourseLimit}
                setIsUserReachedCourseLimit={setIsUserReachedCourseLimit}
                history={history}
              />
            )
          })}
        </Stack>
      )}
    </Droppable>
  )
}
export default NonCollectionCourses
