import React from 'react'
import style from '../style'
import propTypes from '../prop'
import ClassNames from 'classnames'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles, TableCell, IconButton } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const ListContainerColumnExpanded = props => {
  const { classes, expanded, changeExpanded, entity, disabled } = props

  /**
   *
   * @param {*} event
   */
  const handleOnClick = event => {
    changeExpanded(entity.id)
  }

  return (
    <TableCell className={ClassNames(classes.root, classes.expandedColumn)}>
      <IconButton
        disabled={disabled}
        onClick={handleOnClick}
        color="primary"
        className={ClassNames(classes.expand, {
          [classes.expanded]: expanded,
        })}
        aria-expanded={expanded}
      >
        <ExpandMoreIcon />
      </IconButton>
    </TableCell>
  )
}

ListContainerColumnExpanded.propTypes = propTypes

export default withStyles(style)(ListContainerColumnExpanded)
