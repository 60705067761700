import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import clsx from 'clsx'
import { Button } from '@material-ui/core'
const DndContainer = ({ id, children, title, description }) => {
  const {
    attributes,
    setNodeRef,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    data: {
      type: 'container',
    },
  })
  return (
    <div
      {...attributes}
      ref={setNodeRef}
      //      {...listeners}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : 1,
        minWidth: '100px',
        minHeight: '100px',
      }}
    >
      {children}
    </div>
  )
}

export default DndContainer
