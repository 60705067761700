import React from 'react'
import style from './style'
import propTypes from '../prop'
import { withStyles, Grid, Typography } from '@material-ui/core'

const ItemLayoutForm = props => {
  const { classes, formItem, element } = props

  return (
    <Grid className={classes.root} container alignItems="center">
      {formItem.description && (
        <Grid item xs={12}>
          {formItem.description()}
        </Grid>
      )}
      {/*   {formItem.label && (
        <Grid item xs={4}>
          <Typography
            variant="body2"
            className={ClassNames({
              [classes.formLabel]: true,
              [classes.hasContent]: hasContent,
              [classes.disabled]: formItem.disabled,
            })}
          >
            {formItem.label}
            {': '}
            {formItem.required && ' *'}
          </Typography>
        </Grid>
      )}
 */}
      <Grid item xs={12}>
        <Grid container>{element}</Grid>

        {formItem.info && (
          <Grid item xs={12}>
            <Typography className={classes.info}>{formItem.info}</Typography>
          </Grid>
        )}

        {formItem.error.success === false && (
          <Grid container>
            <Typography className={classes.error} variant="caption">
              {formItem.error.message}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

ItemLayoutForm.propTypes = propTypes

export default withStyles(style)(ItemLayoutForm)
