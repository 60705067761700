import {
  USER_PASSWORD_UPDATE_ENTITY,
  USER_PASSWORD_PUT_SUCCESS,
  USER_PASSWORD_SET_SUCCESS,
} from './type'

export default function (
  state = {
    entity: null,
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case USER_PASSWORD_UPDATE_ENTITY:
      const { entity } = payload

      if (entity == null) {
        return {
          ...state,
          entity: null,
        }
      }

      if (state.entity == null) {
        state.entity = {}
      }

      return {
        ...state,
        entity: {
          ...state.entity,
          ...entity,
        },
      }
    case USER_PASSWORD_PUT_SUCCESS:
      return { ...state, entity: payload.entity }
    case USER_PASSWORD_SET_SUCCESS:
      return { ...state, entity: payload.entity }
    /*  case USER_RENEW_PASSWORD_SUCCESS:
            return { ...state, entity: payload.entity} */
    default:
      return state
  }
}
