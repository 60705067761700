import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_FOLDER_ADD_ASYNC } from '../../reducers/media-folder/type'
import { mediaFolderAddService } from '../../api/media-folder'
import { mediaFolderAddSuccess } from '../../reducers/media-folder/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaFolderAddAsyncFlow(payload) {
  try {
    const { content, callback } = payload
    const response = yield call(mediaFolderAddService, content)

    yield put(mediaFolderAddSuccess(response.data))
    yield put(operationSuccess())
    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaFolderAddAsyncWatcher() {
  while (true) {
    const action = yield take(MEDIA_FOLDER_ADD_ASYNC)
    const { payload } = action

    yield fork(generalSaga, mediaFolderAddAsyncFlow, payload)
  }
}
