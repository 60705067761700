import {
  USER_UPDATE_ENTITY,
  USER_LIST,
  USER_LIST_SUCCESS,
  USER_GET_SUCCESS,
  USER_VALIDATE_SHORT_LINK_SUCCESS,
  USER_GET_SELF_SUCCESS,
  USER_ADD_SUCCESS,
  DELETE_USER_SUCCESS,
  USER_REPORT_QUESTION_ANALYSIS_SUCCESS,
  USER_REPORT_DETAILED_ANALYSIS_SUCCESS,
} from './type'

export default function (
  state = {
    list: { results: null, totalCount: 0 },
    reportQuestionAnalysis: { results: null, totalCount: 0 },
    reportDetailedAnalysis: {
      participation: 0,
      repliedItems: 0,
      sentItems: 0,
      enrolledPrograms: {
        journeyList: [],
        reinforcementList: [],
        quizGameList: [],
      },
      userDTO: {
        name: '',
        surName: '',
      },
    },
    entity: null,
    query: null,
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case USER_UPDATE_ENTITY:
      const { entity } = payload

      if (entity == null) {
        return {
          ...state,
          entity: null,
        }
      }

      if (state.entity == null) {
        state.entity = {}
      }

      return {
        ...state,
        entity: {
          ...state.entity,
          ...entity,
        },
      }
    case USER_LIST:
      return { ...state, query: payload.query }
    case USER_LIST_SUCCESS:
      return { ...state, list: payload.response }
    case USER_GET_SUCCESS:
      return { ...state, entity: payload.response }
    case USER_VALIDATE_SHORT_LINK_SUCCESS:
      return { ...state, entity: payload.response }
    case USER_GET_SELF_SUCCESS:
      return { ...state, entity: payload.response }
    case USER_ADD_SUCCESS:
      return { ...state, entity: payload.entity }
    case DELETE_USER_SUCCESS:
      return { ...state, userId: payload.userId }
    case USER_REPORT_QUESTION_ANALYSIS_SUCCESS:
      return { ...state, reportQuestionAnalysis: payload.response }
    case USER_REPORT_DETAILED_ANALYSIS_SUCCESS:
      return { ...state, reportDetailedAnalysis: payload.response }
    default:
      return state
  }
}
