const ShopFieldNames = {
  ID: 'id',
  NAME: 'name',
  SHOP_LOGO: 'shopLogo',
  SUBJECT: 'subject',
  GOAL1: 'goal1',
  GOAL2: 'goal2',
  GOAL3: 'goal3',
  GOAL4: 'goal4',
  IS_ACTIVE: 'isActive',
  INFO_URL: 'infoUrl',
}

const ShopSchema = () => {
  return {
    [ShopFieldNames.ID]: null,
    [ShopFieldNames.SHOP_KEY]: '',
    [ShopFieldNames.NAME]: '',
    [ShopFieldNames.SHOP_LOGO]: null,
    [ShopFieldNames.SUBJECT]: '',
    [ShopFieldNames.GOAL1]: '',
    [ShopFieldNames.GOAL2]: '',
    [ShopFieldNames.GOAL3]: '',
    [ShopFieldNames.GOAL4]: '',
    [ShopFieldNames.IS_ACTIVE]: true,
    [ShopFieldNames.INFO_URL]: '',
  }
}

export { ShopSchema, ShopFieldNames }
