import { put, call, take, fork } from 'redux-saga/effects'
import { questionLinkAddService } from '../../api/question'
import { questionLinkAddSuccess } from '../../reducers/assignment/action'
import { QUESTION_LINK_ADD } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionLinkAddFlow(payload) {
  try {
    const { entity, callback } = payload
    const response = yield call(questionLinkAddService, entity)

    yield put(questionLinkAddSuccess(response.data))

    callback()
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionLinkAddWatcher() {
  while (true) {
    const action = yield take(QUESTION_LINK_ADD)
    const { payload } = action

    yield fork(generalSaga, questionLinkAddFlow, payload)
  }
}
