const style = theme => ({
  container: {
    minHeight: theme.spacing(7),
    width: '100%',
    backgroundColor: '#F4F6F8',
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  checkBoxGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginRight: theme.spacing(2),
    color: '#637381'
  },
})

export default style
