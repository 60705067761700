const findCourseById = (containers, courseId) => {
  for (const collection of containers) {
    const foundCourse = collection.courses.find(
      course => course.id.toString() === courseId,
    )
    if (foundCourse) {
      return foundCourse
    }
  }
  return null // Return null if no course is found
}
export default findCourseById
