import { COMPONENT_BOX_SHOW, COMPONENT_BOX_HIDE } from './type'

/**
 *
 * @param {*} content
 */
export const componentBoxShowAction = content => {
  return {
    type: COMPONENT_BOX_SHOW,
    payload: {
      content,
    },
  }
}

/**
 *
 * @param {*} content
 */
export const componentBoxHideAction = content => {
  return {
    type: COMPONENT_BOX_HIDE,
    payload: {
      content,
    },
  }
}
