import React from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles } from '@material-ui/core'
import { Popover, Button, Alert, AlertTitle } from '@material-ui/core'
import Link from '../../link'

import { QuestionFieldNames } from 'src/model/question'
import InfoIcon from '@material-ui/icons/Info'

/**
 *
 * @param {*} props
 */
const Sertifier = props => {
  const { dispatch, entity, classes } = props

  const sertifierData = entity[QuestionFieldNames.SETTING_DTOS].find(x => x.key === "sertifierPublishableKey");
  const isSertifierConnected = sertifierData && sertifierData.value != ""; // 1: Sertifier
  const deliveryData = entity[QuestionFieldNames.SETTING_DTOS].find(x => x.key === "sertifierDeliveryId"); // 1: Sertifier

  return (
    <div className={classes.center}>
      {!isSertifierConnected && 
        <Alert severity="warning" className={classes.alert}>
          The sertifier has not been connected. This card is considered invalid.
        </Alert>}
      {(isSertifierConnected && !deliveryData) && 
        <Alert severity="warning" className={classes.alert}>
          The sertifier certificate has not been selected. This card is considered invalid.
        </Alert>}
      {isSertifierConnected && deliveryData && <Alert severity="success" className={classes.alert}>
        You are ready for issue certificates!
      </Alert>}
    </div>
  )
}

Sertifier.propTypes = propTypes

export default withStyles(styles)(Sertifier)
