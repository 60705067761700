import React, { useState, useEffect } from 'react'
import translator from 'src/translate'
import { MButton } from 'src/theme'
import styles from './styles'
import StarIcon from '@material-ui/icons/Star'
import CodeIcon from '@material-ui/icons/Code'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { domainListAction } from 'src/reducers/domain/action'
import URLs from 'src/api/url'
import {
  Typography,
  TextField,
  withStyles,
  Grid,
  Button,
  Tab,
  FormControl,
  Box,
  Select,
  MenuItem,
  Input,
} from '@material-ui/core'
import { track } from '@amplitude/analytics-browser'

const ShareCollection = props => {
  const { handleBack, dispatch, classes, collection } = props
  const [tabValue, setTabValue] = useState(0)
  const [showShareButtons, setShowShareButtons] = useState(0)
  const [domains, setDomains] = useState()
  const [domainName, setDomainName] = useState()
  useEffect(() => {
    const query = {
      CurrentPage: 0,
      PageSize: 5,
      SortingColumn: 'LastModifiedAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    }

    dispatch(
      domainListAction({ ...query, SortingColumn: 'LastModifiedAt' }, data => {
        setDomains(data.results)
        data.results[0].domain
          ? setDomainName(data.results[0].domain)
          : setDomainName('share.quizcube.io')
      }),
    )
  }, [])

  track('Collection_Share', {
    type: 'Link',
  })

  const handleTabChange = (collection, newValue) => {
    setTabValue(newValue)
  }
  if (!collection) {
    return null
  }

  /**
   *
   * @param {*} e
   */
  const handleDomain = e => {
    setDomainName(e.target.value)
  }

  const domain = collection.customDomainDtos
    ? collection.customDomainDtos.length == 0 ||
      collection.customDomainDtos[0].verificationStatus != 1
      ? 'https://share.quizcube.io/'
      : 'https://' + collection.customDomainDtos[0].domain + '/'
    : 'https://share.quizcube.io/'

  const hasVerifiedDomain =
    domains && domains.find(x => x.verificationStatus == 1)

  const collectionName = collection.collectionName
    .replaceAll(/\?.*$/g, '')
    .replaceAll(' ', '-')


  return collection ? (
    <div className={classes.settingsContainer}>
      <div className={classes.headerLine}>
        <div className={classes.header}>
          <Typography variant="h6">
            {translator._('assignment.share')}
          </Typography>
          <Typography variant="body2">
            {translator._('assignment.yourCourseShareInfo')}
          </Typography>
        </div>
        {handleBack && (
          <MButton
            variant="outlined"
            color="inherit"
            className={classes.prevButton}
            onClick={() => handleBack()}
          >
            {translator._('general.back')}
          </MButton>
        )}
      </div>

      <div>
        <TabContext value={tabValue}>
          <TabList
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { background: '#00AB55' },
            }}
          >
            <Tab
              disableRipple
              label={translator._('assignment.shareLink')}
              value={0}
              icon={<StarIcon fontSize="small" color="secondary" />}
            />

            <Tab
              disableRipple
              label={translator._('assignment.embedCode')}
              value={1}
              icon={<CodeIcon fontSize="small" color="secondary" />}
            />
          </TabList>

          <TabPanel value={tabValue} index={0} hidden={tabValue !== 0}>
            {hasVerifiedDomain && (
              <div>
                <FormControl
                  style={{ width: '300px' }}
                  variant="outlined"
                  className={classes.listGroup}
                >
                  <Select
                    className={classes.select}
                    inputProps={{
                      className: classes.inputField,
                    }}
                    fullWidth
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Choose your domain"
                    value={domainName}
                    key={domainName}
                    disabled={false}
                    input={<Input id="select-input" name="select-input" />}
                    onChange={handleDomain}
                  >
                    <MenuItem value={'share.quizcube.io/'}>
                      share.quizcube.io
                    </MenuItem>
                    {domains &&
                      domains
                        .filter(x => x.verificationStatus == 1)
                        .map(value => {
                          return (
                            <MenuItem value={value.domain} key={value.domain}>
                              {value.domain}
                            </MenuItem>
                          )
                        })}
                  </Select>
                </FormControl>
              </div>
            )}

            <div className={classes.textFields}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    value={
                      hasVerifiedDomain
                        ? 'https://' +
                          domainName +
                          'collection/' +
                          collectionName +
                          '-1/' +
                          collection.id
                        : domain +
                          'collection/' +
                          collectionName +
                          '-1/' +
                          collection.id
                    }
                    className={classes.textField}
                  />

                  <CopyToClipboard
                    text={
                      hasVerifiedDomain
                        ? 'https://' +
                          domainName +
                          'collection/' +
                          collectionName +
                          '-1/' +
                          collection.id
                        : domain +
                          'collection/' +
                          collectionName +
                          '-1/' +
                          collection.id
                    }
                    className={classes.copy}
                  >
                    <Button variant="outlined">
                      {translator._('assignment.copy')}
                    </Button>
                  </CopyToClipboard>
                  <Box m={5}></Box>
                </Grid>
              </Grid>
            </div>
          </TabPanel>

          <TabPanel value={tabValue} index={1} hidden={tabValue !== 1}>
            {/*
            <div className={classes.settings}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showShareButtons}
                    onChange={(e, checked) => {
                      setShowShareButtons(checked)
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={translator._('assignment.showShareButtonsLabel')}
              />
            </div>
          */}
            <div className={classes.textFields}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <span>
                    Add the following codes to the {'<head>'} tag of your site.
                  </span>
                  <TextField
                    fullWidth
                    multiline
                    value={`
                      <meta http-equiv="Access-Control-Allow-Origin" content="{0}" />
                      <script src="{1}/viewer.js" />
                      <script>
                        const app = new MiniCourse();

                        document.addEventHandler("DOMContentLoaded", () => {
                          app.init({
                            collectionId: "{2}"
                          })
                        })
                      </script>`
                      .replace('{0}', URLs.APP_URL)
                      .replace('{1}', URLs.APP_URL)
                      .replace('{2}', collection.id)}
                    className={classes.textField}
                  />

                  <CopyToClipboard
                    text={`
                    <meta http-equiv="Access-Control-Allow-Origin" content="{0}" />
                    <script src="{1}/viewer.js" />
                    <script>
                      const app = new MiniCourse();

                      document.addEventHandler("DOMContentLoaded", () => {
                        app.init({
                          collectionId: "{2}"
                        })
                      })
                    </script>`
                      .replace('{0}', URLs.APP_URL)
                      .replace('{1}', URLs.APP_URL)
                      .replace('{2}', collection.id)}
                    className={classes.copy}
                  >
                    <Button variant="outlined">
                      {translator._('assignment.copy')}
                    </Button>
                  </CopyToClipboard>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className={classes.previewGroup}>
                    <Typography variant="body1">
                      You can embed your site with JavaScript code.
                      <br />
                      Paste the code in the {'<head>'} of your site.
                      <br />
                      <br />
                      Then add the following in the {'<body>'} where you want to
                      show the course:
                      <br />
                      <br />
                      {'<div id="quizcube-collection-' +
                        collection.id +
                        '"></div>'}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <span>
                    Add the following codes to the {'<body>'} tag of your site.
                  </span>
                  <TextField
                    fullWidth
                    multiline
                    value={
                      '<div id="quizcube-collection-' +
                      collection.id +
                      '"></div>'
                    }
                    className={classes.textField}
                  />

                  <CopyToClipboard
                    text={
                      '<div id="quizcube-collection-' +
                      collection.id +
                      '"></div>'
                    }
                    className={classes.copy}
                  >
                    <Button variant="outlined">
                      {translator._('assignment.copy')}
                    </Button>
                  </CopyToClipboard>
                  <Box m={3}></Box>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  ) : null
}

export default withStyles(styles)(ShareCollection)
