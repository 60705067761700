const AssignmentTypes = {
  AUTOMATED: true,
  ONETIME: false,
}

const AssignmentQuestionSendTypes = {
  ORDERED: 3,
  RANDOM: 2,
}

const AssignmentTimingRadioValues = {
  NOW: 'now',
  LATER: 'later',
}

const AssignmentOneTimeSendDelayByMinutes = 2

const AssignmentDaysSelectionValues = {
  EVERYDAY: 'everyday',
  REPEAT_ON_DAY: 'repeatOnDay',
  EVERY_WEEKDAY: 'everyWeekday',
}

const AssignmentFieldNames = {
  ID: 'id',
  SHOP_ID: 'shopId',
  START_DATE: 'startDate',
  FINISH_DATE: 'finishDate',
  DAYS: 'days',
  SEND_TIME: 'sendTime',
  SEND_TYPE: 'sendType',
  QUESTION_COUNT: 'questionCount',
  IS_ACTIVE: 'isActive',
  IS_DELETED: 'isDeleted',
  TEAM_DTOS: 'teamDTOs',
  SUBJECT_DTOS: 'subjectDTOs',
  CREATE_DATE: 'createdAt',
  FINISH_TYPE: 'finishType',
  NAME: 'name',
  TIME_ZONE: 'timeZone',
  IS_PERIODIC: 'isPeriodic',
  ALLOW_REPEATED_QUESTIONS: 'allowRepeatedQuestions',
  DESCRIPTION: 'description',
  IMAGE_URL: 'imageUrl',
  AUTO_SENDER_QUESTION_RELATION_DTOS: 'courseCardDtos',
  AUTO_SENDER_USER_RELATION_DTOS: 'autoSenderUserRelationDTOs',
  QUESTION_DTOS: 'cardDtos',
  USER_DTOS: 'userDTOs',
  LEADERBOARD_DTO: 'leaderboardDTO',
  UPDATE_DATE: 'lastModifiedAt',
  LAST_UPDATED_BY: 'lastUpdatedBy',
  IS_ADMIN_INCLUDED_IN_USERS: 'isAdminIncludedInUsers',
  CODE: 'code',
  IS_AI: 'isAI',
  THUMBNAIL: 'thumbnail',

  SETTING_DTOS: 'settingDtos',
}

const AssignmentSchema = () => {
  return {
    [AssignmentFieldNames.ID]: null,
    [AssignmentFieldNames.SHOP_ID]: null,
    //[AssignmentFieldNames.Assignment_SUBJECT_LIST]: [],
    [AssignmentFieldNames.START_DATE]: null,
    [AssignmentFieldNames.FINISH_DATE]: null,
    [AssignmentFieldNames.DAYS]: null,
    [AssignmentFieldNames.SEND_TIME]: null,
    [AssignmentFieldNames.SEND_TYPE]: AssignmentQuestionSendTypes.ORDERED,
    [AssignmentFieldNames.QUESTION_COUNT]: null,
    [AssignmentFieldNames.IS_ACTIVE]: true,
    [AssignmentFieldNames.IS_DELETED]: false,
    [AssignmentFieldNames.CREATE_DATE]: null,
    // [AssignmentFieldNames.FINISH_TYPE]: AssignmentQuestionFinishTypes.DEFAULT,
    [AssignmentFieldNames.NAME]: '',
    [AssignmentFieldNames.TIME_ZONE]: window.CONSTANTS.TIME_ZONE,
    [AssignmentFieldNames.ALLOW_REPEATED_QUESTIONS]: false,
    [AssignmentFieldNames.DESCRIPTION]: null,
    [AssignmentFieldNames.IMAGE_URL]: null,
    [AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS]: [],
    [AssignmentFieldNames.AUTO_SENDER_USER_RELATION_DTOS]: [],
    [AssignmentFieldNames.QUESTION_DTOS]: [],
    [AssignmentFieldNames.USER_DTOS]: [],
    [AssignmentFieldNames.LEADERBOARD_DTO]: {},
    // [AssignmentFieldNames.UPDATE_DATE]: '',
    // [AssignmentFieldNames.LAST_UPDATED_BY]: '',
    [AssignmentFieldNames.IS_ADMIN_INCLUDED_IN_USERS]: false,
    [AssignmentFieldNames.CODE]: null,
  }
}

const AssignmentInitialCourseSettings = {
  general: {
    headerScript: '',
    logo: '',
    sendEmailOnFinish: false,
    coverPage: false,
    coverPageText: '',
  },
  seo: {
    title: '',
    description: '',
    favicon: '',
    thumbnail: '',
    indexGoogle: false,
  },
  theme: {},
  webhookAnswer: {
    url: '',
    isActive: true,
    triggers: [
      {
        trigger: 0,
        isActive: false,
      },
      {
        trigger: 1,
        isActive: false,
      },
    ],
  },
}

export {
  AssignmentSchema,
  AssignmentFieldNames,
  AssignmentTypes,
  AssignmentQuestionSendTypes,
  AssignmentTimingRadioValues,
  AssignmentDaysSelectionValues,
  AssignmentOneTimeSendDelayByMinutes,
  AssignmentInitialCourseSettings,
}
