import { put, call, take, fork } from 'redux-saga/effects'
import { assignmentCloneService } from '../../api/assignment'
import { assignmentCloneSuccess } from '../../reducers/course-collection/action'
import { ASSIGNMENT_CLONE } from '../../reducers/course-collection/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* assignmentCloneFlow(payload) {
  try {
    const { code, callback } = payload

    const response = yield call(assignmentCloneService, code)

    yield put(assignmentCloneSuccess(response.data))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* assignmentCloneWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_CLONE)
    const { payload } = action

    yield fork(generalSaga, assignmentCloneFlow, payload)
  }
}
