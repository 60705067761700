import {
  DOMAIN_LIST,
  DOMAIN_LIST_SUCCESS,
  DOMAIN_LIST_ERROR,
  DOMAIN_DELETE,
  DOMAIN_DELETE_SUCCESS,
  DOMAIN_DELETE_ERROR,
  DOMAIN_RETRY,
  DOMAIN_RETRY_SUCCESS,
  DOMAIN_RETRY_ERROR,
  PATCH_DOMAIN,
  PATCH_DOMAIN_SUCCESS,
  PATCH_DOMAIN_ERROR,
  ADD_CUSTOM_DOMAIN,
  ADD_CUSTOM_DOMAIN_SUCCESS,
  ADD_CUSTOM_DOMAIN_ERROR,
} from './type'

/**
 *
 * @param {*} query
 *  @param {*} callback
 */
export const domainListAction = (query, callback) => {
  return {
    type: DOMAIN_LIST,
    payload: { query, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const domainListSuccess = response => {
  return {
    type: DOMAIN_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const domainListError = () => {
  return {
    type: DOMAIN_LIST_ERROR,
  }
}

/**
 *
 * @param {*} id
 * @param {*} callback
 */
export const domainDeleteAction = (id, callback) => {
  return {
    type: DOMAIN_DELETE,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const domainDeleteSuccess = response => {
  return {
    type: DOMAIN_DELETE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const domainDeleteError = () => {
  return {
    type: DOMAIN_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} id
 * @param {*} callback
 */
export const domainRetryAction = (id, callback) => {
  return {
    type: DOMAIN_RETRY,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const domainRetrySuccess = response => {
  return {
    type: DOMAIN_RETRY_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const domainRetryError = () => {
  return {
    type: DOMAIN_RETRY_ERROR,
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */
export const domainPatchAction = (content, callback) => {
  return {
    type: PATCH_DOMAIN,
    payload: { content, callback },
  }
}

/**
 *
 * @param {*} content
 */
export const domainPatchSuccess = content => {
  return {
    type: PATCH_DOMAIN_SUCCESS,
    payload: { content },
  }
}

/**
 *
 */
export const domainPatchError = () => {
  return {
    type: PATCH_DOMAIN_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const addCustomDomainAction = (entity, callback) => {
  return {
    type: ADD_CUSTOM_DOMAIN,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const addCustomDomainSuccess = response => {
  return {
    type: ADD_CUSTOM_DOMAIN_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const addCustomDomainError = () => {
  return {
    type: ADD_CUSTOM_DOMAIN_ERROR,
  }
}
