import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const getFilterNamesForItemService = query =>
  new Request(URL.API, URL.GET_FILTER_NAMES).get(query)

/**
 *
 * @param {*} userIds
 */
export const excelExportUsersService = userIds =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_USERS).post(
    { userIds },
    {
      responseType: 'blob',
    },
  )

/**
 *
 * @param {*} userIds
 */
export const excelExportAllUsersService = () =>
  new Request(URL.REPORT_API, URL.EXCEL_EXPORT_ALL_USERS).post({})

/**
 *
 * @param {*} excelFile
 */
export const excelImportUsersService = excelFile =>
  new Request(URL.API, URL.EXCEL_IMPORT_USERS).post(excelFile)

/**
 *
 * @param {
 * } userIds
 */
export const excelImportQuestionsService = excelFile =>
  new Request(URL.API, URL.EXCEL_IMPORT_QUESTIONS).post(excelFile)

/**
 *
 * @param {*} userIds
 */
export const sendActivationSMSService = userIds =>
  new Request(URL.API, URL.SEND_ACTIVATION_SMS).post({ userIds })

/**
 *
 * @param {*} userIds
 */
export const sendActivationEmailService = userIds =>
  new Request(URL.API, URL.SEND_ACTIVATION_EMAIL).post({ userIds })

/**
 *
 * @param {*} userIds
 */
export const sendMagicLinkViaEmailService = userIds =>
  new Request(URL.API, URL.SEND_MAGIC_LINK_VIA_EMAIL).post({ userIds })

/**
 *
 */
export const sendConstantsService = () =>
  new Request(URL.API, URL.GET_CONSTANTS).get()

/**
 *
 */
export const excelExportUserMessagesService = query =>
  new Request(URL.API, URL.EXCEL_EXPORT_USER_MESSAGES).post(query, {
    responseType: 'blob',
  })

/**
 *
 */
export const shopListService = () => new Request(URL.API, URL.SHOP_LIST).get()

export const languageCheckAPI = () =>
  new Request('https://ipapi.co/', 'json').get()

/**
 *
 * @param {*} code
 */
export const appsumoCodeService = code =>
  new Request(URL.API, URL.APPSUMO_CODE_CONFIRM).post({ code })

/**
 *
 * @param {*} promoCodes
 */
export const appsumoCouponRefundService = promoCodes =>
  new Request(URL.API, URL.APPSUMO_CODE_REFUND).post({ promoCodes })
