import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { switchMode, switchDirection } from '../reducers/settings/action'

import { QuestionFieldNames } from 'src/model/question'
import { AssignmentFieldNames } from 'src/model/assignment'

import {
  courseSettingPatch,
  courseSettingCreate,
} from 'src/reducers/assignment/action'

export function useCourse() {
  return useSelector(state => state.assignment.entity)
}

export function useExternalCourseSettings(entity, key) {
  if (!entity) return {}
  const settings = entity[AssignmentFieldNames.SETTING_DTOS]

  if (!settings) return {}

  if (!key) return settings

  const setting = settings.find(x => x.key === key)

  return setting
}

export function useCourseSettings(key) {
  const dispatch = useDispatch()
  const entity = useCourse()

  const settings = entity[AssignmentFieldNames.SETTING_DTOS]
  const settingEntity = settings.find(x => x.key === key)

  const [setting, setSetting] = useState(settingEntity)

  const execute = useCallback(
    (value = {}, callback = false) => {
      dispatch(
        courseSettingCreate(
          {
            courseId: entity[AssignmentFieldNames.ID],
            key: key,
            value: JSON.stringify(value),
          },
          entity[AssignmentFieldNames.ID],
          dto => {
            if (callback) {
              callback()
            }
            setSetting(dto)
          },
        ),
      )
    },
    [setting],
  )

  const update = useCallback(
    (value, callback) => {
      dispatch(
        courseSettingPatch(
          {
            path: 'Value',
            value: JSON.stringify(value),
          },
          setting.id,
          entity[AssignmentFieldNames.ID],
          key,
          () => {
            if (callback) callback()

            setSetting({
              ...setting,
              value: JSON.stringify(value),
            })
          },
        ),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setting],
  )

  const exists = setting !== undefined
  const value = exists && JSON.parse(setting.value)
  return {
    execute,
    update,
    exists,
    value,
  }
}

/*export function useCourseSettings(key) {
  const entity = useCourse()

  if (!entity) return 'NOT_LOADED'
  const settings = entity[AssignmentFieldNames.SETTING_DTOS]

  if (!key) return settings

  const setting = settings.find(x => x.key === key)

  return setting
}
*/
export function patchSetting(dispatch, courseId, id, key, value, callback) {
  dispatch(
    courseSettingPatch(
      {
        path: 'Value',
        value: value,
      },
      id,
      courseId,
      key,
      () => {
        if (callback) {
          callback()
        }
      },
    ),
  )
}

export function createSetting(dispatch, courseId, key, value, callback) {
  dispatch(
    courseSettingCreate(
      {
        courseId: courseId,
        key: key,
        value: value,
      },
      courseId,
      () => {
        if (callback) {
          callback()
        }
      },
    ),
  )
}
