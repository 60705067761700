import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import isEmpty from '../../../../utils/is-empty'
import { truncateString } from '../../../../utils/truncateString'
import { PatchData } from '../../../../model/patch'
import {
  withStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'

const FormItemSelect = props => {
  const {
    classes,
    required,
    disabled,
    value,
    options,
    optionAttributeName,
    updateEntityKeyValue,
    placeholder,
    nodeOperation,
    label,
  } = props

  const [optionsList, setOptions] = useState(options())
  const [stateValue, setStateValue] = useState(value)

  if (optionsList === null) {
    nodeOperation('')
      .then(response => {
        setOptions(response.data)
      })
      .catch(error => {})
  }
  /**
   *
   * @param event
   * @param child
   */
  const onChange = (event, child) => {
    const newValue = child.props.value

    updateEntityKeyValue(
      newValue,
      props,
      PatchData.OPERATION_REPLACE,
      optionsList[parseInt(child.key)],
    )

    setStateValue(newValue)
  }

  const placeholderView = () => {
    if (placeholder) {
      return <MenuItem value="">{placeholder}</MenuItem>
    } else {
      return ''
    }
  }

  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id={label}>
            {label} {required ? '*' : ''}
          </InputLabel>
          <Select
            label={label}
            labelId={label}
            fullWidth
            className={classes.select}
            disabled={disabled}
            required={required}
            value={isEmpty(stateValue) ? null : stateValue}
            onChange={onChange}
          >
            {placeholderView()}

            {optionsList &&
              optionsList.map((option, index) => (
                <MenuItem key={index} value={option[optionAttributeName.value]}>
                  {truncateString(option[optionAttributeName.label], 50)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      }
    />
  )
}

FormItemSelect.propTypes = propTypes

export default withStyles(style)(FormItemSelect)
