const style = theme => ({
  root: {
    height: 250,
    alignContent: "center",
    alignItems: "center"
  },
  content: {
    width: '100%',
    textAlign: 'center',
  },
  image: {
    width: 200,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

export default style
