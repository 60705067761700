import {
  GET_FILTER_NAMES_SUCCESS,
  SEND_ACTIVATION_SMS_SUCCESS,
  SEND_ACTIVATION_EMAIL_SUCCESS,
  EXCEL_EXPORT_USERS_SUCCESS,
  EXCEL_EXPORT_USER_MESSAGES_SUCCESS,
  EXCEL_EXPORT_ALL_USERS_SUCCESS,
  EXCEL_IMPORT_USERS_SUCCESS,
  EXCEL_IMPORT_GET_FORM_SUCCESS,
  EXCEL_IMPORT_QUESTIONS_SUCCESS,
  EXCEL_IMPORT_QUESTIONS_GET_FORM_SUCCESS,
  GET_CONSTANTS_SUCCESS,
  APPSUMO_CODE_CONFIRM_SUCCESS,
  APPSUMO_CODE_REFUND_SUCCESS,
} from './type'

import { set as setCookie } from 'es-cookie'

export default function (
  state = {
    list: { results: null, totalCount: 0 },
    entity: null,
    constants: null,
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case GET_FILTER_NAMES_SUCCESS:
      return { ...state, list: payload.response }
    case EXCEL_EXPORT_USERS_SUCCESS:
      return { ...state, entity: payload.entity }
    case EXCEL_EXPORT_USER_MESSAGES_SUCCESS:
      return { ...state, entity: payload.entity }
    case EXCEL_EXPORT_ALL_USERS_SUCCESS:
      return { ...state, entity: payload.entity }
    case EXCEL_IMPORT_GET_FORM_SUCCESS:
      return { ...state, entity: payload.entity }
    case EXCEL_IMPORT_USERS_SUCCESS:
      return { ...state, entity: payload.entity }
    case EXCEL_IMPORT_QUESTIONS_GET_FORM_SUCCESS:
      return { ...state, entity: payload.entity }
    case EXCEL_IMPORT_QUESTIONS_SUCCESS:
      return { ...state, entity: payload.entity }
    case SEND_ACTIVATION_SMS_SUCCESS:
      return { ...state, entity: payload.entity }
    case SEND_ACTIVATION_EMAIL_SUCCESS:
      return { ...state, entity: payload.entity }
    case GET_CONSTANTS_SUCCESS:
      return { ...state, constants: payload.constants }
    default:
      return state
  }
}
