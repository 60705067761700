import React, { useState, useRef, useEffect } from 'react'
import translator from 'src/translate'
import { PatchContent, PatchData } from 'src/model/patch'
import { patchCourseCollectionAction } from 'src/reducers/course-collection/action'
import { Stack, OutlinedInput, MenuItem, IconButton } from '@material-ui/core'
import Iconify from 'src/components/Iconify'
import styles from '../../styles'
import MessageBoxContent, { MessageBoxType } from 'src/model/message-box'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from 'src/reducers/message-box/action'
// @mui
import { withStyles } from '@material-ui/core'
import { ButtonType, ButtonData, ButtonActionType } from 'src/model/button'
import { deleteCourseCollectionAction } from 'src/reducers/course-collection/action'
import DeleteCollectionModal from './delete-collection-modal'

const CollectionName = props => {
  const { columnName, id, dispatch, classes } = props

  const [isHovering, setIsHovering] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const handlePatchCourseName = newName => {
    dispatch(
      patchCourseCollectionAction(
        new PatchData(
          new PatchContent(newName, 'Name', PatchData.OPERATION_REPLACE),
          id,
        ),
      ),
    )
  }

  const renameRef = useRef(null)
  const [value, setValue] = useState(columnName)

  const [open, setOpen] = useState(null)

  useEffect(() => {
    if (open) {
      if (renameRef.current) {
        renameRef.current.focus()
      }
    }
  }, [open])

  const handleClose = () => {
    setOpen(null)
  }

  const handleChangeColumnName = event => {
    setValue(event.target.value)
  }

  const handleUpdateColumn = event => {
    if (
      value != columnName &&
      (event.type === 'blur' || event.key === 'Enter') &&
      renameRef.current
    ) {
      renameRef.current.blur()
      handlePatchCourseName(value)
    }
  }

  const handleDeleteColumn = () => {
    setModalOpen(true)
  }
  return (
    <div
      className={classes.columnNameDiv}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <OutlinedInput
        size="small"
        placeholder="Name the category"
        value={value}
        onChange={handleChangeColumnName}
        onBlur={handleUpdateColumn}
        onKeyUp={handleUpdateColumn}
        inputRef={renameRef}
        className={classes.collectionOutlinedInput}
      />

      <div
        className={classes.columnNameIconButtonDiv}
        style={{ opacity: isHovering ? 1 : 0 }}
      >
        <IconButton
          size="small"
          className={classes.columnNameIconButton}
          onClick={handleDeleteColumn}
        >
          <Iconify
            icon={'uil:trash'}
            width={20}
            height={20}
            color={'#F67B3F'}
          />
        </IconButton>
      </div>
      {modalOpen && (
        <DeleteCollectionModal
          collectionId={id}
          dispatch={dispatch}
          isModalOpen={modalOpen}
          handleCloseModal={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(CollectionName)
