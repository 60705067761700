import React, { Component } from 'react'
import propTypes from './prop'
import styles from './style'
import { connect } from 'react-redux'
import translator from '../../translate'
import withStyles from '@material-ui/core/styles/withStyles'
import { Typography, Grid, Box, Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { componentBoxHideAction } from '../../reducers/component-box/action'
class SuccessCode extends Component {
  render() {
    const { classes, history, dispatch } = this.props
    const routeChange = () => {
      let path = `/`
      dispatch(componentBoxHideAction())
      history.push(path)
    }
    return (
      <Grid container spacing={2} className={classes.successGrid}>
        <Grid
          item
          component="img"
          alt={translator._('message.congratulations')}
          height={200}
          src="assets/confetti.gif"
          title={translator._('message.congratulations')}
        ></Grid>
        <Grid item md={12}>
          <Typography component="h1" variant="h4" align="center">
            {translator._('message.success')}
          </Typography>
        </Grid>
        <Box mt={3} />
        <Grid item>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={routeChange}
          >
            Return Main Page
          </Button>
        </Grid>
      </Grid>
    )
  }
}

SuccessCode.propTypes = propTypes

const mapStateToProps = response => ({
  componentBox: response.componentBox,
})
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(SuccessCode)),
)
