const Genre = [
  'Picasso',
  'Greg Rutkowski',
  'Da Vinci',
  'Michelangelo',
  'Andy Warhol',
  'Leonid Afremov',
  'Frida Kahlo',
  'Edward Hopper',
  'Keith Haring',
  'Boris Vallejo',
  'Roy Lichtenstein',
  'HR Giger',
  'Yayoi Kusama',
  'Salvador Dali',
  'Van Gogh',
  'Edward Hopper',
  'Neo Impressionist',
  'Surrealist',
  'Art Deco',
  'Art Nouveau',
  'Futuristic',
  'Rococo',
  'Baroque',
  'Raphael',
  'De Stijl',
  'Cubism',
  'Bauhaus',
  'Impressionism',
  'Photorealism',
  'Renaissance',
  'Roman Mosaic',
  'Realism',
  'Dada',
  'Fauvism',
]
export { Genre }

const Styles = [
  'Synthwave style',
  'Digital art',
  'Dramatic lighting',
  'Oil painting',
  'Shutterstock',
  'Marble art',
  'Anime',
  'Airbrush',
  'Retro art',
  'Story book',
  'Papercraft',
  'Low poly',
  'Charcoal',
  'Comic book',
  'Cartoon',
  'Pastels',
  'Neon',
  'Anaglyph',
  'Illustration art',
  'Illustration flat',
  'Illustration smooth',
  'Isometric',
  'Cyberpunk',
  'Japanese art',
  'Line art',
  'Oil painting',
  'Watercolor',
  'Painting art',
  'Portrait',
  'Photo Product',
  'Pixel Art',
  'Pop art',
  'Artistic portrait',
  'Lego bricks',
  'Star Wars',
  'Cubist',
  'Graffiti',
  'Street Art',
  'Perspective',
  'Abstract',
  'Pixar',
]
export { Styles }

const Places = [
  'On mars',
  'Interior',
  'Outdoor',
  'Office',
  'Garden',
  'Street',
  'In the car',
  'Park',
  'Hospital',
  'Home',
  '80’s',
  '2000’s',
  'At the forest',
  'Workplace',
]
export { Places }

const CameraSettings = [
  'Overhead view',
  'Low angle',
  'Aerial view',
  'Tilted frame',
  'Over-the-shoulder shot',
  'Mid-shot',
  'Long-shot',
  'Close-up',
  'Bokeh, 300mm lens',
  'Motion blur',
  'Fast shutter speed',
  'Sigma 85mm f/1.4',
]
export { CameraSettings }



const Presets = [
  {name: "Painting Art", imageUrl : "/assets/ai-img-presets/painting-art180x180.png", id:"painting_art"},
  {name: "Sketch Art", imageUrl : "/assets/ai-img-presets/sketch-art180x180.png", id:"sketch_art"},
  {name: "Minimal", imageUrl : "/assets/ai-img-presets/minimal180x180.png", id:"minimal"},
  {name: "Cinematic Art", imageUrl : "/assets/ai-img-presets/cinematic-art180x180.png", id:"cinematic_art"},
  {name: "Colorful Art", imageUrl : "/assets/ai-img-presets/colorful-art180x180.png", id:"colorful_art"},
  {name: "Cartoon", imageUrl : "/assets/ai-img-presets/cartoon180x180.png", id:"cartoon"},
]
export { Presets }
