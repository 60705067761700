import { put, call, take, fork } from 'redux-saga/effects'
import { userValidateShortLinkService } from '../../api/user'
import { userValidateShortLinkSuccess } from '../../reducers/user/action'
import { USER_VALIDATE_SHORT_LINK } from '../../reducers/user/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* userValidateShortLinkFlow(payload) {
  try {
    const { sIntegLink } = payload
    const response = yield call(userValidateShortLinkService, sIntegLink)

    yield put(userValidateShortLinkSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* userValidateShortLinkWatcher() {
  while (true) {
    const action = yield take(USER_VALIDATE_SHORT_LINK)
    const { payload } = action

    yield fork(generalSaga, userValidateShortLinkFlow, payload)
  }
}
