import translator from '../../translate'

/**
 *
 * @param value
 */
const ValidationFormItemTree = (form, formItem) => {
  if (formItem === undefined) {
    return ' '
  }
  const error = { success: true, message: '' }
  if (formItem.value === '' || formItem.value === null) {
    error.success = false
    error.message = translator._('form.error.empty')
    return error
  }
  return ' '
}
export default ValidationFormItemTree
