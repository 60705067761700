import PropTypes from 'prop-types'

const propTypes = {
  list: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  content: PropTypes.any.isRequired,
  onAdd: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  parentItemName: PropTypes.string.isRequired,
  addItemButtonLabel: PropTypes.string.isRequired,
  componentBoxContent: PropTypes.func.isRequired,
}

export default propTypes
