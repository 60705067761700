const style = theme => ({
  truncate: {
    /*  ...theme.custom.truncate,
        fontWeight: 600,
        verticalAlign: 'top', */

    fontWeight: 600,
    whiteSpace: 'normal',
  },
  flex: { display: 'flex' },
  textAlign: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  inlineBlock: { display: 'inline-block', fontSize: 13 },
  headerColumn: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    border: 'none',
    backgroundColor: 'transparent',
  },
  isSortable: {
    cursor: 'pointer',
  },
  tableRow: {
    verticalAlign: 'middle',
    height: theme.spacing(5),
  },
  sortableIcon: {},
})

export default style
