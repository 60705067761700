import React, { useState } from 'react'
import style from './style'
import propTypes from './prop'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClassNames from 'classnames'
import {
  withStyles,
  Typography,
  Grid,
  IconButton,
  Collapse,
  MenuList,
  MenuItem,
} from '@material-ui/core'

const FormItemTreeNode = props => {
  const {
    classes,
    node,
    nodeOperation,
    optionAttributeName,
    leafNodeControl,
    onSelectNode,
    value,
    attribute,
  } = props

  const [expand, setExpand] = useState(false)
  const [nodes, setNodes] = useState(node.nodes)
  const isLeafNode = leafNodeControl(node)

  let selected = null
  if (typeof value == 'object') {
    selected = value[attribute] === node[optionAttributeName.value]
  } else {
    selected = value === node[optionAttributeName.value]
  }

  /**
   *
   * @param {*} event
   */
  const onSelectClickHandler = event => {
    onSelectNode(node.id)
  }

  /**
   *
   * @param {*} event
   */
  const onExpandButtonClickHandler = event => {
    setExpand(!expand)

    if (node.nodes) {
      return
    }

    nodeOperation(node[optionAttributeName.value])
      .then(response => {
        node.nodes = response.data
        setNodes(node.nodes)
      })
      .catch(error => { })
  }

  return (
    <React.Fragment>
      <MenuList className={classes.menu}>
        <MenuItem className={classes.menu} selected={selected}>
          <Grid container>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <IconButton
                  disabled={isLeafNode}
                  onClick={onExpandButtonClickHandler}
                  aria-expanded={expand}
                  className={ClassNames({
                    [classes.expand]: true,
                    [classes.expandOpen]: expand,
                  })}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              className={classes.labelContainer}
              onClick={onSelectClickHandler}
            >
              <Typography variant="body1" className={classes.label}>
                {node[optionAttributeName.label]}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </MenuList>
      <Collapse
        className={classes.collapse}
        in={expand}
        timeout="auto"
        unmountOnExit
      >
        {nodes &&
          nodes.map((n, i) => {
            return (
              <FormItemTreeNode
                attribute={attribute}
                value={value}
                leafNodeControl={leafNodeControl}
                classes={classes}
                key={i}
                node={n}
                nodeOperation={nodeOperation}
                optionAttributeName={optionAttributeName}
                onSelectNode={onSelectNode}
              />
            )
          })}
      </Collapse>
    </React.Fragment>
  )
}

FormItemTreeNode.propTypes = propTypes

export default withStyles(style)(FormItemTreeNode)
