import { guid } from '../../../../utils/guid'
import { FormItemType } from '../../../../form/props/form-item/default.js'
import { ShopFieldNames } from '../../../../model/shop'
import translator from '../../../../translate'

const FormItemShopLogo = {
  id: guid().uid(),
  attribute: ShopFieldNames.SHOP_LOGO,
  label: translator._('shop.logo'),
  type: FormItemType.MEDIA_IMAGE,
  required: true,
  disabled: false,
  hidden: false,
}

export default FormItemShopLogo
