const style = theme => ({
  hide: {
    display: 'none',
  },
  root: {
    position: 'fixed',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 99999999,
    backgroundColor: 'rgba(0,0,0,.5)',
    pointerEvents: 'all',
  },
  progressWrapper: {
    textAlign: 'center',
  },
  progress: {
    width: 80,
    height: 80,
  },
})

export default style
