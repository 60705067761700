import moment from 'moment-timezone'
import isEmpty from '../is-empty'
import { TIME_ZONE } from '../../model/time'

import { get as getCookie } from 'es-cookie'

require('moment/min/locales.min')
/**
 *
 * @param value
 */
const ConverterDateToCommonFormat = function (value) {
  if (getCookie('language') !== undefined) {
    moment.locale(getCookie('language'))
  }

  if (isEmpty(value)) {
    return ''
  } else {
    let date = moment.tz(value, TIME_ZONE.ISTANBUL)

    let converted = date.clone().tz(window.CONSTANTS.TIME_ZONE).format('LLL')

    return converted
  }
}

export default ConverterDateToCommonFormat
