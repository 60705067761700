import translator from 'src/translate'
import { withStyles } from '@material-ui/core/styles'
import React, { useState, useRef } from 'react'
import styles from '../../styles'
import { useDispatch } from 'react-redux'
import { Typography, Switch, TextField } from '@material-ui/core'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import URL from 'src/api/url/api'
const CoverPage = props => {
  const {
    classes,
    coverPage,
    setCoverPage,
    coverPageText,
    handleTextFieldChangeCoverText,
    assignmentDescription,
    handleTextFieldChangeDescription,
  } = props
  const ColoredSwitch = withStyles({
    switchBase: {
      color: '#ffffff',
      '&$checked': {
        color: '#f67b3f',
      },
      '&$checked + $track': {
        backgroundColor: '#f67b3f',
      },
    },
    checked: {},
    track: {},
  })(Switch)
  return (
    <div>
      <div
        style={{
          borderBottom: 'solid 1px #ededed',
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="subtitle2"
          style={{ marginTop: '4px', marginBottom: '4px' }}
        >
          Cover Page
        </Typography>
        <div>
          <ColoredSwitch
            checked={coverPage}
            onClick={() => setCoverPage(!coverPage)}
          />
        </div>
      </div>
      <div
        style={{
          transition: 'all 0.1s ease-in-out',
          //visibility: coverPage ? 'visible' : 'hidden',
          opacity: coverPage ? 1 : 0,
          height: coverPage ? 'auto' : 0,
          marginBottom: '48px',
        }}
      >
        <TextField
          className={classes.input__description}
          style={{ width: '-webkit-fill-available', marginBottom: '16px' }}
          multiline
          maxRows={3}
          minRows={3}
          defaultValue={assignmentDescription}
          placeholder="This description will be shown as the initial page of the quiz."
          onChange={handleTextFieldChangeDescription}
        />
        <img src="/assets/cover-page-layout.png" width="100%" />
      </div>
    </div>
  )
}

export default withStyles(styles)(CoverPage)
