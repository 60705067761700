import React, { useState } from 'react'
import style from './style'
import { withStyles, Button, Typography, Switch } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { AnswerItemType } from 'src/model/question'
import { AssignmentFieldNames } from 'src/model/assignment'

import {
  assignmentUpdateAttribute,
  assignmentEditAction,
} from 'src/reducers/assignment/action'
import { questionAddAction } from 'src/reducers/question/action'
import { getQuestionTypeParam, QuestionSchema } from 'src/model/question'

const PasswordCardAddingField = props => {
  const { setPage, list, courseId, onAddCard, dispatch, classes } = props
  const [checked, setChecked] = useState(false)
  const saveType = async selectedType => {
    let entity = QuestionSchema()
    let list = []
    entity = getQuestionTypeParam(selectedType, entity)
    await dispatch(
      questionAddAction(entity, courseId, response => {
        let newTaskObject = { ...response, isNewAdded: true }
        list.push(newTaskObject)
        onAddCard(list, sortCard)
      }),
    )
  }

  //reOrderToNewAdded(cardCount, index + 1, newEntity)

  const sortCard = (newEntity, hasFinishCard) => {
    reOrderToNewAdded(list.length, 0, newEntity)
    setPage(list.length + 1)
  }

  const reOrderToNewAdded = (oldIndex, newIndex, courseEntity) => {
    const list = courseEntity[AssignmentFieldNames.QUESTION_DTOS]

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    if (newIndex !== null) {
      let items = reorder(list, oldIndex, newIndex)

      // TODO: autosenderId için daha temiz ve garanti bir yol olmalı
      // TODO: backendde de autosenderId check edilmeli
      let letNewRelationList = items.map((x, index) => {
        return {
          courseId: window.location.pathname.split('/')[2],
          cardId: x.id,
          orderOfCard: index,
        }
      })

      items.forEach((value, index) => {
        items[index].orderOfCard = index
      })
      let lastEntity = {
        ...courseEntity,
        [AssignmentFieldNames.QUESTION_DTOS]: items,
        [AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS]: letNewRelationList,
      }

      dispatch(assignmentEditAction(lastEntity, () => {}))

      dispatch(
        assignmentUpdateAttribute([
          {
            path: AssignmentFieldNames.QUESTION_DTOS,
            value: items,
          },
          {
            path: AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS,
            value: letNewRelationList,
          },
        ]),
      )
    }
  }
  const handleChange = event => {
    setChecked(event.target.checked)
    if (event.target.checked) {
      saveType([AnswerItemType.PROTECTIONCARD])
    } else {
      return null
    }
  }
  const handleToggle = () => {
    if (!checked) {
      setChecked(!checked)
      saveType([AnswerItemType.PROTECTIONCARD])
    } else {
      return null
    }
  }

  return (
    <div className={classes.addPasswordCard}>
      <div className={classes.addPasswordCardContent} onClick={handleToggle}>
        <div className={classes.addPasswordCardContentHeader}>
          <Icon
            icon="fluent:password-20-regular"
            color="#f67b3f"
            width={25}
            height={25}
            style={{ marginRight: 5 }}
          />

          <Typography className={classes.headerTexts} fontSize={14}>
            Password Wall
          </Typography>
        </div>
        <div>
          <Switch checked={checked}></Switch>
        </div>
      </div>
    </div>
  )
}

export default withStyles(style)(PasswordCardAddingField)
