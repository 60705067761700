import { put, call, take, fork } from 'redux-saga/effects'
import { questionGetService } from '../../api/question'
import { questionGetSuccess } from '../../reducers/question/action'
import { QUESTION_GET } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionGetFlow(payload) {
  try {
    const { id } = payload
    const response = yield call(questionGetService, id)

    yield put(questionGetSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionGetWatcher() {
  while (true) {
    const action = yield take(QUESTION_GET)
    const { payload } = action

    yield fork(generalSaga, questionGetFlow, payload, false)
  }
}
