const styles = theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  successGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100 % +'16px',
    width: 100 % +'16px',
    marginLeft: '-16px',
  },
  image: {
    width: '100%',
    height: undefined,
  },
})

export default styles
