import React from 'react'
import style from './style'
import propTypes from './prop'
import translator from '../../../../translate'
import { withStyles, Grid, Button } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const Footer = props => {
  const { classes, onCancel, onOk, okButtonText, okButtonColor } = props

  return (
    <Grid className={classes.root} justifyContent="flex-end" container>
      <Grid>
        <Button
          onClick={onCancel}
          className={classes.button}
          color="inherit"
          variant="outlined"
        >
          {translator._('action.cancel')}
        </Button>
        <Button
          onClick={onOk}
          className={classes.button}
          color={okButtonColor ? okButtonColor : 'primary'}
          variant="contained"
        >
          {!!okButtonText ? okButtonText : translator._('action.ok')}
        </Button>
      </Grid>
    </Grid>
  )
}

Footer.propTypes = propTypes

export default withStyles(style)(Footer)
