import { AnswerItemType } from 'src/model/question'
import translator from 'src/translate'
import UserType, { UserTypeNames } from 'src/model/user-types'

const options = props => {
  const userRole = UserType[window.CONSTANTS.USER_ROLE_ID]

  const hasScoreCardAndLastCard =
    props.hasScoreCardAndLastCard && props.hasScoreCardAndLastCard

  return [
    {
      name: 'assessing-knowledge',
      icon: '/assets/1-single.png',
      title: 'Single  Answer',
      premiumControl: true,
      locked: false,
      isOnList: true,
      contents: [
        {
          name: 'single-select',
          icon: '/assets/icons/task/single-select.png',
          title: translator._('tasks.singleSelect.title'),
          description: translator._('tasks.singleSelect.description'),
          description2: translator._('tasks.singleSelect.description2'),
          exampleImage: '/assets/icons/task/single-select-example.png',
          dataType: AnswerItemType.TESTINGSINGLECHOICE,
        },
      ],
    },
    {
      name: 'assessing-knowledge',
      icon: '/assets/2-multiple.png',
      title: 'Multiple Answer',
      premiumControl: true,
      locked: false,
      isOnList: true,
      contents: [
        {
          name: 'single-select',
          icon: '/assets/icons/task/single-select.png',
          title: translator._('tasks.singleSelect.title'),
          description: translator._('tasks.singleSelect.description'),
          description2: translator._('tasks.singleSelect.description2'),
          exampleImage: '/assets/icons/task/single-select-example.png',
          dataType: AnswerItemType.TESTINGMULTIPLECHOICE,
        },
      ],
    },
    {
      name: 'assessing-knowledge',
      icon: '/assets/3-truefalse.png',
      title: 'True False',
      locked: false,
      premiumControl: true,
      isOnList: true,
      contents: [
        {
          name: 'single-select',
          icon: '/assets/icons/task/single-select.png',
          title: translator._('tasks.singleSelect.title'),
          description: translator._('tasks.singleSelect.description'),
          description2: translator._('tasks.singleSelect.description2'),
          exampleImage: '/assets/icons/task/single-select-example.png',
          dataType: AnswerItemType.TRUEFALSE,
        },
      ],
    },
    {
      name: 'assessing-knowledge',
      icon: '/assets/4-freefield.png',
      title: 'Free Text',
      locked: false,
      premiumControl: true,
      isOnList: true,
      contents: [
        {
          name: 'single-select',
          icon: '/assets/icons/task/single-select.png',
          title: translator._('tasks.singleSelect.title'),
          description: translator._('tasks.singleSelect.description'),
          description2: translator._('tasks.singleSelect.description2'),
          exampleImage: '/assets/icons/task/single-select-example.png',
          dataType: AnswerItemType.SURVEYFREEFORM,
        },
      ],
    },
    {
      name: 'assessing-knowledge',
      icon: '/assets/2-score-icon.svg',
      title: 'Score',
      locked: !hasScoreCardAndLastCard,
      premiumControl: userRole !== UserTypeNames.STARTER,
      isOnList: false,
      contents: [
        {
          name: 'single-select',
          icon: '/assets/icons/task/single-select.png',
          title: translator._('tasks.singleSelect.title'),
          description: translator._('tasks.singleSelect.description'),
          description2: translator._('tasks.singleSelect.description2'),
          exampleImage: '/assets/icons/task/single-select-example.png',
          dataType: AnswerItemType.SCORECARD,
        },
      ],
    },
    {
      name: 'assessing-knowledge',
      icon: '/assets/3-conditional-icon.svg',
      title: 'Conditional',
      locked: !hasScoreCardAndLastCard,
      premiumControl: userRole !== UserTypeNames.STARTER,
      isOnList: false,
      contents: [
        {
          name: 'single-select',
          icon: '/assets/icons/task/single-select.png',
          title: translator._('tasks.singleSelect.title'),
          description: translator._('tasks.singleSelect.description'),
          description2: translator._('tasks.singleSelect.description2'),
          exampleImage: '/assets/icons/task/single-select-example.png',
          dataType: AnswerItemType.RESULT,
        },
      ],
    },
    {
      name: 'assessing-knowledge',
      icon: '/assets/1-thank.svg',
      title: 'Thank You',
      locked: !hasScoreCardAndLastCard,
      premiumControl: userRole !== UserTypeNames.STARTER,
      isOnList: false,
      contents: [
        {
          name: 'single-select',
          icon: '/assets/icons/task/single-select.png',
          title: translator._('tasks.singleSelect.title'),
          description: translator._('tasks.singleSelect.description'),
          description2: translator._('tasks.singleSelect.description2'),
          exampleImage: '/assets/icons/task/single-select-example.png',
          dataType: AnswerItemType.RESULTDEFAULT,
        },
      ],
    },
    {
      name: 'viewing-content',
      icon: '/assets/input-card.png',
      title: 'Input Card',
      premiumControl: true,
      locked: false,
      isOnList: true,
      contents: [
        {
          name: 'bite-sized-information',
          icon: '/assets/input-card.png',
          title: translator._('tasks.biteSized.title'),
          exampleImage: '/assets/icons/task/bite-size-example.png',
          dataType: AnswerItemType.SURVEYINPUT,
        },
      ],
    },
    {
      name: 'viewing-content',
      icon: '/assets/6-info.png',
      title: 'Info Card',
      premiumControl: true,
      locked: false,
      isOnList: true,
      contents: [
        {
          name: 'bite-sized-information',
          icon: '/assets/icons/task/new-ai-icon-card.png',
          title: translator._('tasks.biteSized.title'),
          exampleImage: '/assets/icons/task/bite-size-example.png',
          dataType: AnswerItemType.INFORM,
        },
      ],
    },
    {
      name: 'viewing-content',
      icon: '/assets/6-info.png',
      title: 'Password Wall',
      premiumControl: true,
      locked: false,
      isOnList: false,
      contents: [
        {
          name: 'bite-sized-information',
          icon: '/assets/icons/task/new-ai-icon-card.png',
          title: translator._('tasks.biteSized.title'),
          exampleImage: '/assets/icons/task/bite-size-example.png',
          dataType: AnswerItemType.PROTECTIONCARD,
        },
      ],
    },
    {
      name: 'viewing-content',
      icon: '/assets/sertifier.svg',
      title: 'Sertifier',
      premiumControl: true,
      locked: false,
      isOnList: false,
      contents: [
        {
          name: 'sertifier',
          icon: '/assets/sertifier.svg',
          title: translator._('tasks.sertifier'),
          description: translator._('tasks.sertifier.description'),
          description2: translator._('tasks.sertifier.description2'),
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.SERTIFIERCARD,
        },
      ],
    },
  ]
}

export default options
