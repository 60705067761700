import React from 'react'
import FormItemDate from './date'
import FormItemGroupCheckbox from './group-checkbox'
import FormItemGroupRadio from './group-radio'
import FormItemInput from './input'
import FormItemColor from './color-picker'
import FormItemList from './list'
import FormItemSelect from './select'
import FormItemSwitch from './switch'
import FormItemReversedSwitch from './reversed-switch'
import FormItemTextArea from './textarea'
import FormItemTree from './tree'
import FormItemFileUpload from './file-upload'
import FormItemPoolSelection from './pool-selection'
import FormItemMediaImage from './media-image'
import FormItemMenu from './menu'
import { FormItemType } from '../../../form/props/form-item/default'

const FormFactory = props => {
  const { type, id, stateMode } = props

  props = { ...props, key: id }

  switch (type) {
    case FormItemType.INPUT:
      return <FormItemInput {...props} />
    case FormItemType.TEXTAREA:
      return <FormItemTextArea {...props} />
    case FormItemType.SELECT:
      return <FormItemSelect {...props} />
    case FormItemType.SWITCH:
      return <FormItemSwitch {...props} />
    case FormItemType.REVERSED_SWITCH:
      return <FormItemReversedSwitch {...props} />
    case FormItemType.DATE:
      return <FormItemDate {...props} />
    case FormItemType.TREE:
      return <FormItemTree {...props} />
    case FormItemType.LIST:
      return <FormItemList {...props} />
    case FormItemType.COLOR_PICKER:
      return <FormItemColor {...props} />
    case FormItemType.FILE_UPLOAD:
      return <FormItemFileUpload {...props} />
    case FormItemType.POOL_SELECTION:
      return <FormItemPoolSelection {...props} />
    case FormItemType.GROUP_CHECKBOX:
      return <FormItemGroupCheckbox {...props} />
    case FormItemType.GROUP_RADIO:
      return <FormItemGroupRadio {...props} />
    case FormItemType.MEDIA_IMAGE:
      return <FormItemMediaImage {...props} />
    case FormItemType.MENU:
      return <FormItemMenu {...props} />
    default:
      return ''
  }
}

export default FormFactory
