import PropTypes from 'prop-types'
import {
  FormUpdateMethodTypes,
  FormStateModeTypes,
} from '../../../../form/props'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  updateMethod: PropTypes.oneOf(FormUpdateMethodTypes).isRequired,
  stateMode: PropTypes.oneOf(FormStateModeTypes).isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  editTitle: PropTypes.string,
  addTitle: PropTypes.string,
  formContent: PropTypes.object,
}

export default propTypes
