import PropTypes from 'prop-types'
import { FormItems } from '../form-item'
import { FormItemPropTypes } from '../form-item/default'

const FormItemListPropTypes = {
  ...FormItemPropTypes,
  itemDataCreator: PropTypes.func.isRequired,
  addItemButtonLabel: PropTypes.string.isRequired,
  //formItem: PropTypes.shape(PropTypes.oneOf(FormItems)).isRequired, // TODO: oneOf expects array, this is causing a warning somehow
}

export default FormItemListPropTypes
