import React, { Component } from 'react'
import { useSelector } from 'react-redux'

import style from './style'
import propTypes from './prop'
import { connect } from 'react-redux'
import MediaHeader from '../../components/media/header'
import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  Tab,
} from '@material-ui/core'
import ImageList from '@material-ui/core/ImageList'
import {
  mediaDirectoryGetRoot,
  mediaDirectoryGet,
} from '../../reducers/media-directory/action'
import withStyles from '@material-ui/core/styles/withStyles'
import MediaItemFactory from '../../components/media/item/factory'
import MessageBoxContent, { MessageBoxType } from '../../model/message-box'
import translator from '../../translate'
import { mediaImageUploadAction } from '../../reducers/media-image/action'
import { ButtonData, ButtonType, ButtonActionType } from '../../model/button'
import { componentBoxHideAction } from '../../reducers/component-box/action'
import {
  MediaDirectoryFieldNames,
  MediaItemType,
} from '../../model/media-directory'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../reducers/message-box/action'
import { mediaVideoUploadAction } from '../../reducers/media-video/action'
import { mediaFolderAddAction } from '../../reducers/media-folder/action'

import URL from 'src/api/url'

import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import Giphy from './giphy'
import Unsplash from './unsplash'
import EmbedImage from './embed-image'
import AiImage from './ai-image-generation'

class ContainerMediaGalery extends Component {
  state = {
    items: [],
    showUploadPanel: false,
    folderId: null,
    parentFolderId: [null],
    tabValue: 6,
    loading: false,
    refreshedAt: null,
  }

  /**
   *
   */
  getMediaItemType = fileType => {
    if (
      fileType === 'image/jpeg' ||
      fileType === 'image/jpg' ||
      fileType === 'image/png' ||
      fileType === 'image/png' ||
      fileType === 'image/gif'
    ) {
      return MediaItemType.IMAGE
    }

    return null
  }

  handleTabChange = (event, newValue) => {
    if (newValue == 1) {
      this.refreshList()
    }
    this.setState({ tabValue: newValue })
  }

  /**
   *
   */
  refreshList = () => {
    const { dispatch, filterType } = this.props
    const { folderId } = this.state
    if (folderId == null) {
      dispatch(mediaDirectoryGetRoot(filterType))
    } else {
      dispatch(mediaDirectoryGet({ folderId, filterType }))
    }

    //this.setState({ showUploadPanel: false })
    //this.setState({ tabValue: 6 })
  }

  /**
   *
   */
  handleClose = () => {}

  backParentFolder = () => {
    const { parentFolderId } = this.state

    let newParentFolderList = parentFolderId.slice(0, -1)
    this.setState(
      {
        folderId: parentFolderId[parentFolderId.length - 2],
        parentFolderId: newParentFolderList,
      },
      () => {
        this.refreshList()
      },
    )
  }
  /**
   *
   */
  handleSelect = item => {
    const { onOk, onRemove } = this.props
    const { parentFolderId, folderId } = this.state

    if (
      item.currentTarget != undefined &&
      item.currentTarget.dataset.type == MediaItemType.FOLDER
    ) {
      parentFolderId.push(item.currentTarget.dataset.id)

      this.setState(
        {
          folderId: item.currentTarget.dataset.id,
          parentFolderId: parentFolderId,
        },
        () => {
          this.refreshList()
        },
      )
    }

    const { items } = this.state
    const index = items.findIndex(i => {
      return i.id === item.id
    })

    if (index === -1) {
      this.setState({ items: [item] }, () => onOk([item], false))
    } else {
      this.setState({
        items: items.filter((i, iIndex) => {
          return index !== iIndex
        }),
      })
      //Soruya eklenen gorseli kaldırmak için
      const assignment = this.props.assignment

      if (onRemove) {
        onRemove(assignment)
      }
    }
  }

  onHandleYoutubeAdd = event => {
    const { onOk } = this.props

    event.preventDefault()
    const youtubeUrl = event.target.youtube.value
    let item = {
      id: Date.now(),
      url: youtubeUrl,
      link: youtubeUrl,
      type: 2,
    }
    this.setState({ items: [item] }, () => onOk([item], true))
  }

  onHandleGifAdd = gif => {
    const { onOk, setImageURL } = this.props
    setImageURL(gif['images']['original'].url)
    let item = {
      id: Date.now(),
      url: gif['images']['original'].url,
      link: gif['images']['original'].url,
      type: 1,
    }
    this.setState({ items: [item] }, () => onOk([item], true))
  }
  onPhotoClickAdd = url => {
    const { onOk, setImageURL } = this.props

    if (
      url &&
      (url.includes('production/images') || url.includes('test/images'))
    ) {
      setImageURL(`${URL.STORAGE}` + url)
    } else if (url && (url.includes('http://') || url.includes('https://'))) {
      setImageURL(url)
    }
    let item = {
      id: Date.now(),
      url: url,
      link: url,
      type: 1,
    }

    this.setState({ items: [item] }, () => onOk([item], true))
  }
  onPhotoClickEmbed = url => {
    const { onOk, setImageURL } = this.props
    setImageURL(url)
    let item = {
      id: Date.now(),
      url: url,
      link: url,
      type: 1,
    }

    this.setState({ items: [item] }, () => onOk([item], true))
  }

  /**
   *
   */
  handleOnOk = close => {
    const { dispatch, onOk } = this.props
    const { items } = this.state

    if (items.length === 0) {
      dispatch(
        messageBoxShowAction(
          new MessageBoxContent(
            MessageBoxType.WARNING,
            translator._('message.didNotChoose'),
            [
              new ButtonData(
                ButtonType.NORMAL,
                translator._('action.ok'),
                ButtonActionType.NO,
                () => {
                  dispatch(messageBoxHideAction())
                },
              ),
            ],
          ),
        ),
      )

      return
    }
    dispatch(componentBoxHideAction())
    //onOk(items, close)
  }

  /**
   *
   */
  handleAddItem = e => {
    const { dispatch, entity } = this.props
    const { type } = e.currentTarget.dataset

    if (type != 0) {
      //this.setState({ showUploadPanel: true })
      this.setState({ tabValue: 0 })
    } else {
      dispatch(
        mediaFolderAddAction(
          {
            name: translator._('mediaGallery.newFolder'),
            parentFolderId: entity.directoryFolderId,
          },
          this.refreshList,
        ),
      )
    }
  }

  /**
   *
   */
  onDrop = e => {
    if (this.state.tabValue !== 6) {
      const { dispatch } = this.props
      const { folderId } = this.state
      const file = e.dataTransfer.files[0]
      const mediaType = this.getMediaItemType(file.type)

      if (!mediaType) {
        return
      }

      if (mediaType === MediaItemType.IMAGE) {
        dispatch(mediaImageUploadAction(file, folderId, this.refreshList))
      } else if (mediaType === MediaItemType.VIDEO) {
        dispatch(mediaVideoUploadAction(file, folderId, this.refreshList))
      }
      this.setState({ tabValue: 1 })
      e.preventDefault()
      e.stopPropagation()
    }
  }

  /**
   *
   */
  onDragEnter = e => {
    if (this.state.tabValue !== 6) {
      const fileType = e.dataTransfer.items[0].type
      const mediaType = this.getMediaItemType(fileType)

      if (!mediaType) {
        e.preventDefault()

        e.dataTransfer.effectAllowed = 'none'
        e.dataTransfer.dropEffect = 'none'

        //this.setState({ showUploadPanel: false })
        this.setState({ tabValue: 0 })
        return false
      }

      //this.setState({ showUploadPanel: true })
      this.setState({ tabValue: 1 })
      e.stopPropagation()
    }
  }

  /**
   *
   */
  onDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  /**
   *
   */
  onDragLeave = e => {
    if (this.state.tabValue !== 6) {
      //this.setState({ showUploadPanel: false })
      this.setState({ tabValue: 1 })
      e.stopPropagation()
    }
  }

  /**
   *
   */
  renderItem = mediaItem => {
    const { items } = this.state

    return MediaItemFactory(
      mediaItem,
      this.handleSelect,
      items.findIndex(i => {
        return i.id === mediaItem.id
      }) !== -1,
    )
  }

  /**
   *
   */
  closeHandler = () => {
    const { dispatch } = this.props

    dispatch(componentBoxHideAction())
  }

  choosePhoto = (e, type) => {
    const { dispatch } = this.props
    const { folderId } = this.state
    if (type == 'Normal Upload') {
      const file = e.target.files[0]
      const mediaType = this.getMediaItemType(file.type)

      if (!mediaType) {
        return
      }

      if (mediaType === MediaItemType.IMAGE) {
        dispatch(mediaImageUploadAction(file, folderId, this.refreshList))
      } else if (mediaType === MediaItemType.VIDEO) {
        dispatch(mediaVideoUploadAction(file, folderId, this.refreshList))
      }
      this.setState({ tabValue: 1 })
      e.preventDefault()
      e.stopPropagation()
    } else {
      const aiFile = e
      dispatch(mediaImageUploadAction(aiFile, folderId, this.refreshList))
    }
  }

  /**
   *
   */
  render() {
    const { classes, entity, cardEntity, popupType, dispatch } = this.props

    const { tabValue } = this.state

    return (
      <Grid
        container
        className={classes.root}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
      >
        <MediaHeader closeHandler={this.closeHandler} popupType={popupType} />
        <TabContext value={tabValue}>
          <React.Fragment>
            {popupType != 'create prompt' ? (
              <Grid
                container
                className={classes.headerContent}
                alignContent="center"
              >
                <TabList
                  onChange={this.handleTabChange}
                  TabIndicatorProps={{
                    style: { background: '#F67B3F' },
                  }}
                  textColor="#00AB55"
                >
                  <Tab disableRipple label="Create with AI" value={6} />

                  <Tab disableRipple label="My Media" value={1} />
                  <Tab
                    disableRipple
                    label={translator._('media.upload')}
                    value={0}
                  />
                  <Tab disableRipple label="Add by Link" value={5} />
                  <Tab disableRipple label="Youtube" value={2} />
                  <Tab disableRipple label="Giphy" value={3} />
                  <Tab disableRipple label="Unsplash" value={4} />
                </TabList>
              </Grid>
            ) : (
              <Box m={2}></Box>
            )}
            {popupType != 'create prompt' && (
              <>
                <TabPanel
                  value={tabValue}
                  index={0}
                  hidden={tabValue !== 0}
                  className={classes.tabPanel}
                >
                  <Grid
                    container
                    className={classes.dropArea}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography align="center">
                        {translator._('media.uploadInfo')}
                      </Typography>

                      <Typography align="center">or</Typography>
                      <TextField
                        type="file"
                        fullWidth={true}
                        onChange={event =>
                          this.choosePhoto(event, 'Normal Upload')
                        }
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1} hidden={tabValue !== 1}>
                  <Grid container className={classes.content}>
                    <ImageList
                      cellHeight={180}
                      className={classes.imageList}
                      cols={5}
                    >
                      {entity &&
                        entity[MediaDirectoryFieldNames.VIDEO_DTOS].map(
                          mediaItem => {
                            mediaItem.type = MediaItemType.VIDEO
                            mediaItem.dispatch = dispatch

                            return this.renderItem(mediaItem)
                          },
                        )}

                      {entity &&
                        entity[MediaDirectoryFieldNames.IMAGE_DTOS].map(
                          mediaItem => {
                            mediaItem.type = MediaItemType.IMAGE
                            mediaItem.dispatch = dispatch

                            return this.renderItem(mediaItem)
                          },
                        )}
                    </ImageList>
                  </Grid>

                  {/*    <MediaFooter
                onOk={() => this.handleOnOk(true)}
                //addItem={this.handleAddItem}
              /> */}
                </TabPanel>

                <TabPanel
                  value={tabValue}
                  index={2}
                  hidden={tabValue !== 2}
                  className={classes.tabPanel}
                >
                  <Grid className={classes.linkPanel}>
                    <form
                      className={classes.form}
                      onSubmit={this.onHandleYoutubeAdd}
                    >
                      <TextField
                        fullWidth
                        type="text"
                        label="Paste Youtube link"
                        id="youtube"
                        name="youtube"
                      />

                      <Button
                        style={{ marginTop: 16 }}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Add YouTube Video
                      </Button>
                    </form>
                    <div className={classes.infoText}></div>
                    {/*<Typography>
                      Want to embed video from other platforms like Vimeo?
                      <a
                        href="https://minicoursegenerator.com/knowledge-base/embedding-media-from-youtube-vimeo-spotify-etc/"
                        target="_blank"
                      >
                        {' '}
                        Learn more.
                      </a>
            </Typography>*/}
                  </Grid>
                </TabPanel>

                <TabPanel
                  value={tabValue}
                  index={3}
                  hidden={tabValue !== 3}
                  className={classes.tabPanel}
                >
                  <Giphy
                    onGifClick={gif => {
                      this.onHandleGifAdd(gif)
                    }}
                    classes={classes}
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={4}
                  hidden={tabValue !== 4}
                  className={classes.tabPanel}
                >
                  <Unsplash
                    onPhotoClick={url => {
                      this.onPhotoClickAdd(url)
                    }}
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={5}
                  hidden={tabValue !== 5}
                  className={classes.tabPanel}
                >
                  <EmbedImage
                    onPhotoClick={url => {
                      this.onPhotoClickEmbed(url)
                    }}
                    classes={classes}
                  />
                </TabPanel>
              </>
            )}

            <TabPanel value={tabValue} index={6} hidden={tabValue !== 6}>
              <AiImage
                cardEntity={cardEntity}
                assignment={this.props.assignment}
                onPhotoClick={url => {
                  this.onPhotoClickAdd(url)
                }}
                dispatch={dispatch}
                loading={this.state.loading}
                choosePhoto={this.choosePhoto}
                refreshedAt={this.state.refreshedAt}
                popupType={this.props.popupType}
              />
            </TabPanel>
          </React.Fragment>
        </TabContext>
      </Grid>
    )
  }

  /**
   *
   */
  componentDidMount() {
    //this.refreshList()
    //this.limits()
  }
}

ContainerMediaGalery.propTypes = propTypes

const mapStateToProps = response => ({
  entity: response.mediaDirectory.entity,
  assignment: response.assignment.entity,
})

export default connect(mapStateToProps)(
  withStyles(style, { withTheme: true })(ContainerMediaGalery),
)
