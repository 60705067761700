import PropTypes from 'prop-types'

export class TagColorType {
  static SUCCESS = 'success'
  static ERROR = 'error'
  static INFO = 'info'
  static PREMIUM = 'warning'
}

export const TagPropTypes = {
  label: PropTypes.func.isRequired,
  color: PropTypes.func.isRequired,
}
