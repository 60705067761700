/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import translator from 'src/translate'
import { assignmentAddAction } from 'src/reducers/assignment/action'
import {
  AssignmentFieldNames,
  AssignmentSchema,
  AssignmentTypes,
} from 'src/model/assignment'
import { collectionCoursePatchAction } from 'src/reducers/course-collection/action'
import { PatchContent, PatchData } from 'src/model/patch'
import {
  Button,
  Divider,
  Modal,
  Typography,
  withStyles,
} from '@material-ui/core'
import { deleteCourseCollectionAction } from 'src/reducers/course-collection/action'
import styles from '../../styles'
import ErrorIcon from '@material-ui/icons/Error'

const DeleteCollectionModal = props => {
  const {
    collectionId,
    dispatch,
    isModalOpen,
    handleCloseModal,
    classes,
  } = props

  const deleteCollection = () => {
    dispatch(
      deleteCourseCollectionAction(collectionId, () => {
        handleCloseModal()
      }),
    )
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleCloseModal()}
      className={classes.deleteCollectionModal}
    >
      <div className={classes.deleteCollectionModalContent}>
        <div className={classes.header}>
          <div className={classes.errorIconBackground}>
            <ErrorIcon className={classes.errorIcon} />
          </div>
          <Typography
            variant="h4"
            className={classes.deleteCollectionModalTitle}
          >
            Delete Collection
          </Typography>
        </div>

        <Typography className={classes.errorMessage}>
          Are you sure you want to delete this collection?
        </Typography>
        <Typography
          variant="body1"
          color="red"
          className={classes.errorMessageNote}
        >
          NOTE: Quizzes in the collection will be deleted!
        </Typography>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            className={classes.deleteButton}
            onClick={() => deleteCollection()}
          >
            <Typography style={{ fontWeight: 500 }}>Delete</Typography>
          </Button>
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={() => handleCloseModal()}
          >
            <Typography style={{ color: '#000', fontWeight: 450 }}>
              Cancel
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default withStyles(styles)(DeleteCollectionModal)
