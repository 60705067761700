import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch } from 'react-redux'
import Page from '../../components/Page'
import style from './style'

// material
import { styled } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Container,
  Typography,
  Divider,
  withStyles,
  TextField,
  Button,
  Paper,
  Stack,
  Switch,
} from '@material-ui/core'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
// components
import { PricingPlanCard } from '../../components/pricing'
import SuccessCode from './success'
import Subscription from './subscription'
//
import {
  PlanFreeIcon,
  PlanPremiumIcon,
  PlanPremiumFridayIcon,
  PlanPremiumEnterpriseIcon,
} from '../../assets'
import { meService } from '../../api/authentication'
import { getUserLimits } from '../../api/limit'
import UserType from '../../model/user-types'
import { createStripeSessionService } from '../../api/payment'
import {
  componentBoxShowAction,
  componentBoxHideAction,
} from '../../reducers/component-box/action'
import ComponentBoxContent from '../../model/component-box'
import { codeVerifierAction } from '../../reducers/common/action'
import translator from 'src/translate'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Icon } from '@iconify/react'
import check from '@iconify-icons/eva/checkmark-circle-2-fill'
import { customerUpgradePortal } from '../../api/limit'
import { track } from '@amplitude/analytics-browser'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10),
}))

export const handleClick = async card => {
  const {
    priceId,
    priceTrialDays,
    successReturnUrl,
    labelAction,
    subscription,
    starterPackage,
    premiumPackage,
  } = card
  track('upgrade_clicked', {
    subscription: subscription,
  })

  if (
    subscription == '  Premium Plus ' &&
    labelAction == 'Upgrade' &&
    (starterPackage || premiumPackage)
  ) {
    customerUpgradePortal().then(response => {
      let sessionUrl = response.data.sessionUrl
      window.open(`${sessionUrl}`)
    })
  } else {
    const resMe = await meService()

    const resStripeSession = await createStripeSessionService(
      priceId,
      priceTrialDays,
      successReturnUrl,
    )

    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      sessionId: resStripeSession.data.sessionId,
    })

    /* if (error) {
      setLoading(false)
      setStripeError(error)
    } */
  }
}

export const PricingPlans = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const [timeInterval, setTimeInterval] = useState(false)
  const [starterPackage, setStarterPackage] = useState('')
  const [premiumPackage, setPremiumPackage] = useState('')
  const [premiumPlusPackage, setPremiumPlusPackage] = useState('')
  const handleChange = () => {
    setTimeInterval(!timeInterval)
  }
  const [type, setType] = useState('')

  {
    /*useEffect(() => {
    dispatch(
      componentBoxHideAction(),
      getUserLimits().then(response => {
        let userType = response.data[0].limit.roleType
        setType(UserType[userType])

        setStarterPackage(
          UserType[userType] == 'Starter' || UserType[userType] == 'Starter - A'
            ? true
            : false,
        )
        setPremiumPackage(
          UserType[userType] == 'Premium - A' ||
            UserType[userType] == 'Premium' ||
            UserType[userType] == 'Premium v2'
            ? true
            : false,
        )

        setPremiumPlusPackage(
          UserType[userType] == 'Premium Plus' ||
            UserType[userType] == 'Premium Plus v2'
            ? true
            : false,
        )
      }),
    )
  }, [])*/
  }

  const PLANS = [
    {
      subscription: 'Basic',
      icon: <PlanFreeIcon />,
      price: 0,
      caption: ' Free Forever ',
      lists: [
        { text: '100 responses / month', isAvailable: true },
        { text: '3 AI-powered quizzes', isAvailable: true },
        {
          text: '10 AI-questions per quiz',
          isAvailable: true,
        },
        { text: '2.000 character input', isAvailable: true },
      ],
      labelAction: type == 'Free Account' ? 'current plan' : 'free plan',
    },

    {
      subscription: '  Premium  ',
      icon: <PlanPremiumIcon />,
      price: timeInterval ? 180 : 29,
      priceId: timeInterval
        ? 'price_1NrycMGQZpi1xPumMhWFnoqZ'
        : 'price_1NryReGQZpi1xPuma9NS7UmN',
      priceTrialDays: null,
      starterPackage: starterPackage,
      premiumPackage: premiumPackage,
      successReturnUrl: timeInterval
        ? 'annual-premium-subscription-successful'
        : 'premium-subscription-successful',
      caption: 'Save 50% when billed annually',
      lists: [
        { text: '1.000 responses / month', isAvailable: true },
        {
          text: '30 AI-powered quizzes',
          isAvailable: true,
        },
        {
          text: '20 AI-questions per quiz',
          isAvailable: true,
        },
        { text: '10.000 character input', isAvailable: true },
        { text: 'Gateways & Completions', isAvailable: true },
        { text: 'Removal of Quiz Cube badge', isAvailable: true },
        { text: 'Custom domains', isAvailable: true },
      ],
      labelAction: starterPackage
        ? 'Upgrade'
        : premiumPackage
        ? 'Current Plan'
        : 'Upgrade',
    },
    {
      subscription: '  Premium Plus ',
      icon: <PlanPremiumFridayIcon />,
      price: timeInterval ? 300 : 49,
      priceId: timeInterval
        ? 'price_1NryewGQZpi1xPum7yTwemTE'
        : 'price_1Nrye2GQZpi1xPum6HT5DHdd',
      priceTrialDays: null,
      starterPackage: starterPackage,
      premiumPackage: premiumPackage,
      successReturnUrl: timeInterval
        ? 'annual-premium-plus-subscription-successful'
        : 'premium-plus-subscription-successful',
      caption: 'Save 50% when billed annually',
      lists: [
        { text: 'Unlimited responses', isAvailable: true },
        {
          text: 'Unlimited responses',
          isAvailable: true,
        },
        {
          text: '30 AI-questions per quiz',
          isAvailable: true,
        },
        { text: '30.000 character input', isAvailable: true },

        { text: 'Gateways & Completions', isAvailable: true },

        { text: 'Removal of Quiz Cube badge', isAvailable: true },
        { text: 'Custom domains', isAvailable: true },
      ],
      labelAction: premiumPackage
        ? 'Upgrade'
        : premiumPlusPackage
        ? 'Current Plan'
        : 'Upgrade',
    },
  ]
  return (
    <>
      {/*  <Box>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Typography variant="overline" sx={{ mr: 1.5 }}>
            MONTHLY
          </Typography>
          <Switch checked={timeInterval} onChange={handleChange} />
          <Typography variant="overline" sx={{ ml: 1.5 }}>
            YEARLY
          </Typography>
          <Box m={5}></Box>
        </Stack>
      </Box> */}
      <Grid
        container
        display="flex"
        //flexDirection="column"
        justifyContent="center"
      >
        {PLANS.map((card, index) => (
          <Grid md={4} key={card.subscription}>
            <PricingPlanCard
              userType={type}
              card={card}
              index={index}
              timeInterval={timeInterval}
              onClickHandler={() => {
                handleClick(card)
              }}
              handleChange={handleChange}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

// ----------------------------------------------------------------------

function Pricing(props) {
  const dispatch = useDispatch()
  const { classes, history } = props

  //const [stripeError, setStripeError] = useState()
  //const [loading, setLoading] = useState()

  const [code, setCode] = useState('')

  const routeChange = () => {
    let path = `/`
    history.push(path)
  }
  const onHandleAppsumoCode = event => {
    if (code != '') {
      dispatch(
        codeVerifierAction(code, () => {
          successCode()
        }),
      )
    }
  }

  const onHandleSubscription = event => {
    if (code != '') {
      subscription()
    }
  }

  const successCode = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <SuccessCode history={history} dispatch={dispatch} />,
          { hideCloseButton: true },
        ),
      ),
    )
  }
  const subscription = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <Subscription history={history} dispatch={dispatch} />,
          { hideCloseButton: false, header: 'Warning!' },
        ),
      ),
    )
  }
  return (
    <RootStyle title="Pricing">
      <Container maxWidth="fit-content">
        <Typography variant="h3" align="center" paragraph>
          Break the limits with paid plans.
        </Typography>
        <Typography align="center" sx={{ color: 'text.secondary' }}>
          Start your subscription and get the most out of Quiz Cube. <br />
          If you change your mind in 14 days after the payment, we will refund
          100% of your money. No questions asked.
        </Typography>
        <Box m={5}></Box>

        <PricingPlans handleClick={handleClick} />

        <Box m={5}></Box>
      </Container>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="caption table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.tableHeader}>
                {translator._('pricing.compare')}
              </TableCell>
              <TableCell align="center">
                {translator._('pricing.free')}
              </TableCell>
              <TableCell align="center" width={150}>
                {translator._('pricing.premium')}
              </TableCell>
              <TableCell align="center" width={150}>
                {translator._('pricing.premiumPlus')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Multiple Question Types
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Creating Quiz Based on Uploaded File
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Creating Quiz Based on URL
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Uploading images, gifs, pdf's
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                HTML editor (to embed videos, podcasts, etc.)
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Theme Customization
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                In-card AI-Assistant for images
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Completion cards with calculations and scoring
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Adding Sign-up wall
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Adding Password wall
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Sharing with a link
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Embedding in a webpage
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Removal of QuizCube Branding
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Export with AIKEN and Excel Formats
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Import with AIKEN & Excel Formats
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Automations with webhooks
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Adding .js codes to head & body
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Creating Quiz Based on Multiple Files
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box m={5}></Box>

      <Divider></Divider>

      <Box m={5}></Box>
      {/*<Grid className={classes.innerGrid}>
        <LocalOfferIcon color="primary" className={classes.icon}>
          {' '}
        </LocalOfferIcon>
        <Box m={1}></Box>
        <Typography variant="h4">
          {translator._('pricing.useCoupon')}
        </Typography>
      </Grid>
      <Box m={3}></Box>

      <Grid container className={classes.innerGrid}>
        <Grid item xs={6} md={2}>
          <TextField
            size="small"
            fullWidth
            label={'Code'}
            id="code"
            name="code"
            onChange={event => {
              setCode(event.target.value)
            }}
          />
        </Grid>
        <Box m={2}></Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            switch (UserType[userType]) {
              case UserType[1]:
                onHandleAppsumoCode()
                break
              case UserType[4]:
                onHandleAppsumoCode()
                break
              case UserType[5]:
                onHandleAppsumoCode()
                break
              case UserType[7]:
                onHandleAppsumoCode()
                break
              case UserType[8]:
                onHandleAppsumoCode()
                break
              case UserType[9]:
                onHandleAppsumoCode()
                break
              case UserType[10]:
                onHandleAppsumoCode()
                break
              default:
                onHandleSubscription()
                break
            }
          }}
        >
          {translator._('pricing.redeem')}
        </Button>
      </Grid>*/}
      <Box m={3}></Box>
      <Grid item align="center">
        <Typography>
          {translator._('pricing.contact')}:
          <a href="mailto:support@quizcube.io">support@quizcube.io</a>
        </Typography>
      </Grid>
    </RootStyle>
  )
}
export default withStyles(style)(Pricing)
