import { put, call, take, fork } from 'redux-saga/effects'
import { createCourseCollectionService } from '../../api/course-collection'
import { createCourseCollectionSuccess } from '../../reducers/course-collection/action.js'
import { CREATE_COURSE_COLLECTION } from '../../reducers/course-collection/type.js'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* createCourseCollectionSagaFlow(payload) {
  try {
    const { collectionName, callback } = payload
    const response = yield call(createCourseCollectionService, collectionName)
    yield put(createCourseCollectionSuccess(response))
    yield put(operationSuccess())
    callback(response)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* createCourseCollectionSagaWatcher() {
  while (true) {
    const action = yield take(CREATE_COURSE_COLLECTION)
    const { payload } = action

    yield fork(generalSaga, createCourseCollectionSagaFlow, payload)
  }
}
