import {
  COURSE_STATISTICS_LIST,
  COURSE_STATISTICS_LIST_SUCCESS,
  COURSE_STATISTICS_LIST_ERROR,
  COURSE_STATISTICS_REPORT,
  COURSE_STATISTICS_REPORT_SUCCESS,
  COURSE_STATISTICS_REPORT_ERROR,
} from './type'

/**
 *
 * @param {*} query
 */
export const courseStatisticsListAction = query => {
  return {
    type: COURSE_STATISTICS_LIST,
    payload: { query },
  }
}

/**
 *
 * @param {*} response
 */
export const courseStatisticsListSuccess = response => {
  return {
    type: COURSE_STATISTICS_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const courseStatisticsListError = () => {
  return {
    type: COURSE_STATISTICS_LIST_ERROR,
  }
}

/**
 *
 */
export const courseStatisticsReportAction = () => {
  return {
    type: COURSE_STATISTICS_REPORT,
    payload: {},
  }
}

/**
 *
 * @param {*} response
 */
export const courseStatisticsReportSuccess = () => {
  return {
    type: COURSE_STATISTICS_REPORT_SUCCESS,
    payload: {},
  }
}

/**
 *
 */
export const courseStatisticsReportError = () => {
  return {
    type: COURSE_STATISTICS_REPORT_ERROR,
  }
}
