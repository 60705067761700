const styles = theme => ({
  root: {
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  childRoot: {
    height: '100%',
  },
})

export default styles
