import { put, call, take, fork } from 'redux-saga/effects'
import { courseSettingCreateService } from '../../api/assignment'
//import { questionPatchSuccess } from '../../reducers/question/action'
import { courseSettingCreateSuccess } from '../../reducers/assignment/action'
import { COURSE_SETTING_CREATE } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* courseSettingsCreateFlow(payload) {
  try {
    const { content, questionId, callback } = payload
    const response = yield call(courseSettingCreateService, content)
    yield put(courseSettingCreateSuccess(response.data, questionId))
    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* courseSettingsCreateWatcher() {
  while (true) {
    const action = yield take(COURSE_SETTING_CREATE)
    const { payload } = action

    yield fork(generalSaga, courseSettingsCreateFlow, payload, false)
  }
}
