const styles = theme => ({
  grid: {
    width: '100%',
    maxWidth: 610,
    flexBasis: 'unset',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none'
  },
})

export default styles
