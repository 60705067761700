import React from 'react'
import styles from './style'
import propTypes from './prop'
import translator from '../../../translate'
import {
  Grid,
  withStyles,
  Button,
  Box,
  IconButton,
  ButtonGroup,
} from '@material-ui/core'
import EditOutlined from '@material-ui/icons/EditOutlined'
import QuestionCreateLink from '../create-link'
import { componentBoxShowAction } from '../../../reducers/component-box/action'
import { QuestionFieldNames } from '../../../model/question'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../reducers/message-box/action'
import { ButtonData, ButtonType, ButtonActionType } from '../../../model/button'
import { questionLinkDeleteAction } from '../../../reducers/question/action'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import { componentBoxHideAction } from '../../../reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { guid } from 'src/utils/guid'
import ModalContent from 'src/components/card-item/link-settings'
import { useCourseSettings } from 'src/hooks/useCourseSettings'
//import { IconPickerItem } from 'react-fa-icon-picker'
import rgbaParser from 'src/utils/rgbaParser'

import URLs from 'src/api/url'
const SETTING_KEY = 'theme'
const QuestionLink = props => {
  const { classes, entity, dispatch, location, refresh } = props
  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)

  /**
   *
   */
  const editClickHandler = link => {
    const component = (
      <QuestionCreateLink
        cardId={entity[QuestionFieldNames.ID]}
        location={location}
        refresh={refresh}
        linkId={link?.id}
        deleteButtonClickHandler={deleteButtonClickHandler}
      />
    )
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, {
          hideCloseButton: false,
        }),
      ),
    )
  }
  const deleteButtonClickHandler = e => {
    const id = e.id
    const question = e.cardId

    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          translator._('message.delete'),
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              e => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.yes'),
              ButtonActionType.DELETE,
              e => {
                dispatch(messageBoxHideAction())

                dispatch(
                  questionLinkDeleteAction(id, question, () => {
                    //dispatch(questionGetAction(entity.id))
                  }),
                  dispatch(componentBoxHideAction()),
                )
              },
            ),
          ],
        ),
      ),
    )
  }
  /**
   *
   * @param {*} e
   */
  const linkButtonClickHandler = e => {
    if (e.currentTarget.dataset.type === 'course') {
      window.open(URLs.APP_URL + e.currentTarget.dataset.url + '_blank')
    }else if(e.currentTarget.dataset.type === 'pdf'){
      window.open(URLs.CDN +e.currentTarget.dataset.url, '_blank')

    } else {
      window.open(e.currentTarget.dataset.url, '_blank')
    }
  }
  const settingsModalClickHandler = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <ModalContent
              classes={classes}
              themeSettings={value}
              cardId={entity[QuestionFieldNames.ID]}
              key={guid.uid}
            />
          ),
          {
            hideCloseButton: false,
            header: translator._('taskItem.linkSettings'),
            backgroundColor:
              value?.bodyBackgroundImage === undefined ||
              value?.bodyBackgroundImage === ''
                ? value?.backgroundColor
                  ? rgbaParser(value?.backgroundColor)
                  : '#ffffff'
                : value?.bodyBackgroundImage,
            fontColor: value?.fontColor
              ? rgbaParser(value?.fontColor)
              : '#000000',
            callback: e => {},
          },
        ),
      ),
    )
  }

  const EditSettingsIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 26C24.1046 26 25 25.1046 25 24C25 22.8954 24.1046 22 23 22C21.8954 22 21 22.8954 21 24C21 25.1046 21.8954 26 23 26Z"
          fill="black"
        />
        <path
          d="M30.777 23.479C30.1591 21.9049 29.0929 20.5467 27.7105 19.5728C26.3281 18.5988 24.6902 18.052 23 18C21.3098 18.052 19.6719 18.5988 18.2895 19.5728C16.9071 20.5467 15.8409 21.9049 15.223 23.479L15 24L15.223 24.521C15.8409 26.0951 16.9071 27.4533 18.2895 28.4272C19.6719 29.4012 21.3098 29.948 23 30C24.6902 29.948 26.3281 29.4012 27.7105 28.4272C29.0929 27.4533 30.1591 26.0951 30.777 24.521L31 24L30.777 23.479ZM23 28C22.2089 28 21.4355 27.7654 20.7777 27.3259C20.1199 26.8864 19.6072 26.2616 19.3045 25.5307C19.0017 24.7998 18.9225 23.9956 19.0769 23.2196C19.2312 22.4437 19.6122 21.731 20.1716 21.1716C20.731 20.6122 21.4437 20.2312 22.2196 20.0769C22.9956 19.9225 23.7998 20.0017 24.5307 20.3045C25.2616 20.6072 25.8864 21.1199 26.3259 21.7777C26.7654 22.4355 27 23.2089 27 24C26.9987 25.0605 26.5768 26.0771 25.827 26.827C25.0771 27.5768 24.0605 27.9987 23 28ZM12.399 20.8C11.581 20.1862 10.9335 19.3736 10.5178 18.4392C10.1022 17.5048 9.93235 16.4797 10.0243 15.4612C10.1163 14.4427 10.4671 13.4646 11.0433 12.6197C11.6196 11.7749 12.4022 11.0914 13.3169 10.6341C14.2317 10.1768 15.2481 9.96085 16.2697 10.0068C17.2913 10.0528 18.2842 10.3591 19.1542 10.8967C20.0241 11.4343 20.7422 12.1854 21.2403 13.0785C21.7384 13.9717 21.9999 14.9773 22 16H20C20 15.3182 19.8257 14.6477 19.4937 14.0521C19.1617 13.4566 18.6829 12.9559 18.1029 12.5974C17.5229 12.2389 16.8609 12.0347 16.1798 12.004C15.4987 11.9734 14.821 12.1174 14.2111 12.4223C13.6013 12.7272 13.0795 13.183 12.6954 13.7463C12.3112 14.3096 12.0774 14.9617 12.0162 15.6408C11.9549 16.3198 12.0683 17.0033 12.3455 17.6262C12.6227 18.2492 13.0545 18.7909 13.6 19.2L12.399 20.8Z"
          fill="black"
        />
        <path
          d="M29.305 11.044L26.945 6.956C26.7141 6.555 26.3512 6.24656 25.9183 6.08334C25.4853 5.92011 25.0091 5.91221 24.571 6.061L22.137 6.885C21.7175 6.60259 21.2792 6.34933 20.825 6.127L20.321 3.607C20.2302 3.1538 19.9852 2.74604 19.6277 2.45307C19.2701 2.1601 18.8222 2 18.36 2H13.64C13.1776 2.00005 12.7296 2.16029 12.372 2.45346C12.0145 2.74663 11.7696 3.15461 11.679 3.608L11.175 6.126C10.7157 6.34621 10.2723 6.59816 9.84798 6.88L7.42798 6.061C6.99011 5.91274 6.51437 5.9209 6.08185 6.0841C5.64932 6.2473 5.28677 6.55544 5.05598 6.956L2.69598 11.044C2.46479 11.4443 2.3794 11.9123 2.45433 12.3685C2.52926 12.8246 2.75989 13.2407 3.10698 13.546L5.03798 15.243C5.02098 15.495 4.99998 15.745 4.99998 16C4.99998 16.258 5.00998 16.513 5.02798 16.766L3.10798 18.454C2.76056 18.759 2.52955 19.175 2.45426 19.6312C2.37896 20.0873 2.46403 20.5555 2.69498 20.956L5.05498 25.044C5.28585 25.445 5.64873 25.7534 6.08169 25.9167C6.51466 26.0799 6.99085 26.0878 7.42898 25.939L9.86298 25.115C10.2823 25.3979 10.7207 25.6515 11.175 25.874L11.678 28.392C11.7686 28.8456 12.0137 29.2537 12.3714 29.5469C12.7292 29.84 13.1775 30.0002 13.64 30H15V28H13.64L12.93 24.45C11.9478 24.0851 11.0361 23.5533 10.235 22.878L6.78798 24.044L4.42798 19.956L7.15298 17.561C6.96655 16.5269 6.96418 15.4679 7.14598 14.433L4.42798 12.043L6.78798 7.956L10.215 9.116C11.0218 8.43973 11.9406 7.90975 12.93 7.55L13.64 4H18.36L19.07 7.55C20.0521 7.91491 20.9639 8.44673 21.765 9.122L25.212 7.956L27.572 12.044L24.774 14.496L26.092 16L28.892 13.546C29.2394 13.241 29.4704 12.825 29.5457 12.3688C29.621 11.9127 29.5359 11.4445 29.305 11.044Z"
          fill="black"
        />
      </svg>
    )
  }

  /**
   *
   */
  const LinkItems = () => {
    let links = entity[QuestionFieldNames.QUESTION_LINKS]

    links.sort((a, b) => {
      if (a.id < b.id) return -1
      if (a.id > b.id) return 1
      return 0
    })
    let item = links.map(link => {
      return (
        <Box className={classes.linkBox}>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            style={{ boxShadow: 'none' }}
          >
            <Button
              variant="outlined"
              data-url={link.url}
              data-type={link.type}
              onClick={linkButtonClickHandler}
              style={{ color: '#0d53b7', boxShadow: 'none' }}
            >
              {link.title}
            </Button>
            <Button
              style={{ padding: 0 }}
              onClick={() => editClickHandler(link)}
            >
              <EditOutlined fontSize="small" />
            </Button>
          </ButtonGroup>
        </Box>
      )
    })

    return item
  }

  return entity[QuestionFieldNames.QUESTION_LINKS]?.length > 0 ? (
    <Grid container justifyContent="center" className={classes.gridContainer}>
      <Grid item className={classes.grid}>
        <div style={{ marginTop: 32 }}>{LinkItems()}</div>

        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={settingsModalClickHandler}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <EditSettingsIcon />
        </IconButton>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

QuestionLink.propTypes = propTypes

export default withStyles(styles)(QuestionLink)
