import {
  FormItemPropTypes,
  FormItemOptionAttributeName,
} from '../form-item/default.js'
import PropTypes from 'prop-types'

const FormItemMenuPropTypes = {
  ...FormItemPropTypes,
  options: PropTypes.func.isRequired,
  optionAttributeName: PropTypes.shape(FormItemOptionAttributeName).isRequired,
}

export default FormItemMenuPropTypes
