import PropTypes from 'prop-types'
import FormGroupPropTypes from '../../../../form/props/form-group'

const propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ...FormGroupPropTypes,
}

export default propTypes
