import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_DIRECTORY_GET } from '../../reducers/media-directory/type'
import { mediaDirectoryGetService } from '../../api/media-directory'
import { mediaDirectoryGetSuccess } from '../../reducers/media-directory/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaDirectoryGetFlow(payload) {
  try {
    const { request } = payload
    const response = yield call(mediaDirectoryGetService, request)

    yield put(mediaDirectoryGetSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaDirectoryGetWatcher() {
  while (true) {
    const action = yield take(MEDIA_DIRECTORY_GET)
    const { payload } = action

    yield fork(generalSaga, mediaDirectoryGetFlow, payload)
  }
}
