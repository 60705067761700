import {
  SEND_CONFIRMATION_EMAIL,
  SEND_CONFIRMATION_EMAIL_SUCCESS,
  SEND_CONFIRMATION_EMAIL_ERROR,
  CONFIRM_CODE,
  CONFIRM_CODE_SUCCESS,
  CONFIRM_CODE_ERROR,
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  SIGN_UP_ROOTIE,
  SIGN_UP_ROOTIE_ERROR,
  SIGN_UP_ROOTIE_SUCCESS,
  APPSUMO_SIGN_UP,
  APPSUMO_SIGN_UP_SUCCESS,
  APPSUMO_SIGN_UP_ERROR,
} from './type'

/**
 *
 * @param {*} email
 */
export const sendConfirmationEmailAction = email => {
  return {
    type: SEND_CONFIRMATION_EMAIL,
    payload: { email },
  }
}

/**
 *
 * @param {*} email
 */
export const sendConfirmationEmailSuccess = email => {
  return {
    type: SEND_CONFIRMATION_EMAIL_SUCCESS,
    payload: { email },
  }
}

/**
 *
 * @param {*} error
 */
export const sendConfirmationEmailError = error => {
  return {
    type: SEND_CONFIRMATION_EMAIL_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} data
 */
export const confirmCodeAction = data => {
  return {
    type: CONFIRM_CODE,
    payload: { data },
  }
}

/**
 *
 * @param {*} email
 */
export const confirmCodeSuccess = data => {
  return {
    type: CONFIRM_CODE_SUCCESS,
    payload: { data },
  }
}

/**
 *
 * @param {*} error
 */
export const confirmCodeError = error => {
  return {
    type: CONFIRM_CODE_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} data
 */
export const signUpAction = data => {
  return {
    type: SIGN_UP,
    payload: { data },
  }
}

/**
 *
 * @param {*} entity
 */
export const signUpSuccess = entity => {
  return {
    type: SIGN_UP_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 * @param {*} error
 */
export const signUpError = error => {
  return {
    type: SIGN_UP_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} data
 */
export const signUpRootieAction = data => {
  return {
    type: SIGN_UP_ROOTIE,
    payload: { data },
  }
}

/**
 *
 * @param {*} entity
 */
export const signUpRootieSuccess = entity => {
  return {
    type: SIGN_UP_ROOTIE_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 * @param {*} error
 */
export const signUpRootieError = error => {
  return {
    type: SIGN_UP_ROOTIE_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} data
 */

export const appSumoSignUpAction = data => {
  return {
    type: APPSUMO_SIGN_UP,
    payload: { data },
  }
}

/**
 *
 * @param {*} entity
 */

export const appSumoSignUpSuccess = entity => {
  return {
    type: APPSUMO_SIGN_UP_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 * @param {*} error
 */
export const appSumoSignUpError = error => {
  return {
    type: APPSUMO_SIGN_UP_ERROR,
    payload: { error },
  }
}
