import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import styles from './styles'

import { useDispatch } from 'react-redux'
import { ShareViewHeader, ShareView } from './components'

const ShareLink = props => {
  const { classes, entity } = props

  const dispatch = useDispatch()

  const components = [
    {
      component: <ShareViewHeader />,
      onView: true,
      mainPage: true,
    },
    {
      component: <ShareView entity={entity} dispatch={dispatch} />,
      onView: true,
      mainPage: true,
    },
  ]

  return (
    <div className={classes.content}>
      <div className={classes.content__components}>
        {components.map(
          (component, index) =>
            component.onView && <div key={index}>{component.component}</div>,
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(ShareLink)
