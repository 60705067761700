import { put, call, take, fork } from 'redux-saga/effects'
import { logoutService } from '../../api/authentication'
import { authenticationLogoutSuccess } from '../../reducers/authentication/action'
import { AUTHENTICATION_LOGOUT } from '../../reducers/authentication/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'
import { remove as removeCookie } from 'es-cookie'

/**
 *
 * @param {*} payload
 */
function* logoutSagaFlow(payload) {
  try {
    yield call(logoutService)

    yield put(operationSuccess())
    yield put(authenticationLogoutSuccess())
    removeCookie('token')
    window.location.href = '/'
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* logoutSagaWatcher() {
  while (true) {
    const action = yield take(AUTHENTICATION_LOGOUT)
    const { payload } = action

    yield fork(generalSaga, logoutSagaFlow, payload)
  }
}
