const MediaDirectoryFieldNames = {
  FOLDER_NAME: 'folderName',
  DIRECTORY_FOLDER_ID: 'directoryFolderId',
  FOLDER_DTOS: 'folderDtos',
  IMAGE_DTOS: 'imageDtos',
  VIDEO_DTOS: 'videoDtos',
}

const MediaDirectorySchema = () => {
  return {
    [MediaDirectoryFieldNames.FOLDER_NAME]: '',
    [MediaDirectoryFieldNames.DIRECTORY_FOLDER_ID]: null,
    [MediaDirectoryFieldNames.FOLDER_DTOS]: [],
    [MediaDirectoryFieldNames.IMAGE_DTOS]: [],
    [MediaDirectoryFieldNames.VIDEO_DTOS]: [],
  }
}

class MediaItemType {
  static FOLDER = 0
  static IMAGE = 1
  static VIDEO = 2
}

export { MediaDirectoryFieldNames, MediaDirectorySchema, MediaItemType }
