import { put, call, take, fork } from 'redux-saga/effects'
import { assignmentCloneTemplateCourseService } from 'src/api/assignment'
import { CLONE_TEMPLATE_COURSE } from 'src/reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* templateCourseCloneFlow(payload) {
  try {
    const { code, title, collectionId, callback } = payload
    const response = yield call(
      assignmentCloneTemplateCourseService,
      code,
      title,
      collectionId,
    )

    yield put(operationSuccess())

    callback(response)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* templateCourseCloneWatcher() {
  while (true) {
    const action = yield take(CLONE_TEMPLATE_COURSE)
    const { payload } = action

    yield fork(generalSaga, templateCourseCloneFlow, payload)
  }
}
