import React, { useState } from 'react'
import styles from './style'
import propTypes from './prop'
import { Grid, withStyles, TextField, Box } from '@material-ui/core'
import translator from '../../../../translate'
import { questionPatchAction } from '../../../../reducers/question/action'
import { QuestionFieldNames } from '../../../../model/question'
import { PatchContent, PatchData } from '../../../../model/patch'

const InputEntry = props => {
  const { classes, entity, dispatch } = props

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item className={classes.grid}>
        <Box mt={2} />
        <TextField
          type="text"
          label={'Users answer in a short-text format.'}
          disabled
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

InputEntry.propTypes = propTypes

export default withStyles(styles)(InputEntry)
