import { put, call, take, fork } from 'redux-saga/effects'
import { appsumoCodeService } from '../../api/common'
import { codeVerifierActionSuccess } from '../../reducers/common/action.js'
import { APPSUMO_CODE_CONFIRM } from '../../reducers/common/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* appsumoCodeVerifierSagaFlow(payload) {
  try {
    const { code, callback } = payload
    const response = yield call(appsumoCodeService, code)
    yield put(codeVerifierActionSuccess(response.code))
    yield put(operationSuccess())
    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* appsumoCodeVerifierSagaWatcher() {
  while (true) {
    const action = yield take(APPSUMO_CODE_CONFIRM)
    const { payload } = action

    yield fork(generalSaga, appsumoCodeVerifierSagaFlow, payload)
  }
}
