import {
  SHOP_UPDATE_ENTITY,
  SHOP_GET,
  SHOP_GET_SUCCESS,
  SHOP_GET_ERROR,
  SHOP_EDIT,
  SHOP_EDIT_SUCCESS,
  SHOP_EDIT_ERROR,
  SHOP_PATCH,
  SHOP_PATCH_SUCCESS,
  SHOP_PATCH_ERROR,
} from './type'

/**
 *
 * @param {*} entity
 */
export const shopUpdateEntityAction = entity => {
  return {
    type: SHOP_UPDATE_ENTITY,
    payload: { entity },
  }
}

/**
 *
 * @param {*} id
 */
export const shopGetAction = id => {
  return {
    type: SHOP_GET,
    payload: { id },
  }
}

/**
 *
 * @param {*} response
 */
export const shopGetSuccess = response => {
  return {
    type: SHOP_GET_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const shopGetError = () => {
  return {
    type: SHOP_GET_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const shopEditAction = id => {
  return {
    type: SHOP_EDIT,
    payload: { id },
  }
}

/**
 *
 * @param {*} response
 */
export const shopEditSuccess = response => {
  return {
    type: SHOP_EDIT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const shopEditError = () => {
  return {
    type: SHOP_EDIT_ERROR,
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */
export const shopPatchAction = (content, callback) => {
  return {
    type: SHOP_PATCH,
    payload: { content, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const shopPatchSuccess = response => {
  return {
    type: SHOP_PATCH_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const shopPatchError = () => {
  return {
    type: SHOP_PATCH_ERROR,
  }
}
