const styles = theme => ({
  paper: {
    backgroundColor: '#0000009e',
    backdropFilter: 'blur(5px)',
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
    position: 'fixed',
    zIndex: 9999,
  },
  grid: {
    width: 550,
    maxWidth: 'none',
    flexBasis: 'unset',
    textAlign: 'center',
    padding: theme.spacing(3),
    borderRadius: 10,
    backgroundColor: '#ffffff',
  },
  buttons: {
    marginTop: theme.spacing(3),
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    gap: '5px'
  },
  button: {
    marginRight: 0,
    display: 'block',

    '&:last-child': {
      marginRight: 0,
    },
  },
  buttonDelete: {
    //backgroundColor: red[500],
  },
})

export default styles
