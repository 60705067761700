import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { processStartEnd } from 'src/reducers/process/action'
import TasksStep from './tasks-step/TasksStep'
import styles from './styles'
import {
  AssignmentFieldNames,
  AssignmentQuestionSendTypes,
} from '../../model/assignment'

import { initialThemeSettings } from 'src/model/theme'
import {
  QuestionFieldNames,
  AnswerItemType,
  defaultQTexts,
} from '../../model/question'
import {
  assignmentUpdateAttribute,
  assignmentGetAction,
  assignmentEditAction,
  assignmentReportAction,
} from '../../reducers/assignment/action'
import { withStyles, Grid, Card } from '@material-ui/core'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import ModalContent from 'src/components/card-item/bottom-bar/button-actions/stripe-payment'
import { assignmentListService } from 'src/api/assignment'
import CreateQuizWithAi from '../create-quiz-with-ai'
import { courseSettingCreate } from 'src/reducers/assignment/action'

const SETTING_KEY = 'theme'

const CreateQuiz = props => {
  const { classes, dispatch, entity, list, match, history } = props

  const { params } = match

  const [pageURL, setURL] = useState(window.location.href)

  const [themeDrawerOpen, setThemeDrawerOpen] = React.useState(false)

  const [listingType, setListingType] = useState('')

  useEffect(() => {
    if (entity != null) {
      const settings = entity[AssignmentFieldNames.SETTING_DTOS]
      const settingEntity = settings.find(x => x.key === SETTING_KEY)
      if (!settingEntity) {
        dispatch(
          courseSettingCreate(
            {
              courseId: entity[AssignmentFieldNames.ID],
              key: 'theme',
              value: JSON.stringify(initialThemeSettings),
            },
            entity[AssignmentFieldNames.ID],
          ),
        )
      }
    }
  }, [entity])

  const [page, setPage] = React.useState(1)
  const [questionSendOrder, setQuestionSendOrder] = useState(
    AssignmentQuestionSendTypes.ORDERED,
  )

  //const prevEntity = useRef()
  useEffect(() => {
    if (params && params.id) {
      dispatch(assignmentGetAction(params.id))
    } else {
      dispatch(processStartEnd())
    }

    ;(async () => {
      // Check the user course count and show popup if user course count less than one
      // IRREGULAR_REQUEST

      const response = await assignmentListService({
        CurrentPage: 0,
        PageSize: 200,
        Asc: false,
        FilterText: '',
        Filters: [],
      })

      const courseList = response && response.data.results
      /* if (courseList.length < 1) {
        dispatch(
          componentBoxShowAction(
            new ComponentBoxContent(<TutorialContent dispatch={dispatch} />, {
              hideCloseButton: false,
              header: 'Quick-Start Guide',
              callback: e => {},
            }),
          ),
        )
      } */
    })()
  }, [])

  useEffect(() => {
    if (entity) {
      const result = entity[AssignmentFieldNames.QUESTION_DTOS].find(
        value =>
          value[QuestionFieldNames.QUESTION_TYPE] ==
          AnswerItemType.STRIPEPAYMENT,
      )
      if (result) {
        const location = pageURL ? pageURL : window.location.href
        const stripeReturn =
          location.split('stripeReturn=').length > 1
            ? location.split('stripeReturn=')[1].split('&')[0]
            : false
        if (location && stripeReturn === 'true') {
          dispatch(
            componentBoxShowAction(
              new ComponentBoxContent(
                (
                  <ModalContent
                    {...props}
                    entity={result}
                    courseId={entity[AssignmentFieldNames.ID]}
                  />
                ),
                {
                  hideCloseButton: false,
                  header: 'Stripe Integration',
                  callback: e => {},
                },
              ),
            ),
          )

          setURL(window.location.origin)
          setPage(result?.orderOfCard + 1)
        }
      }
    }
  }, [entity])

  const submitEntity = newEntity => {
    dispatch(assignmentEditAction(newEntity, () => {}))
  }

  const onDrag = (oldIndex, newIndex, _entity) => {
    if (!_entity) {
      _entity = entity
    }
    const list = _entity
      ? _entity[AssignmentFieldNames.QUESTION_DTOS]
        ? _entity[AssignmentFieldNames.QUESTION_DTOS]
        : []
      : []
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }
    if (newIndex !== null) {
      let items = reorder(list, oldIndex, newIndex)

      // TODO: autosenderId için daha temiz ve garanti bir yol olmalı
      // TODO: backendde de autosenderId check edilmeli
      let letNewRelationList = items.map((x, index) => {
        return {
          courseId: window.location.pathname.split('/')[2],
          cardId: x.id,
          orderOfCard: index,
        }
      })

      items.forEach((value, index) => {
        items[index].orderOfCard = index
      })

      let newEntity = {
        ..._entity,
        [AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS]: letNewRelationList,
      }
      submitEntity(newEntity)
      dispatch(
        assignmentUpdateAttribute([
          {
            path: AssignmentFieldNames.QUESTION_DTOS,
            value: items,
          },
          {
            path: AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS,
            value: letNewRelationList,
          },
        ]),
      )
    }
  }

  const questionAdd = (list, callback, editor) => {
    setListingType('question_add')
    const entityDtos = entity[AssignmentFieldNames.QUESTION_DTOS]
    const hasFinishCard =
      entityDtos.length > 0 &&
      entityDtos[entityDtos.length - 1][QuestionFieldNames.QUESTION_TYPE] >=
        12 &&
      entityDtos[entityDtos.length - 1][QuestionFieldNames.QUESTION_TYPE] <= 14

    let letNewRelationList = list.map((x, index) => {
      return {
        courseId: entity[AssignmentFieldNames.ID],
        cardId: x.id,
        orderOfCard: entity[AssignmentFieldNames.QUESTION_DTOS].length + 1,
      }
    })

    let relationList = [
      ...entity[AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS],
      ...letNewRelationList,
    ]

    const type = list[0][QuestionFieldNames.QUESTION_TYPE]
    if (defaultQTexts[type]) {
      list[0][QuestionFieldNames.QTEXT] = defaultQTexts[type]
    }

    let questionList = [...entity[AssignmentFieldNames.QUESTION_DTOS], ...list]

    let newEntity = {
      ...entity,
      [AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS]: relationList,
      [AssignmentFieldNames.QUESTION_DTOS]: questionList,
    }

    //submitEntity(newEntity)

    dispatch(
      assignmentUpdateAttribute(
        [
          {
            path: AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS,
            value: relationList,
          },
          {
            path: AssignmentFieldNames.QUESTION_DTOS,
            value: questionList,
          },
        ],
        () => {
          if (callback) {
            callback(newEntity, hasFinishCard)
          } else if (hasFinishCard) {
            onDrag(
              newEntity[AssignmentFieldNames.QUESTION_DTOS].length - 1,
              newEntity[AssignmentFieldNames.QUESTION_DTOS].length - 2,
              newEntity,
            )
          }
          const element = document.getElementById(
            'question-' + list[0].orderOfCard,
          )
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            setTimeout(element.scrollIntoView({ behavior: 'smooth' }), 2000)
          }
        },
      ),
    )
  }

  return (
    <Grid className={classes.root}>
      <Grid item xs={3} sm={4} className={classes.createQuizWithAi}>
        <CreateQuizWithAi entity={entity} />
      </Grid>
      <Grid item xs={9} sm={8}>
        <Card className={classes.taskStepCard}>
          <TasksStep
            dispatch={dispatch}
            entity={entity}
            classes={classes}
            onAdd={questionAdd}
            onDrag={onDrag}
            radioValue={questionSendOrder}
            setRadioValue={setQuestionSendOrder}
            submitEntity={submitEntity}
            page={page}
            setPage={setPage}
            setThemeDrawerOpen={setThemeDrawerOpen}
            listingType={listingType}
          />
        </Card>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = response => ({
  entity: response.assignment.entity,
  query: response.assignment.query,
  list: response.assignment.list,
})

export default connect(mapStateToProps)(withStyles(styles)(CreateQuiz))
