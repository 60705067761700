import React, { useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'
import {
  Typography,
  TextField,
  Box,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Tab,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

function UploadUrl(props) {
  const { documentUrl, setDocumentUrl, handleAddSource, classes } = props

  const [newUrl, setNewUrl] = useState()
  const [urlValidation, setUrlValidation] = useState(true)

  useEffect(() => {
    let newUrlList = [...documentUrl]
    if (newUrlList.length == 1) {
      return
    }
    var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    let testList = []
    newUrlList.map(
      url => (testList = [...testList, regexp.test(url).toString()]),
    )
    handleAddSource(!testList.includes('false'))
    setUrlValidation(!testList.includes('false'))
  }, [])
  const handleNewUrl = (e, index) => {
    let testList = []
    var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    setUrlValidation(regexp.test(e))
    handleAddSource(regexp.test(e))
    let newUrlList = [...documentUrl]
    newUrlList[index] = e

    if (e == '' && newUrlList.length > 1) {
      newUrlList.splice(index, 1)
      newUrlList.length > 0 &&
        newUrlList.map(
          url => (testList = [...testList, regexp.test(url).toString()]),
        )
      handleAddSource(!testList.includes('false'))
      setUrlValidation(!testList.includes('false'))
    }
    setDocumentUrl(newUrlList)
  }
  const handleAddUrl = () => {
    if (documentUrl.includes('')) {
      return
    }
    setDocumentUrl([...documentUrl, ''])
  }
  const handleAddedUrl = (e, index) => {
    let newUrlList = [...documentUrl]
    let testList = []
    newUrlList[index] = e
    if (e === '' && newUrlList.length >= 2) {
      newUrlList.splice(index, 1)
      setDocumentUrl(newUrlList)
    } else if (e === '' && newUrlList.length == 1) {
      newUrlList[index] = e
      setDocumentUrl([...newUrlList])
    }
  }

  // this will solve your issue

  return (
    <>
      {documentUrl &&
        documentUrl.map((url, index) => {
          return (
            <TextField
              style={{ width: '-webkit-fill-available', marginBottom: '16px' }}
              placeholder="E.g. https://en.wikipedia.org/wiki/Tennis"
              value={url}
              onBlur={e => handleAddedUrl(e.target.value, index)}
              onChange={e => handleNewUrl(e.target.value, index)}
              error={!urlValidation}
              helperText={!urlValidation ? 'Please enter a valid URL' : ''}
            ></TextField>
          )
        })}

      {documentUrl[0] != '' && documentUrl.length >= 1 && (
        <div
          onClick={() => handleAddUrl()}
          className={classes.addUrlDiv}
          style={{
            border: '1px dashed rgba(51, 102, 255, 0.24)',
            height: 56,
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'row',
            //justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
          }}
        >
          <AddIcon style={{ marginTop: 2, marginRight: 3, width: '15px' }} />
          <Typography fontSize="14px" fontWeight={500}>
            Add More
          </Typography>
        </div>
      )}
    </>
  )
}

export default withStyles(styles)(UploadUrl)
