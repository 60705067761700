const styles = theme => ({
  root: {
    //padding: theme.spacing(2),
    margin: theme.spacing(3),
    [theme.breakpoints.between('xs', 'lg')]: {
      marginBottom: '0px',
    },
  },

  input: {
    padding: 0,
    fontSize: 12,
    height: 30,
    width: 30,
    borderRadius: 20,
    color: '#56616c',
  },
  badgeRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  badge: {
    height: 30,
    padding: '0px 0px 0px 5px',
    border: '1px solid #dcdcdc',
    backgroundColor: '#fdfdfd',
    cursor: 'grabbing',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 6,
    '& a': {
      color: '#222222',
      marginBottom: '4px',
    },
  },
})

export default styles
