import PropTypes from 'prop-types'

const propTypes = {
  list: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  showSortOrder: PropTypes.bool,
}

export default propTypes
