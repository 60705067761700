import React, { useState, useEffect } from 'react'
import styles from './style'
import propTypes from './prop'
import {
  withStyles,
  FormControl,
  Typography,
  FormLabel,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
  Grid,
  Alert,
} from '@material-ui/core'
import translator from 'src/translate'
import CheckIcon from '@material-ui/icons/Check'
import { AssignmentFieldNames } from 'src/model/assignment'
import { QuestionFieldNames } from 'src/model/question'

import { useDispatch, useSelector } from 'react-redux'

import { initialLinkSettings, LINK_PREFERENCES } from 'src/model/link'

import {
  useCourseSettings,
  createSetting,
  patchSetting,
} from 'src/hooks/useCourseSettings'
import rgbaParser from 'src/utils/rgbaParser'

const LinkSettingsModal = props => {
  const { classes, themeSettings, cardId } = props

  const baseEntity = useSelector(state => state.assignment.entity)
  const baseEntityDtos = useSelector(
    state => state.assignment.entity[AssignmentFieldNames.QUESTION_DTOS],
  )
  const dispatch = useDispatch()

  const {value: linkSettings, exists, execute, update} = useCourseSettings(LINK_PREFERENCES)
  const [pageReady, setPageReady] = useState(exists)

  useEffect(() => {
    if (!exists) {
      execute(initialLinkSettings)
    }
  }, [exists])

 
  const handleChangeLinkProperty = data => {
    if (!linkSettings) return

    setPageReady(false)
    update(data, () => setPageReady(true))
  }

  const buttonColor = rgbaParser(
    themeSettings?.buttonColor || { r: 12, g: 83, b: 183, a: 1 },
  )
  const fontColor = rgbaParser(
    themeSettings?.fontColor || { r: 0, g: 0, b: 0, a: 1 },
  )

  const GridContainer = ({ children }) => {
    return <div className={classes.gridBlock}>{children}</div>
  }

  const GridItem = ({ children }) => {
    return <div className={classes.gridItem}>{children}</div>
  }

  const StyledRectangle = props => {
    const enabled = linkSettings === Number(props.dataKey)
    return (
      <div
        className={classes.styledRectangle}
        onClick={() => handleChangeLinkProperty(props.dataKey)}
        style={{
          backgroundColor: buttonColor,
          color: fontColor,
          opacity: enabled ? 1 : 0.4,
          ...props.style,
        }}
      >
        {enabled && (
          <CheckIcon
            fontSize="small"
            style={{ position: 'absolute', color: fontColor }}
          />
        )}
        {props.children}
      </div>
    )
  }

  return (
    <FormControl className={classes.form}>
      <Typography>Filled Boxes</Typography>
      <GridContainer>
        <GridItem>
          <StyledRectangle dataKey={1}></StyledRectangle>
        </GridItem>
        <GridItem>
          <StyledRectangle
            dataKey={2}
            style={{ borderRadius: 10 }}
          ></StyledRectangle>
        </GridItem>
        <GridItem>
          <StyledRectangle
            dataKey={3}
            style={{ borderRadius: 20 }}
          ></StyledRectangle>
        </GridItem>
      </GridContainer>
      <Typography>Outlined Boxes</Typography>
      <GridContainer>
        <GridItem>
          <StyledRectangle
            dataKey={4}
            style={{ background: 'none', border: `2px solid ${buttonColor}` }}
          ></StyledRectangle>
        </GridItem>
        <GridItem>
          <StyledRectangle
            dataKey={5}
            style={{
              background: 'none',
              border: `2px solid ${buttonColor}`,
              borderRadius: 10,
            }}
          ></StyledRectangle>
        </GridItem>
        <GridItem>
          <StyledRectangle
            dataKey={6}
            style={{
              background: 'none',
              border: `2px solid ${buttonColor}`,
              borderRadius: 20,
            }}
          ></StyledRectangle>
        </GridItem>
      </GridContainer>
      <Typography>Option Boxes</Typography>
      <GridContainer>
        <GridItem>
          <StyledRectangle
            dataKey={7}
            style={{ background: 'none', border: `2px solid ${buttonColor}` }}
          >
            <div
              className={classes.circle}
              style={{ border: `2px solid ${buttonColor}` }}
            ></div>
          </StyledRectangle>
        </GridItem>
        <GridItem>
          <StyledRectangle
            dataKey={8}
            style={{
              background: 'none',
              border: `2px solid ${buttonColor}`,
              borderRadius: 10,
            }}
          >
            <div
              className={classes.circle}
              style={{ border: `2px solid ${buttonColor}` }}
            ></div>
          </StyledRectangle>
        </GridItem>
        <GridItem>
          <StyledRectangle
            dataKey={9}
            style={{
              background: 'none',
              border: `2px solid ${buttonColor}`,
              borderRadius: 20,
            }}
          >
            <div
              className={classes.circle}
              style={{ border: `2px solid ${buttonColor}` }}
            ></div>
          </StyledRectangle>
        </GridItem>
      </GridContainer>
      <Typography>Soft-Shadowed Boxes</Typography>
      <GridContainer>
        <GridItem>
          <StyledRectangle
            dataKey={10}
            style={{
              background: 'none',
              border: `2px solid ${buttonColor}`,
              boxShadow:
                'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
            }}
          ></StyledRectangle>
        </GridItem>
        <GridItem>
          <StyledRectangle
            dataKey={11}
            style={{
              background: 'none',
              border: `2px solid ${buttonColor}`,
              borderRadius: 10,
              boxShadow:
                'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
            }}
          ></StyledRectangle>
        </GridItem>
        <GridItem>
          <StyledRectangle
            dataKey={12}
            style={{
              background: 'none',
              border: `2px solid ${buttonColor}`,
              borderRadius: 20,
              boxShadow:
                'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
            }}
          ></StyledRectangle>
        </GridItem>
      </GridContainer>

      <Alert severity="info">
        Color options are set according to your "Theme".
      </Alert>
    </FormControl>
  )
}

LinkSettingsModal.propTypes = propTypes

export default withStyles(styles)(LinkSettingsModal)
