import { initialThemeSettings } from './index'
import URLs from 'src/api/url'
import URL from 'src/api/url/api'

const themePresets = [
  {
    name: 'Default',
    theme: initialThemeSettings,
    previewText: 'Default',
    defaultColor: 'blue',
    selectedForAIGenerator: true,
    purpose: ['Link', 'Embed'],
    colors: {
      blue: {
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },

        sampleUrl: URLs.APP_URL + 'Eg6HV4YZVkSABdpaNtx64w/1',
      },
    },
  },
  {
    name: 'flat',
    theme: {
      ...initialThemeSettings,
      font: 'Roboto',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0 },
      arrow: 2,
    },
    previewText: 'Flat',
    defaultColor: 'blue',
    colors: {
      blue: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },

        backgroundColor: { r: 0, g: 102, b: 150, a: 1 },
        sampleUrl: URLs.APP_URL + 'gCb9e74B-keNOZCcknm4BQ/1',
      },
      orange: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },

        backgroundColor: { r: 255, g: 146, b: 33, a: 1 },
        sampleUrl: URLs.APP_URL + 'RHQlvKFvYUmNteMH3cWvJw/3',
      },
      red: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 244, g: 67, b: 54, a: 1 },
        sampleUrl: URLs.APP_URL + 'k2WUhxUUXEybZ85dbYlS1A/1',
      },
      grey: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 125, g: 125, b: 125, a: 1 },
        sampleUrl: URLs.APP_URL + 'DSZy9kzQN0iK2HSreJtyaA/2',
      },
      green: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 0, g: 150, b: 136, a: 1 },
        sampleUrl: URLs.APP_URL + 'BeEwBEt6u0KJ0nhXwDk20Q/2',
      },
      brown: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 180, g: 112, b: 61, a: 1 },
        sampleUrl: URLs.APP_URL + 'pvsfRjWQSUCpzSJKAVsDDw/1',
      },
    },
  },
  {
    name: 'font',
    theme: {
      ...initialThemeSettings,
      font: 'Noto Sans',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      arrow: 3,
    },
    defaultColor: 'blue',
    previewText: 'Colorful Font',
    sampleUrl: '',
    colors: {
      blue: {
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
        fontColor: { r: 0, g: 102, b: 150, a: 1 },
        buttonColor: { r: 0, g: 102, b: 150, a: 1 },
        sampleUrl: URLs.APP_URL + '7sFIKzvySk2_l8k6zGvNFQ/1',
      },
      orange: {
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
        fontColor: { r: 255, g: 146, b: 33, a: 1 },
        buttonColor: { r: 255, g: 146, b: 33, a: 1 },
        sampleUrl: URLs.APP_URL + 'NDknAgWLvkatU18I8n9oXw/1',
      },
      red: {
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
        fontColor: { r: 244, g: 67, b: 54, a: 1 },
        buttonColor: { r: 244, g: 67, b: 54, a: 1 },
        sampleUrl: URLs.APP_URL + 'rHOST-mit0iCDitSlu7PBQ/1',
      },
      grey: {
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
        fontColor: { r: 125, g: 125, b: 125, a: 1 },
        buttonColor: { r: 125, g: 125, b: 125, a: 1 },
        sampleUrl: URLs.APP_URL + 'YdlegFnZ3kOnUilLbi1HAg/1',
      },
      green: {
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
        fontColor: { r: 0, g: 150, b: 136, a: 1 },
        buttonColor: { r: 0, g: 150, b: 136, a: 1 },
        sampleUrl: URLs.APP_URL + 'esWyqBgxe0WiTmJQxn0-OQ/1',
      },
      brown: {
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
        fontColor: { r: 180, g: 112, b: 61, a: 1 },
        buttonColor: { r: 180, g: 112, b: 61, a: 1 },
        sampleUrl: URLs.APP_URL + 'J39Wj-fVBEClMjpPwmDAoQ/1',
      },
    },
  },
  {
    name: 'center',
    theme: {
      ...initialThemeSettings,
      font: 'Open Sans',
      fontSize: 'XS',
      fontColor: { r: 0, g: 0, b: 0, a: 1 },
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      cardBackgroundColor: { r: 0, g: 0, b: 0, a: 0.1 },
      layout: 1,
      cardAlignment: 'center',
      stretch: 1,
      arrow: 4,
    },
    previewText: 'Center',
    defaultColor: 'orange',
    colors: {
      orange: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },

        backgroundColor: { r: 255, g: 146, b: 33, a: 1 },
        sampleUrl: URLs.APP_URL + 'L3plzU1G7UKzVV10br-nWQ/1',
      },
      green: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 0, g: 150, b: 136, a: 1 },
        sampleUrl: URLs.APP_URL + '9rVYRbYJAEGQ7HZY03009Q/1',
      },
      blue: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },

        backgroundColor: { r: 0, g: 102, b: 150, a: 1 },
        sampleUrl: URLs.APP_URL + 'IojP4ZVR_UGG6LqIC9fE8A/1',
      },

      red: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 244, g: 67, b: 54, a: 1 },
        sampleUrl: URLs.APP_URL + '2MtYPkC6nkq78PGvzjMdcg/1',
      },
      grey: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 125, g: 125, b: 125, a: 1 },
        sampleUrl: URLs.APP_URL + 'M2_suLZ3nUy8g4o7prAUKw/1',
      },

      brown: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 180, g: 112, b: 61, a: 1 },
        sampleUrl: URLs.APP_URL + 'bvSQkJxaQ0C3YdWfKN5qkw/1',
      },
    },
  },
  {
    name: 'illustration',
    theme: {
      ...initialThemeSettings,

      font: 'Inter',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage: 'blue.jpg',
      cardBackgroundColor: { r: 0, g: 0, b: 0, a: 0.2 },
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 1,
    },
    defaultColor: 'blue',
    previewText: 'Illustration',
    sampleUrl: '',
    colors: {
      blue: {
        bodyBackgroundImage: `${URL.CDN}assets/theme/illustration/blue.jpg`,
        sampleUrl: URLs.APP_URL + 'blue-638094658056566306/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 132, g: 160, b: 175, a: 1 },
      },
      brown: {
        bodyBackgroundImage: `${URL.CDN}assets/theme/illustration/brown.jpg`,
        sampleUrl: URLs.APP_URL + 'brown-638094660149788258/1',
        backgroundColor: { r: 119, g: 84, b: 86, a: 1 },
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
      },
      green: {
        bodyBackgroundImage: `${URL.CDN}assets/theme/illustration/green.jpg`,
        sampleUrl: URLs.APP_URL + 'green-638094666287060098/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 10, g: 50, b: 37, a: 1 },
      },
      navy: {
        bodyBackgroundImage: `${URL.CDN}assets/theme/illustration/navy.jpg`,
        sampleUrl: URLs.APP_URL + 'navy-638094668656994551/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 26, g: 50, b: 81, a: 1 },
      },
      orange: {
        bodyBackgroundImage: `${URL.CDN}assets/theme/illustration/orange.jpg`,
        sampleUrl: URLs.APP_URL + 'orange-638094668994594926/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 255, g: 179, b: 137, a: 1 },
      },
      purple: {
        bodyBackgroundImage: `${URL.CDN}assets/theme/illustration/purple.jpg`,
        sampleUrl: URLs.APP_URL + 'purple-638094669538795141/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 109, g: 111, b: 173, a: 1 },
      },
      yellow: {
        bodyBackgroundImage: `${URL.CDN}assets/theme/illustration/yellow.jpg`,
        sampleUrl: URLs.APP_URL + 'yellow-638094669862098755/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 236, g: 175, b: 69, a: 1 },
      },
    },
  },
  {
    name: 'abstract',
    theme: {
      ...initialThemeSettings,

      font: 'Manrope',
      fontColor: { r: 41, g: 36, b: 36, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 247, g: 247, b: 247, a: 1 },
      bodyBackgroundImage: 'blue.svg',

      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0 },
      buttonColor: { r: 74, g: 139, b: 195, a: 1 },
      cardAlignment: 'top',
      stretch: 2,
      layout: 2,
      arrow: 2,
    },
    defaultColor: 'blue',
    previewText: 'Abstract',
    selectedForAIGenerator: true,
    purpose: ['Link', 'Embed'],
    sampleUrl: '',
    colors: {
      blue: {
        sampleUrl: URLs.APP_URL + 'abstract-blue-638094511045330100/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 247, g: 247, b: 247, a: 1 },
      },
      green: {
        sampleUrl: URLs.APP_URL + 'green-638095496705773610/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 61, g: 183, b: 81, a: 1 },
      },
      orange: {
        sampleUrl: URLs.APP_URL + 'orange-638095498080586377/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 203, g: 97, b: 22, a: 1 },
      },
      pink: {
        sampleUrl: URLs.APP_URL + 'pink-638095499339970432/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 190, g: 67, b: 201, a: 1 },
      },
      red: {
        sampleUrl: URLs.APP_URL + 'red-638095500023750584/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 187, g: 53, b: 55, a: 1 },
      },
      yellow: {
        sampleUrl: URLs.APP_URL + 'yellow-638095499933716226/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 165, g: 177, b: 39, a: 1 },
      },
    },
  },
  {
    name: 'classic',
    theme: {
      ...initialThemeSettings,

      font: 'PT Serif',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0 },
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      cardAlignment: 'top',
      stretch: 2,
      layout: 2,
      bodyBackgroundImage: 'blue.svg',
      arrow: 3,
    },
    selectedForAIGenerator: true,
    purpose: ['Carousel'],
    defaultColor: 'blue',
    previewText: 'Classic',
    sampleUrl: '',
    colors: {
      blue: {
        sampleUrl: URLs.APP_URL + 'blue-638094562468583867/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 71, g: 197, b: 239, a: 1 },
      },
      green: {
        sampleUrl: URLs.APP_URL + 'green-638094565835927511/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 212, g: 232, b: 214, a: 1 },
      },

      pink: {
        sampleUrl: URLs.APP_URL + 'pink-638094567876208642/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 251, g: 208, b: 231, a: 1 },
      },
      purple: {
        sampleUrl: URLs.APP_URL + 'purple-638095505372925080/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 151, g: 127, b: 140, a: 1 },
      },
      white: {
        sampleUrl: URLs.APP_URL + 'white-638095506753823911/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 234, g: 245, b: 246, a: 1 },
      },
      yellow: {
        sampleUrl: URLs.APP_URL + 'yellow-638095507251334773/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 255, g: 248, b: 189, a: 1 },
      },
    },
  },
  {
    name: 'contrast',
    theme: {
      ...initialThemeSettings,

      font: 'Kanit',
      fontColor: { r: 252, g: 250, b: 144, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage: 'black.svg',
      cardBackgroundColor: { r: 251, g: 246, b: 240, a: 0 },
      buttonColor: { r: 252, g: 250, b: 144, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 2,
      sampleUrl: 'https://app.minicoursegenerator.com/Eg6HV4YZVkSABdpaNtx64w/1',
      arrow: 4,
    },
    defaultColor: 'black',
    previewText: 'Contrast',
    selectedForAIGenerator: true,
    purpose: ['Carousel', 'Link', 'Embed'],
    sampleUrl: '',
    colors: {
      black: {
        sampleUrl: URLs.APP_URL + 'contrast-black-637983281795147875/1',
        fontColor: { r: 252, g: 250, b: 144, a: 1 },
        backgroundColor: { r: 51, g: 51, b: 51, a: 1 },
      },
      blue: {
        sampleUrl: URLs.APP_URL + 'contrast-blue-637983275580768470/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 108, g: 180, b: 248, a: 1 },
      },

      green: {
        sampleUrl: URLs.APP_URL + 'green-637983261328967835/1',
        fontColor: { r: 55, g: 55, b: 55, a: 1 },
        buttonColor: { r: 55, g: 55, b: 55, a: 1 },
        backgroundColor: { r: 148, g: 208, b: 161, a: 1 },
      },
      navy: {
        sampleUrl: URLs.APP_URL + 'navy-637983280570364707/1',
        fontColor: { r: 125, g: 255, b: 194, a: 1 },
        buttonColor: { r: 125, g: 255, b: 194, a: 1 },
        backgroundColor: { r: 31, g: 51, b: 171, a: 1 },
      },
      purple: {
        sampleUrl: URLs.APP_URL + 'purple-637983276227224065/1',
        fontColor: { r: 253, g: 255, b: 120, a: 1 },
        buttonColor: { r: 253, g: 255, b: 120, a: 1 },
        backgroundColor: { r: 166, g: 157, b: 248, a: 1 },
      },
      yellow: {
        sampleUrl: URLs.APP_URL + 'contrast-lemon-637983277723126805/1',
        fontColor: { r: 123, g: 28, b: 159, a: 1 },
        buttonColor: { r: 123, g: 28, b: 159, a: 1 },
        backgroundColor: { r: 252, g: 250, b: 127, a: 1 },
      },
    },
  },
  {
    name: 'flowers',
    theme: {
      ...initialThemeSettings,

      font: 'Heebo',
      fontColor: { r: 133, g: 54, b: 50, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      cardBackgroundColor: { r: 252, g: 243, b: 238, a: 1 },
      buttonColor: { r: 133, g: 54, b: 50, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 2,
      bodyBackgroundImage: 'brown.svg',
    },
    defaultColor: 'brown',
    previewText: 'Flowers',
    selectedForAIGenerator: true,
    purpose: ['Carousel', 'Link', 'Embed'],
    sampleUrl: '',
    colors: {
      brown: {
        sampleUrl: URLs.APP_URL + 'brown-637982692188500309/1',
        fontColor: { r: 133, g: 54, b: 50, a: 1 },
        backgroundColor: { r: 252, g: 243, b: 238, a: 1 },
      },
      green: {
        sampleUrl: URLs.APP_URL + 'green-637982698244009776/1',
        fontColor: { r: 66, g: 96, b: 66, a: 1 },
        buttonColor: { r: 66, g: 96, b: 66, a: 1 },
        backgroundColor: { r: 249, g: 252, b: 245, a: 1 },
      },

      red: {
        sampleUrl: URLs.APP_URL + 'red-637983528374072234/1',
        fontColor: { r: 93, g: 24, b: 0, a: 1 },
        buttonColor: { r: 93, g: 24, b: 0, a: 1 },
        backgroundColor: { r: 252, g: 240, b: 240, a: 1 },
      },
      orange: {
        sampleUrl: URLs.APP_URL + 'orange-637983085347497173/1',
        fontColor: { r: 230, g: 85, b: 80, a: 1 },
        buttonColor: { r: 230, g: 85, b: 80, a: 1 },
        backgroundColor: { r: 251, g: 246, b: 240, a: 1 },
      },
    },
  },
  {
    name: 'fluid',
    theme: {
      ...initialThemeSettings,

      font: 'Lato',
      fontColor: { r: 41, g: 36, b: 36, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0 },
      buttonColor: { r: 41, g: 36, b: 36, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 2,
      bodyBackgroundImage: 'yellow.svg',
      arrow: 4,
    },
    defaultColor: 'yellow',
    previewText: 'Fluid',
    selectedForAIGenerator: true,
    purpose: ['Carousel', 'Link', 'Embed'],
    sampleUrl: '',
    colors: {
      blue: {
        sampleUrl: URLs.APP_URL + 'gradient-fluid-4-637982661589807132/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 170, g: 174, b: 235, a: 1 },
      },
      gray: {
        sampleUrl: URLs.APP_URL + 'gradient-fluid-3-637982487566999232/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 182, g: 199, b: 225, a: 1 },
      },

      pink: {
        sampleUrl: URLs.APP_URL + 'gradient-fluid-1-637982486599242000/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 240, g: 194, b: 221, a: 1 },
      },
      purple: {
        sampleUrl: URLs.APP_URL + 'purple-637982458612063212/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 234, g: 165, b: 231, a: 1 },
      },
      yellow: {
        sampleUrl: URLs.APP_URL + 'yellow-638153342629201226/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 90, g: 180, b: 200, a: 1 },
        backgroundColor: { r: 239, g: 248, b: 196, a: 1 },
      },
      green: {
        sampleUrl: URLs.APP_URL + 'green-638153328264076752/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 193, g: 240, b: 185, a: 1 },
      },
      lightblue: {
        sampleUrl: URLs.APP_URL + 'blue-638154260233694316/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 10, g: 50, b: 180, a: 1 },
        backgroundColor: { r: 188, g: 230, b: 254, a: 1 },
      },
      orange: {
        sampleUrl: URLs.APP_URL + 'orange-638153340972485445/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 247, g: 189, b: 168, a: 1 },
      },
    },
  },
  {
    name: 'geo',
    theme: {
      ...initialThemeSettings,

      font: 'Heebo',
      fontColor: { r: 0, g: 0, b: 0, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      cardBackgroundColor: { r: 0, g: 0, b: 0, a: 0.2 },
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 2,
      bodyBackgroundImage: 'blue.svg',
      arrow: 3,
    },
    defaultColor: 'blue',
    previewText: 'Geo',
    sampleUrl: '',
    colors: {
      blue: {
        sampleUrl: URLs.APP_URL + 'blue-638053173253948021/1',
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        buttonColor: { r: 0, g: 0, b: 0, a: 1 },
        backgroundColor: { r: 226, g: 241, b: 255, a: 1 },
      },
      gray: {
        sampleUrl: URLs.APP_URL + 'gray-638053172513454490/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 133, g: 135, b: 137, a: 1 },
      },

      green: {
        sampleUrl: URLs.APP_URL + 'green-638053142963330949/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 165, g: 196, b: 142, a: 1 },
      },
      navy: {
        sampleUrl: URLs.APP_URL + 'navy-637982676921433348/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 49, g: 62, b: 111, a: 1 },
      },
      orange: {
        sampleUrl: URLs.APP_URL + 'orange-638094647399014528/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 226, g: 181, b: 129, a: 1 },
      },
      pink: {
        sampleUrl: URLs.APP_URL + 'pink-638094647586238084/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 220, g: 148, b: 180, a: 1 },
      },
      white: {
        sampleUrl: URLs.APP_URL + 'white-638094647519634927/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 194, g: 189, b: 182, a: 1 },
      },
      purple: {
        sampleUrl: URLs.APP_URL + 'purple-638094647549246904/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 163, g: 161, b: 195, a: 1 },
      },
    },
  },
  {
    name: 'landscape',
    theme: {
      ...initialThemeSettings,

      font: 'Barlow',
      fontColor: { r: 9, g: 21, b: 107, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0.9 },
      buttonColor: { r: 93, g: 117, b: 243, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 2,

      bodyBackgroundImage: 'orange.jpg',
      arrow: 4,
    },
    defaultColor: 'orange',
    previewText: 'Landscape',
    sampleUrl: '',
    colors: {
      black: {
        sampleUrl: URLs.APP_URL + 'black-637979695529014039/1',
        bodyBackgroundImage: `${URL.CDN}assets/theme/landscape/black.jpg`,
        fontColor: { r: 9, g: 21, b: 107, a: 1 },
      },
      gray: {
        sampleUrl: URLs.APP_URL + 'gray-637979693266429746/1',
        fontColor: { r: 9, g: 21, b: 107, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/landscape/gray.jpg`,
        backgroundColor: { r: 238, g: 239, b: 239, a: 1 },
      },
      green: {
        sampleUrl: URLs.APP_URL + 'green-637979690321076155/1',
        fontColor: { r: 9, g: 21, b: 107, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/landscape/green.jpg`,
        backgroundColor: { r: 234, g: 235, b: 235, a: 1 },
      },
      orange: {
        sampleUrl: URLs.APP_URL + 'orange-637979702717431330/1',
        fontColor: { r: 9, g: 21, b: 107, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/landscape/orange.jpg`,
        backgroundColor: { r: 205, g: 207, b: 186, a: 1 },
      },
      white: {
        sampleUrl: URLs.APP_URL + 'white-637978086057450386/1',
        fontColor: { r: 9, g: 21, b: 107, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/landscape/white.jpg`,
        backgroundColor: { r: 240, g: 240, b: 240, a: 1 },
      },
      yellow: {
        sampleUrl: URLs.APP_URL + 'yellow-637979687202257048/1',
        fontColor: { r: 9, g: 21, b: 107, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/landscape/yellow.jpg`,
        backgroundColor: { r: 243, g: 240, b: 197, a: 1 },
      },
    },
  },
  {
    name: 'pet',
    theme: {
      ...initialThemeSettings,

      font: 'Heebo',
      fontColor: { r: 41, g: 36, b: 36, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 0 },
      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0.7 },
      buttonColor: { r: 41, g: 36, b: 36, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 2,

      bodyBackgroundImage: 'blue.svg',
      arrow: 2,
    },
    defaultColor: 'blue',
    previewText: 'Pet',
    sampleUrl: '',
    colors: {
      blue: {
        sampleUrl: URLs.APP_URL + 'pet-blue-638001588096856626/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 108, g: 207, b: 234, a: 1 },
      },
      yellow: {
        sampleUrl: URLs.APP_URL + 'pet-yellow-638001601909565155/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 232, g: 231, b: 191, a: 1 },
      },
      green: {
        sampleUrl: URLs.APP_URL + 'pet-green-638001590193779328/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 204, g: 255, b: 212, a: 1 },
      },
      orange: {
        sampleUrl: URLs.APP_URL + 'pet-orange-638094730765478421/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 249, g: 178, b: 107, a: 1 },
      },
      pink: {
        sampleUrl: URLs.APP_URL + 'pet-pink-638094731372238952/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 224, g: 191, b: 232, a: 1 },
      },
      purple: {
        sampleUrl: URLs.APP_URL + 'pet-purple-638001601257425639/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 104, g: 59, b: 147, a: 1 },
      },
      white: {
        sampleUrl: URLs.APP_URL + 'pet-white-638094731883227981/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      },
      navy: {
        sampleUrl: URLs.APP_URL + 'pet-navy-638001599083800083/1',
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 13, g: 39, b: 48, a: 1 },
      },
    },
  },
  {
    name: 'rainbow',
    theme: {
      ...initialThemeSettings,

      font: 'Nunito Sans',
      fontColor: { r: 41, g: 36, b: 36, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 0 },
      bodyBackgroundImage:
        URL.CDN+'emir637831896232284552/6d831dfb-8d65-45a3-aae1-b287ae3751b9.jpg',
      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0.8 },
      buttonColor: { r: 41, g: 36, b: 36, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 1,
      bodyBackgroundImage: 'blue.jpg',
    },
    selectedForAIGenerator: true,
    purpose: ['Carousel'],
    defaultColor: 'blue',
    previewText: 'Rainbow',
    sampleUrl: '',
    colors: {
      blue: {
        sampleUrl: URLs.APP_URL + 'blue-637983509034932151/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/rainbow/blue.jpg`,
        backgroundColor: { r: 247, g: 244, b: 239, a: 1 },
      },

      green: {
        sampleUrl: URLs.APP_URL + 'green-637983525410113077/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/rainbow/green.jpg`,
        backgroundColor: { r: 126, g: 255, b: 248, a: 1 },
      },
      orange: {
        sampleUrl: URLs.APP_URL + 'orange-637983488301445338/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/rainbow/orange.jpg`,
        backgroundColor: { r: 247, g: 244, b: 239, a: 0 },
      },
      pink: {
        sampleUrl: URLs.APP_URL + 'pink-637983503286256640/1',
        fontColor: { r: 41, g: 36, b: 36, a: 1 },
        buttonColor: { r: 41, g: 36, b: 36, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/rainbow/pink.jpg`,
        backgroundColor: { r: 247, g: 244, b: 239, a: 1 },
      },
      red: {
        sampleUrl: URLs.APP_URL + 'red-637983504334399178/1',
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/rainbow/red.jpg`,
        backgroundColor: { r: 245, g: 30, b: 79, a: 1 },
      },
    },
  },
  {
    name: 'watercolor',
    theme: {
      ...initialThemeSettings,

      font: 'Manrope',
      fontColor: { r: 6, g: 86, b: 140, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage:
      URL.CDN+'emir637831896232284552/6c494d1d-2884-4b1b-b95d-f610d0c316f5.png',
      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0 },
      buttonColor: { r: 6, g: 86, b: 140, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 3,
      bodyBackgroundImage: 'blue.jpg',
      arrow: 4,
    },
    defaultColor: 'blue',
    previewText: 'Watercolor',
    selectedForAIGenerator: true,
    purpose: ['Link', 'Embed'],
    sampleUrl: '',
    colors: {
      blue: {
        sampleUrl: URLs.APP_URL + 'blue-638053138204761539/1',
        fontColor: { r: 6, g: 86, b: 140, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/watercolor/blue.jpg`,
      },

      brown: {
        sampleUrl: URLs.APP_URL + 'brown-638053070307353635/1',
        fontColor: { r: 6, g: 86, b: 140, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/watercolor/brown.jpg`,
        backgroundColor: { r: 244, g: 239, b: 235, a: 1 },
      },
      green: {
        sampleUrl: URLs.APP_URL + 'green-638053087736187398/1',
        fontColor: { r: 6, g: 86, b: 140, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/watercolor/green.jpg`,
        backgroundColor: { r: 248, g: 245, b: 240, a: 1 },
      },
      orange: {
        sampleUrl: URLs.APP_URL + 'orange-638053076688805850/1',
        fontColor: { r: 6, g: 86, b: 140, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/watercolor/orange.jpg`,
        backgroundColor: { r: 254, g: 238, b: 225, a: 1 },
      },
      pink: {
        sampleUrl: URLs.APP_URL + 'pink-638053088644311973/1',
        fontColor: { r: 6, g: 86, b: 140, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/watercolor/pink.jpg`,
        backgroundColor: { r: 253, g: 234, b: 236, a: 1 },
      },
      turquoise: {
        sampleUrl: URLs.APP_URL + 'turquoise-637986108174438997/1',
        fontColor: { r: 6, g: 86, b: 140, a: 1 },
        bodyBackgroundImage: `${URL.CDN}assets/theme/watercolor/turquoise.jpg`,
        backgroundColor: { r: 229, g: 255, b: 224, a: 1 },
      },
    },
  },
  {
    name: 'waveline',
    theme: {
      ...initialThemeSettings,

      font: 'Source Sans Pro',
      fontColor: { r: 0, g: 48, b: 65, a: 1 },
      fontSize: 'M',
      lineHeight: 'M',
      backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage:
      URL.CDN+'emir637831896232284552/03569ead-aa55-491f-ad1d-e235343e0b9a.jpg',
      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0.3 },
      buttonColor: { r: 0, g: 48, b: 65, a: 1 },
      cardAlignment: 'center',
      stretch: 2,
      layout: 1,
      bodyBackgroundImage: 'blue.svg',
      arrow: 3,
    },
    selectedForAIGenerator: true,
    purpose: ['Carousel'],
    defaultColor: 'blue',
    previewText: 'Waveline',
    sampleUrl: '',
    colors: {
      blue: {
        sampleUrl: URLs.APP_URL + 'blue-637982675513217072/1',
        fontColor: { r: 0, g: 48, b: 65, a: 1 },
        backgroundColor: { r: 115, g: 204, b: 214, a: 1 },
      },
      green: {
        sampleUrl: URLs.APP_URL + 'green-638094883530538378/1',
        fontColor: { r: 0, g: 48, b: 65, a: 1 },
        backgroundColor: { r: 220, g: 255, b: 234, a: 1 },
      },
      orange: {
        sampleUrl: URLs.APP_URL + 'orange-637982673924322822/1',
        fontColor: { r: 0, g: 48, b: 65, a: 1 },
        backgroundColor: { r: 244, g: 218, b: 178, a: 1 },
      },
      pink: {
        sampleUrl: URLs.APP_URL + 'pink-637982672814922713/1',
        fontColor: { r: 0, g: 48, b: 65, a: 1 },
        backgroundColor: { r: 249, g: 165, b: 153, a: 1 },
      },
      white: {
        sampleUrl: URLs.APP_URL + 'white-638094883687912029/1',
        fontColor: { r: 0, g: 48, b: 65, a: 1 },
        backgroundColor: { r: 235, g: 231, b: 224, a: 1 },
      },
      yellow: {
        sampleUrl: URLs.APP_URL + 'yellow-637982665580603842/1',
        fontColor: { r: 0, g: 48, b: 65, a: 1 },
        backgroundColor: { r: 246, g: 233, b: 175, a: 1 },
      },
    },
  },
  {
    name: 'shiny',
    theme: {
      ...initialThemeSettings,
      font: 'Poppins',
      fontSize: 'M',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage: `cadetblue.svg`,
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
    },
    previewText: 'Shiny',
    defaultColor: 'cadetblue',
    sampleUrl: '',
    colors: {
      cadetblue: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 26, g: 124, b: 151, a: 1 },
        sampleUrl: URLs.APP_URL + '2P-srK2xhkCJM7k0B5QTnw/1',
      },
      green: {
        backgroundColor: { r: 144, g: 180, b: 152, a: 1 },

        sampleUrl: URLs.APP_URL + 'uVq6FZTYQU-rapSpnIbFjQ/1',
      },
      grey: {
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        buttonColor: { r: 39, g: 39, b: 39, a: 1 },
        backgroundColor: { r: 176, g: 176, b: 178, a: 1 },
        sampleUrl: URLs.APP_URL + 'fikuwID6xE2FgwSu9R4TDw/1',
      },
      orange: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 255, g: 184, b: 72, a: 1 },
        sampleUrl: URLs.APP_URL + '1GKwy7dxAEKN9jf_pjnOHQ/1',
      },
      purple: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 154, g: 58, b: 153, a: 1 },

        sampleUrl: URLs.APP_URL + 'PPDoHzt6kkeLBkXfwa_aPw/1',
      },
      lightpink: {
        fontColor: { r: 108, g: 26, b: 0, a: 1 },
        buttonColor: { r: 108, g: 26, b: 0, a: 1 },
        backgroundColor: { r: 255, g: 196, b: 205, a: 1 },
        sampleUrl: URLs.APP_URL + 'cRaYYLB5jEqUo4B5nXoIUg/1',
      },
    },
  },
  {
    name: 'wave',
    theme: {
      ...initialThemeSettings,
      font: 'Inter',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage: `green.svg`,
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      layout: 3,
      arrow: 2,
    },
    previewText: 'Wave',
    defaultColor: 'green',
    sampleUrl: '',
    colors: {
      green: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 59, g: 154, b: 60, a: 1 },
        sampleUrl: URLs.APP_URL + 'MpvyzlDyvkOQAbRGK1OUCQ/1',
      },
      sandybrown: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 246, g: 182, b: 129, a: 1 },
        sampleUrl: URLs.APP_URL + 'tA65te0yiEOFLn0nBmNSIQ/1',
      },
      lightblue: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 76, g: 127, b: 160, a: 1 },

        sampleUrl: URLs.APP_URL + 'IuhvBksztU-3tyKgYU5d2g/1',
      },
      orange: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 244, g: 162, b: 97, a: 1 },

        sampleUrl: URLs.APP_URL + 'uK3gOiRCok63I145VIIx7w/1',
      },
    },
  },
  {
    name: 'circles',
    theme: {
      ...initialThemeSettings,
      font: 'Roboto',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage: `blue.svg`,
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      layout: 3,
      arrow: 3,
    },
    previewText: 'Circles',
    defaultColor: 'blue',
    sampleUrl: '',
    colors: {
      blue: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 66, g: 165, b: 245, a: 1 },
        sampleUrl: URLs.APP_URL + 'Hr5tHJJ8sUWTaECBjMhzpw/1',
      },
      lightgoldenrodyellow: {
        fontColor: { r: 139, g: 83, b: 0, a: 1 },
        buttonColor: { r: 139, g: 83, b: 0, a: 1 },
        backgroundColor: { r: 243, g: 223, b: 193, a: 1 },
        sampleUrl: URLs.APP_URL + 'RU1alE5QM02C6vaULo-xSQ/1',
      },
      darkBlue: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 14, g: 42, b: 71, a: 1 },
        sampleUrl: URLs.APP_URL + 'P07O6JkdREuUt2wedIpQCw/1',
      },
      green: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },

        backgroundColor: { r: 59, g: 154, b: 60, a: 1 },

        sampleUrl: URLs.APP_URL + '0EUPA7niP0yakuu9nJOsrA/1',
      },
      orange: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 255, g: 136, b: 1, a: 1 },

        sampleUrl: URLs.APP_URL + 'KwaoFdOEN0WKSGK2D_cU9Q/1',
      },
      pink: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 247, g: 164, b: 153, a: 1 },

        sampleUrl: URLs.APP_URL + 'KCOWM0ZH00qZsqgQWZ6iqg/1',
      },
    },
  },
  {
    name: 'curveLine',
    theme: {
      ...initialThemeSettings,
      font: 'Poppins',
      fontSize: 'M',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage: `blue.svg`,
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      arrow: 4,
    },
    previewText: 'Curve Line',
    defaultColor: 'blue',
    sampleUrl: '',
    colors: {
      blue: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 33, g: 158, b: 158, a: 1 },

        sampleUrl: URLs.APP_URL + 'QZg42MUqN0CU0Z9EXnzKSQ/1',
      },
      goldenrod: {
        fontColor: { r: 74, g: 53, b: 0, a: 1 },
        buttonColor: { r: 74, g: 53, b: 0, a: 1 },
        backgroundColor: { r: 233, g: 196, b: 107, a: 1 },

        sampleUrl: URLs.APP_URL + 'BwS9fs0w4kWPVUoes-zOUg/1',
      },
      darkBlue: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 38, g: 70, b: 83, a: 1 },

        sampleUrl: URLs.APP_URL + 'xwkEysuXHkiv6ifu-g1zZw/1',
      },
      orange: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 244, g: 162, b: 97, a: 1 },

        sampleUrl: URLs.APP_URL + 'P1VQWXfwOUK8m-MsxOxNPA/1',
      },
    },
  },
  {
    name: 'rectLight',
    theme: {
      ...initialThemeSettings,
      font: 'Poppins',
      fontSize: 'M',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage: `blue.svg`,
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
    },
    previewText: 'Rectangle Light',
    defaultColor: 'blue',
    sampleUrl: '',
    colors: {
      blue: {
        fontColor: { r: 0, g: 34, b: 83, a: 1 },
        buttonColor: { r: 49, g: 123, b: 247, a: 1 },
        sampleUrl: URLs.APP_URL + 'aaHGDEIhaE6S4iDMu4t9zA/1',
        backgroundColor: { r: 185, g: 220, b: 255, a: 1 },
      },
      brown: {
        fontColor: { r: 222, g: 244, b: 255, a: 1 },
        buttonColor: { r: 222, g: 244, b: 255, a: 1 },
        backgroundColor: { r: 91, g: 81, b: 81, a: 1 },

        sampleUrl: URLs.APP_URL + 'xTpN6d-E5EqMtTP_SVZCSg/1',
      },
      darkturquoise: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 86, g: 123, b: 131, a: 1 },

        sampleUrl: URLs.APP_URL + 'xD4f116CwkGBKgQG4X-6mA/1',
      },
      green: {
        fontColor: { r: 3, g: 61, b: 16, a: 1 },
        buttonColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 3, g: 61, b: 16, a: 1 },

        sampleUrl: URLs.APP_URL + 'II806O3UU0egPRKnfTd3vw/1',
      },
      turquoise: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 57, g: 174, b: 169, a: 1 },
        sampleUrl: URLs.APP_URL + '7ITAR4d0sk2DmckxTA_F5Q/1',
      },
      yellow: {
        fontColor: { r: 55, g: 55, b: 55, a: 1 },
        buttonColor: { r: 55, g: 55, b: 55, a: 1 },
        backgroundColor: { r: 228, g: 238, b: 190, a: 1 },

        sampleUrl: URLs.APP_URL + 'ZfMi9blIpE2t8gWgy1O88Q/1',
      },
    },
  },
  {
    name: 'shinyOverlay',
    theme: {
      ...initialThemeSettings,
      fontSize: 'XS',
      font: 'Roboto',
      fontColor: { r: 0, g: 0, b: 0, a: 1 },
      bodyBackgroundImage: `blue.svg`,
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
      cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0.5 },
      layout: 1,
      cardAlignment: 'center',
      stretch: 1,
      arrow: 2,
    },
    previewText: 'Overlay',
    defaultColor: 'blue',
    sampleUrl: '',
    colors: {
      blue: {
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        backgroundColor: { r: 8, g: 98, b: 126, a: 1 },

        sampleUrl: URLs.APP_URL + 'sSzLxoA45E6wb6jtsppG5Q/1',
      },
      green: {
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        backgroundColor: { r: 36, g: 126, b: 31, a: 1 },

        sampleUrl: URLs.APP_URL + '8RxUmwbSPkKBI4e5mZWn8A/1',
      },
      olive: {
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        backgroundColor: { r: 102, g: 107, b: 39, a: 1 },

        sampleUrl: URLs.APP_URL + 'Dv6yuWM0q0W3GBHuxNy3-g/1',
      },
      darkblue: {
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        backgroundColor: { r: 6, g: 55, b: 116, a: 1 },

        sampleUrl: URLs.APP_URL + 'uBW_7wzIxkGWgWpbKCG5zA/1',
      },
      purple: {
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        backgroundColor: { r: 97, g: 23, b: 102, a: 1 },

        sampleUrl: URLs.APP_URL + 'QHx6E0Xb2Uyxa4W02N1woA/1',
      },
      red: {
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        backgroundColor: { r: 112, g: 11, b: 22, a: 1 },

        sampleUrl: URLs.APP_URL + 'Ibechwga6kaU3sSNbfIGEA/1',
      },
    },
  },
  {
    name: 'moon',
    theme: {
      ...initialThemeSettings,
      font: 'Inter',
      fontSize: 'XS',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      buttonColor: { r: 168, g: 121, b: 189, a: 1 },
      bodyBackgroundImage: `purple.svg`,
      cardBackground: '#f2f2f2',
      cardBackgroundColor: { r: 57, g: 56, b: 109, a: 0.7 },
      layout: 1,
      cardAlignment: 'center',
      stretch: 2,
    },
    previewText: 'Moon',
    defaultColor: 'purple',
    sampleUrl: '',
    colors: {
      purple: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        sampleUrl: URLs.APP_URL + 'Up0Eef15nkuIBv2ng_KTzg/1',
        backgroundColor: { r: 50, g: 50, b: 93, a: 1 },
      },
    },
  },
  {
    name: 'sprinkle',
    theme: {
      ...initialThemeSettings,
      font: 'Roboto',
      fontColor: { r: 255, g: 255, b: 255, a: 1 },
      bodyBackgroundImage: `blue.svg`,
      buttonColor: { r: 255, g: 255, b: 255, a: 1 },
    },
    defaultColor: 'blue',
    previewText: 'Sprinkle',
    sampleUrl: '',
    colors: {
      blue: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 0, g: 129, b: 167, a: 1 },

        sampleUrl: URLs.APP_URL + 'jvapmfC7SkG3LndimzdS_w/1',
      },
      orange: {
        fontColor: { r: 255, g: 255, b: 255, a: 1 },
        backgroundColor: { r: 244, g: 140, b: 6, a: 1 },

        sampleUrl: URLs.APP_URL + 'mQVcMpweQ0iopEStrXlhOQ/1',
      },
      pink: {
        fontColor: { r: 79, g: 25, b: 37, a: 1 },
        buttonColor: { r: 79, g: 25, b: 37, a: 1 },

        backgroundColor: { r: 247, g: 209, b: 205, a: 1 },

        sampleUrl: URLs.APP_URL + 'r72SmQ3RikagjFUF4k9WDQ/1',
      },
      purple: {
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        backgroundColor: { r: 224, g: 170, b: 255, a: 1 },

        sampleUrl: URLs.APP_URL + '8GqN7SQfaE-aSTMgYPUtuA/1',
      },
    },
  },
]

export default themePresets
