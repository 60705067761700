const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: 16,
    marginTop: 16,
    paddingBottom: 16,
  },
  teamListRoot: {
    height: 'fit-content',
    width: '100%',
    //backgroundColor: '#F4F6F8',
    padding: '0 16px',
    borderRadius: '16px',
    marginBottom: 16,
  },
})

export default styles
