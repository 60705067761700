import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import style from '../style'
import translator from '../../../../../translate'
import { ButtonType } from '../../../../../model/button'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { MButton } from 'src/theme'
import { withStyles, Menu, MenuItem, Link } from '@material-ui/core'
import { guid } from 'src/utils/guid'

/**
 *
 * @param {*} props
 */
const ListContainerColumnAction = props => {
  const { classes, column, entity, dispatch, refresh } = props
  const [anchorEl, setAnchorEl] = useState(null)
  let history = useHistory()

  /**
   *
   * @param {*} exec
   */
  const menuItemClickHandler = exec => {
    exec(entity, dispatch, refresh, history)

    handleClose()
  }

  const menuItemLinkClickHandler = link => {
    history.push(link)
  }

  /**
   *
   * @param {*} event
   */
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  /**
   *
   * @param {*} event
   */
  const handleClose = event => {
    setAnchorEl(null)
  }

  /**
   *
   * @param {*} action
   * @param {*} index
   */
  const renderMenuItem = action => {
    if (!action.hidden(entity)) {
      return (
        <MenuItem
          key={guid().uid()}
          onClick={() => {
            menuItemClickHandler(action.exec)
          }}
          disabled={action.disabled(entity)}
        >
          {action.label}
        </MenuItem>
      )
    }
  }

  /**
   *
   * @param {*} action
   * @param {*} index
   */
  const renderLink = action => {
    return (
      <MenuItem
        key={guid().uid()}
        component={Link}
        onClick={() => {
          menuItemLinkClickHandler(action.link(entity, dispatch, refresh))
        }}
        disabled={action.disabled(entity)}
      >
        {action.label}
      </MenuItem>
    )
  }

  return (
    <React.Fragment>
      {column.actions.map((action, index) => {
        if (action.icon) {
          let ButtonIcon = action.icon
          return (
            <MButton
              className={classes.buttonWithIcon}
              variant="outlined"
              color="inherit"
              endIcon={<ButtonIcon className={classes.actionIcon} />}
              key={guid().uid()}
              onClick={() => {
                action.exec(entity, dispatch, refresh, history)
              }}
            >
              {action.label}
            </MButton>
          )
        }
        return ''
      })}
      <MButton
        className={classes.actionButton}
        variant="outlined"
        color="inherit"
        endIcon={<KeyboardArrowDownIcon color="inherit" />}
        onClick={handleClick}
      >
        {translator._('general.actions')}
      </MButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {column.actions.map(action => {
          let element = ''
          switch (action.type) {
            case ButtonType.NORMAL:
              element = renderMenuItem(action)
              break
            case ButtonType.LINK:
              element = action.icon ? undefined : renderLink(action)
              break
            default:
              element = undefined
              break
          }

          return element
        })}
      </Menu>
    </React.Fragment>
  )
}

export default withStyles(style)(ListContainerColumnAction)
