import React from 'react'
import style from './style'
import propTypes from './prop'
import ListContainerColumnAction from './action'
import ListItemTag from './list-item-tag'
import ListItemField from './list-item-field'
import { assignmentGetAction } from 'src/reducers/assignment/action'
import { useHistory } from 'react-router-dom'
import { guid } from 'src/utils/guid'
import { ListApplicationType, ListColumnType } from '../../../../model/list'
import { Box, withStyles, Grid, Typography } from '@material-ui/core'

const DoubleLineBody = props => {
  const {
    classes,
    queryResult,
    row,
    dispatch,
    refresh,
    showRowAction,
    applicationType,
  } = props

  const history = useHistory()

  const handleNameClick = item => {
    if (applicationType === ListApplicationType.ASSIGNMENT) {
      dispatch(
        assignmentGetAction(item.id, () => {
          history.push('/mini-course/' + item.id)
        }),
      )
    }
  }

  return (
    <div className={classes.listContainer}>
      {queryResult.results.map(entity => {
        return (
          <Box key={guid().uid()}>
            <Grid container className={classes.listItem} sx={{ boxShadow: 1 }}>
              <Grid
                item
                xs={12}
                className={classes.headerRow}
                sx={{ boxShadow: 1 }}
              >
                <Typography
                  variant="h6"
                  className={classes.headerClickable}
                  onClick={() => handleNameClick(entity)}
                >
                  {entity[row.columns[0].attribute]}

                  {row.columns.filter(
                    x => x.type === ListColumnType.TAG && x.hidden !== true,
                  ).length > 0 &&
                    row.columns
                      .filter(x => x.type === ListColumnType.TAG)
                      .map(column => {
                        return column.tags.map(tag => {
                          return (
                            <ListItemTag
                              entity={entity}
                              tag={tag}
                              key={guid().uid()}
                              classes={classes}
                            />
                          )
                        })
                      })}
                </Typography>
                <div className={classes.buttonGroup}>
                  {row.columns.map(column => {
                    let renderer = undefined
                    if (typeof column.hidden === 'function') {
                      let hidden = column.hidden(entity)
                      if (hidden === true) {
                        return null
                      }
                    } else if (column.hidden === true) {
                      return null
                    }
                    if (!column.renderer) {
                      if (column.type == ListColumnType.ACTION) {
                        if (showRowAction !== false) {
                          renderer = (
                            <ListContainerColumnAction
                              key={guid().uid()}
                              column={column}
                              entity={entity}
                              dispatch={dispatch}
                              refresh={refresh}
                            />
                          )
                        }
                      }
                    }
                    return renderer
                  })}
                </div>
              </Grid>
              {/* {row.columns.filter(x => x.type === ListColumnType.TAG).length >
                0 && (
                <Grid item xs={12} className={classes.tagGroup}>
                  {row.columns
                    .filter(x => x.type === ListColumnType.TAG)
                    .map(column => {
                      return column.tags.map(tag => {
                        return (
                          <ListItemTag
                            entity={entity}
                            tag={tag}
                            key={guid().uid()}
                            classes={classes}
                          />
                        )
                      })
                    })}
                </Grid>
              )} */}
              {row.columns.filter(x => x.type === ListColumnType.STRING)
                .length > 1 && (
                <Grid item xs={12}>
                  <Grid container>
                    {row.columns.map((column, index) => {
                      if (column.type === ListColumnType.STRING) {
                        let FieldIcon = column.icon ? column.icon : null
                        if (index > 0) {
                          return (
                            <ListItemField
                              FieldIcon={FieldIcon}
                              index={index}
                              classes={classes}
                              entity={entity}
                              column={column}
                              key={guid().uid()}
                            />
                          )
                        }
                      }
                    })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        )
      })}
    </div>
  )
}

DoubleLineBody.propTypes = propTypes

export default withStyles(style)(DoubleLineBody)
