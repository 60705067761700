import React from 'react'
import style from './style'
import propTypes from './prop'
import { withStyles, Grid, Button } from '@material-ui/core'
import translator from '../../../../translate'
import { FormStateModeType } from '../../../../form/props'

/**
 *
 * @param {*} props
 */
const Footer = props => {
  const { classes, stateMode, save, put, cancel } = props

  let cancelButtonView = () => {
    if (cancel != undefined) {
      return (
        <Button
          onClick={cancel}
          color="inherit"
          variant="contained"
          className={classes.cancel}
        >
          {translator._('action.cancel')}
        </Button>
      )
    }
  }

  return (
    <Grid className={classes.root} container alignItems="center">
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          {cancelButtonView()}

          <Button
            onClick={
              stateMode === FormStateModeType.UPDATE && !!put ? put : save
            }
            color="primary"
            variant="contained"
          >
            {translator._('action.save')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

Footer.propTypes = propTypes

export default withStyles(style)(Footer)
