import { put, call, take, fork } from 'redux-saga/effects'
import { assignmentDeleteService } from '../../api/assignment'

import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'
import { assignmentDeleteSuccess } from 'src/reducers/course-collection/action'
import { ASSIGNMENT_DELETE } from 'src/reducers/course-collection/type'

/**
 *
 * @param {*} payload
 */
function* assignmentDeleteFlow(payload) {
  try {
    const { id, callback } = payload

    yield call(assignmentDeleteService, id)
    yield put(assignmentDeleteSuccess(id))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* assignmentDeleteWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_DELETE)
    const { payload } = action

    yield fork(generalSaga, assignmentDeleteFlow, payload)
  }
}
