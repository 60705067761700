import { put, call, take, fork } from 'redux-saga/effects'
import { questionListMakePassiveService } from '../../api/question'
import { questionListMakePassiveSuccess } from '../../reducers/question/action'
import { QUESTION_LIST_MAKE_PASSIVE } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionListMakePassiveFlow(payload) {
  try {
    const { ids, callback } = payload

    yield call(questionListMakePassiveService, ids)
    yield put(questionListMakePassiveSuccess(ids))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionListMakePassiveWatcher() {
  while (true) {
    const action = yield take(QUESTION_LIST_MAKE_PASSIVE)
    const { payload } = action

    yield fork(generalSaga, questionListMakePassiveFlow, payload)
  }
}
