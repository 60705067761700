import PropTypes from 'prop-types'
import { FormItemPropTypes } from '../form-item/default.js'

const FormItemTextAreaPropTypes = {
  ...FormItemPropTypes,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  placeholder: PropTypes.string,
}

export default FormItemTextAreaPropTypes
