import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_IMAGE_UPLOAD } from '../../reducers/media-image/type'
import { mediaImageUploadService } from '../../api/media-image'
import { mediaImageUploadSuccess } from '../../reducers/media-image/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaImageUploadFlow(payload) {
  try {
    const { parentFolderId, file, callback } = payload

    var formData = new FormData()

    formData.append('file', file)
    if (parentFolderId) {
      formData.set('parentFolderId', parentFolderId)
    }

    const response = yield call(mediaImageUploadService, formData)

    yield put(mediaImageUploadSuccess(response.data))
    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaImageUploadWatcher() {
  while (true) {
    const action = yield take(MEDIA_IMAGE_UPLOAD)
    const { payload } = action

    yield fork(generalSaga, mediaImageUploadFlow, payload)
  }
}
