import {
  MEDIA_DIRECTORY_GET_ROOT,
  MEDIA_DIRECTORY_GET_ROOT_SUCCESS,
  MEDIA_DIRECTORY_GET_ROOT_ERROR,
  MEDIA_DIRECTORY_GET,
  MEDIA_DIRECTORY_GET_SUCCESS,
  MEDIA_DIRECTORY_GET_ERROR,
} from './type'

/**
 *
 */
export const mediaDirectoryGetRoot = filterType => {
  return {
    type: MEDIA_DIRECTORY_GET_ROOT,
    payload: { filterType },
  }
}

/**
 *
 * @param {*} entity
 */
export const mediaDirectoryGetRootSuccess = entity => {
  return {
    type: MEDIA_DIRECTORY_GET_ROOT_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const mediaDirectoryGetRootError = () => {
  return {
    type: MEDIA_DIRECTORY_GET_ROOT_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const mediaDirectoryGet = request => {
  return {
    type: MEDIA_DIRECTORY_GET,
    payload: { request },
  }
}

/**
 *
 * @param {*} entity
 */
export const mediaDirectoryGetSuccess = entity => {
  return {
    type: MEDIA_DIRECTORY_GET_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const mediaDirectoryGetError = () => {
  return {
    type: MEDIA_DIRECTORY_GET_ERROR,
  }
}
