import { put, call, take, fork } from 'redux-saga/effects'
import { openaiSendCourseCreationRequestService } from '../../api/assignment'
import { OPENAI_SEND_COURSE_CREATION_REQUEST } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* openaiSendCourseCreationRequestFlow(payload) {
  try {
    const {
      title,
      outlines,
      profession,
      audience,
      description,
      themeSettings,
      courseCollectionId,
      variables,
      gatewaySelection,
      language,
      purpose,
      selectedPreset,
      callback,
    } = payload

    const response = yield call(
      openaiSendCourseCreationRequestService,
      title,
      outlines,
      profession,
      audience,
      description,
      themeSettings,
      courseCollectionId,
      variables,
      gatewaySelection,
      language,
      purpose,
      selectedPreset,
    )

    yield put(operationSuccess())

    callback(response)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* openaiSendCourseCreationRequestWatcher() {
  while (true) {
    const action = yield take(OPENAI_SEND_COURSE_CREATION_REQUEST)
    const { payload } = action

    yield fork(generalSaga, openaiSendCourseCreationRequestFlow, payload, false)
  }
}
