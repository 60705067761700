import React from 'react'
import style from './style'
import propTypes from './prop'
import QuestionItem from './components/item'
import { withStyles, Grid } from '@material-ui/core'
import SortableList, { SortableItem } from 'react-easy-sort'
import { AssignmentFieldNames } from '../../model/assignment'

import {
  QuestionFieldNames,
  isAnswerItemTypeResultCard,
} from '../../model/question'
import {
  assignmentUpdateAttribute,
  assignmentEditAction,
} from 'src/reducers/assignment/action'
import { questionAddAction } from '../../reducers/question/action'
import { getQuestionTypeParam, QuestionSchema } from 'src/model/question'
import { startConnection } from 'src/utils/signalR-connection/signalR-connection'

import CourseNavbar from './components/course-navbar'
import EmptyListContent from './components/empty-list-content'
import LastCardAddingField from './components/last-card-adding-field'
import PasswordCardAddingField from './components/password-card-adding-field'
const QuestionList = props => {
  const {
    dispatch,
    list,
    entity,
    onAdd,
    onMove,
    onRemove,
    content,
    showSortOrder,
    refresh,
    onDragEnd,
    classes,
    courseId,
    courseCode,
    baseEntity,
    onAddCard,
    page,
    setPage,
    listingType,
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [cachedListLength, setCachedListLength] = React.useState(0)
  const [lastClickTime, setLastClickTime] = React.useState(Date.now())

  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ])

  const isLastCardFinishCard =
    (baseEntity &&
      baseEntity[AssignmentFieldNames.QUESTION_DTOS].length > 0 &&
      baseEntity.cardDtos[baseEntity.cardDtos.length - 1].type == 13) ||
    (baseEntity &&
      baseEntity[AssignmentFieldNames.QUESTION_DTOS].length > 0 &&
      isAnswerItemTypeResultCard(
        baseEntity.cardDtos[baseEntity.cardDtos.length - 1].type,
      ))

  const isFirstCardPasswordCard =
    baseEntity &&
    baseEntity[AssignmentFieldNames.QUESTION_DTOS].length > 0 &&
    baseEntity.cardDtos[0].type == 15

  const [editor, setEditor] = React.useState(null)

  React.useEffect(() => {
    //Start socket connection for media update
    startConnection()
  }, [])

  React.useLayoutEffect(() => {
    const updateSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  React.useEffect(() => {
    if (list && list.length > 0) {
      if (cachedListLength != 0) {
        const hasHaveFinishCard =
          list[list.length - 2] &&
          list[list.length - 2][QuestionFieldNames.QUESTION_TYPE] >= 12 &&
          list[list.length - 2][QuestionFieldNames.QUESTION_TYPE] <= 15

        if (listingType != 'question_add') {
          setPage(Math.min(1, list.length - 1))
        } else {
          setPage(Math.max(1, list.length))
        }
      }

      setCachedListLength(list.length)
    }
  }, [list.length])

  React.useEffect(() => {
    setEditor()
  }, [page])

  const handleChange = (editor, value) => {
    setPage(value)
    setLastClickTime(Date.now())
    setEditor(editor)
  }

  return (
    <div className={classes.cardListView}>
      <div className={classes.cardListContainer}>
        <div className={classes.cardListHolder}>
          <CourseNavbar entity={entity} dispatch={dispatch} />
          <div>
            <SortableList
              className="list"
              draggedItemClassName="dragged"
              allowDrag={false}
            >
              {entity && (
                <Grid container className={classes.sortableListGrid}>
                  {list.length > 0 ? (
                    <>
                      {!isFirstCardPasswordCard && (
                        <PasswordCardAddingField
                          onAddCard={onAddCard}
                          list={list}
                          dispatch={dispatch}
                          entity={entity}
                          courseId={courseId}
                        />
                      )}

                      {list.map((item, index) => (
                        <SortableItem key={'Sortable' + item.id}>
                          <div
                            className={classes.front}
                            key={'QuestionItemHolder' + index}
                            id={'question-' + index}
                          >
                            <QuestionItem
                              dispatch={dispatch}
                              entity={item}
                              index={index}
                              id={index}
                              onMove={onMove}
                              onClick={editor => {
                                handleChange(editor, index + 1)
                              }}
                              cardCount={list?.length}
                              onDrag={(prev, next) => {
                                onDragEnd(prev, next)
                                handleChange(null, next + 1)
                              }}
                              onRemove={onRemove}
                              refresh={refresh}
                              showSortOrder={showSortOrder}
                              courseId={courseId}
                              baseEntity={baseEntity}
                              onAddCard={onAddCard}
                              page={page}
                              setPage={setPage}
                              lastCardFinish={
                                list[list.length - 1][
                                  QuestionFieldNames.QUESTION_TYPE
                                ] >= 12 &&
                                list[list.length - 1][
                                  QuestionFieldNames.QUESTION_TYPE
                                ] < 17
                              }
                            />
                          </div>
                        </SortableItem>
                      ))}
                      {!isLastCardFinishCard && (
                        <LastCardAddingField
                          setPage={setPage}
                          list={list}
                          courseId={courseId}
                          onAddCard={onAddCard}
                          dispatch={dispatch}
                        />
                      )}
                    </>
                  ) : (
                    <EmptyListContent
                      onAddCard={onAddCard}
                      list={list}
                      dispatch={dispatch}
                      entity={entity}
                      courseId={courseId}
                    />
                  )}
                </Grid>
              )}
            </SortableList>
          </div>
        </div>
      </div>
    </div>
  )
}

QuestionList.propTypes = propTypes

export default withStyles(style)(QuestionList)
