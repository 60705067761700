import React, { useState } from 'react'
import styles from '../../style'
import {
  Button,
  withStyles,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import UserType, { UserTypeNames } from 'src/model/user-types'

const ExportButton = props => {
  const {
    handleRedirecPricing,
    handleExportExcelCourse,
    handleExportTxtCourse,
    classes,
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        color="inherit"
        className={classes.previewBtn}
        size="small"
      >
        <Icon
          icon="uil:export"
          style={{ marginTop: '3px', marginRight: '5px' }}
          width="16"
          height="16"
        />
        <Typography className={classes.buttonTextes}>Export</Typography>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            UserType[window.CONSTANTS.USER_ROLE_ID] ===
            UserTypeNames.REGULAR_ADMIN_ACCOUNT
              ? handleRedirecPricing()
              : handleExportExcelCourse()
          }
        >
          {UserType[window.CONSTANTS.USER_ROLE_ID] ===
            UserTypeNames.REGULAR_ADMIN_ACCOUNT && (
            <Icon
              icon={'fa6-solid:crown'}
              style={{ marginTop: '3px', marginRight: '5px' }}
              color="#f7b155"
              width="16"
              height="16"
            />
          )}
          <Typography style={{ fontSize: 12 }}>Export As Excel</Typography>
        </MenuItem>
        <MenuItem
          onClick={() =>
            UserType[window.CONSTANTS.USER_ROLE_ID] ===
            UserTypeNames.REGULAR_ADMIN_ACCOUNT
              ? handleRedirecPricing()
              : handleExportTxtCourse()
          }
        >
          {UserType[window.CONSTANTS.USER_ROLE_ID] ===
            UserTypeNames.REGULAR_ADMIN_ACCOUNT && (
            <Icon
              icon={'fa6-solid:crown'}
              style={{ marginTop: '3px', marginRight: '5px' }}
              color="#f7b155"
              width="16"
              height="16"
            />
          )}
          <Typography style={{ fontSize: 12 }}>
            Export As AIKEN Format
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}
export default withStyles(styles)(ExportButton)
