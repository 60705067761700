import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import isEmpty from '../../../../utils/is-empty'
import ImageIcon from '@material-ui/icons/ImageSearch'
import RemoveIcon from '@material-ui/icons/RemoveCircle'
import { withStyles, IconButton, Grid } from '@material-ui/core'
import ContainerMediaGalery from '../../../../container/media-galery'
import ComponentBoxContent from '../../../../model/component-box'
import { PatchData } from '../../../../model/patch'
import URL from '../../../../api/url'
import {
  componentBoxHideAction,
  componentBoxShowAction,
} from '../../../../reducers/component-box/action'

const FormItemMediaImage = props => {
  const { classes, dispatch, value, updateEntityKeyValue } = props
  const [stateValue, setStateValue] = useState(value)

  /**
   *
   */
  const handleAddImage = () => {
    /**
     *
     * @param {} items
     */
    const onOk = items => {
      const item = items[0]

      setStateValue(URL.STORAGE + item.url)

      updateEntityKeyValue(
        URL.STORAGE + item.url,
        props,
        PatchData.OPERATION_REPLACE,
      )

      dispatch(componentBoxHideAction())
    }

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(<ContainerMediaGalery onOk={onOk} />, {
          hideCloseButton: false,
        }),
      ),
    )
  }

  /**
   *
   */
  const handleRemove = () => {
    setStateValue(null)

    updateEntityKeyValue(null, props, PatchData.OPERATION_REPLACE)
  }

  let element = ''

  if (stateValue) {
    element = (
      <Grid>
        <img
          src={/* URL.STORAGE + */ stateValue}
          className={classes.mediaImage}
          alt=""
        />
        <IconButton onClick={handleRemove}>
          <RemoveIcon />
        </IconButton>
      </Grid>
    )
  } else {
    element = (
      <IconButton onClick={handleAddImage}>
        <ImageIcon />
      </IconButton>
    )
  }
  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={element}
    />
  )
}

FormItemMediaImage.propTypes = propTypes

export default withStyles(style)(FormItemMediaImage)
