const ASSIGNMENT_UPDATE_ENTITY = 'ASSIGNMENT_UPDATE_ENTITY'

const ASSIGNMENT_LIST = 'ASSIGNMENT_LIST'
const ASSIGNMENT_LIST_SUCCESS = 'ASSIGNMENT_LIST_SUCCESS'
const ASSIGNMENT_LIST_ERROR = 'ASSIGNMENT_LIST_ERROR'

const ASSIGNMENT_GET = 'ASSIGNMENT_GET'
const ASSIGNMENT_GET_SUCCESS = 'ASSIGNMENT_GET_SUCCESS'
const ASSIGNMENT_GET_ERROR = 'ASSIGNMENT_GET_ERROR'

const ASSIGNMENT_ADD = 'ASSIGNMENT_ADD'
const ASSIGNMENT_ADD_SUCCESS = 'ASSIGNMENT_ADD_SUCCESS'
const ASSIGNMENT_ADD_ERROR = 'ASSIGNMENT_ADD_ERROR'

const ASSIGNMENT_EDIT = 'ASSIGNMENT_EDIT'
const ASSIGNMENT_EDIT_SUCCESS = 'ASSIGNMENT_EDIT_SUCCESS'
const ASSIGNMENT_EDIT_ERROR = 'ASSIGNMENT_EDIT_ERROR'

const ASSIGNMENT_UPDATE_ATTRIBUTE = 'ASSIGNMENT_UPDATE_ATTRIBUTE'

const ASSIGNMENT_REMOVE_USER = 'ASSIGNMENT_REMOVE_USER'

const ASSIGNMENT_REMOVE_QUESTION = 'ASSIGNMENT_REMOVE_QUESTION'

const ASSIGNMENT_PATCH = 'ASSIGNMENT_PATCH'
const ASSIGNMENT_PATCH_SUCCESS = 'ASSIGNMENT_PATCH_SUCCESS'
const ASSIGNMENT_PATCH_ERROR = 'ASSIGNMENT_PATCH_ERROR'

const ASSIGNMENT_REPORT = 'ASSIGNMENT_REPORT'
const ASSIGNMENT_REPORT_SUCCESS = 'ASSIGNMENT_REPORT_SUCCESS'
const ASSIGNMENT_REPORT_ERROR = 'ASSIGNMENT_REPORT_ERROR'
const QUESTION_PATCH_SUCCESS = 'ASSIGNMENT_QUESTION_PATCH_SUCCESS'

const QUESTION_MEDIA_ADD_SUCCESS = 'ASSIGNMENT_QUESTION_MEDIA_ADD_SUCCESS'
const QUESTION_MEDIA_DELETE_SUCCESS = 'ASSIGNMENT_QUESTION_MEDIA_DELETE_SUCCESS'
const QUESTION_LINK_ADD_SUCCESS = 'ASSIGNMENT_QUESTION_LINK_ADD_SUCCESS'
const QUESTION_SELECTION_ADD_SUCCESS =
  'ASSIGNMENT_QUESTION_SELECTION_ADD_SUCCESS'
const QUESTION_SELECTION_DELETE_SUCCESS =
  'ASSIGNMENT_QUESTION_SELECTION_DELETE_SUCCESS'
const QUESTION_LINK_DELETE_SUCCESS = 'ASSIGNMENT_QUESTION_LINK_DELETE_SUCCESS'
const QUESTION_SELECTION_PATCH_SUCCESS =
  'ASSIGNMENT_QUESTION_SELECTION_PATCH_SUCCESS'
const ASSIGNMENT_REMOVE_QUESTION_SUCCESS = 'ASSIGNMENT_REMOVE_QUESTION_SUCCESS'

const ASSIGNMENT_ADD_BULK_QUESTION = 'ASSIGNMENT_ADD_BULK_QUESTION'
const ASSIGNMENT_ADD_BULK_QUESTION_SUCCESS =
  'ASSIGNMENT_ADD_BULK_QUESTION_SUCCESS'
const ASSIGNMENT_ADD_BULK_QUESTION_ERROR = 'ASSIGNMENT_ADD_BULK_QUESTION_ERROR'

const OPENAI_SEND_REQUEST = 'OPENAI_SEND_REQUEST'
const OPENAI_SEND_REQUEST_SUCCESS = 'OPENAI_SEND_REQUEST_SUCCESS'
const OPENAI_SEND_REQUEST_ERROR = 'OPENAI_SEND_REQUEST_ERROR'
const QUESTION_PAYMENT_DATA_UPDATE = 'QUESTION_PAYMENT_DATA_UPDATE'
const QUESTION_PAYMENT_DISCONNECT = 'QUESTION_PAYMENT_DISCONNECT'

const QUESTION_SETTING_PATCH = 'QUESTION_SETTING_PATCH'
const QUESTION_SETTING_PATCH_SUCCESS = 'QUESTION_SETTING_PATCH_SUCCESS'
const QUESTION_SETTING_PATCH_ERROR = 'QUESTION_SETTING_PATCH_ERROR'

const QUESTION_SETTING_CREATE = 'QUESTION_SETTING_CREATE'
const QUESTION_SETTING_CREATE_SUCCESS = 'QUESTION_SETTING_CREATE_SUCCESS'
const QUESTION_SETTING_CREATE_ERROR = 'QUESTION_SETTING_CREATE_ERROR'
const QUESTION_SETTING_REMOVE = 'QUESTION_SETTING_REMOVE'

const ASSIGNMENT_CARD_LINK_PATCH = 'ASSIGNMENT_CARD_LINK_PATCH'
const ASSIGNMENT_CARD_LINK_PATCH_SUCCESS = 'ASSIGNMENT_CARD_LINK_PATCH_SUCCESS'
const ASSIGNMENT_CARD_LINK_PATCH_ERROR = 'ASSIGNMENT_CARD_LINK_PATCH_ERROR'

const COURSE_SETTING_PATCH = 'COURSE_SETTING_PATCH'
const COURSE_SETTING_PATCH_SUCCESS = 'COURSE_SETTING_PATCH_SUCCESS'
const COURSE_SETTING_PATCH_ERROR = 'COURSE_SETTING_PATCH_ERROR'

const COURSE_SETTING_CREATE = 'COURSE_SETTING_CREATE'
const COURSE_SETTING_CREATE_SUCCESS = 'COURSE_SETTING_CREATE_SUCCESS'
const COURSE_SETTING_CREATE_ERROR = 'COURSE_SETTING_CREATE_ERROR'
const COURSE_SETTING_REMOVE = 'COURSE_SETTING_REMOVE'

const QUESTION_SELECTIONS_ADD = 'QUESTION_SELECTIONS_ADD'
const QUESTION_SELECTIONS_ADD_SUCCESS = 'QUESTION_SELECTIONS_ADD_SUCCESS'
const QUESTION_SELECTIONS_ADD_ERROR = 'QUESTION_SELECTIONS_ADD_ERROR'

const OPENAI_SEND_CARD_REQUEST = 'OPENAI_SEND_CARD_REQUEST'
const OPENAI_SEND_CARD_REQUEST_SUCCESS = 'OPENAI_SEND_CARD_REQUEST_SUCCESS'
const OPENAI_SEND_CARD_REQUEST_ERROR = 'OPENAI_SEND_CARD_REQUEST_ERROR'

const OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST =
  'OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST'
const OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_SUCCESS =
  'OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_SUCCESS'
const OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_ERROR =
  'OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_ERROR'

const OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST =
  'OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST'
const OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_SUCCESS =
  'OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_SUCCESS'
const OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_ERROR =
  'OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_ERROR'

const OPENAI_SEND_COURSE_CREATION_REQUEST =
  'OPENAI_SEND_COURSE_CREATION_REQUEST'
const OPENAI_SEND_COURSE_CREATION_REQUEST_SUCCESS =
  'OPENAI_SEND_COURSE_CREATION_REQUEST_SUCCESS'
const OPENAI_SEND_COURSE_CREATION_REQUEST_ERROR =
  'OPENAI_SEND_COURSE_CREATION_REQUEST_ERROR'

const CLONE_TEMPLATE_COURSE = 'CLONE_TEMPLATE_COURSE'
const CLONE_TEMPLATE_COURSE_SUCCESS = 'CLONE_TEMPLATE_COURSE_SUCCESS'
const CLONE_TEMPLATE_COURSE_ERROR = 'CLONE_TEMPLATE_COURSE_ERROR'

const CREATE_PROMPT_FROM_CARD = 'CREATE_PROMPT_FROM_CARD'
const CREATE_PROMPT_FROM_CARD_SUCCESS = 'CREATE_PROMPT_FROM_CARD_SUCCESS'
const CREATE_PROMPT_FROM_CARD_ERROR = 'CREATE_PROMPT_FROM_CARD_ERROR'

const QUESTION_CHANGE_IMAGE = 'QUESTION_CHANGE_IMAGE'

const GET_COURSE_ANALYTICS = 'GET_COURSE_ANALYTICS'
const GET_COURSE_ANALYTICS_SUCCESS = 'GET_COURSE_ANALYTICS_SUCCESS'
const GET_COURSE_ANALYTICS_ERROR = 'GET_COURSE_ANALYTICS_ERROR'

const ADD_COURSE_DOCUMENT = 'ADD_COURSE_DOCUMENT'
const ADD_COURSE_DOCUMENT_SUCCESS = 'ADD_COURSE_DOCUMENT_SUCCESS'
const ADD_COURSE_DOCUMENT_ERROR = 'ADD_COURSE_DOCUMENT_ERROR'

const GET_COURSE_DOCUMENT_BY_ID = 'GET_COURSE_DOCUMENT_BY_ID'
const GET_COURSE_DOCUMENT_BY_ID_SUCCESS = 'GET_COURSE_DOCUMENT_BY_ID_SUCCESS'
const GET_COURSE_DOCUMENT_BY_ID_ERROR = 'GET_COURSE_DOCUMENT_BY_ID_ERROR'

const GENERATE_QUIZ_WITH_COURSE_DOCUMENT = 'GENERATE_QUIZ_WITH_COURSE_DOCUMENT'
const GENERATE_QUIZ_WITH_COURSE_DOCUMENT_SUCCESS =
  'GENERATE_QUIZ_WITH_COURSE_DOCUMENT_SUCCESS'
const GENERATE_QUIZ_WITH_COURSE_DOCUMENT_ERROR =
  'GENERATE_QUIZ_WITH_COURSE_DOCUMENT_ERROR'

const DELETE_COURSE_DOCUMENT = 'DELETE_COURSE_DOCUMENT'
const DELETE_COURSE_DOCUMENT_SUCCESS = 'DELETE_COURSE_DOCUMENT_SUCCESS'
const DELETE_COURSE_DOCUMENT_ERROR = 'DELETE_COURSE_DOCUMENT_ERROR'

const GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT =
  'GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT'
const GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_SUCCESS =
  'GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_SUCCESS'
const GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_ERROR =
  'GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_ERROR'

const GENERATE_INFORMATION_WITH_COURSE_DOCUMENT =
  'GENERATE_INFORMATION_WITH_COURSE_DOCUMENT'
const GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_SUCCESS =
  'GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_SUCCESS'
const GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_ERROR =
  'GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_ERROR'

const EXPORT_COURSE_EXCEL = 'EXPORT_COURSE_EXCEL'
const EXPORT_COURSE_EXCEL_SUCCESS = 'EXPORT_COURSE_EXCEL_SUCCESS'
const EXPORT_COURSE_EXCEL_ERROR = 'EXPORT_COURSE_EXCEL_ERROR'

const IMPORT_COURSE_EXCEL = 'IMPORT_COURSE_EXCEL'
const IMPORT_COURSE_EXCEL_SUCCESS = 'IMPORT_COURSE_EXCEL_SUCCESS'
const IMPORT_COURSE_EXCEL_ERROR = 'IMPORT_COURSE_EXCEL_ERROR'

const IMPORT_COURSE_TXT = 'IMPORT_COURSE_TXT'
const IMPORT_COURSE_TXT_SUCCESS = 'IMPORT_COURSE_TXT_SUCCESS'
const IMPORT_COURSE_TXT_ERROR = 'IMPORT_COURSE_TXT_ERROR'

const EXPORT_COURSE_TXT = 'EXPORT_COURSE_TXT'
const EXPORT_COURSE_TXT_SUCCESS = 'EXPORT_COURSE_TXT_SUCCESS'
const EXPORT_COURSE_TXT_ERROR = 'EXPORT_COURSE_TXT_ERROR'

const EXPORT_COURSE_TO_SCORM = 'EXPORT_COURSE_TO_SCORM'
const EXPORT_COURSE_TO_SCORM_SUCCESS = 'EXPORT_COURSE_TO_SCORM_SUCCESS'
const EXPORT_COURSE_TO_SCORM_ERROR = 'EXPORT_COURSE_TO_SCORM_ERROR'

export {
  ASSIGNMENT_UPDATE_ENTITY,
  ASSIGNMENT_ADD,
  ASSIGNMENT_ADD_SUCCESS,
  ASSIGNMENT_ADD_ERROR,
  ASSIGNMENT_LIST,
  ASSIGNMENT_LIST_SUCCESS,
  ASSIGNMENT_LIST_ERROR,
  ASSIGNMENT_GET,
  ASSIGNMENT_GET_SUCCESS,
  ASSIGNMENT_GET_ERROR,
  ASSIGNMENT_EDIT,
  ASSIGNMENT_EDIT_SUCCESS,
  ASSIGNMENT_EDIT_ERROR,
  ASSIGNMENT_UPDATE_ATTRIBUTE,
  ASSIGNMENT_REMOVE_USER,
  ASSIGNMENT_PATCH,
  ASSIGNMENT_PATCH_SUCCESS,
  ASSIGNMENT_PATCH_ERROR,
  ASSIGNMENT_REMOVE_QUESTION,
  ASSIGNMENT_REPORT,
  ASSIGNMENT_REPORT_SUCCESS,
  ASSIGNMENT_REPORT_ERROR,
  QUESTION_PATCH_SUCCESS,
  QUESTION_MEDIA_ADD_SUCCESS,
  QUESTION_MEDIA_DELETE_SUCCESS,
  QUESTION_LINK_ADD_SUCCESS,
  QUESTION_SELECTION_ADD_SUCCESS,
  QUESTION_SELECTION_DELETE_SUCCESS,
  QUESTION_LINK_DELETE_SUCCESS,
  QUESTION_SELECTION_PATCH_SUCCESS,
  ASSIGNMENT_REMOVE_QUESTION_SUCCESS,
  ASSIGNMENT_ADD_BULK_QUESTION,
  ASSIGNMENT_ADD_BULK_QUESTION_SUCCESS,
  ASSIGNMENT_ADD_BULK_QUESTION_ERROR,
  OPENAI_SEND_REQUEST,
  OPENAI_SEND_REQUEST_SUCCESS,
  OPENAI_SEND_REQUEST_ERROR,
  QUESTION_PAYMENT_DATA_UPDATE,
  QUESTION_PAYMENT_DISCONNECT,
  QUESTION_SETTING_PATCH,
  QUESTION_SETTING_PATCH_SUCCESS,
  QUESTION_SETTING_PATCH_ERROR,
  QUESTION_SETTING_CREATE,
  QUESTION_SETTING_CREATE_SUCCESS,
  QUESTION_SETTING_CREATE_ERROR,
  QUESTION_SETTING_REMOVE,
  ASSIGNMENT_CARD_LINK_PATCH,
  ASSIGNMENT_CARD_LINK_PATCH_SUCCESS,
  ASSIGNMENT_CARD_LINK_PATCH_ERROR,
  COURSE_SETTING_PATCH,
  COURSE_SETTING_PATCH_SUCCESS,
  COURSE_SETTING_PATCH_ERROR,
  COURSE_SETTING_CREATE,
  COURSE_SETTING_CREATE_SUCCESS,
  COURSE_SETTING_CREATE_ERROR,
  COURSE_SETTING_REMOVE,
  QUESTION_SELECTIONS_ADD,
  QUESTION_SELECTIONS_ADD_SUCCESS,
  QUESTION_SELECTIONS_ADD_ERROR,
  OPENAI_SEND_CARD_REQUEST_ERROR,
  OPENAI_SEND_CARD_REQUEST_SUCCESS,
  OPENAI_SEND_CARD_REQUEST,
  OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST,
  OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_SUCCESS,
  OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_ERROR,
  OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST,
  OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_SUCCESS,
  OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_ERROR,
  OPENAI_SEND_COURSE_CREATION_REQUEST,
  OPENAI_SEND_COURSE_CREATION_REQUEST_SUCCESS,
  OPENAI_SEND_COURSE_CREATION_REQUEST_ERROR,
  CLONE_TEMPLATE_COURSE,
  CLONE_TEMPLATE_COURSE_SUCCESS,
  CLONE_TEMPLATE_COURSE_ERROR,
  CREATE_PROMPT_FROM_CARD,
  CREATE_PROMPT_FROM_CARD_SUCCESS,
  CREATE_PROMPT_FROM_CARD_ERROR,
  QUESTION_CHANGE_IMAGE,
  GET_COURSE_ANALYTICS,
  GET_COURSE_ANALYTICS_SUCCESS,
  GET_COURSE_ANALYTICS_ERROR,
  ADD_COURSE_DOCUMENT,
  ADD_COURSE_DOCUMENT_SUCCESS,
  ADD_COURSE_DOCUMENT_ERROR,
  GET_COURSE_DOCUMENT_BY_ID,
  GET_COURSE_DOCUMENT_BY_ID_SUCCESS,
  GET_COURSE_DOCUMENT_BY_ID_ERROR,
  GENERATE_QUIZ_WITH_COURSE_DOCUMENT,
  GENERATE_QUIZ_WITH_COURSE_DOCUMENT_SUCCESS,
  GENERATE_QUIZ_WITH_COURSE_DOCUMENT_ERROR,
  DELETE_COURSE_DOCUMENT,
  DELETE_COURSE_DOCUMENT_SUCCESS,
  DELETE_COURSE_DOCUMENT_ERROR,
  GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT,
  GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_SUCCESS,
  GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_ERROR,
  GENERATE_INFORMATION_WITH_COURSE_DOCUMENT,
  GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_SUCCESS,
  GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_ERROR,
  EXPORT_COURSE_EXCEL,
  EXPORT_COURSE_EXCEL_SUCCESS,
  EXPORT_COURSE_EXCEL_ERROR,
  IMPORT_COURSE_EXCEL,
  IMPORT_COURSE_EXCEL_SUCCESS,
  IMPORT_COURSE_EXCEL_ERROR,
  IMPORT_COURSE_TXT,
  IMPORT_COURSE_TXT_SUCCESS,
  IMPORT_COURSE_TXT_ERROR,
  EXPORT_COURSE_TXT,
  EXPORT_COURSE_TXT_SUCCESS,
  EXPORT_COURSE_TXT_ERROR,
  EXPORT_COURSE_TO_SCORM,
  EXPORT_COURSE_TO_SCORM_SUCCESS,
  EXPORT_COURSE_TO_SCORM_ERROR,
}
