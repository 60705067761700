import { put, call, take, fork } from 'redux-saga/effects'
import { patchCollectionSettingsService } from '../../api/course-collection'
import { patchCollectionSettingsSuccess } from '../../reducers/course-collection/action'
import { PATCH_COLLECTION_SETTINGS } from '../../reducers/course-collection/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* patchCollectionSettingFlow(payload) {
  try {
    const { content, callback } = payload
    const response = yield call(patchCollectionSettingsService, content)

    yield put(patchCollectionSettingsSuccess(content))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* patchCollectionSettingWatcher() {
  while (true) {
    const action = yield take(PATCH_COLLECTION_SETTINGS)
    const { payload } = action

    yield fork(generalSaga, patchCollectionSettingFlow, payload, false)
  }
}
