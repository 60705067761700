/*
    Theme model
        Font
        Font Color
        Font Size
        Body Background (image or color)
        Card Background (color)
        Button Color

        Card Alignment
            Top
            Center
        
        Layout

        Card Stretch
            Full
            Wide
*/

const initialThemeSettings = {
  font: 'Roboto',
  fontColor: { r: 0, g: 0, b: 0, a: 1 },
  fontSize: 'M',
  lineHeight: 'M',
  backgroundColor: { r: 242, g: 242, b: 242, a: 1 },
  bodyBackgroundImage:
    'https://cdn.minicoursegenerator.com/production/images/mini1631212909/7360ec3a-5364-4e57-ab8c-e2d9a9968cad.png',
  cardBackgroundColor: { r: 242, g: 242, b: 242, a: 0 },
  buttonColor: { r: 246, g: 123, b: 63, a: 1 },
  cardAlignment: 'center',
  stretch: 2,
  layout: 2,
  arrow: 1,
}

export { initialThemeSettings }
