import React from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import styles from '../styles'

const ImportButtons = props => {
  const { handleImport, dispatch, uploadedFile, classes } = props
  return (
    <div className={classes.modalContent__buttons}>
      <Button
        variant="outlined"
        className={classes.modalContent__cancelButton}
        onClick={() => dispatch(componentBoxHideAction())}
      >
        <Typography className={classes.modalContent__buttonText}>
          Cancel
        </Typography>
      </Button>
      <Button
        disabled={!uploadedFile}
        variant="contained"
        className={classes.modalContent__importButton}
        onClick={() => handleImport()}
      >
        <Typography className={classes.modalContent__buttonText}>
          Import
        </Typography>
      </Button>
    </div>
  )
}

export default withStyles(styles)(ImportButtons)
