import translator from 'src/translate'
import { withStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styles from '../styles'
import {
  Button,
  Typography,
  TextField,
  Box,
  Switch,
  FormControlLabel,
  FormGroup,
  CircularProgress,
} from '@material-ui/core'

import {
  useCourseSettings,
  createSetting,
  patchSetting,
} from 'src/hooks/useCourseSettings'

import { Icon } from '@iconify/react'
import heartOutline from '@iconify-icons/eva/heart-outline'
import {
  AssignmentFieldNames,
  AssignmentInitialCourseSettings,
} from 'src/model/assignment'
import UserType, { UserTypeNames } from 'src/model/user-types'

import { useDispatch } from 'react-redux'
const SETTING_KEY = 'webhookAnswer'
const Webhook = props => {
  const { classes, entity, setSettingDrawerOpen } = props
  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)
  const dispatch = useDispatch()
  const isUserPremium =
    UserType[window.CONSTANTS.USER_ROLE_ID] !==
      UserTypeNames.REGULAR_ADMIN_ACCOUNT &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.PREMIUM &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.PREMIUM_V2 &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1 &&
    UserType[window.CONSTANTS.USER_ROLE_ID] !== UserTypeNames.APPSUMO_TIER_1A
  const [loading, setLoading] = useState(false)

  const [endpointUrl, setEndpointUrl] = useState(exists ? value.url : '')

  const [cardEnd, setCardEnd] = useState(
    exists ? value.triggers[0].isActive : false,
  )
  const [courseEnd, setCourseEnd] = useState(
    exists ? value.triggers[1].isActive : false,
  )

  const saveSettings = e => {
    setLoading(true)
    let triggers = [
      {
        trigger: 0,
        isActive: cardEnd,
      },
      { trigger: 1, isActive: courseEnd },
    ]
    let variables = {
      url: endpointUrl,
      isActive: true,
      triggers,
    }

    if (!exists) {
      // create a setting entity with execute function
      execute(variables, () => setTimeout(() => setLoading(false), 1000))
    } else {
      update(variables, () => setTimeout(() => setLoading(false), 1000))
    }
    setTimeout(() => setLoading(false), 1000)
  }
  return (
    <form className={classes.content}>
      <Box m={1}></Box>
      <TextField
        type="text"
        id="endpointUrl"
        variant="outlined"
        label={translator._('assignment.courseSettings.endpointUrl')}
        defaultValue={endpointUrl}
        onBlur={event => setEndpointUrl(event.target.value)}
        disabled={isUserPremium ? false : true}
      />
      <Box m={1}></Box>
      <Typography variant="body1" style={{ fontWeight: 600 }}>
        {translator._('assignment.courseSettings.event')}
      </Typography>
      <Box m={0.5}></Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id="cardEnd"
              checked={cardEnd}
              onChange={() => setCardEnd(!cardEnd)}
              disabled={isUserPremium ? false : true}
            />
          }
          label={translator._('assignment.courseSettings.card')}
        />
      </FormGroup>
      <Box m={0.25}></Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id="courseEnd"
              checked={courseEnd}
              onChange={() => setCourseEnd(!courseEnd)}
              disabled={isUserPremium ? false : true}
            />
          }
          label={translator._('assignment.courseSettings.course')}
        />
      </FormGroup>

      {loading ? (
        <div className={classes.bottomButtonLoader}>
          <CircularProgress></CircularProgress>
        </div>
      ) : isUserPremium ? (
        <div className={classes.bottomButton}>
          <Button
            fullWidth={true}
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => saveSettings()}
          >
            {translator._('assignment.courseSettings.save')}
          </Button>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }} m={1}></Box>
          <Button
            fullWidth={true}
            color="secondary"
            size="small"
            variant="outlined"
            sx={{ display: { xs: 'block', sm: 'none' } }}
            onClick={() => setSettingDrawerOpen(false)}
          >
            {translator._('assignment.courseSettings.cancel')}
          </Button>
        </div>
      ) : (
        <div className={classes.bottomButton}>
          <Button
            fullWidth={true}
            to="/pricing"
            color="primary"
            variant="contained"
            startIcon={
              <Icon size="small" icon="fa6-solid:crown" color="#f7b155"></Icon>
            }
            component={RouterLink}
          >
            Upgrade to Edit
          </Button>
        </div>
      )}
    </form>
  )
}

export default withStyles(styles)(Webhook)
