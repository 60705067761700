const Options = [
  {
    text: 'Course, coaching, workshop provider to individuals',
  },
  {
    text: 'In-house training & content provider for corporations',
  },
  {
    text: 'Independent training & content provider for corporations',
  },
  {
    text: 'High school & higher education instructor',
  },
  {
    text: 'Content marketing & digital marketing professional',
  },
  {
    text: 'None of the above',
  },
]

export { Options }
