import React, { useState } from 'react'
import style from '../../style'
import { withStyles, Typography, Popover, Button } from '@material-ui/core'
import SelectTypeWidget from 'src/components/select-type-widget'

const EmptyListContent = props => {
  const { onAddCard, list, dispatch, entity, courseId, classes } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectWidgetOpen, setSelectWidgetOpen] = React.useState(null)
  const isLastCard = list.length == 0

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setSelectWidgetOpen(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setSelectWidgetOpen(false)
  }

  const sortCard = (newEntity, hasFinishCard) => {
    handleClose()
    //reOrderToNewAdded(cardCount, index + 1, newEntity)
    //setPage(index + 2)
  }
  return (
    <div className={classes.emptySlot}>
      <div className={classes.emptySlotContent}>
        <img src="/assets/no-question.png"></img>
        <Typography className={classes.emptySlotTitle}>
          There are no questions (yet).
        </Typography>
        <Typography className={classes.emptySlotDescription}>
          Add your source(s) from the left section and let the magic happens.
        </Typography>
        <Button aria-label="add card" onClick={handleClick}>
          <Typography className={classes.emptySlotButtonText}>
            or create quiz from scratch manually
          </Typography>
        </Button>
      </div>

      <Popover
        style={{ opacity: selectWidgetOpen ? 1 : 0 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.popover}
      >
        <SelectTypeWidget
          dispatch={dispatch}
          courseId={courseId}
          entity={entity}
          onAdd={list => {
            onAddCard(list, sortCard, isLastCard)
          }}
          disabledAiPopup={true}
        />
      </Popover>
    </div>
  )
}

export default withStyles(style)(EmptyListContent)
