const styles = theme => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageHeader: {
    marginBottom: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ourDiv: {
    backgroundColor: '#F67B3F',
    borderRadius: '16px',
    width: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  contentDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 1000,
    height: 300,
  },
  contentDivItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 440,
    padding: 70,
  },
  logos: {
    width: '170px',
    marginBottom: 50,
  },
  mb: {
    marginBottom: 50,
  },
  buttons: {
    color: '#F67B3F',
    borderRadius: 50,
    boxShadow: 'none',
    width: '50%',
  },
})

export default styles
