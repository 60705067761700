import React from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import { withStyles } from '@material-ui/core'
import TeamList from '../../../../components/team-list'
import { PatchData } from '../../../../model/patch'

const FormItemPoolSelection = props => {
  const content = []
  const {
    dispatch,
    value,
    relationFieldName,
    itemName,
    move,
    parentItemName,
    addItemButtonLabel,
    updateEntityKeyValue,
    componentBoxContent,
  } = props

  /**
   *
   * @param {*} content
   * @param {*} list
   */
  const onAdd = (content, list) => {
    value.forEach(element => {
      if (element[itemName]) {
        list.push(element[itemName])
      } else {
        list.push(element)
      }
    })

    updateEntityKeyValue(list, props, PatchData.OPERATION_REPLACE)
  }

  /**
   *
   * @param {*} entity
   */
  const remove = entity => {
    let newList
    if (entity[itemName] != null) {
      newList = value
        .filter(
          element => element[relationFieldName] !== entity[relationFieldName],
        )
        .map(item => item[itemName])
    } else {
      newList = value.filter(element => element.id !== entity.id)
    }
    updateEntityKeyValue(newList, props, PatchData.OPERATION_REPLACE)
  }

  return (
    <ItemLayout
      hasContent={false}
      formItem={props}
      element={
        <TeamList
          dispatch={dispatch}
          list={value}
          content={content}
          onAdd={onAdd}
          onMove={move}
          onRemove={remove}
          itemName={itemName}
          parentItemName={parentItemName}
          addItemButtonLabel={addItemButtonLabel}
          componentBoxContent={componentBoxContent}
        />
      }
    />
  )
}

FormItemPoolSelection.propTypes = propTypes

export default withStyles(style)(FormItemPoolSelection)
