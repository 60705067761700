import React from 'react'
import styles from './style'
import propTypes from './prop'
import FormFactory from '../../form-item/factory'
import { guid } from '../../../../utils/guid'
import SYSTEM_DATA from '../../../../data'
import {
  FormItemType,
  FormItemFilterAddMode,
} from '../../../../form/props/form-item/default.js'
import {
  withStyles,
  MenuList,
  MenuItem,
  Typography,
  Grid,
} from '@material-ui/core'
import translator from '../../../../translate'

const LayoutSearch = props => {
  const elements = []
  const {
    classes,
    formContent,
    dispatch,
    formController,
    updateFormContent,
  } = props

  /**
   *
   * @param {*} formItem
   */
  const removeFilterHandler = formItem => {
    const filters = formController.getEntity().Filters
    formController.setEntity({
      ...formController.getEntity(),
      Filters: filters.filter((filter, filterIndex) => {
        return formItem.filterIndex !== filterIndex
      }),
    })

    updateFormContent({
      ...formContent,
      groups: formContent.groups.filter(group => {
        return group.id !== formItem.groupId
      }),
    })

    formController.firePatchEvent()
  }

  /**
   *
   * @param {*} e
   */
  const addFilterHandler = async e => {
    const attribute = e.currentTarget.dataset.attribute
    const filters = formController.getEntity().Filters
    const itemCount = filters.length
    const formItem = formContent.formItemsSuggestion.find(item => {
      return item.attribute === attribute
    })

    formController.setEntity({
      ...formController.getEntity(),
      Filters: [
        ...filters,
        {
          FilteredField: attribute,
          FilteredValue: formItem.options ? formItem.options()[0].value : '',
          AndOr: 'and',
        },
      ],
    })

    updateFormContent({
      ...formContent,
      groups: [
        ...formContent.groups,
        {
          id: guid().uid(),
          formItems: [
            {
              ...(await formController.getDefaultFormItem({
                ...formItem,

                attribute: `Filters[${itemCount}].${attribute}`,
              })),
              id: guid().uid(),
              onClear: removeFilterHandler,
              filterIndex: itemCount,
            },
            {
              ...(await formController.getDefaultFormItem({
                attribute: `Filters[${itemCount}].AndOr`,
              })),
              id: guid().uid(),
              label: null,
              type: FormItemType.SELECT,
              optionAttributeName: {
                label: 'label',
                value: 'value',
              },
              required: false,
              disabled: false,
              hidden: false,
              options: () => {
                return SYSTEM_DATA.FILTER_LOGIC
              },
            },
          ],
        },
      ],
    })

    formController.firePatchEvent()
  }

  /**
   *
   * @param {*} attribute
   */
  const usedFilter = attribute => {
    return formController.getEntity().Filters.find(item => {
      return item.hasOwnProperty(attribute)
    })
      ? true
      : false
  }

  formContent.groups.forEach(group => {
    group.formItems.forEach((formItem, groupIndex) => {
      formItem.groupId = group.id
      formItem.dispatch = dispatch

      elements.push(FormFactory(formItem))
    })
  })

  return (
    <React.Fragment>
      {formContent.groups.map((group, groupIndex) => {
        return (
          <Grid container key={groupIndex} className={classes.filterItemGroup}>
            {group.formItems.map(formItem => {
              formItem.groupId = group.id
              formItem.dispatch = dispatch

              return FormFactory(formItem)
            })}
          </Grid>
        )
      })}

      <Grid
        container
        className={classes.filterListWrapper}
        alignContent="flex-start"
      >
        <Grid container>
          <Typography variant="body1" className={classes.filterListTitle}>
            {translator._('general.addFilter')}
          </Typography>
        </Grid>
        <Grid container className={classes.filterList}>
          <MenuList>
            {formContent.formItemsSuggestion &&
              formContent.formItemsSuggestion.map((formItem, index) => (
                <MenuItem
                  disabled={
                    formItem.filterAddMode === FormItemFilterAddMode.SINGLE
                      ? usedFilter(formItem.attribute)
                      : false
                  }
                  onClick={addFilterHandler}
                  key={index}
                  data-attribute={formItem.attribute}
                >
                  {formItem.label}
                </MenuItem>
              ))}
          </MenuList>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

LayoutSearch.propTypes = propTypes

export default withStyles(styles)(LayoutSearch)
