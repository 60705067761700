const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',

    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  root: {
    height: '100vh',
    justifyContent: 'center',
    backgroundColor: '#f4f6f8',
  },
  video: {
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
  },

  image: {
    backgroundImage: 'url(/assets/signup_screen_newbg4.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
    },
  },
  paperText: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: { width: '-webkit-fill-available', maxWidth: 480 },
  submit: {
    marginTop: theme.spacing(3),
    height: '50px',
    maxWidth: 500,
    borderRadius: '16px',
    backgroundColor: '#015FFB',
    width: '-webkit-fill-available',
  },
  combo: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    //borderWidth: 1,
    //borderStyle: 'solid',
    boxSizing: 'border-box',
    padding: theme.spacing(2),

    '&:hover': {
      borderColor: '#80bdff',
    },
  },
  overlayText: {
    maxWidth: '90%',
    padding: 20,
    margin: '5%',
    background: '#f0ffff14',
    bottom: 0,
    position: 'absolute',
    color: 'whitesmoke',
  },
  interestButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '130px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonIndex: {
    color: '#808080',
    borderRadius: '4px',
    border: '1px solid #808080',
  },
  formGroup: {
    padding: '8px',
    //marginLeft: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formGroupCard: {
    display: 'flex',
    width: '-webkit-fill-available',
    flexDirection: 'row',
    maxWidth: 500,
    marginBottom: theme.spacing(1),
    boxShadow: 'none',
    border: '1px solid #808080',
    background: '#ffffff',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
