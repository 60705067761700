import React from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import { Icon } from '@iconify/react'
import styles from '../styles'

const NoGroupView = props => {
  const { handleTeamsButtonClick, classes } = props
  return (
    <div className={classes.noTeamsDiv}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src="/assets/empty-team.png"
          style={{
            width: '80px',
            height: '80px',
          }}
        ></img>
      </div>

      <Typography textAlign={'center'}>Your groups list is Empty</Typography>
      <Typography textAlign={'center'}>
        Go to the Groups page and add a group
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="text"
          style={{
            backgroundColor: '#fff',
            color: '#637381',
            width: '70%',
          }}
          onClick={() => {
            handleTeamsButtonClick()
          }}
        >
          <Icon
            icon={'mdi:people'}
            color="#637381"
            style={{ marginTop: '2px', marginRight: '3px' }}
          />
          <Typography fontSize={'14px'} fontWeight={500} color="#002E47">
            Groups
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(NoGroupView)
