import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import { connect } from 'react-redux'
import propTypes from './prop'
import translator from '../../translate'
import { snackbarDispose } from '../../reducers/snackbar/action'
import { withSnackbar } from 'notistack'
import parse from 'html-react-parser';

import {
  CssBaseline,
  Paper,
  Typography,
  Box,
  Container,
} from '@material-ui/core'

class UnsubscribedDone extends Component {
  /**
   *
   */
  render() {
    const { classes } = this.props
    return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            {translator._('unsubscribedDone.title')}
          </Typography>

          <Box m={2}></Box>
          <Typography variant="body2">
            {parse(translator._('unsubscribedDone.info'))}
          </Typography>
          <Box m={2}></Box>

          <img src="assets/conf-code.png" alt="confirm code" />
        </Paper>
      </Container>
    )
  }

  /**
   *
   */
  componentDidUpdate() {
    const { snackbar, enqueueSnackbar, dispatch } = this.props
    if (snackbar.messages) {
      snackbar.messages.forEach(message => {
        message.messageIds.forEach(messageId => {
          enqueueSnackbar(translator._(messageId), {
            preventDuplicate: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
        dispatch(snackbarDispose(message.id))
      })
    }
  }
}

UnsubscribedDone.propTypes = propTypes

const mapStateToProps = response => ({
  authentication: response.authentication,
  snackbar: response.snackbar,
})

export default connect(mapStateToProps)(
  withStyles(styles)(withSnackbar(UnsubscribedDone)),
)
