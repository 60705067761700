import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import { confirmCodeAction } from '../../reducers/sign-up/action.js'
import { connect } from 'react-redux'
import propTypes from './prop'
import translator from '../../translate'
import { snackbarDispose } from '../../reducers/snackbar/action'
import { withSnackbar } from 'notistack'
import { get as getCookie } from 'es-cookie'

import {
  Button,
  CssBaseline,
  Paper,
  Typography,
  TextField,
  Grid,
  Box,
  Container,
} from '@material-ui/core'

class CheckVerificationCode extends Component {
  state = {
    code1: null,
    code2: null,
    code3: null,
    code4: null,
  }

  /**
   *
   */
  onHandleSignUp = event => {
    event.preventDefault()

    const code =
      event.target.code1.value +
      event.target.code2.value +
      event.target.code3.value +
      event.target.code4.value
    const data = {
      email: getCookie('email'),
      code,
      isRedirect: false,
    }

    this.props.dispatch(confirmCodeAction(data))
  }

  /**
   *
   */
  code1Control = e => {
    let val = e.target.value
    if (val.length == 4) {
      let val1 = val.slice(0, 1)
      let val2 = val.slice(1, 2)
      let val3 = val.slice(2, 3)
      let val4 = val.slice(3, 4)
      this.setState({ code1: val1 })
      this.setState({ code2: val2 })
      this.setState({ code3: val3 })
      this.setState({ code4: val4 })
    } else {
      if (val > 9) {
        val = val.slice(-1)
      }
      if (val < 0) {
        val = 0
      }
      this.code2.focus()

      this.setState({ code1: val })
    }
  }

  /**
   *
   * @param {*} e
   */
  code2Control = e => {
    let val = e.target.value

    if (val > 9) {
      val = val.slice(-1)
    }
    if (val < 0) {
      val = 0
    }
    this.code3.focus()

    this.setState({ code2: val })
  }

  /**
   *
   * @param {*} e
   */
  code3Control = e => {
    let val = e.target.value

    if (val > 9) {
      val = val.slice(-1)
    }
    if (val < 0) {
      val = 0
    }
    this.code4.focus()

    this.setState({ code3: val })
  }

  /**
   *
   */
  code4Control = e => {
    let val = e.target.value

    if (val > 9) {
      val = val.slice(-1)
    }

    if (val < 0) {
      val = 0
    }

    this.setState({ code4: val })
  }

  /**
   *
   */
  render() {
    const { classes } = this.props

    return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            {translator._('verifyEmail.title')}
          </Typography>

          <Typography variant="body1">
            {translator._('verifyEmail.text1')}
          </Typography>
          <Typography variant="body1">
            {translator._('verifyEmail.text2')}
          </Typography>

          <form className={classes.form} onSubmit={this.onHandleSignUp}>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <TextField
                  name="code1"
                  value={this.state.code1}
                  required
                  fullWidth
                  id="code1"
                  autoFocus
                  inputRef={input => {
                    this.code1 = input
                  }}
                  InputProps={{
                    className: classes.textField,
                  }}
                  onChange={this.code1Control}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  value={this.state.code2}
                  required
                  fullWidth
                  id="code2"
                  name="code2"
                  inputRef={input => {
                    this.code2 = input
                  }}
                  InputProps={{
                    className: classes.textField,
                  }}
                  onChange={this.code2Control}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  value={this.state.code3}
                  required
                  fullWidth
                  id="code3"
                  name="code3"
                  inputRef={input => {
                    this.code3 = input
                  }}
                  InputProps={{
                    className: classes.textField,
                  }}
                  onChange={this.code3Control}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  value={this.state.code4}
                  required
                  fullWidth
                  id="code4"
                  name="code4"
                  inputRef={input => {
                    this.code4 = input
                  }}
                  InputProps={{
                    className: classes.textField,
                  }}
                  onChange={this.code4Control}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {translator._('verifyEmail.continue')}
            </Button>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Box m={2}>
                  {/* <Link href="/login" variant="body2">
                                        Resend Code
                                    </Link> */}
                </Box>
              </Grid>
            </Grid>

            <Typography variant="body2">
              {translator._('verifyEmail.info')}
            </Typography>
          </form>
          <img src="assets/conf-code.png" alt="confirm code" />
        </Paper>
      </Container>
    )
  }

  /**
   *
   */
  componentDidUpdate() {
    const { snackbar, enqueueSnackbar, dispatch } = this.props
    if (snackbar.messages) {
      snackbar.messages.forEach(message => {
        message.messageIds.forEach(messageId => {
          enqueueSnackbar(translator._(messageId), {
            preventDuplicate: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
        dispatch(snackbarDispose(message.id))
      })
    }
  }
}

CheckVerificationCode.propTypes = propTypes

const mapStateToProps = response => ({
  authentication: response.authentication,
  snackbar: response.snackbar,
})

export default connect(mapStateToProps)(
  withStyles(styles)(withSnackbar(CheckVerificationCode)),
)
