import PropTypes from 'prop-types'
import { ListUseModeTypes } from '../../../../model/list'

const propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  showChildList: PropTypes.bool.isRequired,
  showSelect: PropTypes.bool.isRequired,
  customFirstHeaderRow: PropTypes.any,
  usageMode: PropTypes.oneOf(ListUseModeTypes).isRequired,
  showDragDrop: PropTypes.bool,
}

export default propTypes
