const SEND_CONFIRMATION_EMAIL = 'SEND_CONFIRMATION_EMAIL'
const SEND_CONFIRMATION_EMAIL_SUCCESS = 'SEND_CONFIRMATION_EMAIL_SUCCESS'
const SEND_CONFIRMATION_EMAIL_ERROR = 'SEND_CONFIRMATION_EMAIL_ERROR'

const CONFIRM_CODE = 'CONFIRM_CODE'
const CONFIRM_CODE_SUCCESS = 'CONFIRM_CODE_SUCCESS'
const CONFIRM_CODE_ERROR = 'CONFIRM_CODE_ERROR'

const SIGN_UP = 'SIGN_UP'
const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
const SIGN_UP_ERROR = 'SIGN_UP_ERROR'

const SIGN_UP_ROOTIE = 'SIGN_UP_ROOTIE'
const SIGN_UP_ROOTIE_SUCCESS = 'SIGN_UP_ROOTIE_SUCCESS'
const SIGN_UP_ROOTIE_ERROR = 'SIGN_UP_ROOTIE_ERROR'

const APPSUMO_SIGN_UP = 'APPSUMO_SIGN_UP'
const APPSUMO_SIGN_UP_SUCCESS = 'APPSUMO_SIGN_UP_SUCCESS'
const APPSUMO_SIGN_UP_ERROR = 'APPSUMO_SIGN_UP_ERROR'

export {
  SEND_CONFIRMATION_EMAIL,
  SEND_CONFIRMATION_EMAIL_SUCCESS,
  SEND_CONFIRMATION_EMAIL_ERROR,
  CONFIRM_CODE,
  CONFIRM_CODE_SUCCESS,
  CONFIRM_CODE_ERROR,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_ROOTIE,
  SIGN_UP_ROOTIE_SUCCESS,
  SIGN_UP_ROOTIE_ERROR,
  APPSUMO_SIGN_UP,
  APPSUMO_SIGN_UP_SUCCESS,
  APPSUMO_SIGN_UP_ERROR,
}
