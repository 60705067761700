import { call, take, fork } from 'redux-saga/effects'
import { questionSettingRemoveService } from '../../api/question'
import { QUESTION_SETTING_REMOVE } from '../../reducers/assignment/type'
import generalSaga from '../general'

/**
 *
 * @param {*} payload
 */
function* questionSettingsRemoveFlow(payload) {
  try {
    const { id, questionId } = payload

    yield call(questionSettingRemoveService, id)
    //yield put(operationSuccess())
  } catch (error) {
    //yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionSettingsRemoveWatcher() {
  while (true) {
    const action = yield take(QUESTION_SETTING_REMOVE)
    const { payload } = action

    yield fork(generalSaga, questionSettingsRemoveFlow, payload, false)
  }
}
