const styles = theme => ({
  modal: {
    display: 'flex !important',
  },
  modalContent: {
    width: '520px',
    //height: '196px',
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
    paddingTop: '20px',
    borderRadius: '16px',
    '&:focus': {
      outline: 'none!important',
    },
  },
  modalContent__header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  modalContent__header__title: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#002E47',
  },
  modalContent__header__text: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#002E47',
    marginBottom: '16px',
  },
  modalContent__header__warning: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#ED4245',
    marginBottom: '16px',
  },
  modalContent__buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
  },
  modalContent__deleteButton: {
    backgroundColor: '#ED4245',
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ED4245',
      color: '#fff',
      boxShadow: 'none',
    },
  },
  modalContent__cancelButton: {
    color: '#002E47',
    border: 'none',
    outline: '1px solid #637381',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#002E47',
      outline: '1px solid #637381',
      border: 'none',
    },
  },
  modalContent__buttonText: {
    fontSize: '14px',
    fontWeight: 500,
  },
})

export default styles
