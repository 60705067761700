import translator from 'src/translate'
import React, { useState } from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import {
  Drawer,
  Tab,
  Typography,
  Card,
  Button,
  Box,
  Grid,
} from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { Icon } from '@iconify/react'

import AnalyticsWidgetSummary from 'src/components/analytics-drawer/components/AnalyticsWidgetSummary'

import { getCourseAnalyticsByIdAction } from 'src/reducers/assignment/action'
import { en } from 'faker/lib/locales'

const AnalyticsDrawer = props => {
  const {
    setAnalyticsDrawerOpen,
    analyticsDrawerOpen,
    dispatch,
    entity,
    classes,
    handleGetAnalytics,
  } = props

  const [totalViews, setTotalViews] = useState()
  const [uniqueViews, setUniqueViews] = useState()
  const [engagedUsers, setEngagedUsers] = useState()

  React.useEffect(() => {
    dispatch(
      getCourseAnalyticsByIdAction(entity.id, response => {
        setTotalViews(response.totalViews)
        setUniqueViews(response.uniqueViews)
        setEngagedUsers(response.engagedUsers)
      }),
    )
  }, [])
  return (
    <div>
      <Drawer
        variant="temporary"
        anchor="right"
        classes={{ paper: classes.drawer }}
        open={analyticsDrawerOpen}
        onClose={() => setAnalyticsDrawerOpen(false)}
        onOpen={() => setAnalyticsDrawerOpen(true)}
        transitionDuration={{ enter: 700, exit: 700 }}
      >
        <div className={classes.list}>
          <Box m={2}></Box>
          <div>
            <Typography variant="h5" className={classes.tabList}>
              Analytics
            </Typography>
          </div>
          <Box m={1}></Box>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <AnalyticsWidgetSummary
                title="Total Views"
                total={totalViews}
                color="success"
                icon={'bi:people-fill'}
              />
            </Grid>
            <Grid item>
              <AnalyticsWidgetSummary
                title="Unique Views"
                total={uniqueViews}
                color="info"
                icon={'icon-park-solid:people-top'}
              />
            </Grid>
            <Grid item>
              <AnalyticsWidgetSummary
                title="Engaged Users"
                total={engagedUsers}
                color="primary"
                icon={'fluent:people-lock-20-filled'}
              />
            </Grid>
          </Grid>
          <Box m={2}></Box>
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                handleGetAnalytics()
                setAnalyticsDrawerOpen(false)
              }}
              variant="contained"
              size="large"
              style={{ width: '60%', backgroundColor: '#f67b3f' }}
            >
              Download Report
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
export default withStyles(styles)(AnalyticsDrawer)
