import React from 'react'
import style from './style'
import propTypes from './prop'
import { withStyles, Grid } from '@material-ui/core'
import AvatarListItem from './avatar-list-item'
const AvatarBody = props => {
  const {
    classes,
    queryResult,
    row,
    selectedList,
    changeSelected,
    dispatch,
    refresh,

    showSelect,
    showRowAction,
    md,
    lg,
  } = props

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Grid container className={classes.container}>
        {queryResult.results.map((entity, index) => (
          <React.Fragment key={index}>
            <AvatarListItem
              entity={entity}
              row={row}
              index={index}
              showSelect={showSelect}
              changeSelected={changeSelected}
              checked={selectedList.indexOf(entity.id) !== -1}
              showRowAction={showRowAction}
              dispatch={dispatch}
              refresh={refresh}
              md={md}
              lg={lg}
            />
          </React.Fragment>
        ))}
      </Grid>
    </div>
  )
}

AvatarBody.propTypes = propTypes

export default withStyles(style)(AvatarBody)
