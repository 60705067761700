const style = theme => ({
  bodyRow: {
    backgroundColor: 'transparent',
    // '&:nth-child(odd)': {
    //   backgroundColor: '#f9fafc',
    // },
  },
  childList: {
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingRight: '0!important',
    paddingTop: 0,
  },
  dragHandle: {},
  tableBody: {
    backgroundColor: '#FFF',
  },
})

export default style
