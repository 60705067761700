import React, { useState } from 'react'
import style from './style'
import propTypes from './prop'
import ClassNames from 'classnames'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormFactory from '../../form-item/factory'
import { FormStateModeType } from '../../../../form/props'
import {
  withStyles,
  Grid,
  Typography,
  IconButton,
  Collapse,
} from '@material-ui/core'

const FormItemGroup = props => {
  const {
    classes,
    expanded,
    collapsible,
    label,
    formItems,
    id,
    dispatch,
    stateMode,
  } = props
  const [expand, setExpand] = useState(expanded)

  /**
   *
   * @param {*} e
   */
  const handleExpand = e => {
    setExpand(!expand)
  }

  return (
    <Grid className={classes.root} container>
      {label && (
        <Grid
          className={classes.header}
          container
          justifyContent="center"
          alignItems="center"
          onClick={handleExpand}
        >
          <Grid item className={classes.labelWrapper}>
            <Typography variant="body1" className={classes.label}>
              {label}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton
              className={ClassNames(classes.expand, {
                [classes.expandOpen]: expand,
                [classes.iconHidden]: !collapsible,
              })}
              aria-expanded={expand}
            >
              <ExpandMoreIcon color="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      )}

      <Collapse
        className={classes.collapse}
        in={expand}
        timeout="auto"
        unmountOnExit
      >
        {formItems.map(formItem => {
          formItem.groupId = id
          formItem.dispatch = dispatch

          if (formItem.hidden) {
            return ''
          }
          if (stateMode == FormStateModeType.UPDATE && formItem.hiddenEdit) {
            return ''
          }

          return FormFactory({ ...formItem, stateMode })
        })}
      </Collapse>
    </Grid>
  )
}

FormItemGroup.propTypes = propTypes

export default withStyles(style)(FormItemGroup)
