import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import propTypes from './prop'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import translator from 'src/translate'

import CenterBox from '../../components/center-box'

import { Typography, Grid, Button } from '@material-ui/core'
class CheckDevice extends Component {
  componentWillMount() {
    const { match } = this.props
    var branchId = match.params.branchId
    var shortLink = match.params.shortLink

    if (isMobile) {
      window.location =
        'https://web.rootielearning.com/r/index.html?code=' +
        shortLink +
        '&link=' +
        branchId
    } else {
      setTimeout(
        () =>
          window.open(
            'https://web.rootielearning.com/r/index.html?code=' +
              shortLink +
              '&link=' +
              branchId,
            '_blank',
          ),
        1000,
      )
    }
  }

  /**
   *
   */
  render() {
    const { match } = this.props
    var branchId = match.params.branchId
    var shortLink = match.params.shortLink

    let url

    url =
      'https://web.rootielearning.com/r/index.html?code=' +
      shortLink +
      '&link=' +
      branchId

    return (
      <CenterBox>
        <Grid container justifyContent="center">
          <Grid item xs={4} style={{ marginTop: 100 }} spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="p"
                style={{
                  fontSize: 16,
                  color: 'rgb(90, 87, 87)',
                  display: 'block',
                  paddingBottom: 10,
                  textAlign: 'center',
                }}
              >
                {translator._('verifyEmail.redirect')}
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                href={url}
                target="_blank"
                color="primary"
                variant="contained"
              >
                {translator._('signUp.continue')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CenterBox>
    )
  }
}

CheckDevice.propTypes = propTypes

const mapStateToProps = response => ({
  componentBox: response.componentBox,
})
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(CheckDevice)),
)
