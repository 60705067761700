import React, { useState, useRef, useEffect } from 'react'
import translator from 'src/translate'
// @mui
import {
  OutlinedInput,
  Paper,
  Button,
  ClickAwayListener,
} from '@material-ui/core'
// redux
import { useDispatch } from 'react-redux'
import { createCourseCollectionAction } from 'src/reducers/course-collection/action'
// components
import Iconify from 'src/components/Iconify'

// ----------------------------------------------------------------------

export default function KanbanColumnAdd() {
  const dispatch = useDispatch()
  const collectionNameRef = useRef(null)

  const [collectionName, setCollectionName] = useState('')

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      if (collectionNameRef.current) {
        collectionNameRef.current.focus()
      }
    }
  }, [open])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeName = event => {
    setCollectionName(event.target.value)
  }

  const handleCreateColumn = async () => {
    if (collectionName.length < 1) {
      setOpen(false)
      return
    }
    try {
      if (collectionNameRef) {
        dispatch(createCourseCollectionAction(collectionName, response => {}))
        setCollectionName('')
      }
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  const handleKeyUp = event => {
    debugger
    if (event.key === 'Enter') {
      handleCreateColumn()
    }
  }

  return (
    <Paper
      sx={{
        width: '100%',
        height: '43px',
        borderRadius: '16px',
        background: '#f4f6f8',
      }}
    >
      {!open && (
        <Button
          style={{
            height: '43px',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            border: 'none',
            fontWeight: 400,
            fontSize: '14px',
            padding: '5px 20px',
          }}
          fullWidth
          size="medium"
          color="inherit"
          variant="outlined"
          startIcon={
            <Iconify
              icon={'eva:plus-fill'}
              style={{ color: '#F67B3F' }}
              width={20}
              height={20}
            />
          }
          onClick={handleOpen}
        >
          {translator._('courseCollection.collection.add')}
        </Button>
      )}

      {open && (
        <ClickAwayListener onClickAway={handleCreateColumn}>
          <OutlinedInput
            fullWidth
            placeholder="New Category"
            inputRef={collectionNameRef}
            value={collectionName}
            onChange={handleChangeName}
            onKeyUp={handleKeyUp}
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              height: '43px',
              borderRadius: '16px',
              outline: 'none',
              border: 'none',
            }}
          />
        </ClickAwayListener>
      )}
    </Paper>
  )
}
