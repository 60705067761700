import React, { useState } from 'react'
import translator from 'src/translate'
import { assignmentPatchAction } from 'src/reducers/assignment/action'
import { AssignmentFieldNames } from '../../model/assignment'
import styles from './styles'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { PatchContent, PatchData } from 'src/model/patch'
import {
  Button,
  Divider,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'

const RenameModal = props => {
  const { title, inputLabel, dispatch, entity, classes, refresh } = props
  const [name, setName] = useState(entity ? entity.name : '')

  const handleSubmit = () => {
    dispatch(
      assignmentPatchAction(
        new PatchData(
          new PatchContent(
            name,
            AssignmentFieldNames.NAME,
            PatchContent.OPERATION_REPLACE,
          ),
          entity[AssignmentFieldNames.ID],
        ),
        () => {
          handleCloseModal(true)
          refresh()
        },
      ),
    )
    entity.name = name
  }

  const handleTextFieldChange = e => {
    setName(e.target.value)
  }

  const handleCloseModal = willRefresh => {
    dispatch(componentBoxHideAction())
    willRefresh && refresh()
  }

  return (
    <div className={classes.namingModalContent}>
      <div className={classes.headerGroup}>
        <Typography variant={'h5'}>{title}</Typography>
      </div>

      <TextField
        id="outlined-basic"
        label={inputLabel}
        onChange={handleTextFieldChange}
        className={classes.textField}
        value={name}
      />
      <Divider />
      <div className={classes.buttonGroup}>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => handleCloseModal(false)}
        >
          {translator._('assignment.back')}
        </Button>
        <Button
          variant="contained"
          disabled={name.length >= 1 ? false : true}
          className={classes.continueButton}
          onClick={() => handleSubmit()}
        >
          {translator._('assignment.save')}
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(RenameModal)
