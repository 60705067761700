import PropTypes from 'prop-types'
import FormGroupPropTypes from './form-group'

export class FormUseModeType {
  static PREVIEW = 'preview'
  static EDIT = 'edit'
  static SEARCH = 'search'
}

export const FormUseModeTypes = [
  FormUseModeType.PREVIEW,
  FormUseModeType.EDIT,
  FormUseModeType.SEARCH,
]

export class FormUpdateMethodType {
  static PUT = 'put'
  static PATCH = 'patch'
}

export const FormUpdateMethodTypes = [
  FormUpdateMethodType.PUT,
  FormUpdateMethodType.PATCH,
]

export class FormStateModeType {
  static SAVE = 'save'
  static UPDATE = 'update'
}

export const FormStateModeTypes = [
  FormStateModeType.SAVE,
  FormStateModeType.UPDATE,
]

export class FormViewType {
  static VIEW_DRAWER = 'drawer'
  static VIEW_MODAL = 'modal'
  static VIEW_INLINE = 'inline'
}

export const FormViewTypes = [
  FormViewType.VIEW_DRAWER,
  FormViewType.VIEW_MODAL,
  FormViewType.VIEW_INLINE,
]

export const FormPropTypes = {
  addTitle: PropTypes.string,
  editTitle: PropTypes.string,
  formItemsSuggestion: PropTypes.arrayOf(PropTypes.any),
  groups: PropTypes.arrayOf(PropTypes.shape(FormGroupPropTypes)).isRequired,
}
