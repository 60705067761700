import React, { useState, useEffect } from 'react'
import styles from '../styles'
import withStyles from '@material-ui/core/styles/withStyles'
import { alpha, styled } from '@material-ui/core/styles'
import {
  Grid,
  IconButton,
  Typography,
  Button,
  Box,
  Divider,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core'

import { LimitTypeEnum } from 'src/model/limit'
import { getUserLimits } from 'src/api/limit'

function Features(props) {
  const {
    produceType,
    setProduceType,
    questionType,
    setQuestionType,
    maxQuestions,
    setMaxQuestions,
    languageCode,
    setLanguageCode,
    titleList,
    setTitleList,
    setTitle,
    title,
    roleType,
    classes,
  } = props

  const [maxQuestionLimit, setmaxQuestionLimit] = useState(['3'])
  const [limitLoading, setLimitLoading] = useState(true)

  const handleProduceType = (event, index) => {
    switch (index) {
      case 0:
        setQuestionType(event.target.value)
        break
      case 1:
        setMaxQuestions(event.target.value)
        break
      case 2:
        setLanguageCode(event.target.value)
        break
      default:
    }
  }
  const SelectStyled = styled(Select)(({ theme }) => ({
    '& .MuiInputBase-input': {
      paddingBottom: '18px',
      maxHeight: '20px',
    },
  }))
  let selectionOptions = []

  useEffect(() => {
    //This useEffect hook is used for getting user's limit count for course creation.
    setLimitLoading(true)
    getUserLimits().then(response => {
      let maxCardLimit = response.data.find(
        x => x.limit.type == LimitTypeEnum.AIQuestionsPerQuiz,
      )
      let upperLimit = maxCardLimit.limit.upperLimit

      let maxQuestionLimitArray = maxQuestionLimit
      for (let index = 5; index < 35; index += 5) {
        if (upperLimit < index) {
          maxQuestionLimitArray.push(index + ' (Upgrade)')
        } else {
          maxQuestionLimitArray.push(index.toString())
        }
      }
      setmaxQuestionLimit(maxQuestionLimitArray)

      setLimitLoading(false)
    })
  }, [])

  const selectionData = [
    {
      selectionName: 'Question Type',
      selectionOptions: [
        'Multiple Choice',
        'True/False',
        'Free Text',
        'Fill in the Blank',
      ],
      value: questionType,
    },
    {
      selectionName: 'Max. # of questions',
      selectionOptions: maxQuestionLimit,
      value: maxQuestions,
    }
  ]

  if (limitLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <CircularProgress size={16} />
      </div>
    )
  }

  return (
    <div style={{ marginTop: 8, width: '100%' }}>
      {selectionData.map((item, index) => (
        <div
          style={{ display: 'flex', alignContent: 'center', marginBottom: 8 }}
          key={item.selectionName}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '-webkit-fill-available',
            }}
          >
            <Typography
              style={{
                fontWeight: 500,
                fontSize: '14px',
                textAlign: 'center',
                display: 'flex',
              }}
            >
              {item.selectionName}
            </Typography>
          </div>
          <FormControl variant="filled" style={{ width: '100%' }} size="small">
            <SelectStyled
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={item.value}
              onChange={event => handleProduceType(event, index)}
            >
              {item.selectionOptions.map((item, index) => (
                <MenuItem
                  disabled={item.includes('Upgrade')}
                  key={item}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </SelectStyled>
          </FormControl>
        </div>
      ))}

      <div style={{ display: 'flex', alignContent: 'center', marginBottom: 8 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '-webkit-fill-available',
          }}
        >
          <Typography
            style={{
              fontWeight: 500,
              fontSize: '14px',
              textAlign: 'center',
              display: 'flex',
            }}
          >
            Language
          </Typography>
        </div>
        <FormControl variant="filled" style={{ width: '100%' }} size="small">
          <SelectStyled
            value={languageCode}
            onChange={event => handleProduceType(event, 2)}
          >
            <MenuItem value="auto">Auto </MenuItem>
            <MenuItem value="ab">Abkhazian </MenuItem>
            <MenuItem value="ace">Achinese </MenuItem>
            <MenuItem value="ach">Acoli </MenuItem>
            <MenuItem value="ada">Adangme </MenuItem>
            <MenuItem value="ady">Adyghe </MenuItem>
            <MenuItem value="aa">Afar </MenuItem>
            <MenuItem value="afh">Afrihili </MenuItem>
            <MenuItem value="af">Afrikaans </MenuItem>
            <MenuItem value="agq">Aghem </MenuItem>
            <MenuItem value="ain">Ainu </MenuItem>
            <MenuItem value="ak">Akan </MenuItem>
            <MenuItem value="akk">Akkadian </MenuItem>
            <MenuItem value="bss">Akoose </MenuItem>
            <MenuItem value="akz">Alabama </MenuItem>
            <MenuItem value="sq">Albanian </MenuItem>
            <MenuItem value="ale">Aleut </MenuItem>
            <MenuItem value="arq">Algerian Arabic </MenuItem>
            <MenuItem value="en_US">American English </MenuItem>
            <MenuItem value="ase">American Sign Language </MenuItem>
            <MenuItem value="am">Amharic </MenuItem>
            <MenuItem value="egy">Ancient Egyptian </MenuItem>
            <MenuItem value="grc">Ancient Greek </MenuItem>
            <MenuItem value="anp">Angika </MenuItem>
            <MenuItem value="njo">Ao Naga </MenuItem>
            <MenuItem value="ar">Arabic </MenuItem>
            <MenuItem value="an">Aragonese </MenuItem>
            <MenuItem value="arc">Aramaic </MenuItem>
            <MenuItem value="aro">Araona </MenuItem>
            <MenuItem value="arp">Arapaho </MenuItem>
            <MenuItem value="arw">Arawak </MenuItem>
            <MenuItem value="hy">Armenian </MenuItem>
            <MenuItem value="rup">Aromanian </MenuItem>
            <MenuItem value="frp">Arpitan </MenuItem>
            <MenuItem value="as">Assamese </MenuItem>
            <MenuItem value="ast">Asturian </MenuItem>
            <MenuItem value="asa">Asu </MenuItem>
            <MenuItem value="cch">Atsam </MenuItem>
            <MenuItem value="en_AU">Australian English </MenuItem>
            <MenuItem value="de_AT">Austrian German </MenuItem>
            <MenuItem value="av">Avaric </MenuItem>
            <MenuItem value="ae">Avestan </MenuItem>
            <MenuItem value="awa">Awadhi </MenuItem>
            <MenuItem value="ay">Aymara </MenuItem>
            <MenuItem value="az">Azerbaijani </MenuItem>
            <MenuItem value="bfq">Badaga </MenuItem>
            <MenuItem value="ksf">Bafia </MenuItem>
            <MenuItem value="bfd">Bafut </MenuItem>
            <MenuItem value="bqi">Bakhtiari </MenuItem>
            <MenuItem value="ban">Balinese </MenuItem>
            <MenuItem value="bal">Baluchi </MenuItem>
            <MenuItem value="bm">Bambara </MenuItem>
            <MenuItem value="bax">Bamun </MenuItem>
            <MenuItem value="bjn">Banjar </MenuItem>
            <MenuItem value="bas">Basaa </MenuItem>
            <MenuItem value="ba">Bashkir </MenuItem>
            <MenuItem value="eu">Basque </MenuItem>
            <MenuItem value="bbc">Batak Toba </MenuItem>
            <MenuItem value="bar">Bavarian </MenuItem>
            <MenuItem value="bej">Beja </MenuItem>
            <MenuItem value="be">Belarusian </MenuItem>
            <MenuItem value="bem">Bemba </MenuItem>
            <MenuItem value="bez">Bena </MenuItem>
            <MenuItem value="bn">Bengali </MenuItem>
            <MenuItem value="bew">Betawi </MenuItem>
            <MenuItem value="bho">Bhojpuri </MenuItem>
            <MenuItem value="bik">Bikol </MenuItem>
            <MenuItem value="bin">Bini </MenuItem>
            <MenuItem value="bpy">Bishnupriya </MenuItem>
            <MenuItem value="bi">Bislama </MenuItem>
            <MenuItem value="byn">Blin </MenuItem>
            <MenuItem value="zbl">Blissymbols </MenuItem>
            <MenuItem value="brx">Bodo </MenuItem>
            <MenuItem value="bs">Bosnian </MenuItem>
            <MenuItem value="brh">Brahui </MenuItem>
            <MenuItem value="bra">Braj </MenuItem>
            <MenuItem value="pt_BR">Brazilian Portuguese </MenuItem>
            <MenuItem value="br">Breton </MenuItem>
            <MenuItem value="en_GB">British English </MenuItem>
            <MenuItem value="bug">Buginese </MenuItem>
            <MenuItem value="bg">Bulgarian </MenuItem>
            <MenuItem value="bum">Bulu </MenuItem>
            <MenuItem value="bua">Buriat </MenuItem>
            <MenuItem value="my">Burmese </MenuItem>
            <MenuItem value="cad">Caddo </MenuItem>
            <MenuItem value="frc">Cajun French </MenuItem>
            <MenuItem value="en_CA">Canadian English </MenuItem>
            <MenuItem value="fr_CA">Canadian French </MenuItem>
            <MenuItem value="yue">Cantonese </MenuItem>
            <MenuItem value="cps">Capiznon </MenuItem>
            <MenuItem value="car">Carib </MenuItem>
            <MenuItem value="ca">Catalan </MenuItem>
            <MenuItem value="cay">Cayuga </MenuItem>
            <MenuItem value="ceb">Cebuano </MenuItem>
            <MenuItem value="tzm">Central Atlas Tamazight </MenuItem>
            <MenuItem value="dtp">Central Dusun </MenuItem>
            <MenuItem value="ckb">Central Kurdish </MenuItem>
            <MenuItem value="esu">Central Yupik </MenuItem>
            <MenuItem value="shu">Chadian Arabic </MenuItem>
            <MenuItem value="chg">Chagatai </MenuItem>
            <MenuItem value="ch">Chamorro </MenuItem>
            <MenuItem value="ce">Chechen </MenuItem>
            <MenuItem value="chr">Cherokee </MenuItem>
            <MenuItem value="chy">Cheyenne </MenuItem>
            <MenuItem value="chb">Chibcha </MenuItem>
            <MenuItem value="cgg">Chiga </MenuItem>
            <MenuItem value="qug">Chimborazo Highland Quichua </MenuItem>
            <MenuItem value="zh">Chinese </MenuItem>
            <MenuItem value="chn">Chinook Jargon </MenuItem>
            <MenuItem value="chp">Chipewyan </MenuItem>
            <MenuItem value="cho">Choctaw </MenuItem>
            <MenuItem value="cu">Church Slavic </MenuItem>
            <MenuItem value="chk">Chuukese </MenuItem>
            <MenuItem value="cv">Chuvash </MenuItem>
            <MenuItem value="nwc">Classical Newari </MenuItem>
            <MenuItem value="syc">Classical Syriac </MenuItem>
            <MenuItem value="ksh">Colognian </MenuItem>
            <MenuItem value="swb">Comorian </MenuItem>
            <MenuItem value="swc">Congo Swahili </MenuItem>
            <MenuItem value="cop">Coptic </MenuItem>
            <MenuItem value="kw">Cornish </MenuItem>
            <MenuItem value="co">Corsican </MenuItem>
            <MenuItem value="cr">Cree </MenuItem>
            <MenuItem value="mus">Creek </MenuItem>
            <MenuItem value="crh">Crimean Turkish </MenuItem>
            <MenuItem value="hr">Croatian </MenuItem>
            <MenuItem value="cs">Czech </MenuItem>
            <MenuItem value="dak">Dakota </MenuItem>
            <MenuItem value="da">Danish </MenuItem>
            <MenuItem value="dar">Dargwa </MenuItem>
            <MenuItem value="dzg">Dazaga </MenuItem>
            <MenuItem value="del">Delaware </MenuItem>
            <MenuItem value="din">Dinka </MenuItem>
            <MenuItem value="dv">Divehi </MenuItem>
            <MenuItem value="doi">Dogri </MenuItem>
            <MenuItem value="dgr">Dogrib </MenuItem>
            <MenuItem value="dua">Duala </MenuItem>
            <MenuItem value="nl">Dutch </MenuItem>
            <MenuItem value="dyu">Dyula </MenuItem>
            <MenuItem value="dz">Dzongkha </MenuItem>
            <MenuItem value="frs">Eastern Frisian </MenuItem>
            <MenuItem value="efi">Efik </MenuItem>
            <MenuItem value="arz">Egyptian Arabic </MenuItem>
            <MenuItem value="eka">Ekajuk </MenuItem>
            <MenuItem value="elx">Elamite </MenuItem>
            <MenuItem value="ebu">Embu </MenuItem>
            <MenuItem value="egl">Emilian </MenuItem>
            <MenuItem value="en">English </MenuItem>
            <MenuItem value="myv">Erzya </MenuItem>
            <MenuItem value="eo">Esperanto </MenuItem>
            <MenuItem value="et">Estonian </MenuItem>
            <MenuItem value="pt_PT">European Portuguese </MenuItem>
            <MenuItem value="es_ES">European Spanish </MenuItem>
            <MenuItem value="ee">Ewe </MenuItem>
            <MenuItem value="ewo">Ewondo </MenuItem>
            <MenuItem value="ext">Extremaduran </MenuItem>
            <MenuItem value="fan">Fang </MenuItem>
            <MenuItem value="fat">Fanti </MenuItem>
            <MenuItem value="fo">Faroese </MenuItem>
            <MenuItem value="hif">Fiji Hindi </MenuItem>
            <MenuItem value="fj">Fijian </MenuItem>
            <MenuItem value="fil">Filipino </MenuItem>
            <MenuItem value="fi">Finnish </MenuItem>
            <MenuItem value="nl_BE">Flemish </MenuItem>
            <MenuItem value="fon">Fon </MenuItem>
            <MenuItem value="gur">Frafra </MenuItem>
            <MenuItem value="fr">French </MenuItem>
            <MenuItem value="fur">Friulian </MenuItem>
            <MenuItem value="ff">Fulah </MenuItem>
            <MenuItem value="gaa">Ga </MenuItem>
            <MenuItem value="gag">Gagauz </MenuItem>
            <MenuItem value="gl">Galician </MenuItem>
            <MenuItem value="gan">Gan Chinese </MenuItem>
            <MenuItem value="lg">Ganda </MenuItem>
            <MenuItem value="gay">Gayo </MenuItem>
            <MenuItem value="gba">Gbaya </MenuItem>
            <MenuItem value="gez">Geez </MenuItem>
            <MenuItem value="ka">Georgian </MenuItem>
            <MenuItem value="de">German </MenuItem>
            <MenuItem value="aln">Gheg Albanian </MenuItem>
            <MenuItem value="bbj">Ghomala </MenuItem>
            <MenuItem value="glk">Gilaki </MenuItem>
            <MenuItem value="gil">Gilbertese </MenuItem>
            <MenuItem value="gom">Goan Konkani </MenuItem>
            <MenuItem value="gon">Gondi </MenuItem>
            <MenuItem value="gor">Gorontalo </MenuItem>
            <MenuItem value="got">Gothic </MenuItem>
            <MenuItem value="grb">Grebo </MenuItem>
            <MenuItem value="el">Greek </MenuItem>
            <MenuItem value="gn">Guarani </MenuItem>
            <MenuItem value="gu">Gujarati </MenuItem>
            <MenuItem value="guz">Gusii </MenuItem>
            <MenuItem value="gwi">Gwichʼin </MenuItem>
            <MenuItem value="hai">Haida </MenuItem>
            <MenuItem value="ht">Haitian </MenuItem>
            <MenuItem value="hak">Hakka Chinese </MenuItem>
            <MenuItem value="ha">Hausa </MenuItem>
            <MenuItem value="haw">Hawaiian </MenuItem>
            <MenuItem value="he">Hebrew </MenuItem>
            <MenuItem value="hz">Herero </MenuItem>
            <MenuItem value="hil">Hiligaynon </MenuItem>
            <MenuItem value="hi">Hindi </MenuItem>
            <MenuItem value="ho">Hiri Motu </MenuItem>
            <MenuItem value="hit">Hittite </MenuItem>
            <MenuItem value="hmn">Hmong </MenuItem>
            <MenuItem value="hu">Hungarian </MenuItem>
            <MenuItem value="hup">Hupa </MenuItem>
            <MenuItem value="iba">Iban </MenuItem>
            <MenuItem value="ibb">Ibibio </MenuItem>
            <MenuItem value="is">Icelandic </MenuItem>
            <MenuItem value="io">Ido </MenuItem>
            <MenuItem value="ig">Igbo </MenuItem>
            <MenuItem value="ilo">Iloko </MenuItem>
            <MenuItem value="smn">Inari Sami </MenuItem>
            <MenuItem value="id">Indonesian </MenuItem>
            <MenuItem value="izh">Ingrian </MenuItem>
            <MenuItem value="inh">Ingush </MenuItem>
            <MenuItem value="ia">Interlingua </MenuItem>
            <MenuItem value="ie">Interlingue </MenuItem>
            <MenuItem value="iu">Inuktitut </MenuItem>
            <MenuItem value="ik">Inupiaq </MenuItem>
            <MenuItem value="ga">Irish </MenuItem>
            <MenuItem value="it">Italian </MenuItem>
            <MenuItem value="jam">Jamaican Creole English </MenuItem>
            <MenuItem value="ja">Japanese </MenuItem>
            <MenuItem value="jv">Javanese </MenuItem>
            <MenuItem value="kaj">Jju </MenuItem>
            <MenuItem value="dyo">Jola-Fonyi </MenuItem>
            <MenuItem value="jrb">Judeo-Arabic </MenuItem>
            <MenuItem value="jpr">Judeo-Persian </MenuItem>
            <MenuItem value="jut">Jutish </MenuItem>
            <MenuItem value="kbd">Kabardian </MenuItem>
            <MenuItem value="kea">Kabuverdianu </MenuItem>
            <MenuItem value="kab">Kabyle </MenuItem>
            <MenuItem value="kac">Kachin </MenuItem>
            <MenuItem value="kgp">Kaingang </MenuItem>
            <MenuItem value="kkj">Kako </MenuItem>
            <MenuItem value="kl">Kalaallisut </MenuItem>
            <MenuItem value="kln">Kalenjin </MenuItem>
            <MenuItem value="xal">Kalmyk </MenuItem>
            <MenuItem value="kam">Kamba </MenuItem>
            <MenuItem value="kbl">Kanembu </MenuItem>
            <MenuItem value="kn">Kannada </MenuItem>
            <MenuItem value="kr">Kanuri </MenuItem>
            <MenuItem value="kaa">Kara-Kalpak </MenuItem>
            <MenuItem value="krc">Karachay-Balkar </MenuItem>
            <MenuItem value="krl">Karelian </MenuItem>
            <MenuItem value="ks">Kashmiri </MenuItem>
            <MenuItem value="csb">Kashubian </MenuItem>
            <MenuItem value="kaw">Kawi </MenuItem>
            <MenuItem value="kk">Kazakh </MenuItem>
            <MenuItem value="ken">Kenyang </MenuItem>
            <MenuItem value="kha">Khasi </MenuItem>
            <MenuItem value="km">Khmer </MenuItem>
            <MenuItem value="kho">Khotanese </MenuItem>
            <MenuItem value="khw">Khowar </MenuItem>
            <MenuItem value="ki">Kikuyu </MenuItem>
            <MenuItem value="kmb">Kimbundu </MenuItem>
            <MenuItem value="krj">Kinaray-a </MenuItem>
            <MenuItem value="rw">Kinyarwanda </MenuItem>
            <MenuItem value="kiu">Kirmanjki </MenuItem>
            <MenuItem value="tlh">Klingon </MenuItem>
            <MenuItem value="bkm">Kom </MenuItem>
            <MenuItem value="kv">Komi </MenuItem>
            <MenuItem value="koi">Komi-Permyak </MenuItem>
            <MenuItem value="kg">Kongo </MenuItem>
            <MenuItem value="kok">Konkani </MenuItem>
            <MenuItem value="ko">Korean </MenuItem>
            <MenuItem value="kfo">Koro </MenuItem>
            <MenuItem value="kos">Kosraean </MenuItem>
            <MenuItem value="avk">Kotava </MenuItem>
            <MenuItem value="khq">Koyra Chiini </MenuItem>
            <MenuItem value="ses">Koyraboro Senni </MenuItem>
            <MenuItem value="kpe">Kpelle </MenuItem>
            <MenuItem value="kri">Krio </MenuItem>
            <MenuItem value="kj">Kuanyama </MenuItem>
            <MenuItem value="kum">Kumyk </MenuItem>
            <MenuItem value="ku">Kurdish </MenuItem>
            <MenuItem value="kru">Kurukh </MenuItem>
            <MenuItem value="kut">Kutenai </MenuItem>
            <MenuItem value="nmg">Kwasio </MenuItem>
            <MenuItem value="ky">Kyrgyz </MenuItem>
            <MenuItem value="quc">Kʼicheʼ </MenuItem>
            <MenuItem value="lad">Ladino </MenuItem>
            <MenuItem value="lah">Lahnda </MenuItem>
            <MenuItem value="lkt">Lakota </MenuItem>
            <MenuItem value="lam">Lamba </MenuItem>
            <MenuItem value="lag">Langi </MenuItem>
            <MenuItem value="lo">Lao </MenuItem>
            <MenuItem value="ltg">Latgalian </MenuItem>
            <MenuItem value="la">Latin </MenuItem>
            <MenuItem value="es_419">Latin American Spanish </MenuItem>
            <MenuItem value="lv">Latvian </MenuItem>
            <MenuItem value="lzz">Laz </MenuItem>
            <MenuItem value="lez">Lezghian </MenuItem>
            <MenuItem value="lij">Ligurian </MenuItem>
            <MenuItem value="li">Limburgish </MenuItem>
            <MenuItem value="ln">Lingala </MenuItem>
            <MenuItem value="lfn">Lingua Franca Nova </MenuItem>
            <MenuItem value="lzh">Literary Chinese </MenuItem>
            <MenuItem value="lt">Lithuanian </MenuItem>
            <MenuItem value="liv">Livonian </MenuItem>
            <MenuItem value="jbo">Lojban </MenuItem>
            <MenuItem value="lmo">Lombard </MenuItem>
            <MenuItem value="nds">Low German </MenuItem>
            <MenuItem value="sli">Lower Silesian </MenuItem>
            <MenuItem value="dsb">Lower Sorbian </MenuItem>
            <MenuItem value="loz">Lozi </MenuItem>
            <MenuItem value="lu">Luba-Katanga </MenuItem>
            <MenuItem value="lua">Luba-Lulua </MenuItem>
            <MenuItem value="lui">Luiseno </MenuItem>
            <MenuItem value="smj">Lule Sami </MenuItem>
            <MenuItem value="lun">Lunda </MenuItem>
            <MenuItem value="luo">Luo </MenuItem>
            <MenuItem value="lb">Luxembourgish </MenuItem>
            <MenuItem value="luy">Luyia </MenuItem>
            <MenuItem value="mde">Maba </MenuItem>
            <MenuItem value="mk">Macedonian </MenuItem>
            <MenuItem value="jmc">Machame </MenuItem>
            <MenuItem value="mad">Madurese </MenuItem>
            <MenuItem value="maf">Mafa </MenuItem>
            <MenuItem value="mag">Magahi </MenuItem>
            <MenuItem value="vmf">Main-Franconian </MenuItem>
            <MenuItem value="mai">Maithili </MenuItem>
            <MenuItem value="mak">Makasar </MenuItem>
            <MenuItem value="mgh">Makhuwa-Meetto </MenuItem>
            <MenuItem value="kde">Makonde </MenuItem>
            <MenuItem value="mg">Malagasy </MenuItem>
            <MenuItem value="ms">Malay </MenuItem>
            <MenuItem value="ml">Malayalam </MenuItem>
            <MenuItem value="mt">Maltese </MenuItem>
            <MenuItem value="mnc">Manchu </MenuItem>
            <MenuItem value="mdr">Mandar </MenuItem>
            <MenuItem value="man">Mandingo </MenuItem>
            <MenuItem value="mni">Manipuri </MenuItem>
            <MenuItem value="gv">Manx </MenuItem>
            <MenuItem value="mi">Maori </MenuItem>
            <MenuItem value="arn">Mapuche </MenuItem>
            <MenuItem value="mr">Marathi </MenuItem>
            <MenuItem value="chm">Mari </MenuItem>
            <MenuItem value="mh">Marshallese </MenuItem>
            <MenuItem value="mwr">Marwari </MenuItem>
            <MenuItem value="mas">Masai </MenuItem>
            <MenuItem value="mzn">Mazanderani </MenuItem>
            <MenuItem value="byv">Medumba </MenuItem>
            <MenuItem value="men">Mende </MenuItem>
            <MenuItem value="mwv">Mentawai </MenuItem>
            <MenuItem value="mer">Meru </MenuItem>
            <MenuItem value="mgo">Metaʼ </MenuItem>
            <MenuItem value="es_MX">Mexican Spanish </MenuItem>
            <MenuItem value="mic">Micmac </MenuItem>
            <MenuItem value="dum">Middle Dutch </MenuItem>
            <MenuItem value="enm">Middle English </MenuItem>
            <MenuItem value="frm">Middle French </MenuItem>
            <MenuItem value="gmh">Middle High German </MenuItem>
            <MenuItem value="mga">Middle Irish </MenuItem>
            <MenuItem value="nan">Min Nan Chinese </MenuItem>
            <MenuItem value="min">Minangkabau </MenuItem>
            <MenuItem value="xmf">Mingrelian </MenuItem>
            <MenuItem value="mwl">Mirandese </MenuItem>
            <MenuItem value="lus">Mizo </MenuItem>
            <MenuItem value="ar_001">Modern Standard Arabic </MenuItem>
            <MenuItem value="moh">Mohawk </MenuItem>
            <MenuItem value="mdf">Moksha </MenuItem>
            <MenuItem value="ro_MD">Moldavian </MenuItem>
            <MenuItem value="lol">Mongo </MenuItem>
            <MenuItem value="mn">Mongolian </MenuItem>
            <MenuItem value="mfe">Morisyen </MenuItem>
            <MenuItem value="ary">Moroccan Arabic </MenuItem>
            <MenuItem value="mos">Mossi </MenuItem>
            <MenuItem value="mul">Multiple Languages </MenuItem>
            <MenuItem value="mua">Mundang </MenuItem>
            <MenuItem value="ttt">Muslim Tat </MenuItem>
            <MenuItem value="mye">Myene </MenuItem>
            <MenuItem value="naq">Nama </MenuItem>
            <MenuItem value="na">Nauru </MenuItem>
            <MenuItem value="nv">Navajo </MenuItem>
            <MenuItem value="ng">Ndonga </MenuItem>
            <MenuItem value="nap">Neapolitan </MenuItem>
            <MenuItem value="ne">Nepali </MenuItem>
            <MenuItem value="new">Newari </MenuItem>
            <MenuItem value="sba">Ngambay </MenuItem>
            <MenuItem value="nnh">Ngiemboon </MenuItem>
            <MenuItem value="jgo">Ngomba </MenuItem>
            <MenuItem value="yrl">Nheengatu </MenuItem>
            <MenuItem value="nia">Nias </MenuItem>
            <MenuItem value="niu">Niuean </MenuItem>
            <MenuItem value="zxx">No linguistic content </MenuItem>
            <MenuItem value="nog">Nogai </MenuItem>
            <MenuItem value="nd">North Ndebele </MenuItem>
            <MenuItem value="frr">Northern Frisian </MenuItem>
            <MenuItem value="se">Northern Sami </MenuItem>
            <MenuItem value="nso">Northern Sotho </MenuItem>
            <MenuItem value="no">Norwegian </MenuItem>
            <MenuItem value="nb">Norwegian Bokmål </MenuItem>
            <MenuItem value="nn">Norwegian Nynorsk </MenuItem>
            <MenuItem value="nov">Novial </MenuItem>
            <MenuItem value="nus">Nuer </MenuItem>
            <MenuItem value="nym">Nyamwezi </MenuItem>
            <MenuItem value="ny">Nyanja </MenuItem>
            <MenuItem value="nyn">Nyankole </MenuItem>
            <MenuItem value="tog">Nyasa Tonga </MenuItem>
            <MenuItem value="nyo">Nyoro </MenuItem>
            <MenuItem value="nzi">Nzima </MenuItem>
            <MenuItem value="nqo">NʼKo </MenuItem>
            <MenuItem value="oc">Occitan </MenuItem>
            <MenuItem value="oj">Ojibwa </MenuItem>
            <MenuItem value="ang">Old English </MenuItem>
            <MenuItem value="fro">Old French </MenuItem>
            <MenuItem value="goh">Old High German </MenuItem>
            <MenuItem value="sga">Old Irish </MenuItem>
            <MenuItem value="non">Old Norse </MenuItem>
            <MenuItem value="peo">Old Persian </MenuItem>
            <MenuItem value="pro">Old Provençal </MenuItem>
            <MenuItem value="or">Oriya </MenuItem>
            <MenuItem value="om">Oromo </MenuItem>
            <MenuItem value="osa">Osage </MenuItem>
            <MenuItem value="os">Ossetic </MenuItem>
            <MenuItem value="ota">Ottoman Turkish </MenuItem>
            <MenuItem value="pal">Pahlavi </MenuItem>
            <MenuItem value="pfl">Palatine German </MenuItem>
            <MenuItem value="pau">Palauan </MenuItem>
            <MenuItem value="pi">Pali </MenuItem>
            <MenuItem value="pam">Pampanga </MenuItem>
            <MenuItem value="pag">Pangasinan </MenuItem>
            <MenuItem value="pap">Papiamento </MenuItem>
            <MenuItem value="ps">Pashto </MenuItem>
            <MenuItem value="pdc">Pennsylvania German </MenuItem>
            <MenuItem value="fa">Persian </MenuItem>
            <MenuItem value="phn">Phoenician </MenuItem>
            <MenuItem value="pcd">Picard </MenuItem>
            <MenuItem value="pms">Piedmontese </MenuItem>
            <MenuItem value="pdt">Plautdietsch </MenuItem>
            <MenuItem value="pon">Pohnpeian </MenuItem>
            <MenuItem value="pl">Polish </MenuItem>
            <MenuItem value="pnt">Pontic </MenuItem>
            <MenuItem value="pt">Portuguese </MenuItem>
            <MenuItem value="prg">Prussian </MenuItem>
            <MenuItem value="pa">Punjabi </MenuItem>
            <MenuItem value="qu">Quechua </MenuItem>
            <MenuItem value="raj">Rajasthani </MenuItem>
            <MenuItem value="rap">Rapanui </MenuItem>
            <MenuItem value="rar">Rarotongan </MenuItem>
            <MenuItem value="rif">Riffian </MenuItem>
            <MenuItem value="rgn">Romagnol </MenuItem>
            <MenuItem value="ro">Romanian </MenuItem>
            <MenuItem value="rm">Romansh </MenuItem>
            <MenuItem value="rom">Romany </MenuItem>
            <MenuItem value="rof">Rombo </MenuItem>
            <MenuItem value="root">Root </MenuItem>
            <MenuItem value="rtm">Rotuman </MenuItem>
            <MenuItem value="rug">Roviana </MenuItem>
            <MenuItem value="rn">Rundi </MenuItem>
            <MenuItem value="ru">Russian </MenuItem>
            <MenuItem value="rue">Rusyn </MenuItem>
            <MenuItem value="rwk">Rwa </MenuItem>
            <MenuItem value="ssy">Saho </MenuItem>
            <MenuItem value="sah">Sakha </MenuItem>
            <MenuItem value="sam">Samaritan Aramaic </MenuItem>
            <MenuItem value="saq">Samburu </MenuItem>
            <MenuItem value="sm">Samoan </MenuItem>
            <MenuItem value="sgs">Samogitian </MenuItem>
            <MenuItem value="sad">Sandawe </MenuItem>
            <MenuItem value="sg">Sango </MenuItem>
            <MenuItem value="sbp">Sangu </MenuItem>
            <MenuItem value="sa">Sanskrit </MenuItem>
            <MenuItem value="sat">Santali </MenuItem>
            <MenuItem value="sc">Sardinian </MenuItem>
            <MenuItem value="sas">Sasak </MenuItem>
            <MenuItem value="sdc">Sassarese Sardinian </MenuItem>
            <MenuItem value="stq">Saterland Frisian </MenuItem>
            <MenuItem value="saz">Saurashtra </MenuItem>
            <MenuItem value="sco">Scots </MenuItem>
            <MenuItem value="gd">Scottish Gaelic </MenuItem>
            <MenuItem value="sly">Selayar </MenuItem>
            <MenuItem value="sel">Selkup </MenuItem>
            <MenuItem value="seh">Sena </MenuItem>
            <MenuItem value="see">Seneca </MenuItem>
            <MenuItem value="sr">Serbian </MenuItem>
            <MenuItem value="sh">Serbo-Croatian </MenuItem>
            <MenuItem value="srr">Serer </MenuItem>
            <MenuItem value="sei">Seri </MenuItem>
            <MenuItem value="ksb">Shambala </MenuItem>
            <MenuItem value="shn">Shan </MenuItem>
            <MenuItem value="sn">Shona </MenuItem>
            <MenuItem value="ii">Sichuan Yi </MenuItem>
            <MenuItem value="scn">Sicilian </MenuItem>
            <MenuItem value="sid">Sidamo </MenuItem>
            <MenuItem value="bla">Siksika </MenuItem>
            <MenuItem value="szl">Silesian </MenuItem>
            <MenuItem value="zh_Hans">Simplified Chinese </MenuItem>
            <MenuItem value="sd">Sindhi </MenuItem>
            <MenuItem value="si">Sinhala </MenuItem>
            <MenuItem value="sms">Skolt Sami </MenuItem>
            <MenuItem value="den">Slave </MenuItem>
            <MenuItem value="sk">Slovak </MenuItem>
            <MenuItem value="sl">Slovenian </MenuItem>
            <MenuItem value="xog">Soga </MenuItem>
            <MenuItem value="sog">Sogdien </MenuItem>
            <MenuItem value="so">Somali </MenuItem>
            <MenuItem value="snk">Soninke </MenuItem>
            <MenuItem value="azb">South Azerbaijani </MenuItem>
            <MenuItem value="nr">South Ndebele </MenuItem>
            <MenuItem value="alt">Southern Altai </MenuItem>
            <MenuItem value="sma">Southern Sami </MenuItem>
            <MenuItem value="st">Southern Sotho </MenuItem>
            <MenuItem value="es">Spanish </MenuItem>
            <MenuItem value="srn">Sranan Tongo </MenuItem>
            <MenuItem value="zgh">Standard Moroccan Tamazight </MenuItem>
            <MenuItem value="suk">Sukuma </MenuItem>
            <MenuItem value="sux">Sumerian </MenuItem>
            <MenuItem value="su">Sundanese </MenuItem>
            <MenuItem value="sus">Susu </MenuItem>
            <MenuItem value="sw">Swahili </MenuItem>
            <MenuItem value="ss">Swati </MenuItem>
            <MenuItem value="sv">Swedish </MenuItem>
            <MenuItem value="fr_CH">Swiss French </MenuItem>
            <MenuItem value="gsw">Swiss German </MenuItem>
            <MenuItem value="de_CH">Swiss High German </MenuItem>
            <MenuItem value="syr">Syriac </MenuItem>
            <MenuItem value="shi">Tachelhit </MenuItem>
            <MenuItem value="tl">Tagalog </MenuItem>
            <MenuItem value="ty">Tahitian </MenuItem>
            <MenuItem value="dav">Taita </MenuItem>
            <MenuItem value="tg">Tajik </MenuItem>
            <MenuItem value="tly">Talysh </MenuItem>
            <MenuItem value="tmh">Tamashek </MenuItem>
            <MenuItem value="ta">Tamil </MenuItem>
            <MenuItem value="trv">Taroko </MenuItem>
            <MenuItem value="twq">Tasawaq </MenuItem>
            <MenuItem value="tt">Tatar </MenuItem>
            <MenuItem value="te">Telugu </MenuItem>
            <MenuItem value="ter">Tereno </MenuItem>
            <MenuItem value="teo">Teso </MenuItem>
            <MenuItem value="tet">Tetum </MenuItem>
            <MenuItem value="th">Thai </MenuItem>
            <MenuItem value="bo">Tibetan </MenuItem>
            <MenuItem value="tig">Tigre </MenuItem>
            <MenuItem value="ti">Tigrinya </MenuItem>
            <MenuItem value="tem">Timne </MenuItem>
            <MenuItem value="tiv">Tiv </MenuItem>
            <MenuItem value="tli">Tlingit </MenuItem>
            <MenuItem value="tpi">Tok Pisin </MenuItem>
            <MenuItem value="tkl">Tokelau </MenuItem>
            <MenuItem value="to">Tongan </MenuItem>
            <MenuItem value="fit">Tornedalen Finnish </MenuItem>
            <MenuItem value="zh_Hant">Traditional Chinese </MenuItem>
            <MenuItem value="tkr">Tsakhur </MenuItem>
            <MenuItem value="tsd">Tsakonian </MenuItem>
            <MenuItem value="tsi">Tsimshian </MenuItem>
            <MenuItem value="ts">Tsonga </MenuItem>
            <MenuItem value="tn">Tswana </MenuItem>
            <MenuItem value="tcy">Tulu </MenuItem>
            <MenuItem value="tum">Tumbuka </MenuItem>
            <MenuItem value="aeb">Tunisian Arabic </MenuItem>
            <MenuItem value="tr">Turkish </MenuItem>
            <MenuItem value="tk">Turkmen </MenuItem>
            <MenuItem value="tru">Turoyo </MenuItem>
            <MenuItem value="tvl">Tuvalu </MenuItem>
            <MenuItem value="tyv">Tuvinian </MenuItem>
            <MenuItem value="tw">Twi </MenuItem>
            <MenuItem value="kcg">Tyap </MenuItem>
            <MenuItem value="udm">Udmurt </MenuItem>
            <MenuItem value="uga">Ugaritic </MenuItem>
            <MenuItem value="uk">Ukrainian </MenuItem>
            <MenuItem value="umb">Umbundu </MenuItem>
            <MenuItem value="und">Unknown Language </MenuItem>
            <MenuItem value="hsb">Upper Sorbian </MenuItem>
            <MenuItem value="ur">Urdu </MenuItem>
            <MenuItem value="ug">Uyghur </MenuItem>
            <MenuItem value="uz">Uzbek </MenuItem>
            <MenuItem value="vai">Vai </MenuItem>
            <MenuItem value="ve">Venda </MenuItem>
            <MenuItem value="vec">Venetian </MenuItem>
            <MenuItem value="vep">Veps </MenuItem>
            <MenuItem value="vi">Vietnamese </MenuItem>
            <MenuItem value="vo">Volapük </MenuItem>
            <MenuItem value="vro">Võro </MenuItem>
            <MenuItem value="vot">Votic </MenuItem>
            <MenuItem value="vun">Vunjo </MenuItem>
            <MenuItem value="wa">Walloon </MenuItem>
            <MenuItem value="wae">Walser </MenuItem>
            <MenuItem value="war">Waray </MenuItem>
            <MenuItem value="wbp">Warlpiri </MenuItem>
            <MenuItem value="was">Washo </MenuItem>
            <MenuItem value="guc">Wayuu </MenuItem>
            <MenuItem value="cy">Welsh </MenuItem>
            <MenuItem value="vls">West Flemish </MenuItem>
            <MenuItem value="fy">Western Frisian </MenuItem>
            <MenuItem value="mrj">Western Mari </MenuItem>
            <MenuItem value="wal">Wolaytta </MenuItem>
            <MenuItem value="wo">Wolof </MenuItem>
            <MenuItem value="wuu">Wu Chinese </MenuItem>
            <MenuItem value="xh">Xhosa </MenuItem>
            <MenuItem value="hsn">Xiang Chinese </MenuItem>
            <MenuItem value="yav">Yangben </MenuItem>
            <MenuItem value="yao">Yao </MenuItem>
            <MenuItem value="yap">Yapese </MenuItem>
            <MenuItem value="ybb">Yemba </MenuItem>
            <MenuItem value="yi">Yiddish </MenuItem>
            <MenuItem value="yo">Yoruba </MenuItem>
            <MenuItem value="zap">Zapotec </MenuItem>
            <MenuItem value="dje">Zarma </MenuItem>
            <MenuItem value="zza">Zaza </MenuItem>
            <MenuItem value="zea">Zeelandic </MenuItem>
            <MenuItem value="zen">Zenaga </MenuItem>
            <MenuItem value="za">Zhuang </MenuItem>
            <MenuItem value="gbz">Zoroastrian Dari </MenuItem>
            <MenuItem value="zu">Zulu </MenuItem>
            <MenuItem value="zun">Zuni </MenuItem>
          </SelectStyled>
        </FormControl>
      </div>
    </div>
  )
}

export default withStyles(styles)(Features)
