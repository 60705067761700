export const config = {
  //     cancelToken: new CancelToken(cancel => {
  //         return null
  //     }),
  //     maxContentLength: 2000,
  //     maxRedirects: 5,
  //     responseType: 'json',
  //timeout: 1000000000000,
  //     validateStatus: status => status >= 200 && status < 300,
  //     withCredentials: false,
}
