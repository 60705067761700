const style = theme => ({
  root: {},
  inputBase: {
    flex: 1,
    border: 'none',
    padding: 0,
  },
  formGroup: {
    flexDirection: 'row',
    minHeight: 30,
    maxHeight: 300,
    overflowX: 'hidden',
  },

  formControlLabel: {
    width: '100%',
  },
  checkbox: {
    height: 'auto',
    width: 'auto',
    marginRight: 5,
  },
  select: {
    //padding: 0,
    //borderColor: 'transparent',
    minWidth: 200,
  },
  input: {
    borderColor: 'transparent',
  },
  textarea: {
    padding: '16px 8px',
    border: '1px solid #c4c4c4',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  endAdornmentIcon: {
    color: '#757575',
    fontSize: 16,
  },
  listItemWrapper: {
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  listItemRemoveButton: {
    position: 'absolute',
    top: -15,
    right: -10,
    padding: 0,
    color: 'red',
  },
  tree: {
    maxHeight: 220,
    overflowX: 'hidden',
  },
  mediaImage: {
    maxWidth: 150,
    maxHeight: 150,
  },
})

export default style
