import React from 'react'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SendIcon from '@material-ui/icons/Send'
import Iconify from 'src/components/Iconify'
import NamingModal from '../naming-modal'
import { useDispatch } from 'react-redux'
import { AssignmentTypes } from 'src/model/assignment'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

export default function CustomizedMenus(props) {
  const { classes, entity, history, id, collectionName, courses } = props

  //const [isNamingModalOpen, setNamingModalOpen] = useState(false)
  //const [anchorEl, setAnchorEl] = React.useState(null)
  //const handleClick = event => {
  //  setAnchorEl(event.currentTarget)
  //}
  //const handleClose = () => {
  //  setAnchorEl(null)
  //}
  const dispatch = useDispatch()

  const handleCreateRedirect = () => {
    history.push('/create-course/' + id)
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="secondary"
        fullWidth
        size="large"
        onClick={() => handleCreateRedirect()}
        startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
      >
        Create a Mini-Course
      </Button>

      {/*<StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => setNamingModalOpen(true)}>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Create from scratch" />
        </StyledMenuItem>
        <StyledMenuItem to="/ai-assistant" component={RouterLink}>
          <ListItemIcon></ListItemIcon>
          <ListItemText primary="Create with AI-Assistant" />
        </StyledMenuItem>
  </StyledMenu>

      {isNamingModalOpen && (
        <NamingModal
          courses={courses}
          classes={classes}
          dispatch={dispatch}
          id={id}
          entity={entity}
          history={history}
          handleCloseModal={() => setNamingModalOpen(false)}
          isModalOpen={isNamingModalOpen}
          collectionName={collectionName}
          selectedAssignmentType={AssignmentTypes.ONETIME}
        />
      )}
      */}
    </div>
  )
}
