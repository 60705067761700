import { put, call, take, fork } from 'redux-saga/effects';
import { importCourseTxtService } from '../../api/assignment';
//import { questionPatchSuccess } from '../../reducers/question/action'
import { importCourseTxtSuccess } from '../../reducers/assignment/action';
import { IMPORT_COURSE_TXT } from '../../reducers/assignment/type';
import generalSaga from '../general';
import { operationSuccess, operationFail } from '../../reducers/operation/action';

/**
 *
 * @param {*} payload
 */
function* importCourseTxtFlow(payload) {
  try {
    const { entity, callback } = payload;
    const response = yield call(importCourseTxtService, entity);

    yield put(operationSuccess());
    yield put(importCourseTxtSuccess(response.data));
    callback(response.data);
  } catch (error) {
    yield put(operationFail(error));
  }
}

/**
 *
 */
export default function* importCourseTxtWatcher() {
  while (true) {
    const action = yield take(IMPORT_COURSE_TXT);
    const { payload } = action;

    yield fork(generalSaga, importCourseTxtFlow, payload);
  }
}
