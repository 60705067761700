import { put, call, take, fork } from 'redux-saga/effects'
import { userGetSelfService } from '../../api/user'
import { userGetSelfSuccess } from '../../reducers/user/action'
import { USER_GET_SELF } from '../../reducers/user/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* userGetSelfFlow(payload) {
  try {
    const { id } = payload
    const response = yield call(userGetSelfService, id)

    yield put(userGetSelfSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* userGetSelfWatcher() {
  while (true) {
    const action = yield take(USER_GET_SELF)
    const { payload } = action

    yield fork(generalSaga, userGetSelfFlow, payload)
  }
}
