import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch } from 'react-redux'
import Page from '../../components/Page'
import style from './style'
// material
import { styled } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Typography,
  withStyles,
  TextField,
  Button,
} from '@material-ui/core'
import translator from 'src/translate'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
// components
import SuccessCode from './success'
import Subscription from './subscription'
//
import { componentBoxShowAction } from '../../reducers/component-box/action'
import ComponentBoxContent from '../../model/component-box'
import { codeVerifierAction } from '../../reducers/common/action'
import { getUserLimits } from '../../api/limit'
import UserType from '../../model/user-types'

const stripePromise = loadStripe(
  /*process.env.STRIPE_KEY*/ 'pk_live_51JZBNlC5V6vmi0g2HiJM57Ad4dBfu287CBKNNoCoOwe0j9UW9CiK3SXnp0f8Nd2nCnM9lIAYQRqdbMTcr8SGTJ3B00f9vz2KdX',
)

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10),
}))

// ----------------------------------------------------------------------

function Pricing(props) {
  const dispatch = useDispatch()
  const { classes, history } = props

  //const [stripeError, setStripeError] = useState()
  //const [loading, setLoading] = useState()

  const [code, setCode] = useState('')
  const [userType, setType] = useState('')

  useEffect(() => {
    getUserLimits().then(response => {
      let userType = response.data[0].limit.roleType
      setType(userType)
    })
  }, [])
  const routeChange = () => {
    let path = `/`
    history.push(path)
  }
  const onHandleAppsumoCode = event => {
    
    if (code != '') {
      dispatch(
        codeVerifierAction(code, () => {
          successCode()
        }),
      )
    }
  }
  const onHandleSubscription = event => {
    
    if (code != '') {
      subscription()
    }
  }
  const successCode = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <SuccessCode history={history} dispatch={dispatch} />,
          { hideCloseButton: true },
        ),
      ),
    )
  }
  const subscription = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <Subscription history={history} dispatch={dispatch} />,
          { hideCloseButton: false, header: 'Warning!' },
        ),
      ),
    )
  }

  return (
    <RootStyle title="Use Coupon">
      <Box m={5}></Box>
      <Grid className={classes.innerGrid}>
        <LocalOfferIcon color="primary"> </LocalOfferIcon>
        <Box m={1}></Box>
        <Typography variant="h3" paragraph>
          {translator._('pricing.useCoupon')}
        </Typography>
      </Grid>
      <Box m={2}></Box>

      <Grid container className={classes.innerGrid}>
        <Grid item xs={6} md={2}>
          <TextField
            fullWidth
            label={'Code'}
            id="code"
            name="code"
            onChange={event => {
              setCode(event.target.value)
            }}
          />
        </Grid>
        <Box m={2}></Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            switch (UserType[userType]) {
              case UserType[1]:
                onHandleAppsumoCode()
                break
              case UserType[4]:
                onHandleAppsumoCode()
                break
              case UserType[5]:
                onHandleAppsumoCode()
                break
              case UserType[7]:
                onHandleAppsumoCode()
                break
              case UserType[8]:
                onHandleAppsumoCode()
                break
              case UserType[9]:
                onHandleAppsumoCode()
                break
              case UserType[10]:
                onHandleAppsumoCode()
                break
              default:
                onHandleSubscription()
                break
            }
          }}
        >
          <Typography>{translator._('pricing.redeem')}</Typography>
        </Button>
      </Grid>
      <Box m={5}></Box>
      <Grid item align="center">
        <Typography>
          {translator._('pricing.contact')}:{' '}
          <a href="mailto:support@quizcube.io">support@quizcube.io</a>
        </Typography>
      </Grid>
    </RootStyle>
  )
}

export default withStyles(style)(Pricing)
