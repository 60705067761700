const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 70

const styles = theme => ({
  '@global': {
    body: {
      margin: 0,
      padding: 0,
      backgroundColor: '#fff!important',
    },
    html: {
      fontSize: '100%',
    },
    main: {
      display: 'block',
    },
    '#root': {
      display: 'flex',
      //height: '100vh',
      width: '100%',
      zIndex: 1,
    },
  },
  enterasan: {
    display: 'block',
  },
  content: {
    position: 'absolute',
    top: 144,
    left: 0,
    right: 0,
    bottom: 0,
  },
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
  },
  main: {
    flexGrow: 1,
    //minHeight: '100%',
    width: '100%',
    marginTop: APP_BAR_MOBILE,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    //marginBottom: theme.spacing(3),
    /*
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 10,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: APP_BAR_MOBILE + 40,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    */
  },
  backdrop: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    backdropFilter: 'blur(8px)',
    display: 'none',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 150,
  },
})

export default styles
