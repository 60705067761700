const USER_PASSWORD_UPDATE_ENTITY = 'USER_PASSWORD_UPDATE_ENTITY'
const USER_PASSWORD_PUT = 'USER_PASSWORD_PUT'
const USER_PASSWORD_PUT_SUCCESS = 'USER_PASSWORD_PUT_SUCCESS'
const USER_PASSWORD_PUT_ERROR = 'USER_PASSWORD_PUT_ERROR'
const USER_PASSWORD_SET = 'USER_PASSWORD_SET'
const USER_PASSWORD_SET_SUCCESS = 'USER_PASSWORD_SET_SUCCESS'
const USER_PASSWORD_SET_ERROR = 'USER_PASSWORD_SET_ERROR'
const USER_RENEW_PASSWORD = 'USER_RENEW_PASSWORD'
const USER_RENEW_PASSWORD_SUCCESS = 'USER_RENEW_PASSWORD_SUCCESS'
const USER_RENEW_PASSWORD_ERROR = 'USER_RENEW_PASSWORD_ERROR'

export {
  USER_PASSWORD_UPDATE_ENTITY,
  USER_PASSWORD_PUT,
  USER_PASSWORD_PUT_SUCCESS,
  USER_PASSWORD_PUT_ERROR,
  USER_PASSWORD_SET,
  USER_PASSWORD_SET_SUCCESS,
  USER_PASSWORD_SET_ERROR,
  USER_RENEW_PASSWORD,
  USER_RENEW_PASSWORD_SUCCESS,
  USER_RENEW_PASSWORD_ERROR,
}
