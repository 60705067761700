import React, { useState } from 'react'
import styles from '../style'
import propTypes from '../prop'
import {
  withStyles,
  ImageListItemBar,
  IconButton,
  Chip,
} from '@material-ui/core'
import withMediaLayout from '../layout-hoc'
import AddIcon from '@material-ui/icons/AddCircle'
import WaitIcon from '@material-ui/icons/Loop'
import RemoveIcon from '@material-ui/icons/RemoveCircle'
import ConverterDateToShortFormat from '../../../../utils/converter/date-to-short-format'
import DeleteIcon from '@material-ui/icons/Delete'
import Zoom from '@material-ui/core/Zoom'
import translator from '../../../../translate'
import ClassNames from 'classnames'

import {
  ButtonType,
  ButtonData,
  ButtonActionType,
} from '../../../../model/button'
import MessageBoxContent, {
  MessageBoxType,
} from '../../../../model/message-box'

import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../../reducers/message-box/action'

import { mediaVideoDeleteAction } from '../../../../reducers/media-video/action'
/**
 *
 * @param {*} props
 */
const MediaItemVideo = props => {
  const {
    classes,
    thumbnailSmallLink,
    name,
    createDate,
    onSelect,
    selected,
    id,
    dispatch,
    status,
  } = props

  const [hideItem, setHideItem] = useState(true)
  const [displayItem, setDisplayItem] = useState('block')

  /**
   *
   */
  const handleSelect = () => {
    onSelect(props)
  }
  const onDelete = () => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          translator._('message.deleteVideo'),
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              () => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.yes'),
              ButtonActionType.DELETE,
              () => {
                dispatch(
                  mediaVideoDeleteAction({ id }, () => {
                    dispatch(messageBoxHideAction())
                    setHideItem(false)
                  }),
                )
              },
            ),
          ],
        ),
      ),
    )
  }

  const uploadError = () => {
    return (
      <Zoom
        in={hideItem}
        onExited={() => setDisplayItem('none')}
        appear={false}
      >
        <div
          style={{
            background: '#5d473f',
            height: '100%',
            display: displayItem,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              width: '100%',
              height: 150,
            }}
          >
            <img
              src="/assets/codec-error.gif"
              className={classes.imageContent}
              alt=""
            />
          </div>

          <IconButton
            className={classes.deleteButton}
            size="small"
            onClick={onDelete}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          <ImageListItemBar
            style={{ height: 'auto' }}
            title={name ? name : '-'}
            subtitle={ConverterDateToShortFormat(createDate)}
            className={ClassNames({
              [classes.selected]: selected,
            })}
            actionPosition="right"
            classes={{
              root: classes.titleBar,
              title: classes.title,
              subtitle: classes.subtitle,
              titleWrapActionPosRight: classes.titleWrapActionPosRight,
            }}
            actionIcon={
              <Chip
                label={translator._('media.codecError')}
                size="small"
                className={classes.chip}
              />
            }
          />
        </div>
      </Zoom>
    )
  }

  const uploading = () => {
    return (
      <Zoom
        in={hideItem}
        onExited={() => setDisplayItem('none')}
        appear={false}
      >
        <div
          style={{
            background: '#5d473f',
            height: '100%',
            display: displayItem,
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              width: '100%',
              height: 150,
            }}
          >
            <img
              src="/assets/video_loading.gif"
              className={classes.imageContent}
              alt=""
            />
          </div>

          <IconButton
            className={classes.deleteButton}
            size="small"
            onClick={onDelete}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          <ImageListItemBar
            style={{ height: 'auto' }}
            title={name ? name : '-'}
            subtitle={ConverterDateToShortFormat(createDate)}
            className={ClassNames({
              [classes.selected]: selected,
            })}
            actionPosition="right"
            classes={{
              root: classes.titleBar,
              title: classes.title,
              subtitle: classes.subtitle,
              titleWrapActionPosRight: classes.titleWrapActionPosRight,
            }}
            actionIcon={
              <Chip
                label={translator._('media.transcoding')}
                size="small"
                className={classes.chip}
              />
            }
          />
        </div>
      </Zoom>
    )
  }

  const available = () => {
    return (
      <Zoom
        in={hideItem}
        onExited={() => setDisplayItem('none')}
        appear={false}
      >
        <div
          style={{
            background: '#5d473f',
            height: '100%',
            display: displayItem,
            overflow: 'hidden',
          }}
          onClick={handleSelect}
        >
          <div
            style={{
              overflow: 'hidden',
              width: '100%',
              height: 150,
            }}
          >
            <img
              src={
                thumbnailSmallLink
                  ? thumbnailSmallLink
                  : '/assets/video_loading-2.gif'
              }
              className={classes.imageContent}
              alt=""
            />
          </div>

          <IconButton
            className={classes.deleteButton}
            size="small"
            onClick={onDelete}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          <ImageListItemBar
            style={{ height: 'auto' }}
            title={name ? name : '-'}
            subtitle={ConverterDateToShortFormat(createDate)}
            className={ClassNames({
              [classes.selected]: selected,
            })}
            classes={{
              root: classes.titleBar,
              title: classes.title,
              subtitle: classes.subtitle,
              titleWrapActionPosRight: classes.titleWrapActionPosRight,
            }}
            actionIcon={
              <IconButton
                className={classes.addIcon}
                //onClick={handleSelect}
              >
                {selected && <RemoveIcon />}

                {!selected && thumbnailSmallLink && <AddIcon />}
                {!selected && !thumbnailSmallLink && <WaitIcon />}
              </IconButton>
            }
          />
        </div>
      </Zoom>
    )
  }

  if (status === 'uploading_error') {
    return uploadError()
  } else if (status === 'available') {
    return available()
  } else {
    return uploading()
  }
}

MediaItemVideo.propTypes = propTypes

export default withMediaLayout(withStyles(styles)(MediaItemVideo))
