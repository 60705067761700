const URL = {
  LIST_GAMEINFO: 'GameInfo/GetGameInfosByFilter',
  GET_GAMEINFO: 'GameInfo/GetGameInfoById',
  ADD_GAMEINFO: 'GameInfo/AddGameInfo',
  PATCH_GAMEINFO: 'GameInfo/PatchGameInfo',
  CLONE_GAMEINFO: 'GameInfo/CloneGameInfo',
  DELETE_GAMEINFO: 'GameInfo/DeleteGameInfo',
  DELETE_GAMEINFO_LIST: 'GameInfo/DeleteGameInfoList',
  ADD_QUESTION_GAMEINFO: 'GameInfo/AddGameInfoQuestionRelation',
  DELETE_QUESTION_GAMEINFO: 'GameInfo/DeleteGameInfoQuestionRelation',
  GET_QUESTION_LIST_FOR_GAMEINFO: 'GameInfo/GetGameInfoWithQuestions',
  GAMEINFO_REPORT_CARD_VALUE: 'Report/QuizGameReportCardValue',
  GAMEINFO_REPORT_LEADERBOARD: 'Report/QuizGameReportLeaderboard',
  GAMEINFO_REPORT_SUBJECT: 'Report/QuizGameReportSubject',
  GAMEINFO_REPORT_TEAM_COMPARISON_BY_GAME_METRICS:
    'Report/QuizGameReportTeamComparisonByGameMetrics',
  GAMEINFO_REPORT_TEAM_COMPARISON_BY_SUBJECTS:
    'Report/QuizGameReportTeamComparisonBySubjects',
  GAMEINFO_REPORT_CONTENT_ANALYSIS: 'Report/QuizGameReportContentAnalysis',
  GAMEINFO_REPORT_USER_DETAIL: 'Report/QuizGameReportUserDetail',
}

export default URL
