import clsx from 'clsx'
import { Icon } from '@iconify/react'
import MyAvatar from 'src/components/MyAvatar'
import React, { useRef, useState } from 'react'
import homeFill from '@iconify-icons/eva/home-fill'
//import baselineDomain from '@iconify-icons/eva/d'
import PopoverMenu from 'src/components/PopoverMenu'
import personFill from '@iconify-icons/eva/person-fill'
import { Link as RouterLink } from 'react-router-dom'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { Button, Box, Divider, MenuItem, Typography } from '@material-ui/core'
import { MIconButton } from 'src/theme'
import { authenticationLogoutAction } from '../../../reducers/authentication/action'
import globeFill from '@iconify-icons/eva/globe-outline'
import roadFill from '@iconify-icons/eva/paper-plane-fill'
import messageCircleFill from '@iconify-icons/eva/message-circle-fill'
//import attachFill from '@iconify-icons/eva/attach-fill'
import translator from 'src/translate'
import { track } from '@amplitude/analytics-browser'

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/',
    target: '_self',
  },
  {
    label: 'Account',
    icon: personFill,
    linkTo: '/settings/profile',
    target: '_self',
  },
  {
    label: 'Custom Domain',
    icon: globeFill,
    linkTo: '/custom-domain',
    target: '_self',
  },
  {
    label: 'Other Products',
    icon: roadFill,
    linkTo: '/other-products',
    target: '_self',
  },
  /*   {
      label: 'Roadmap',
      icon: roadFill,
      linkTo: '/roadmap',
      target: '_self',
    },
    {
      label: 'Suggestions',
      icon: attachFill,
      linkTo: '/suggestions',
      target: '_self',
    },
  {
    label: 'Help Center',
    icon: messageCircleFill,
    linkTo: '/help-center',
    target: '_blank',
  },
   */
]

const useStyles = makeStyles(theme => ({
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44,
  },
  isSelected: {
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      background: alpha(theme.palette.grey[900], 0.8),
    },
  },
}))

// ----------------------------------------------------------------------

function Account(props) {
  const { dispatch } = props
  const classes = useStyles()
  const anchorRef = useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleLogoutOpenPopup = e => {
    dispatch(authenticationLogoutAction())
  }

  const firstCharsAtName =
    window.CONSTANTS.NAME.charAt(0) + window.CONSTANTS.SUR_NAME.charAt(0)

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}
      >
        <MyAvatar text={firstCharsAtName} />
      </MIconButton>

      <PopoverMenu
        width={220}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ my: 2, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {window.CONSTANTS.COMPANY_NAME}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {window.CONSTANTS.NAME} {window.CONSTANTS.SUR_NAME}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map(option => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            replace={true}
            component={RouterLink}
            onClick={handleClose}
            target={option.target}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogoutOpenPopup}
          >
            {translator._('loginPage.logout')}
          </Button>
        </Box>
      </PopoverMenu>
    </>
  )
}

export default Account
