const style = theme => ({
  root: {
    //height: 76,
    paddingTop: theme.spacing(2),
    borderTop: '1px solid #f1f1f1',
    //paddingRight: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
})

export default style
