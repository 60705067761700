import React, { useState } from 'react'
import style from './style'
import propTypes from './prop'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles, Grid, MenuItem, Menu } from '@material-ui/core'
import { MButton } from 'src/theme'
import { Button } from '@material-ui/core'
/**
 *
 * @param {*} props
 */
const GrupActions = props => {
  const { classes, actions, executeAction, selectedCount } = props
  const [anchorEl, setAnchorEl] = useState(null)

  /**
   *
   * @param {*} action
   */
  const handleAction = action => {
    executeAction(action)

    handleMenuClose()
  }

  /**
   *
   * @param {*} action
   */
  const handleMenuClose = event => {
    setAnchorEl(null)
  }
  /**
   *
   * @param {*} action
   */
  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  /**
   *
   * @param {*} action
   */
  const renderButton = action => {
    return (
      <Button
        className={classes.action}
        style={action.styles && action.styles}
        variant={action.variant}
        size="small"
        onClick={e => {
          handleAction(action)
        }}
        disabled={action.disabled(selectedCount)}
      >
        {action.icon && action.icon}
        {action.label}
      </Button>
    )
  }
  /**
   *
   * @param {*} group
   */
  const renderSelect = group => {
    return (
      <React.Fragment>
        <MButton
          onClick={handleMenuOpen}
          //className={classes.action}
          color="secondary"
          size="small"
          disabled={selectedCount > 0 ? false : true}
          variant="contained"
        >
          {group.label}
          <ExpandMoreIcon className={classes.actionMenuIcon} />
        </MButton>
        {/* <Typography
                    className={classes.actionMenu}
                    onClick={handleMenuOpen}
                >
                    <React.Fragment>
                        {group.label}
                        <ExpandMoreIcon className={classes.actionMenuIcon} />
                    </React.Fragment>
                </Typography> */}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {group.items.map((action, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleAction(action)
              }}
            >
              {action.label}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    )
  }

  return (
    <Grid className={classes.buttonsGrid}>
      {actions.map((action, index) => {
        let element = null
        if (action.items.length === 1) {
          element = renderButton(action.items[0])
        } else if (action.items.length === 0) {
          element = null
        } else {
          /*if (selectedCount > 0) */
          element = renderSelect(action)
        }

        return <React.Fragment key={index}>{element}</React.Fragment>
      })}
    </Grid>
  )
}

GrupActions.propTypes = propTypes

export default withStyles(style)(GrupActions)
