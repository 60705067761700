import { put, call, take, fork } from 'redux-saga/effects'
import { questionTagDeleteService } from '../../api/question'
import { questionTagDeleteSuccess } from '../../reducers/question/action'
import { QUESTION_TAG_DELETE } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionTagDeleteFlow(payload) {
  try {
    const { entity } = payload

    yield call(questionTagDeleteService, entity)
    yield put(questionTagDeleteSuccess(entity.tagId))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionTagDeleteWatcher() {
  while (true) {
    const action = yield take(QUESTION_TAG_DELETE)
    const { payload } = action

    yield fork(generalSaga, questionTagDeleteFlow, payload)
  }
}
