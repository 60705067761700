import React, { useState } from 'react'
import styles from './style'
import propTypes from './prop'
import translator from '../../../../../translate'
import { componentBoxHideAction } from '../../../../../reducers/component-box/action'
import { PatchContent, PatchData } from '../../../../../model/patch'
import { questionSelectionsAddAction } from '../../../../../reducers/assignment/action'
import {
  QuestionFieldNames,
  SelectionFieldNames,
} from '../../../../../model/question'
import {
  Grid,
  withStyles,
  Button,
  Typography,
  TextField,
  Alert,
} from '@material-ui/core'

const BulkImportOption = props => {
  const { classes, entity, dispatch, defaultOptions } = props
  const [options, setOptions] = useState(defaultOptions)

  /**
   *
   */
  const createButtonClickHandler = () => {
    let pieces = options.split('\n')

    if (pieces.length > 0) {
      if (!entity[QuestionFieldNames.IS_ANSWERED]) {
        let objects = []
        pieces.forEach(element => {
          let object = {}
          object[SelectionFieldNames.ISCORRECT] = false
          object[SelectionFieldNames.STEXT] = element
          objects = [...objects, object]
        })
        const data = {
          selectionDtos: objects,
          cardId: entity[QuestionFieldNames.ID],
        }

        dispatch(
          questionSelectionsAddAction(data, () => {
            dispatch(componentBoxHideAction())
          }),
        )
      }
    }
  }

  /**
   *
   * @param {*} e
   */
  const handleOptions = e => {
    setOptions(e.target.value)
  }

  const closeModal = e => {
    dispatch(componentBoxHideAction())
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={4} className={classes.grid}>
        <Grid container className={classes.childGrid}>
          <Typography variant="h6">
            {translator._('taskItem.addQuestion.importOptions.title')}
          </Typography>
          <Typography variant="body2">
            {translator._('taskItem.addQuestion.importOptions.desc')}
          </Typography>
        </Grid>

        <Grid container className={classes.childGrid}>
          <TextField
            fullWidth={true}
            id="options"
            name="options"
            multiline
            rows={6}
            onChange={handleOptions}
          />
        </Grid>

        {entity[QuestionFieldNames.IS_ANSWERED] && (
          <Alert severity="info">
            {translator._('taskItem.addQuestion.importOptions.info')}
          </Alert>
        )}

        <Grid container className={classes.buttonHolder} spacing={1}>
          <Grid item>
            <Button onClick={closeModal} color="inherit" variant="contained">
              {translator._('taskItem.addQuestion.importOptions.cancel')}{' '}
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={createButtonClickHandler}
              color="secondary"
              variant="contained"
              disabled={!options}
            >
              {translator._('taskItem.addQuestion.importOptions.add')}{' '}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

BulkImportOption.propTypes = propTypes

export default withStyles(styles)(BulkImportOption)
