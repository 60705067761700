const styles = theme => ({
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
  },
  createButton: {
    height: 48,
    width: 207,
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      height: 'fit-content',
      fontSize: '.9rem',
    },
  },
  modal: {
    display: 'flex !important',
  },
  modalContent: {
    borderRadius: theme.shape.borderRadiusMd,
    height: 503,
    width: 712,
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
  },
  namingModalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadiusMd,
    height: 258,
    width: 712,
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
  },
  headerGroup: {
    margin: theme.spacing(3),
    marginLeft: 25,
    marginBottom: 31,
  },
  cardSelectorGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 25,
    marginRight: 10,
  },
  option: {
    height: 278,
    width: 324,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('all'),
    border: `solid 1px ${theme.palette.grey[500_32]}`,
    margin: 'auto',
    marginBottom: 38,
    marginRight: 15,
  },
  isSelected: {
    boxShadow: theme.shadows[25].z8,
  },
  radioButtonHolder: {
    alignSelf: 'flex-end',
  },
  checkIcon: {
    color: '#00AB55',
  },
  smallImage: {
    height: 80,
    width: 80,
    marginBottom: theme.spacing(1),
  },
  cardSelectorText: {
    textAlign: 'center',
  },
  cardSelectorSubtext: {
    fontWeight: 400,
    textAlign: 'center',
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: 38,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginRight: 24,
    marginBottom: 28,
  },
  continueButton: {
    backgroundColor: '#00AB55',
    marginLeft: theme.spacing(2),
  },
  buttonText: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
})

export default styles
