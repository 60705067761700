import PropTypes from 'prop-types'

const propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  entity: PropTypes.object,
  onMove: PropTypes.func,
  onRemove: PropTypes.func,
}

export default propTypes
