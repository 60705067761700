import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import { sendConfirmationEmailAction } from '../../reducers/sign-up/action.js'
import { connect } from 'react-redux'
import translator from '../../translate'
import propTypes from './prop'
import { Button, FormControl, TextField } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { snackbarDispose } from '../../reducers/snackbar/action'

class GetStarted extends Component {
  /**
   *
   */
  onHandleLogin = event => {
    event.preventDefault()
    const email = event.target.email.value

    let application = 'becompe'

    if (window.location.hostname.indexOf('rootie') !== -1) {
      application = 'rootie'
    }

    if (email !== '' && email !== null) {
      const data = {
        email,
        application,
      }
      this.props.dispatch(sendConfirmationEmailAction(data))
    }
  }

  /**
   *
   */
  render() {
    const { classes } = this.props
    return (
      <main className={classes.main}>
        <form onSubmit={this.onHandleLogin}>
          <FormControl margin="normal" required>
            <TextField
              id="email"
              name="email"
              type="email"
              label={translator._('loginPage.email')}
              fullWidth
              className={classes.input}
            />
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {translator._('loginPage.getStarted')}
          </Button>
        </form>
      </main>
    )
  }

  /**
   *
   */
  componentDidUpdate() {
    const { snackbar, enqueueSnackbar, dispatch } = this.props
    if (snackbar.messages) {
      snackbar.messages.forEach(message => {
        message.messageIds.forEach(messageId => {
          enqueueSnackbar(translator._(messageId), {
            preventDuplicate: true,
            persist: false,
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'center',
            },
            autoHideDuration: 2000,
          })
        })
        dispatch(snackbarDispose(message.id))
      })
    }
  }
}

GetStarted.propTypes = propTypes

const mapStateToProps = response => ({
  snackbar: response.snackbar,
})

export default connect(mapStateToProps)(
  withStyles(styles)(withSnackbar(GetStarted)),
)
