import { put, call, take, fork } from 'redux-saga/effects'
import { teamsPatchService } from '../../api/teams'
import { patchTeamSuccess } from '../../reducers/teams/action'
import { PATCH_TEAM } from '../../reducers/teams/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* patchTeamFlow(payload) {
  try {
    const { content, callback } = payload
    yield call(teamsPatchService, content)

    yield put(patchTeamSuccess(content))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* patchTeamWatcher() {
  while (true) {
    const action = yield take(PATCH_TEAM)
    const { payload } = action

    yield fork(generalSaga, patchTeamFlow, payload)
  }
}
