const URL = {
  LIST_USER: 'User/GetAllUsersAsyncByQueryForShop',
  GET_USER: 'User/GetUserById',
  VALIDATE_SHORT_INTEGRATION_LINK_USER: 'User/ValidateShortIntegrationLinkUser',
  GET_SELF: 'Auth/GetSelf',
  ADD_USER: 'Registration/AddUser',
  PATCH_USER: 'User/PatchUser',
  PATCH_ADMIN: 'User/PatchAdmin',
  DELETE_USER: 'User/DeleteUser',
  ACTIVE_USER_LIST: 'User/MakeUserListActive',
  PASSIVE_USER_LIST: 'User/MakeUserListPassive',
  DELETE_USER_LIST: 'User/DeleteUserList',
  CHECK_USER_NAME_AVAILABLE: 'User/CheckIfUserNameAvailable',
  USER_REPORT_QUESTION_ANALYSIS: 'Report/UserQuestionAnalysis',
  USER_REPORT_DETAILED_ANALYSIS: 'Report/UserDetailedAnalysis',
  USER_ROLE_DETAILED_LIST: 'Auth/GetUsersByFilter',
  SET_USER_ROLE: 'Auth/SetUserRole',
}

export default URL
