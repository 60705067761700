import translator from '../../../translate'
import {
  userUpdateEntityAction,
  userListMakeActiveAction,
  userListMakePassiveAction,
  userListDeleteAction,
} from '../../../reducers/user/action'
import { UserSchema } from '../../../model/user'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import AddIcon from '@material-ui/icons/Add'
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom'
import { ButtonType, ButtonData, ButtonActionType } from '../../../model/button'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../reducers/message-box/action'
import {
  sendActivationEmailAction,
  sendActivationSMSAction,
  ExcelExportUsersAction,
} from '../../../reducers/common/action'
import { excelExportAllUsersService } from '../../../api/common'
/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 */
const actions = [
  {
    label: '',
    items: [
      {
        label: translator._('action.add'),
        type: ButtonType.NORMAL,
        icon: AddIcon,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(userUpdateEntityAction(UserSchema()))
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },

  {
    label: '',
    items: [
      {
        label: translator._('action.excelExportAllUsers'),
        type: ButtonType.NORMAL,
        icon: AddIcon,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            messageBoxShowAction(
              new MessageBoxContent(
                MessageBoxType.WARNING,
                translator._('message.excelMail.userList') +
                  window.CONSTANTS.EMAIL,
                [
                  new ButtonData(
                    ButtonType.NORMAL,
                    translator._('action.no'),
                    ButtonActionType.NO,
                    () => {
                      dispatch(messageBoxHideAction())
                    },
                  ),
                  new ButtonData(
                    ButtonType.ACTION,
                    translator._('action.yes'),
                    ButtonActionType.DELETE,
                    async e => {
                      dispatch(messageBoxHideAction())
                      let response = await excelExportAllUsersService()
                    },
                  ),
                ],
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },
  {
    label: translator._('general.actions'),
    items: [
      {
        label: translator._('general.excelExport'),
        type: ButtonType.NORMAL,
        icon: VerticalAlignBottom,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(ExcelExportUsersAction(entity))
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('user.SendSMSforLogin'),
        type: ButtonType.NORMAL,
        icon: null,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(sendActivationSMSAction(entity))
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('user.SendEmailforPasswordCreation'),
        type: ButtonType.NORMAL,
        icon: null,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(sendActivationEmailAction(entity))
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('user.MakeActive'),
        type: ButtonType.NORMAL,
        icon: null,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            messageBoxShowAction(
              new MessageBoxContent(
                MessageBoxType.WARNING,
                translator._('message.makeActive'),
                [
                  new ButtonData(
                    ButtonType.NORMAL,
                    translator._('action.no'),
                    ButtonActionType.NO,
                    () => {
                      dispatch(messageBoxHideAction())
                    },
                  ),
                  new ButtonData(
                    ButtonType.ACTION,
                    translator._('action.yes'),
                    ButtonActionType.YES,
                    () => {
                      dispatch(
                        userListMakeActiveAction(entity, () => {
                          dispatch(messageBoxHideAction())
                          refresh()
                        }),
                      )
                    },
                  ),
                ],
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('user.MakePassive'),
        type: ButtonType.NORMAL,
        icon: null,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            messageBoxShowAction(
              new MessageBoxContent(
                MessageBoxType.WARNING,
                translator._('message.makePassive'),
                [
                  new ButtonData(
                    ButtonType.NORMAL,
                    translator._('action.no'),
                    ButtonActionType.NO,
                    () => {
                      dispatch(messageBoxHideAction())
                    },
                  ),
                  new ButtonData(
                    ButtonType.ACTION,
                    translator._('action.yes'),
                    ButtonActionType.YES,
                    () => {
                      dispatch(
                        userListMakePassiveAction(entity, () => {
                          dispatch(messageBoxHideAction())
                          refresh()
                        }),
                      )
                    },
                  ),
                ],
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('action.delete'),
        type: ButtonType.NORMAL,
        icon: null,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            messageBoxShowAction(
              new MessageBoxContent(
                MessageBoxType.WARNING,
                translator._('message.delete'),
                [
                  new ButtonData(
                    ButtonType.NORMAL,
                    translator._('action.no'),
                    ButtonActionType.NO,
                    () => {
                      dispatch(messageBoxHideAction())
                    },
                  ),
                  new ButtonData(
                    ButtonType.ACTION,
                    translator._('action.yes'),
                    ButtonActionType.DELETE,
                    () => {
                      dispatch(
                        userListDeleteAction(entity, () => {
                          dispatch(messageBoxHideAction())
                          refresh()
                        }),
                      )
                    },
                  ),
                ],
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },
]

export default actions
