import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import FormItemTreeNode from './node'
import { PatchData } from '../../../../model/patch'
import isEmpty from '../../../../utils/is-empty'
import { withStyles, Grid } from '@material-ui/core'

const FormItemTree = props => {
  const {
    classes,
    value,
    nodeOperation,
    optionAttributeName,
    leafNodeControl,
    updateEntityKeyValue,
    attribute,
  } = props

  const [nodes, setNodes] = useState(null)
  const [stateValue, setStateValue] = useState(value)

  if (nodes === null) {
    nodeOperation('')
      .then(response => {
        setNodes(response.data)
      })
      .catch(error => {})
  }

  /**
   *
   */
  const getValue = () => {
    if (typeof value == 'object') {
      return value[attribute]
    }
    return value
  }
  /**
   *
   */
  const setValue = val => {
    if (typeof value == 'object') {
      value[attribute] = val

      return value
    }

    return val
  }

  /**
   *
   * @param {*} nodeId
   */
  const onSelectNode = nodeId => {
    if (nodeId === getValue()) {
      updateEntityKeyValue('', props, PatchData.OPERATION_REMOVE, value)

      setStateValue('')
    } else {
      const newValue = setValue(nodeId)

      if (value) {
        updateEntityKeyValue(newValue, props, PatchData.OPERATION_REPLACE)

        setStateValue(newValue)
      } else {
        updateEntityKeyValue(newValue, props, PatchData.OPERATION_ADD)

        setStateValue(newValue)
      }
    }
  }

  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <Grid container className={classes.tree}>
          {nodes &&
            nodes.map((node, index) => {
              return (
                <FormItemTreeNode
                  value={stateValue}
                  attribute={attribute}
                  leafNodeControl={leafNodeControl}
                  key={index}
                  node={node}
                  nodeOperation={nodeOperation}
                  optionAttributeName={optionAttributeName}
                  onSelectNode={onSelectNode}
                />
              )
            })}
        </Grid>
      }
    />
  )
}

FormItemTree.propTypes = propTypes

export default withStyles(style)(FormItemTree)
