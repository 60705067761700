import { call, take, fork } from 'redux-saga/effects'
import { courseSettingRemoveService } from '../../api/assignment'
import { COURSE_SETTING_REMOVE } from '../../reducers/assignment/type'
import generalSaga from '../general'

/**
 *
 * @param {*} payload
 */
function* courseSettingsRemoveFlow(payload) {
  try {
    const { id, questionId } = payload

    yield call(courseSettingRemoveService, id)
    //yield put(operationSuccess())
  } catch (error) {
    //yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* courseSettingsRemoveWatcher() {
  while (true) {
    const action = yield take(COURSE_SETTING_REMOVE)
    const { payload } = action

    yield fork(generalSaga, courseSettingsRemoveFlow, payload, false)
  }
}
