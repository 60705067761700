import { put, call, take, fork } from 'redux-saga/effects'
import { assignmentAddService } from '../../api/assignment'
import { assignmentAddSuccess } from '../../reducers/assignment/action'
import { ASSIGNMENT_ADD } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* assignmentAddFlow(payload) {
  try {
    const { entity, history, callback } = payload

    const response = yield call(assignmentAddService, entity)

    yield put(assignmentAddSuccess(response.data))
    yield put(operationSuccess())
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* assignmentAddWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_ADD)
    const { payload } = action

    yield fork(generalSaga, assignmentAddFlow, payload)
  }
}
