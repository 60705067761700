import { guid } from '../../../../utils/guid'
import { FormItemType } from '../../../../form/props/form-item/default.js'
import { ShopFieldNames } from '../../../../model/shop'
import translator from '../../../../translate'
import { FormInputType } from '../../../../form/props/form-item/input'

const FormItemGoal1 = {
  id: guid().uid(),
  attribute: ShopFieldNames.GOAL1,
  label: translator._('shop.goal1'),
  type: FormItemType.INPUT,
  required: true,
  disabled: false,
  hidden: false,
  inputType: FormInputType.TEXT,
}

export default FormItemGoal1
