import React, { useState } from 'react'
import {
  withStyles,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
  Input,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import styles from './styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useSelector } from 'react-redux'
import translator from 'src/translate'

const ShareView = props => {
  const { entity, classes } = props

  const [copiedShareCode, setCopiedShareCode] = useState(false)
  const [showShareButtons, setShowShareButtons] = useState(0)

  const onClickShareCode = () => {
    setCopiedShareCode(true)
    setTimeout(() => {
      setCopiedShareCode(false)
    }, 500)
  }

  const domain = entity.customDomainDtos
    ? entity.customDomainDtos.length == 0 ||
      entity.customDomainDtos[0].verificationStatus != 1
      ? 'share.quizcube.io'
      : 'https://' + entity.customDomainDtos[0].domain + '/'
    : 'share.quizcube.io'

  const [domainName, setDomainName] = useState(domain)

  const collectionName = entity.collectionName
    .replaceAll(/\?.*$/g, '')
    .replaceAll(' ', '-')

  const customDomain = useSelector(state => state.domain.list)

  const customDomainList = customDomain && customDomain.results
  const collectionDomain =
    customDomainList &&
    customDomainList.filter(
      item =>
        item.defaultCollectionDto != null &&
        item.defaultCollectionDto.id == entity.id,
    )
  const [domains, setDomains] = useState(collectionDomain)

  const hasVerifiedDomain =
    domains && domains.find(x => x.verificationStatus == 1)

  const handleDomain = e => {
    setDomainName(e.target.value)
  }
  let shareCode = showShareButtons ? '?shr=1' : ''
  return (
    <div className={classes.customDomain__root}>
      {hasVerifiedDomain && (
        <div>
          <FormControl
            style={{ width: '100%' }}
            variant="outlined"
            className={classes.listGroup}
          >
            <Select
              className={classes.select}
              inputProps={{
                className: classes.inputField,
              }}
              fullWidth
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Choose your domain"
              value={domainName}
              disabled={false}
              input={<Input id="select-input" name="select-input" />}
              onChange={handleDomain}
            >
              <MenuItem value={'share.quizcube.io'}>share.quizcube.io</MenuItem>
              {domains &&
                domains
                  .filter(x => x.verificationStatus == 1)
                  .map(value => {
                    return (
                      <MenuItem value={value.domain} key={value.domain}>
                        {value.domain}
                      </MenuItem>
                    )
                  })}
            </Select>
          </FormControl>
        </div>
      )}
      <div className={classes.settingsShare}>
        <FormControlLabel
          control={
            <Switch
              checked={showShareButtons}
              onChange={(e, checked) => {
                setShowShareButtons(checked)
              }}
              name="checkedB"
            />
          }
          label={translator._('assignment.showShareButtonsLabel')}
        />
      </div>
      <TextField
        fullWidth
        value={
          hasVerifiedDomain
            ? 'https://' +
              domainName +
              '/category/' +
              collectionName
                .replaceAll(' ', '-')
                .replaceAll('?', '')
                .replaceAll('&', '') +
              '-1/' +
              entity.id +
              shareCode
            : domain +
              '/category/' +
              collectionName
                .replaceAll(' ', '-')
                .replaceAll('?', '')
                .replaceAll('&', '') +
              '-1/' +
              entity.id +
              shareCode
        }
        className={classes.textField}
        multiline
        sx={{
          input: { color: '#212B36' },
        }}
      />

      <CopyToClipboard
        text={
          hasVerifiedDomain
            ? 'https://' +
              domainName +
              '/category/' +
              collectionName
                .replaceAll(' ', '-')
                .replaceAll('?', '')
                .replaceAll('&', '') +
              '-1/' +
              entity.id +
              shareCode
            : domain +
              '/category/' +
              collectionName
                .replaceAll(' ', '-')
                .replaceAll('?', '')
                .replaceAll('&', '') +
              '-1/' +
              entity.id +
              shareCode
        }
        className={classes.copy}
      >
        <Button
          style={{ textTransform: 'none' }}
          onClick={onClickShareCode}
          variant="outlined"
        >
          {copiedShareCode ? 'Copied!' : 'Copy to Clipboard'}
        </Button>
      </CopyToClipboard>
    </div>
  )
}

export default withStyles(styles)(ShareView)
