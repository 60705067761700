import { put, take, fork } from 'redux-saga/effects'
import { ASSIGNMENT_UPDATE_ATTRIBUTE } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* assignmentUpdateFlow(payload) {
  try {
    const { callback } = payload
    yield put(operationSuccess())
    //yield delay(1000)
    callback()
  } catch (error) {
    yield put(operationFail())
  }
}

/**
 *
 */
export default function* assignmentUpdateWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_UPDATE_ATTRIBUTE)
    const { payload } = action

    yield fork(generalSaga, assignmentUpdateFlow, payload)
  }
}
