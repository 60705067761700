const URL = {
  LIST_TEAM_BY_QUERY: 'Team/GetTeamsByFilter',
  LIST_TEAM_BY_PARENT: 'Team/GetTeamsByParentId',
  GET_TEAM: 'Team/GetTeamById',
  GET_TEAM_LIST: 'Team/GetTeamListById',
  ADD_TEAM: 'Team/AddTeam',
  EDIT_TEAM: 'Team/EditTeam',
  PATCH_TEAM: 'Team/PatchTeam',
  DELETE_TEAM: 'Team/DeleteTeam',
  ADD_USER_TO_TEAM: 'Team/AddUserToTeam',
  DELETE_USER_FROM_TEAM: 'Team/RemoveUserFromTeam',
  ACTIVE_TEAM_LIST: 'Team/MakeTeamListActive',
  PASSIVE_TEAM_LIST: 'Team/MakeTeamListPassive',
  DELETE_TEAM_LIST: 'Team/DeleteTeamList',
  SET_PARENT_OF_TEAM_LIST: 'Team/SetParentOfTeamList',
  CHECK_TEAM_NAME_AVAILABLE: 'Team/CheckIfNameAvailable',
}

export default URL
