import React from 'react'
// material
import {
  Typography,
  Card,
  CardContent,
  withStyles,
  Box,
  Link,
} from '@material-ui/core'
import { connect } from 'react-redux'
import style from './style'
import translator from 'src/translate'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function Cockpit(props) {
  const { classes } = props
  return (
    <React.Fragment>
      <Typography variant="h3" align="center" gutterBottom></Typography>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom align="center">
            {translator._('cockpit.whyYouHere')}
          </Typography>
          <Box m={2} />
          <Link href="/coursestatistics" align="center">
            <Typography>{translator._('cockpit.courseStatistics')}</Typography>
          </Link>
          <Box m={2} />
          <Link href="/coursetimeseries" align="center">
            <Typography>{translator._('cockpit.courseTimeSeries')}</Typography>
          </Link>
          <Box m={2} />
          <Link href="/code-refund" align="center">
            <Typography>{translator._('cockpit.refundPage')}</Typography>
          </Link>
          <Box m={2} />
          <Link href="/user-roles" align="center">
            <Typography>User Roles Change</Typography>
          </Link>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default connect()(withStyles(style)(Cockpit))
