import { guid } from '../../../../../utils/guid'
import {
  FormItemType,
  FormItemFilterAddMode,
} from '../../../../../form/props/form-item/default'
import SYSTEM_DATA from '../../../../../data'
import translator from '../../../../../translate/index'

const FormItemSendType = {
  id: guid().uid(),
  attribute: 'SendType',
  label: translator._('general.sendType'),
  type: FormItemType.MENU,
  options: () => {
    return SYSTEM_DATA.FILTER_OPTIONS.AUTO_SENDER_SEND_TYPE
  },
  optionAttributeName: { label: 'label', value: 'value' },
  required: false,
  disabled: false,
  hidden: false,
  filterAddMode: FormItemFilterAddMode.SINGLE,
}

export default FormItemSendType
