import { put, call, take, fork } from 'redux-saga/effects'
import { addCourseDocumentService } from '../../api/assignment'
//import { questionPatchSuccess } from '../../reducers/question/action'
import { addCourseDocumentSuccess } from '../../reducers/assignment/action'
import { ADD_COURSE_DOCUMENT } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* addCourseDocumentFlow(payload) {
  try {
    const { entity, callback } = payload
    const response = yield call(addCourseDocumentService, entity)

    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* addCourseDocumentWatcher() {
  while (true) {
    const action = yield take(ADD_COURSE_DOCUMENT)
    const { payload } = action

    yield fork(generalSaga, addCourseDocumentFlow, payload)
  }
}
