const styles = theme => ({
  grid: {
    width: 400,
    maxWidth: 'none',
    flexBasis: 'unset',
  },
  childGrid: {
    marginBottom: theme.spacing(1.5),
  },
  buttonHolder: {
    justifyContent: 'flex-end',
    borderTop: '1px solid #cecece',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
})

export default styles
