import {
  USER_UPDATE_ENTITY,
  USER_LIST,
  USER_LIST_SUCCESS,
  USER_LIST_ERROR,
  USER_ID_LIST,
  USER_ID_LIST_SUCCESS,
  USER_ID_LIST_ERROR,
  USER_GET,
  USER_GET_SUCCESS,
  USER_GET_ERROR,
  USER_VALIDATE_SHORT_LINK,
  USER_VALIDATE_SHORT_LINK_SUCCESS,
  USER_VALIDATE_SHORT_LINK_ERROR,
  USER_GET_SELF,
  USER_GET_SELF_SUCCESS,
  USER_GET_SELF_ERROR,
  USER_ADD,
  USER_ADD_SUCCESS,
  USER_ADD_ERROR,
  USER_PATCH,
  USER_PATCH_SUCCESS,
  USER_PATCH_ERROR,
  ADMIN_PATCH,
  ADMIN_PATCH_SUCCESS,
  ADMIN_PATCH_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  USER_LIST_DELETE,
  USER_LIST_DELETE_SUCCESS,
  USER_LIST_DELETE_ERROR,
  USER_LIST_MAKE_ACTIVE,
  USER_LIST_MAKE_ACTIVE_SUCCESS,
  USER_LIST_MAKE_ACTIVE_ERROR,
  USER_LIST_MAKE_PASSIVE,
  USER_LIST_MAKE_PASSIVE_SUCCESS,
  USER_LIST_MAKE_PASSIVE_ERROR,
  USER_REPORT_DETAILED_ANALYSIS,
  USER_REPORT_DETAILED_ANALYSIS_SUCCESS,
  USER_REPORT_DETAILED_ANALYSIS_ERROR,
  USER_REPORT_QUESTION_ANALYSIS,
  USER_REPORT_QUESTION_ANALYSIS_SUCCESS,
  USER_REPORT_QUESTION_ANALYSIS_ERROR,
} from './type'

/**
 *
 * @param {*} entity
 */
export const userUpdateEntityAction = entity => {
  return {
    type: USER_UPDATE_ENTITY,
    payload: { entity },
  }
}

/**
 *
 * @param {*} query
 */
export const userListAction = query => {
  return {
    type: USER_LIST,
    payload: { query },
  }
}

/**
 *
 * @param {*} response
 */
export const userListSuccess = response => {
  return {
    type: USER_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userListError = () => {
  return {
    type: USER_LIST_ERROR,
  }
}

/**
 *
 * @param {*} query
 */
export const userIdListAction = (query, callback) => {
  return {
    type: USER_ID_LIST,
    payload: { query, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const userIdListSuccess = response => {
  return {
    type: USER_ID_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userIdListError = () => {
  return {
    type: USER_ID_LIST_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const userGetAction = id => {
  return {
    type: USER_GET,
    payload: { id },
  }
}

/**
 *
 * @param {*} response
 */
export const userGetSuccess = response => {
  return {
    type: USER_GET_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userGetError = () => {
  return {
    type: USER_GET_ERROR,
  }
}

/**
 *
 * @param {*} sIntegLink
 */
export const userValidateShortLinkAction = sIntegLink => {
  return {
    type: USER_VALIDATE_SHORT_LINK,
    payload: { sIntegLink },
  }
}

/**
 *
 * @param {*} response
 */
export const userValidateShortLinkSuccess = response => {
  return {
    type: USER_VALIDATE_SHORT_LINK_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userValidateShortLinkError = () => {
  return {
    type: USER_VALIDATE_SHORT_LINK_ERROR,
  }
}

/**
 *
 */
export const userGetSelfAction = () => {
  return {
    type: USER_GET_SELF,
    payload: {},
  }
}

/**
 *
 * @param {*} response
 */
export const userGetSelfSuccess = response => {
  return {
    type: USER_GET_SELF_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userGetSelfError = () => {
  return {
    type: USER_GET_SELF_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const userAddAction = (entity, callback) => {
  return {
    type: USER_ADD,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const userAddSuccess = response => {
  return {
    type: USER_ADD_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userAddError = () => {
  return {
    type: USER_ADD_ERROR,
  }
}

/**
 * @param {*} content
 * @param {*} callback
 */
export const userPatchAction = (content, callback) => {
  return {
    type: USER_PATCH,
    payload: { content, callback },
  }
}

/**
 * @param {*} response
 */
export const userPatchSuccess = response => {
  return {
    type: USER_PATCH_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userPatchError = () => {
  return {
    type: USER_PATCH_ERROR,
  }
}

/**
 * @param {*} content
 * @param {*} callback
 */
export const adminPatchAction = (content, callback) => {
  return {
    type: ADMIN_PATCH,
    payload: { content, callback },
  }
}

/**
 * @param {*} response
 */
export const adminPatchSuccess = response => {
  return {
    type: ADMIN_PATCH_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const adminPatchError = () => {
  return {
    type: ADMIN_PATCH_ERROR,
  }
}

/**
 *
 * @param {*} id
 * @param {*} callback
 */
export const userDeleteAction = (id, callback) => {
  return {
    type: DELETE_USER,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const userDeleteSuccess = response => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userDeleteError = () => {
  return {
    type: DELETE_USER_ERROR,
  }
}
/**
 *
 * @param {*} ids
 * @param {*} callback
 */
export const userListDeleteAction = (ids, callback) => {
  return {
    type: USER_LIST_DELETE,
    payload: { ids, callback },
  }
}

/**
 *
 * @param {*} ids
 */
export const userListDeleteSuccess = ids => {
  return {
    type: USER_LIST_DELETE_SUCCESS,
    payload: { ids },
  }
}

/**
 *
 */
export const userListDeleteError = () => {
  return {
    type: USER_LIST_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} ids
 * @param {*} callback
 */
export const userListMakeActiveAction = (ids, callback) => {
  return {
    type: USER_LIST_MAKE_ACTIVE,
    payload: { ids, callback },
  }
}

/**
 *
 * @param {*} ids
 */
export const userListMakeActiveSuccess = ids => {
  return {
    type: USER_LIST_MAKE_ACTIVE_SUCCESS,
    payload: { ids },
  }
}

/**
 *
 */
export const userListMakeActiveError = () => {
  return {
    type: USER_LIST_MAKE_ACTIVE_ERROR,
  }
}

/**
 *
 * @param {*} ids
 * @param {*} callback
 */
export const userListMakePassiveAction = (ids, callback) => {
  return {
    type: USER_LIST_MAKE_PASSIVE,
    payload: { ids, callback },
  }
}

/**
 *
 * @param {*} ids
 */
export const userListMakePassiveSuccess = ids => {
  return {
    type: USER_LIST_MAKE_PASSIVE_SUCCESS,
    payload: { ids },
  }
}

/**
 *
 */
export const userListMakePassiveError = () => {
  return {
    type: USER_LIST_MAKE_PASSIVE_ERROR,
  }
}

/**
 *
 * @param {*} reqParams
 * @param {*} callback
 */
export const userReportDetailedAnalysisAction = (reqParams, callback) => {
  return {
    type: USER_REPORT_DETAILED_ANALYSIS,
    payload: { reqParams, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const userReportDetailedAnalysisSuccess = response => {
  return {
    type: USER_REPORT_DETAILED_ANALYSIS_SUCCESS,
    payload: { response },
  }
}
/**
 *
 */
export const userReportDetailedAnalysisError = () => {
  return {
    type: USER_REPORT_DETAILED_ANALYSIS_ERROR,
  }
}

/**
 *
 * @param {*} reqParams
 * @param {*} callback
 */
export const userReportQuestionAnalysisAction = (reqParams, callback) => {
  return {
    type: USER_REPORT_QUESTION_ANALYSIS,
    payload: { reqParams, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const userReportQuestionAnalysisSuccess = response => {
  return {
    type: USER_REPORT_QUESTION_ANALYSIS_SUCCESS,
    payload: { response },
  }
}
/**
 *
 */
export const userReportQuestionAnalysisError = () => {
  return {
    type: USER_REPORT_QUESTION_ANALYSIS_ERROR,
  }
}
