import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import propTypes from './prop'
import translator from '../../translate'
import MobileIcon from '@material-ui/icons/MobileScreenShare'
import ArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import { connect } from 'react-redux'
import AddToQueue from '@material-ui/icons/AddToQueue'
import MobileOnboarding from './mobile-onboarding'
import AdminOnboarding from './admin-onboarding'
import { componentBoxShowAction } from '../../reducers/component-box/action'
import ComponentBoxContent from '../../model/component-box'
import ComponentBox from '../../components/component-box'
import { withRouter } from 'react-router-dom'
import {
  CssBaseline,
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from '@material-ui/core'

class Welcome extends Component {
  /**
   *
   */
  mobileExperience = () => {
    const { dispatch } = this.props
    const component = <MobileOnboarding />

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, { hideCloseButton: false }),
      ),
    )
  }

  /**
   *
   */
  adminExperience = () => {
    const { dispatch } = this.props
    const component = <AdminOnboarding />

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, { hideCloseButton: false }),
      ),
    )
  }

  /**
   *
   */
  buttonAction = e => {
    const { history } = this.props

    history.push(e.currentTarget.dataset.path)
  }

  /**
   *
   */
  render() {
    const { classes, dispatch, componentBox } = this.props

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image}></Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Typography component="h1" variant="h4">
              {translator._('welcome.welcomeTitle')}
            </Typography>
            <Box mt={4} />

            <Typography variant="body1">
              {translator._('welcome.welcomeText')}
            </Typography>
            <Box mt={2} />

            <Grid container>
              <Grid item xs={12} md={12}>
                <List dense>
                  <ListItem
                    button
                    divider
                    classes={{
                      root: classes.listItemRoot,
                    }}
                    onClick={this.mobileExperience}
                  >
                    <ListItemAvatar>
                      <MobileIcon fontSize="large" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={translator._('welcome.mobileButtonText')}
                      primaryTypographyProps={{
                        color: 'secondary',
                        variant: 'button',
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="Go">
                        <ArrowIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                    button
                    divider
                    dense
                    classes={{
                      root: classes.listItemRoot,
                    }}
                    onClick={this.adminExperience}
                  >
                    <ListItemAvatar>
                      <AddToQueue fontSize="large" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={translator._('welcome.adminButtonText')}
                      primaryTypographyProps={{
                        color: 'secondary',
                        variant: 'button',
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="Go">
                        <ArrowIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={12}>
                <Box mt={2} />

                <Button
                  size="small"
                  className={classes.margin}
                  color="secondary"
                  onClick={this.buttonAction}
                  data-path="/"
                >
                  {translator._('welcome.goToDashboard')}
                </Button>
              </Grid>
            </Grid>

            <Box mt={5} />
          </div>
        </Grid>
        {componentBox.content && (
          <ComponentBox content={componentBox.content} dispatch={dispatch} />
        )}
      </Grid>
    )
  }
}

Welcome.propTypes = propTypes

const mapStateToProps = response => ({
  componentBox: response.componentBox,
})
export default connect(mapStateToProps)(withRouter(withStyles(styles)(Welcome)))
