import React, { useEffect, useState, useRef, useCallback } from 'react'
import styles from './styles'
import { LimitTypeEnum } from 'src/model/limit'

// @mui
import {
  Container,
  Grid,
  withStyles,
  Box,
  CircularProgress,
  Button,
  Typography,
} from '@material-ui/core'
import { getUserLimits } from 'src/api/limit'
import LimitReachedModal from './components/limit-reached-modal/index'
import { getCollectionSettingsAction } from 'src/reducers/course-collection/action'

import { CourseCard, AddCourseCard, CollectionName } from './components'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { courseCollectionListAction } from 'src/reducers/course-collection/action'
import useAsync from 'src/hooks/useAsync'
// components
import Page from '../../components/Page'
import { useHistory } from 'react-router-dom'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { KanbanColumnAdd } from 'src/components/kanban'
import CollectionSettingsDrawer from 'src/components/collection-settings-drawer'
import { teamListAction } from 'src/reducers/teams/action'
import {
  useHandleDragMove,
  groupCoursesByCollection,
  handleDragEnd,
  createCourse,
  findCourseById,
} from './logic'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import DndContainer from './components/dnd-container'
import Items from './components/dnd-items'

// sections

// ----------------------------------------------------------------------

const fetchCourseCollections = data => {
  const dispatch = data.dispatch
  return new Promise((resolve, reject) => {
    dispatch(
      courseCollectionListAction(data.query, response => {
        resolve(response)
      }),
    )
  })
}

function QuizCube(props) {
  const { classes } = props
  let history = useHistory()
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [currentCredit, setCurrentCredit] = useState()
  const [limitLoading, setLimitLoading] = useState(true)
  const [selectedCollection, setSelectedCollection] = useState()
  const [changedCourses, setChangedCourses] = useState([])
  const [
    collectionSettingsDrawerOpen,
    setCollectionSettingsDrawerOpen,
  ] = useState(false)

  const { execute, status, error } = useAsync(fetchCourseCollections, true, {
    dispatch: dispatch,
    query: {
      CurrentPage: 0,
      PageSize: 20,
      SortingColumn: 'LastModifiedAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    },
  })

  useEffect(() => {
    //This useEffect hook is used for getting user's limit count for course creation.
    setLimitLoading(true)
    getUserLimits().then(response => {
      let courseLimit = response.data.find(
        x => x.limit.type == LimitTypeEnum.ActiveQuizzes,
      )
      let count = courseLimit.count
      let upperLimit = courseLimit.limit.upperLimit

      if (upperLimit == -1) {
        upperLimit = 100000
      }
      setCurrentCredit(upperLimit - count)
      setLimitLoading(false)
    })
  }, [])
  const value = useSelector(state => state.courseCollectionList.list)

  const { courseCollectionDtos: collections = [], courseDtos = [] } = value

  const data = {
    courseDtos: [...courseDtos],
    courseCollectionDtos: [...collections],
  }
  const handleOpenSettingsDrawer = entity => {
    setSelectedCollection(entity)
    dispatch(
      getCollectionSettingsAction(entity.id, response => {
        dispatch(
          teamListAction({}, response => {
            setCollectionSettingsDrawerOpen(true)
          }),
        )
      }),
    )
  }

  const groupedData = groupCoursesByCollection(
    data.courseDtos,
    data.courseCollectionDtos,
  )
  const [containers, setContainers] = useState(groupedData)
  const [activeId, setActiveId] = useState(null)
  const ref = useRef(null)
  const redirectCourse = id => {
    history.push('/quiz/' + id)
  }
  useEffect(() => {
    setContainers(groupedData)
  }, [value])

  const componentBoxHide = () => {
    dispatch(componentBoxHideAction())
  }
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragStart = useCallback(
    event => {
      const { active } = event
      const { id } = active
      activeId == null && setActiveId(id)
    },
    [activeId],
  )

  // Find the value of the items

  const handleDragMove = useHandleDragMove({
    activeId,
    containers,
    collections,
    setContainers,
    setChangedCourses,
  })
  return (
    <Page title="Quiz Cube" style={{ height: 'auto', paddingBottom: 16 }}>
      <Container
        ref={ref}
        style={{
          //backgroundColor: '#f4f6f8',
          //borderRadius: 16,
          width: '100%',
          maxWidth: '100%',
          height: '100%',
        }}
      >
        {limitLoading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box style={{ paddingBottom: 16, paddingTop: 16 }}>
              <KanbanColumnAdd />
            </Box>
            <CollectionSettingsDrawer
              setCollectionSettingDrawerOpen={setCollectionSettingsDrawerOpen}
              collectionSettingsDrawerOpen={collectionSettingsDrawerOpen}
              dispatch={dispatch}
              entity={selectedCollection}
            ></CollectionSettingsDrawer>

            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragStart={event => {
                handleDragStart(event)
              }}
              onDragEnd={() => {
                handleDragEnd(
                  changedCourses,
                  dispatch,
                  setActiveId,
                  setChangedCourses,
                )
              }}
              onDragMove={event => {
                handleDragMove(event)
              }}
            >
              <SortableContext items={containers.map(i => `${i.id}-container`)}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                  }}
                >
                  {containers.map((collection, index) => {
                    collection.courses.sort((a, b) => {
                      return a.courseCollectionOrder - b.courseCollectionOrder
                    })

                    return (
                      <DndContainer
                        key={collection.id}
                        id={collection.id}
                        style={{
                          minWidth: '100px',
                          minHeight: '100px',
                        }}
                      >
                        <SortableContext
                          items={collection.courses.map(
                            course => `${course.id}-item`,
                          )}
                          style={{
                            minWidth: '100px',
                            minHeight: '100px',
                          }}
                        >
                          <div
                            key={'collection-' + index}
                            style={{
                              backgroundColor: '#f4f6f8',
                              borderRadius: 16,
                              padding: 16,
                            }}
                          >
                            <div
                              style={{
                                marginBottom: 16,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <CollectionName
                                columnName={collection.collectionName}
                                id={collection.id}
                                dispatch={dispatch}
                              />

                              <Button
                                className={classes.learnersAccessButton}
                                variant="text"
                                onClick={() =>
                                  handleOpenSettingsDrawer(collection)
                                }
                              >
                                <Typography className={classes.buttonText}>
                                  Users' Access
                                </Typography>
                              </Button>
                            </div>

                            <Grid
                              container
                              direction="row"
                              //justifyContent="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid
                                item
                                onClick={() =>
                                  currentCredit <= 0
                                    ? setModalOpen(true)
                                    : createCourse(
                                        collection.id,
                                        collection.courses,
                                        dispatch,
                                        history,
                                      )
                                }
                              >
                                <AddCourseCard />
                              </Grid>
                              {collection.courses.length > 0 ? (
                                collection.courses.map((course, index) => {
                                  return (
                                    <Grid item key={course.name + index}>
                                      <Items
                                        key={course.id}
                                        id={`${course.id}-item`}
                                      >
                                        <CourseCard
                                          dispatch={dispatch}
                                          course={course}
                                          redirectCourse={redirectCourse}
                                          history={history}
                                          componentBoxHide={componentBoxHide}
                                          setCurrentCredit={setCurrentCredit}
                                          currentCredit={currentCredit}
                                        />
                                      </Items>
                                    </Grid>
                                  )
                                })
                              ) : (
                                <Items
                                  key={collection.id}
                                  id={`empty-item-${collection.id}`}
                                >
                                  <div
                                    style={{
                                      minHeight: '100px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  ></div>
                                </Items>
                              )}
                            </Grid>
                          </div>
                        </SortableContext>
                      </DndContainer>
                    )
                  })}
                </div>
              </SortableContext>
              <Box style={{ paddingBottom: 16, paddingTop: 16 }}>
                <KanbanColumnAdd />
              </Box>
              <DragOverlay adjustScale={false}>
                {/* Drag Overlay For item Item */}
                {activeId && activeId.toString().includes('-item') && (
                  <Items id={activeId}>
                    <CourseCard
                      dispatch={dispatch}
                      course={findCourseById(
                        containers,
                        activeId.split('-item')[0],
                      )}
                      redirectCourse={redirectCourse}
                      history={history}
                      componentBoxHide={componentBoxHide}
                      setCurrentCredit={setCurrentCredit}
                      currentCredit={currentCredit}
                    />
                  </Items>
                )}
              </DragOverlay>
            </DndContext>
          </>
        )}
        {modalOpen && (
          <LimitReachedModal
            handleCloseModal={() => setModalOpen(false)}
            isModalOpen={modalOpen}
            history={history}
          />
        )}
      </Container>
    </Page>
  )
}

export default withStyles(styles)(QuizCube)
