import React from 'react'
import translator from '../../../translate'
import { Typography } from '@material-ui/core'
import { ListColumnType } from '../../../model/list'

import { ConverterArrayToStringFormat } from '../../../utils/converter'

/**
 *
 * @param {*} dispatch
 * @param {*} refresh
 */
const columns = [
  {
    attribute: 'picture',
    label: translator._('user.picture'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    renderer: null,
    actions: null,
  },
  {
    attribute: 'name',
    label: translator._('user.name'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: entity => entity.name.charAt(0) + entity.surName.charAt(0),
    suffix: null,
    renderer: entity => {
      return (
        <Typography variant="subtitle2">
          {entity.name + ' ' + entity.surName}{' '}
        </Typography>
      )
    },
    actions: null,
  },
  {
    attribute: 'email',
    label: translator._('user.email'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: null,
    suffix: null,
    renderer: null,
    actions: null,
  },
  {
    attribute: 'userTeamRelationDTOs',
    label: translator._('user.teams'),
    type: ListColumnType.STRING,
    sortable: true,
    converter: item => {
      return ConverterArrayToStringFormat(item.userTeamRelationDTOs)
    },
    suffix: null,
    renderer: null,
    actions: null,
  },
]

export default columns
