import { put, take, fork } from 'redux-saga/effects'
import { QUESTION_UPDATE_ENTITY } from '../../reducers/question/type'
import generalSaga from '../general'
import { operationFail } from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionUpdateEntityFlow(payload) {
  try {
    const { entity, callback } = payload
    callback(entity)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionUpdateEntityWatcher() {
  while (true) {
    const action = yield take(QUESTION_UPDATE_ENTITY)
    const { payload } = action

    yield fork(generalSaga, questionUpdateEntityFlow, payload)
  }
}
