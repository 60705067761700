import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import ClassNames from 'classnames'
import { PatchData } from '../../../../model/patch'
import {
  withStyles,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

const FormItemGroupCheckbox = props => {
  const {
    classes,
    value,
    options,
    optionAttributeName,
    updateEntityKeyValue,
    label,
  } = props

  const [stateValue, setStateValue] = useState(value)
  const cloneOptions = options.map(option => {
    option.checked =
      stateValue.find(v => {
        return option[optionAttributeName.value] == v
      }) != null

    return option
  })

  /**
   *
   * @param {*} event
   * @param {*} checked
   */
  const changeHandler = (event, checked) => {
    const selectedValue = event.target.value
    let valueClone = [...stateValue]
    let patchOperation = null

    if (checked) {
      valueClone.push(selectedValue)

      patchOperation = PatchData.OPERATION_ADD
    } else {
      valueClone = valueClone.filter(v => v !== selectedValue)

      patchOperation = PatchData.OPERATION_REMOVE
    }

    updateEntityKeyValue(valueClone, props, patchOperation)

    setStateValue(valueClone)
  }

  return (
    <ItemLayout
      hasContent={stateValue.length > 0}
      formItem={props}
      element={
        <FormGroup
          className={ClassNames({
            [classes.formGroup]: true,
          })}
        >
          <FormLabel component="legend">{label}</FormLabel>

          {cloneOptions.map((option, index) => {
            return (
              <FormControlLabel
                key={index}
                className={classes.formControlLabel}
                label={option[optionAttributeName.label]}
                control={
                  <Checkbox
                    id={option[optionAttributeName.value] + '-checkbox'}
                    value={option[optionAttributeName.value]}
                    color="primary"
                    className={classes.checkbox}
                    checked={option.checked}
                    disabled={option.disabled}
                    onChange={changeHandler}
                  />
                }
              />
            )
          })}
        </FormGroup>
      }
    />
  )
}

FormItemGroupCheckbox.propTypes = propTypes

export default withStyles(style)(FormItemGroupCheckbox)
