const URL = {
  MEDIA_IMAGE_GET_BY_FILTER: 'Media/GetImagesByFilter',
  MEDIA_IMAGE_GET_BY_ID: 'Media/GetImageById',
  MEDIA_IMAGE_UPLOAD_MANY: 'Media/UploadManyImage',
  MEDIA_IMAGE_UPLOAD: 'Media/UploadImage',
  MEDIA_IMAGE_PATCH: 'Media/PatchImage',
  MEDIA_IMAGE_DELETE: 'Media/DeleteImage',
  MEDIA_IMAGE_DELETE_LIST: 'Media/DeleteImageList',
  OPENAI_GENERATE_IMAGE: 'OpenAI/SendImageCreationRequest',
  MEDIA_CARD_IMAGE_CREATION: 'OpenAI/SendCardImageCreationRequest',
}

export default URL
