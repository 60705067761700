// ----------------------------------------------------------------------

export default function Progress({ theme }) {
  const isLight = theme.palette.mode === 'light'

  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        bar: {
          borderRadius: 4,
        },
        colorPrimary: {
          backgroundColor:
            theme.palette.primary[isLight ? 'lighter' : 'darker'],
        },
      },
    },
  }
}
