const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '100vh',
    textAlign: 'center',
    justifyContent: 'center',
  },
  holder: {
    margin: theme.spacing(3),
    //maxWidth: '500px',
    marginRight: 'auto',
    marginLeft: 'auto',
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: 'white',
    minHeight: 247,
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: '0px',
      margin: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alert: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  circularProgress: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  successGrid: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 300,
    margin: '0 auto',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '170px',
    minHeight: '100vh',
    textAlign: 'center',
    justifyContent: 'center',
  },
})

export default styles
