import React from 'react'
import { Typography, withStyles, IconButton } from '@material-ui/core'
import styles from './styles'
import { Icon } from '@iconify/react'
import { domainDeleteAction } from 'src/reducers/domain/action'
import { useSelector } from 'react-redux'

const CnameList = props => {
  const { entity, dispatch, classes } = props

  //0 = Pending 1 = Verified 2 = NotVerified 3 = PreVerified

  const customDomain = useSelector(state => state.domain.list)
  const customDomainList = customDomain && customDomain.results
  const collectionDomain =
    customDomainList &&
    customDomainList.filter(
      item =>
        item.defaultCollectionDto != null &&
        item.defaultCollectionDto.id == entity.id,
    )

  const handleDeleteCollection = item => {
    dispatch(domainDeleteAction(item.id, () => {}))
  }

  const listItemView = item => {
    const listViewComponents = [
      { text: 'Pending', icon: 'ph:clock-bold', color: '#FFAB00' },
      { text: 'Connected', icon: 'mdi:tick-circle-outline', color: '#37D768' },
      { text: 'Not Verified', icon: 'foundation:x', color: '#ED4245' },
    ]
    const listViewItem = listViewComponents[item.verificationStatus]

    return (
      <>
        <Icon
          icon={listViewItem.icon}
          color={listViewItem.color}
          width={'22px'}
          height={'22px'}
          style={{ marginRight: 5 }}
        />
        {listViewItem.text}
      </>
    )
  }
  return (
    <div className={classes.customDomain__cnameList}>
      <div className={classes.customDomain__headerList}>
        <Typography fontSize={'16px'} fontWeight={500}>
          Domains linked to this collection
        </Typography>
      </div>
      <div className={classes.customDomain__list}>
        {collectionDomain && collectionDomain.length > 0 ? (
          collectionDomain.map((item, index) => (
            <div className={classes.customDomain__listItem} key={index}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                style={{
                  width: '45%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.domain}
              </Typography>

              <Typography
                style={{
                  width: '45%',
                  fontSize: '14px',
                  fontWeight: 400,
                  display: 'flex',
                  alignContent: 'center',
                }}
              >
                {listItemView(item)}
              </Typography>
              <IconButton
                style={{ padding: '5px' }}
                onClick={() => handleDeleteCollection(item)}
              >
                <Icon
                  icon={'lucide:trash'}
                  color="#C3CBD1"
                  style={{ width: '20px', height: '20px' }}
                ></Icon>
              </IconButton>
            </div>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src="/assets/no-connect.png"
                style={{
                  width: '80px',
                  height: '80px',
                }}
              ></img>
            </div>
            <Typography fontSize={'16px'} fontWeight={500}>
              No domain linked to this collection yet.
            </Typography>
            <Typography fontSize={'14px'} fontWeight={400} color="#637381">
              To connect a domain, go to the{' '}
              <a href="/custom-domain">Custom Domain</a> tab.
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(CnameList)
