const style = theme => ({
  searchBar: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: '20%',
  },
  content: {
    height: 'calc(100% - 110px)',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    maxWidth: 600,
  },

  imageItem: {
    marginBottom: 5,
    width: 256,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 450,
  },

  cardHeader: {
    marginBottom: '10px',
    display: 'inline-block',
    width: '100%',
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '36px',
    float: 'left',
  },
  imageCard: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F4F6F8',
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center',
    alignItems: 'center',
    //padding: '30px',
    gap: '24px',
  },

  ////
  section: { display: 'flex', flexDirection: 'column', alignItems: 'start' },
  sectionTitle: {
    fontWeight: 700,
    fontSize: '14px',
    width: '100%',
    float: 'left',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  random: {
    height: '26px',
    fontWeight: 500,
    fontSize: '12px',
    //color: '#00a350',
    float: 'right',
    lineHeight: '21px',
    //border: '1px solid #00a350 ',
    '&:hover': {
      cursor: 'pointer',
      //backgroundColor: '#ffffff',
      //border: '1px solid #00a350 ',
    },
  },
  presets: {
    padding: 8,
  },

  preset: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    float: 'left',
    width: '90px',
    height: '90px',
    cursor: 'pointer',
    backgroundColor: '#F4F6F8',
    '&:hover': {
      outline: '3px solid #00a350 !important',
    },
  },
  mainGrid: { textAlign: 'center', height: 475, padding: 10 },
  imageGrid: {
    height: 475,
    padding: 10,
    textAlign: 'center',
  },
  requestButton: {
    //backgroundColor: '#00AB55',
    borderRadius: 16,
    boxShadow: 'none',
  },
  resultImage: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  previewText: {
    position: 'absolute',
    left: 0,
    top: 0,
    marginLeft: 10,
    marginTop: 10,
  },
  media: {
    width: '100%',
    height: '100px',
  },
  aiImageIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    marginRight: 10,
    marginTop: 10,
    color: '#00a350',
  },
  limitText: {
    textAlign: 'left',
    fontWeight: 400,
    color: '#919EAB',
    fontSize: '10px',
  },
  generatedImage: {
    paddingTop: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default style
