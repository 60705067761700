const URL = {
  TEAMS: 'teams',
  MEMBER_LIST: '/members/list',
  MEMBER_BATCH: '/members/batch',
  MEMBER: '/members',
  MEMBERS_EXPORT: '/members/export',
  MEMBERS_IMPORT: '/members/import',
  LEARNER_AUTH: 'learner-auth',
  SENDMAIL_TO_MEMBERS: '/send-magic-link/',
}

export default URL
