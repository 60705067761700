const styles = theme => ({
  root: {
    padding: theme.spacing(2, 0, 0.5, 0),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cardMedia: {
    paddingTop: '56.25%',
    maxHeight: 200,
  },
  media: {
    height: 140,
  },
  cardContent: {
    flexGrow: 1,
    overflowX: 'hidden',
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  dummyRoot: {
    height: '100%',
  },
  dummy: {
    fontSize: '3em',
  },
  tab: { width: '33%', minWidth: 'auto' },
  input: {
    padding: 0,
    fontSize: 12,
    height: 30,
    width: 30,
    borderRadius: 20,
    color: '#56616c',
  },
  inputChild: {
    textAlign: 'center',
  },
  blur: {
    border: '1px solid transparent',
    backgroundColor: 'transparent',
  },
  badgeRoot: {
    display: 'flex',
  },
  badge: {
    height: 30,
    width: 30,
    borderRadius: 20,
    padding: 0,
  },
  swipeableView: {
    height: '100%',
  },
  imageArea: {
    position: 'relative',
    minHeight: 80,
  },
  overlay: {
    color: '#4e4d4d',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: '#ffffffcf',
    width: '100%',
  },
  overlayText: {
    color: '#4e4d4d',
    padding: 20,
  },
  colorHolder: {
    width: 20,
    height: '100%',
    marginRight: 10,
    borderRadius: 3,
  },
  chip: {
    backgroundColor: '#e9e9e9',
    margin: 2,
  },
})

export default styles
