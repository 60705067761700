/*eslint-disable */
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export class BaseError {
  /**
   *
   * @param {string} type
   * @param {AxiosRequestConfig} config
   * @param {string} code
   * @param {Object} request
   * @param {AxiosResponse} response
   */
  constructor(type, config, code, request, response) {
    this._config = config
    this._code = code
    this._request = request
    this._response = response
    this._type = type
  }

  /**
   *
   */
  get type() {
    return this._type
  }

  /**
   *
   */
  get config() {
    return this._config
  }

  /**
   *
   */
  get code() {
    return this._code
  }

  /**
   *
   */
  get request() {
    return this._request
  }

  /**
   *
   */
  get response() {
    return this._response
  }
}
/*eslint-enable */
