import { put, call, take, fork } from 'redux-saga/effects'
import { questionReportRelatedTeamsService } from '../../api/question'
import { questionReportRelatedTeamsSuccess } from '../../reducers/question/action'
import { QUESTION_REPORT_RELATED_TEAMS } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionReportRelatedTeamsFlow(payload) {
  try {
    const { reqParams, callback } = payload
    const response = yield call(questionReportRelatedTeamsService, reqParams)

    yield put(questionReportRelatedTeamsSuccess(response.data))
    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionReportRelatedTeamsWatcher() {
  while (true) {
    const action = yield take(QUESTION_REPORT_RELATED_TEAMS)
    const { payload } = action

    yield fork(generalSaga, questionReportRelatedTeamsFlow, payload)
  }
}
