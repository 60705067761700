import { SNACKBAR_SHOW, SNACKBAR_DISPOSE } from './type'
import SnackbarMessage from '../../model/snackbar'

export default function (state = {}, action) {
  switch (action.type) {
    case SNACKBAR_SHOW:
      if (action.payload.message instanceof SnackbarMessage) {
        if (!state.messages) {
          state.messages = []
        }

        const messages = [action.payload.message.toJSON(), ...state.messages]

        return {
          ...state,
          messages,
        }
      } else {
        console.error(
          'Snackbar needs to object type like SnackbarMessage. Please check it.',
        )
      }

      return state
    case SNACKBAR_DISPOSE:
      return {
        ...state,
        messages: state.messages.filter(
          message => message.id !== action.payload.id,
        ),
      }
    default:
      return state
  }
}
