import { put, call, take, fork } from 'redux-saga/effects'
import { questionMediaDeleteService } from '../../api/question'
import { questionMediaDeleteSuccess } from '../../reducers/assignment/action'
import { QUESTION_MEDIA_DELETE } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionMediaDeleteFlow(payload) {
  try {
    const { id, questionId, callback } = payload
    yield call(questionMediaDeleteService, id)

    yield put(questionMediaDeleteSuccess(id, questionId))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionMediaDeleteWatcher() {
  while (true) {
    const action = yield take(QUESTION_MEDIA_DELETE)
    const { payload } = action

    yield fork(generalSaga, questionMediaDeleteFlow, payload)
  }
}
