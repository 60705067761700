const styles = theme => ({
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
  },
  createButton: {
    height: 48,
    width: 207,
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      height: 'fit-content',
      fontSize: '.9rem',
    },
  },
  modal: {
    display: 'flex !important',
  },
  modalContent: {
    borderRadius: theme.shape.borderRadiusMd,
    height: 503,
    width: 712,
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
  },
  namingModalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadiusMd,
    minHeight: 258,
    width: 712,
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
  },
  headerGroup: {
    margin: theme.spacing(3),
    marginLeft: 25,
    marginBottom: 31,
  },
  cardSelectorGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 25,
    marginRight: 10,
  },
  option: {
    height: 278,
    width: 324,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('all'),
    border: `solid 1px ${theme.palette.grey[500_32]}`,
    margin: 'auto',
    marginBottom: 38,
    marginRight: 15,
  },
  isSelected: {
    boxShadow: theme.shadows[25].z8,
  },
  radioButtonHolder: {
    alignSelf: 'flex-end',
  },
  checkIcon: {
    color: '#00AB55',
  },
  smallImage: {
    height: 80,
    width: 80,
    marginBottom: theme.spacing(1),
  },
  cardSelectorText: {
    textAlign: 'center',
  },
  cardSelectorSubtext: {
    fontWeight: 400,
    textAlign: 'center',
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: 25,
  },
  imageUpload: {
    display: 'grid',
  },
  thumbNailImage: {
    maxHeight: 120,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginRight: 24,
    marginBottom: 28,
  },
  continueButton: {
    backgroundColor: '#00AB55',
    marginLeft: theme.spacing(2),
  },
  buttonText: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  root__bg: {
    display: 'grid',
    padding: theme.spacing(3),
    paddingTop: 0,
    boxSizing: 'border-box',
    width: '100%',
    gridTemplate: 'auto / 128px calc(100% - 128px)',
    gap: '15px',
    justifyContent: 'space-between',
  },
  root__section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  image__bg: {
    position: 'relative',
    display: 'flex',
    width: 128,
    height: 128,
    borderRadius: '1rem',
    border: `1px solid #d9d9d9`,
    backgroundColor: '#fbfbfb',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  image__icon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 100,
    height: 100,
    transform: 'translate(-50%, -50%)',
    opacity: 0.5,
    transition: '0.2s linear all',
    display: 'flex',
    justifyContent: 'center',
    gap: 5,
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    '&:hover': {
      opacity: 1,
    },
  },
  image__span: {
    fontSize: '0.85rem',
    lineHeight: '0.75rem',
    fontWeight: '700',
    color: theme.palette.grey[500],
    width: '100%',
  },
  image__spanSecond: {
    fontSize: '0.55rem',
    lineHeight: '0.75rem',
    fontWeight: '700',
    color: theme.palette.grey[500],
    width: '100%',
  },
  image__content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image__img: {
    maxHeight: '128px',
    zIndex: '2',
  },
  root__content: {
    display: 'flex',
    width: '100%',
    gap: '0.5rem',
    flexDirection: 'column',
  },
  input__title: {
    fontSize: '2rem',
    borderColor: 'black',
  },
  span__extension: {
    fontSize: '0.8rem',
    lineHeight: '0.75rem',
    textAlign: 'center',
    width: '128px',
    color: theme.palette.grey[500],
  },
  file__upload: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxHeight: '128px',
    cursor: 'pointer',
    opacity: '0',
  },
  image__remove: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 48,
    height: 48,
    borderRadius: '1rem',
    opacity: 0,
    backgroundColor: theme.palette.grey[500_16],
    transition: '0.2s linear all',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 0.3,
    zIndex: 999,
    '&:hover': {
      opacity: 1,
    },
  },
})

export default styles
