import React, { useState, useEffect, useRef } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import { connect } from 'react-redux'
import propTypes from './prop'
import Preloader from '../../components/preloader'
import ComponentBox from '../../components/component-box'
import MessageBox from '../../components/message-box'
import { Switch, Route } from 'react-router-dom'
import Menus from '../../routes'
import { withSnackbar } from 'notistack'
import translator from '../../translate'
import { snackbarDispose } from '../../reducers/snackbar/action'
import TopBar from './TopBar'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import jss from 'jss'

const Main = props => {
  const {
    classes,
    process,
    componentBox,
    messageBox,
    dispatch,
    snackbar,
    enqueueSnackbar,
  } = props

  const [openNav, setOpenNav] = useState(false)

  const mounted = useRef()
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true
    } else {
      if (snackbar.messages) {
        snackbar.messages.forEach(message => {
          message.messageIds.forEach(messageId => {
            enqueueSnackbar(translator._(messageId), {
              variant: message.type,
            })
          })
          dispatch(snackbarDispose(message.id))
        })
      }
    }
  })

  const hideBackDropAction = () => {
    var elem = document.getElementById('backDrop')
    elem.style.display = 'none'

    var selectTypeWidget = document.getElementById('selectTypeWidget')
    selectTypeWidget.style.border = '1px solid #ededed'
  }

  return (
    <div className={classes.root}>
      <TopBar onOpenNav={() => {}} dispatch={dispatch} />{' '}
      <div className={classes.main}>
        <Switch>
          {Menus.map((menu, index) => {
            if (menu.subMenus !== undefined) {
              return menu.subMenus.map((subMenu, index2) => {
                return (
                  <Route
                    key={index2 + 2000}
                    exact={subMenu.default}
                    path={subMenu.link}
                    component={subMenu.pages}
                  />
                )
              })
            } else {
              return (
                <Route
                  key={index}
                  exact={menu.default}
                  path={menu.link}
                  component={menu.pages}
                />
              )
            }
          })}
        </Switch>
      </div>
      {messageBox.content && (
        <MessageBox content={messageBox.content} dispatch={dispatch} />
      )}
      {process && <Preloader process={process} />}
      {componentBox.content && (
        <ComponentBox content={componentBox.content} dispatch={dispatch} />
      )}
      <div
        id="backDrop"
        className={classes.backdrop}
        onClick={() => hideBackDropAction()}
      >
       {/*  <KeyboardBackspaceIcon />
        <span>You can add a card on the left.</span> */}
      </div>
    </div>
  )
}

Main.propTypes = propTypes

const mapStateToProps = response => {
  return {
    process: response.process,
    componentBox: response.componentBox,
    messageBox: response.messageBox,
    snackbar: response.snackbar,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(withSnackbar(Main)))
