import React from 'react'
// material
import { Container, withStyles } from '@material-ui/core'

import style from './style'

function Suggestions(props) {
  const { classes } = props

  return (
    <Container>
      <iframe
        width="100%"
        height="1500"
        className={classes.iframe}
        title="suggestions"
        src="https://app.loopedin.io/suggestions-by-categories"
      ></iframe>
    </Container>
  )
}

export default withStyles(style)(Suggestions)
