import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import { connect } from 'react-redux'
import parse from 'html-react-parser';
import translator from '../../translate'
import { withSnackbar } from 'notistack'
import { snackbarDispose } from '../../reducers/snackbar/action'
import { get as getCookie } from 'es-cookie'
import { CssBaseline, Paper, Typography, Box } from '@material-ui/core'
import Logo from '../../components/logo/index'
import FormChangePassword from '../../container/form-renew-password'

import { meService } from '../../api/authentication'
class SetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
    }
  }

  componentDidMount() {
    this.checkMe()
  }

  async checkMe() {
    const resMe = await meService()

    if (resMe.data.name) {
      this.setState({ name: resMe.data.name })
    } else {
      window.location.href = '/'
    }
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  /**
   *
   */
  render() {
    const { classes, dispatch } = this.props
    const { name } = this.state
    translator.changeLanguage(getCookie('language'))
    if (name) {
      return (
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <img
              src="/assets/renew-password.svg"
              className={classes.maskot}
              alt="maskot"
            ></img>

            <Typography className={classes.info1}>
              {translator._('setPassword.hi').replace('#NAME#', name)}
              <br></br>
              {parse(translator._('setPassword.hi2'))}
              <br></br>
              {translator._('setPassword.renewInfo')}
            </Typography>
            <Box m={2}></Box>
            <div>
              <FormChangePassword hasHeader={false} dispatch={dispatch} />
            </div>
          </Paper>
        </main>
      )
    } else {
      return ''
    }
  }

  RenderButtonText = () => {
    return translator._('setPassword.renewPassword')
  }
  componentDidUpdate() {
    const { snackbar, enqueueSnackbar, dispatch } = this.props
    if (snackbar.messages) {
      snackbar.messages.forEach(message => {
        message.messageIds.forEach(messageId => {
          enqueueSnackbar(translator._(messageId), {
            preventDuplicate: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
        dispatch(snackbarDispose(message.id))
      })
    }
  }
}

const mapStateToProps = response => ({
  snackbar: response.snackbar,
})

export default connect(mapStateToProps)(
  withStyles(styles)(withSnackbar(SetPassword)),
)
