const SHOP_UPDATE_ENTITY = 'SHOP_UPDATE_ENTITY'

const SHOP_GET = 'SHOP_GET'
const SHOP_GET_SUCCESS = 'SHOP_GET_SUCCESS'
const SHOP_GET_ERROR = 'SHOP_GET_ERROR'

const SHOP_EDIT = 'SHOP_EDIT'
const SHOP_EDIT_SUCCESS = 'SHOP_EDIT_SUCCESS'
const SHOP_EDIT_ERROR = 'SHOP_EDIT_ERROR'

const SHOP_PATCH = 'SHOP_PATCH'
const SHOP_PATCH_SUCCESS = 'SHOP_PATCH_SUCCESS'
const SHOP_PATCH_ERROR = 'SHOP_PATCH_ERROR'

export {
  SHOP_UPDATE_ENTITY,
  SHOP_GET,
  SHOP_GET_SUCCESS,
  SHOP_GET_ERROR,
  SHOP_EDIT,
  SHOP_EDIT_SUCCESS,
  SHOP_EDIT_ERROR,
  SHOP_PATCH,
  SHOP_PATCH_SUCCESS,
  SHOP_PATCH_ERROR,
}
