import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import { connect } from 'react-redux'
import translator from '../../translate'
import propTypes from './prop'
import { withSnackbar } from 'notistack'
import { snackbarDispose } from '../../reducers/snackbar/action'
import Requirements from '../../container/form-renew-password/requirements'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CopyIcon from '@material-ui/icons/FileCopy'

import {
  Button,
  CssBaseline,
  FormControl,
  Paper,
  Input,
  InputAdornment,
  IconButton,
  Divider,
  Box,
} from '@material-ui/core'
import {
  userPasswordSetAction,
  userPasswordPutAction,
} from '../../reducers/user-password/action'
import { userValidateShortLinkAction } from '../../reducers/user/action'
import Logo from '../../components/logo/index'
class SetPw extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSetPasswordPage: this.props.match.path.includes('set-pw'),
      hideFormArea: false,
      long: false,
      number: false,
      lowercase: false,
      uppercase: false,
      special: false,
      password: '',
      showPassword: false,
      disabled: true,
    }
  }

  onHandleSetPassword = event => {
    event.preventDefault()
    const { match } = this.props
    const { params } = match
    const { sIntegLink } = params

    const password = event.target.password.value
    const repassword = password

    const data = {
      shortLink: sIntegLink,
      password,
      repassword,
    }

    if (this.state.isSetPasswordPage) {
      this.props.dispatch(userPasswordSetAction(data))
    } else {
      this.props.dispatch(userPasswordPutAction(data))
    }

    this.setState({ hideFormArea: true })
  }

  handleChange = prop => event => {
    let regexForLong = new RegExp('(?=.{8,})')

    let long = regexForLong.test(event.target.value)

    let regexForNumber = new RegExp('(?=.*[0-9])')

    let number = regexForNumber.test(event.target.value)

    let regexForLowercase = new RegExp('(?=.*[a-z])')

    let lowercase = regexForLowercase.test(event.target.value)

    let regexForUppercase = new RegExp('(?=.*[A-Z])')

    let uppercase = regexForUppercase.test(event.target.value)

    let regexForSpecial = new RegExp('(?=.*[!@#$%^&*])')

    let special = regexForSpecial.test(event.target.value)

    this.setState({
      password: event.target.value,
      long,
      number,
      lowercase,
      uppercase,
      special,
      disabled: !(long && number && lowercase && uppercase && special),
    })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  componentDidMount() {
    const { match } = this.props
    const { params } = match
    const { sIntegLink } = params

    this.props.dispatch(userValidateShortLinkAction(sIntegLink))
  }

  copyUserName = () => {
    var textField = document.createElement('textarea')
    textField.innerText = this.props.entity.userName
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()

    alert('Copied Username: ' + this.props.entity.userName)
  }

  /**
   *
   */
  render() {
    const { classes, entity, process, match } = this.props
    const {
      hideFormArea,
      long,
      number,
      lowercase,
      uppercase,
      special,
      password,
      showPassword,
      disabled,
    } = this.state

    if (entity) {
      translator.changeLanguage(entity.language)
    }
    if (entity == null) {
      return ''
    } else if (entity.hasPassword && this.state.isSetPasswordPage) {
      return (
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <img
              src="/assets/becompe-maskot.png"
              className={classes.maskot}
            ></img>

            <div className={classes.logoHolder}>
              <img src={entity.shopLogo} className={classes.logo}></img>
              <span className={classes.line}></span>

              <Logo className={classes.logoApp}></Logo>
            </div>

            <form className={classes.form} onSubmit={this.onHandleSetPassword}>
              <p className={classes.info1}>
                {translator._('setPassword.info3')}
              </p>
            </form>

            <div className={classes.storeHolder}>
              <a
                href={
                  window.location.hostname.indexOf('rootie') !== -1
                    ? translator._('setPassword.appStoreUrlRootie')
                    : translator._('setPassword.appStoreUrlBecompe')
                }
                target="_blank"
              >
                <img
                  src="/assets/app-store-badge.png"
                  height="35"
                  className={classes.appStore}
                ></img>
              </a>

              <a
                href={
                  window.location.hostname.indexOf('rootie') !== -1
                    ? translator._('setPassword.playStoreUrlRootie')
                    : translator._('setPassword.playStoreUrlBecompe')
                }
                target="_blank"
              >
                <img src="/assets/google-play-badge.png" height="35"></img>
              </a>
            </div>
          </Paper>
        </main>
      )
    } else if (hideFormArea) {
      return (
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Box m={2}></Box>
            <div className={classes.logoHolder}>
              <img src={entity.shopLogo} className={classes.logo}></img>
            </div>
            <Box m={1}></Box>
            <a className={classes.yourUserName}>
              {translator._('setPassword.yourUserName')}
            </a>
            <p className={classes.userName}>
              {entity.userName}{' '}
              <IconButton size="small" onClick={this.copyUserName}>
                <CopyIcon fontSize="small" color="secondary"></CopyIcon>
              </IconButton>
            </p>
            <div className={classes.success}>
              <CheckCircleIcon
                style={{
                  color: 'white',
                  verticalAlign: 'middle',
                }}
              ></CheckCircleIcon>{' '}
              <span>{translator._('setPassword.successMessage')}</span>
            </div>
            <p className={classes.info2}>{translator._('setPassword.info2')}</p>
            <Box m={2}></Box>
            <Divider variant="middle" className={classes.divider} />{' '}
            <Box m={2}></Box>
            <Logo className={classes.logoApp}></Logo>
            <Box m={1}></Box>
            <a
              className={classes.supportEmail}
              href="mailto:support@rootielearning.com"
            >
              support@rootielearning.com
            </a>
            <Box m={1}></Box>
            <div className={classes.storeHolder}>
              <a
                href={
                  window.location.hostname.indexOf('rootie') !== -1
                    ? translator._('setPassword.appStoreUrlRootie')
                    : translator._('setPassword.appStoreUrlBecompe')
                }
                target="_blank"
              >
                <img
                  src="/assets/app-store-badge.png"
                  height="45"
                  className={classes.appStore}
                ></img>
              </a>

              <a
                href={
                  window.location.hostname.indexOf('rootie') !== -1
                    ? translator._('setPassword.playStoreUrlRootie')
                    : translator._('setPassword.playStoreUrlBecompe')
                }
                target="_blank"
              >
                <img src="/assets/google-play-badge.png" height="45"></img>
              </a>
            </div>
          </Paper>
        </main>
      )
    } else {
      return (
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <img
              src="/assets/becompe-maskot.png"
              className={classes.maskot}
            ></img>

            <div className={classes.logoHolder}>
              <img src={entity.shopLogo} className={classes.logo}></img>
              <span className={classes.line}></span>

              <Logo className={classes.logoApp}></Logo>
            </div>
            <form className={classes.form} onSubmit={this.onHandleSetPassword}>
              <p className={classes.info1}>
                {translator._('setPassword.info1')}
              </p>

              <FormControl
                margin="normal"
                required
                fullWidth
                className={classes.input}
              >
                <Input
                  id="password"
                  placeholder="Password"
                  inputProps={{
                    'aria-label': 'description',
                  }}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={this.handleChange('password')}
                  label={translator._('setPassword.password')}
                  variant="outlined"
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.handleClickShowPassword()
                        }}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Requirements
                long={long}
                number={number}
                uppercase={uppercase}
                lowercase={lowercase}
                special={special}
                classes={classes}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled}
                className={classes.submit}
              >
                {this.RenderButtonText()}
              </Button>
            </form>
          </Paper>
        </main>
      )
    }
  }

  RenderButtonText = () => {
    if (this.state.isSetPasswordPage) {
      return translator._('setPassword.setPassword')
    } else {
      return translator._('setPassword.changePassword')
    }
  }
  componentDidUpdate() {
    const { snackbar, enqueueSnackbar, dispatch } = this.props
    if (snackbar.messages) {
      snackbar.messages.forEach(message => {
        message.messageIds.forEach(messageId => {
          enqueueSnackbar(translator._(messageId), {
            preventDuplicate: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
        dispatch(snackbarDispose(message.id))
      })
    }
  }
}

SetPw.propTypes = propTypes

const mapStateToProps = response => ({
  authentication: response.authentication,
  snackbar: response.snackbar,
  entity: response.user.entity,
})

export default connect(mapStateToProps)(withStyles(styles)(withSnackbar(SetPw)))
