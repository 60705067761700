/*eslint-disabled */
export default class MessageBoxContent {
  /**
   *
   * @param {MessageBoxType} type
   * @param {string} message
   */
  constructor(type, message, buttons, description) {
    this._type = type
    this._message = message
    this._buttons = buttons
    this._description = description
  }

  /**
   *
   */
  get type() {
    return this._type
  }

  /**
   *
   */
  get message() {
    return this._message
  }

  /**
   *
   */
  get buttons() {
    return this._buttons
  }

  get description() {
    return this._description
  }

  /**
   *
   */
  toJSON() {
    return {
      type: this._type,
      message: this._message,
      buttons: this._buttons,
      description: this._description,
    }
  }
}

export class MessageBoxType {
  static ERROR = 0
  static INFO = 1
  static SUCCESS = 2
  static WARNING = 3
}
/*eslint-enabled */
