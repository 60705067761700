import { put, call, take, fork } from 'redux-saga/effects'
import { teamsAddService } from '../../api/teams'
import { addTeamSuccess } from '../../reducers/teams/action'
import { ADD_TEAM } from '../../reducers/teams/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* addTeamFlow(payload) {
  try {
    const { name, callback } = payload

    const response = yield call(teamsAddService, name)

    yield put(addTeamSuccess(response.data))
    yield put(operationSuccess())
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* teamAddWatcher() {
  while (true) {
    const action = yield take(ADD_TEAM)
    const { payload } = action

    yield fork(generalSaga, addTeamFlow, payload)
  }
}
