const URL = {
  LIST_QUESTION: 'Question/GetQuestionsByFilter', //onceliksiz
  GET_QUESTION: 'Question/GetQuestionById', //onceliksiz
  GET_QUESTION_LIST: 'Question/GetQuestionListById', //kullanilmiyor
  ADD_QUESTION: 'Question/AddQuestion', //kullanilmiyor
  EDIT_QUESTION: 'Question/EditQuestion', //kullanilmiyor
  ADD_QUESTION_INSIDE_COURSE: 'Card/CreateAndAddToCourse',
  PATCH_QUESTION: 'Card/Patch',
  PATCH_SETTING: 'Card/PatchSetting',
  CREATE_SETTING: 'Card/CreateSetting',
  REMOVE_SETTING: 'Card/RemoveCardSetting',
  ADD_QUESTIONSELECTION: 'Card/CreateSelection',
  PATCH_SELECTION: 'Card/PatchSelection',
  ADD_QUESTIONMEDIA: 'Card/AddCardMedia',
  ADD_QUESTIONLINK: 'Card/CreateLink',
  DELETE_QUESTIONMEDIA: 'Card/RemoveCardMedia',
  DELETE_QUESTIONLINK: 'Card/RemoveCardLink',
  DELETE_QUESTIONSELECTION: 'Card/RemoveCardSelection',
  GET_SERTIFIER_DELIVERIES: 'Integration/GetSertifierDeliveries',
  ADD_QUESTIONSELECTIONS: 'Card/CreateSelections',
  DELETE_QUESTION: 'Question/DeleteQuestion', //kullanilmiyor
  DELETE_QUESTION_LIST: 'Question/DeleteQuestionList', //kullanilmiyor
  ACTIVE_QUESTION_LIST: 'Question/MakeQuestionListActive', //kullanilmiyor
  PASSIVE_QUESTION_LIST: 'Question/MakeQuestionListPassive', //kullanilmiyor
  ADD_QUESTION_TAG: 'Question/AddQuestionTagRelation', //kullanilmiyor
  ADD_QUESTION_GAMEINFO: 'Question/AddGameInfoQuestionRelation', //kullanilmiyor
  DELETE_QUESTION_TAG: 'Question/RemoveQuestionTagRelation', //kullanilmiyor
  DELETE_QUESTION_GAMEINFO: 'Question/RemoveGameInfoQuestionRelation', //kullanilmiyor
  QUESTION_REPORT_COMPARE_TEAMS: 'Report/QuestionAnalysisCompareTeams', //kullanilmiyor
  QUESTION_REPORT_RELATED_TEAMS: 'Report/QuestionAnalysisRelatedTeamList', //kullanilmiyor
  QUESTION_REPORT_SHOW_USER_ANSWERS: 'Report/QuestionAnalysisShowUserAnswers', //kullanilmiyor
  QUESTION_REPORT_CHOICE_TENDENCY: 'Report/QuestionAnalysisChoiceTendency', //kullanilmiyor
  QUESTION_REPORT_IMPROVEMENT: 'Report/ImprovementTeamReport', //kullanilmiyor
  CLONE_QUESTION: 'Question/CloneQuestion', //kullanilmiyor
  PREVIEW_QUESTION: 'Question/CreatePreviewQuestionShortLink', //kullanilmiyor
}

export default URL
