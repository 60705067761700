import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { OPENAI_GENERATE_IMAGE } from '../../reducers/media-image/type'
import { openaiGenerateImageService } from '../../api/media-image'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* openaiGenerateImageFlow(payload) {
  try {
    const { description, callback } = payload

    const response = yield call(openaiGenerateImageService, description)

    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* openaiGenerateImageWatcher() {
  while (true) {
    const action = yield take(OPENAI_GENERATE_IMAGE)
    const { payload } = action

    yield fork(generalSaga, openaiGenerateImageFlow, payload)
  }
}
