import URL from '../url'
import Request from '../request'

/**
 *
 */
export const mediaDirectoryGetRootService = filterType =>
  new Request(URL.API, URL.MEDIA_DIRECTORY_GET_ROOT).get({
    filterType: filterType,
  })

/**
 *
 * @param {*} id
 */
export const mediaDirectoryGetService = request =>
  new Request(URL.API, URL.MEDIA_DIRECTORY_GET).post(request)
