import { ValidationFormItemDate } from '../validation'
import { ValidationFormItemFileUpload } from '../validation'
import { ValidationFormItemGroupCheckbox } from '../validation'
import { ValidationFormItemGroupRadio } from '../validation'
import { ValidationFormItemInput } from '../validation'
import { ValidationFormItemMediaImage } from '../validation'
import { ValidationFormItemPoolSelection } from '../validation'
import { ValidationFormItemSelect } from '../validation'
import { ValidationFormItemSwitch } from '../validation'
import { ValidationFormItemTextarea } from '../validation'
import { ValidationFormItemTree } from '../validation'
import { ValidationFormItemColorPicker } from '../validation'
import { FormItemType } from '../props/form-item/default'

/**
 *
 * @param {*} props
 */
const ValidationFactory = type => {
  switch (type) {
    case FormItemType.INPUT:
      return ValidationFormItemInput
    case FormItemType.COLOR_PICKER:
      return ValidationFormItemColorPicker
    case FormItemType.DATE:
      return ValidationFormItemDate
    case FormItemType.FILE_UPLOAD:
      return ValidationFormItemFileUpload
    case FormItemType.GROUP_CHECKBOX:
      return ValidationFormItemGroupCheckbox
    case FormItemType.GROUP_RADIO:
      return ValidationFormItemGroupRadio
    case FormItemType.MEDIA_IMAGE:
      return ValidationFormItemMediaImage
    case FormItemType.POOL_SELECTION:
      return ValidationFormItemPoolSelection
    case FormItemType.SELECT:
      return ValidationFormItemSelect
    case FormItemType.SWITCH:
      return ValidationFormItemSwitch
    case FormItemType.TEXTAREA:
      return ValidationFormItemTextarea
    case FormItemType.TREE:
      return ValidationFormItemTree
    default:
      return null
  }
}

export default ValidationFactory
