import PropTypes from 'prop-types'

const propTypes = {
  column: PropTypes.object,
  entity: PropTypes.object,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  refresh: PropTypes.func,
  checked: PropTypes.bool,
  expand: PropTypes.bool,
  changeSelected: PropTypes.func,
  changeExpanded: PropTypes.func,
  align: PropTypes.string,
}

export default propTypes
