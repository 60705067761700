const style = theme => ({
  inlineRoot: {
    height: '100%',
    overflow: 'hidden',
  },
  drawerRoot: {
    width: 450,
    height: '100%',
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  modalRoot: {
    //height: '100%',
  },
})

export default style
