function* sequence(form) {
  if (form !== null) {
    let n1 = form.groups.length
    let n2 = 0
    let i = 0
    let j = 0
    let group = null

    for (i; i < n1; i++) {
      group = form.groups[i]
      j = 0
      n2 = group.formItems.length
      for (j; j < n2; j++) {
        yield group.formItems[j]
      }
    }
  }
}

/**
 *
 * @param form
 * @param callback
 */
const FormUtilReadItemAsync = async (form, callback) => {
  const formItemGenerator = sequence(form)
  let formItem = null
  do {
    formItem = formItemGenerator.next().value
    if (formItem) {
      await callback(formItem)
    }
  } while (formItem)
}
/**
 *
 * @param form
 * @param itemMap
 */
const FormUtilWriteItemAttribute = async (form, itemMap) => {
  await FormUtilReadItemAsync(form, async formItem => {
    if (itemMap[formItem.attribute]) {
      const attributeValues = itemMap[formItem.attribute]

      for (var key in attributeValues) {
        formItem[key] = attributeValues[key]
      }
    }
  })
}

/**
 *
 * @param form
 * @param itemMap
 */
const FormUtilReadItemAttribute = async (form, attribute, property) => {
  let propertyValue

  await FormUtilReadItemAsync(form, async formItem => {
    if (formItem.attribute === attribute) {
      propertyValue = formItem[property]
    }
  })

  return propertyValue
}

export {
  FormUtilReadItemAsync,
  FormUtilWriteItemAttribute,
  FormUtilReadItemAttribute,
}
