import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_VIDEO_DELETE } from '../../reducers/media-video/type'
import { mediaVideoDeleteService } from '../../api/media-video'
import { mediaVideoDeleteSuccess } from '../../reducers/media-video/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaVideoDeleteFlow(payload) {
  try {
    const { id, callback } = payload
    const response = yield call(mediaVideoDeleteService, id)

    yield put(mediaVideoDeleteSuccess(response.data))
    yield put(operationSuccess())
    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaVideoDeleteWatcher() {
  while (true) {
    const action = yield take(MEDIA_VIDEO_DELETE)
    const { payload } = action

    yield fork(generalSaga, mediaVideoDeleteFlow, payload)
  }
}
