import React from 'react'
import MediaItemFolder from './folder'
import MediaItemImage from './image'
import MediaItemVideo from './video'
import { MediaItemType } from '../../../model/media-directory'

/**
 *
 * @param {*} props
 * @param {*} onSelect
 */
const MediaItemFactory = (props, onSelect, selected) => {
  const { type, id } = props

  props = { ...props, key: id, onSelect, selected }

  switch (type) {
    case MediaItemType.FOLDER:
      return <MediaItemFolder {...props} />
    case MediaItemType.IMAGE:
      return <MediaItemImage {...props} />
    case MediaItemType.VIDEO:
      return <MediaItemVideo {...props} />
    default:
      return ''
  }
}

export default MediaItemFactory
