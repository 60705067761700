import { put, call, take, fork } from 'redux-saga/effects'
import { teamsDeleteService } from '../../api/teams'
import { deleteTeamSuccess } from '../../reducers/teams/action'
import { DELETE_TEAM } from '../../reducers/teams/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* deleteTeamFlow(payload) {
  try {
    const { id, callback } = payload
    yield call(teamsDeleteService, id)

    yield put(deleteTeamSuccess(id))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* deleteTeamWatcher() {
  while (true) {
    const action = yield take(DELETE_TEAM)
    const { payload } = action

    yield fork(generalSaga, deleteTeamFlow, payload)
  }
}
