import { FormItemPropTypes } from '../form-item/default.js'
import PropTypes from 'prop-types'

const FormItemPoolSelectionPropTypes = {
  ...FormItemPropTypes,
  list: PropTypes.array,
  add: PropTypes.func,
  remove: PropTypes.func,
  move: PropTypes.func,
  itemName: PropTypes.string.isRequired,
  parentItemName: PropTypes.string.isRequired,
  addItemButtonLabel: PropTypes.string.isRequired,
}

export default FormItemPoolSelectionPropTypes
