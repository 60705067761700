import React from 'react'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import style from './style'
import propTypes from './prop'
import { withStyles, IconButton } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const TablePaginationActions = props => {
  const { classes, onPageChange, page, rowsPerPage, count } = props
  /**
   *
   */
  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0)
  }

  /**
   *
   */
  const handleBackButtonClick = event => {
    onPageChange(event, page - 1)
  }

  /**
   *
   */
  const handleNextButtonClick = event => {
    onPageChange(event, page + 1)
  }

  /**
   *
   */
  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}
TablePaginationActions.propTypes = propTypes

export default withStyles(style)(TablePaginationActions)
