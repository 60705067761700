const style = theme => ({
  root: {
    padding: 2,
    paddingLeft: theme.spacing(1),
    border: 'none',
  },
  fontSizeNormal: {
    fontSize: 13,
  },
  selectColumn: {
    width: 40,
    padding: 0,
    textAlign: 'center',
  },
  expandedColumn: {
    width: 40,
    padding: 0,
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  value: {
    display: 'table-cell',
    fontSize: '1em',
  },
  suffix: {
    display: 'table-cell',
    paddingLeft: 5,
  },
  expand: {
    width: 'auto',
    height: 'auto',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: theme.spacing(0.5),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: 'rotate(180deg)',
  },

  icon: {
    color: '#919EAB',
  },
  actionButton: {
    float: 'right',
  },
})

export default style
