const styles = theme => ({
  group: {
    height: 60,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      height: 'auto',
    },
  },
  groupTitleCard: {
    alignItems: 'center',
    backgroundColor: '#F4F6F7',
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-evenly',
    width: 80,
  },
  groupTitle: {
    color: '#212B36',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.5,
    marginRight: theme.spacing(2),
    width: 72,
  },
  groupImage: {
    height: 63,
    width: 63,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  card: {
    boxShadow: `none !important`,
    borderRadius: theme.spacing(1),
    height: 80,
    //marginLeft: theme.spacing(3),
    width: 80,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      width: 135,
    },
  },
  cardAction: {
    display: 'flex',
    flexDirection: 'column',
    height: 80,
    width: 80,
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      height: '75px',
      width: 70,
      justifyContent: 'center',
    },
  },
  radioButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  checkIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    color: '#00AB55',
  },
  cardMedia: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  optionImage: {
    height: 33,
    width: 33,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
  cardText: {
    color: '#212B36',
    fontSize: 14,
    lineHeight: 1.3,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    width: 80,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginRight: 24,
    marginBottom: 28,
  },
  continueButton: {
    backgroundColor: '#00AB55',
    marginLeft: theme.spacing(2),
  },
  buttonText: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  speadDialTitle: {
    fontSize: 11,
    width: 45,
    textAlign: 'center',
  },
  speedDialWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    background: '#fdfdfd',
    backdropFilter: 'blur(8px)',
    borderRadius: '20px',
    padding: '12px 30px',
    width: '420px',
    justifyContent: 'space-between',
    overflow: 'hidden',
    //marginBottom: '1rem',
  },
  speedDial: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  speedDialHolderOpen: { overflow: 'hidden' },
  speedDialHolder: { overflow: 'auto' },

  StaticTooltipLabel: {
    background: '#ffe7d9',
    color: '#ff4842',
    fontSize: '.65rem',
    width: 'max-content',
    whiteSpace: 'pre-line',
  },
  StaticTooltipLabelStripe: {
    position: 'absolute',
    background: '#ffe7d9',
    color: '#ff4842',
    fontSize: '.65rem',
    width: 'max-content',
    marginTop: '3.2rem',
    marginLeft: '.6rem',
    fontWeight: 'bold',
    left: '0',
    bottom: '52px',
    whiteSpace: 'pre-line',
  },
  tooltip: {
    backgroundColor: '#0d53b7 !important',
  },
  aiImage: {
    borderRadius: '50%',
    height: '56px',
    width: '56px',
    '&:hover': { cursor: 'pointer' },
  },
})

export default styles
