import React from 'react'
import styles from '../styles'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Typography,
  TextField,
  Box,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Tab,
  Tooltip,
  IconButton,
  Divider,
} from '@material-ui/core'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

const SourceItem = props => {
  const {
    courseDocuments,
    handleDelete,
    produceType,
    selectedMenu,
    classes,
  } = props

  const handleDeleteItem = item => {
    if (
      item.fileUrl.includes(
        'https://llm.ams3.cdn.digitaloceanspaces.com/sources/',
      )
    ) {
      let itemArray = item.fileUrl.split('/')
      let fileName = itemArray[itemArray.length - 1]
      handleDelete(fileName, item.id)
    } else {
      handleDelete(item.fileUrl, item.id)
    }
  }

  return (
    <div style={{ marginBottom: 8 }}>
      {courseDocuments.map((item, index) => (
        <div
          style={{
            marginBottom: 8,
          }}
          key={'source_item' + index}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip title={item.fileName} placement="top">
              <a
                href={item.fileUrl}
                target="_blank"
                style={{ color: '#002E47' }}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    width: '250px',
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.fileName}
                </Typography>
              </a>
            </Tooltip>

            <div style={{ flexGrow: 1 }}></div>
            <IconButton
              style={{ color: '#5865f2' }}
              size="small"
              onClick={() => handleDeleteItem(item)}
            >
              <DeleteOutlineIcon style={{ color: '#F67B3F' }} />
            </IconButton>
          </div>
          <Divider style={{ backgroundColor: 'rgba(51, 102, 255, 0.16)' }} />
        </div>
      ))}
    </div>
  )
  // }
}

export default withStyles(styles)(SourceItem)
