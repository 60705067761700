const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  root: {
    height: '100vh',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    marginTop: theme.spacing(3),
    alignSelf: 'center',
  },

  response: {
    whiteSpace: 'break-spaces',
  },
  characterCount: {
    textAlign: 'right',
    fontSize: 11,
  },
  title: {
    textAlign: 'center',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: ' 50vh',
    letterSpacing: 1,
  },

  animationTitle: {
    backgroundImage:
      'url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif)',
    backgroundSize: 'cover',
    color: 'transparent',
    textTransform: 'uppercase',
    fontSize: 80,
    lineHeight: 1,
    fontWeight: 'bold',
    margin: '10px 0',
    ['-moz-background-clip']: 'text',
    ['-webkit-background-clip']: 'text',
  },

  header: {
    marginBottom: theme.spacing(1),
  },
  upgradeArea: {
    textAlign: 'center',
  },
  upgradeGif: {
    margin: '0 auto',
    marginBottom: theme.spacing(4),
  },
  textAreaHolder: { maxWidth: 450, margin: '0 auto' },
  limitText: {
    textAlign: 'center',
    fontSize: 14,
    marginBottom: theme.spacing(1),
    '& span': {
      fontWeight: 'bold',
      fontSize: 14,
    },
  },
  loadingHolder: {
    margin: '0 auto',
  },
  popUp: {
    width: '550px',
    height: '100%',
  },
  textFirst: { paddingLeft: theme.spacing(2) },
  instructionBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  grow: { flexGrow: 1 },
  instructionsHover: {
    fontSize: 'smaller',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
