const styles = theme => ({
  page__header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 16px',
  },
  page__header__title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  page__header__button: {
    borderRadius: '12px',
    backgroundColor: '#37D768',
    color: '#fff',
    height: '40px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#63f28f',
    },
  },
  breadCrumbsText: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
})

export default styles
