import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const subjectListByQueryService = query =>
  new Request(URL.API, URL.LIST_SUBJECT_BY_QUERY).post(query)

/**
 *
 * @param {*} parentId
 */
export const subjectListByParentService = parentId =>
  new Request(URL.API, URL.LIST_SUBJECT_BY_PARENT).get({ parentId })

/**
 *
 * @param {*} parentId
 */
export const subjectListByParentAddEmptyService = parentId =>
  new Request(
    URL.API,
    URL.LIST_SUBJECT_BY_PARENT + '?parentId=' + parentId + '&addEmpty=true',
  ).get()

/**
 *
 * @param {*} id
 */
export const subjectGetService = id =>
  new Request(URL.API, URL.GET_SUBJECT).get({ id })
/**
 *
 * @param {*} entity
 */
export const subjectAddService = entity =>
  new Request(URL.API, URL.ADD_SUBJECT).post(entity)

/**
 *
 * @param {*} entity
 */
export const subjectEditService = entity =>
  new Request(URL.API, URL.EDIT_SUBJECT).put(entity)

/**
 *
 * @param {*} content
 */
export const subjectPatchService = content =>
  new Request(URL.API, URL.PATCH_SUBJECT + '?id=' + content.id).patch(content)

/**
 *
 * @param {*} id
 */
export const subjectDeleteService = id =>
  new Request(URL.API, URL.DELETE_SUBJECT).delete({ id })

/**
 * @param {*} entity
 */
export const subjectQuestionAddService = entity =>
  new Request(URL.API, URL.ADD_QUESTION_TAG).post(entity)

/**
 * @param {*} entity
 */
export const subjectQuestionDeleteService = entity =>
  new Request(URL.API, URL.DELETE_QUESTION_TAG).post(entity)

/**
 * @param {*} ids
 */
export const subjectListMakeActiveService = ids =>
  new Request(URL.API, URL.ACTIVE_SUBJECT_LIST).post(ids)
/**
 * @param {*} ids
 */
export const subjectListMakePassiveService = ids =>
  new Request(URL.API, URL.PASSIVE_SUBJECT_LIST).post(ids)

/**
 * @param {*} ids
 */
export const subjectListDeleteService = ids =>
  new Request(URL.API, URL.DELETE_SUBJECT_LIST).post(ids)

// REPORT

export const subjectReportLineSeriesService = reqParams =>
  new Request(URL.REPORT_API, URL.SUBJECT_REPORT_LINE_SERIES).post(reqParams)

/**
 *
 * @param {*}
 */
export const subjectReportContentBreakdownService = reqParams =>
  new Request(URL.REPORT_API, URL.SUBJECT_REPORT_CONTENT_BREAKDOWN).get(
    reqParams,
  )

/**
 *
 * @param {*} reqParams
 */
export const subjectReportEngagementService = reqParams =>
  new Request(URL.REPORT_API, URL.SUBJECT_REPORT_TEAM_ENGAGEMENT_DATA).post(
    reqParams,
  )

/**
 *
 * @param {*} reqParams
 */
export const subjectReportPerformanceService = reqParams =>
  new Request(URL.REPORT_API, URL.SUBJECT_REPORT_TEAM_PERFORMANCE_DATA).post(
    reqParams,
  )

/**
 *
 * @param {*}
 */
export const subjectReportRelatedTeamListService = reqParams =>
  new Request(URL.REPORT_API, URL.SUBJECT_REPORT_RELATED_TEAM_LIST).post(
    reqParams,
  )

/**
 *
 * @param {*}
 */
export const subjectReportContentAnalysisAssessmentService = reqParams =>
  new Request(
    URL.REPORT_API,
    URL.SUBJECT_REPORT_CONTENT_ANALYSIS_ASSESSMENT,
  ).post(reqParams)

/**
 *
 * @param {*}
 */
export const subjectReportContentAnalysisNonAssessmentService = reqParams =>
  new Request(
    URL.REPORT_API,
    URL.SUBJECT_REPORT_CONTENT_ANALYSIS_NON_ASSESSMENT,
  ).post(reqParams)

/**
 *
 * @param {*} entity
 */
export const subjectListSetParentService = entity =>
  new Request(URL.API, URL.SET_PARENT_OF_SUBJECT_LIST).post(entity)

export const checkSubjectNameAvailableService = name =>
  new Request(URL.API, URL.CHECK_SUBJECT_NAME_AVAILABLE).get({ name })

/**
 *
 * @param {*} reqParams
 */
export const subjectReportImprovementService = reqParams =>
  new Request(URL.REPORT_API, URL.SUBJECT_REPORT_IMPROVEMENT_DATA).post(
    reqParams,
  )
