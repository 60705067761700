import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_FOLDER_DELETE } from '../../reducers/media-folder/type'
import { mediaFolderDeleteService } from '../../api/media-folder'
import { mediaFolderDeleteSuccess } from '../../reducers/media-folder/action'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaFolderDeleteFlow(payload) {
  try {
    const { id, callback } = payload
    const response = yield call(mediaFolderDeleteService, id)

    yield put(mediaFolderDeleteSuccess(response.data))
    yield put(operationSuccess())
    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaFolderDeleteWatcher() {
  while (true) {
    const action = yield take(MEDIA_FOLDER_DELETE)
    const { payload } = action

    yield fork(generalSaga, mediaFolderDeleteFlow, payload)
  }
}
