const style = theme => ({
  logo: {
    margin: '0 auto',
    height: 30,
    [theme.breakpoints.down('md')]: {
      height: 20,
    },
  },
})

export default style
