import { guid } from '../../../../utils/guid'
import { FormItemType } from '../../../../form/props/form-item/default.js'
import { ShopFieldNames } from '../../../../model/shop'
import translator from '../../../../translate'
import { FormInputType } from '../../../../form/props/form-item/input'

const FormItemInfoURL = {
  id: guid().uid(),
  attribute: ShopFieldNames.INFO_URL,
  label: translator._('shop.infoURL'),
  type: FormItemType.INPUT,
  required: true,
  disabled: false,
  hidden: false,
  inputType: FormInputType.TEXT,
}

export default FormItemInfoURL
