const URL = {
  LIST_CALENDAR: 'Calendar/GetCalendarsByFilter',
  GET_CALENDAR: 'Calendar/GetCalendarById',
  ADD_CALENDAR: 'Calendar/AddCalendar',
  PATCH_CALENDAR: 'Calendar/PatchCalendar',
  DELETE_CALENDAR: 'Calendar/DeleteCalendar',
  DELETE_CALENDAR_LIST: 'Calendar/DeleteCalendarList',
  ADD_CALENDAR_TAG: 'Calendar/AddCalendarTag',
  DELETE_CALENDAR_TAG: 'Calendar/DeleteCalendarTag',
  ADD_CALENDAR_USER: 'Calendar/AddCalendarUser',
  DELETE_CALENDAR_USER: 'Calendar/DeleteCalendarUser',
}

export default URL
