import { guid } from '../../../../utils/guid'

import { FormItemVerificationStatus } from './form-item'

const Filters = {
  addTitle: guid().uid(),
  editTitle: null,
  groups: [
    {
      id: guid().uid(),
      label: null,
      collapsible: false,
      expanded: true,
      formItems: [{ ...FormItemVerificationStatus }],
    },
  ],
  formItemsSuggestion: [],
}

export default Filters
