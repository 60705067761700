const styles = theme => ({
  namingModalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadiusMd,
    minHeight: 250,
    width: 600,
    backgroundColor: '#fff',
    margin: 'auto',
  },
  headerGroup: {
    margin: theme.spacing(3),
    marginLeft: 25,
    marginBottom: 31,
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: 38,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginRight: 24,
    //marginBottom: 28,
  },
  continueButton: {
    backgroundColor: '#00AB55',
    marginLeft: theme.spacing(2),
  },
  input: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  listGroup: {
    padding: 24,
    marginTop: 0,
  },
  listLabel: {
    padding: 24,
  },
  select: {
    marginTop: '0!important',
  },
})

export default styles
