import {
  USER_PASSWORD_UPDATE_ENTITY,
  USER_PASSWORD_PUT,
  USER_PASSWORD_PUT_SUCCESS,
  USER_PASSWORD_PUT_ERROR,
  USER_PASSWORD_SET,
  USER_PASSWORD_SET_SUCCESS,
  USER_PASSWORD_SET_ERROR,
  USER_RENEW_PASSWORD,
  USER_RENEW_PASSWORD_SUCCESS,
  USER_RENEW_PASSWORD_ERROR,
} from './type'

/**
 *
 * @param {*} entity
 */
export const userPasswordUpdateEntityAction = entity => {
  return {
    type: USER_PASSWORD_UPDATE_ENTITY,
    payload: { entity },
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const userPasswordPutAction = (entity, callback) => {
  return {
    type: USER_PASSWORD_PUT,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const userPasswordPutSuccess = response => {
  return {
    type: USER_PASSWORD_PUT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userPasswordPutError = () => {
  return {
    type: USER_PASSWORD_PUT_ERROR,
  }
}

export const userPasswordSetAction = (entity, callback) => {
  return {
    type: USER_PASSWORD_SET,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const userPasswordSetSuccess = response => {
  return {
    type: USER_PASSWORD_SET_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userPasswordSetError = () => {
  return {
    type: USER_PASSWORD_SET_ERROR,
  }
}

export const userRenewPasswordAction = (entity, callback) => {
  return {
    type: USER_RENEW_PASSWORD,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const userRenewPasswordSuccess = response => {
  return {
    type: USER_RENEW_PASSWORD_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const userRenewPasswordError = () => {
  return {
    type: USER_RENEW_PASSWORD_ERROR,
  }
}
