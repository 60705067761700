import URL from "src/api/url"


const Options = [
  {
    cardName: 'Warm up',
    icon: '/assets/onboarding-templates/warmup.png',
    appBarIcon:
      '/assets/onboarding-templates/onboarding-app-bar/warmup-app-bar.svg',
    text: 'Create excitement before training & workshop sessions.',
    items: [
      {
        name: 'Warm-up before Wellbeing Sessions',
        code: 'warmup-638088580418814988',
        id: '12389',
        thumbnail:
          URL.CDN+'production/images/'+'production/images/'+'bengi637847907641458216/204c8372-ce50-4dde-8f83-eec14a0b796f.png',
        about:
          'Warm-up mini-courses like this one will increase engagement, build anticipation, and let you tailor content to your audience for a more effective session. Give an outline, assign a task, make quizzes, and ask questions. This template is prepared with a lighter theme that would suit subjects like meditation very well.',
      },
      {
        name: 'Warm-up with a Cute Tone',
        code: 'warmup-638097084726033439',
        id: '12717',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/4748c8e6-e8a5-4b64-93a9-1409ad1be8c4.png',
        about:
          'Warm-up mini-courses like this one will increase engagement, build anticipation, and let you tailor content to your audience for a more effective session. Give an outline, assign a task, make quizzes, and ask questions. This template is prepared with a cute theme that would suit subjects like pet training very well.',
      },
      {
        name: 'Warm-up with a Formal Tone',
        code: 'warmup-638097084886668688',
        id: '12718',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/6e247de4-55ba-4b71-9533-bcb6d79d7f64.png',
        about:
          'Warm-up mini-courses like this one will increase engagement, build anticipation, and let you tailor content to your audience for a more effective session. Give an outline, assign a task, make quizzes, and ask questions. This template is prepared with a formal theme that would suit subjects like leadership and sales very well.',
      },
    ],
  },
  {
    cardName: 'Recap',
    icon: '/assets/onboarding-templates/recap.png',
    appBarIcon:
      '/assets/onboarding-templates/onboarding-app-bar/recap-app-bar.svg',
    text: 'Reinforce knowledge after training & workshop sessions.',
    items: [
      {
        name: 'Recap with an Energetic Tone',
        code: 'recap-638088580802194800',
        id: '12390',
        about:
          'A recap mini-course like this will ensure that the information you shared during your session will last longer and also help you build long-term relationships with your clients. Give summaries, ask questions, and remind of other events. This template is prepared with an energetic theme that would suit subjects like public speech very well.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/267aea2a-9546-44bc-a05d-0e793bd46a28.png',
      },
      {
        name: 'Recap with a Formal Tone',
        code: 'recap-with-an-energetic-tone-638097225234489783',
        id: '12721',
        about:
          'A recap mini-course like this will ensure that the information you shared during your session will last longer and also help you build long-term relationships with your clients. Give summaries, ask questions, and remind of other events. This template is prepared with a formal theme that would suit subjects like leadership and sales very well.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/7306a000-c437-48f6-b9c9-7c67dcbd94b8.png',
      },
    ],
  },
  {
    cardName: 'Onboarding',
    icon: '/assets/onboarding-templates/onboarding.png',
    appBarIcon:
      '/assets/onboarding-templates/onboarding-app-bar/onboarding-app-bar.png',
    text: 'Welcome new members to an organization or a membership platform.',
    items: [
      {
        name: 'Onboarding for an Employee',
        code: 'onboarding-638088581055497922',
        id: '12391',
        about:
          'Teach without creating hesitancy to start and create a sense of achievement quickly. Assist your narrative with quiz questions to keep the attention high. What’s more, you can link the next mini-course at the end. This template is prepared for employee onboarding in particular, but it can fit other subjects, too.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/1960ee83-be16-41e9-aca4-3a59cf408f45.png',
      },
      {
        name: 'Onboarding for Membership Programs',
        code: 'onboarding-638091875843124789',
        id: '12494',
        about:
          'Teach without creating hesitancy to start and create a sense of achievement quickly. Assist your narrative with quiz questions to keep the attention high. What’s more, you can link the next mini-course at the end. This template is prepared for new member onboarding in particular, but it can fit other subjects, too.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/3becbb5b-1a85-4d99-b832-a8397b004d2d.png',
      },
    ],
  },
  {
    cardName: 'Lead Magnet',
    icon: '/assets/onboarding-templates/magnet.png',
    appBarIcon:
      '/assets/onboarding-templates/onboarding-app-bar/magnet-app-bar.svg',
    text: 'Engage cold audience and collect emails.',
    items: [
      {
        name: 'Lead Magnet with a Formal Tone',
        code: 'lead-magnet-638088550969348762',
        id: '12388',
        about:
          'Use your mini-course to introduce your field of expertise to your audience. Start with giving basic information, ask questions that will arouse curiosity, and collect emails with a signup wall. This template is prepared with a formal theme that would suit subjects like leadership and sales very well.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/97fc5758-f7b8-4397-a97b-433fd92de673.png',
      },
      {
        name: 'Lead Magnet with an Entertaining Tone',
        code: 'lead-magnet-638097355334736509',
        id: '12726',
        about:
          'Use your mini-course to introduce your field of expertise to your audience. Start with giving basic information, ask questions that will arouse curiosity, and collect emails with a signup wall. This template is prepared with an entertaining theme to grab attention and boost excitement.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/fbecae05-0c1e-41f5-a69e-93dbfac6ecb0.png',
      },
    ],
  },
  {
    cardName: 'Lead Nurturing',
    icon: '/assets/onboarding-templates/lead.png',
    appBarIcon:
      '/assets/onboarding-templates/onboarding-app-bar/lead-app-bar.svg',
    text: 'Promote premium offers and convert more audience into clients.',
    items: [
      {
        name: 'Lead Nurturing with a Formal Tone',
        code: 'lead-nurturing-638088581250357820',
        id: '12392',
        about:
          'Lead nurturing mini-courses like this one is perfect for promoting the right offers to the right people. Inform, determine knowledge level, and show a customized message upon completion. You can also gate access with a password wall to give a sense of exclusivity. This template is prepared with a formal theme that would suit subjects like leadership and sales very well.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/6092140d-5d40-4979-a3e8-3886a7fdcd31.png',
      },
      {
        name: 'Lead Nurturing with a Calming Tone',
        code: 'lead-nurturing-638097377226237835',
        id: '12734',
        about:
          'Lead nurturing mini-courses like this one is perfect for promoting the right offers to the right people. Inform, determine knowledge level, and show a customized message upon completion. You can also gate access with a password wall to give a sense of exclusivity. This template is prepared with a calming theme that would suit subjects like meditation very well.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/f2368813-2206-44bb-9996-5886461f0893.png',
      },
    ],
  },
  {
    cardName: 'Accepting Payment',
    icon: '/assets/onboarding-templates/payment.png',
    appBarIcon:
      '/assets/onboarding-templates/onboarding-app-bar/payment-app-bar.svg',
    text: 'Sell micro-learning journeys via Stripe.',
    items: [
      {
        name: 'Energetic Mini-Course with Stripe Paywall',
        code: 'accepting-payment-638088581512679981',
        id: '12393',
        about:
          'With a mini-course like this, you can present a sneak peek of your content first, and then receive payment via payment wall. You can give the main part of the content after the Stripe wall, all in the same mini-course. You can also display score at the end. This template is prepared with an energetic theme to grab attention and boost excitement.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/5f39b6f6-ec47-4180-b658-77d219403cbf.png',
      },
      {
        name: 'Formal Mini-Course with Stripe Paywall',
        code: 'accepting-payment-638097376519080604',
        id: '12731',
        about:
          'With a mini-course like this, you can present a sneak peek of your content first, and then receive payment via payment wall. You can give the main part of the content after the Stripe wall, all in the same mini-course. You can also display score at the end. This template is prepared with a formal theme that would suit corporate subjects very well.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/0f3e78b8-6f2a-43fa-89f7-a4ff351d7179.png',
      },
    ],
  },
  {
    cardName: 'Quiz',
    icon: '/assets/onboarding-templates/quiz.png',
    appBarIcon:
      '/assets/onboarding-templates/onboarding-app-bar/quiz-app-bar.svg',
    text: 'Let them test their knowledge and show personalized messages at the end.',
    items: [
      {
        name: 'Instructive Quiz',
        code: 'quiz-638088581664002398',
        id: '12394',
        about:
          'Make an instructive quiz to teach and test at the same time. Use a conditional card to display personalized messages at the end. This template is prepared with a plain theme not to distract the end user and help them focus.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/95e45056-6a49-45e3-bef1-c75182e2ad9b.png',
      },
      {
        name: 'Exam-like Quiz',
        code: 'quiz-638092157052263502',
        id: '12528',
        about:
          'Make a quiz with multiple quiz questions and use a conditional card to display personalized messages at the end. This template is prepared with a plain theme not to distract the end user and help them focus.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/de87ea33-8c10-48a0-af62-30ba2d9a9b62.png',
      },
    ],
  },
  {
    cardName: 'Certification',
    icon: '/assets/onboarding-templates/sertifier.png',
    appBarIcon:
      '/assets/onboarding-templates/onboarding-app-bar/sertifier-app-bar.svg',
    text: 'Give certificates upon completion or success rate via Sertifier.',
    items: [
      {
        name: 'Certification upon Course Completion',
        code: 'certification-638097352897974817',
        id: '12725',
        about:
          'You can use our direct Sertifier integration to give certificates to end users directly on your mini-courses. Give information about a subject, add questions, and at the end, give certificates to whom completes the mini-course. This template is prepared with a plain theme not to distract the end user and help them focus.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/00d08d0f-2420-4432-b4fd-c3f9d9f8622b.png',
      },
      {
        name: 'Certification Based on Score',
        code: 'certification-638088581864143585',
        id: '12395',
        about:
          'You can use our direct Sertifier integration to give certificates to end users directly on your mini-courses. Give information about a subject, make a quiz based on that information, and at the end, give certificates based on the score. This template is prepared with a plain theme not to distract the end user and help them focus.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/bce2db93-fd29-41da-b259-b924d8f0e2f7.png',
      },
    ],
  },
  {
    cardName: 'Survey',
    icon: '/assets/onboarding-templates/survey.png',
    appBarIcon:
      '/assets/onboarding-templates/onboarding-app-bar/survey-app-bar.svg',
    text: 'Learn more about your audience with free text, numeric, multiple choice, star rating questions.',
    items: [
      {
        name: 'Survey before a Meeting',
        code: 'survey-638088582057177602',
        id: '12396',
        about:
          'Ask questions to learn more about your audience before meeting them face-to-face. You can also use a page to give information, add links or PDFs, and even embed your calendars. This template is prepared with a formal theme that would suit corporate subjects very well.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/6fab9a3b-8c58-461a-847a-28f38f5d71f6.png',
      },
      {
        name: 'Survey for Getting Feedback',
        code: 'survey-638091937157742364',
        id: '12497',
        about:
          'Use a mini-course like this to get feedback from your clients following a program. Ask open-ended and star-rating questions, and don’t forget to promote the following program at the end. This template is prepared with a positive theme to entertain the end user.',
        thumbnail:
          URL.CDN+'production/images/'+'bengi637847907641458216/24f6daaf-9e25-4f7e-9aa0-ed8c4e0984ef.png',
      },
    ],
  },
]

export { Options }
