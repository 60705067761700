const URL = {
  ADD_SHOP_MESSAGE: 'ShopMessage/AddShopMessage',
  GET_SHOP_MESSAGE: 'ShopMessage/GetShopMessage',
  EDIT_SHOP_MESSAGE: 'ShopMessage/EditShopMessage',
  PATCH_SHOP_MESSAGE: 'ShopMessage/PatchShopMessage',
  GET_SHOP_MESSAGE_LIST: 'ShopMessage/GetShopMessageList',
  DELETE_SHOP_MESSAGE: 'ShopMessage/DeleteShopMessage',
}

export default URL
