import { put, call, take, fork } from 'redux-saga/effects'
import { assignmentListService } from '../../api/assignment'
import { assignmentListSuccess } from '../../reducers/assignment/action'
import { ASSIGNMENT_LIST } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* assignmentListFlow(payload) {
  try {
    const { query, callback } = payload
    const response = yield call(assignmentListService, query)

    callback(response)
    yield put(assignmentListSuccess(response.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* assignmentListWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_LIST)
    const { payload } = action

    yield fork(generalSaga, assignmentListFlow, payload)
  }
}
