import translator from '../../translate'

/**
 *
 * @param value
 */
const ValidationFormItemInput = (form, formItem) => {
  const error = { success: true, message: '' }

  if (formItem === undefined) {
    return error
  }
  if (formItem.value === '' || formItem.value === null) {
    error.success = false
    error.message = translator._('form.error.empty')

    return error
  }

  if (formItem.minLength) {
    if (formItem.value.length < formItem.minLength) {
      error.success = false
      error.message = translator._('form.error.minLength')

      return error
    }
  }

  if (formItem.maxLength) {
    if (formItem.value.length > formItem.maxLength) {
      error.success = false
      error.message = translator._('form.error.maxLength')

      return error
    }
  }

  return error
}

export default ValidationFormItemInput
