import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import ListSchema from './list'
import List from '../list'
import { ListUseModeType } from '../../model/list'
import { Typography, Card, CardContent, Box } from '@material-ui/core'
class UserList extends Component {
  static defaultProps = {
    hideTopBar: false,
    hideSearchBar: false,
    showPaging: true,
    showSelect: false,
    showChildList: false,
    usageMode: ListUseModeType.LIST,
    showDetailSearch: true,
    query: {
      currentPage: 0,
      pageSize: 0,
      asc: true,
      sortingColumn: 'id',
      filterText: 'string',
      filters: [],
    },
  }

  /**
   *
   */
  render() {
    let newQuery = this.props.query

    const { queryResult } = this.props
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            User List
          </Typography>
          <Box m={2} />
          <List
            list={ListSchema}
            disabled={false}
            {...this.props}
            query={newQuery}
          />
        </CardContent>
      </Card>
    )
  }
}

UserList.propTypes = propTypes

const mapStateToProps = response => ({
  queryResult: response.userRoleDetailedList.list,
})

export default connect(mapStateToProps)(UserList)
