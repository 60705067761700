const style = theme => ({
  pagination: {
    display: 'inline-block'
  },
  cardListHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    overflowX: 'clip',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: 'max-content'
    }
  },
  emptySlot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
})

export default style
