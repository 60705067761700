const styles = theme => ({
  iconButtonAdd: {
    transition: '0.4s all',
    color: '#f67b3f',
    backgroundColor: '#ffffff',

    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#f67b3f',
    },
    width: 32,
    height: 32,
    marginRight: 5,
  },
  addQuizCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '240px',
    height: '140px',
    minWidth: '240px',
    minHeight: '140px',
    background: '#F67B3F',
    padding: 8,
    transition: '0.4s all',
    boxShadow: 'none',
    '&:hover': {
      background: '#fff',
      cursor: 'pointer',
    },
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
  },

  modal: {
    display: 'flex !important',
  },

  namingModalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadiusMd,
    minHeight: 258,
    width: 712,
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
  },
  headerGroup: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  headerGroupSecond: {
    margin: theme.spacing(3),
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginRight: 24,
  },
  continueButton: {
    backgroundColor: '#F67B3F',
    borderRadius: 16,
    marginLeft: theme.spacing(2),
  },

  courseCardGrid: {
    marginBottom: 8,
  },
  backButton: {
    borderRadius: 16,
  },
  iconButton: {
    zIndex: 1000,
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '240px',
    height: '140px',
    minWidth: '240px',
    minHeight: '140px',
    backgroundColor: '#fff',
    boxShadow: 'none',
    padding: 16,
    '&:hover': {
      cursor: 'pointer',
      outline: '1px solid #F67B3F',
    },
  },
  modalStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  columnNameIconButton: {
    width: '32px',
    minWidth: '32px',
    height: '32px',
  },
  columnNameDiv: {
    display: 'flex',
    flexDirection: 'row',
    width: '280px',
    height: '36px',
  },
  columnNameIconButtonDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  collectionOutlinedInput: {
    typography: 'h6',
    fontWeight: 'fontWeightBold',
    width: '240px',
    borderRadius: '16px',
    height: '36px',
  },
  deleteCollectionModal: {
    display: 'flex',
    flexDirection: 'column',
  },
  deleteCollectionModalContent: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadiusMd,
    minHeight: 220,
    width: 600,
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
  },
  errorIcon: {
    color: 'red',
    width: '32px',
    height: '32px',
  },
  errorIconBackground: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',
    height: '48px',
    backgroundColor: '#ffd5d5',
    borderRadius: '16px',
    marginRight: '8px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '16px',
  },
  deleteCollectionModalTitle: {
    fontWeight: 800,
    marginBottom: '5px',
  },
  errorMessage: {
    fontWeight: 600,
    marginBottom: '16px',
  },
  errorMessageNote: {
    fontWeight: 400,
    marginBottom: '16px',
  },
  deleteButton: {
    backgroundColor: 'red',
    boxShadow: 'none',
    marginRight: '16px',
    '&:hover': {
      background: '#ee7474',
      boxShadow: 'none',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cancelButton: { border: '1px solid #f2f2f2', boxShadow: 'none' },
  learnersAccessButton: {
    display: 'flex',
    backgroundColor: '#E7F430',
    height: '36px',
    maxWidth: '130px',
    borderRadius: '16px',
    width: '100%',
    color: '#000',
    '&:hover': {
      backgroundColor: '#E7F430',
      color: '#000',
      outline: '1px solid #000',
    },
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: 500,
  },
})

export default styles
