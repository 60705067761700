import React, { useEffect, useState } from 'react'
// @mui
import { Container, Stack, Box, Alert } from '@material-ui/core'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { courseCollectionListAction } from 'src/reducers/course-collection/action'

// components
import Page from '../../components/Page'
import NonCollectionCourses from './non-collection-courses'
// sections
import { KanbanColumn, KanbanColumnAdd } from 'src/components/kanban'
import useAsync from 'src/hooks/useAsync'
import { courseCollectionUpdateAction } from 'src/reducers/course-collection/action'
import { editCourseCollectionIndexAction } from 'src/reducers/course-collection/action'
import { LimitTypeEnum } from 'src/model/limit'
import { getUserLimits } from 'src/api/limit'
import { isMobile } from 'react-device-detect'

// ----------------------------------------------------------------------

const DEFAULT_COLLECTION_GUID = '00000000-0000-0000-0000-000000000000'

// Columns are collections
const TYPE_COLUMN = 'column'
// Tasks are courses
const TYPE_TASK = 'task'

const defaultCollection = {
  id: DEFAULT_COLLECTION_GUID,
  collectionName: 'Independent Mini-Courses',
  collectionIndex: 0,
  isDeleted: false,
  userId: '',
}

const fetchCourseCollections = data => {
  const dispatch = data.dispatch
  return new Promise((resolve, reject) => {
    dispatch(
      courseCollectionListAction(data.query, response => {
        resolve(response)
      }),
    )
  })
}

function Kanban({ history }) {
  const dispatch = useDispatch()
  const { execute, status, error } = useAsync(fetchCourseCollections, true, {
    dispatch: dispatch,
    query: {
      CurrentPage: 0,
      PageSize: 20,
      SortingColumn: 'LastModifiedAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    },
  })
  const [isUserReachedCourseLimit, setIsUserReachedCourseLimit] = useState(
    'Initial',
  )
  const [
    hasCoursesWithOutCollection,
    setHasCoursesWithOutCollection,
  ] = useState(false)

  const value = useSelector(state => state.courseCollectionList.list)
  const { courseCollectionDtos: collections = [], courseDtos = [] } = value

  useEffect(() => {
    if (courseDtos && courseDtos.length > 0) {
      const filteredCourses = courseDtos.filter(
        x => x.courseCollectionId == null,
      )
      setHasCoursesWithOutCollection(filteredCourses.length > 0 ? true : false)
    }

    if (collections && collections.length > 1) {
      collections.sort((a, b) => {
        return a.collectionIndex - b.collectionIndex
      })
    }
  }, [courseDtos, collections])

  useEffect(() => {
    getUserLimits().then(response => {
      let count = response.data.find(
        x => x.limit.type == LimitTypeEnum.CourseCreation,
      ).count

      let limit = response.data.find(
        x => x.limit.type == LimitTypeEnum.CourseCreation,
      ).limit.upperLimit

      count >= limit && limit != -1
        ? setIsUserReachedCourseLimit(true)
        : setIsUserReachedCourseLimit(false)
    })
  }, [])

  const onDrag = event => {
    const { draggableId, destination, source, type } = event

    // Reorder collection
    if (!destination) return

    // Prevent same position dragging
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return

    // Prevent default course dragging
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return

    switch (type) {
      case TYPE_COLUMN:
        const [from, to] = [source.index, destination.index]
        let newColumnOrder = collections

        // Finding the column which is getting dragged
        const draggingCollection = newColumnOrder.find(
          x => x.id === draggableId,
        )

        newColumnOrder.splice(from, 1)
        newColumnOrder.splice(to, 0, draggingCollection)

        for (const column of newColumnOrder) {
          column.collectionIndex = newColumnOrder.indexOf(column)
        }

        dispatch(
          courseCollectionUpdateAction({
            courseCollectionDtos: newColumnOrder,
          }),
        )
        break
      case TYPE_TASK:
        // Finding the course which is dragging
        const [sourceIndex, destinationIndex] = [
          source.index,
          destination.index,
        ]

        // We're setting the 'null' collection to the default collection because backend stores the 'null' collection as the default collection
        const [sourceCollectionIndex, destinationCollectionIndex] = [
          source.droppableId === DEFAULT_COLLECTION_GUID
            ? null
            : source.droppableId,
          destination.droppableId === DEFAULT_COLLECTION_GUID
            ? null
            : destination.droppableId,
        ]

        const destinationCollection = collections.find(
          x => x.id === destinationCollectionIndex,
        )

        let draggingCourse = courseDtos.find(x => x.id === Number(draggableId))

        const [coursesOnSourceColumn, coursesOnDestinationColumn] = [
          courseDtos.filter(
            x => x.courseCollectionId === sourceCollectionIndex,
          ),
          courseDtos.filter(
            x => x.courseCollectionId === destinationCollectionIndex,
          ),
        ]

        // Sort array by courseCollectionOrder
        for (const arr of [coursesOnSourceColumn, coursesOnDestinationColumn]) {
          arr.sort((a, b) => {
            return a.courseCollectionOrder - b.courseCollectionOrder
          })
        }

        // Update courseCollectionId
        draggingCourse = {
          ...draggingCourse,
          courseCollectionId: destinationCollectionIndex,
          courseCollectionDto: destinationCollection,
        }

        // Re-order courses
        if (sourceCollectionIndex === destinationCollectionIndex) {
          // If collections are same, we need to splice destination column, because variables are diffirent and source splicing doesn't affect the destination collection
          coursesOnDestinationColumn.splice(sourceIndex, 1)
        }
        coursesOnSourceColumn.splice(sourceIndex, 1)
        coursesOnDestinationColumn.splice(destinationIndex, 0, draggingCourse)

        // Update courseCollectionOrder
        for (const arr of [coursesOnSourceColumn, coursesOnDestinationColumn]) {
          for (const course of arr) {
            course.courseCollectionOrder = arr.indexOf(course)
          }
        }

        const changedCourses = [
          ...coursesOnSourceColumn,
          ...coursesOnDestinationColumn,
        ]

        //Object.assign(courseDtos, changedCourses)
        dispatch(
          editCourseCollectionIndexAction({
            courseDtos: changedCourses,
          }),
        )

        break
      default:
        break
    }
  }

  return (
    <Page title="Quiz Cube">
      {isMobile && (
        <>
          {/* <img src="/assets/mobile.gif"></img>*/}
          <Alert
            severity="warning"
            style={{
              position: 'absolute',
              top: '68px',
              width: '350px',
              height: '50px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            We recommend creating & editing mini-courses on desktop browsers.
          </Alert>
          <Box style={{ height: '40px' }}></Box>
        </>
      )}

      <Container
        maxWidth={false}
        sx={{
          height: 1,
          display: 'flex',
          flexDirection: 'row',
          scrollBehavior: 'smooth',
        }}
      >
        <DragDropContext onDragEnd={onDrag}>
          {hasCoursesWithOutCollection && (
            <NonCollectionCourses
              collections={collections}
              courseDtos={courseDtos}
              history={history}
              isUserReachedCourseLimit={isUserReachedCourseLimit}
              setIsUserReachedCourseLimit={setIsUserReachedCourseLimit}
            />
          )}
          <Droppable
            droppableId="all-columns"
            direction="horizontal"
            type="column"
          >
            {provided => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                direction="row"
                alignItems="flex-start"
                spacing={2}
                sx={{ height: 'calc(100% - 32px)' }}
              >
                {collections.map((collection, index) => {
                  let courses = courseDtos.filter(
                    course =>
                      course.courseCollectionId &&
                      course.courseCollectionId === collection.id,
                  )

                  courses.sort((a, b) => {
                    return a.courseCollectionOrder - b.courseCollectionOrder
                  })
                  return (
                    <KanbanColumn
                      courses={courses}
                      index={courses.courseCollectionOrder}
                      key={collection.id}
                      column={{
                        collection: collection,
                        name: collection.collectionName,
                        cardIds: [],
                        id: collection.id,
                        collectionIndex: index, //collection.collectionIndex,
                      }}
                      history={history}
                      isUserReachedCourseLimit={isUserReachedCourseLimit}
                      setIsUserReachedCourseLimit={setIsUserReachedCourseLimit}
                    />
                  )
                })}

                {provided.placeholder}
                <Box m={2}>
                  <KanbanColumnAdd />
                </Box>
                <Box m={2}></Box>
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </Page>
  )
}
export default Kanban
export { DEFAULT_COLLECTION_GUID }
