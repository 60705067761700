import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import EditIcon from '@material-ui/icons/Edit'
import MaskedInput from 'react-text-mask'
import { PatchData } from '../../../../model/patch'
import isEmpty from '../../../../utils/is-empty'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import {
  withStyles,
  TextField,
  InputAdornment,
  Input,
  IconButton,
  FormControl,
} from '@material-ui/core'
import { FormInputType } from '../../../../form/props/form-item/input'
import { FormStateModeType } from '../../../../form/props'

const TextMaskCustom = props => {
  const { inputRef, mask, ...other } = props

  return <MaskedInput {...other} mask={mask} placeholderChar={'X'} showMask />
}

const TextDefault = props => {
  const {
    placeholder,
    maxLength,
    minLength,
    step,
    min,
    max,
    mask,
    ...other
  } = props

  return (
    <Input
      {...other}
      inputProps={{
        maxLength,
        minLength,
        step,
        min,
        max,
        placeholder,
        /* autoComplete: 'new-password' */
      }}
    />
  )
}

const FormItemInput = props => {
  const {
    attribute,
    classes,
    required,
    disabled,
    disabledEdit,
    value,
    inputType,
    maxLength,
    minLength,
    max,
    min,
    step,
    mask,
    placeholder,
    updateEntityKeyValue,
    stateMode,
    label,
  } = props

  const [stateValue, setStateValue] = useState(value)
  const [showPassword, setShowPassword] = useState(false)

  const [stateBlurValue, setStateBlurValue] = useState(value)

  /**
   *
   * @param {*} event
   */
  const onChangeHandler = event => {
    const { target } = event
    const { value } = target

    setStateValue(value)
  }

  /**
   *
   * @param {*} event
   */
  const onBlur = event => {
    if (stateBlurValue === stateValue) {
      return
    }

    updateEntityKeyValue(stateValue, props, PatchData.OPERATION_REPLACE)

    setStateBlurValue(stateValue)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const endAdornmentView = () => {
    if (FormInputType.PASSWORD == inputType) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )
    }

    return (
      <InputAdornment position="end">
        <EditIcon className={classes.endAdornmentIcon} />
      </InputAdornment>
    )
  }

  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            type={showPassword ? 'text' : inputType}
            fullWidth={inputType == FormInputType.NUMBER ? false : true}
            required={required}
            disabled={
              disabledEdit != undefined && stateMode == FormStateModeType.UPDATE
                ? disabledEdit
                : disabled
            }
            value={stateValue != null ? stateValue : ''}
            onBlur={onBlur}
            id={attribute}
            onChange={onChangeHandler}
            label={label}
            InputProps={{
              inputComponent: mask ? TextMaskCustom : TextDefault,
              inputProps: {
                placeholder,
                maxLength,
                minLength,
                step,
                min,
                max,
                mask,
              },
              className: classes.input,
              endAdornment: endAdornmentView(),
            }}
          />
        </FormControl>
      }
    />
  )
}

FormItemInput.propTypes = propTypes

export default withStyles(style)(FormItemInput)
