import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import EditIcon from '@material-ui/icons/Edit'
import isEmpty from '../../../../utils/is-empty'
import { PatchData } from '../../../../model/patch'
import { withStyles, TextField, InputAdornment } from '@material-ui/core'

const FormItemTextArea = props => {
  const {
    classes,
    required,
    disabled,
    value,
    error,
    maxLength,
    minLength,
    placeholder,
    updateEntityKeyValue,
    label,
  } = props

  const [stateValue, setStateValue] = useState(value)
  const [stateBlurValue, setStateBlurValue] = useState(value)

  /**
   *
   * @param {*} event
   */
  const onChangeHandler = event => {
    const target = event.target

    setStateValue(target.value)
  }

  /**
   *
   * @param {*} event
   */
  const onBlur = event => {
    if (stateBlurValue === stateValue) {
      return
    }

    updateEntityKeyValue(stateValue, props, PatchData.OPERATION_REPLACE)

    setStateBlurValue(stateValue)
  }

  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <TextField
          multiline={true}
          fullWidth={true}
          required={required}
          disabled={disabled}
          value={stateValue ? stateValue : ''}
          onChange={onChangeHandler}
          onBlur={onBlur}
          rows={4}
          label={label}
          error={error.success}
          InputProps={{
            inputProps: { placeholder, maxLength, minLength },
            className: classes.textarea,
            endAdornment: (
              <InputAdornment position="end">
                <EditIcon className={classes.endAdornmentIcon} />
              </InputAdornment>
            ),
          }}
        />
      }
    />
  )
}

FormItemTextArea.propTypes = propTypes

export default withStyles(style)(FormItemTextArea)
