import { guid } from '../../../../../utils/guid'
import SYSTEM_DATA from '../../../../../data'
import translator from '../../../../../translate'
import { FormItemType } from '../../../../../form/props/form-item/default'

const FormItemVerificationStatus = {
  id: guid().uid(),
  attribute: 'VerificationStatus',
  label: translator._('general.status'),
  type: FormItemType.SELECT,
  optionAttributeName: { label: 'label', value: 'value' },
  placeholder: translator._('general.status'),
  required: false,
  disabled: false,
  hidden: false,
  options: () => {
    return SYSTEM_DATA.FILTER_OPTIONS.VerificationStatus
  },
}

export default FormItemVerificationStatus
