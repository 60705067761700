import { put, call, take, fork } from 'redux-saga/effects'
import { userAddService } from '../../api/user'
import { userAddSuccess } from '../../reducers/user/action'
import { USER_ADD } from '../../reducers/user/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* userAddFlow(payload) {
  try {
    const { entity, callback } = payload
    const response = yield call(userAddService, entity)

    yield put(userAddSuccess(response.data))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* userAddWatcher() {
  while (true) {
    const action = yield take(USER_ADD)
    const { payload } = action

    yield fork(generalSaga, userAddFlow, payload)
  }
}
