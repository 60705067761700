import React, { Component } from 'react'
import style from './style'
import propTypes from './prop'
import Form from '../form'
import ShopForm from './form'
import { connect } from 'react-redux'
import { withStyles, Grid } from '@material-ui/core'
import { shopPatchAction } from '../../reducers/shop/action'
import {
  FormUseModeType,
  FormUpdateMethodType,
  FormStateModeType,
  FormViewType,
} from '../../form/props'

class FormShop extends Component {
  /**
   *
   */
  onPatch = patchData => {
    const { dispatch } = this.props

    dispatch(shopPatchAction(patchData, () => {}))
  }
  /**
   *
   */
  render() {
    const { dispatch, entity } = this.props

    return (
      <Grid container>
        <React.Fragment>
          <Form
            entity={entity}
            form={ShopForm}
            useMode={FormUseModeType.EDIT}
            updateMethod={FormUpdateMethodType.PATCH}
            dispatch={dispatch}
            viewMode={FormViewType.VIEW_INLINE}
            stateMode={
              entity.id === null
                ? FormStateModeType.SAVE
                : FormStateModeType.UPDATE
            }
            onPatch={this.onPatch}
          />
        </React.Fragment>
      </Grid>
    )
  }
}

FormShop.propTypes = propTypes

const mapStateToProps = response => ({
  entity: response.shop.entity,
})

export default connect(mapStateToProps)(
  withStyles(style, { withTheme: true })(FormShop),
)
