import React from 'react'
import Stars from './stars'
import Freeform from './freeform'
import NumericEntry from './numeric-entry'
import PhotoAnswer from './photo-answer'
import Inform from './inform'
import { AnswerItemType } from '../../../model/question'
import Option from './option'
import Signup from './signup'
import Stripe from './stripe-payment'
import ResultCard from './result-card'
import ScoreCard from './score'
import SertifierCard from './sertifier'
import ProtectionCard from './protection'
import ResultStandart from './result-standart'
import QuestionAi from './ai'

/**
 *
 * @param {*} props
 */
const AnswerTypeFactory = props => {
  const { type, id } = props
  props = { ...props, key: id }

  switch (type) {
    case AnswerItemType.INFORM:
      return <Inform {...props} />
    case AnswerItemType.TESTINGSINGLECHOICE:
      return <Option {...props} />
    case AnswerItemType.TESTINGMULTIPLECHOICE:
      return <Option {...props} />
    case AnswerItemType.SURVEYSINGLECHOICE:
      return <Option {...props} />
    case AnswerItemType.SURVEYMULTIPLECHOICE:
      return <Option {...props} />
    case AnswerItemType.SURVEYFREEFORM:
      return <Freeform {...props} />
    case AnswerItemType.SURVEYINPUT:
      return <PhotoAnswer {...props} />
    case AnswerItemType.SURVEYSTARS:
      return <Stars {...props} />
    case AnswerItemType.SURVEYNUMERICENTRY:
      return <NumericEntry {...props} />
    case AnswerItemType.SIGNUP:
      return <Signup {...props} />
    case AnswerItemType.STRIPEPAYMENT:
      return <Stripe {...props} />
    case AnswerItemType.RESULT:
      return <ResultCard {...props} />
    case AnswerItemType.SCORECARD:
      return <ScoreCard {...props} />
    case AnswerItemType.SERTIFIERCARD:
      return <SertifierCard {...props} />
    case AnswerItemType.PROTECTIONCARD:
      return <ProtectionCard {...props} />
    case AnswerItemType.RESULTDEFAULT:
      return <ResultStandart {...props} />
    case AnswerItemType.AI:
      return <QuestionAi {...props} />
    default:
      return ''
  }
}

export default AnswerTypeFactory
