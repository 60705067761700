import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const userChangePasswordService = query =>
  new Request(URL.API, URL.CHANGE_PASSWORD_USER).post(query)

/**
 *
 * @param {*} query
 */
export const userPasswordSetService = query =>
  new Request(URL.API, URL.PASSWORD_SET).post(query)

/**
 *
 * @param {*} query
 */
export const userRenewPasswordService = query =>
  new Request(URL.API, URL.RENEW_PASSWORD).post(query)
