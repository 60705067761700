import PropTypes from 'prop-types'
import {
  FormItemPropTypes,
  FormItemOptions,
  FormItemOptionAttributeName,
} from '../form-item/default'

const FormItemGroupCheckboxPropTypes = {
  ...FormItemPropTypes,
  options: PropTypes.arrayOf(PropTypes.shape(FormItemOptions)).isRequired,
  optionAttributeName: PropTypes.shape(FormItemOptionAttributeName).isRequired,
  getDataAsync: PropTypes.func,
}

export default FormItemGroupCheckboxPropTypes
