import PropTypes from 'prop-types'
import { FormItemPropTypes } from '../form-item/default'

export class FormInputType {
  static TEXT = 'text'
  static NUMBER = 'number'
  static DATE = 'date'
  static PASSWORD = 'password'
  static FILE = 'file'
}

const FormInputTypes = [
  FormInputType.TEXT,
  FormInputType.NUMBER,
  FormInputType.DATE,
  FormInputType.PASSWORD,
  FormInputType.FILE,
]

const FormItemInputPropTypes = {
  ...FormItemPropTypes,
  inputType: PropTypes.oneOf(FormInputTypes).isRequired,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  placeholder: PropTypes.string,
  mask: PropTypes.array,
}

export default FormItemInputPropTypes
