const styles = theme => ({
  addButton: {
    //width: '100%',
    marginTop: theme.spacing(1.5),
  },
  buttonIcon: {
    marginRight: theme.spacing(2),
  },
})

export default styles
