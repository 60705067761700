import React from 'react'
import { TextField, Typography, withStyles, Button } from '@material-ui/core'
import styles from './styles'

import { useSelector } from 'react-redux'
import { addCustomDomainAction } from 'src/reducers/domain/action'

const CnameView = props => {
  const { entity, dispatch, classes } = props
  const [domain, setDomain] = React.useState('')
  const collectionSettings = useSelector(
    state => state.courseCollectionList.collectionSettings,
  )
  const customDomain = useSelector(state => state.domain.list)

  let customDomainList = customDomain && customDomain.results
  customDomainList =
    customDomainList &&
    customDomainList.filter(
      x => x.defaultCollectionDto && x.defaultCollectionDto.id == entity.id,
    )
  const cname =
    collectionSettings.domain == null
      ? 'share.quizcube.io'
      : customDomainList.find(
          x => x.defaultCollectionDto.id == collectionSettings.domain,
        ) != null
      ? customDomainList.find(
          x => x.defaultCollectionDto.id == collectionSettings.domain,
        )[0].domain
      : 'share.quizcube.io'

  const handleAddDomain = () => {
    const data = {
      domain: domain,
      defaultCollectionId: entity.id,
      defaultCourseId: null,
      type: 'Collection',
    }
    dispatch(addCustomDomainAction(data, response => {}))
  }

  return (
    <div className={classes.customDomain__root}>
      <div className={classes.customDomain__cname}>
        <Typography color={'#637381'} fontSize={'14px'} fontWeight={500}>
          CNAME
        </Typography>
        <div className={classes.customDomain__domainView}>
          <Typography fontSize={'16px'} fontWeight={400}>
            {cname}
          </Typography>
        </div>
      </div>
      <div className={classes.customDomain__cname}>
        <Typography color={'#637381'} fontSize={'14px'} fontWeight={500}>
          Domain
        </Typography>
        <TextField
          value={domain}
          onChange={e => setDomain(e.target.value)}
          variant={'outlined'}
          placeholder="app.your.domain.com"
        />
      </div>
      <div className={classes.customDomain__buttonDiv}>
        <Button
          className={classes.customDomain__addDomainButton}
          onClick={() => handleAddDomain()}
          color="primary"
        >
          <Typography fontSize={'16px'} fontWeight={600}>
            Add Domain
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(CnameView)
