import {
  FormItemActive,
  FormItemSubject,
  FormItemTeam,
  FormItemSendType,
} from './form-item'

const QuestionFiltersForm = {
  addTitle: null,
  editTitle: null,
  groups: [],
  formItemsSuggestion: [
    { ...FormItemSubject },
    { ...FormItemTeam },
    { ...FormItemActive },
    { ...FormItemSendType },
  ],
  hasInitialFilter: true,
}

export default QuestionFiltersForm
