import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from './prop'
import ListSchema from './list'
import List from '../list'
import { ListUseModeType } from '../../model/list'
import { Typography, Card, CardContent, Box } from '@material-ui/core'
class ListCourseStatistics extends Component {
  static defaultProps = {
    hideTopBar: false,
    hideSearchBar: true,
    showPaging: true,
    showSelect: false,
    showChildList: false,
    usageMode: ListUseModeType.LIST,
    showDetailSearch: false,
    query: {
      CurrentPage: 0,
      PageSize: 40,
      SortingColumn: 'UniqueEngageUsers',
      Asc: false,
      FilterText: '',
      Filters: [],
    },
  }

  /**
   *
   */
  render() {
    let newQuery = this.props.query

    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom align="center">
            Course Statistics
          </Typography>
          <Box m={2} />
          <List
            list={ListSchema}
            disabled={false}
            {...this.props}
            query={newQuery}
          />
        </CardContent>
      </Card>
    )
  }
}
ListCourseStatistics.propTypes = propTypes
const mapStateToProps = response => ({
  queryResult: response.courseStatistics.list,
})

export default connect(mapStateToProps)(ListCourseStatistics)
