import {
  COURSE_TIME_SERIES_LIST,
  COURSE_TIME_SERIES_LIST_SUCCESS,
} from './type'
export default function (
  state = {
    list: { results: null, totalCount: 0 },
    query: null,
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case COURSE_TIME_SERIES_LIST:
      return { ...state, query: payload.query }
    case COURSE_TIME_SERIES_LIST_SUCCESS:
      return { ...state, list: payload.response }
    default:
      return state
  }
}
