const styles = theme => ({
  grid: {
    width: '100%',
    maxWidth: 610,
    flexBasis: 'unset',
    textAlign: 'center',
  },
})

export default styles
