import React from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

/**
 *
 * @param {*} props
 */
const Signup = props => {
  const { dispatch, entity, classes } = props

  return (
    <TextField
      className={classes.padding}
      placeholder="Users enter their e-mail address."
      width="100%"
      disabled
      fullWidth
    />
  )
}

Signup.propTypes = propTypes

export default withStyles(styles)(Signup)
