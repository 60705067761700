import { put, call, take, fork } from 'redux-saga/effects'
import { teamsMemberPatchService } from '../../api/teams'
import { patchTeamsMemberSuccess } from '../../reducers/teams/action'
import { TEAMS_MEMBER_PATCH } from '../../reducers/teams/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* patchTeamsMemberFlow(payload) {
  try {
    const { teamsId, memberId, content, callback } = payload

    yield call(teamsMemberPatchService, teamsId, memberId, content)

    yield put(patchTeamsMemberSuccess(teamsId, memberId, content))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* patchTeamMemberWatcher() {
  while (true) {
    const action = yield take(TEAMS_MEMBER_PATCH)
    const { payload } = action

    yield fork(generalSaga, patchTeamsMemberFlow, payload)
  }
}
