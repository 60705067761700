import { put, call, take, fork } from 'redux-saga/effects'
import { openaiSendCardRequestService } from '../../api/assignment'
import { OPENAI_SEND_CARD_REQUEST } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* openAiCardRequestFlow(payload) {
  try {
    const { starterText, courseId, cardId, callback } = payload

    const response = yield call(
      openaiSendCardRequestService,
      starterText,
      courseId,
      cardId,
    )

    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* openAiCardRequestWatcher() {
  while (true) {
    const action = yield take(OPENAI_SEND_CARD_REQUEST)
    const { payload } = action

    yield fork(generalSaga, openAiCardRequestFlow, payload, false)
  }
}
