const styles = theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
  },
  centerWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '7px',
  },
  helperText: {
    color: '#637381',
  },
  addButton: {
    width: '40%',
  },
  conditionViewButton: {
    width: '100%',
  },
  centerWrapperContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '4px',
    width: '100%',
  },
})

export default styles
