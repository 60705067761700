import { put, call, take, fork } from 'redux-saga/effects'
import { questionReportChoiceTendencyService } from '../../api/question'
import { questionReportChoiceTendencySuccess } from '../../reducers/question/action'
import { QUESTION_REPORT_CHOICE_TENDENCY } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* questionReportChoiceTendencyFlow(payload) {
  try {
    const { reqParams, parentId, callback } = payload
    const response = yield call(questionReportChoiceTendencyService, reqParams)

    yield put(questionReportChoiceTendencySuccess(response.data, parentId))
    yield put(operationSuccess())

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* questionReportChoiceTendencyWatcher() {
  while (true) {
    const action = yield take(QUESTION_REPORT_CHOICE_TENDENCY)
    const { payload } = action

    yield fork(generalSaga, questionReportChoiceTendencyFlow, payload)
  }
}
