import React, { useState } from 'react'
import style from '../style'
import propTypes from './prop'
import ItemLayout from '../../renderer/item-layout'
import { PatchData } from '../../../../model/patch'
import isEmpty from '../../../../utils/is-empty'
import {
  withStyles,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
} from '@material-ui/core'

const FormItemGroupRadio = props => {
  const {
    classes,
    disabled,
    value,
    options,
    optionAttributeName,
    updateEntityKeyValue,
    label,
  } = props

  const [stateValue, setStateValue] = useState(value)

  /**
   *
   * @param {*} event
   * @param {*} updateValue
   */
  const changeHandler = (event, updateValue) => {
    updateEntityKeyValue(updateValue, props, PatchData.OPERATION_REPLACE)

    setStateValue(updateValue)
  }

  return (
    <ItemLayout
      hasContent={!isEmpty(stateValue)}
      formItem={props}
      element={
        <FormControl variant="outlined" className={classes.formControl}>
          <FormLabel component="legend">{label}</FormLabel>

          <RadioGroup
            className={classes.formGroup}
            value={stateValue.toString()}
            onChange={changeHandler}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                disabled={disabled}
                className={classes.formControlLabel}
                label={option[optionAttributeName.label]}
                value={option[optionAttributeName.value].toString()}
                control={
                  <Radio
                    id={option[optionAttributeName.value].toString() + '-radio'}
                    color="primary"
                    className={classes.radio}
                  />
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      }
    />
  )
}

FormItemGroupRadio.propTypes = propTypes

export default withStyles(style)(FormItemGroupRadio)
