import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useDispatch } from 'react-redux'
import styles from './styles'
import { Typography, Divider, Button } from '@material-ui/core'

const OtherProjects = props => {
  const dispatch = useDispatch()
  const { classes } = props
  const redirectTo = project => {
    if (project === 'knowup') {
      window.open('https://knowup.io/', '_blank')
    } else {
      window.open('https://minicoursegenerator.com/', '_blank')
    }
  }
  return (
    <div className={classes.page}>
      <div className={classes.pageHeader}>
        <div className={classes.ourDiv}>
          <Typography
            color="#fff"
            fontSize={'16px'}
            fontWeight={600}
            style={{ height: '26px' }}
          >
            Our
          </Typography>
        </div>
        <Typography fontSize={'32px'} fontWeight={700}>
          Other Products
        </Typography>
      </div>
      <div className={classes.contentDiv}>
        <div className={classes.contentDivItem}>
          <img className={classes.logos} src="/assets/mcg-logo-big.png" />
          <Typography
            textAlign={'center'}
            fontSize={'16px'}
            fontWeight={600}
            className={classes.mb}
          >
            The easiest way to create, share, <br />
            and sell mini-courses & collections
          </Typography>
          <Button
            onClick={() => redirectTo()}
            variant="outlined"
            className={classes.buttons}
          >
            Learn More
          </Button>
        </div>
        <Divider color={'#000'} orientation="vertical"></Divider>
        <div className={classes.contentDivItem}>
          <img className={classes.logos} src="/assets/knowup-black-big.png" />
          <Typography
            textAlign={'center'}
            fontSize={'16px'}
            fontWeight={600}
            className={classes.mb}
          >
            Run your academy directly on Discord with the KnowUp Discord App.
          </Typography>
          <Button
            onClick={() => redirectTo('knowup')}
            variant="outlined"
            className={classes.buttons}
          >
            Learn More
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(OtherProjects)
