import React from 'react'
import { Icon } from '@iconify/react'

import { Typography, Button, withStyles } from '@material-ui/core'
import styles from '../styles'
import LearnersImportCsvTemplate from 'src/model/file/LearnersImportCsvTemplate.csv'

const ViewExample = props => {
  const { uploadedFile, classes } = props

  return (
    <div className={classes.modalContent__viewFile}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon
            icon={'fa-solid:file-csv'}
            height={20}
            width={20}
            color={uploadedFile ? '#919EAB' : '#45B058'}
          ></Icon>
          <Typography fontSize={'14px'} fontWeight={500}>
            Example .CSV File
          </Typography>
        </div>
        <Typography fontSize={'12px'} fontWeight={400} color={'#637381'}>
          You can download the attached example and use.
        </Typography>
      </div>

      <a
        href={LearnersImportCsvTemplate}
        download="Example-CSV-File.csv"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="text"
          className={classes.modalContent__downloadExampleButton}
        >
          <Typography className={classes.modalContent__buttonText}>
            Download
          </Typography>
        </Button>
      </a>
    </div>
  )
}

export default withStyles(styles)(ViewExample)
