import { put, call, take, fork } from 'redux-saga/effects'
import { teamsMemberAddService } from '../../api/teams'
import { addTeamsMemberSuccess } from '../../reducers/teams/action'
import { TEAMS_MEMBER_ADD } from '../../reducers/teams/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* addTeamsMemberFlow(payload) {
  try {
    const { teamsId, teamMembers, callback } = payload

    const response = yield call(teamsMemberAddService, teamsId, teamMembers)

    yield put(addTeamsMemberSuccess(response.data))
    yield put(operationSuccess())
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* addTeamsMemberWatcher() {
  while (true) {
    const action = yield take(TEAMS_MEMBER_ADD)
    const { payload } = action

    yield fork(generalSaga, addTeamsMemberFlow, payload)
  }
}
