const MEDIA_DIRECTORY_GET_ROOT = 'MEDIA_DIRECTORY_UPDATE_ENTITY'
const MEDIA_DIRECTORY_GET_ROOT_SUCCESS = 'MEDIA_DIRECTORY_UPDATE_ENTITY_SUCCESS'
const MEDIA_DIRECTORY_GET_ROOT_ERROR = 'MEDIA_DIRECTORY_UPDATE_ENTITY_ERROR'
const MEDIA_DIRECTORY_GET = 'MEDIA_DIRECTORY_PUT'
const MEDIA_DIRECTORY_GET_SUCCESS = 'MEDIA_DIRECTORY_PUT_SUCCESS'
const MEDIA_DIRECTORY_GET_ERROR = 'MEDIA_DIRECTORY_PUT_ERROR'

export {
  MEDIA_DIRECTORY_GET_ROOT,
  MEDIA_DIRECTORY_GET_ROOT_SUCCESS,
  MEDIA_DIRECTORY_GET_ROOT_ERROR,
  MEDIA_DIRECTORY_GET,
  MEDIA_DIRECTORY_GET_SUCCESS,
  MEDIA_DIRECTORY_GET_ERROR,
}
