import {
  MEDIA_FOLDERS_GET_BY_FILTER,
  MEDIA_FOLDERS_GET_BY_FILTER_SUCCESS,
  MEDIA_FOLDERS_GET_BY_FILTER_ERROR,
  MEDIA_FOLDER_GET_BY_ID,
  MEDIA_FOLDER_GET_BY_ID_SUCCESS,
  MEDIA_FOLDER_GET_BY_ID_ERROR,
  MEDIA_FOLDER_GET_ROOT,
  MEDIA_FOLDER_GET_ROOT_SUCCESS,
  MEDIA_FOLDER_GET_ROOT_ERROR,
  MEDIA_FOLDER_ADD_ASYNC,
  MEDIA_FOLDER_ADD_ASYNC_SUCCESS,
  MEDIA_FOLDER_ADD_ASYNC_ERROR,
  MEDIA_FOLDER_PATCH,
  MEDIA_FOLDER_PATCH_SUCCESS,
  MEDIA_FOLDER_PATCH_ERROR,
  MEDIA_FOLDER_DELETE_LIST,
  MEDIA_FOLDER_DELETE_LIST_SUCCESS,
  MEDIA_FOLDER_DELETE_LIST_ERROR,
  MEDIA_FOLDER_DELETE,
  MEDIA_FOLDER_DELETE_SUCCESS,
  MEDIA_FOLDER_DELETE_ERROR,
} from './type'

/**
 *
 * @param {*} query
 */
export const mediaFolderGetByFilterAction = query => {
  return {
    type: MEDIA_FOLDERS_GET_BY_FILTER,
    payload: { query },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaFolderGetByFilterSuccess = response => {
  return {
    type: MEDIA_FOLDERS_GET_BY_FILTER_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaFolderGetByFilterError = () => {
  return {
    type: MEDIA_FOLDERS_GET_BY_FILTER_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const mediaFolderGetByIdAction = id => {
  return {
    type: MEDIA_FOLDER_GET_BY_ID,
    payload: { id },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaFolderGetByIdSuccess = response => {
  return {
    type: MEDIA_FOLDER_GET_BY_ID_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaFolderGetByIdError = () => {
  return {
    type: MEDIA_FOLDER_GET_BY_ID_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const mediaFolderGetRootAction = id => {
  return {
    type: MEDIA_FOLDER_GET_ROOT,
    payload: { id },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaFolderGetRootSuccess = response => {
  return {
    type: MEDIA_FOLDER_GET_ROOT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaFolderGetRootError = () => {
  return {
    type: MEDIA_FOLDER_GET_ROOT_ERROR,
  }
}

/**
 *
 * @param {*} content
 */
export const mediaFolderAddAction = (content, callback) => {
  return {
    type: MEDIA_FOLDER_ADD_ASYNC,
    payload: { content, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaFolderAddSuccess = response => {
  return {
    type: MEDIA_FOLDER_ADD_ASYNC_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaFolderAddError = () => {
  return {
    type: MEDIA_FOLDER_ADD_ASYNC_ERROR,
  }
}

/**
 *
 * @param {*} content
 */
export const mediaFolderPatchAction = content => {
  return {
    type: MEDIA_FOLDER_PATCH,
    payload: { content },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaFolderPatchSuccess = response => {
  return {
    type: MEDIA_FOLDER_PATCH_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaFolderPatchError = () => {
  return {
    type: MEDIA_FOLDER_PATCH_ERROR,
  }
}

/**
 *
 * @param {*} ids
 */
export const mediaFolderDeleteListAction = ids => {
  return {
    type: MEDIA_FOLDER_DELETE_LIST,
    payload: { ids },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaFolderDeleteListSuccess = response => {
  return {
    type: MEDIA_FOLDER_DELETE_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaFolderDeleteListError = () => {
  return {
    type: MEDIA_FOLDER_DELETE_LIST_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const mediaFolderDeleteAction = (id, callback) => {
  return {
    type: MEDIA_FOLDER_DELETE,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaFolderDeleteSuccess = response => {
  return {
    type: MEDIA_FOLDER_DELETE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaFolderDeleteError = () => {
  return {
    type: MEDIA_FOLDER_DELETE_ERROR,
  }
}
