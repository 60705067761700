const styles = theme => ({
  center: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  padding: {
    paddingTop: '1rem',
  },
  maxWidthAlert: {
    padding: '1rem',
    width: 'fit-content',
    fontSize: '.8rem',
  },
  helperButton: {
    textTransform: 'inherit',
  },
  helperText: {
    color: '#637381',
  },
})

export default styles
