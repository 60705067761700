import { put, call, take, fork } from 'redux-saga/effects'
import { assignmentPatchService } from 'src/api/assignment'
import { collectionCoursePatchSuccess } from 'src/reducers/course-collection/action'
import { COLLECTION_COURSE_PATCH } from 'src/reducers/course-collection/type'
import generalSaga from '../general'
import { operationSuccess, operationFail } from 'src/reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* collectionCoursePatchFlow(payload) {
  try {
    const { content, callback } = payload
    callback()
    yield call(assignmentPatchService, content)
    yield put(collectionCoursePatchSuccess(content))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* collectionCoursePatchWatcher() {
  while (true) {
    const action = yield take(COLLECTION_COURSE_PATCH)
    const { payload } = action

    yield fork(generalSaga, collectionCoursePatchFlow, payload, false)
  }
}
