import { COURSE_STATISTICS_LIST, COURSE_STATISTICS_LIST_SUCCESS } from './type'

export default function (
  state = {
    list: { results: null, totalCount: 0 },
    query: null,
  },
  action,
) {
  const { payload, type } = action

  switch (type) {
    case COURSE_STATISTICS_LIST:
      return { ...state, query: payload.query }
    case COURSE_STATISTICS_LIST_SUCCESS:
      return { ...state, list: payload.response }
    default:
      return state
  }
}
