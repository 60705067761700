import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const teamListByQueryService = query =>
  new Request(URL.API, URL.LIST_TEAM_BY_QUERY).post(query)

/**
 *
 * @param {*} parentId
 */
export const teamListByParentService = parentId => {
  return new Request(URL.API, URL.LIST_TEAM_BY_PARENT).get({ parentId })
}

/**
 *
 * @param {*} parentId
 */
export const teamListByParentWithEmptyService = parentId => {
  return new Request(
    URL.API,
    URL.LIST_TEAM_BY_PARENT + '?parentId=' + parentId + '&addEmpty=true',
  ).get()
}

/**
 *
 * @param {*} id
 */
export const teamGetService = id =>
  new Request(URL.API, URL.GET_TEAM).get({ id })

/**
 *
 * @param {*} id
 */
export const teamGetListService = ids =>
  new Request(URL.API, URL.GET_TEAM_LIST).post(ids)

/**
 *
 * @param {*} entity
 */
export const teamAddService = entity =>
  new Request(URL.API, URL.ADD_TEAM).post(entity)

/**
 *
 * @param {*} entity
 */
export const teamEditService = entity =>
  new Request(URL.API, URL.EDIT_TEAM).put(entity)

/**
 *
 * @param {*} content
 */
export const teamPatchService = content =>
  new Request(URL.API, URL.PATCH_TEAM + '?id=' + content.id).patch(content)

/**
 *
 * @param {*} id
 */
export const teamDeleteService = id =>
  new Request(URL.API, URL.DELETE_TEAM).delete({ id })

/**
 * @param {*} entity
 */
export const teamUserAddService = entity =>
  new Request(URL.API, URL.ADD_USER_TO_TEAM).post(entity)

/**
 * @param {*} entity
 */
export const teamUserDeleteService = entity =>
  new Request(URL.API, URL.DELETE_USER_FROM_TEAM).post(entity)

/**
 * @param {*} ids
 */
export const teamListMakeActiveService = ids =>
  new Request(URL.API, URL.ACTIVE_TEAM_LIST).post(ids)

/**
 * @param {*} ids
 */
export const teamListMakePassiveService = ids =>
  new Request(URL.API, URL.PASSIVE_TEAM_LIST).post(ids)

/**
 * @param {*} ids
 */
export const teamListDeleteService = ids =>
  new Request(URL.API, URL.DELETE_TEAM_LIST).post(ids)

/**
 *
 * @param {*} entity
 */
export const teamListSetParentService = entity =>
  new Request(URL.API, URL.SET_PARENT_OF_TEAM_LIST).post(entity)

export const checkTeamNameAvailableService = name =>
  new Request(URL.API, URL.CHECK_TEAM_NAME_AVAILABLE).get({ name })
