/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import translator from 'src/translate'
import { assignmentAddAction } from 'src/reducers/assignment/action'
import {
  AssignmentFieldNames,
  AssignmentSchema,
  AssignmentTypes,
} from 'src/model/assignment'
import { collectionCoursePatchAction } from 'src/reducers/course-collection/action'
import { PatchContent, PatchData } from 'src/model/patch'
import {
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
  withStyles,
  OutlinedInput,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './styles'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import URL from 'src/api/url/api'
import { DEFAULT_COLLECTION_GUID } from 'src/pages/list-courses'
import { track } from '@amplitude/analytics-browser'

const NamingModal = props => {
  const {
    history,
    dispatch,
    handleCloseModal,
    classes,
    isModalOpen,
    selectedAssignmentType,
    id,
    collectionName,
    courseEntity,
    courses,
  } = props
  const [assignmentTitle, setAssignmentTitle] = useState(
    courseEntity ? courseEntity.name : '',
  )
  const [assignmentDescription, setAssignmentDescription] = useState(
    courseEntity?.description || '',
  )
  const [thumbnailUrl, setThumbnailUrl] = useState(
    courseEntity?.thumbnail || null,
  )

  const onThumbnailUpload = event => {
    if (thumbnailUrl) return
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        setThumbnailUrl(`${result.url}`)
      }),
    )
  }

  const handleSaveCourse = () => {
    const patchSource = new PatchData(
      null,
      courseEntity[AssignmentFieldNames.ID],
    )
    patchSource.addContent(
      new PatchContent(
        assignmentTitle,
        AssignmentFieldNames.NAME,
        PatchData.OPERATION_REPLACE,
      ),
    )
    patchSource.addContent(
      new PatchContent(
        assignmentDescription,
        AssignmentFieldNames.DESCRIPTION,
        PatchData.OPERATION_REPLACE,
      ),
    )

    patchSource.addContent(
      new PatchContent(
        thumbnailUrl,
        AssignmentFieldNames.THUMBNAIL,
        PatchData.OPERATION_REPLACE,
      ),
    )

    dispatch(
      collectionCoursePatchAction(patchSource, () => handleCloseModal(true)),
    )
  }

  const handleCreateButton = () => {
    const courseCollectionOrder = courses ? courses.length : 0
    dispatch(
      assignmentAddAction(
        {
          ...AssignmentSchema(),
          [AssignmentFieldNames.NAME]: assignmentTitle,
          [AssignmentFieldNames.IS_PERIODIC]: AssignmentTypes.ONETIME,
          [AssignmentFieldNames.DESCRIPTION]: assignmentDescription,
          [AssignmentFieldNames.THUMBNAIL]: thumbnailUrl,
          CourseCollectionId: id === DEFAULT_COLLECTION_GUID ? null : id,
          CourseCollectionOrder: courseCollectionOrder,
        },
        history,
        entity => {
          track('Course Created', {
            courseId: entity.id,
          })
          history.push('/quiz/' + entity.id)
        },
      ),
    )
  }
  const handleTextFieldChange = e => {
    setAssignmentTitle(e.target.value)
  }
  const handleTextFieldChangeDescription = e => {
    setAssignmentDescription(e.target.value)
  }

  const isTextFieldValid = () => {
    if (courseEntity || assignmentTitle.length >= 1) {
      return true
    }
    return false
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleCloseModal()}
      className={classes.modal}
    >
      <div className={classes.namingModalContent}>
        <div className={classes.headerGroup}>
          <Typography variant={'h5'}>
            {courseEntity
              ? `Rename ${courseEntity.name}`
              : `${translator._('assignment.createOneTime')}`}
          </Typography>
        </div>

        <div className={classes.root__bg}>
          <div
            className={classes.root__section}
            style={{
              justifyContent: 'flex-start',
              gap: '0.7rem',
              flexDirection: 'column',
            }}
          >
            <div className={classes.image__bg}>
              {!thumbnailUrl && (
                <div className={classes.image__icon}>
                  <AddPhotoAlternateIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                  <span className={classes.image__span}>Add Image</span>
                </div>
              )}
              <div className={classes.image__content}>
                {thumbnailUrl && (
                  <img
                    className={classes.image__img}
                    alt=""
                    src={`${URL.MEDIA}` + thumbnailUrl}
                  />
                )}
              </div>
              {thumbnailUrl && (
                <div
                  className={classes.image__remove}
                  onClick={() => setThumbnailUrl()}
                >
                  <DeleteIcon fontSize="medium" />
                </div>
              )}

              {!thumbnailUrl && (
                <input
                  type="file"
                  className={classes.file__upload}
                  onChange={onThumbnailUpload}
                />
              )}
            </div>
            <span className={classes.span__extension}>.png, .jpg, .gif</span>
          </div>
          <div
            className={classes.root__section}
            style={{ paddingRight: '1rem' }}
          >
            <div className={classes.root__content}>
              <OutlinedInput
                sx={{
                  typography: 'h6',
                  fontWeight: 'normal',
                  borderColor: 'black',
                }}
                defaultValue={assignmentTitle}
                placeholder={translator._('assignment.assignmentName')}
                onChange={handleTextFieldChange}
              />
              <TextField
                className={classes.input__description}
                multiline
                maxRows={3}
                minRows={3}
                defaultValue={assignmentDescription}
                placeholder={translator._('assignment.assignmentDescription')}
                onChange={handleTextFieldChangeDescription}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.buttonGroup}>
          <Button variant="outlined" color="inherit" onClick={handleCloseModal}>
            {translator._('assignment.back')}
          </Button>
          <Button
            variant="contained"
            disabled={isTextFieldValid() ? false : true}
            className={classes.continueButton}
            onClick={courseEntity ? handleSaveCourse : handleCreateButton}
          >
            {translator._(
              courseEntity ? 'assignment.save' : 'assignment.create',
            )}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default withStyles(styles)(NamingModal)
