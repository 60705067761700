import React, { useEffect, useState } from 'react'
import styles from './style'
import propTypes from './prop'

import {
  withStyles,
  Button,
  Alert,
  CircularProgress,
  Typography,
  IconButton,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import {
  useCardSettings,
  createSetting,
  patchSetting,
} from 'src/hooks/useCardSettings'
import { AssignmentFieldNames } from 'src/model/assignment'
import { QuestionFieldNames, AnswerItemType } from '../../../../model/question'

import { guid } from 'src/utils/guid'
import {
  componentBoxShowAction,
  componentBoxHideAction,
} from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'

import translator from 'src/translate'
import ModalContent from './popup'

const has = numeric => {
  if (numeric == 0) return true

  return numeric ? true : false
}

const ResultCard = props => {
  const { classes, entity, dispatch } = props
  const [componentBoxContent, setComponentBoxContent] = useState(false)

  const cardId = entity[QuestionFieldNames.ID]

  const settings = useCardSettings(cardId, QuestionFieldNames.RESULT_CONDITIONS)
  const resultConditions = settings ? JSON.parse(settings.value) : undefined

  useEffect(() => {
    if (!resultConditions) {
      createSetting(
        dispatch,
        cardId,
        QuestionFieldNames.RESULT_CONDITIONS,
        JSON.stringify([]),
      )
    }
  }, [resultConditions])

  const saveCondition = data => {
    if (!settings) return
    const settingId = settings.id

    patchSetting(
      dispatch,
      cardId,
      settingId,
      QuestionFieldNames.RESULT_CONDITIONS,
      JSON.stringify(data),
    )
    dispatch(componentBoxHideAction(componentBoxContent))
    setComponentBoxContent(false)
  }

  const handleOpenPopup = (id, header) => {
    const component = new ComponentBoxContent(
      (
        <ModalContent
          {...props}
          conditions={resultConditions}
          conditionId={id}
          key={guid.uid}
          actionType={has(id) ? 'edit' : 'add'}
          saveCondition={saveCondition}
        />
      ),
      {
        hideCloseButton: false,
        header: has(id) ? (
          header
        ) : (
          <div style={{ display: 'flex', gap: '5px' }}>
            <img src="/assets/3-conditional-icon.svg"></img>
            <Typography style={{ color: '#F67B3F' }}>Add Condition</Typography>
          </div>
        ),
        callback: e => {},
      },
    )
    setComponentBoxContent(component)
    dispatch(componentBoxShowAction(component))
  }
  const prepareDeleteCondition = (value, index) => {
    resultConditions.splice(index, 1)
    return saveCondition(resultConditions)
  }
  return (
    <>
      {resultConditions && typeof resultConditions != 'undefined' ? (
        <div className={classes.wrapper}>
          <Typography className={classes.helperText}>
            Your Conditions
          </Typography>
          {resultConditions && resultConditions.length > 0 ? (
            <div className={classes.centerWrapper}>
              {resultConditions.map((value, index) => {
                const header = `${value.type} ${value.condition} ${value.value}`
                return (
                  <div className={classes.centerWrapperContent}>
                    <Button
                      variant="outlined"
                      className={classes.conditionViewButton}
                      onClick={() => handleOpenPopup(index, header)}
                    >
                      {header}
                    </Button>
                    <IconButton
                      aria-label="delete"
                      onClick={() => prepareDeleteCondition(value, index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                )
              })}
            </div>
          ) : (
            <Alert severity="warning">
              You must add at least 1 condition for this card to work.
            </Alert>
          )}
          <Typography className={classes.helperText}>
            Your Conditions
          </Typography>

          <Button
            variant={
              resultConditions && resultConditions.length > 0
                ? 'outlined'
                : 'contained'
            }
            onClick={() => handleOpenPopup()}
            className={classes.addButton}
            startIcon={<AddIcon />}
          >
            {resultConditions && resultConditions.length > 0
              ? 'Add new condition '
              : 'Add condition'}
          </Button>
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  )
}

ResultCard.propTypes = propTypes

export default withStyles(styles)(ResultCard)
