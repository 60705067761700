import { put, call, take, fork } from 'redux-saga/effects'
import { teamsCollectionService } from '../../api/teams'

import { SEND_MAIL_TO_LEARNER } from '../../reducers/teams/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* sendMailToLearnerFlow(payload) {
  try {
    const {
      collectionId,
      sendLinkToNewMembersOnly,
      courseCode,
      callback,
    } = payload

    const response = yield call(
      teamsCollectionService,
      collectionId,
      sendLinkToNewMembersOnly,
      courseCode,
    )

    yield put(operationSuccess())
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* sendMailToLearnerWatcher() {
  while (true) {
    const action = yield take(SEND_MAIL_TO_LEARNER)
    const { payload } = action

    yield fork(generalSaga, sendMailToLearnerFlow, payload)
  }
}
